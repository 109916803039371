import { AbstractDocument } from "./abstract-document.model";
import { BasicSystemConfig } from './basic-system-config.model';
import { Constants } from '../app.constants';
import { BasicSensorPosRef } from './basic-sensor-posref.model';
import { BasicEngineThruster } from './basic-engine-thruster.model';


export class VesselDPConfiguration extends AbstractDocument {

    public marineAuxSystems: BasicSystemConfig[];
    public powerGenerations: BasicSystemConfig[];
    public powerDistributions: BasicSystemConfig[];
    public thrusters: BasicSystemConfig[];
    public dpControlSystems: BasicSystemConfig[];
    public vesselManagementSystems: BasicSystemConfig[];
    public powerManagementSystems: BasicSystemConfig[];
    public thursterCount: number;
    public engineCount: number;
    public vessel_ms: string;
    public power_ms: string;
    public mandatoryComplete: boolean;
    type= Constants.TYPES.VESSEL_DP_CONFIG;

    
    public vThrusterNames: BasicEngineThruster[];
    public vEngineNames: BasicEngineThruster[];
    public vDpControllers: BasicSensorPosRef[];
    public vDpSensors: BasicSensorPosRef[];
    public vPosRefSystems: BasicSensorPosRef[];
}