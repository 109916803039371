import { TrialRev } from './annual-trial-rev';

export class RevTile {
    public trialRev: TrialRev;
    public revNumber: number;
    public char: string;
    public readOnly: boolean;
    public showEditModeTile: boolean;
    public showReadModeTile: boolean;
    public statusDisplay: string;
    public statusDate: string;
    public showStatus: boolean;
    public docFileKey: string;
    public docLocalPath: string;
    public docMDT: number;
    public completed: boolean;//Full Trial is Final
    public show: boolean;//To show the tile or not
    public generateReport: boolean;//Means Fresh New tile and TrialRev doc not present
    public updateReport: boolean;//Send for Review enable along with generate button
    public hasNewModsPostLastGenerate: boolean;
    public viewReport: boolean;//To View or Download the report
    public enableReview: boolean;//For Reviewer and Approver
    // public disableReview: boolean;//For Reviewer and Approver
    public reviewDeniedVisible: boolean;//
    public reviewDenied: boolean;//
    public reviewDeniedUnread: boolean;//
    public pendingReview: boolean;//To see for Creator/Onboard guy or Reviewer
    public showCloseTrialButton: boolean;
    public showGenerateNextRevButton: boolean;
    public revNoGenerateNextRevButton: number;
    public enableGenerateRevButton: boolean;//Needed??
    public charGenerateNextRevButton: string;
}