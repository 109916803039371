import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-color-modal',
  templateUrl: './color-modal.page.html',
  styleUrls: ['./color-modal.page.scss'],
})
export class ColorModalPage implements OnInit {

  blue : string;
  red : string;
  yellow : string;
  green : string;

  color: string;
  selectedColor: string = "blue";
  constructor(navParams: NavParams, public modalController: ModalController) { 
  this.blue = "#007AA0";
  this.red = "#bb0303";
  this.yellow = "#f5c24c";
  this.green = "#048c56";

  this.selectedColor = navParams.get('previousHeaderColor');
  console.log("this.selectedColor:----"+this.selectedColor);
  }

  ngOnInit() {
    //this.color = "#007AA0";
    this.color = "";
  }

  colorChosen(color, selectedColor) {
    this.selectedColor = selectedColor;
    this.color = color;
  }

  cancelledSelection() {
    this.color = "";
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      color: this.color
    });
  }

}



/*import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-color-modal',
  templateUrl: './color-modal.page.html',
  styleUrls: ['./color-modal.page.scss'],
})
export class ColorModalPage implements OnInit {

  color:string ;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
    this.color = "#fff";
  }

  colorChosen(color){
    this.color = color;
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true,
      color:this.color
    });
  }

}
*/