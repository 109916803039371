import { Injectable, Injector } from '@angular/core';

// MISC
import { ArrayUtils } from '../../../commons/utils';

// MODEL
import { EnvCondKeyPersonnel } from '../../../models';

// PROVIDERS
import { EnvCondPersonnelDao } from '../../dao';
import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { SectionStatus } from 'src/app/models/enums';

@Injectable()
export class EnvCondPersonnelService extends AbstractService {

    constructor(protected injector: Injector, private envDao: EnvCondPersonnelDao) {
        super(injector, "EnvCondPersonnelService");
    }

    /**
     *
     * @param {EnvCondKeyPersonnel} env
     * @returns {Promise<EnvCondKeyPersonnel>}
     */
    public async createEnvCondKeyPersonnel(env: EnvCondKeyPersonnel): Promise<EnvCondKeyPersonnel> {
        env.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(env);
        this.addPrincipalDocIdToChannel(env.annualTrialId, env);
        let response = this.envDao.create(env);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<EnvCondKeyPersonnel>}
     */
    public async getEnvCondKeyPersonnel(docId: string): Promise<EnvCondKeyPersonnel> {
        return await this.envDao.get(docId);
    }

    /**
     *
     * @returns {Promise<Array<EnvCondKeyPersonnel>>}
     */
    public async findAll(): Promise<Array<EnvCondKeyPersonnel>> {
        return this.envDao.findAll();
    }

    /**
     *
     * @returns {Promise<Array<EnvCondKeyPersonnel>>}
     */
    public async findAllCreatedEnvCondKeyPersonnels(): Promise<Array<EnvCondKeyPersonnel>> {
        var query = {
            type: Constants.TYPES.ENV_COND_PERSONNEL,
            created: true
        }
        return this.envDao.findBasedOnQuery(query);
    }


    /**
     *
     * @returns {Promise<Array<EnvCondKeyPersonnel>>}
     */
    public async findEnvCondKeyPersonnelForAnnualTrial(annualTrialIdVal: string): Promise<Array<EnvCondKeyPersonnel>> {

        var query = {
            type: Constants.TYPES.ENV_COND_PERSONNEL,
            annualTrialId: annualTrialIdVal
            //TODO: Manoj add created param also
        }
        return this.envDao.findBasedOnQuery(query);
    }

    public async getCurrentEnvCondKeyPersonnelForAnnualTrial(annualTrialIdVal: string): Promise<EnvCondKeyPersonnel> {
        let envArray = await this.findEnvCondKeyPersonnelForAnnualTrial(annualTrialIdVal);
        if (envArray && envArray.length > 0) {
            return envArray[0];
        }
        return undefined;
    }

    public async getStatusOfCurrentEnvCondKeyPersonnelForAnnualTrial(annualTrialIdVal: string, env?: EnvCondKeyPersonnel): Promise<SectionStatus> {
        if (!env) {
            env = await this.getCurrentEnvCondKeyPersonnelForAnnualTrial(annualTrialIdVal);
        }
        if (env) {
            if (env.completed) {
                return SectionStatus.COMPLETED;
            }
            else if (env.created) {
                return SectionStatus.CREATED;
            }
            else if (!env.created) {
                return SectionStatus.DRAFT;
            }
        }
        return SectionStatus.PENDING;
    }


    /**
     *
     * @param {EnvCondKeyPersonnel} env
     * @returns {Promise<boolean>}
     */
    public async removeEnvCondKeyPersonnel(env: EnvCondKeyPersonnel): Promise<boolean> {
        return this.envDao.delete(env);
    }

    /**
     *
     * @param {EnvCondKeyPersonnel} env
     * @returns {Promise<EnvCondKeyPersonnel>}
     */
    public async updateEnvCondKeyPersonnel(env: EnvCondKeyPersonnel): Promise<EnvCondKeyPersonnel> {
        env.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.envDao.update(env);
    }

    async fetchEnvConditionMandatoryCompletion(annualId) {  //use to fetch already created ship mandatory status when clicked on edit
        let mandatoryObj = [];

        let envConditionComplete: any;
        let envSensorComplete: any;

        let envObj = await this.getCurrentEnvCondKeyPersonnelForAnnualTrial(annualId);
        if (envObj != undefined) {

            if (envObj.hasOwnProperty("envConditionCompleted")) {
                envConditionComplete = envObj.envConditionCompleted;
            } else
                envConditionComplete = false;

            if (envObj.hasOwnProperty("keyPersonnelCompleted")) {
                envSensorComplete = envObj.keyPersonnelCompleted;
            } else
                envSensorComplete = null;

            mandatoryObj.push((envConditionComplete != null) ? !envConditionComplete : null);   //true = incomplete, false = complete
            mandatoryObj.push((envSensorComplete != null) ? !envSensorComplete : null);
        }
        if (envObj == undefined) {
            mandatoryObj = [null, null];    //null for new creation
        }
        return mandatoryObj;
    }

}
