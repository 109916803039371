import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { StepExecStatus } from './enums';

export class FindingsStepInfo {
    public stepUId: string;
    public stepUUID: string;
    public testCaseExecId: string;
    public testNumber: string;
    public stepNumber: string;
    public testName: string;
    public testDesc: string;
    public testStatus: string;
    public comment: string;
    public closureDate: string;
    public closeOut: string;
    public attachment: any = [];
    public isAdditionalFinding: boolean;
    public imageDocIds: Array<string> = [];
    public fromPreviousTrial: boolean = false;
    public previousTrialId: string = "";
    /**A B or C to be used Only*/
    public category: StepExecStatus = StepExecStatus.NOT_EXECUTED;
}

export class FindingsAndCloseoutDetails extends AbstractDocument {
    public vesselId: string;
    public annualTrialId: string;
    public aFindings: Array<FindingsStepInfo> = [];
    public bFindings: Array<FindingsStepInfo> = [];
    public cFindings: Array<FindingsStepInfo> = [];

    // public revNumber: number = -1;
    // public copyDate: Date;
    // public isCopy: boolean = false;
    public previousFindingsImported:boolean = false;
    public containsPreviousFindings:boolean = false;
    type = Constants.TYPES.FINDINGS_AND_CLOSEOUT;
}

