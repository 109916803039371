import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable, forkJoin } from "rxjs";
import { map, reduce } from 'rxjs/operators';
import * as Rx from 'rxjs';
import { Constants } from "../../../app.constants";

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {

    private readonly I18N_DIR = "./assets/i18n/";
    private readonly I18N_FILES = ["account","global","vessel-details","annual-trial"];

    constructor(private http: HttpClient) { }

    public getTranslation(lang: string): Observable<any> {
        return forkJoin(this.getObservables(lang)).pipe(
            map((res) => {
                return res.reduce((prev, curr) => {
                    return Object.assign(prev, curr);
                }, {})
            })
        );
    }

    private getObservables(lang: string): Observable<any>[] {
        const obs = [this.http.get(this.I18N_DIR + lang + "." + Constants.FILE.EXTENSION.JSON)];
        this.I18N_FILES.forEach(o => {
            obs.push(this.loadFile(lang, o));
        });
        return obs;
    }

    private loadFile(lang: string, fileName: string): Observable<Object> {
        return this.http.get(this.I18N_DIR + lang + "/" + fileName + "." + Constants.FILE.EXTENSION.JSON);
    }
}

