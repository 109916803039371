import { Injectable, Injector, ViewChild, ElementRef } from '@angular/core';
import { PDFDocument, StandardFonts, rgb, PDFPage, PDFFont } from 'pdf-lib'
import * as fontkit from '@pdf-lib/fontkit'
import * as _ from 'lodash';
// MISC
import { ArrayUtils, StringUtils, DateUtils } from '../../../commons/utils';

// MODEL

/*pdfmake library started*/
import pdfMake from 'pdfmake/build/pdfmake';

// PROVIDERS
import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { PDFGenerateUtilsService } from './pdf-generate-utils.service';
import { ReportConst, CellBasic, PDRectangle, ImageAspect, FontAlign, FontStyle, FontSet, TextContentType, Point, NewPage, ReportContentType } from './pdf-constants';
import { ImageDocService } from '../image-doc/image-doc.service';
import { Doc, AnnualTrials, Image, BasicSystemConfig, TrialRev, BasicEngineThruster } from 'src/app/models';
import { MainDataService } from '../data/main-data.service';
import { AppManagerService } from '../../app-manager/app-manager.service';
import { DocSubTypeEnum, KeyPersonnelRole, DocTypeEnum, RevStatus, KeyPersonnelRoleLabel } from 'src/app/models/enums';
import { FileHandlerService } from '../image-doc/file-handler.service';
import { sprintf } from 'sprintf-js';
import { ReportIndex } from 'src/app/models/report-index.model';

import { CordovaUtils } from '../../../commons/utils/cordova-utils';


@Injectable()
export class PDFGenerateService extends AbstractService {

    //Global Vars
    regularFont: PDFFont;
    boldFont: PDFFont;
    italicFont: PDFFont;
    boldItalicFont: PDFFont;
    symbolFont: PDFFont;
    zapfDingbatsFont: PDFFont;
    fontSet: FontSet;
    skipFooterPages: number[];
    tableOfContents: Array<ReportIndex> = [];
    // var sprintf = require('sprintf-js').sprintf;
    //     vsprintf = require('sprintf-js').vsprintf

    // sprintf('%2$s %3$s a %1$s', 'cracker', 'Polly', 'wants')
    // vsprintf('The first 4 letters of the english alphabet are: %s, %s, %s and %s', ['a', 'b', 'c', 'd'])

    vesselData: any = {};
    isCordovaAvailable: boolean;
    public afterItemRevision: number = 0;
    public afterFigNo: number = 0;

    constructor(protected injector: Injector, protected pdfUtils: PDFGenerateUtilsService, private fileHandlerService: FileHandlerService,
        private dataService: MainDataService, private appManager: AppManagerService) {
        super(injector, "PDFGenerateService");
        this.isCordovaAvailable = CordovaUtils.cordovaIsAvailable();
        console.log("this.isCordovaAvailable in pdf-generate:---", this.isCordovaAvailable);
    }

    private getRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    async createDummyPdf(vesselId: string, annualTrialId: string): Promise<string> {
        let doc = new Doc();
        let fileName = "DummyFile_" + this.getRandomInt(1000) + ".pdf";
        doc.displayFileName = fileName;
        doc.fileName = fileName;
        doc.localpath = "";
        doc.remoteFileKey = "";
        //doc.remoteUrl = "E:\\Projects\\IHM\User Bugs\\gulliver\\Almonda_Risk_Assessment_v1.pdf";
        //doc.remoteUrl = "http://localhost:8080/bvcommons/filehandler/s3/downloadstream?s3key=s3Test/presignedurl/MicrosoftTeams-image.png";
        doc.remoteUrl = "http://localhost:8080/bvcommons/filehandler/s3/downloadstream?s3key=s3Test/Almonda_Risk_Assessment_v1.pdf";
        doc.subtype = DocSubTypeEnum.REV;
        doc.parentDocId = annualTrialId;

        doc = await this.fileHandlerService.imageDocService.createDoc(doc);
        return doc.uid;

    }



    generateFileName(vesselName: string, trialYear: string, docType: string): string {
        let fileName = this.fileHandlerService.filterName(vesselName + "_" + this.fileHandlerService.filterName(trialYear) + "_" + docType + "_" + DateUtils.getCurrentDateMilli());
        return fileName.toUpperCase() + ".pdf";;
    }

    getTempDoc(vesselName: string, trialYear: string, annualTrialId: string, docSubType: DocSubTypeEnum, rev?: string): Doc {
        let doc = new Doc();
        let docType: string = "";
        if (docSubType == DocSubTypeEnum.REV || docSubType == DocSubTypeEnum.REV0) {
            docType = Constants.REPORT_NAME_REVISION + " " + rev;
        }
        else if (docSubType == DocSubTypeEnum.LT_END_COMPLI) {
            docType = this.fileHandlerService.filterName(Constants.REPORT_NAME_END_OF_TRIALS);
        }
        else if (docSubType == DocSubTypeEnum.LT_PROV_FIND) {
            docType = this.fileHandlerService.filterName(Constants.REPORT_NAME_PROV_FINDINGS);
        }

        let fileName = this.generateFileName(vesselName, trialYear, docType);//"DummyFile_" + this.getRandomInt(1000) + ".pdf";
        doc.displayFileName = fileName;
        doc.fileName = fileName;
        doc.localpath = "";
        doc.remoteFileKey = "";

        doc.subtype = docSubType;
        doc.parentDocId = annualTrialId;

        return doc;
    }

    getDuplicateDoc(orgDoc: Doc, postFix: string) {
        let doc = new Doc();
        let fileName = orgDoc.fileName;

        let fileVals = fileName.split(".");
        if (fileVals && fileVals.length > 1) {//Manoj this part assumes that no extra . are present in the filename
            fileName = fileVals[0] + "_" + postFix + "." + fileVals[1];
        }
        else {
            fileName = fileName + "_" + postFix + ".pdf";//This condition should ideally not execute
        }
        doc.displayFileName = fileName;
        doc.fileName = fileName;
        doc.localpath = "";
        doc.remoteFileKey = "";

        doc.subtype = orgDoc.subtype;
        doc.parentDocId = orgDoc.parentDocId;

        doc.docInfoPages = orgDoc.docInfoPages;

        return doc;
    }

    addReportIndex(srNo: string, name: string, pageNo: number, isTitle: boolean, isAppendix?: number) {
        let reportIndex: ReportIndex = new ReportIndex();
        reportIndex.isAppendix = !isAppendix ? 0 : isAppendix;
        reportIndex.srNo = srNo;
        reportIndex.name = name;
        reportIndex.pageNo = pageNo;
        reportIndex.isTitle = isTitle;
        this.tableOfContents.push(reportIndex);
    }

    async updateRevisionHistory(vesselId: string, annualTrialId: string, revNumber: number, orgDoc: Doc, revStatus: RevStatus) {
        let data: any = await this.dataService.getDocumentHistoryData(vesselId, annualTrialId, revNumber, revStatus);

        console.log("data in updateRevisionHistory in pdf-generate: ", data);
        if (!data || !orgDoc) {
            this.appManager.showToast("Error! Failed to load required data for report...", "bottom", true);
            return null;
        }
        let oldPdfDoc = await this.fetchPDF(orgDoc.remoteFileKey);
        console.log("oldPdfDoc:---", oldPdfDoc);
        if (!oldPdfDoc) {
            this.appManager.showToast("Error! Unable to load exisinting report. Please check your network connection...", "bottom", true);
            return null;
        }
        let pdfDoc = await PDFDocument.create();
        pdfDoc = this.getMetaDataFromPDF(pdfDoc, oldPdfDoc);
        await this.setUpFontSet(pdfDoc);
        let maxOldRevhistoryPageIndex = 1;
        if (orgDoc.docInfoPages && orgDoc.docInfoPages.length > 0) {
            for (let index = 0; index < orgDoc.docInfoPages.length; index++) {
                const element = orgDoc.docInfoPages[index];
                if (element - 1 > maxOldRevhistoryPageIndex) {
                    maxOldRevhistoryPageIndex = element - 1;
                }
            }
        }
        let docInfoPages = [];
        // if (!orgDocInfoPages || orgDocInfoPages.length <= 0) {
        //     // orgDocInfoPages = [];
        //     // orgDocInfoPages.push(2);//NOT INDEX
        // }

        let extraDocInfoPages = 0;
        const [firstCoverPage] = await pdfDoc.copyPages(oldPdfDoc, [0]);
        pdfDoc.addPage(firstCoverPage);
        extraDocInfoPages = await this.addDocumentInfo(pdfDoc, data) - 1;
        docInfoPages.push(1);
        for (let index = 1; index <= extraDocInfoPages; index++) {
            docInfoPages.push(1 + index);
        }
        for (let index = maxOldRevhistoryPageIndex + 1; index < oldPdfDoc.getPageCount(); index++) {
            const [nextPage] = await pdfDoc.copyPages(oldPdfDoc, [index]);
            pdfDoc.addPage(nextPage);
        }
        this.vesselData.vesselName = data.vesselName;
        this.vesselData.imoNo = data.imoNo;
        this.vesselData.trialYear = data.trialYear;

        const savedPDFURI = await pdfDoc.saveAsBase64({ dataUri: true });//.save();
        const fileBlob = this.fileHandlerService.dataURItoBlob(savedPDFURI);
        // let doc = this.getTempDoc(this.vesselData.vesselName, this.vesselData.trialYear, annualTrialId, revNumber == Constants.REV_0_VAL ? DocSubTypeEnum.REV0 : DocSubTypeEnum.REV);
        let doc = this.getDuplicateDoc(orgDoc, revStatus == RevStatus.APPROVAL_ACCEPTED ? "APPR" : (revStatus == RevStatus.REVIEW_ACCEPTED ? "REVD" : ""));
        console.log("Insdie pdf-generateservice updateRevisionHistory : savedPDFURI - ", savedPDFURI);
        console.log("Insdie pdf-generateservice updateRevisionHistory : fileBlob - ", fileBlob);

        doc.docInfoPages = docInfoPages;//TODO: Manoj check if new doc is required... as better approach
        let finalDoc = await this.fileHandlerService.processSelectedFile(doc, doc.fileName, fileBlob, this.vesselData.imoNo);
        // let finalDoc = await this.fileHandlerService.reUploadFile(doc, fileBlob, this.vesselData.imoNo);
        console.log("finalDoc in updateRevisionHistory :---", finalDoc);
        // this.pdfUtils.openFileInWindow(pdfBytes);
        return finalDoc;
    }

    async setUpFontSet(pdfDoc: PDFDocument) {
        // const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
        // const fontBytes = await fetch(url).then(res => res.arrayBuffer())
        // pdfDoc.registerFontkit(fontkit);
        // this.regularFont = await pdfDoc.embedFont(fontBytes);
        this.regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        this.boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        this.italicFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
        this.boldItalicFont = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
        this.symbolFont = await pdfDoc.embedFont(StandardFonts.Symbol);
        this.zapfDingbatsFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

        this.fontSet = { regular: this.regularFont, bold: this.boldFont, italic: this.italicFont, boldItalic: this.boldItalicFont, symbolFont: this.symbolFont, zapfDingbatsFont: this.zapfDingbatsFont };
        this.pdfUtils.symbolFont = this.symbolFont;
        this.pdfUtils.zapfDingbatsFont = this.zapfDingbatsFont;
        // this.fontSet = { regular: this.symbolFont, bold: this.symbolFont, italic: this.symbolFont, boldItalic: this.symbolFont, symbolFont: this.symbolFont };
    }

    async createRevision(vesselId: string, annualTrialId: string, revNumber: number, type: string = "old") {
        console.log(type);
        // if (revNumber > Constants.REV_B_VAL) {
        //     let findingHistory = await this.dataService.findingsAndCloseoutService.createee
        // }
        this.logger.debug("createRevision Start: vesselId:" + vesselId + " annualTrialId:" + annualTrialId + " revNumber:" + revNumber);
        if (revNumber > Constants.REV_B_VAL) {
            let findings = await this.dataService.findingsAndCloseoutService.getCurrentFindingsCloseoutForAnnualTrial(annualTrialId);
            let annualTrial = await this.dataService.annualTrialService.getAnnualTrials(annualTrialId);
            let trialYear = "";
            if (annualTrial) {
                trialYear = "" + annualTrial.trialYear;
            }
            if (findings) {
                this.logger.debug("createRevision Creating Finding History");
                let maxCPlus = -1;
                if (revNumber == Constants.REV_0_VAL) {
                    let trialRevMaxC = await this.dataService.annualTrialService.getMaxCPlusRevNoTrialRev(annualTrialId);
                    if (trialRevMaxC) {
                        this.logger.debug("createRevision trialRevMaxC:", trialRevMaxC);
                        maxCPlus = trialRevMaxC.revNumber;
                    }
                }
                let findingHistory = await this.dataService.findingsAndCloseoutService.createFindingsHistory(findings.uid, revNumber, trialYear, findings, maxCPlus);
            }

        }
        let data: any = await this.dataService.getRevisionData(vesselId, annualTrialId, revNumber);
        if (revNumber == Constants.REV_0_VAL) {
            if (!data.annualTrial) {
                this.appManager.showToast("Error! Failed to load required annual trial data for report...", "bottom", true);
                return null;
            }
            if (StringUtils.isBlank(data.annualTrial.startDateTrial)) {
                this.appManager.showToast("Error! Please set 'Start date of the trial'...", "bottom", true);
                return null;
            }
            if (StringUtils.isBlank(data.annualTrial.endDateTrial)) {
                this.appManager.showToast("Error! Please set 'End date of the trial'...", "bottom", true);
                return null;
            }
        }
        if (!data) {
            this.appManager.showToast("Error! Failed to load required data for report...", "bottom", true);
            return null;
        }



        if (type == "new") {
            //let mynewPDF = await this.mynewProcessRevision(data);
            return data;
        } else {
            let pdfDoc = await PDFDocument.create();
            await this.setUpFontSet(pdfDoc);
            let responseDoc = await this.processRevision(pdfDoc, data);
            pdfDoc = responseDoc.pdfDoc;
            this.vesselData.vesselName = data.vesselName;
            this.vesselData.imoNo = data.imoNo;
            this.vesselData.trialYear = data.trialYear;
            if (Constants.REV_TEST_ENABLED) {
                const pdfBytes = await pdfDoc.save();
                this.pdfUtils.openFileInWindow(pdfBytes);
            }
            else {
                let doc = this.getTempDoc(this.vesselData.vesselName, this.vesselData.trialYear, annualTrialId, revNumber == Constants.REV_0_VAL ? DocSubTypeEnum.REV0 : DocSubTypeEnum.REV, data.revChar);
                let author = revNumber <= Constants.REV_B_VAL ? this.dataService.getDPResponsiblePersonName(data.vessel) : this.dataService.getOnboardTrialPersonName(data.annualTrial);
                if (StringUtils.isBlank(author)) {
                    author = "DP Solution"
                }
                pdfDoc = this.addMetaDataToPDF(pdfDoc, doc.fileName, "BVS DP Trials Revision " + this.dataService.getRevDisplayName(revNumber), author);
                const savedPDFURI = await pdfDoc.saveAsBase64({ dataUri: true });//.save();
                const fileBlob = this.fileHandlerService.dataURItoBlob(savedPDFURI);

                doc.docInfoPages = responseDoc.docInfoPages;
                let finalDoc = await this.fileHandlerService.processSelectedFile(doc, doc.fileName, fileBlob, this.vesselData.imoNo);
                this.logger.debug("createRevision END: finalDoc:", finalDoc);
                // this.pdfUtils.openFileInWindow(pdfBytes);
                console.log("finalDoc in create revision: ", finalDoc);
                return finalDoc;
            }
        }
    }
    async createProvFindings(vesselId: string, annualTrialId: string, isEndOfTrial: boolean) {
        this.vesselData = {};
        let pdfDoc = await PDFDocument.create();
        this.regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        this.boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        this.italicFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
        this.boldItalicFont = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
        this.symbolFont = await pdfDoc.embedFont(StandardFonts.Symbol);
        this.zapfDingbatsFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

        this.fontSet = { regular: this.regularFont, bold: this.boldFont, italic: this.italicFont, boldItalic: this.boldItalicFont, symbolFont: this.symbolFont, zapfDingbatsFont: this.zapfDingbatsFont };
        this.pdfUtils.symbolFont = this.symbolFont;
        this.pdfUtils.zapfDingbatsFont = this.zapfDingbatsFont;
        let vessel = await this.dataService.vesselService.getVessel(vesselId);
        let annualTrial = await this.dataService.annualTrialService.getAnnualTrials(annualTrialId);
        let clientInfo = await this.dataService.annualTrialService.getAnnualTrialsClientInfo(annualTrial.clientInfoId);
        let findingsAndCloseoutList = await this.dataService.findingsAndCloseoutService.findAll(vesselId, annualTrialId);
        let testCasesExec = await this.dataService.getTestCaseService().findAllTestCaseExec(annualTrialId, Constants.SORT_BY.ASC);
        let testCases = await this.dataService.getTestCaseService().findVesselTestCases(vesselId, Constants.SORT_BY.ASC);
        let vesselMainParticulars = await this.dataService.vesselService.getMainParticulars(vessel.mainParticularsId);

        let data: any = {};
        data.vessel = vessel;
        data.annualTrial = annualTrial;
        data.clientInfo = clientInfo;
        data.testCases = testCases;
        data.testCasesExec = testCasesExec;
        data.vesselMainParticulars = vesselMainParticulars;
        if (findingsAndCloseoutList && findingsAndCloseoutList.length > 0) {
            //Filter out data
            let findings = findingsAndCloseoutList[0];
            this.logger.debug("createProvFindings: ORG findings:", JSON.stringify(findingsAndCloseoutList[0]));
            if (findings.containsPreviousFindings) {
                let aFindings = _.filter(findings.aFindings, { fromPreviousTrial: false });
                let bFindings = _.filter(findings.bFindings, { fromPreviousTrial: false });
                let cFindings = _.filter(findings.cFindings, { fromPreviousTrial: false });
                findings.aFindings = aFindings;
                findings.bFindings = bFindings;
                findings.cFindings = cFindings;
            }
            this.logger.debug("createProvFindings: FILTERED findings:", JSON.stringify(findings));
            data.findings = findings;
        }
        else {
            data.findings = {};
        }
        let datesTrial = this.dataService.getTrialStartEndDates(data, "DD/MM/YYYY");
        if (datesTrial && datesTrial.length > 0) {
            let savedStartV = data.annualTrial.startDateTrial;
            let savedEndV = data.annualTrial.endDateTrial;
            data.annualTrial.startDateTrial = datesTrial[0];
            if (datesTrial.length > 1) {
                data.annualTrial.endDateTrial = datesTrial[1];
            }

            if (savedStartV != data.annualTrial.startDateTrial || savedEndV != data.annualTrial.endDateTrial) {
                //Update new date values
                this.logger.debug("Update AnnualTrialDoc with date: Start");
                this.dataService.getAnnualTrialService().updateAnnualTrials(data.annualTrial);
                this.logger.debug("Update AnnualTrialDoc with date: End");
            }
        }

        if (isEndOfTrial) {
            await this.addEndOfTrial(pdfDoc, data);
        }
        else {
            await this.addProvFindings(pdfDoc, data);
        }

        await this.addProvFindingsHeaderLogo(pdfDoc);

        await this.addProvFindDocFooter(pdfDoc);

        // this.vesselData.vesselName = "Test";
        // this.vesselData.imoNo = "1234321";
        // this.vesselData.trialYear = "2020";
        let author = this.dataService.getOnboardTrialPersonName(annualTrial);
        if (StringUtils.isBlank(author)) {
            author = "DP Solution"
        }
        let doc = this.getTempDoc(vessel.vesselName, annualTrial.trialYear, annualTrialId, isEndOfTrial ? DocSubTypeEnum.LT_END_COMPLI : DocSubTypeEnum.LT_PROV_FIND);
        pdfDoc = this.addMetaDataToPDF(pdfDoc, doc.fileName, isEndOfTrial ? "BVS End of DP Trials Compliance Letter" : "BVS Provisional Findings Letter", author);
        const savedPDFURI = await pdfDoc.saveAsBase64({ dataUri: true });//.save();
        const fileBlob = this.fileHandlerService.dataURItoBlob(savedPDFURI);//this.appManager.getFormattedTimeStamp(annualTrial.trialYear, Constants.DATE_FORMAT.DATE_4_ANGULAR)

        let finalDoc = await this.fileHandlerService.processSelectedFile(doc, doc.fileName, fileBlob, this.vesselData.imoNo);
        // this.pdfUtils.openFileInWindow(pdfBytes);
        return finalDoc;
    }

    addMetaDataToPDF(pdfDoc: PDFDocument, title: string, subject?: string, author?: string) {
        // Note that these fields are visible in the "Document Properties" section of 
        // most PDF readers.
        pdfDoc.setTitle(title)
        if (StringUtils.isNotBlank(author)) {
            pdfDoc.setAuthor(author)
        }
        if (StringUtils.isNotBlank(subject)) {
            pdfDoc.setSubject(subject)
        }

        // pdfDoc.setSubject('📘 An Epic Tale of Woe 📖')
        // pdfDoc.setKeywords(['eggs', 'wall', 'fall', 'king', 'horses', 'men'])
        pdfDoc.setProducer('Bureau Veritas Solutions Marine & Offshore')
        pdfDoc.setCreator('DP Solution');//'pdf-lib (https://github.com/Hopding/pdf-lib)')
        let creationDate = new Date();
        pdfDoc.setCreationDate(creationDate)
        pdfDoc.setModificationDate(creationDate)
        return pdfDoc;
    }

    getMetaDataFromPDF(pdfDoc: PDFDocument, oldPdfDoc: PDFDocument) {
        if (oldPdfDoc) {
            if (StringUtils.isNotBlank(oldPdfDoc.getTitle())) {
                pdfDoc.setTitle(oldPdfDoc.getTitle())
            }
            if (StringUtils.isNotBlank(oldPdfDoc.getAuthor())) {
                pdfDoc.setAuthor(oldPdfDoc.getAuthor())
            }
            if (StringUtils.isNotBlank(oldPdfDoc.getSubject())) {
                pdfDoc.setSubject(oldPdfDoc.getSubject())
            }
            if (StringUtils.isNotBlank(oldPdfDoc.getProducer())) {
                pdfDoc.setProducer(oldPdfDoc.getProducer())
            }
            if (StringUtils.isNotBlank(oldPdfDoc.getCreator())) {
                pdfDoc.setCreator(oldPdfDoc.getCreator())
            }
            if (oldPdfDoc.getCreationDate()) {
                pdfDoc.setCreationDate(oldPdfDoc.getCreationDate())
            }
            let creationDate = new Date();
            pdfDoc.setModificationDate(creationDate)
        }
        return pdfDoc;
    }

    async createPdf(vesselId: string, annualTrialId: string, revNumber: number) {

        let pdfDoc = await PDFDocument.create();
        this.regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        this.boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        this.italicFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
        this.boldItalicFont = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
        this.symbolFont = await pdfDoc.embedFont(StandardFonts.Symbol);
        this.zapfDingbatsFont = await pdfDoc.embedFont(StandardFonts.ZapfDingbats);

        this.fontSet = { regular: this.regularFont, bold: this.boldFont, italic: this.italicFont, boldItalic: this.boldItalicFont, symbolFont: this.symbolFont, zapfDingbatsFont: this.zapfDingbatsFont };
        this.pdfUtils.symbolFont = this.symbolFont;
        this.pdfUtils.zapfDingbatsFont = this.zapfDingbatsFont;
        let data: any = {};
        this.skipFooterPages = [];
        let responseDoc = await this.processRevision(pdfDoc, data);
        pdfDoc = responseDoc.pdfDoc;
        let extraDocInfoPages = 0;
        // await this.addCoverPage(pdfDoc);
        // this.addDocumentInfo(pdfDoc);

        // this.addIntroduction(pdfDoc);

        // this.addVesselParticulars(pdfDoc);

        // this.addFindingsPage(pdfDoc);

        // this.addAppendixPage(pdfDoc, "APPENDIX A – FINDINGS & CLOSED OUT DETAILS ATTACHMENTS");
        // this.addAppendixPage(pdfDoc, "APPENDIX B – STATUS REPORT");


        // this.addStatusReportPage(pdfDoc);

        // this.addAppendixPage(pdfDoc, "APPENDIX C – TEST SHEETS");

        // this.addAppendixPage(pdfDoc, "APPENDIX D – SUPPORTING DOCUMENTS");

        // //END
        // await this.addHeaderLogo(pdfDoc);

        // await this.addRevDocFooter(pdfDoc, "2020", "Client Name", "Vessel Name", "1xxxx", "A");

        const pdfBytes = await pdfDoc.save();

        this.pdfUtils.openFileInWindow(pdfBytes);
    }

    async processRevision(pdfDoc: PDFDocument, data: any) {
        this.skipFooterPages = [];
        this.tableOfContents = [];
        let extraDocInfoPages = 0;
        let docInfoPages = [];
        await this.addCoverPage(pdfDoc, data);
        extraDocInfoPages = await this.addDocumentInfo(pdfDoc, data) - 1;
        this.addExecutiveSummary(pdfDoc, data);

        pdfDoc.addPage();
        pdfDoc.addPage();

        await this.addIntroduction(pdfDoc, data);
        // await this.addVesselParticulars(pdfDoc, data);
        let vesselParticulars = await this.mynewProcessRevision(data, ReportContentType.VESSEL_PARTICULARS);
        console.log(`Vessel Particular: `, vesselParticulars);
        let vesselParticularsData = await this.addtestCasePageProcess(pdfDoc, vesselParticulars, undefined, false);
        let vpSize = vesselParticularsData.skipPages.length - 1;
        await this.addVesselParticulars(pdfDoc, data, true, vpSize);

        this.addDPTrialsProgramme(pdfDoc)
        this.addConclusions(pdfDoc, data);
        await this.addFindingsPage(pdfDoc, data);


        this.addAppendixPage(pdfDoc, "APPENDIX A", "STATUS REPORT");


        await this.addStatusReportPage(pdfDoc, data);


        this.addAppendixPage(pdfDoc, "APPENDIX B", "TEST SHEETS");
        //await this.addTestCasePage(pdfDoc, data);
        /*rravi need to change testcase to another one. */
        data.showTestCases = await this.addTestCasePage(pdfDoc, data, 'new');

        for (let s = 0; s < data.showTestCases.length; s++) {
            let myNewPdf = await this.mynewProcessRevision(data.showTestCases[s], ReportContentType.TESTCASE);
            let testCaseData =  await this.addtestCasePageProcess(pdfDoc, myNewPdf, data.testCases[s])
        }
        /*rravi will insert it later*/


        // this.addAppendixPage(pdfDoc, "APPENDIX C", "FINDINGS & CLOSED OUT DETAILS ATTACHMENTS");
        await this.addFindingsDocPage(pdfDoc, data);

        if (data.supportDocs && data.supportDocs.length > 0) {
            this.addAppendixPage(pdfDoc, "APPENDIX D ", " SUPPORTING DOCUMENTS");
            await this.addSupportDocPage(pdfDoc, data);//TODO: Enable it    
        }

        this.addTableOfContent(pdfDoc);
        //END
        await this.addHeaderLogo(pdfDoc);

        // await this.addRevDocFooter(pdfDoc, data.trialYear, data.clientInfo.clientName, data.vesselName, data.annualTrial.reportNo, data.revChar, extraDocInfoPages);//"1xxxx"

        await this.addRevDocFooter(pdfDoc, data.trialYear, data.annualTrial.titleTrialsDoc, data.annualTrial.bvsProjectNo, data.revChar, extraDocInfoPages);//"1xxxx"


        docInfoPages.push(1);
        for (let index = 1; index <= extraDocInfoPages; index++) {
            docInfoPages.push(1 + index);
        }
        return { pdfDoc: pdfDoc, docInfoPages: docInfoPages };
    }

    async mynewProcessRevision(data, reportContentType: string) {
        return new Promise(async (resolve: any, reject: any) => {
            console.log("largeTermData", JSON.stringify(data));

            let pageMargin: any = {
                hPos: 60,
                vPos: 80
            };

            let pdfData;
            if(reportContentType === ReportContentType.TESTCASE) {
                pdfData = await this.getTestCases(data);
            }
            else if(reportContentType === ReportContentType.VESSEL_PARTICULARS) {
                pdfData = await this.getVesselParticular(data, pageMargin.hPos, pageMargin.vPos);
            }

            // let myTestCases = await this.getTestCases(data);
            // await  this.pdfMakeAttribute();

            var myContent = [];

            //Add Test Case
            // myContent = myContent.concat(myTestCases);
            myContent = myContent.concat(pdfData);


            //const documentDefinition = { content: html };
            var documentDefinition = {
                pageSize: 'A4',
                pageMargins: [pageMargin.hPos, pageMargin.vPos + 30, pageMargin.hPos, pageMargin.vPos],
                pageOrientation: 'portrait',
                content: myContent,
                styles: {
                    "header": {
                        fontSize: ReportConst.SUBTITLE_FONT_SIZE,
                        color: ReportConst.COLOR_HEX_BLUE,
                        bold: true
                    },
                    "header-2": {
                        fontSize: ReportConst.SUBTITLE_FONT_SIZE_2,
                        color: ReportConst.COLOR_HEX_BLUE,
                        bold: true
                    },
                    "sub-header": {
                        fontSize: ReportConst.CONTENT_FONT_SIZE,
                        color: ReportConst.COLOR_HEX_BLUE,
                        bold: true
                    },
                    "para": {
                        fontSize: ReportConst.CONTENT_FONT_SIZE,
                    },
                    "caption": {
                        fontSize: ReportConst.CAPTION_FONT_SIZE,
                        color: ReportConst.COLOR_HEX_BLUE,
                        bold: true
                    },
                    "mt-15": {
                        margin: [0, 15, 0, 0],
                    },
                    "mt-20": {
                        margin: [0, 20, 0, 0],
                    },
                },
                defaultStyle: {
                    fontSize: 9,
                    bold: false
                },
            };
            //pdfMake.createPdf(documentDefinition).open();
            pdfMake.createPdf(documentDefinition).getBase64(async (fileBase64) => {
                resolve(fileBase64);
            });/* */
        });

    }


    async getTestCases(data, myPDFContent: any = {}) {
        let myTestCases = [];

        // let mainTable = document.getElementById('test_table_' + s).innerHTML;
        let mytestName = "";
        if (data && data.testName) {
            mytestName = data.testName;
        }

        let html = await this.createMytestTable(data);
        myTestCases.push(html);


        return myTestCases;
    }

    /* pdfmake code Start */
    public styleHeader = "header";
    public styleSubHeader = "sub-header";
    public stylePara = "para";
    public styleCaption = "caption";
    public leftAlign = "left";
    public srNoWidth = "10%";
    public mt_15= "mt-15";
    public mt_20 = "mt-20";
    public centerAlign = "center";
    public justifyAlign = "justify";
    public pageSize: any = {
        width: 595.28,
        height: 841.89
      };

    async getVesselParticular(data, hPos, vPos) {
        let vesselParticulars = [];

        let columns = [];
        let contentText = "Vessel Particulars";
        let srNo = "2.";
        // vesselParticulars.push(this.addTocContent(srNo, contentText, this.tocHeader));
        columns.push(this.addContentText(srNo, [this.styleHeader], this.leftAlign, undefined, undefined, true, false, this.srNoWidth));
        columns.push(this.addContentText(contentText, [this.styleHeader], this.leftAlign));
        vesselParticulars.push({
        columns: columns,
        });

        srNo = "2.1";
        contentText = "General";
        columns = [];
        // vesselParticulars.push(this.addTocContent(srNo, contentText, this.tocHeaderSub));
        columns.push(this.addContentText(srNo, [this.styleSubHeader, this.mt_20], this.leftAlign, undefined, undefined, true, false, this.srNoWidth));
        columns.push(this.addContentText(contentText, [this.styleSubHeader, this.mt_20], this.leftAlign));
        vesselParticulars.push({
        columns: columns,
        });

        let equipmentClass = "";
        if (data.vesselMainParticulars) {
        equipmentClass = "" + data.vesselMainParticulars.dpEquipmentClass;
        }

        srNo = "2.1.1";
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_1_1, data.vesselName, equipmentClass, data.vessel.vesselType,
        data.vessel.builderName, data.vessel.deliveryYear);
        columns = [];
        columns.push(this.addContentText(srNo, [this.stylePara, this.mt_15], this.justifyAlign, undefined, undefined, false, false, this.srNoWidth));
        columns.push(this.addContentText(contentText, [this.stylePara, this.mt_15], this.justifyAlign));
        vesselParticulars.push({
        columns: columns,
        });

        let itemRevision = 2;
        if (StringUtils.isNotBlank(data.vesselMainParticulars.addDescription)) {
        srNo = "2.1.2";
        itemRevision++;
        contentText = data.vesselMainParticulars.addDescription;
        columns = [];
        columns.push(this.addContentText(srNo, [this.stylePara, this.mt_15], this.justifyAlign, undefined, undefined, false, false, this.srNoWidth));
        columns.push(this.addContentText(contentText, [this.stylePara, this.mt_15], this.justifyAlign));
        vesselParticulars.push({
            columns: columns,
        });
        }

        srNo = "2.1." + itemRevision;
        itemRevision++;
        contentText = data.vesselMainParticulars.dpPowerSystemRedundancyConcept;//"DP Power System Redundancy Concept.";
        columns = [];
        columns.push(this.addContentText(srNo, [this.stylePara, this.mt_15], this.justifyAlign, undefined, undefined, false, false, this.srNoWidth));
        columns.push(this.addContentText(contentText, [this.stylePara, this.mt_15], this.justifyAlign));
        vesselParticulars.push({
        columns: columns,
        });

        srNo = "2.1." + itemRevision;
        itemRevision++;
        contentText = "Major power system equipment is allocated as shown in Table 2-1 and arranged as shown in Table 2-1.";
        columns = [];
        columns.push(this.addContentText(srNo, [this.stylePara, this.mt_15], this.justifyAlign, undefined, undefined, false, false, this.srNoWidth));
        columns.push(this.addContentText(contentText, [this.stylePara, this.mt_15], this.justifyAlign));
        vesselParticulars.push({
        columns: columns,
        });

        srNo = "";
        contentText = "Table 2-1" + ReportConst.CONTENT_SPACE + "Allocation of Equipment to DP Redundancy Groups";
        columns = [];
        /* columns.push(this.addContent(srNo, [this.styleCaption, this.mt_15], this.justifyAlign, undefined, undefined, false, false, this.srNoWidth)); */
        columns.push(this.addContentText(contentText, [this.styleCaption, this.mt_15], this.centerAlign));
        vesselParticulars.push({
        columns: columns,
        });

        this.afterItemRevision = itemRevision;
        let anyCellFilled = false;
        let tableData = data.vesselMainParticulars.dpRedundancyGroupName;
        for (let rowIndex = 1; rowIndex < tableData.length; rowIndex++) {
            const rowData = tableData[rowIndex];
            for (let index = 0; index < rowData.length; index++) {
              const element = rowData[index];
              if (StringUtils.isNotBlank(element.value)) {
                anyCellFilled = true;
              }
            }
          }
        if(anyCellFilled){
            let redundancyGroupObj = await this.addRedundencyGroupTable(data, [35, 20, 35, 3]);
            vesselParticulars.push(redundancyGroupObj);
        }

        //Add Image
        let figNo = 1;
        if (data.vesselDpRedundancyGroupsImage) {
            if (StringUtils.isNotBlank(data.vesselDpRedundancyGroupsImage.remoteFileKey)) {
                let fileURL = "";

                if (this.isCordovaAvailable && !this.connectivityService.isConnected()) { //to uncomment Yash
                fileURL = this.fileHandlerService.displayFileFromKey(data.vesselDpRedundancyGroupsImage.remoteFileKey);
                } else {
                // if (data.vesselImage && StringUtils.isNotBlank(data.vesselImage.remoteFileKey)) {   //web code
                fileURL = this.fileHandlerService.getRemoteFileURL(data.vesselDpRedundancyGroupsImage.remoteFileKey);
                // }
                }

                const jpgImageBytes = await fetch(fileURL).then((res) => res.arrayBuffer());
                let imageObj = {
                image: jpgImageBytes,
                width: this.pageSize.width - 3 * hPos,
                height: (this.pageSize.width - 2 * ReportConst.MARGIN_DEFAULT) / 1.75,
                margin: [35, ReportConst.MARGIN_TOP_3, 35, 0]
                }
                // vesselParticulars.push(this.addPage('', this.pBreakBefore));
                vesselParticulars.push(imageObj);

                srNo = "";
                contentText = ReportConst.REPORT_MSGS.FIG_2_1;
                figNo++;
                columns = [];
                columns.push(this.addContentText(srNo, [this.styleCaption, this.mt_15], this.justifyAlign, { x: hPos, y: vPos + 480 }, undefined, false, false, this.srNoWidth));
                columns.push(this.addContentText(contentText, [this.styleCaption, this.mt_15], this.centerAlign, { x: hPos, y: vPos + 480 }));
                vesselParticulars.push({
                columns: columns,
                });
                this.afterFigNo = figNo;
            }
        }

        return vesselParticulars;
    }

    async addRedundencyGroupTable_Delete(data, margins?: any, absolutePos?: any): Promise<any> {
        let redundancyTableBody = [];
        let columnWidth = [];
        let defaultFontSize = 10;
        let tableData = data.vesselMainParticulars.dpRedundancyGroupName;
        console.log(`Redundancy Data: `, tableData);
    
        if (tableData != undefined && tableData.length > 0) {
          for (let index = 0; index < tableData.length; index++) {
            const rowElement = tableData[index];
            let redundRow = [];
            for (let cellIndex = 0; cellIndex < rowElement.length; cellIndex++) {
              const cellElement = rowElement[cellIndex];
              let rowObj;
              if (index === 0) {
                let cellWidth;
                rowObj = {
                  text: cellElement['value'],
                  fontSize: defaultFontSize,
                  bold: true,
                  color: cellElement['headerColor'] ? 'white' : undefined,
                  fillColor: cellElement['headerColor'] ? cellElement['headerColor'] : undefined,
                  rowSpan: cellElement['rowspan'],
                  colSpan: cellElement['colspan'],
                  alignment: 'center'
                }
                redundRow.push(rowObj);
                if (cellElement['width']) {
                  cellWidth = (cellElement['width'] / rowElement.length) + '%';
                }
                columnWidth.push(cellElement['width'] ? cellWidth : '*');
              }
              else {
                if (cellElement['colspan'] > 1) {
                  rowObj = {
                    text: cellElement['value'],
                    fontSize: defaultFontSize,
                    color: cellElement['headerColor'] ? 'white' : undefined,
                    fillColor: cellElement['headerColor'] ? cellElement['headerColor'] : undefined,
                    rowSpan: cellElement['rowspan'],
                    colSpan: cellElement['colspan'],
                  }
                  redundRow.push(rowObj);
                  redundRow.push({});
                }
                else {
                  rowObj = {
                    text: cellElement['value'],
                    fontSize: defaultFontSize,
                    color: cellElement['headerColor'] ? 'white' : undefined,
                    fillColor: cellElement['headerColor'] ? cellElement['headerColor'] : undefined,
                    rowSpan: cellElement['rowspan'],
                    colSpan: cellElement['colspan'],
                  }
                  redundRow.push(rowObj);
                }
              }
              // redundRow.push(rowObj);
            }
            redundancyTableBody.push(redundRow);
          }
        }
    
        let redundancyGrpTable = await this.getTableObj(redundancyTableBody, columnWidth, undefined, margins);
    
        return redundancyGrpTable;
    }

    

    async addRedundencyGroupTable(data, margins?: any, absolutePos?: any): Promise<any> {
        let redundancyTableBody= [];
        
        let defaultFontSize = 10;
        let tableData = data.vesselMainParticulars.dpRedundancyGroupName;
        console.log(`Redundancy Data: `, tableData);
        let mytableDataShow  : any= [];
        if (tableData != undefined && tableData.length > 0) {
            // for (let index = 0; index < tableData.length; index++) {
                // const rowElement = tableData[index];
                const rowElement = tableData;
                let columnWidth = [];
                let totalWidth = 0;
                for (let cellIndex = 0; cellIndex < rowElement[0].length; cellIndex++) {
                    const cellElement = rowElement[0][cellIndex];
                    if (cellElement['width']) {
                        totalWidth += cellElement['width'];
                    }
                }
                for (let cellIndex = 0; cellIndex < rowElement[0].length; cellIndex++) {
                    const cellElement = rowElement[0][cellIndex];
                    let cellWidth;
                    if (cellElement['width']) {
                        cellWidth = ((cellElement['width'] / totalWidth) * 100) + '%';
                    }
                    columnWidth.push(cellElement['width'] ? cellWidth : '*');
                }
                console.log(`Redudancy Column Width: `, columnWidth);
                let redundancyGrpTable = await this.createARedundancyGroup(rowElement, columnWidth);
                mytableDataShow = {        
                    table: {
                        widths: redundancyGrpTable.width,
                        body: redundancyGrpTable['table']
                    },
                    margin : margins
                }
                console.log(`Redu Grp Table: `, mytableDataShow);
                
            // }
        }
        return mytableDataShow;
    }


    async createARedundancyGroup(myTableData, columns?) {
        let myWidthCol = [];
        for(let x = 0; x < myTableData[0].length; x++) {
            myWidthCol.push('*');
        }
        if (columns && columns.length > 0) {
            // myWidthCol = await this.tableWidthCalcArray(columns);
            myWidthCol = columns;
        }

        let myblankData = [];
        for (let s = 0; s < myTableData.length; s++) {
            let mycolumn = [];
            for (let t = 0; t < myWidthCol.length; t++) {
                mycolumn.push({ text: " " });
            }
            myblankData.push(mycolumn);
        }
        for (let s = 0; s < myTableData.length; s++) {
            let columnsData = [];
            for (let t = 0; t < myTableData[s].length; t++) {
                if (myTableData[s][t].rowspan > 1) {
                    let mytotalRowspan = myTableData[s][t].rowspan;
                    for (let u = 1; u < mytotalRowspan; u++) {
                        myblankData[s + u][t].activation = false;
                    }
                    if (myTableData[s][t].colspan > 1) {
                        let mytotalColspan = myTableData[s][t].colspan;
                        for (let u = 1; u < mytotalRowspan; u++) {
                            for (let v = 1; v < mytotalColspan; v++) {
                                myblankData[s + u][t + v].activation = false;
                            }
                        }
                    }
                }
                if (myTableData[s][t].colspan > 1) {
                    let mytotalColspan = myTableData[s][t].colspan;
                    while (myblankData[s][t].activation == false) {
                        t++;
                    }
                    for (let u = 1; u < mytotalColspan; u++) {
                        myblankData[s][t + u].activation = false;
                    }
                }
            }
        }
        //

        for (let s = 0; s < myTableData.length; s++) {
            let myNewColSpan = 0;
            for (let t = 0; t < myTableData[s].length; t++) {
                let myObj: any;

                myObj = {
                    text: (myTableData[s][t].value && myTableData[s][t].value != undefined)? myTableData[s][t].value : " ",
                    fillColor: (s == 0 && (myTableData[s][t].headerColor == "" || myTableData[s][t].headerColor == undefined)) ? "#007aa0" : myTableData[s][t].headerColor, //DT-343
                    colSpan: myTableData[s][t].colspan,
                    rowSpan: myTableData[s][t].rowspan,
                    bold: (myTableData[s][t].style == '1' || myTableData[s][t].style == '3' || myTableData[s][t].title) ? true : false,
                    italic: (myTableData[s][t].style == '2' || myTableData[s][t].style == '3') ? true : false,
                    // color: (myTableData[s][t].headerColor && myTableData[s][t].headerColor != undefined) ? "#ffffff" : "#000000",
                    color: (myTableData[s][t].headerColor && myTableData[s][t].headerColor != undefined || (myTableData[s][t].headerColor == "" && s == 0)) ? "#ffffff" : "#000000", //DT-343
                };

                let myCurrentCol = t + myNewColSpan; //0 1 2
                let myAddition = myNewColSpan; //0 
                while (myblankData[s][myCurrentCol].activation == false) {
                    myCurrentCol++;
                    myNewColSpan++;
                }
                if (myblankData[s][myCurrentCol] && myblankData[s][myCurrentCol].text) {
                    myblankData[s][myCurrentCol] = myObj;
                }
            }
        }

        return {
            table: myblankData,
            width: myWidthCol
        }
    }

    async getTableObj(body: any, widths: any, layout?: any, margins?: any, absolutePos?: any, heights?: any, relativePos?: any): Promise<any> {
        let defaultLayout = {
          hLineColor: ReportConst.COLOR_HEX_GREY,
          vLineColor: ReportConst.COLOR_HEX_GREY,
          hLineWidth: function (i, node) {
            return 0.40;
          },
          vLineWidth: function (i, node) {
            return 0.40;
          }
        };
        let defaultMargin = undefined;
        let defaultAbsolutePos = undefined;
        let defaultRelativePos = undefined;
    
        let tableObj = {
          table: {
            widths: widths ? widths : undefined,
            heights: heights ? heights : undefined,
            body: body
          },
          layout: layout === undefined ? defaultLayout : layout,
          margin: (margins === undefined && absolutePos && relativePos) ? defaultMargin : margins,
          absolutePosition: (absolutePos === undefined && margins && relativePos) ? defaultAbsolutePos : absolutePos,
          relativePosition: (relativePos === undefined && absolutePos && margins) ? defaultRelativePos : relativePos,
        }
    
        return tableObj;
    }
    
    addContentText(content: any, style: Array<string>, alignment?: string, absolutePos?: any, relativePos?: any, bold?: boolean, italics?: boolean, width?: any, lineHeight?: any) {
        let addContent = {
          text: content,
          style: style,
          alignment: alignment ? alignment : undefined,
          absolutePosition: absolutePos ? absolutePos : undefined,
          relativePosition: relativePos ? relativePos : undefined,
          bold: (bold != undefined || bold != false) ? bold : undefined,
          italics: (italics != undefined || italics != false) ? italics : undefined,
          width: width ? width : undefined,
          lineHeight: lineHeight ? lineHeight : undefined,
        }
    
        return addContent;
    }
    /* pdfmake Code end */
    async createMytestTable(testCases) {
        let myContent: any;
        let myInsertedData = await this.createAtable(testCases.row_sections[0].rows, false);
        myContent = {
            style: 'section',
            table: {
                widths: myInsertedData.width,
                body: myInsertedData.table
            },
        }

        let MytablePage = [];
        MytablePage = MytablePage.concat(myContent);
        return MytablePage;
    }

    async createAtable(myTableData, child, TableTitle?, columns?) {
        let myWidthCol = ['20%', '40%', '40%'];
        if (child) {
            if(columns && columns.length > 0) {
                myWidthCol = await this.tableWidthCalcArray(columns);
            }
            else
                myWidthCol = await this.tableWidthCalcArray(myTableData[0]);
        }
        let mytitleColspan = myWidthCol.length;

        let myblankData = [];
        for (let s = 0; s < myTableData.length; s++) {
            let mycolumn = [];
            for (let t = 0; t < myWidthCol.length; t++) {
                mycolumn.push({ text: " " });
            }
            myblankData.push(mycolumn);
        }
        for (let s = 0; s < myTableData.length; s++) {
            let columnsData = [];
            for (let t = 0; t < myTableData[s].length; t++) {
                if (myTableData[s][t].rowSpan > 1) {
                    let mytotalRowspan = myTableData[s][t].rowSpan;
                    for (let u = 1; u < mytotalRowspan; u++) {
                        myblankData[s + u][t].activation = false;
                    }
                    if (myTableData[s][t].colSpan > 1) {
                        let mytotalColspan = myTableData[s][t].colSpan;
                        for (let u = 1; u < mytotalRowspan; u++) {
                            for (let v = 1; v < mytotalColspan; v++) {
                                myblankData[s + u][t + v].activation = false;
                            }
                        }
                    }
                }
                if (myTableData[s][t].colSpan > 1) {
                    let mytotalColspan = myTableData[s][t].colSpan;
                    while (myblankData[s][t].activation == false) {
                        t++;
                    }
                    for (let u = 1; u < mytotalColspan; u++) {
                        myblankData[s][t + u].activation = false;
                    }
                }
            }
        }
        //

        for (let s = 0; s < myTableData.length; s++) {
            let myNewColSpan = 0;
            for (let t = 0; t < myTableData[s].length; t++) {
                let myObj: any;
                if (StringUtils.isBlank(myTableData[s][t].name) && StringUtils.isNotBlank(myTableData[s][t].imageKey)) {
                    const jpgImageBytes = await fetch(myTableData[s][t].imageKey).then((res) => (res.arrayBuffer()));
                    myObj = {
                        image: jpgImageBytes,
                        width: (ReportConst.TABLE_IMAGE_SIZE.width / (myTableData[s].length > 2 ? myTableData[s].length : 1)) * myTableData[s][t].colSpan,
                        height: ReportConst.TABLE_IMAGE_SIZE.height,
                        alignment: 'center',
                        colSpan: myTableData[s][t].colSpan,
                        rowSpan: myTableData[s][t].rowSpan,
                    }
                }
                else {
                    myObj = {
                        text: (myTableData[s][t].title ? myTableData[s][t].title : myTableData[s][t].name) + " ",
                        fillColor: myTableData[s][t].backgroundColor,
                        colSpan: myTableData[s][t].colSpan,
                        rowSpan: myTableData[s][t].rowSpan,
                        bold: (myTableData[s][t].style == '1' || myTableData[s][t].style == '3' || myTableData[s][t].title) ? true : false,
                        italic: (myTableData[s][t].style == '2' || myTableData[s][t].style == '3') ? true : false,
                        color: (myTableData[s][t].backgroundColor && myTableData[s][t].backgroundColor != undefined) ? "#ffffff" : "#000000",
                    };
                }

                if (myTableData[s][t].tabelData) {
                    myObj = [];
                    let newObj: any = {};
                    for (let u = 0; u < myTableData[s][t].tabelData.length; u++) {
                        let myInsertedData = await this.createAtable(myTableData[s][t].tabelData[u].row_sections[0].rows, true, myTableData[s][t].tabelData[u].title, myTableData[s][t].tabelData[u].columns);

                        myObj = {
                            style: 'section',
                            colSpan: myTableData[s][t].colSpan,
                            table: {
                                widths: myInsertedData.width,
                                body: myInsertedData.table
                            },
                        }
                        // myObj.push(newObj);

                    }

                }
                let myCurrentCol = t + myNewColSpan; //0 1 2
                let myAddition = myNewColSpan; //0 
                while (myblankData[s][myCurrentCol].activation == false) {
                    myCurrentCol++;
                    myNewColSpan++;
                }
                if (myblankData[s][myCurrentCol] && myblankData[s][myCurrentCol].text) {
                    myblankData[s][myCurrentCol] = myObj;
                }
            }
        }
        if (TableTitle) {
            let myNewObj = [];
            for (let s = 0; s < mytitleColspan; s++) {
                myNewObj.push({});
            }
            myNewObj[0] = {
                text: TableTitle + " ",
                colSpan: mytitleColspan,
                rowSpan: 1,
                bold: true,
                italic: true,
                alignment: 'center',
                fillColor: ReportConst.COLOR_HEX_BLUE,
                color: '#ffffff'
            };
            myblankData.unshift(myNewObj);
        }

        return {
            table: myblankData,
            width: myWidthCol
        }
    }

    async tableWidthCalcArray(innerColumns) {
        let width = [];
        for (let s = 0; s < innerColumns.length; s++) {
            let innerRow2: any = innerColumns[s];
            // let mywidth = (!isNaN(innerRow2.width)) ? Math.floor(innerRow2.width * 100) + '%' : 'auto';
            let mywidth = (!isNaN(innerRow2.width)) ? innerRow2.width * 100 + '%' : 'auto';
            width.push(mywidth);
        }
        return width;
    }

    addTableOfContent(pdfDoc: PDFDocument): number {
        let page = pdfDoc.getPage(3);//Can be 4 also
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        let contentText = ReportConst.REPORT_MSGS.CONTENTS;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.HEADER, false, true);
        cursorY = this.addIndent(cursorY);
        let pageIndex = 4;
        for (let index = 0; index < this.tableOfContents.length; index++) {
            const element = this.tableOfContents[index];
            if (cursorY < ReportConst.MARGIN_TOP_2) {
                if (pageIndex <= 4) {
                    page = pdfDoc.getPage(pageIndex);//Can be 4 also
                }
                else {
                    page = pdfDoc.insertPage(pageIndex);
                }
                pageIndex++;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
            }
            cursorY = this.addIndexLine(page, cursorY, element.srNo, element.name, element.pageNo + "", element.isTitle, element.isAppendix);
        }
        return pageIndex;
    }

    async addHeaderLogo(pdfDoc: PDFDocument) {
        const jpgImageBytes = await fetch(ReportConst.BV_LOGO_BLUE_PATH).then((res) => res.arrayBuffer());
        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);
        for (let index = 1; index < pdfDoc.getPageCount(); index++) {
            let addHeader = true;
            if (this.skipFooterPages.indexOf(index) > -1) {
                addHeader = false;
            }
            if (addHeader) {
                const page = pdfDoc.getPage(index);
                page.drawImage(jpgImage, {
                    x: page.getWidth() - ReportConst.HEADER_LOGO_SIZE.width,
                    y: page.getHeight() - ReportConst.HEADER_LOGO_SIZE.height,
                    width: ReportConst.HEADER_LOGO_SIZE.width,
                    height: ReportConst.HEADER_LOGO_SIZE.height,
                })
            }
        }
    }

    async addRevDocFooter(pdfDoc: PDFDocument, year: string, titleName: string, docNo: string, rev: string, extraDocInfoPages: number) {
        let cursorY = ReportConst.MARGIN_DEFAULT;
        let nameRatio = 0.44;
        let docNoRatio = 0.28;
        let revRatio = 0.14;
        let pageRatio = 0.14;
        for (let index = 2 + extraDocInfoPages; index < pdfDoc.getPageCount(); index++) {
            let addFooter = true;
            if (this.skipFooterPages.indexOf(index) > -1) {
                addFooter = false;
            }
            if (!addFooter) {
                continue;
            }
            const page = pdfDoc.getPage(index);
            cursorY = ReportConst.MARGIN_DEFAULT;
            let startPoint: Point = { x: ReportConst.REPORT_CONTENT_START_OFFSET_1, y: cursorY };
            let endPoint: Point = { x: page.getWidth() - ReportConst.REPORT_CONTENT_START_OFFSET_1, y: cursorY };
            this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, startPoint, endPoint, ReportConst.FOOTER_BORDER_WIDTH);
            let contentWidth = page.getWidth() - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_1 - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_2;

            let cursorX = ReportConst.REPORT_CONTENT_START_OFFSET_1 + ReportConst.REPORT_CONTENT_START_OFFSET_2;
            cursorY = cursorY - 1.5 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);
            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "DP Solution - Annual Trials " + year, false, ReportConst.COLOR_BLUE_BV, ReportConst.CONTENT_FONT_SIZE_2, this.boldFont, false);
            cursorY = cursorY - 2.5 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);


            let centerTextPosY = cursorY + this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2) / 2;
            let lineYStart = cursorY - 0.4 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);
            let lineYEnd = cursorY + 2.2 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);

            let subsectionWidth = contentWidth * nameRatio;
            // this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
            //     clientName + " - " + vesselName, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, false);//20102021 centertext to false
            // true, this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);
            this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
                titleName, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, false);
            cursorX = cursorX + subsectionWidth;

            this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, { x: cursorX, y: lineYStart }, { x: cursorX, y: lineYEnd }, ReportConst.TABLE_BORDER_WIDTH_NORMAL);

            subsectionWidth = contentWidth * docNoRatio;
            this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
                "Project No: " + docNo, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, true,
                this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);
            cursorX = cursorX + subsectionWidth;

            this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, { x: cursorX, y: lineYStart }, { x: cursorX, y: lineYEnd }, ReportConst.TABLE_BORDER_WIDTH_NORMAL);

            subsectionWidth = contentWidth * revRatio;
            this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
                "Rev " + rev, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, true,
                this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);
            cursorX = cursorX + subsectionWidth;

            this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, { x: cursorX, y: lineYStart }, { x: cursorX, y: lineYEnd }, ReportConst.TABLE_BORDER_WIDTH_NORMAL);

            subsectionWidth = contentWidth * pageRatio;
            this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
                (index + 1) + "/" + pdfDoc.getPageCount(), false, ReportConst.COLOR_GREY, ReportConst.PAGE_NO_FONT_SIZE, this.regularFont, true,
                this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);



        }
    }

    async addProvFindingsHeaderLogo(pdfDoc: PDFDocument) {
        const jpgImageBytes = await fetch(ReportConst.BV_LOGO_BLACK_PATH).then((res) => res.arrayBuffer());
        const jpgImage = await pdfDoc.embedPng(jpgImageBytes);
        for (let index = 0; index < pdfDoc.getPageCount(); index++) {
            const page = pdfDoc.getPage(index);
            page.drawImage(jpgImage, {
                x: ReportConst.HEADER_LOGO_OFFSET.x,//page.getWidth() - ReportConst.HEADER_LOGO_SIZE.width,
                y: page.getHeight() - ReportConst.HEADER_LOGO_SIZE.height - ReportConst.HEADER_LOGO_OFFSET.y,
                width: ReportConst.HEADER_LOGO_SIZE.width,
                height: ReportConst.HEADER_LOGO_SIZE.height,
            })

        }
    }

    async addProvFindDocFooter(pdfDoc: PDFDocument) {
        let cursorY = ReportConst.MARGIN_DEFAULT;
        const jpgImageBytes = await fetch(ReportConst.DPS_LOGO_BLUE_PATH).then((res) => res.arrayBuffer());
        const jpgImage = await pdfDoc.embedPng(jpgImageBytes);

        for (let index = 0; index < pdfDoc.getPageCount(); index++) {
            const page = pdfDoc.getPage(index);
            cursorY = ReportConst.MARGIN_DEFAULT;
            // let startPoint: Point = { x: ReportConst.REPORT_CONTENT_START_OFFSET_1, y: cursorY };
            // let endPoint: Point = { x: page.getWidth() - ReportConst.REPORT_CONTENT_START_OFFSET_1, y: cursorY };
            // this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, startPoint, endPoint, ReportConst.FOOTER_BORDER_WIDTH);
            let contentWidth = page.getWidth() - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_1 - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_2;

            cursorY = cursorY - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_2;

            let imageX = page.getWidth() - ReportConst.HEADER_LOGO_SIZE.width - ReportConst.MARGIN_IMAGE_1;
            let imageY = ReportConst.HEADER_LOGO_SIZE.height / 2;
            page.drawImage(jpgImage, {
                x: imageX,
                y: imageY,
                width: ReportConst.FOOTER_LOGO_SIZE.width,
                height: ReportConst.FOOTER_LOGO_SIZE.height,
            })
            imageY = imageY - ReportConst.CAPTION_FONT_SIZE
            this.pdfUtils.addParagraph(page, ReportConst.HEADER_LOGO_SIZE.width + ReportConst.MARGIN_IMAGE_1, imageX, imageY,
                "FOR THE BUSINESS AHEAD.",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.PAGE_NO_FONT_SIZE, this.boldFont, false);
            imageY = imageY - ReportConst.CAPTION_FONT_SIZE
            this.pdfUtils.addParagraph(page, ReportConst.HEADER_LOGO_SIZE.width + ReportConst.MARGIN_IMAGE_1, imageX, imageY,
                "www.bvsolutions-m-o.com",
                false, ReportConst.COLOR_BLACK, ReportConst.CAPTION_FONT_SIZE, this.boldFont, false);


            let cursorX = ReportConst.MARGIN_DEFAULT_LOW;

            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "BUREAU VERITAS SOLUTIONS MARINE AND OFFSHORE UK",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.FOOTER_FONT_SIZE, this.boldFont, false);
            cursorY = cursorY - 1.1 * this.boldFont.heightAtSize(ReportConst.FOOTER_FONT_SIZE);
            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "Pavilion One - Craigshaw Business Park - Craigshaw Road - Aberdeen - AB12 3AR - Scotland UK",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.FOOTER_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - 1.1 * this.regularFont.heightAtSize(ReportConst.FOOTER_FONT_SIZE);
            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "Tél. : +44 (0) 1224 577070 - Email: info@mac-l.com / www.mac-l.com",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.FOOTER_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - 1.1 * this.regularFont.heightAtSize(ReportConst.FOOTER_FONT_SIZE);
            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "Registered in Scotland",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.FOOTER_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - 1.1 * this.regularFont.heightAtSize(ReportConst.FOOTER_FONT_SIZE);
            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "Registered No. SC380777",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.FOOTER_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - 1.1 * this.regularFont.heightAtSize(ReportConst.FOOTER_FONT_SIZE);
            this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                "VAT registered 725 4402 54",
                false, ReportConst.COLOR_BLUE_BV, ReportConst.FOOTER_FONT_SIZE, this.regularFont, false);

            // "BUREAU VERITAS SOLUTIONS MARINE AND OFFSHORE UK"
            // "Pavilion One - Craigshaw Business Park - Craigshaw Road - Aberdeen - AB12 3AR - Scotland UK"
            // "Tél. : +44 (0) 1224 577070 - Email: info@mac-l.com / www.mac-l.com"
            // "Registered in Scotland"
            // "Registered No. SC380777"
            // "VAT registered 725 4402 54"


            // let cursorX = ReportConst.REPORT_CONTENT_START_OFFSET_1 + ReportConst.REPORT_CONTENT_START_OFFSET_2;
            // cursorY = cursorY - 1.5 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);
            // this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            //     "DP Solution - Annual Trials " + year, false, ReportConst.COLOR_BLUE_BV, ReportConst.CONTENT_FONT_SIZE_2, this.boldFont, false);
            // cursorY = cursorY - 2.5 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);


            // let centerTextPosY = cursorY + this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2) / 2;
            // let lineYStart = cursorY - 0.4 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);
            // let lineYEnd = cursorY + 2.2 * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2);

            // let subsectionWidth = contentWidth * nameRatio;
            // this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
            //     clientName + " - " + vesselName, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, true,
            //     this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);
            // cursorX = cursorX + subsectionWidth;

            // this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, { x: cursorX, y: lineYStart }, { x: cursorX, y: lineYEnd }, ReportConst.TABLE_BORDER_WIDTH_NORMAL);

            // subsectionWidth = contentWidth * docNoRatio;
            // this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
            //     "Document No. " + docNo, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, true,
            //     this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);
            // cursorX = cursorX + subsectionWidth;

            // this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, { x: cursorX, y: lineYStart }, { x: cursorX, y: lineYEnd }, ReportConst.TABLE_BORDER_WIDTH_NORMAL);

            // subsectionWidth = contentWidth * revRatio;
            // this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
            //     "Rev " + rev, false, ReportConst.COLOR_GREY, ReportConst.CONTENT_FONT_SIZE_2, this.regularFont, true,
            //     this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);
            // cursorX = cursorX + subsectionWidth;

            // this.pdfUtils.drawLine(page, ReportConst.COLOR_BLUE_BV, { x: cursorX, y: lineYStart }, { x: cursorX, y: lineYEnd }, ReportConst.TABLE_BORDER_WIDTH_NORMAL);

            // subsectionWidth = contentWidth * pageRatio;
            // this.pdfUtils.addParagraph(page, subsectionWidth, cursorX, cursorY,
            //     (index+1) + "/" + pdfDoc.getPageCount(), false, ReportConst.COLOR_GREY, ReportConst.PAGE_NO_FONT_SIZE, this.regularFont, true,
            //     this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2), cursorX, centerTextPosY);



        }
    }

    getCurrentDate() {
        return this.appManager.getFormattedTimeStamp(this.appManager.getCurrentDate(), Constants.DATE_FORMAT.DATE_4_ANGULAR);
        // return "2020/08/25";//TODO
    }

    getTableWorkingPage(pdfDoc: PDFDocument, currentPage: PDFPage, cursorY: number, extraHeight?: number, landscape?: boolean) {
        let newPage: NewPage = new NewPage();
        newPage.page = currentPage;
        newPage.isNew = false;
        newPage.cursorY = cursorY;
        if (!extraHeight) {
            extraHeight = 0;
        }
        if (cursorY - ReportConst.MIN_TABLE_HEIGHT_HEADER - extraHeight < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE) {
            if (landscape) { //DT-324 bug fixed
                newPage.page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
            }
            else {
                newPage.page = pdfDoc.addPage();
            }
            // newPage.page = pdfDoc.addPage(); // Before DT-324
            newPage.isNew = true;
            newPage.cursorY = newPage.page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        this.logger.debug("getTableWorkingPage: ", cursorY + " newPage:" + newPage.isNew + " newPage.cursorY:" + newPage.cursorY);
        return newPage;
    }

    getWorkingPage(pdfDoc: PDFDocument, currentPage: PDFPage, cursorY: number, nextText: string, width: number, fontSize: number, font: PDFFont, fixedLines?: number, topMargin?: number, landscape?: boolean,
        additionalMargin?: number, bottomMargin?: number): NewPage {
        let lines = 0;
        let newPage: NewPage = new NewPage();
        if (StringUtils.isNotBlank(nextText)) {
            nextText = this.pdfUtils.cleanText(nextText, true);
            try {
                lines = this.pdfUtils.parseLines(nextText, width, fontSize, font, false, false, true).length;
            } catch (error) {
                this.logger.error("getWorkingPage: nextText:" + nextText + " error:", error);
                lines = 1;
            }

        }
        else if (fixedLines && fixedLines > 0) {
            lines = fixedLines;
        }
        if (!additionalMargin) {
            additionalMargin = 2 * font.heightAtSize(fontSize)
        }
        if (!bottomMargin) {
            bottomMargin = ReportConst.MARGIN_BOTTOM;
        }
        let minRequiredSpace = lines * font.heightAtSize(fontSize) + additionalMargin + bottomMargin;

        if (cursorY <= minRequiredSpace) {
            if (!topMargin || topMargin < 0) {
                topMargin = ReportConst.MARGIN_TOP_2;
            }
            if (landscape) {
                newPage.page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
            }
            else
                newPage.page = pdfDoc.addPage();
            newPage.isNew = true;
            newPage.cursorY = newPage.page.getHeight() - topMargin;
        }
        else {
            newPage.page = currentPage;
            newPage.isNew = false;
            newPage.cursorY = cursorY;
        }
        return newPage;
    }

    addFindings(pdfDoc: PDFDocument, currentPage: PDFPage, cursorY: number, findings: any = [], topMargin: number): NewPage {
        let page = currentPage;
        let newPage: NewPage = new NewPage();
        newPage.cursorY = cursorY;
        newPage.page = currentPage;
        for (let index = 0; index < findings.length; index++) {
            // let page = currentPage; //DT-332 Bug fixed
            let finding: any = findings[index];
            let srNoX = ReportConst.MARGIN_DEFAULT_LOW;
            let srNoWidth = ReportConst.REPORT_CONTENT_SRNO_WIDTH_2;
            let closedWidth = 1.5 * ReportConst.REPORT_CONTENT_SRNO_WIDTH;
            let contentX = srNoX + srNoWidth + ReportConst.REPORT_CONTENT_START_OFFSET_2;
            let contentWidth = page.getWidth() - contentX - ReportConst.REPORT_CONTENT_START_OFFSET_1;

            let testNumber = "-";
            if (finding.testNumber) {
                testNumber = "" + finding.testNumber;
            }
            let stepNumber = "-";
            if (finding.stepNumber || finding.stepNumber == 0) {
                stepNumber = 1 + finding.stepNumber + "";
            }

            let content = sprintf(ReportConst.REPORT_MSGS.F_TEST_NO_STEP_NO, testNumber, finding.testName, stepNumber, finding.testDesc);
            if (finding.isAdditionalFinding) {
                content = sprintf(ReportConst.REPORT_MSGS.F_ADDITIONAL_FINDING, finding.testName, finding.testDesc);
            }

            let workPage = this.getWorkingPage(pdfDoc, page, cursorY, content, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                // cursorY = page.getHeight() - topMargin; //DT-332 Bug fixed
                cursorY = topMargin;
                newPage.isNew = workPage.isNew;
            }
            // cursorY = page.getHeight() - (page.getHeight() - 150);
            let lines = this.pdfUtils.addParagraph(page, srNoWidth, srNoX, cursorY,
                index + 1 + ".", false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);//Manoj: Centertext true missing other parts

            lines = this.pdfUtils.addParagraph(page, contentWidth, contentX, cursorY,
                content, false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = this.pdfUtils.updateCursorY(cursorY, lines, this.regularFont, ReportConst.CONTENT_FONT_SIZE);


            if (finding.testStatus == "Closed") {
                let widthClose = page.getWidth() - srNoX - closedWidth - ReportConst.REPORT_CONTENT_START_OFFSET_1;

                lines = this.pdfUtils.addParagraph(page, closedWidth, srNoX, cursorY,
                    ReportConst.REPORT_MSGS.CLOSED_C, false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);

                lines = this.pdfUtils.addParagraph(page, widthClose, srNoX + closedWidth, cursorY,
                    this.appManager.getFormattedTimeStamp(finding.closureDate, Constants.DATE_FORMAT.DATE_4_ANGULAR), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
                cursorY = this.pdfUtils.updateCursorY(cursorY, lines, this.regularFont, ReportConst.CONTENT_FONT_SIZE);

                let workPage = this.getWorkingPage(pdfDoc, page, cursorY, finding.closeOut, page.getWidth() - ReportConst.REPORT_CONTENT_START_OFFSET_1, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
                if (workPage.isNew) {
                    page = workPage.page;
                    // cursorY = page.getHeight() - topMargin; //DT-332 Bug fixed
                    cursorY = topMargin;
                    newPage.isNew = workPage.isNew;
                }
                lines = this.pdfUtils.addParagraph(page, page.getWidth() - ReportConst.REPORT_CONTENT_START_OFFSET_1, srNoX, cursorY,
                    finding.closeOut, false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
                cursorY = this.pdfUtils.updateCursorY(cursorY, lines, this.regularFont, ReportConst.CONTENT_FONT_SIZE);
            }
            cursorY = this.pdfUtils.updateCursorY(cursorY, lines, this.regularFont, ReportConst.CONTENT_FONT_SIZE);//Newly added 19092020
        }
        newPage.page = page;
        newPage.cursorY = cursorY;
        return newPage;
    }

    async addEndOfTrial(pdfDoc: PDFDocument, data: any) {
        let page = pdfDoc.addPage();
        let cursorX = ReportConst.MARGIN_DEFAULT_LOW;
        let topMargin = page.getHeight() - ReportConst.MARGIN_TOP_3;
        let cursorY = topMargin;
        let contentWidth = page.getWidth() - 2 * cursorX;

        this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            this.getCurrentDate(), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - 2 * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            sprintf(ReportConst.REPORT_MSGS.F_OUR_REFERENCE, data.annualTrial.bvsReportNo), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - 4 * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            data.clientInfo.clientName, false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - 7 * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        let subject = sprintf(ReportConst.REPORT_MSGS.F_SUB_END_OF_TRIALS, data.vessel.vesselName, data.annualTrial.trialYear);//this.appManager.getFormattedTimeStamp(data.annualTrial.startDateTrial, Constants.DATE_FORMAT.DATE_4_ANGULAR));
        let lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            subject.toUpperCase(), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (lines + 2) * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);


        let onboardUsers = this.dataService.getListOfOnboardPersons(data.annualTrial);
        let onboardUsersString = "";
        if (onboardUsers.length > 0) {
            onboardUsersString = StringUtils.getReadStringForList(onboardUsers);
        }
        else {
            onboardUsers = [];
        }

        let clientCoordObjs = data.clientInfo.clientTrialsCoordinator;
        let clientCoords = [];
        if (clientCoordObjs && clientCoordObjs.length > 0) {
            clientCoordObjs.forEach(element => {
                if (element && StringUtils.isNotBlank(element.clientTrials)) {
                    clientCoords.push(element.clientTrials.trim());
                }
            });
        }

        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            sprintf(ReportConst.REPORT_MSGS.F_EOD_P1, data.vessel.vesselType, data.vessel.vesselName, data.clientInfo.clientRepresentative),
            true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - (lines + 1) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        // this.logger.debug("addEndOfTrial: data.annualTrial: ",data.annualTrial);

        if (data.annualTrial.startDateTrial == data.annualTrial.endDateTrial) {
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                sprintf(ReportConst.REPORT_MSGS.F_EOD_P2_B, onboardUsersString, StringUtils.getEmptyForNull(data.annualTrial.portJoining), StringUtils.getEmptyForNull(data.annualTrial.trialsLocation), StringUtils.getEmptyForNull(data.annualTrial.startDateTrial, " "),//this.appManager.getFormattedTimeStamp(data.annualTrial.startDateTrial, Constants.DATE_FORMAT.DATE_4_ANGULAR),
                    StringUtils.getEmptyForNull(data.annualTrial.titleTrialsDoc)),
                true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        }
        else {
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                sprintf(ReportConst.REPORT_MSGS.F_EOD_P2, onboardUsersString, StringUtils.getEmptyForNull(data.annualTrial.portJoining), StringUtils.getEmptyForNull(data.annualTrial.trialsLocation), StringUtils.getEmptyForNull(data.annualTrial.startDateTrial, " "),//this.appManager.getFormattedTimeStamp(data.annualTrial.startDateTrial, Constants.DATE_FORMAT.DATE_4_ANGULAR),
                    StringUtils.getEmptyForNull(data.annualTrial.endDateTrial, " "), StringUtils.getEmptyForNull(data.annualTrial.titleTrialsDoc)),
                true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        }

        cursorY = cursorY - (lines + 1) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        /* lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            sprintf(ReportConst.REPORT_MSGS.F_EOD_P3, StringUtils.getReadStringForList(clientCoords), onboardUsersString),
            true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false); */
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY, //DT-338 Trial Coordinator issues fixed
            sprintf(ReportConst.REPORT_MSGS.F_EOD_P3_1, onboardUsersString),
            true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - (lines + 1) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        let equipmentClass = "";
        if (data.vesselMainParticulars) {
            equipmentClass = "" + data.vesselMainParticulars.dpEquipmentClass;
        }
        let noAFindings: boolean = true;
        if (data.findings) {
            if (data.findings.aFindings && data.findings.aFindings.length > 0) {
                noAFindings = false;
                lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                    sprintf(ReportConst.REPORT_MSGS.F_EOD_P6, data.vessel.vesselName, equipmentClass),
                    true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
                cursorY = cursorY - (lines + 2) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

                lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                    ReportConst.REPORT_MSGS.FOR_IMMD_ATTENTION,
                    false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
                cursorY = cursorY - (lines + 1) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

                let workPage = this.addFindings(pdfDoc, page, cursorY, data.findings.aFindings, topMargin);
                cursorY = workPage.cursorY;
                if (workPage.isNew) {
                    page = workPage.page;
                }
                cursorY = this.pdfUtils.updateCursorY(cursorY, 2, this.regularFont, ReportConst.CONTENT_FONT_SIZE);
            }
        }
        if (noAFindings) {
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                ReportConst.REPORT_MSGS.EOD_P4,
                true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - (lines + 1) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

            let nextText = sprintf(ReportConst.REPORT_MSGS.F_EOD_P5, data.vessel.vesselName, equipmentClass);
            let workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
            }
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                nextText,
                true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = this.pdfUtils.updateCursorY(cursorY, (lines + 2), this.regularFont, ReportConst.CONTENT_FONT_SIZE);
        }

        let nextText = ReportConst.REPORT_MSGS.FULL_REPORT_TO_FOLLOW;
        let workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
        if (workPage.isNew) {
            page = workPage.page;
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        }
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            nextText,
            false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - (lines + 3) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        nextText = ReportConst.REPORT_MSGS.ON_BEAHLF_OF_BVS;
        workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
        if (workPage.isNew) {
            page = workPage.page;
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        }
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            nextText,
            false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - (lines + 3) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        for (let index = 0; index < onboardUsers.length; index++) {
            nextText = onboardUsers[index];
            workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
            }
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                nextText,
                false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - (lines + 3) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        }

    }

    async addProvFindings(pdfDoc: PDFDocument, data: any) {
        let page = pdfDoc.addPage();
        let cursorX = ReportConst.MARGIN_DEFAULT_LOW;
        let topMargin = page.getHeight() - ReportConst.MARGIN_TOP_3;
        let cursorY = topMargin;
        let contentWidth = page.getWidth() - 2 * cursorX;

        this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            this.getCurrentDate(), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - 2 * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            sprintf(ReportConst.REPORT_MSGS.F_OUR_REFERENCE, data.annualTrial.bvsReportNo), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - 4 * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            data.clientInfo.clientName, false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - 7 * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        let subject = sprintf(ReportConst.REPORT_MSGS.F_SUBJECT_PROV_FINDINGS, data.vessel.vesselName, data.annualTrial.trialYear);//this.appManager.getFormattedTimeStamp(data.annualTrial.startDateTrial, Constants.DATE_FORMAT.DATE_4_ANGULAR));
        let lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            subject.toUpperCase(), false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (lines + 2) * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);


        let onboardUsers = this.dataService.getListOfOnboardPersons(data.annualTrial);
        let onboardUsersString = "";
        if (onboardUsers.length > 0) {
            onboardUsersString = StringUtils.getReadStringForList(onboardUsers);
        }
        else {
            onboardUsers = [];
        }

        if (data.annualTrial.startDateTrial == data.annualTrial.endDateTrial) {
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                sprintf(ReportConst.REPORT_MSGS.F_PF_P1_B, data.vessel.vesselName, StringUtils.getEmptyForNull(data.annualTrial.trialsLocation), StringUtils.getEmptyForNull(data.annualTrial.startDateTrial, " "),//this.appManager.getFormattedTimeStamp(data.annualTrial.startDateTrial, Constants.DATE_FORMAT.DATE_4_ANGULAR),
                    StringUtils.getEmptyForNull(data.annualTrial.titleTrialsDoc)),
                true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        }
        else {
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                sprintf(ReportConst.REPORT_MSGS.F_PF_P1, data.vessel.vesselName, StringUtils.getEmptyForNull(data.annualTrial.trialsLocation), StringUtils.getEmptyForNull(data.annualTrial.startDateTrial, " "),//this.appManager.getFormattedTimeStamp(data.annualTrial.startDateTrial, Constants.DATE_FORMAT.DATE_4_ANGULAR),
                    StringUtils.getEmptyForNull(data.annualTrial.endDateTrial, " "), StringUtils.getEmptyForNull(data.annualTrial.titleTrialsDoc)),
                true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        }

        cursorY = cursorY - (lines + 3) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        /////////////////////////A
        let nextText = ReportConst.REPORT_MSGS.CAT_A_PF_TITLE;
        let workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.boldFont);
        if (workPage.isNew) {
            page = workPage.page;
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        }
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            nextText,
            false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (lines + 1) * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        let noAFindings: boolean = true;
        if (data.findings) {
            if (data.findings.aFindings && data.findings.aFindings.length > 0) {
                noAFindings = false;
                workPage = this.addFindings(pdfDoc, page, cursorY, data.findings.aFindings, topMargin);
                cursorY = workPage.cursorY;
                if (workPage.isNew) {
                    page = workPage.page;
                }
                cursorY = this.pdfUtils.updateCursorY(cursorY, 2, this.regularFont, ReportConst.CONTENT_FONT_SIZE);
            }
        }
        if (noAFindings) {
            nextText = ReportConst.REPORT_MSGS.NO_FINDINGS_FOR_CATEGORY;
            workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
            }
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                nextText,
                false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = this.pdfUtils.updateCursorY(cursorY, (lines + 2), this.regularFont, ReportConst.CONTENT_FONT_SIZE);
        }


        ///////////////////////
        /////////////////////////A
        nextText = ReportConst.REPORT_MSGS.CAT_B_PF_TITLE;
        workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.boldFont);
        if (workPage.isNew) {
            page = workPage.page;
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        }
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            nextText,
            false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (lines + 1) * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        let noBFindings: boolean = true;
        if (data.findings) {
            if (data.findings.bFindings && data.findings.bFindings.length > 0) {
                noBFindings = false;
                workPage = this.addFindings(pdfDoc, page, cursorY, data.findings.bFindings, topMargin);
                cursorY = workPage.cursorY;
                if (workPage.isNew) {
                    page = workPage.page;
                }
                cursorY = this.pdfUtils.updateCursorY(cursorY, 2, this.regularFont, ReportConst.CONTENT_FONT_SIZE);
            }
        }
        if (noBFindings) {
            nextText = ReportConst.REPORT_MSGS.NO_FINDINGS_FOR_CATEGORY;
            workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
            }
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                nextText,
                false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = this.pdfUtils.updateCursorY(cursorY, (lines + 2), this.regularFont, ReportConst.CONTENT_FONT_SIZE);
        }
        ///////////////////////
        /////////////////////////A
        nextText = ReportConst.REPORT_MSGS.CAT_C_PF_TITLE;
        workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.boldFont);
        if (workPage.isNew) {
            page = workPage.page;
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        }
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            nextText,
            false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (lines + 1) * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        let noCFindings: boolean = true;
        if (data.findings) {
            if (data.findings.cFindings && data.findings.cFindings.length > 0) {
                noCFindings = false;
                workPage = this.addFindings(pdfDoc, page, cursorY, data.findings.cFindings, topMargin);
                cursorY = workPage.cursorY;
                if (workPage.isNew) {
                    page = workPage.page;
                }
                cursorY = this.pdfUtils.updateCursorY(cursorY, 2, this.regularFont, ReportConst.CONTENT_FONT_SIZE);
            }
        }
        if (noCFindings) {
            nextText = ReportConst.REPORT_MSGS.NO_FINDINGS_FOR_CATEGORY;
            workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
            }
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                nextText,
                false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = this.pdfUtils.updateCursorY(cursorY, (lines + 2), this.regularFont, ReportConst.CONTENT_FONT_SIZE);
        }

        ///////////////////////
        nextText = ReportConst.REPORT_MSGS.ON_BEAHLF_OF_BVS;
        workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
        if (workPage.isNew) {
            page = workPage.page;
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        }
        lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
            nextText,
            false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - (lines + 3) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);

        for (let index = 0; index < onboardUsers.length; index++) {
            nextText = onboardUsers[index];
            workPage = this.getWorkingPage(pdfDoc, page, cursorY, nextText, contentWidth, ReportConst.CONTENT_FONT_SIZE, this.regularFont);
            if (workPage.isNew) {
                page = workPage.page;
                cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
            }
            lines = this.pdfUtils.addParagraph(page, contentWidth, cursorX, cursorY,
                nextText,
                false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
            cursorY = cursorY - (lines + 3) * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        }
        // let finalString = sprintf("%s %s!", "Hello", "Manoj");
        // this.pdfUtils.addParagraph(page, 100, 100, 100,
        //     finalString+" Page Size: "+page.getWidth()+" h:"+page.getHeight(), false, ReportConst.COLOR_BLACK, ReportConst.TITLE_FONT_SIZE_2, this.boldFont, false);
    }
    async addCoverPage(pdfDoc: PDFDocument, data: any) {
        const page = pdfDoc.addPage();
        let section2x = page.getWidth() * 0.6;
        let section2Width = page.getWidth() - section2x;
        let section2Rect: PDRectangle = { x: section2x, y: 0, width: section2Width, height: page.getHeight() };
        this.pdfUtils.drawRectangle(page, section2Rect, ReportConst.COLOR_BLUE_BV, 1, ReportConst.COLOR_BLUE_BV);

        let shipURL = "";//"assets/images/ship_photo_temp.jpg";    
        //let shipURL = "";//"assets/images/ship_photo_temp.jpg";
        // if (data.vesselImage && StringUtils.isNotBlank(data.vesselImage.remoteFileKey)) {        //manoj code
        //     shipURL = this.fileHandlerService.getRemoteFileURL(data.vesselImage.remoteFileKey);
        // }

        if (data.vesselImage && StringUtils.isNotBlank(data.vesselImage.remoteFileKey)) {
            if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
                shipURL = this.fileHandlerService.displayFileFromKey(data.vesselImage.remoteFileKey);
            } else {
                // if (data.vesselImage && StringUtils.isNotBlank(data.vesselImage.remoteFileKey)) {   //web code
                shipURL = this.fileHandlerService.getRemoteFileURL(data.vesselImage.remoteFileKey);
                // }
            }
        }


        await this.pdfUtils.drawImage(pdfDoc, page, { x: section2x + ReportConst.MARGIN_IMAGE_2, y: page.getHeight() - section2Width / 1.2, width: section2Width / 2.4, height: section2Width / 2.4 }, ReportConst.BV_LOGO_HEADER_PATH, ImageAspect.START);
        await this.pdfUtils.drawImage(pdfDoc, page, { x: section2x + ReportConst.MARGIN_IMAGE_2, y: +ReportConst.MARGIN_IMAGE_2, width: section2Width - 2 * ReportConst.MARGIN_IMAGE_2, height: section2Width - 2 * ReportConst.MARGIN_IMAGE_2 }, ReportConst.BV_LOGO_PATH, ImageAspect.START);
        if (StringUtils.isNotBlank(shipURL)) {
            await this.pdfUtils.drawImage(pdfDoc, page, { x: ReportConst.MARGIN_IMAGE_1, y: ReportConst.MARGIN_IMAGE_1, width: section2x - ReportConst.MARGIN_IMAGE_1 - 1, height: section2x - ReportConst.MARGIN_IMAGE_1 }, shipURL, ImageAspect.END);
        }

        let cursorY = page.getHeight() - section2Width * 1.1;
        let cursorA = cursorY;
        // this.pdfUtils.addParagraph(page, section2x - 2 * ReportConst.MARGIN_IMAGE_1, ReportConst.MARGIN_IMAGE_1, cursorA,
        //     ReportConst.REPORT_MSGS.DP_SOLUTION.toUpperCase(), false, ReportConst.COLOR_BLUE_BV, ReportConst.TITLE_FONT_SIZE_1, this.boldFont, false);

        //Left Side Client Name Generate code
        this.pdfUtils.addParagraph(page, section2x - 2 * ReportConst.MARGIN_IMAGE_1, ReportConst.MARGIN_IMAGE_1, cursorA,
            data.clientInfo.clientName.toUpperCase(), false, ReportConst.COLOR_BLACK, ReportConst.TITLE_FONT_SIZE_1, this.boldFont, false);
        // console.log("sudhansu",data);

        // let lines = this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
        //     data.clientInfo.clientName.toUpperCase(), false, ReportConst.COLOR_WHITE, ReportConst.TITLE_FONT_SIZE_2, this.boldFont, false);

        let lines = this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
            ReportConst.REPORT_MSGS.DP_SOLUTION_1.toUpperCase() + data.annualTrial.trialYear, false, ReportConst.COLOR_WHITE, ReportConst.TITLE_FONT_SIZE_0, this.boldFont, false);

        cursorY = cursorY - (ReportConst.NEW_LINE_FONT_SIZE_FACTOR_2 * lines * this.boldFont.heightAtSize(ReportConst.TITLE_FONT_SIZE_1) - ReportConst.LINE_INDENT_SUBTITLE_2);
        cursorA = cursorA - (ReportConst.NEW_LINE_FONT_SIZE_FACTOR_2 * this.boldFont.heightAtSize(ReportConst.TITLE_FONT_SIZE_1) - ReportConst.LINE_INDENT_SUBTITLE_2);
        // this.pdfUtils.addParagraph(page, section2x - 2 * ReportConst.MARGIN_IMAGE_1, ReportConst.MARGIN_IMAGE_1, cursorA,
        //     data.annualTrial.titleTrialsDoc.toUpperCase(), false, ReportConst.COLOR_BLACK, ReportConst.TITLE_FONT_SIZE_1, this.boldFont, false);

        //Left Side Vessel Name Generate code
        this.pdfUtils.addParagraph(page, section2x - 2 * ReportConst.MARGIN_IMAGE_1, ReportConst.MARGIN_IMAGE_1, cursorA,
            data.vesselName.toUpperCase(), false, ReportConst.COLOR_BLACK, ReportConst.TITLE_FONT_SIZE_1, this.boldFont, false);

        // this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
        //     data.vesselName.toUpperCase(), false, ReportConst.COLOR_WHITE, ReportConst.TITLE_FONT_SIZE_2, this.boldFont, false);

        cursorY = cursorY - 3 * this.boldFont.heightAtSize(ReportConst.TITLE_FONT_SIZE_2);

        lines = this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
            ReportConst.REPORT_MSGS.PRO_NO.toUpperCase() + " " + data.annualTrial.bvsProjectNo, false, ReportConst.COLOR_WHITE, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (ReportConst.NEW_LINE_FONT_SIZE_FACTOR * lines * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE)) - ReportConst.LINE_INDENT_SUBTITLE_2;

        lines = this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
            ReportConst.REPORT_MSGS.REP_NO.toUpperCase() + " " + data.annualTrial.bvsReportNo, false, ReportConst.COLOR_WHITE, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (ReportConst.NEW_LINE_FONT_SIZE_FACTOR * lines * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE)) - ReportConst.LINE_INDENT_SUBTITLE_2;

        lines = this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
            ReportConst.REPORT_MSGS.REV + data.revChar, false, ReportConst.COLOR_WHITE, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - (ReportConst.NEW_LINE_FONT_SIZE_FACTOR * lines * this.boldFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE)) - ReportConst.LINE_INDENT_SUBTITLE_2;

        lines = this.pdfUtils.addParagraph(page, section2Width - 1.5 * ReportConst.MARGIN_IMAGE_2, section2x + ReportConst.MARGIN_IMAGE_2, cursorY,
            this.appManager.getFormattedTimeStamp(new Date(), Constants.DATE_FORMAT.DATE_4_ANGULAR), false, ReportConst.COLOR_WHITE, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);


    }

    async addDocumentInfo(pdfDoc: PDFDocument, data: any) {
        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP;
        this.pdfUtils.addParagraph(page, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY,
            ReportConst.REPORT_MSGS.DOCUMENT_INFORMATION, false, ReportConst.COLOR_BLUE_BV, ReportConst.SUBTITLE_FONT_SIZE_2, this.boldFont, false);

        cursorY = cursorY - this.boldFont.heightAtSize(ReportConst.SUBTITLE_FONT_SIZE_2) - ReportConst.PARA_INDENT;

        this.pdfUtils.addParagraph(page, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY,
            ReportConst.REPORT_MSGS.REV_HISTORY, false, ReportConst.COLOR_BLUE_BV, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
        cursorY = cursorY - this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);// - ReportConst.LINE_INDENT_SUBTITLE;

        let pages = [pdfDoc.getPageCount()];
        //add table
        let newPage = await this.addRevisionTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (newPage.isNew) {
            pages.push(pdfDoc.getPageCount());
        }

        cursorY = this.addIndent(cursorY, TextContentType.TABLE);
        if (data.revNumber > Constants.REV_B_VAL) {
            this.pdfUtils.addParagraph(page, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY,
                ReportConst.REPORT_MSGS.AMENDMENT_RECORD, false, ReportConst.COLOR_BLUE_BV, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
            cursorY = cursorY - this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);// - ReportConst.LINE_INDENT_SUBTITLE;
            //add table
            newPage = await this.addAmendmentTable(pdfDoc, page, cursorY, data);
            cursorY = newPage.cursorY;
            page = newPage.page;
            if (newPage.isNew) {
                pages.push(pdfDoc.getPageCount());
            }
        }

        for (let index = 0; index < pages.length; index++) {
            const page = pdfDoc.getPage(index + 1);
            let cursorY = 150;
            let lines = this.pdfUtils.addParagraph(page, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY,
                ReportConst.REPORT_MSGS.DISCLAIMER, false, ReportConst.COLOR_BLUE_BV, ReportConst.CONTENT_FONT_SIZE, this.boldFont, false);
            cursorY = cursorY - lines * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE) - ReportConst.LINE_INDENT_SUBTITLE;

            lines = this.pdfUtils.addParagraph(page, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY,
                ReportConst.REPORT_MSGS.DISCLAIMER_MSG, true, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        }

        return pages.length;
    }

    addAppendixPage(pdfDoc: PDFDocument, appendix: string, title: string) {
        const page = pdfDoc.addPage();
        let cursorY = page.getHeight() / 2 - ReportConst.SUBTITLE_FONT_SIZE / 2;
        this.addReportIndex(appendix, title, pdfDoc.getPageCount(), false, 1);
        this.pdfUtils.addParagraph(page, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY,
            appendix + " - " + title, false, ReportConst.COLOR_BLUE_BV, ReportConst.SUBTITLE_FONT_SIZE, this.boldFont, true,
            this.boldFont.heightAtSize(ReportConst.SUBTITLE_FONT_SIZE), ReportConst.MARGIN_DEFAULT, cursorY);
    }

    addExecutiveSummary(pdfDoc: PDFDocument, data: any) {
        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        let contentText = ReportConst.REPORT_MSGS.EXECUTIVE_SUMMARY;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.HEADER, false, true);
        cursorY = this.addIndent(cursorY);

        let equipmentClass = "";
        if (data.vesselMainParticulars) {
            equipmentClass = "" + data.vesselMainParticulars.dpEquipmentClass;
        }

        contentText = sprintf(ReportConst.REPORT_MSGS.F_EXECUTIVE_SUMMARY_INTRO_1, StringUtils.getEmptyForNull(data.clientInfo.clientRepresentative, " "), StringUtils.getEmptyForNull(data.clientInfo.clientPosition, " "),
            StringUtils.getEmptyForNull(data.clientInfo.clientName, " "), StringUtils.getEmptyForNull(data.vesselName, " "));
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        cursorY = this.addIndent(cursorY);

        contentText = sprintf(ReportConst.REPORT_MSGS.F_EXECUTIVE_SUMMARY_INTRO_2, StringUtils.getEmptyForNull(data.vesselName, " "), StringUtils.getEmptyForNull(equipmentClass, " "), StringUtils.getEmptyForNull(data.vessel.vesselType, " "),
            StringUtils.getEmptyForNull(data.vessel.deliveryYear, " "), StringUtils.getEmptyForNull(data.vessel.classSociety, " "), StringUtils.getEmptyForNull(data.vessel.classNotation, " "));
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        cursorY = this.addIndent(cursorY);

        // contentText = StringUtils.getEmptyForNull(data.vessel.classNotation, " ");
        // cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        // cursorY = this.addIndent(cursorY);

        cursorY = this.addIndent(cursorY);
        cursorY = this.addIndent(cursorY);

        contentText = ReportConst.REPORT_MSGS.DP_POWER_REDUN;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.SUB_HEADER, false, true);
        cursorY = this.addIndent(cursorY);

        contentText = data.vesselMainParticulars.dpPowerSystemRedundancyConcept;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addIndent(cursorY);

        contentText = ReportConst.REPORT_MSGS.WCFDI_TITLE;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.SUB_HEADER, false, true);
        cursorY = this.addIndent(cursorY);

        contentText = data.vesselMainParticulars.wcfdi;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        contentText = ReportConst.REPORT_MSGS.COMPLIANCE;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.SUB_HEADER, false, true);
        cursorY = this.addIndent(cursorY);

        let master = this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel ? data.envKeyPersonnel.keyPersonnels : "", KeyPersonnelRole.VESSEL_MASTER);

        let onboardUsersString = this.dataService.getActualTrialRespPersonNames(data.annualTrial);//14102021: this.dataService.getOnboardPersonNames(data.annualTrial);
        if (onboardUsersString.length <= 0) {//20102021:
            onboardUsersString = this.dataService.getOnboardPersonNames(data.annualTrial);
        }

        //Sudhanshu Chaubey: Feb 2022: Change done in onboard trial compliance msg: Start
        if (data.annualTrial.startDateTrial && data.annualTrial.endDateTrial && data.annualTrial.startDateTrial != data.annualTrial.endDateTrial) {
            contentText = sprintf(ReportConst.REPORT_MSGS.F_COMPLIANCE_MSG, data.trialYear, data.annualTrial.startDateTrial, data.annualTrial.endDateTrial,
                data.annualTrial.trialsLocation, StringUtils.isNotBlank(master) ? master : onboardUsersString);
        }
        else if (data.annualTrial.startDateTrial == data.annualTrial.endDateTrial) {
            contentText = sprintf(ReportConst.REPORT_MSGS.F_COMPLIANCE_MSG_B, data.trialYear, data.annualTrial.startDateTrial,
                data.annualTrial.trialsLocation, StringUtils.isNotBlank(master) ? master : onboardUsersString);
        }
        else {
            contentText = sprintf(ReportConst.REPORT_MSGS.F_COMPLIANCE_MSG_B_1, data.trialYear,
                data.annualTrial.trialsLocation, StringUtils.isNotBlank(master) ? master : onboardUsersString);
        }
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addIndent(cursorY);
        //Sudhanshu Chaubey: Feb 2022: Change done in onboard trial compliance msg: End

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        contentText = sprintf(data.findingsAPresent ? ReportConst.REPORT_MSGS.F_COMPLIANCE_MSG_2 : ReportConst.REPORT_MSGS.F_COMPLIANCE_MSG_1, data.vesselName, equipmentClass);
        cursorY = this.addContent(pdfDoc, page, cursorY, "", contentText, TextContentType.PARA, false, true);
        cursorY = this.addIndent(cursorY);
        if (!data.onboard) {
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.TRIALS_NOT_PERFORMED, TextContentType.PARA, false, false, FontAlign.CENTER, FontStyle.ITALIC);
            cursorY = this.addIndent(cursorY);
        }

    }



    async addIntroduction(pdfDoc: PDFDocument, data: any) {
        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        let contentText = ReportConst.REPORT_MSGS.INTRODUCTION;
        let srNo = "1.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.HEADER);
        cursorY = this.addIndent(cursorY);
        // this.pdfUtils.contentFitsInPage(page, cursorY, contentText, );
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), true);
        contentText = "Instructions";
        srNo = "1.1";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "1.1.1";
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_1_1, data.clientInfo.clientRepresentative, data.clientInfo.clientPosition,
            data.clientInfo.clientName, data.vesselName, data.trialYear);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);


        srNo = "1.2";
        contentText = "Scope of Work";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "1.2.1";
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_2_1, data.annualTrial.proposalRefId);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "1.3";
        contentText = "Conduct of Work";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "1.3.1";
        let prevReportNo = ""
        if (StringUtils.isNotBlank(data.annualTrial.prevReportNo)) {
            prevReportNo = " (" + data.annualTrial.prevReportNo + ")";
        }
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_3_1, prevReportNo);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        let master = this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel ? data.envKeyPersonnel.keyPersonnels : "", KeyPersonnelRole.VESSEL_MASTER);

        let onboardUsersString = this.dataService.getActualTrialRespPersonNames(data.annualTrial);//14102021: this.dataService.getOnboardPersonNames(data.annualTrial);
        if (onboardUsersString.length <= 0) {//20102021:
            onboardUsersString = this.dataService.getOnboardPersonNames(data.annualTrial);
        }
        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        // let datesTrial = this.dataService.getTrialStartEndDates(data);
        srNo = "1.3.2";
        if (data.annualTrial.startDateTrial == data.annualTrial.endDateTrial) {
            contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_3_2_B, data.trialYear, data.annualTrial.startDateTrial, data.annualTrial.trialsLocation,
                onboardUsersString);//master, onboardUsersString);
        }
        else
            contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_3_2, data.trialYear, data.annualTrial.startDateTrial, data.annualTrial.endDateTrial, data.annualTrial.trialsLocation,
                onboardUsersString);//master, onboardUsersString);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "1.4";
        contentText = "Applicable Rules and Guidelines";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "1.4.1";
        contentText = ReportConst.REPORT_MSGS.F_SEC_1_4_1;//sprintf(ReportConst.REPORT_MSGS.F_SEC_1_4_1, data.vesselMainParticulars.imoCircular, data.vesselMainParticulars.classSocietyRules);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addContent(pdfDoc, page, cursorY, ReportConst.REPORT_MSGS.BULLET_POINT, StringUtils.getEmptyForNull(data.vesselMainParticulars.imoCircular, " "), TextContentType.PARA, true, false, null, null, 0, false, true);
        cursorY = this.addContent(pdfDoc, page, cursorY, ReportConst.REPORT_MSGS.BULLET_POINT, StringUtils.getEmptyForNull(data.vesselMainParticulars.classSocietyRules, " "), TextContentType.PARA, true, false, null, null, 0, false, true);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "1.4.2";
        contentText = ReportConst.REPORT_MSGS.F_SEC_1_4_2;//sprintf(ReportConst.REPORT_MSGS.F_SEC_1_4_2, data.annualTrial.industryGuideDoc);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);
        let guideDocs = [];
        if (StringUtils.isNotBlank(data.annualTrial.industryGuideDoc)) {
            guideDocs = data.annualTrial.industryGuideDoc.split("\n");
        }
        for (let g = 0; g < guideDocs.length; g++) {
            let element = guideDocs[g];
            if (StringUtils.isNotBlank(element)) {
                element = element.replace(ReportConst.REPORT_MSGS.BULLET_POINT, "");//TODO: Manoj to be tested
                cursorY = this.addContent(pdfDoc, page, cursorY, ReportConst.REPORT_MSGS.BULLET_POINT, element, TextContentType.PARA, true, false, null, null, 0, false, true);
                if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
                    page = pdfDoc.addPage();
                    cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                }
                // cursorY = this.addIndent(cursorY);    
            }
        }

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "1.4.3";
        contentText = data.vesselMainParticulars.imoCircular == Constants.IMO_CIRCULAR_645_1994 ? ReportConst.REPORT_MSGS.SEC_1_4_3 : ReportConst.REPORT_MSGS.SEC_1_4_4;
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "";
        contentText = data.vesselMainParticulars.imoCircular == Constants.IMO_CIRCULAR_645_1994 ? ReportConst.REPORT_MSGS.NOTE_99 : ReportConst.REPORT_MSGS.NOTE_100;
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA, false, false, FontAlign.LEFT, FontStyle.ITALIC, ReportConst.CONTENT_FONT_SIZE_2);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        if (StringUtils.isNotBlank(data.vesselMainParticulars.provingTrialsCompletionDate)) {
            srNo = "1.4.4";
            contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_4_5, this.appManager.getFormattedTimeStamp(data.vesselMainParticulars.provingTrialsCompletionDate, Constants.DATE_FORMAT.DATE_4_ANGULAR));
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
            cursorY = this.addIndent(cursorY);
        }


        //Add new page
        // page = pdfDoc.addPage();
        // cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "1.5";
        contentText = "Environmental Conditions during Trials";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "1.5.1";
        contentText = "Environmental conditions throughout the trials period were as shown in Table 1-1.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "";
        contentText = "Table 1-1" + ReportConst.CONTENT_SPACE + " Environmental Conditions during Trials";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);
        // cursorY = this.addIndent(cursorY);
        //add table
        let newPage = await this.addEnvCondtionTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.envKeyPersonnel && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY, ReportConst.MARGIN_TABLE_EXTRA_TITLE);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "1.6";
        contentText = "Key Personnel in Attendance at Trials";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "1.6.1";
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_1_6_1, data.trialYear);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "";
        contentText = "Table 1-2" + ReportConst.CONTENT_SPACE + "Key Personnel in Attendance at Trials";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);
        // cursorY = this.addIndent(cursorY);
        //add table
        newPage = await this.addKeyPersonnelAttendTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.envKeyPersonnel && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }


    }

    async addVesselParticulars(pdfDoc: PDFDocument, data: any, isImage?: boolean, vpSize?: number) {

        let page;
        let cursorY;
        let contentText;
        let srNo;
        let itemRevision = this.afterItemRevision;
        let newPage;

        contentText = "Vessel Particulars";
        srNo = "2.";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount() - vpSize, true);

        srNo = "2.1";
        contentText = "General";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount() - vpSize, false);

        if(!isImage) { // This part will we create by pdfMake
            let page = pdfDoc.addPage();
            let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

            let contentText = "Vessel Particulars";
            let srNo = "2.";
            this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), true);
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.HEADER);
            cursorY = this.addIndent(cursorY);

            srNo = "2.1";
            contentText = "General";
            this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
            cursorY = this.addIndent(cursorY);

            let equipmentClass = "";
            if (data.vesselMainParticulars) {
                equipmentClass = "" + data.vesselMainParticulars.dpEquipmentClass;
            }

            srNo = "2.1.1";
            contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_1_1, data.vesselName, equipmentClass, data.vessel.vesselType,
                data.vessel.builderName, data.vessel.deliveryYear);
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
            cursorY = this.addIndent(cursorY);

            let itemRevision = 2;
            if (StringUtils.isNotBlank(data.vesselMainParticulars.addDescription)) {
                srNo = "2.1.2";
                itemRevision++;
                contentText = data.vesselMainParticulars.addDescription;
                cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
                cursorY = this.addIndent(cursorY);
            }


            srNo = "2.1." + itemRevision;
            itemRevision++;
            contentText = data.vesselMainParticulars.dpPowerSystemRedundancyConcept;//"DP Power System Redundancy Concept.";
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
            cursorY = this.addIndent(cursorY);

            let newPage = this.getTableWorkingPage(pdfDoc, page, cursorY, ReportConst.MARGIN_TABLE_EXTRA_TITLE);
            cursorY = newPage.cursorY;
            page = newPage.page;

            srNo = "2.1." + itemRevision;
            itemRevision++;
            contentText = "Major power system equipment is allocated as shown in Table 2-1 and arranged as shown in Table 2-1.";
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
            cursorY = this.addIndent(cursorY);

            srNo = "";
            contentText = "Table 2-1" + ReportConst.CONTENT_SPACE + "Allocation of Equipment to DP Redundancy Groups";
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);
            cursorY = this.addIndent(cursorY);

            // srNo = "";
            // contentText = "Name of the redundancy group";
            // cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA, false, true, FontAlign.CENTER);

            //add table
            newPage = await this.addRedundancyGroupTable(pdfDoc, page, cursorY, data);
            cursorY = newPage.cursorY;
            page = newPage.page;
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
        

            let figNo = 1;
            //add Image
            if (data.vesselDpRedundancyGroupsImage) {
                if (StringUtils.isNotBlank(data.vesselDpRedundancyGroupsImage.remoteFileKey)) {
                    //let fileURL = this.fileHandlerService.getRemoteFileURL(data.vesselDpRedundancyGroupsImage.remoteFileKey);
                    let fileURL = "";
                    if (cursorY < (ReportConst.MARGIN_TOP_2 + ReportConst.CONTENT_IMAGE_MIN_SIZE.height)) {
                        page = pdfDoc.addPage();
                        cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2 - ReportConst.CONTENT_IMAGE_MIN_SIZE.height;
                    }
                    //await this.pdfUtils.drawImage(pdfDoc, page, { x: ReportConst.CONTENT_IMAGE_X, y: cursorY, width:  ReportConst.CONTENT_IMAGE_MIN_SIZE.width, height: ReportConst.CONTENT_IMAGE_MIN_SIZE.height }, fileURL, ImageAspect.CENTER); //Manoj Code

                    if (this.isCordovaAvailable && !this.connectivityService.isConnected()) { //to uncomment Yash
                        fileURL = this.fileHandlerService.displayFileFromKey(data.vesselDpRedundancyGroupsImage.remoteFileKey);
                    } else {
                        // if (data.vesselImage && StringUtils.isNotBlank(data.vesselImage.remoteFileKey)) {   //web code
                        fileURL = this.fileHandlerService.getRemoteFileURL(data.vesselDpRedundancyGroupsImage.remoteFileKey);
                        // }
                    }

                    await this.pdfUtils.drawImage(pdfDoc, page, { x: ReportConst.CONTENT_IMAGE_X, y: cursorY, width: ReportConst.CONTENT_IMAGE_MIN_SIZE.width, height: ReportConst.CONTENT_IMAGE_MIN_SIZE.height }, fileURL, ImageAspect.CENTER);

                    cursorY = this.addIndent(cursorY);
                    cursorY = this.addIndent(cursorY);

                    srNo = "";
                    contentText = ReportConst.REPORT_MSGS.FIG_2_1;//"Figure 2-1" + ReportConst.CONTENT_SPACE + "Overview of DP Redundancy Groups";
                    figNo++;
                    cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);
                    cursorY = this.addIndent(cursorY);
                    cursorY = this.addIndent(cursorY);
                    cursorY = this.addIndent(cursorY);
                }
            }
        }

        // let itemRevision = 4;
        //2 images on new pages 
        let figNo = this.afterFigNo;
        let gaImgKey = "";
        let powerSystemImgKey = "";
        if (data.vesselGeneralArrangementImage) {
            gaImgKey = data.vesselGeneralArrangementImage.remoteFileKey;
            if (StringUtils.isNotBlank(gaImgKey)) {
                srNo = "2.1." + itemRevision;
                itemRevision++;
                contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_1_4, figNo + "", data.vesselName);//"Figure 2 2 shows the general arrangement of VESSEL NAME.";
                await this.addImagePage(pdfDoc, true, sprintf(ReportConst.REPORT_MSGS.F_FIG_2_2, figNo + "", data.vesselName), gaImgKey, "", srNo, contentText);
                figNo++;
                // cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
                // cursorY = this.addIndent(cursorY);

            }
        }
        if (data.vesselPowerSystemImage) {
            powerSystemImgKey = data.vesselPowerSystemImage.remoteFileKey;
            if (StringUtils.isNotBlank(powerSystemImgKey)) {
                srNo = "2.1." + itemRevision;
                itemRevision++;
                contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_1_5, figNo + "", data.vesselName);//"Figure 2 3 shows a simplified overview of the VESSEL NAME power system.";
                await this.addImagePage(pdfDoc, true, sprintf(ReportConst.REPORT_MSGS.FIG_2_3, figNo + ""), powerSystemImgKey, "", srNo, contentText);
                figNo++;
                // cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
                // cursorY = this.addIndent(cursorY);
            }
        }
        // if (StringUtils.isNotBlank(gaImgKey)) {
        //     await this.addImagePage(pdfDoc, true, sprintf(ReportConst.REPORT_MSGS.F_FIG_2_2, figNo+"", data.vesselName), gaImgKey);
        // }
        // if (StringUtils.isNotBlank(powerSystemImgKey)) {
        //     await this.addImagePage(pdfDoc, true, ReportConst.REPORT_MSGS.FIG_2_3, figNo+"", powerSystemImgKey);
        // }



        page = pdfDoc.addPage();
        cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        srNo = "2.1." + itemRevision;
        itemRevision++;
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_1_6, data.vesselName);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "";
        contentText = "Table 2-2" + ReportConst.CONTENT_SPACE + "General Details & Main Particulars";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);

        //add table
        newPage = await this.addGenDetailsTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY, ReportConst.MARGIN_TABLE_EXTRA_TITLE);
        cursorY = newPage.cursorY;
        page = newPage.page;

        srNo = "2.1." + itemRevision;
        itemRevision++;
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_1_7, data.vesselName);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "";
        contentText = "Table 2-3" + ReportConst.CONTENT_SPACE + "Main DP Equipment";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);

        //add table
        // XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX TODO REmove New page
        newPage = await this.addMainDPEquipTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);


        // page = pdfDoc.addPage();
        // cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY, ReportConst.MARGIN_TABLE_EXTRA_TITLE);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "2.2";
        contentText = "Vessel Configuration for DP Operations";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "2.2.1";
        contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_2_2_1, data.vesselName);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "";
        contentText = "Table 2-4" + ReportConst.CONTENT_SPACE + "DP Configuration";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);

        //add table
        newPage = await this.addDPConfigTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "2.3";
        contentText = "Worst Case Failure Design Intent";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "2.3.1";
        contentText = data.vesselMainParticulars.wcfdi;
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        srNo = "2.4";
        contentText = "Upgrades since Last Annual DP Trials";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "2.4.1";
        contentText = data.annualTrial.upgradesSinceLastTrials;
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        // srNo = "1.2";
        // contentText = "Scope";
        // cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        // cursorY = this.addIndent(cursorY);

        // srNo = "1.2";
        // contentText = "Scope";
        // cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        // cursorY = this.addIndent(cursorY);
    }


    async addImagePage(pdfDoc: PDFDocument, landscape: boolean, caption: string, imageKey: string, fileName?: string, srNo?: string, contentTitle?: string) {
        let page: PDFPage = null;
        if (landscape) {
            page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
        }
        else
            page = pdfDoc.addPage();

        // let cursorY = ReportConst.MARGIN_TOP;
        let imageY = ReportConst.MARGIN_TOP;
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2 + 5;
        let contentTitleHeight = 0;
        if (StringUtils.isNotBlank(contentTitle)) {
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentTitle, TextContentType.PARA);
            // cursorY = this.addIndent(cursorY);
            contentTitleHeight = ReportConst.MARGIN_TOP_2 - ReportConst.MARGIN_TOP;
        }

        let fileURL = "assets/images/ship_photo_temp.jpg";
        if (StringUtils.isNotBlank(imageKey)) {
            //fileURL = this.fileHandlerService.getRemoteFileURL(imageKey); //manoj code
            if (this.isCordovaAvailable && !this.connectivityService.isConnected()) { //to uncomment Yash
                fileURL = this.fileHandlerService.displayFileFromKey(imageKey);
            } else {
                fileURL = this.fileHandlerService.getRemoteFileURL(imageKey);
            }

        } else if (fileName) {
            fileURL = this.fileHandlerService.displayFileFromFileName(fileName);
        }
        let imageRect: PDRectangle = { x: ReportConst.MARGIN_DEFAULT, y: imageY, width: page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT, height: page.getHeight() - 2 * ReportConst.MARGIN_TOP - contentTitleHeight };
        let updatedRect: PDRectangle = await this.pdfUtils.drawImage(pdfDoc, page, imageRect, fileURL, ImageAspect.CENTER);
        cursorY = updatedRect.y - ReportConst.SUBTITLE_FONT_SIZE;
        // cursorY = ReportConst.MARGIN_TOP - ReportConst.SUBTITLE_FONT_SIZE;
        cursorY = this.addContent(pdfDoc, page, cursorY, "", caption, TextContentType.CAPTION);
    }

    addDPTrialsProgramme(pdfDoc: PDFDocument) {

        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        // let contentText = ReportConst.REPORT_MSGS.SEC_3;
        let srNo = "3.";
        this.addReportIndex(srNo, ReportConst.REPORT_MSGS.SEC_3, pdfDoc.getPageCount(), true);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3, TextContentType.HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "3.1";
        this.addReportIndex(srNo, ReportConst.REPORT_MSGS.SEC_3_1, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "1.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_1, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "2.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_2, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "3.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_3, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "4.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_4, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "5.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_5, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "6.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_6, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "7.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_7, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "8.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_8, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "9.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_9, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        srNo = "10.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_10, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

        page = pdfDoc.addPage();
        cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        srNo = "11.";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_3_1_11, TextContentType.PARA, true);
        cursorY = this.addIndent(cursorY);

    }

    addConclusions(pdfDoc: PDFDocument, data: any) {

        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        let contentText = ReportConst.REPORT_MSGS.SEC_4;
        let srNo = "4.";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), true);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "4.1";
        contentText = ReportConst.REPORT_MSGS.SEC_4_1;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "4.1.1";
        if (data.annualTrial.startDateTrial == data.annualTrial.endDateTrial) {
            contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_4_1_1_B, data.trialYear, data.vesselName, data.annualTrial.startDateTrial);
        }
        else
            contentText = sprintf(ReportConst.REPORT_MSGS.F_SEC_4_1_1, data.trialYear, data.vesselName, data.annualTrial.startDateTrial, data.annualTrial.endDateTrial);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "4.1.2";
        contentText = ReportConst.REPORT_MSGS.F_SEC_4_1_2;//sprintf(ReportConst.REPORT_MSGS.F_SEC_4_1_2, data.vesselMainParticulars.imoCircular, data.vesselMainParticulars.classSocietyRules);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addContent(pdfDoc, page, cursorY, ReportConst.REPORT_MSGS.BULLET_POINT, StringUtils.getEmptyForNull(data.vesselMainParticulars.imoCircular, " "), TextContentType.PARA, true, false, null, null, 0, false, true);
        cursorY = this.addContent(pdfDoc, page, cursorY, ReportConst.REPORT_MSGS.BULLET_POINT, StringUtils.getEmptyForNull(data.vesselMainParticulars.classSocietyRules, " "), TextContentType.PARA, true, false, null, null, 0, false, true);

        cursorY = this.addIndent(cursorY);

        let equipmentClass = "";
        if (data.vesselMainParticulars) {
            equipmentClass = "" + data.vesselMainParticulars.dpEquipmentClass;
        }

        srNo = "4.1.3";
        contentText = sprintf(data.findingsAPresent ? ReportConst.REPORT_MSGS.F_SEC_4_1_3_B : ReportConst.REPORT_MSGS.F_SEC_4_1_3_A, data.vesselName, equipmentClass);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);
        if (!data.onboard) {
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.TRIALS_NOT_PERFORMED, TextContentType.PARA, false, false, FontAlign.CENTER, FontStyle.ITALIC);
            cursorY = this.addIndent(cursorY);
        }

        srNo = "4.2";
        contentText = ReportConst.REPORT_MSGS.SEC_4_2;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "4.2.1";
        contentText = ReportConst.REPORT_MSGS.SEC_4_2_1;
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "4.3";
        contentText = ReportConst.REPORT_MSGS.SEC_4_3;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.3", ReportConst.REPORT_MSGS.SEC_4_3, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.3.1", data.trialConclusion ? data.trialConclusion.mMaintenance : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        srNo = "4.4";
        contentText = ReportConst.REPORT_MSGS.SEC_4_4;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.4", ReportConst.REPORT_MSGS.SEC_4_4, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.4.1", data.trialConclusion ? data.trialConclusion.dpMaintenance : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.5";
        contentText = ReportConst.REPORT_MSGS.SEC_4_5;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.5", ReportConst.REPORT_MSGS.SEC_4_5, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.5.1", data.trialConclusion ? data.trialConclusion.pGeneration : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.6";
        contentText = ReportConst.REPORT_MSGS.SEC_4_6;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.6", ReportConst.REPORT_MSGS.SEC_4_6, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.6.1", data.trialConclusion ? data.trialConclusion.pdistribution : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);


        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.7";
        contentText = ReportConst.REPORT_MSGS.SEC_4_7;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.7", ReportConst.REPORT_MSGS.SEC_4_7, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.7.1", data.trialConclusion ? data.trialConclusion.pManagement : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.8";
        contentText = ReportConst.REPORT_MSGS.SEC_4_8;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.8", ReportConst.REPORT_MSGS.SEC_4_8, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.8.1", data.trialConclusion ? data.trialConclusion.cLoops : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.9";
        contentText = ReportConst.REPORT_MSGS.SEC_4_9;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.9", ReportConst.REPORT_MSGS.SEC_4_9, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.9.1", data.trialConclusion ? data.trialConclusion.eSensors : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.10";
        contentText = ReportConst.REPORT_MSGS.SEC_4_10;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.10", ReportConst.REPORT_MSGS.SEC_4_10, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.10.1", data.trialConclusion ? data.trialConclusion.pReferences : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.11";
        contentText = ReportConst.REPORT_MSGS.SEC_4_11;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.11", ReportConst.REPORT_MSGS.SEC_4_11, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.11.1", data.trialConclusion ? data.trialConclusion.dpControl : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.12";
        contentText = ReportConst.REPORT_MSGS.SEC_4_12;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.12", ReportConst.REPORT_MSGS.SEC_4_12, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.12.1", data.trialConclusion ? data.trialConclusion.personnel : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

        if (cursorY - ReportConst.MIN_PARA_HEIGHT < ReportConst.MARGIN_BOTTOM) {
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }

        srNo = "4.13";
        contentText = ReportConst.REPORT_MSGS.SEC_4_13;
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, ReportConst.REPORT_MSGS.SEC_4_13, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);
        cursorY = this.addContent(pdfDoc, page, cursorY, "4.13.1", data.trialConclusion ? data.trialConclusion.incidents : ReportConst.REPORT_MSGS.PENDING_ONBOARD_SECTION, TextContentType.PARA, false, false, null, data.trialConclusion ? FontStyle.REGULAR : FontStyle.ITALIC);
        cursorY = this.addIndent(cursorY);

    }

    async addFindingsPage(pdfDoc: PDFDocument, data: any) {

        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        let contentText = "Findings";
        let srNo = "5.";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), true);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "5.1";
        contentText = "Categories";
        this.addReportIndex(srNo, contentText, pdfDoc.getPageCount(), false);
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        cursorY = this.addIndent(cursorY);

        srNo = "5.1.1";
        let imcaLatest = "IMCA M190";//, Rev.2.1, January 2020";
        contentText = "Following the conclusion of the DP Annual Trials, any findings or observations have been prioritised according to " + imcaLatest + " as follows:";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "";
        contentText = "•  Category ‘A’ – For Immediate Attention";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "";
        contentText = "•  Category ‘B’ – For Action When Reasonably Convenient";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        srNo = "";
        contentText = "•  Category ‘C’ – For Future Attention/Consideration";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);


        srNo = "5.1.2";
        contentText = "The findings from the DP Annual Trials are shown in Table 5-1" + (data.hasPreviousFindings ? " & 5-2." : ".");
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.PARA);
        cursorY = this.addIndent(cursorY);

        page = pdfDoc.addPage([page.getHeight(), page.getWidth()]);
        cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        srNo = "";
        contentText = "Table 5-1" + ReportConst.CONTENT_SPACE + "Trials Findings";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);

        let newPage = await this.addFindingsTable(pdfDoc, page, cursorY, data, false);
        cursorY = newPage.cursorY;
        page = newPage.page;

        if (!data.onboard) {
            let findinPresent = false;
            if (data.findings && (data.newFindings.aFindings.length > 0 || data.newFindings.bFindings.length > 0 || data.newFindings.cFindings.length > 0)) {
                findinPresent = true;
            }
            if (!findinPresent) {
                cursorY = this.addIndent(cursorY);
                cursorY = this.addIndent(cursorY);
                cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
            }

        }

        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        if (data.oldFindings) {
            newPage = this.getTableWorkingPage(pdfDoc, page, cursorY, 0, true); //DT-324 Issues Fix
            cursorY = newPage.cursorY;
            page = newPage.page;
            contentText = "Table 5-2" + ReportConst.CONTENT_SPACE + "Trials Findings from previous Annual Trials";
            cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);

            newPage = await this.addFindingsTable(pdfDoc, page, cursorY, data, true);
            cursorY = newPage.cursorY;
            page = newPage.page;
        }

    }


    async addFindingsDocPage(pdfDoc: PDFDocument, data: any, type = 'old') {
        this.logger.debug("addFindingsDocPage: START");
        let supportDocs: Array<Image | Doc> = [];
        let findingDescs: any = [];

        // if (data.testCasesExec && data.testCasesExec.length > 0) {
        //     for (let index = 0; index < data.testCasesExec.length; index++) {
        //         const testCaseExec = data.testCasesExec[index];
        //         if (testCaseExec) {
        //             if (testCaseExec.stepsData && testCaseExec.stepsData.length > 0) {
        //                 for (let i = 0; i < testCaseExec.stepsData.length; i++) {
        //                     let stepsData = testCaseExec.stepsData[i];
        //                     if (stepsData) {
        //                         if (stepsData.testProofDocIds && stepsData.testProofDocIds.length > 0) {
        //                             let obj: any = {};
        //                             let supportDocsOBJ: Array<Image | Doc> = [];
        //                             let testNumber = "-";
        //                             if (testCaseExec.testNumber) {
        //                                 testNumber = "" + testCaseExec.testNumber;
        //                             }
        //                             let stepNumber = "-";
        //                             // if (finding.stepNumber) {
        //                             stepNumber = 1 + i + "";
        //                             // }

        //                             obj.content = sprintf(ReportConst.REPORT_MSGS.F_TEST_NO_STEP_NO, testNumber, testCaseExec.testName, stepNumber, stepsData.testComments);
        //                             for (let j = 0; j < stepsData.testProofDocIds.length; j++) {
        //                                 let docId = stepsData.testProofDocIds[j];
        //                                 if (StringUtils.isNotBlank(docId)) {
        //                                     let doc = await this.fileHandlerService.imageDocService.getImageOrDoc(docId);
        //                                     if (doc) {
        //                                         supportDocs.push(doc);
        //                                         supportDocsOBJ.push(doc);
        //                                     }
        //                                 }
        //                             }
        //                             obj.supportDocs = supportDocsOBJ;
        //                             findingDescs.push(obj);
        //                         }
        //                     }
        //                 }
        //             }

        //         }
        //     }
        // }
        // this.logger.debug("addFindingsDocPage: DATA LOADED TESTCASEEXEC:", JSON.stringify(findingDescs));
        // this.logger.debug("addFindingsDocPage: DATA LOADED TESTCASEEXEC DOC:", JSON.stringify(supportDocs));
        if (data.findings) {
            let aSupportDocs = await this.dataService.getFindingAttachments(data.findings.aFindings);
            let bSupportDocs = await this.dataService.getFindingAttachments(data.findings.bFindings);
            let cSupportDocs = await this.dataService.getFindingAttachments(data.findings.cFindings);
            aSupportDocs.forEach(element => {
                if (element) {
                    findingDescs.push(element);
                    if (element.supportDocs && element.supportDocs.length > 0) {
                        element.supportDocs.forEach(doc => {
                            supportDocs.push(doc);
                        })
                    }
                }
                // supportDocs.push(element);
            });
            bSupportDocs.forEach(element => {
                if (element) {
                    findingDescs.push(element);
                    if (element.supportDocs && element.supportDocs.length > 0) {
                        element.supportDocs.forEach(doc => {
                            supportDocs.push(doc);
                        })
                    }
                }
                // supportDocs.push(element);
            });
            cSupportDocs.forEach(element => {
                if (element) {
                    findingDescs.push(element);
                    if (element.supportDocs && element.supportDocs.length > 0) {
                        element.supportDocs.forEach(doc => {
                            supportDocs.push(doc);
                        })
                    }
                }
                // supportDocs.push(element);
            });
        }
        this.logger.debug("addFindingsDocPage: DATA LOADED Findings:", JSON.stringify(findingDescs));
        this.logger.debug("addFindingsDocPage: DATA LOADED Findings DOC:", JSON.stringify(supportDocs));
        if (type == 'new' && 1) {
            return supportDocs;
        } else if (supportDocs.length > 0) {

            // let page = pdfDoc.addPage();
            // let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
            // let pageIndex = 0;
            // for (let index = 0; index < findingDescs.length; index++) {
            //     if (cursorY < ReportConst.MARGIN_TOP_2) {
            //         page = pdfDoc.addPage();
            //         cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
            //     }
            //     const obj = findingDescs[index];
            //     if (obj.supportDocs && obj.supportDocs.length > 0) {
            //         cursorY = this.addContent(pdfDoc, page, cursorY, "C." + (pageIndex + 1), obj.content, TextContentType.SUB_HEADER, false, false,
            //             null, null, -1, true);
            //         for (let x = 0; x < obj.supportDocs.length; x++) {
            //             if (cursorY < ReportConst.MARGIN_TOP_2) {
            //                 page = pdfDoc.addPage();
            //                 cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
            //             }
            //             const imageDoc = obj.supportDocs[x];
            //             cursorY = this.addContent(pdfDoc, page, cursorY, "", imageDoc.fileName, TextContentType.PARA, false, false, null, FontStyle.ITALIC);
            //         }
            //         cursorY = this.addIndent(cursorY);
            //         pageIndex++;
            //     }
            // }
            this.addAppendixPage(pdfDoc, "APPENDIX C", "FINDINGS & CLOSED OUT DETAILS ATTACHMENTS");
            let pageToBeAdded = pdfDoc.getPageCount();
            let pageObj = await this.addFileAttachments(pdfDoc, supportDocs, true, true);
            let pageNumbers = pageObj.pageNumbers
            let newPagesAdded = 0;

            let page = pdfDoc.insertPage(pageToBeAdded);
            let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
            let pageIndex = 0;
            let pageNoIndex = 0;
            for (let index = 0; index < findingDescs.length; index++) {
                if (cursorY < ReportConst.MARGIN_TOP_2) {
                    newPagesAdded++;
                    page = pdfDoc.insertPage(pageToBeAdded + newPagesAdded);
                    cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                }
                const obj = findingDescs[index];
                if (obj.supportDocs && obj.supportDocs.length > 0) {
                    cursorY = this.addContent(pdfDoc, page, cursorY, "C." + (pageIndex + 1), obj.content, TextContentType.SUB_HEADER, false, false,
                        null, null, -1, true);
                    for (let x = 0; x < obj.supportDocs.length; x++) {
                        if (cursorY < ReportConst.MARGIN_TOP_2) {
                            newPagesAdded++;
                            page = pdfDoc.insertPage(pageToBeAdded + newPagesAdded);
                            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                        }
                        const imageDoc = obj.supportDocs[x];
                        cursorY = this.addIndexLine(page, cursorY, (x + 1) + "", imageDoc.fileName, (pageNumbers[pageNoIndex] + newPagesAdded + 1) + "", false, 1, ReportConst.COLOR_BLACK);
                        // cursorY = this.addContent(pdfDoc, page, cursorY, "", imageDoc.fileName + "-----" + pageNumbers[pageNoIndex], TextContentType.PARA, false, false, null, FontStyle.ITALIC);
                        pageNoIndex++;
                    }
                    cursorY = this.addIndent(cursorY);
                    pageIndex++;
                }
            }
            newPagesAdded++;
            for (let index = 0; index < pageObj.skipPages.length; index++) {//HAD TO DO THIS AS FOOTER WAS OVERLAPPTING
                const element = pageObj.skipPages[index];
                this.skipFooterPages.push(element + newPagesAdded);
            }
        }
        this.logger.debug("addFindingsDocPage: END:", supportDocs);

    }

    async addtestCasePageProcess(pdfDoc: PDFDocument, mypdf: any, testCase: any, isTestCase: boolean = true) {
        if(isTestCase && testCase) {
            this.addReportIndex("Test No. " + StringUtils.getEmptyForNull(testCase.testNumber + "", " "), testCase.testName, pdfDoc.getPageCount() + 1, false, 2);
        }

        return await this.addFileAttachments(pdfDoc, [{remoteFileKey : mypdf, filename : 'Test Case'}], true, false, true);
    }

    async addSupportDocPage(pdfDoc: PDFDocument, data: any) {
        if (data.supportDocs && data.supportDocs.length > 0) {
            let page = pdfDoc.addPage();
            let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
            for (let index = 0; index < data.supportDocs.length; index++) {
                if (cursorY < ReportConst.MARGIN_TOP_2) {
                    page = pdfDoc.addPage();
                    cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                }
                const imageDoc = data.supportDocs[index];
                cursorY = this.addContent(pdfDoc, page, cursorY, "D." + (index + 1), imageDoc.displayFileName, TextContentType.SUB_HEADER, false, false,
                    null, null, -1, true);
                cursorY = this.addContent(pdfDoc, page, cursorY, "", imageDoc.fileName, TextContentType.PARA, false, false, null, FontStyle.ITALIC);
                cursorY = this.addIndent(cursorY);
            }
        }


        await this.addFileAttachments(pdfDoc, data.supportDocs);
    }

    //Array<Image | Doc>
    async addFileAttachments(pdfDoc: PDFDocument, imageDocs: Array<any>, ignoreFooter?: boolean, isFindings: boolean = false, isBlob: boolean = false) {
        let pageNumbers = [];
        let skipPages = [];
        if (imageDocs && imageDocs.length > 0) {
            for (let index = 0; index < imageDocs.length; index++) {
                const imageDoc = imageDocs[index];
                let docType = this.fileHandlerService.getDocType(imageDoc.fileName);
                if (docType == DocTypeEnum.IMAGE) {
                    if (StringUtils.isNotBlank(imageDoc.remoteFileKey)) {
                        try {
                            await this.addImagePage(pdfDoc, false, "", imageDoc.remoteFileKey);
                            pageNumbers.push(pdfDoc.getPageCount());
                        } catch (error) {
                            this.logger.error("addFileAttachments: IMAGE:", imageDoc)
                            this.logger.error("addFileAttachments: IMAGE error:", error)
                        }
                    } else if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
                        if (isFindings) {
                            try {
                                await this.addImagePage(pdfDoc, false, "", "", imageDoc.fileName);
                                pageNumbers.push(pdfDoc.getPageCount());
                            } catch (error) {
                                this.logger.error("addFileAttachments: IMAGE:", imageDoc)
                            }
                        }
                    }
                }
                else {
                    let beforeAdd = pdfDoc.getPageCount();
                    if (StringUtils.isNotBlank(imageDoc.remoteFileKey) || (isBlob && imageDoc.remoteFileKey)) {
                        try {
                            pageNumbers.push(pdfDoc.getPageCount() + 1);
                            await this.insertPDF(pdfDoc, imageDoc.remoteFileKey, '', isBlob);
                        } catch (error) {
                            this.logger.error("addFileAttachments: DOC:", imageDoc)
                            this.logger.error("addFileAttachments: DOC error:", error)
                        }
                    } else if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
                        if (isFindings || isBlob) {
                            try {
                                pageNumbers.push(pdfDoc.getPageCount() + 1);
                                await this.insertPDF(pdfDoc, imageDoc.remoteFileKey, imageDoc.fileName, isBlob); //PdfMake Change also need in mobile build
                            } catch (error) {
                                this.logger.error("addFileAttachments: DOC error:", error)
                            }
                        }
                    }




                    let afterAdd = pdfDoc.getPageCount();
                    for (let index = beforeAdd; index < afterAdd; index++) {
                        if (!ignoreFooter) {
                            this.skipFooterPages.push(index);
                        }
                        skipPages.push(index);
                    }
                }
            }
        }
        return { pageNumbers: pageNumbers, skipPages: skipPages };
    }

    async fetchPDF(fileKey: string, fileName?: string, isBlob : boolean = false) {
        let pdfBytes;
        if(!isBlob){
            let fileURL = "";//"assets/images/ship_photo_temp.jpg";
            if (StringUtils.isNotBlank(fileKey)) {
                //fileURL = this.fileHandlerService.getRemoteFileURL(fileKey);  //manoj code
                if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
                    fileURL = this.fileHandlerService.displayFileFromKey(fileKey);
                } else {
                    fileURL = this.fileHandlerService.getRemoteFileURL(fileKey);
                }
            } else if (fileName) {
                fileURL = this.fileHandlerService.displayFileFromFileName(fileName);
            }
            else
                return;
            pdfBytes = await fetch(fileURL).then(res => res.arrayBuffer());
        }else {
            pdfBytes = fileKey;
        }
        const pdfDoc = await PDFDocument.load(pdfBytes, { ignoreEncryption: true });
        return pdfDoc;
    }


    async insertPDF(pdfDoc: PDFDocument, fileKey: string, fileName?: string, isBlob ?: boolean) {
        // let fileURL = "";//"assets/images/ship_photo_temp.jpg";
        // if (StringUtils.isNotBlank(fileKey)) {
        //     fileURL = this.fileHandlerService.getRemoteFileURL(fileKey);
        // }
        // else
        //     return;
        // const pdfBytes = await fetch(fileURL).then(res => res.arrayBuffer());
        // const firstDonorPdfDoc = await PDFDocument.load(pdfBytes)

        //const firstDonorPdfDoc = await this.fetchPDF(fileKey);
        let firstDonorPdfDoc;
        if (StringUtils.isNotBlank(fileKey) || isBlob) {
            firstDonorPdfDoc = await this.fetchPDF(fileKey, '', isBlob);
        } else if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
            if (fileName) {
                firstDonorPdfDoc = await this.fetchPDF("", fileName);
            }
            else if(isBlob && fileKey) {
                firstDonorPdfDoc = await this.fetchPDF(fileKey, '', isBlob);
            }
        }
        let pages = [];
        if (firstDonorPdfDoc && firstDonorPdfDoc.getPageCount() > 0) {
            for (let index = 0; index < firstDonorPdfDoc.getPageCount(); index++) {
                const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [index])
                pdfDoc.addPage(firstDonorPage);
            }
        }
    }

    // async copyPages() {
    //     const url1 = 'https://pdf-lib.js.org/assets/with_update_sections.pdf'
    //     const url2 = 'https://pdf-lib.js.org/assets/with_large_page_count.pdf'

    //     const firstDonorPdfBytes = await fetch(url1).then(res => res.arrayBuffer())
    //     const secondDonorPdfBytes = await fetch(url2).then(res => res.arrayBuffer())

    //     const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes)
    //     const secondDonorPdfDoc = await PDFDocument.load(secondDonorPdfBytes)

    //     const pdfDoc = await PDFDocument.create();

    //     const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [0])
    //     const [secondDonorPage] = await pdfDoc.copyPages(secondDonorPdfDoc, [742])

    //     pdfDoc.addPage(firstDonorPage)
    //     pdfDoc.insertPage(0, secondDonorPage)

    //     const pdfBytes = await pdfDoc.save()
    //   }

    async addStatusReportPage(pdfDoc: PDFDocument, data: any) {
        const maxColsPerTable = 5;
        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        let srNo = "B.1";
        let contentText = "Thrusters";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        // let thursterCountDPConfig = StringUtils.getValueFromObjAny(data.vesselDpConfiguration, "thursterCount", 4);
        // let engineCountDPConfig = StringUtils.getValueFromObjAny(data.vesselDpConfiguration, "engineCount", 4);

        let thursterCountDPConfig = StringUtils.getValueFromObjAny(data.vesselDpConfiguration, "vThrusterNames", []);
        let engineCountDPConfig = StringUtils.getValueFromObjAny(data.vesselDpConfiguration, "vEngineNames", []);

        let thursterCount = thursterCountDPConfig.length;
        let engineCount = engineCountDPConfig.length;
        if (data.statusThrusters && data.statusThrusters.length > 0) {
            thursterCount = data.statusThrusters.length;
        }
        if (data.statusEngines && data.statusEngines.length > 0) {
            engineCount = data.statusEngines.length;
        }

        let tableCount = 1 + Math.floor(thursterCount / maxColsPerTable);
        let newPage = null;
        let maxCols = Math.ceil(thursterCount / tableCount);
        let tableIndex = 0;
        this.logger.debug("addStatusReportPage: THRUSTER ALL:", data.statusThrusters);
        for (let index = 0; index < tableCount; index++) {
            let count = (index == tableCount - 1) ? (thursterCount - (index * maxCols)) : maxCols;

            let filteredData = _.slice(data.statusThrusters, tableIndex, tableIndex + count);
            let filteredConfigs = _.slice(thursterCountDPConfig, tableIndex, tableIndex + count);
            this.logger.debug("addStatusReportPage: THRUSTER filtered:" + index + " DATA:", filteredData, " Configs:", filteredConfigs);

            newPage = await this.addThrusterTable(pdfDoc, page, cursorY, false, filteredData, count, tableIndex, filteredConfigs);
            cursorY = newPage.cursorY;
            page = newPage.page;

            if (!data.statusReport && !data.onboard) {
                cursorY = this.addIndent(cursorY);
                cursorY = this.addIndent(cursorY);
                cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
            }
            else {
                cursorY = this.addIndent(cursorY);
                cursorY = this.addIndent(cursorY);
            }
            cursorY = this.addIndent(cursorY, index < tableCount - 1 ? TextContentType.PARA : TextContentType.TABLE);
            tableIndex = tableIndex + count;
        }


        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.2";
        contentText = "Engines";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        tableCount = 1 + Math.floor(engineCount / maxColsPerTable);

        maxCols = Math.ceil(engineCount / tableCount);
        tableIndex = 0;
        for (let index = 0; index < tableCount; index++) {
            let count = (index == tableCount - 1) ? (engineCount - (index * maxCols)) : maxCols;
            let filteredData = _.slice(data.statusEngines, tableIndex, tableIndex + count);
            let filteredConfigs = _.slice(engineCountDPConfig, tableIndex, tableIndex + count);
            newPage = await this.addThrusterTable(pdfDoc, page, cursorY, true, filteredData, count, tableIndex, filteredConfigs);
            cursorY = newPage.cursorY;
            page = newPage.page;

            if (!data.statusReport && !data.onboard) {
                cursorY = this.addIndent(cursorY);
                cursorY = this.addIndent(cursorY);
                cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
            }
            else {
                cursorY = this.addIndent(cursorY);
                cursorY = this.addIndent(cursorY);
            }
            cursorY = this.addIndent(cursorY, index < tableCount - 1 ? TextContentType.PARA : TextContentType.TABLE);
            tableIndex = tableIndex + count;
        }
        // newPage = await this.addThrusterTable(pdfDoc, page, cursorY, true, data.statusEngines, engineCount, tableIndex);
        // cursorY = newPage.cursorY;
        // page = newPage.page;
        // if (!data.statusReport && !data.onboard) {
        //     cursorY = this.addIndent(cursorY);
        //     cursorY = this.addIndent(cursorY);
        //     cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        // }
        // cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        this.logger.debug("addStatusReportPage: B.3 ", cursorY);
        srNo = "B.3";
        contentText = "Switchboards";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        this.logger.debug("addStatusReportPage: B.3 1 ", cursorY);
        newPage = await this.addSwitchBoardMaintenanceTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        this.logger.debug("addStatusReportPage: B.3 2 ", cursorY);
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        this.logger.debug("addStatusReportPage: B.3 3 ", cursorY);
        // page = pdfDoc.addPage();//TODO: MAnoj Pagination
        // cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        this.logger.debug("addStatusReportPage: B.4 ", cursorY);
        srNo = "B.4";
        contentText = "DP Environmental Sensors & Position Reference Systems";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        // cursorY = this.addIndent(cursorY);
        newPage = await this.addEnvSensorsPosRefTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.5";
        contentText = "Hardware Modifications";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        newPage = await this.addHardwareModsTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.6";
        contentText = "DP Capability Plots";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        newPage = await this.addDPCapabilityPlotsTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        // page = pdfDoc.addPage();//TODO: MAnoj Pagination
        // cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.7";
        contentText = "DP Incidents";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        newPage = await this.addDPIncidentsTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.8";
        contentText = "DP FMEA & Annual Trials";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        newPage = await this.addDPFMEAAnnualTrialsTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.9";
        contentText = "Other Documentation";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        newPage = await this.addOtherDocumentationTable(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY, TextContentType.TABLE);

        // page = pdfDoc.addPage();//TODO: MAnoj Pagination
        // cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;

        newPage = this.getTableWorkingPage(pdfDoc, page, cursorY);
        cursorY = newPage.cursorY;
        page = newPage.page;
        srNo = "B.10";
        contentText = "Key Personnel";
        cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.SUB_HEADER);
        newPage = await this.addKeyPersonnel(pdfDoc, page, cursorY, data);
        cursorY = newPage.cursorY;
        page = newPage.page;
        if (!data.statusReport && !data.onboard) {
            cursorY = this.addIndent(cursorY);
            cursorY = this.addIndent(cursorY);
            cursorY = this.addContent(pdfDoc, page, cursorY, "", ReportConst.REPORT_MSGS.PENDING_ONBOARD_TABLE, TextContentType.PARA, false, true, FontAlign.CENTER, FontStyle.ITALIC);
        }
        cursorY = this.addIndent(cursorY);


        // srNo = "";
        // contentText = "Table 5-1" + ReportConst.CONTENT_SPACE + "Trials Findings";
        // cursorY = this.addContent(pdfDoc, page, cursorY, srNo, contentText, TextContentType.CAPTION);



    }
    getCellBasicObj(name: string, colIndex: number, width?: number, style?: FontStyle, align?: FontAlign, backgroundColor?: string, fontColor?: string, colSpan?: number, rowSpan?: number,
        cellType?: number, imageKey?: string, tableData?: any): CellBasic {
        let cell: CellBasic = { name: name, colIndex: colIndex };
        if (width) {
            cell.width = width;
        }
        if (style) {
            cell.style = style;
        }

        if (align) {
            cell.align = align;
        }
        if (StringUtils.isNotBlank(backgroundColor)) {
            cell.backgroundColor = backgroundColor;
        }
        if (StringUtils.isNotBlank(fontColor)) {
            cell.fontColor = fontColor;
        }
        if (colSpan) {
            if (colSpan < 1) {
                colSpan = 1;
            }
            cell.colSpan = colSpan;
        }
        if (rowSpan) {
            if (rowSpan < 1) {
                rowSpan = 1;
            }
            cell.rowSpan = rowSpan;
        }
        if (cellType) {
            if (cellType < 0) {
                cellType = 0;
            }
            cell.cellType = cellType;
        }
        if (StringUtils.isNotBlank(imageKey)) {
            cell.imageKey = imageKey;
        }
        if (tableData) {
            cell.tabelData = tableData;
        }
        return cell;
    }

    addIndent(cursorY: number, contentType?: TextContentType): number {
        let lineIndent = ReportConst.PARA_INDENT_2;
        if (contentType) {
            lineIndent = Constants.LINE_INDENT_VAL[contentType + ""];
        }
        cursorY = cursorY - lineIndent;
        return cursorY;
    }



    getFontFromStyle(fontStyle: FontStyle): PDFFont {
        let font = this.fontSet.regular;
        switch (fontStyle) {
            case FontStyle.BOLD:
                font = this.fontSet.bold;
                break;
            case FontStyle.ITALIC:
                font = this.fontSet.italic;
                break;
            case FontStyle.BOLD_ITALIC:
                font = this.fontSet.boldItalic;
                break;
            default:
                break;
        }
        return font;
    }

    addIndexLine(page: PDFPage, cursorY: number, srNo: string, content: string, pageNumber: string, isTitle: boolean, isAppendix: number, fontColorOther?: any) {
        let fontColor = ReportConst.COLOR_BLUE_BV;
        let fontSize = ReportConst.CONTENT_FONT_SIZE;
        let font = this.fontSet.bold;
        let centerText = false;
        let justifyText = false;
        let contentType = TextContentType.SUB_TITLE;
        let borderWidth = ReportConst.TABLE_BORDER_WIDTH_NORMAL;


        let srNoX = ReportConst.REPORT_CONTENT_START_OFFSET_1;
        let srNoWidth = ReportConst.REPORT_CONTENT_SRNO_WIDTH_3;


        if (isAppendix > 0) {
            fontSize = ReportConst.CONTENT_FONT_SIZE_2;
            srNoWidth = ReportConst.REPORT_CONTENT_SRNO_WIDTH_3 * 2.3;
        }
        if (fontColorOther) {
            fontColor = ReportConst.COLOR_BLACK;
            srNoWidth = ReportConst.REPORT_CONTENT_SRNO_WIDTH_3 * 1.3;
            font = this.fontSet.regular;
        }
        else if (isTitle) {
            fontSize = ReportConst.SUBTITLE_FONT_SIZE;
            contentType = TextContentType.TITLE;
            borderWidth = ReportConst.TABLE_BORDER_WIDTH_STRONG_2;
            cursorY = this.addIndent(cursorY);
        }
        // let totalWidth = page.getWidth() - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_1;
        let contentX = srNoX + srNoWidth + ReportConst.REPORT_CONTENT_START_OFFSET_2;
        let contentWidth: number = font.widthOfTextAtSize(content, fontSize);//page.getWidth() - contentX - ReportConst.REPORT_CONTENT_START_OFFSET_1;


        let pageNumberWidth: number = font.widthOfTextAtSize(pageNumber, fontSize);
        let pageNumberX: number = page.getWidth() - ReportConst.REPORT_CONTENT_START_OFFSET_1 - pageNumberWidth;
        let maxContentWidth = pageNumberX - contentX - 20;
        if (contentWidth > maxContentWidth) {
            contentWidth = maxContentWidth
        }

        this.pdfUtils.addParagraph(page, srNoWidth, srNoX, cursorY,
            srNo, justifyText, fontColor, fontSize, font, centerText);//Manoj: Centertext true missing other parts

        let lines = this.pdfUtils.addParagraph(page, contentWidth, contentX, cursorY,//2 * contentWidth
            content, justifyText, fontColor, fontSize, font, centerText, -1, -1, -1, false, true);
        // this.logger.debug("addIndex lines:", lines);
        if (lines.length > 1) {
            contentWidth = font.widthOfTextAtSize(lines[0], fontSize);
            // this.logger.debug("addIndex line New Width:" + contentWidth + " Y:" + cursorY);
        }

        this.pdfUtils.addParagraph(page, 2 * pageNumberWidth,
            pageNumberX, cursorY, pageNumber, justifyText, fontColor, fontSize, font, centerText);

        let linestartX = contentX + contentWidth + ReportConst.REPORT_CONTENT_START_OFFSET_2;
        let lineEndX = pageNumberX - ReportConst.REPORT_CONTENT_START_OFFSET_2;
        // this.logger.debug("addIndex line Start:" + linestartX + " Y:" + cursorY);
        // this.logger.debug("addIndex line End Width:" + lineEndX + " Y:" + cursorY);

        this.pdfUtils.drawLine(page, fontColor, { x: linestartX, y: cursorY },
            { x: lineEndX, y: cursorY }, borderWidth);

        cursorY = cursorY - lines.length * (font.heightAtSize(ReportConst.CONTENT_FONT_SIZE_2) * ReportConst.NEW_LINE_FONT_SIZE_FACTOR);//instead of fontSize using ReportConst.CONTENT_FONT_SIZE_2
        cursorY = this.addIndent(cursorY);

        return cursorY;
    }

    addContent(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, srNo: string, content: string, contentType: TextContentType, srInPara?: boolean, hideSrNo?: boolean,
        fontAlign?: FontAlign, fontStyle?: FontStyle, fSize?: number, ignoreIndent?: boolean, useBulletForSrNo?: boolean): number {

        let fontSize = ReportConst.CONTENT_FONT_SIZE;
        let fontColor = ReportConst.COLOR_BLACK;
        let font = this.fontSet.regular;
        let centerText = false;
        let justifyText = contentType == TextContentType.PARA;
        if (useBulletForSrNo) {
            srNo = ReportConst.REPORT_MSGS.BULLET_POINT;
        }

        if (fontAlign) {
            centerText = fontAlign === FontAlign.CENTER;
        }
        if (contentType === TextContentType.HEADER) {
            fontColor = ReportConst.COLOR_BLUE_BV;
            fontSize = ReportConst.SUBTITLE_FONT_SIZE;
            font = this.fontSet.bold;
        }
        else if (contentType === TextContentType.SUB_HEADER) {
            fontColor = ReportConst.COLOR_BLUE_BV;
            fontSize = ReportConst.CONTENT_FONT_SIZE;
            font = this.fontSet.bold;
        }
        else if (contentType === TextContentType.PARA) {
            fontColor = ReportConst.COLOR_BLACK;
            fontSize = ReportConst.CONTENT_FONT_SIZE;
            font = this.fontSet.regular;
        }
        else if (contentType === TextContentType.CAPTION) {
            fontColor = ReportConst.COLOR_BLUE_BV;
            fontSize = ReportConst.CAPTION_FONT_SIZE;
            font = this.fontSet.bold;
            centerText = true;//Added 01092020
        }

        if (fontStyle) {
            font = this.getFontFromStyle(fontStyle);
        }
        if (fSize && fSize > 0) {
            fontSize = fSize;
        }

        let srNoX = ReportConst.REPORT_CONTENT_START_OFFSET_1;
        let srNoWidth = ReportConst.REPORT_CONTENT_SRNO_WIDTH;
        let contentX = srNoX + srNoWidth + ReportConst.REPORT_CONTENT_START_OFFSET_2;
        let contentWidth = page.getWidth() - contentX - ReportConst.REPORT_CONTENT_START_OFFSET_1;
        let lines = 0;

        if (hideSrNo) {
            contentWidth = page.getWidth() - 2 * srNoX;
            contentX = srNoX;
        }
        else {
            if (srInPara) {
                srNoX = ReportConst.REPORT_CONTENT_START_OFFSET_1 + ReportConst.REPORT_CONTENT_SRNO_WIDTH + ReportConst.REPORT_CONTENT_START_OFFSET_2;
                srNoWidth = ReportConst.REPORT_CONTENT_SRNO_WIDTH_2;
            }
            contentX = srNoX + srNoWidth + ReportConst.REPORT_CONTENT_START_OFFSET_2;
            contentWidth = page.getWidth() - contentX - ReportConst.REPORT_CONTENT_START_OFFSET_1;
            if (centerText) {
                contentWidth = page.getWidth() - 2 * contentX;
            }
        }

        // let workPage = this.getWorkingPage(pdfDoc, page, cursorY, content, contentWidth, fontSize, font, -1, -1, false, 0, 0);
        // page = workPage.page;
        // cursorY = workPage.cursorY;
        // if (workPage.isNew) {
        //     cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;//MARING OK??
        // }
        if (hideSrNo) {
            lines = this.pdfUtils.addParagraph(page, contentWidth, contentX, cursorY,
                content, justifyText, fontColor, fontSize, font, centerText);
        }
        else {
            // cursorY = page.getHeight() - (page.getHeight() - 150);
            lines = this.pdfUtils.addParagraph(page, srNoWidth, srNoX, cursorY,
                srNo, justifyText, fontColor, fontSize, font, centerText);//Manoj: Centertext true missing other parts

            lines = this.pdfUtils.addParagraph(page, contentWidth, contentX, cursorY,
                content, justifyText, fontColor, fontSize, font, centerText);
        }
        cursorY = cursorY - lines * (font.heightAtSize(fontSize) * ReportConst.NEW_LINE_FONT_SIZE_FACTOR);
        if (!ignoreIndent) {
            cursorY = this.addIndent(cursorY, contentType);
        }
        return cursorY;
    }

    getRemarksRow(remarks: string, colSpan: number) {
        if (StringUtils.isBlank(remarks)) {
            remarks = " " + Constants.STRING_SEPARATOR_2 + " ";//+ Constants.STRING_SEPARATOR_2 + " ";// + Constants.STRING_SEPARATOR_2 + " ";
        }
        return [this.getCellBasicObj(ReportConst.REPORT_MSGS.REMARKS_C + " " + remarks, 0, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, colSpan, 1)]
    }

    async addKeyPersonnel(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: ReportConst.REPORT_MSGS.NAME, width: 0.25, colIndex: 0 },
            { name: ReportConst.REPORT_MSGS.RANK, width: 0.15, colIndex: 1 },
            { name: ReportConst.REPORT_MSGS.TRAINING, width: 0.20, colIndex: 2 },
            // { name: ReportConst.REPORT_MSGS.TIME_ON_VESSEL, width: 0.20, colIndex: 3 },
            { name: ReportConst.REPORT_MSGS.YEAR_VESSEL_EXP, width: 0.20, colIndex: 3 }, //Sudhanshu Chaubey: April 2022 DT-311
            { name: ReportConst.REPORT_MSGS.TIME_IN_RANK, width: 0.20, colIndex: 4 }
        ];

        if (data.statusCrewPersonnels && data.statusCrewPersonnels.length > 0) {//} && thrusters.length > 0) {    
            for (let index = 0; index < data.statusCrewPersonnels.length; index++) {
                const personnel = data.statusCrewPersonnels[index];

                rowsArray.push([
                    this.getCellBasicObj(StringUtils.getEmptyForNull(personnel.name, " "), 0, null, FontStyle.ITALIC, FontAlign.LEFT),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(personnel.rank, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(personnel.training, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(personnel.timeOnVessel, " "), 3, null, FontStyle.ITALIC, FontAlign.LEFT),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(personnel.dpOperationsExp, " "), 4, null, FontStyle.ITALIC, FontAlign.LEFT)
                ]);
            }
        }
        else {
            rowsArray.push([
                this.getCellBasicObj(" ", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 3, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 4, null, null, FontAlign.LEFT)
            ]);
            rowsArray.push([
                this.getCellBasicObj(" ", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 3, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 4, null, null, FontAlign.LEFT)
            ]);
            rowsArray.push([
                this.getCellBasicObj(" ", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 3, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 4, null, null, FontAlign.LEFT)
            ]);
        }
        //DATA end

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);
        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addDPCapabilityPlotsTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let rowsArray = [];
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ARE_CORRECT_PLOTS_ONBOARD, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.cpCorrectCap), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ARE_VERIFY_FOOTPRINTS, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.cpFootPrints), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ADDITIONAL_COMMENTS, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.cpComments, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 2, 1)
        ]);

        let newPage = await this.addHardwareModsCommonTable(pdfDoc, page, cursorY, rowsArray);
        return newPage;
    }

    async addDPIncidentsTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let rowsArray = [];
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_INCIDENTS_RECORDED, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.incAny), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        if (this.dataService.getStatusFieldValue(data.statusDpDocuments.incAny) == "Yes") {
            rowsArray.push([
                this.getCellBasicObj(ReportConst.REPORT_MSGS.YES_HOW_MANY, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
                this.getCellBasicObj(data.statusDpDocuments.incCount, 2, null, FontStyle.ITALIC, FontAlign.LEFT)
                // this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.incCount, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
            ]);
        }
        else {
            rowsArray.push([
                this.getCellBasicObj(ReportConst.REPORT_MSGS.YES_HOW_MANY, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
                this.getCellBasicObj(" ", 2, null, FontStyle.ITALIC, FontAlign.LEFT)
                // this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.incCount, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
            ]);
        }
        // rowsArray.push([
        //     this.getCellBasicObj(ReportConst.REPORT_MSGS.YES_HOW_MANY, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
        //     this.getCellBasicObj(data.statusDpDocuments.incCount, 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        //     // this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.incCount, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        // ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.IS_THERE_DOC_SATISFACTORY_EXPLANATION, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.incDocExplain), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ADDITIONAL_COMMENTS, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.incComments, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 2, 1)
        ]);

        let newPage = await this.addHardwareModsCommonTable(pdfDoc, page, cursorY, rowsArray);
        return newPage;
    }

    async addDPFMEAAnnualTrialsTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let rowsArray = [];
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.LAST_FMEA_REVISION_DATE, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.fmeaRevision, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_ALL_FINDINGS_CLOSED, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.fmeaFindingsClosed), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ANY_FINDINGS_NOT_DOCUMENTED, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.fmeaNotDocRemarks, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.LAST_DP_TRIALS_DATE, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.appManager.getFormattedTimeStamp(data.statusDpDocuments.fmeaLastDPTrialDate, Constants.DATE_FORMAT.DATE_4_ANGULAR), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_ALL_FINDINGS_CLOSED, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.fmeaFindingsClosed2), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_OPEN_RECOMMEND_FOR_REPORT, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.fmeaOpenRecommIncluded), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ADDITIONAL_COMMENTS, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.fmeaComments, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 2, 1)
        ]);

        let newPage = await this.addHardwareModsCommonTable(pdfDoc, page, cursorY, rowsArray);
        return newPage;
    }

    async addOtherDocumentationTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let rowsArray = [];
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.IS_VESSEL_SPECIFIC_MANUAL, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.othDPOpManual), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ARE_APPROPRIATE_CHECKLIST, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.othAppChecklistPresent), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.LOGBOOK_UP_TO_DATE, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.othDPLogUptoDate), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.DO_KEY_DP_PERSONNEL_HAVE_ACCESS_TO_GUIDE_DOCS, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.othPersonAccessIMCADoc), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ARE_RECORDS_AVAILABLE_FOR_VENDORS, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusDpDocuments.othDocsForVendors), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ADDITIONAL_COMMENTS, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusDpDocuments.othComments, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 2, 1)
        ]);

        let newPage = await this.addHardwareModsCommonTable(pdfDoc, page, cursorY, rowsArray);
        return newPage;
    }

    async addHardwareModsTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let rowsArray = [];
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_ANY_MODS_SINCE_LAST_TRIALS, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusSwitchBoardDPSystem.hdAnyMods), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_MODS_TESTED, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusSwitchBoardDPSystem.hdModsTested), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.HAVE_TRIALS_PROCED_UPDATED, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(data.statusSwitchBoardDPSystem.hdTrialProcUpdated), " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);

        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.ADDITIONAL_COMMENTS, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusSwitchBoardDPSystem.hdComments, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 2, 1)
        ]);

        let newPage = await this.addHardwareModsCommonTable(pdfDoc, page, cursorY, rowsArray);
        return newPage;
    }

    async addHardwareModsCommonTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, rowsArray: any): Promise<NewPage> {
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        // let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: " ", width: 0.23, colIndex: 0 },
            { name: " ", width: 0.47, colIndex: 1 },
            { name: " ", width: 0.3, colIndex: 2 }
        ];

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);
        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addSwitchBoardMaintenanceTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: " ", width: 0.12, colIndex: 0 },
            { name: " ", width: 0.35, colIndex: 1 },
            { name: " ", width: 0.18, colIndex: 2 },
            { name: " ", width: 0.35, colIndex: 3 }
        ];
        // let dataPresent = false;
        // if (data.statusReport){//} && thrusters.length > 0) {
        //     dataPresent = true;
        // }
        let remarks = "";
        //DATA set
        remarks = StringUtils.getEmptyForNull(data.statusSwitchBoardDPSystem.sbRemarks, " ");
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.COMPANY, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusSwitchBoardDPSystem.sbCompany, " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 3, 1)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.DATE, 0, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(this.appManager.getFormattedTimeStamp(data.statusSwitchBoardDPSystem.sbMaintDate, Constants.DATE_FORMAT.DATE_4_ANGULAR), " "), 1, null, FontStyle.ITALIC, FontAlign.LEFT),
            this.getCellBasicObj(ReportConst.REPORT_MSGS.REPORT_NUMBER, 2, null, null, FontAlign.LEFT),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusSwitchBoardDPSystem.sbReportNumber, " "), 3, null, FontStyle.ITALIC, FontAlign.LEFT)
        ]);
        rowsArray.push([
            this.getCellBasicObj(ReportConst.REPORT_MSGS.MODIFICATIONS_SINCE_LAST_TRIALS, 0, null, null, FontAlign.LEFT, null, null, 2, 1),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.statusSwitchBoardDPSystem.sbModsLastTrial, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT, null, null, 2, 1)
        ]);
        //DATA end
        rowsArray.push(this.getRemarksRow(remarks, 4));

        let section0: any = {};
        section0.name = ReportConst.REPORT_MSGS.SWITHCBOAR_MAINTENANCE_HEALTH_CHECK;
        section0.rows = rowsArray;
        sectionsArray.push(section0);
        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addThrusterTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, isEngine: boolean, thrusters: any, count: number, startIndex: number, thrusterEngNames: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let cols: number = count;//4//Default
        let dataPresent = false;
        if (thrusters && thrusters.length > 0) {
            cols = thrusters.length;
            dataPresent = true;
        }
        let colWidth = (1 - 0.25) / cols;
        let columnsArray: Array<CellBasic> = [
            { name: " ", width: 0.25, colIndex: 0 }
        ];
        let maintenanceRecords = [];
        let outstandings = [];
        let oilDates = [];
        let oilResult = [];
        let runningHours = [];
        let lastOverhauls = [];
        maintenanceRecords.push(this.getCellBasicObj(ReportConst.REPORT_MSGS.MAINTENANCE_RECORDS_CHECKED, 0, null, null, FontAlign.LEFT));
        outstandings.push(this.getCellBasicObj(ReportConst.REPORT_MSGS.OUTSTANDING_MAINTENANCE, 0, null, null, FontAlign.LEFT));
        oilDates.push(this.getCellBasicObj(ReportConst.REPORT_MSGS.LAST_OIL_ANALYSIS_DATE, 0, null, null, FontAlign.LEFT));
        oilResult.push(this.getCellBasicObj(ReportConst.REPORT_MSGS.LAST_OIL_ANALYSIS_RESULT, 0, null, null, FontAlign.LEFT));
        runningHours.push(this.getCellBasicObj(isEngine ? ReportConst.REPORT_MSGS.ENGINE_RUNNING_HOURS : ReportConst.REPORT_MSGS.THRUSTER_RUNNING_HOURS, 0, null, null, FontAlign.LEFT));
        lastOverhauls.push(this.getCellBasicObj(ReportConst.REPORT_MSGS.LAST_MAJOR_OVERHAUL, 0, null, null, FontAlign.LEFT));
        let remarks = "";
        for (let index = 0; index < cols; index++) {
            let colIndex = index + 1;
            if (dataPresent) {
                const thruster = thrusters[index];
                let name = isEngine ? thruster.engineName : thruster.thrusterName;
                columnsArray.push({ name: name, width: colWidth, colIndex: colIndex });
                maintenanceRecords.push(this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(thruster.maintRecordCheck), " "), colIndex, null, FontStyle.ITALIC, FontAlign.CENTER));
                outstandings.push(this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(thruster.outstandMaint), " "), colIndex, null, FontStyle.ITALIC, FontAlign.CENTER));
                oilDates.push(this.getCellBasicObj(StringUtils.getEmptyForNull(this.appManager.getFormattedTimeStamp(thruster.lastOilAnalDate, Constants.DATE_FORMAT.DATE_4_ANGULAR), " "), colIndex, null, FontStyle.ITALIC, FontAlign.CENTER));
                oilResult.push(this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getAnalysisResultValue(thruster.lastOilAnalResult), " "), colIndex, null, FontStyle.ITALIC, FontAlign.CENTER));
                runningHours.push(this.getCellBasicObj(StringUtils.getEmptyForNull(thruster.thursterRunHrs + "", " "), colIndex, null, FontStyle.ITALIC, FontAlign.CENTER));
                lastOverhauls.push(this.getCellBasicObj(StringUtils.getEmptyForNull(thruster.lastMjrOverhaulHrs + "", " "), colIndex, null, FontStyle.ITALIC, FontAlign.CENTER));

                if (StringUtils.isNotBlank(thruster.remarks)) {
                    remarks = remarks + name + " - " + thruster.remarks + (index < cols - 1 ? Constants.STRING_SEPARATOR_2 : "");
                }
            }
            else {
                const thrusterEngBasic = thrusterEngNames[index];
                let name = thrusterEngBasic.engineThruster;//isEngine ? "Engine" : "Thruster";
                columnsArray.push({ name: name, width: colWidth, colIndex: colIndex });
                // columnsArray.push({ name: name + " " + (index + startIndex + 1), width: colWidth, colIndex: colIndex });
                maintenanceRecords.push(this.getCellBasicObj(" ", colIndex, null, null, FontAlign.CENTER));
                outstandings.push(this.getCellBasicObj(" ", colIndex, null, null, FontAlign.CENTER));
                oilDates.push(this.getCellBasicObj(" ", colIndex, null, null, FontAlign.CENTER));
                oilResult.push(this.getCellBasicObj(" ", colIndex, null, null, FontAlign.CENTER));
                runningHours.push(this.getCellBasicObj(" ", colIndex, null, null, FontAlign.CENTER));
                lastOverhauls.push(this.getCellBasicObj(" ", colIndex, null, null, FontAlign.CENTER));
            }
        }

        rowsArray.push(maintenanceRecords);
        rowsArray.push(outstandings);
        rowsArray.push(oilDates);
        rowsArray.push(oilResult);
        rowsArray.push(runningHours);
        rowsArray.push(lastOverhauls);

        rowsArray.push(this.getRemarksRow(remarks, ++cols));

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);
        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }


    async addEnvSensorsPosRefTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let dataPresent = false;
        let remarks = "";
        if (data.statusSwitchBoardDPSystem.envSensors && data.statusSwitchBoardDPSystem.envSensors.length > 0) {
            dataPresent = true;
        }
        let columnsArray: Array<CellBasic> = [
            { name: " ", width: 0.28, colIndex: 0 },
            { name: "Records Checked", width: 0.12, colIndex: 1 },
            { name: "Remarks", width: 0.60, colIndex: 2 }
        ];
        if (dataPresent) {
            remarks = data.statusSwitchBoardDPSystem.envGenRemarks;
            for (let index = 0; index < data.statusSwitchBoardDPSystem.envSensors.length; index++) {
                const sensor = data.statusSwitchBoardDPSystem.envSensors[index];
                // if (sensor) {
                //     rowsArray.push([this.getCellBasicObj(StringUtils.getEmptyForNull(sensor.name, " "), 0, null, null, FontAlign.LEFT),
                //     this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(sensor.sensorValue), " "), 1, null, FontStyle.ITALIC, FontAlign.CENTER),
                //     this.getCellBasicObj(StringUtils.getEmptyForNull(sensor.remarks, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
                //     ]);
                // }
                if (sensor) {
                    // var newSensorSystemName = controller.sensorSystemName;
                    var mySensor: any = sensor.name;
                    if (mySensor.indexOf(Constants.lovDPController[0]) != -1) {
                        mySensor = "DP Control System";
                        // mySensor = "";
                    }
                    rowsArray.push([this.getCellBasicObj(StringUtils.getEmptyForNull(mySensor, " "), 0, null, null, FontAlign.LEFT),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(this.dataService.getStatusFieldValue(sensor.sensorValue), " "), 1, null, FontStyle.ITALIC, FontAlign.CENTER),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(sensor.remarks, " "), 2, null, FontStyle.ITALIC, FontAlign.LEFT)
                    ]);
                }
            }
        }
        else {
            //Jan 2022 take data from there
            let keepOldFormat = true;
            if (data.vesselDpConfiguration) {
                console.log("ENV_SENSORS_POS_REF: A ", data.vesselDpConfiguration);
                let selectedSensorList = [];
                if (data.vesselDpConfiguration.vDpControllers && data.vesselDpConfiguration.vDpControllers.length > 0) {
                    selectedSensorList.push(data.vesselDpConfiguration.vDpControllers);
                }

                if (data.vesselDpConfiguration.vDpSensors && data.vesselDpConfiguration.vDpSensors.length > 0) {
                    selectedSensorList.push(data.vesselDpConfiguration.vDpSensors);
                }

                if (data.vesselDpConfiguration.vPosRefSystems && data.vesselDpConfiguration.vPosRefSystems.length > 0) {
                    selectedSensorList.push(data.vesselDpConfiguration.vPosRefSystems);
                }
                selectedSensorList = _.flatten(selectedSensorList);
                console.log("ENV_SENSORS_POS_REF: B", selectedSensorList);
                if (selectedSensorList.length > 0) {
                    selectedSensorList.forEach((controller, index) => {
                        for (let counter = 1; counter <= controller.count; counter++) {
                            keepOldFormat = false;
                            var newSensorSystemName = controller.sensorSystemName;
                            var myCounter: any = counter;
                            if (newSensorSystemName == Constants.lovDPController[0]) {
                                newSensorSystemName = "DP Control System";
                                myCounter = "";
                            }
                            rowsArray.push([this.getCellBasicObj(newSensorSystemName + " " + myCounter, 0, null, null, FontAlign.LEFT),
                            this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                            this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                            ]);
                        }
                    });
                }
            }

            if (keepOldFormat) {
                // rowsArray.push([this.getCellBasicObj("Main DP Control System", 0, null, null, FontAlign.LEFT),
                rowsArray.push([this.getCellBasicObj("DP Control System", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("Gyro 1", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);
                rowsArray.push([this.getCellBasicObj("Gyro 2", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("Gyro 3", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                // rowsArray.push([this.getCellBasicObj("Gyro 4", 0, null, null, FontAlign.LEFT),
                // this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                // this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                // ]);

                rowsArray.push([this.getCellBasicObj("Wind sensor 1", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("Wind sensor 2", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("Wind sensor 3", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                // rowsArray.push([this.getCellBasicObj("Wind sensor 4", 0, null, null, FontAlign.LEFT),
                // this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                // this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                // ]);

                rowsArray.push([this.getCellBasicObj("MRU 1", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("MRU 2", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("MRU 3", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                // rowsArray.push([this.getCellBasicObj("MRU 4", 0, null, null, FontAlign.LEFT),
                // this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                // this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                // ]);

                rowsArray.push([this.getCellBasicObj("DGPS 1", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);

                rowsArray.push([this.getCellBasicObj("DGPS 2", 0, null, null, FontAlign.LEFT),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER)
                ]);
            }

        }
        rowsArray.push(this.getRemarksRow(remarks, 3));

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    getFindingRow(finding, srNo: number, findingType) {
        let testNumber = "-";
        if (finding.testNumber) {
            testNumber = "" + finding.testNumber;
        }
        let stepNumber = "-";
        if ((finding.stepNumber || finding.stepNumber == 0) && !finding.isAdditionalFinding) {
            stepNumber = 1 + finding.stepNumber + "";
        }
        return [this.getCellBasicObj("" + srNo, 0, null, null, FontAlign.CENTER),
        this.getCellBasicObj(findingType, 1, null, null, FontAlign.CENTER),
        this.getCellBasicObj(testNumber, 2, null, null, FontAlign.CENTER),
        this.getCellBasicObj(finding.testName, 3, null, null, FontAlign.CENTER),
        this.getCellBasicObj(stepNumber, 4, null, null, FontAlign.CENTER),
        this.getCellBasicObj(finding.testDesc, 5, null, null, FontAlign.CENTER),
        this.getCellBasicObj(finding.testStatus, 6, null, null, FontAlign.CENTER, finding.testStatus == Constants.FINDING_CLOSED ? ReportConst.COLOR_HEX_GREEN : ReportConst.COLOR_HEX_RED),
        this.getCellBasicObj(this.appManager.getFormattedTimeStamp(finding.closureDate, Constants.DATE_FORMAT.DATE_4_ANGULAR), 7, null, null, FontAlign.CENTER),
        this.getCellBasicObj(finding.closeOut, 8, null, null, FontAlign.CENTER)
        ];
    }

    async addFindingsTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any, isPreviousFinding: boolean): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "#", width: 0.03, colIndex: 0 },
            { name: "Cat.", width: 0.05, colIndex: 1 },
            { name: "Test no.", width: 0.07, colIndex: 2 },
            { name: "Test / Finding Name", width: 0.12, colIndex: 3 },
            { name: "Step no.", width: 0.07, colIndex: 4 },
            { name: "Description of the finding", width: 0.18, colIndex: 5 },
            { name: "Status", width: 0.06, colIndex: 6 },
            { name: "Closure date", width: 0.12, colIndex: 7 },
            { name: "Close Out Details / Comments", width: 0.32, colIndex: 8 }
        ];

        let findings = isPreviousFinding ? data.oldFindings : data.newFindings;
        if (findings && (findings.aFindings.length > 0 || findings.bFindings.length > 0 || findings.cFindings.length > 0)) {
            this.logger.debug("addFindingsTable: ADD ACTUAL DATA: isPreviousFinding:" + isPreviousFinding + " data:", findings);
            let srNo = 1;
            if (findings.aFindings && findings.aFindings.length > 0) {
                for (let index = 0; index < findings.aFindings.length; index++) {
                    const finding = findings.aFindings[index];
                    if (finding) {
                        rowsArray.push(this.getFindingRow(finding, srNo, "A"));
                        srNo++;
                    }
                }
            }
            if (findings.bFindings && findings.bFindings.length > 0) {
                for (let index = 0; index < findings.bFindings.length; index++) {
                    const finding = findings.bFindings[index];
                    if (finding) {
                        rowsArray.push(this.getFindingRow(finding, srNo, "B"));
                        srNo++;
                    }
                }
            }
            if (findings.cFindings && findings.cFindings.length > 0) {
                for (let index = 0; index < findings.cFindings.length; index++) {
                    const finding = findings.cFindings[index];
                    if (finding) {
                        rowsArray.push(this.getFindingRow(finding, srNo, "C"));
                        srNo++;
                    }
                }
            }
        }
        else {
            this.logger.debug("addFindingsTable: ADD EMPTY DATA: isPreviousFinding:" + isPreviousFinding);
            for (let index = 0; index < 3; index++) {
                rowsArray.push([this.getCellBasicObj(" ", 0, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 1, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 2, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 3, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 4, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 5, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 6, null, null, FontAlign.CENTER),//, finding.testStatus == Constants.FINDING_CLOSED ? ReportConst.COLOR_HEX_GREEN : ReportConst.COLOR_HEX_RED),
                this.getCellBasicObj(" ", 7, null, null, FontAlign.CENTER),
                this.getCellBasicObj(" ", 8, null, null, FontAlign.CENTER)
                ]);

            }

        }


        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet, false, true);
        return newPage;
    }

    async addGenDetailsTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.4, colIndex: 0 },
            { name: "", width: 0.6, colIndex: 1 }
        ];
        let addRow = true;
        addRow = StringUtils.isNotBlank(data.vessel.dateOfBuild);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Date of Built", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vessel.dateOfBuild, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.isNotBlank(data.vessel.flagState);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Flag State Authority", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vessel.flagState, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.isNotBlank(data.vessel.classSociety);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Class Society", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vessel.classSociety, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.isNotBlank(data.vessel.classNotation);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Class Notation", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vessel.classNotation, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "lengthOverall");
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Length Overall", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.lengthOverall, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "breadthMoulded");
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Breadth Moulded", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.breadthMoulded, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "lengthPerpendiculars");
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Length between Perpendiculars (meters)", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.lengthPerpendiculars, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "drought");
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Draught (meters)", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.drought, 1, null, FontStyle.ITALIC, null)]);

        // addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "draft");
        // if (addRow)
        //     rowsArray.push([this.getCellBasicObj("Draft (meters)", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.draft, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "grossTon");
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Gross Tonnage", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.grossTon, 1, null, FontStyle.ITALIC, null)]);

        addRow = StringUtils.getValueFromObjAny(data.vesselPrincipalDimens, "deadweightTon");
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Deadweight Tonnage", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselPrincipalDimens.deadweightTon, 1, null, FontStyle.ITALIC, null)]);

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;
        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT_SRNO, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT_SRNO,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }


    getBasicSystemConfig(title: string, systemArray: BasicSystemConfig[]) {
        let rowsArray = [];
        if (systemArray && systemArray.length > 0) {
            for (let index = 0; index < systemArray.length; index++) {
                const sysConfig = systemArray[index];
                if (index == 0) {
                    rowsArray.push([this.getCellBasicObj(title, 0, null, FontStyle.BOLD, null, null, null, 1, systemArray.length),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(sysConfig.system), 1, null, FontStyle.ITALIC, null),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(sysConfig.configuration), 2, null, FontStyle.ITALIC, null)]);
                }
                else {
                    rowsArray.push([
                        this.getCellBasicObj(StringUtils.getEmptyForNull(sysConfig.system), 1, null, FontStyle.ITALIC, null),
                        this.getCellBasicObj(StringUtils.getEmptyForNull(sysConfig.configuration), 2, null, FontStyle.ITALIC, null)]);
                }
            }
        }
        this.logger.info("getBasicSystemConfig: rowsArray", rowsArray);
        return rowsArray;
    }

    async addDPConfigTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.22, colIndex: 0 },
            { name: ReportConst.REPORT_MSGS.SYSTEM, width: 0.22, colIndex: 1 },
            { name: ReportConst.REPORT_MSGS.CONFIGURATION_FOR_DP, width: 0.56, colIndex: 2 }
        ];

        if (data.vesselDpConfiguration) {
            let marineArray = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.MARINE_AUX_SYSTEMS, data.vesselDpConfiguration.marineAuxSystems);
            marineArray.forEach(element => {
                rowsArray.push(element);
            });

            let powerGenerations = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.POWER_GENERATION, data.vesselDpConfiguration.powerGenerations);
            powerGenerations.forEach(element => {
                rowsArray.push(element);
            });

            let powerDistributions = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.POWER_DISTRIBUTION, data.vesselDpConfiguration.powerDistributions);
            powerDistributions.forEach(element => {
                rowsArray.push(element);
            });

            let thrusters = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.THRUSTERS, data.vesselDpConfiguration.thrusters);
            thrusters.forEach(element => {
                rowsArray.push(element);
            });

            let dpControlSystems = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.DP_CONTROL_SYSTEM, data.vesselDpConfiguration.dpControlSystems);
            dpControlSystems.forEach(element => {
                rowsArray.push(element);
            });

            let vesselManagementSystems = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.VESSEL_MANAGEMENT_SYSTEM, data.vesselDpConfiguration.vesselManagementSystems);
            vesselManagementSystems.forEach(element => {
                rowsArray.push(element);
            });

            let powerManagementSystems = this.getBasicSystemConfig(ReportConst.REPORT_MSGS.POWER_MANAGEMENT_SYSTEM, data.vesselDpConfiguration.powerManagementSystems);
            powerManagementSystems.forEach(element => {
                rowsArray.push(element);
            });
        }


        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;
        this.logger.error("addDPConfigTable(): ", jsonTableDataObject)

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT_SRNO, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT_SRNO,
            JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addMainDPEquipTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.3, colIndex: 0 },
            { name: "", width: 0.3, colIndex: 1 },
            { name: "", width: 0.4, colIndex: 2 }
        ];

        let groups = [];
        let groupTypes = [];
        if (data.vesselMainDPEquipment.thrusterTypes && data.vesselMainDPEquipment.thrusterTypes.length > 0) {
            for (let index = 0; index < data.vesselMainDPEquipment.thrusterTypes.length; index++) {
                const thrusterType = data.vesselMainDPEquipment.thrusterTypes[index];
                if (thrusterType) {
                    if (groups[thrusterType.type]) {
                        groups[thrusterType.type].push(thrusterType);
                    }
                    else {
                        groups[thrusterType.type] = [];
                        groups[thrusterType.type].push(thrusterType);
                        groupTypes.push(thrusterType.type);
                    }
                    this.logger.debug("addMainDPEquipTable: groups:", groups);
                }
            }
        }
        for (let i = 0; i < groupTypes.length; i++) {
            const type = groupTypes[i];
            let group = groups[type];
            for (let index = 0; index < group.length; index++) {
                const thrusterType = group[index];
                if (index == 0) {
                    rowsArray.push([this.getCellBasicObj(thrusterType.type, 0, null, FontStyle.BOLD, null, null, null, 1, group.length),
                    this.getCellBasicObj(thrusterType.subtype, 1, null, FontStyle.REGULAR, null),
                    this.getCellBasicObj(thrusterType.description, 2, null, FontStyle.ITALIC, null)]);
                }
                else {
                    rowsArray.push([this.getCellBasicObj(thrusterType.subtype, 1, null, FontStyle.REGULAR, null),
                    this.getCellBasicObj(thrusterType.description, 2, null, FontStyle.ITALIC, null)]);
                }
            }

        }
        let addRow = true;
        // addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.mainEngines);
        // if (addRow)
        //     rowsArray.push([this.getCellBasicObj("Main Engines", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.mainEngines, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        // addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.mainGenerators);
        // if (addRow)
        //     rowsArray.push([this.getCellBasicObj("Main Generators", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.mainGenerators, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        // addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.emergencyGenerator);
        // if (addRow)
        //     rowsArray.push([this.getCellBasicObj("Emergency Generator", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.emergencyGenerator, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.mainSwitchboards);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Main Switchboards", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.mainSwitchboards, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.emergencySwitchboards);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Emergency Switchboards", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.emergencySwitchboards, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);


        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.dpControlSystem);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("DP Control System", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.dpControlSystem, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.environmentalSensors);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Environmental Sensors", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.environmentalSensors, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.positionReferenceSystems);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Position Reference Sensors", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.positionReferenceSystems, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.independentJoystickSystem);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Independent Joystick System", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.independentJoystickSystem, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.vesselManagementSystem);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Vessel Management System", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.vesselManagementSystem, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        addRow = StringUtils.isNotBlank(data.vesselMainDPEquipment.powerManagementSystem);
        if (addRow)
            rowsArray.push([this.getCellBasicObj("Power Management System", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.vesselMainDPEquipment.powerManagementSystem, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT_SRNO, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT_SRNO,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    getColumnArrayFromData(headerData): Array<CellBasic> {
        let columnsArray: Array<CellBasic> = [];
        let totalWidth = 0;
        for (let index = 0; index < headerData.length; index++) {
            const element = headerData[index];
            if (isNaN(element.width) || element.width < 100) { //DT-317 width bug fixed
                element.width = 100;
            }
            totalWidth = totalWidth + element.width;
        }
        for (let index = 0; index < headerData.length; index++) {
            const element = headerData[index];
            let cellbasic: CellBasic = new CellBasic();
            cellbasic.name = element.innerHtml;
            cellbasic.width = element.width / totalWidth;
            cellbasic.backgroundColor = ReportConst.COLOR_HEX_BLUE;
            cellbasic.fontColor = ReportConst.COLOR_HEX_WHITE;
            cellbasic.colIndex = index;
            if (StringUtils.isNotBlank(element.headerColor)) {
                cellbasic.backgroundColor = element.headerColor
            }
            columnsArray.push(cellbasic);
        }
        return columnsArray;
    }

    async addRedundancyGroupTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let tableData = data.vesselMainParticulars.dpRedundancyGroupName;
        console.log(`Table Data: `, tableData);

        if (!tableData || tableData.length <= 0) {
            let newPage: NewPage = new NewPage();
            newPage.cursorY = cursorY;
            newPage.page = page;
            return newPage;
        }

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = this.getColumnArrayFromData(tableData[0]);
        console.log(`columnsArray: `, columnsArray);

        for (let rowIndex = 1; rowIndex < tableData.length; rowIndex++) {
            let row: Array<CellBasic> = [];
            const rowData = tableData[rowIndex];
            let anyCellFilled = false;
            for (let index = 0; index < rowData.length; index++) {
                const element = rowData[index];
                let cellbasic: CellBasic = new CellBasic();
                cellbasic.name = element.innerHtml;
                cellbasic.colSpan = element.colspan;
                cellbasic.rowSpan = element.rowspan;
                if (element.style) {
                    cellbasic.style = element.style;
                }
                cellbasic.colIndex = index;
                if (StringUtils.isNotBlank(cellbasic.name)) {
                    anyCellFilled = true;
                }
                row.push(cellbasic);
            }
            if (anyCellFilled) {
                rowsArray.push(row);
            }
        }

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        console.log(`RowArray: `, rowsArray);

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT_SRNO, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT_SRNO,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addKeyPersonnelAttendTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.6, colIndex: 0 },
            { name: "", width: 0.4, colIndex: 1 }
        ];
        if (!data.envKeyPersonnel) {
            // let row5: Array<CellBasic> = [];
            // row5.push(this.getCellBasicObj("Vessel Master", 0, null, FontStyle.BOLD, null));
            // row5.push(this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.VESSEL_MASTER) : " ", 1, null, FontStyle.ITALIC, null));
            // rowsArray.push(row5);

            let row4: Array<CellBasic> = [];
            row4.push(this.getCellBasicObj("SDPO", 0, null, FontStyle.BOLD, null));
            row4.push(this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.SDPO) : " ", 1, null, FontStyle.ITALIC, null));
            rowsArray.push(row4);

            let row3: Array<CellBasic> = [];
            row3.push(this.getCellBasicObj("DPO", 0, null, FontStyle.BOLD, null));
            row3.push(this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.DPO) : " ", 1, null, FontStyle.ITALIC, null));
            rowsArray.push(row3);

            let row2: Array<CellBasic> = [];
            row2.push(this.getCellBasicObj("Chief Engineer", 0, null, FontStyle.BOLD, null));
            row2.push(this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.CHIEF_ENGINEER) : " ", 1, null, FontStyle.ITALIC, null));
            rowsArray.push(row2);

            let row1: Array<CellBasic> = [];
            row1.push(this.getCellBasicObj("Electrician", 0, null, FontStyle.BOLD, null));
            row1.push(this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.ELECTRICIAN) : " ", 1, null, FontStyle.ITALIC, null));
            rowsArray.push(row1);

            let row0: Array<CellBasic> = [];
            row0.push(this.getCellBasicObj("Instrument Technician", 0, null, FontStyle.BOLD, null));
            row0.push(this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.INSTRUMENT_TECHNICIAN) : " ", 1, null, FontStyle.ITALIC, null));
            rowsArray.push(row0);

            rowsArray.push([this.getCellBasicObj("1st Engineer", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.ENGINEER_1) : " ", 1, null, FontStyle.ITALIC, null)]);
            rowsArray.push([this.getCellBasicObj("2nd Engineer", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.ENGINEER_2) : " ", 1, null, FontStyle.ITALIC, null)]);
            rowsArray.push([this.getCellBasicObj("3rd Engineer", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.ENGINEER_3) : " ", 1, null, FontStyle.ITALIC, null)]);
            rowsArray.push([this.getCellBasicObj("BVS Surveyor 1", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.TRIALS_RESPONSIBLE_PERSON_ONBOARD) : " ", 1, null, FontStyle.ITALIC, null)]);
            rowsArray.push([this.getCellBasicObj("BVS Surveyor 2", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.TRIALS_RESPONSIBLE_PERSON_ONBOARD) : " ", 1, null, FontStyle.ITALIC, null)]);
            rowsArray.push([this.getCellBasicObj("Class Surveyor", 0, null, FontStyle.BOLD, null), this.getCellBasicObj(data.envKeyPersonnel ? this.dataService.getEnvCondKeyPersonnelName(data.envKeyPersonnel.keyPersonnels, KeyPersonnelRole.CLASS_SURVEYOR) : " ", 1, null, FontStyle.ITALIC, null)]);
        }
        else {
            if (data.envKeyPersonnel.keyPersonnels && data.envKeyPersonnel.keyPersonnels.length > 0) {
                for (let index = 0; index < data.envKeyPersonnel.keyPersonnels.length; index++) {
                    const element = data.envKeyPersonnel.keyPersonnels[index];
                    let row0: Array<CellBasic> = [];
                    if (StringUtils.getEmptyForNull(element.name).length > 0 && element.roleName != KeyPersonnelRoleLabel.VESSEL_MASTER) {//Manoj: 20102021: Added condition
                        row0.push(this.getCellBasicObj(element.roleName, 0, null, FontStyle.BOLD, null));
                        row0.push(this.getCellBasicObj(StringUtils.getEmptyForNull(element.name, " "), 1, null, FontStyle.ITALIC, null));
                        rowsArray.push(row0);
                    }
                }
            }
        }

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT_SRNO, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT_SRNO,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addEnvCondtionTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.4, colIndex: 0 },
            { name: "", width: 0.6, colIndex: 1 }
        ];

        let addRow = true;
        addRow = StringUtils.isNotBlank(data.annualTrial.startDateTrial) || StringUtils.isNotBlank(data.annualTrial.endDateTrial);
        let row5: Array<CellBasic> = [];
        let startEndDate = (data.annualTrial.startDateTrial == data.annualTrial.endDateTrial) ? data.annualTrial.startDateTrial : data.annualTrial.startDateTrial + " to " + data.annualTrial.endDateTrial;
        row5.push(this.getCellBasicObj("Date", 0, null, FontStyle.BOLD, null)); row5.push(this.getCellBasicObj(startEndDate, 1, null, FontStyle.ITALIC, null));
        if (addRow) {
            rowsArray.push(row5);
        }

        addRow = StringUtils.isNotBlank(data.annualTrial.trialsLocation);
        let row4: Array<CellBasic> = [];
        row4.push(this.getCellBasicObj("Location", 0, null, FontStyle.BOLD, null)); row4.push(this.getCellBasicObj(data.annualTrial.trialsLocation, 1, null, FontStyle.ITALIC, null));
        if (addRow) {
            rowsArray.push(row4);
        }

        addRow = StringUtils.isNotBlank(StringUtils.getValueFromObjString(data.envKeyPersonnel, "wind"));
        let row3: Array<CellBasic> = [];
        row3.push(this.getCellBasicObj("Wind", 0, null, FontStyle.BOLD, null)); row3.push(this.getCellBasicObj(data.envKeyPersonnel ? data.envKeyPersonnel.wind : " ", 1, null, FontStyle.ITALIC, null));
        if (addRow || !data.onboard) {
            rowsArray.push(row3);
        }

        addRow = StringUtils.isNotBlank(StringUtils.getValueFromObjString(data.envKeyPersonnel, "calCurrent"));
        let row2: Array<CellBasic> = [];
        row2.push(this.getCellBasicObj("Calculated Current", 0, null, FontStyle.BOLD, null)); row2.push(this.getCellBasicObj(data.envKeyPersonnel ? data.envKeyPersonnel.calCurrent : " ", 1, null, FontStyle.ITALIC, null));
        if (addRow || !data.onboard) {
            rowsArray.push(row2);
        }

        addRow = StringUtils.isNotBlank(StringUtils.getValueFromObjString(data.envKeyPersonnel, "waveHeight"));
        let row1: Array<CellBasic> = [];
        row1.push(this.getCellBasicObj("Wave Height", 0, null, FontStyle.BOLD, null)); row1.push(this.getCellBasicObj(data.envKeyPersonnel ? data.envKeyPersonnel.waveHeight : " ", 1, null, FontStyle.ITALIC, null));
        if (addRow || !data.onboard) {
            rowsArray.push(row1);
        }

        addRow = StringUtils.isNotBlank(StringUtils.getValueFromObjString(data.envKeyPersonnel, "waterDepth"));
        let row0: Array<CellBasic> = [];
        row0.push(this.getCellBasicObj("Water Depth", 0, null, FontStyle.BOLD, null)); row0.push(this.getCellBasicObj(data.envKeyPersonnel ? data.envKeyPersonnel.waterDepth : " ", 1, null, FontStyle.ITALIC, null));
        if (addRow || !data.onboard) {
            rowsArray.push(row0);
        }
        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT_SRNO, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT_SRNO,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addRevisionTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {
        console.log("data in addRevisionTable :----", data);
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "Rev", width: 0.11, colIndex: 0 },
            { name: "Date", width: 0.16, colIndex: 1 },
            { name: "Description", width: 0.37, colIndex: 2 },
            { name: "By", width: 0.1, colIndex: 3 },
            { name: "Reviewed", width: 0.13, colIndex: 4 },
            { name: "Approved", width: 0.13, colIndex: 5 }
        ];

        if (data.revisionHistory && data.revisionHistory.length > 0) {
            for (let index = 0; index < data.revisionHistory.length; index++) {
                const revision = data.revisionHistory[index];
                rowsArray.push([
                    this.getCellBasicObj(revision.rev, 0, null, null, FontAlign.CENTER),
                    this.getCellBasicObj(revision.date, 1, null, null, FontAlign.CENTER),
                    this.getCellBasicObj(revision.description, 2),
                    this.getCellBasicObj(revision.by, 3, null, null, FontAlign.CENTER),
                    this.getCellBasicObj(revision.reviewed, 4, null, null, FontAlign.CENTER),
                    this.getCellBasicObj(revision.approved, 5, null, null, FontAlign.CENTER),
                ]);
            }
        }

        // let row0: Array<CellBasic> = [];
        // row0.push(this.getCellBasicObj("A", null, null, FontAlign.CENTER)); row0.push(this.getCellBasicObj("DD/MM/YYYY", null, null, FontAlign.CENTER));
        // row0.push(this.getCellBasicObj("Draft for internal review")); row0.push(this.getCellBasicObj("KH", null, null, FontAlign.CENTER)); row0.push(this.getCellBasicObj("PG", null, null, FontAlign.CENTER)); row0.push(this.getCellBasicObj("YP", null, null, FontAlign.CENTER));
        // rowsArray.push(row0);
        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addAmendmentTable(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, data: any): Promise<NewPage> {

        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.3, colIndex: 0 },
            { name: "", width: 0.7, colIndex: 1 }
        ];

        if (data.amendments && data.amendments.length > 0) {
            for (let index = 0; index < data.amendments.length; index++) {
                const element = data.amendments[index];
                rowsArray.push([
                    this.getCellBasicObj(element.rev, 0, null, null, FontAlign.CENTER),
                    this.getCellBasicObj(element.description, 1, null, null, FontAlign.LEFT)
                ])
            }
        }
        // let row0: Array<CellBasic> = [];
        // row0.push(this.getCellBasicObj("C", null, null, FontAlign.CENTER)); row0.push(this.getCellBasicObj("Test results from DP Annual Trials YEAR included."));
        // rowsArray.push(row0);

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async addTestCasePendingExec(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, testCase: any, srno: number): Promise<NewPage> {
        let newPage: NewPage = new NewPage();
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.2, colIndex: 0 },
            { name: "", width: 0.06, colIndex: 1 },
            { name: "", width: 0.34, colIndex: 2 },
            { name: "", width: 0.4, colIndex: 3 }
        ];


        return newPage;
    }

    async getFormattedJsonTableData(tableData) {
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];

        let tableWidth = tableData.tableWidth;
        let tableName = tableData.tableName;
        let isTableName = tableData.isTableName;
        let isTableHeader = tableData.isTableHeader;
        let noOfCols = tableData.noOfCols;
        let noOfRows = tableData.noOfRows;
        let rows = tableData.tableData;
        let columnsArray: Array<CellBasic> = [];
        if (rows && rows.length > 0) {
            //DT-312 Start
            let myTotalWidth = 0;
            let myMultiPara = 1;
            for (let k = 0; k < 1; k++) {
                const row = rows[k];
                if (row && row.length > 0) {
                    let formatRow = [];
                    for (let j = 0; j < row.length; j++) {
                        const cell = row[j];
                        if (cell) {
                            myTotalWidth += cell.width;
                        }
                    }
                }
            }
            // if (myTotalWidth < tableWidth || myTotalWidth > tableWidth) {
                myMultiPara = tableWidth / myTotalWidth;
            // }
            console.log(`myMultiPara: `, myMultiPara, `myTotalWidth `, myTotalWidth);
            //End
            let headerRow = [];
            for (let k = 0; k < rows.length; k++) {
                const row = rows[k];
                if (row && row.length > 0) {
                    let formatRow = [];
                    for (let j = 0; j < row.length; j++) {
                        const cell = row[j];
                        if (cell) {
                            this.logger.debug("getFormattedJsonTableData: cell", cell);
                            let cellBasic: CellBasic = new CellBasic();
                            if (k == 0) {
                                headerRow = rows[k];
                                cellBasic.name = cell.value;
                                cellBasic.width = cell.width / tableWidth * myMultiPara; //DT-312
                                cellBasic.colIndex = j;
                                if (isTableHeader && !StringUtils.isNotBlank(cell.headerColor)) {
                                    cell.headerColor = "#007AA0";
                                }
                                if (StringUtils.isNotBlank(cell.headerColor)) {
                                    cellBasic.backgroundColor = cell.headerColor;
                                    cellBasic.fontColor = "#ffffff";
                                    cellBasic.style = FontStyle.BOLD;
                                }
                                this.logger.debug("getFormattedJsonTableData: cellBasic", cellBasic);
                                columnsArray.push(cellBasic);
                            }
                            else {
                                let columnNo = parseInt(cell.class.substring(cell.class.indexOf('col')).match(/\d+/)[0])
                                cellBasic.name = cell.value;
                                cellBasic.colIndex = j;
                                cellBasic.colSpan = cell.colspan;
                                cellBasic.rowSpan = cell.rowspan;
                                if (k == 1) {
                                    cellBasic.width = headerRow[j].width / tableWidth * myMultiPara;
                                }
                                if (StringUtils.isNotBlank(cell.headerColor)) {
                                    cellBasic.backgroundColor = cell.headerColor;
                                    cellBasic.fontColor = "#ffffff";
                                    cellBasic.style = FontStyle.BOLD;
                                }
                                this.logger.debug("getFormattedJsonTableData: cellBasic", cellBasic);
                                formatRow.push(cellBasic);
                            }

                        }
                    }
                    if (k > 0)
                        rowsArray.push(formatRow);
                }
            }
        }

        // let columnsArray: Array<CellBasic> = [
        //     { name: "", width: 0.2, colIndex: 0 },
        //     { name: "", width: 0.08, colIndex: 1 },
        //     { name: "", width: 0.33, colIndex: 2 },
        //     { name: "", width: 0.39, colIndex: 3 }
        // ];

        // rowsArray.push([this.getCellBasicObj(genComments, 0, null, FontStyle.ITALIC, null, null, null, 4, 1)]);

        // rowsArray.push([
        //     this.getCellBasicObj("Witnessed By: " + witnessedBy, 0, null, FontStyle.BOLD, null, null, null, 3, 1),
        //     this.getCellBasicObj("Date: " + witnessedDate, 3, null, FontStyle.BOLD, null, null, null, 1, 1)]);

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;
        jsonTableDataObject.title = "";
        if (tableData.isTableName) {
            jsonTableDataObject.title = StringUtils.getEmptyForNull(tableData.tableName, " ");
        }
        this.logger.debug("getFormattedJsonTableData: DATA ORG: ", tableData);
        this.logger.debug("getFormattedJsonTableData: DATA FORMATTD: ", jsonTableDataObject);
        return jsonTableDataObject;
    }

    async addTestCase(pdfDoc: PDFDocument, page: PDFPage, cursorY: number, testCase: any, testCaseExec: any, srno: number, type: string = "old") {
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "", width: 0.2, colIndex: 0 },
            // { name: "", width: 0.08, colIndex: 1 },
            { name: "", width: 0.4, colIndex: 1 },
            { name: "", width: 0.4, colIndex: 2 }
        ];

        let isTestCaseExec = false;
        let data = testCase;
        if (testCaseExec) {
            data = testCaseExec;
            isTestCaseExec = true;
        }
        //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx USE THIS VESSEL_CONFIG_TEST_SHEET_CROSS_REF
        this.logger.debug("addTestCase: testCase:", testCase);
        this.logger.debug("addTestCase: testCaseExec:", testCaseExec);

        rowsArray.push([this.getCellBasicObj("Test No. " + data.testNumber + " - " + data.testName, 0, null, FontStyle.BOLD, null, null, null, 3, 1)]);
        // rowsArray.push([this.getCellBasicObj("Test N° " + srno + " - " + data.testName + " [" + data.testNumber + "]", 0, null, FontStyle.BOLD, null, null, null, 3, 1)]);

        rowsArray.push([
            this.getCellBasicObj("Objective", 0, null, FontStyle.BOLD, null),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.testObjective, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);


        rowsArray.push([
            this.getCellBasicObj("System configuration", 0, null, FontStyle.BOLD, null),
            this.getCellBasicObj(StringUtils.getEmptyForNull(data.testSysConfig, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

        let methodSteps = [];
        let expectedResultSteps = [];
        let actualResultSteps = [];
        let actualResultStepsVal = [];
        rowsArray.push([this.getCellBasicObj("Method", 0, null, FontStyle.BOLD, null, null, null, 3, 1)]);
        if (data.stepsData && data.stepsData.length > 0) {
            for (let index = 0; index < data.stepsData.length; index++) {
                // for (let index = 0; index < 1; index++) {//TODO: MANOJ UNCOMMENT
                const element = data.stepsData[index];
                let stepId = (index + 1);
                if (element.stepId) {
                    stepId = element.stepId + 1;
                }
                let stepIdS = stepId + "";
                // if (index == 0) {
                //     methodSteps.push([this.getCellBasicObj("Method", 0, null, FontStyle.BOLD, null, null, null, 1, data.stepsData.length),
                //     this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 1, null, FontStyle.REGULAR, null),
                //     this.getCellBasicObj(StringUtils.getEmptyForNull(element.method, " "), 2, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                // }
                // else {
                methodSteps.push([
                    this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 0, null, FontStyle.REGULAR, null),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(element.method, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                // }
                expectedResultSteps.push([this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 0, null, FontStyle.REGULAR, null),
                this.getCellBasicObj(StringUtils.getEmptyForNull(element.expResult, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

                let formattedTableDatas: any = [];
                if (element && element.tableData && element.tableData.length > 0) {
                    for (let i = 0; i < element.tableData.length; i++) {
                        // for (let i = 0; i < 1; i++) {//TODO: MANOJ UNCOMMENT
                        const tableData = element.tableData[i];
                        let formattedTableData = await this.getFormattedJsonTableData(tableData);
                        if (formattedTableData) {
                            formattedTableDatas.push(formattedTableData)
                        }
                    }
                }
                this.logger.info("formattedTableDatas:", formattedTableDatas);
                this.logger.info("element:", JSON.stringify(element));

                // if (isTestCaseExec) {

                let testResults = " ";
                if (!isTestCaseExec) {//Manoj Added 16032021
                    // testResults = "Status: To be completed during the on-board trials";
                }
                if (element.testResults) {
                    testResults = StringUtils.getEmptyForNull(element.testResults, " ");
                    actualResultStepsVal.push(testResults);
                }
                else {
                    actualResultStepsVal.push(Constants.FINDINGS_STATUS[0]);
                }
                // let addDetailFindings: boolean = true;
                // if ((element.testResults == Constants.FINDINGS_STATUS[0] || testResults == " ") && isTestCaseExec) {//20102021: Added
                // // if ((element.testResults == Constants.FINDINGS_STATUS[0] || element.testResults == Constants.FINDINGS_STATUS[10] || testResults == " ") && isTestCaseExec) {//20102021: commented
                //     // addDetailFindings = false;
                //     actualResultSteps.push([this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 0, null, FontStyle.REGULAR, null, null, null, 1, 1),
                //     this.getCellBasicObj(StringUtils.getEmptyForNull(testResults, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                // }
                if (element.testResults == Constants.FINDINGS_STATUS[0] && isTestCaseExec) {
                    // if ((element.testResults == Constants.FINDINGS_STATUS[0] || element.testResults == Constants.FINDINGS_STATUS[10] || testResults == " ") && isTestCaseExec) {//20102021: commented
                    // addDetailFindings = false;
                    actualResultSteps.push([this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 0, null, FontStyle.REGULAR, null, null, null, 1, 2),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(testResults, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

                    let baseTables = 1;
                    let testComments = "";

                    if (element.testComments) {
                        testComments = StringUtils.getEmptyForNull(element.testComments, "");
                        baseTables++;
                    }
                    actualResultSteps.push([this.getCellBasicObj(StringUtils.getEmptyForNull(testComments, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                }
                else {
                    let extraTables = 0;
                    let baseTables = 1;
                    let testComments = "";

                    if (element.testComments) {
                        testComments = StringUtils.getEmptyForNull(element.testComments, "");
                    }
                    if (StringUtils.isNotBlank(testComments)) {
                        baseTables++;
                    }
                    if (formattedTableDatas && formattedTableDatas.length > 0) {
                        extraTables = formattedTableDatas.length - 1;
                        baseTables++;
                    }
                    let supportDocs: Array<Image | Doc> = [];
                    if (element.testProofDocIds && element.testProofDocIds.length > 0) {
                        for (let index = 0; index < element.testProofDocIds.length; index++) {
                            const docId = element.testProofDocIds[index];
                            if (StringUtils.isNotBlank(docId)) {
                                let doc = await this.fileHandlerService.imageDocService.getImageOrDoc(docId);
                                if (doc && doc.type == Constants.TYPES.IMAGE) {
                                    supportDocs.push(doc);
                                }
                            }
                        }
                    }
                    let cell1Type = 0;
                    let cell2Type = 0;
                    let cell1Key = "";
                    let cell2Key = "";
                    if (supportDocs.length > 0) {
                        cell1Type = 1;
                        cell1Key = supportDocs[0].remoteFileKey;

                        if (cell1Key && StringUtils.isNotBlank(cell1Key)) {
                            cell1Key = this.fileHandlerService.getRemoteFileURL(cell1Key);
                        }
                        else if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
                            if (supportDocs[0] && supportDocs[0].hasOwnProperty("fileName") && (!supportDocs[0].hasOwnProperty("remoteFileKey"))) {
                                cell1Key = this.fileHandlerService.displayFileFromFileName(supportDocs[0].fileName);
                            }
                        }

                        if (supportDocs.length > 1) {
                            cell2Type = 1;
                            cell2Key = supportDocs[1].remoteFileKey;
                            if (cell2Key && StringUtils.isNotBlank(cell2Key)) {
                                cell2Key = this.fileHandlerService.getRemoteFileURL(cell2Key);
                            }
                            else if (this.isCordovaAvailable && !this.connectivityService.isConnected()) {
                                if (supportDocs[1] && supportDocs[1].hasOwnProperty("fileName") && (!supportDocs[1].hasOwnProperty("remoteFileKey")))
                                    cell2Key = this.fileHandlerService.displayFileFromFileName(supportDocs[1].fileName);
                            }
                        }
                        baseTables++;
                    }

                    actualResultSteps.push([this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 0, null, FontStyle.REGULAR, null, null, null, 1, baseTables + extraTables),
                    this.getCellBasicObj(StringUtils.getEmptyForNull(testResults, " "), 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                    if (StringUtils.isNotBlank(testComments)) {
                        actualResultSteps.push([this.getCellBasicObj(testComments, 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                    }


                    // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 3, 1)]);
                    if (formattedTableDatas && formattedTableDatas.length > 0) {
                        for (let k = 0; k < formattedTableDatas.length; k++) {
                            const element = formattedTableDatas[k];
                            let dummyTable = [];
                            dummyTable.push(element);
                            actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1, 2, null, dummyTable)]);
                        }
                        // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1, 2, null, formattedTableDatas)]);
                    }
                    else {
                        // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                    }
                    if (supportDocs.length > 0) {
                        if (supportDocs.length > 1) {
                            actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 1, 1, cell1Type, cell1Key),
                            this.getCellBasicObj(" ", 2, null, FontStyle.ITALIC, null, null, null, 1, 1, cell2Type, cell2Key)]);
                        }
                        else {
                            actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1, cell1Type, cell1Key)]);
                        }

                    }

                }



                // }
                // else {

                // actualResultSteps.push([this.getCellBasicObj("Step " + StringUtils.getEmptyForNull(stepIdS, " "), 0, null, FontStyle.REGULAR, null, null, null, 1, 1),
                // this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);

                // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                // // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 3, 1)]);
                // if (formattedTableDatas && formattedTableDatas.length > 0) {
                //     actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1, 2, null, formattedTableDatas)]);
                // }
                // else {
                //     actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1)]);
                // }
                // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 1, 1),
                // this.getCellBasicObj(" ", 2, null, FontStyle.ITALIC, null, null, null, 1, 1)]);
                // }
            }

        }

        for (let index = 0; index < methodSteps.length; index++) {
            const element = methodSteps[index];
            rowsArray.push(element);
        }
        rowsArray.push([this.getCellBasicObj("Expected Results", 0, null, FontStyle.BOLD, null, null, null, 3, 1)]);

        for (let index = 0; index < expectedResultSteps.length; index++) {
            const element = expectedResultSteps[index];
            rowsArray.push(element);
        }
        rowsArray.push([this.getCellBasicObj("Actual Results", 0, null, FontStyle.BOLD, null, null, null, 3, 1)]);
        let allActualResultsNotExec = true;
        let allWitnessedByCrew = true;
        for (let index = 0; index < actualResultSteps.length; index++) {
            const element = actualResultSteps[index];
            // allActualResultsNotExec = allActualResultsNotExec && (element.testResults == Constants.FINDINGS_STATUS[0] || StringUtils.isBlank(element.testResults));
            rowsArray.push(element);
        }
        for (let index = 0; index < actualResultStepsVal.length; index++) {
            const element = actualResultStepsVal[index];
            allActualResultsNotExec = allActualResultsNotExec && (element == Constants.FINDINGS_STATUS[0]);
            allWitnessedByCrew = allWitnessedByCrew && (element == Constants.FINDINGS_STATUS[60]);
            // rowsArray.push(element);
        }

        if (data.isExcluded) {
            data.finalComments = "Not required to be performed this year";
        }
        let genComments = isTestCaseExec ? (data.finalComments ? StringUtils.getEmptyForNull(data.finalComments, " ") : " ") : (data.genComment ? StringUtils.getEmptyForNull(data.genComment, " ") : " ");
        let witnessedBy = isTestCaseExec ? (data.finalWitnessedBy && !allActualResultsNotExec ? StringUtils.getEmptyForNull(data.finalWitnessedBy, " ") : " ") : " ";
        // let executeCompleteDate = new Date(data.executeCompleteDate);
        // let witnessedDate = isTestCaseExec ? (data.executeCompleteDate > 0 && !allActualResultsNotExec ? DateUtils.formatDate(executeCompleteDate, "DD-MM-YYYY") : " ") : " ";//14102021
        let witnessedDate = isTestCaseExec ? (data.executeDate && !allActualResultsNotExec ? StringUtils.getEmptyForNull(data.executeDate, " ") : " ") : " ";
        // let witnessedDate = isTestCaseExec ? (testCaseExec.finalModDate ? StringUtils.getEmptyForNull(this.appManager.getFormattedTimeStamp(testCaseExec.finalModDate, Constants.DATE_FORMAT.DATE_4_ANGULAR), " ") : " ") : " ";
        rowsArray.push([this.getCellBasicObj("General comments", 0, null, FontStyle.BOLD, null, null, null, 3, 1)]);
        rowsArray.push([this.getCellBasicObj(genComments, 0, null, FontStyle.ITALIC, null, null, null, 3, 1)]);

        //Jan 2022 CR Start
        let formattedCommentTableDatas: any = [];
        if (!data.isExcluded) {
            if (data.commentTable && data.commentTable.length > 0) {
                for (let i = 0; i < data.commentTable.length; i++) {
                    // for (let i = 0; i < 1; i++) {//TODO: MANOJ UNCOMMENT
                    const tableData = data.commentTable[i];
                    let formattedTableData = await this.getFormattedJsonTableData(tableData);
                    if (formattedTableData) {
                        formattedCommentTableDatas.push(formattedTableData)
                    }
                }
            }
            this.logger.info("formattedCommentTableDatas:", formattedCommentTableDatas);
            let extraTablesC = 0;
            let baseTablesC = 1;
            if (formattedCommentTableDatas && formattedCommentTableDatas.length > 0) {
                extraTablesC = formattedCommentTableDatas.length - 1;
                baseTablesC++;
            }
            if (formattedCommentTableDatas && formattedCommentTableDatas.length > 0) {
                for (let k = 0; k < formattedCommentTableDatas.length; k++) {
                    const element = formattedCommentTableDatas[k];
                    let dummyTable = [];
                    dummyTable.push(element);
                    rowsArray.push([this.getCellBasicObj(" ", 0, null, FontStyle.ITALIC, null, null, null, 3, 1, 2, null, dummyTable)]);
                }
                // actualResultSteps.push([this.getCellBasicObj(" ", 1, null, FontStyle.ITALIC, null, null, null, 2, 1, 2, null, formattedTableDatas)]);
            }
        }


        //Jan 2022 CR End

        rowsArray.push([
            this.getCellBasicObj((allWitnessedByCrew ? "Verified By: " : "Witnessed By: ") + witnessedBy, 0, null, FontStyle.BOLD, null, null, null, 2, 1),
            this.getCellBasicObj("Date: " + witnessedDate, 2, null, FontStyle.BOLD, null, null, null, 1, 1)]);

        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;
        console.log("test case: jsonTableDataObject:", JSON.stringify(jsonTableDataObject));
        this.pdfUtils.currentTempCursorY = 0;

        if (type == "new") {
            return jsonTableDataObject;
        } else {
            let newPage = await this.pdfUtils.createTestCaseTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
                JSON.parse(ReportConst.LARGE_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
            return newPage;
        }
    }

    async addTestCasePage(pdfDoc: PDFDocument, data: any, type: string = "old") {
        let page;
        let cursorY;
        if(type != 'new'){
            page = pdfDoc.addPage();
            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        }
        
        let myTestCasesData = [];
        if (data.testCases && data.testCases.length > 0) {
            let testCaseExecStarted = data.testCasesExec && data.testCasesExec.length > 0;
            for (let index = 0; index < data.testCases.length; index++) {
                // for (let index = 0; index < 1; index++) {//TODO: MANOJ UNCOMMENT
                const testCase = data.testCases[index];
                if (testCase) {
                    if(type != 'new'){
                        this.addReportIndex("Test No. " + StringUtils.getEmptyForNull(testCase.testNumber + "", " "), testCase.testName, pdfDoc.getPageCount(), false, 2);
                    }

                    let currentTestCaseExec = null;
                    if (testCaseExecStarted) {
                        let testCaseExecList = _.filter(data.testCasesExec, { testCaseId: testCase.uid });
                        if (testCaseExecList && testCaseExecList.length > 0) {
                            currentTestCaseExec = testCaseExecList[0];//TODO Manoj why zeror
                        }
                    }
                    let newPage = await this.addTestCase(pdfDoc, page, cursorY, testCase, currentTestCaseExec, index + 1, type);

                    /*rravi need to insert pdf here */

                    if (type == "new") {
                        myTestCasesData.push(newPage);
                    } else {

                        if (index < data.testCases.length - 1) {
                            page = pdfDoc.addPage();
                            cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                        }
                    }
                    // return;//TODO: Manoj remove
                }

                // cursorY = newPage.cursorY;
                // if (newPage.isNew) {
                //     page = newPage.page;
                // }
            }
        }
        if (type == "new") {
            return myTestCasesData;
        }

    }
    async addDyanmicTableTest(pdfDoc: PDFDocument, data: any) {
        let page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_3;
        let jsonTableDataObject: any = {};
        let sectionsArray = [];
        let rowsArray = [];
        let columnsArray: Array<CellBasic> = [
            { name: "Col 1", width: 0.2, colIndex: 0 },
            { name: "Col 2", width: 0.3, colIndex: 1 },
            { name: "Col 3", width: 0.1, colIndex: 2 },
            { name: "Col 4", width: 0.4, colIndex: 3 },
        ];
        rowsArray.push([this.getCellBasicObj("A11", 0, null, null, FontAlign.CENTER),
        this.getCellBasicObj("B12", 1, null, null, FontAlign.CENTER),
        this.getCellBasicObj("C13", 2, null, null, FontAlign.CENTER),
        this.getCellBasicObj("D14", 3, null, null, FontAlign.CENTER)
        ]);

        rowsArray.push([this.getCellBasicObj("A21", 0, null, null, FontAlign.CENTER, null, null, 2, 1),
        // this.getCellBasicObj("B23", 0, null, null, FontAlign.CENTER),
        this.getCellBasicObj("C24", 2, null, null, FontAlign.CENTER, null, null, 2, 1)
        ]);

        rowsArray.push([this.getCellBasicObj("A31", 0, null, null, FontAlign.CENTER, null, null, 1, 3),
        this.getCellBasicObj("B32", 1, null, null, FontAlign.CENTER, null, null, 1, 2),
        this.getCellBasicObj("C33", 2, null, null, FontAlign.CENTER),
        this.getCellBasicObj("D34", 3, null, null, FontAlign.CENTER)
        ]);

        rowsArray.push([//this.getCellBasicObj("A41", 0, null, null, FontAlign.CENTER),
            this.getCellBasicObj("B43", 2, null, null, FontAlign.CENTER),
            this.getCellBasicObj("C44", 3, null, null, FontAlign.CENTER)
        ]);

        rowsArray.push([this.getCellBasicObj("B", 1, null, null, FontAlign.CENTER),
        this.getCellBasicObj("C", 2, null, null, FontAlign.CENTER),
        this.getCellBasicObj("D", 3, null, null, FontAlign.CENTER),
        ]);
        // let row0: Array<CellBasic> = [];
        // row0.push(this.getCellBasicObj("C", null, null, FontAlign.CENTER)); row0.push(this.getCellBasicObj("Test results from DP Annual Trials YEAR included."));
        // rowsArray.push(row0);

        // let row1: Array<CellBasic> = [];
        // row1.push(this.getCellBasicObj("D", null, null, FontAlign.CENTER)); row1.push(this.getCellBasicObj("Findings closed : Categogy – Test number – Step number"));
        // rowsArray.push(row1);

        // let row2 = []; 
        // row2.push("C"); row2.push("");
        // rowsArray.push(row2);

        // let row3 = []; 
        // row3.push("D"); row3.push(""); 
        // rowsArray.push(row3);

        // let row4 = []; 
        // row4.push("0"); row4.push(""); 
        // rowsArray.push(row4);


        let section0: any = {};
        section0.name = "";
        section0.rows = rowsArray;
        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;

        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.MARGIN_DEFAULT, cursorY, page.getWidth() - 2 * ReportConst.MARGIN_DEFAULT,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }

    async createPdfTest(vesselId: string, annualTrialId: string) {
        const jpgUrl = ReportConst.BV_LOGO_BLUE_PATH;//'https://pdf-lib.js.org/assets/cat_riding_unicorn.jpg'
        const pngUrl = 'https://pdf-lib.js.org/assets/minions_banana_alpha.png'

        const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer())
        const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer())

        const pdfDoc = await PDFDocument.create()
        this.regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
        this.boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

        const page1 = pdfDoc.addPage()
        const { width, height } = page1.getSize()
        const fontSize = 30
        let random = "This report is intended for the sole use of the person or company to whom it is addressed and no liability of any nature whatsoever shall be assumed to any other party in respect of its contents.  As to the addressee, BUREAU VERITAS SOLUTIONS MARINE & OFFSHORE SAS  and its Affiliates (the Company) shall not (save as provided in the Company's Conditions of Business dated January 2014, Rev 1) be liable for any loss or damage whatsoever suffered by virtue of any act, omission or default (whether arising by negligence or otherwise) by the Company or any of its servants.";
        let cursorY = height - 4 * fontSize;
        let lines = this.pdfUtils.addParagraph(page1, width - 2 * ReportConst.MARGIN_DEFAULT, ReportConst.MARGIN_DEFAULT, cursorY, random, false, ReportConst.COLOR_BLACK, ReportConst.CONTENT_FONT_SIZE, this.regularFont, false);
        cursorY = cursorY - lines * this.regularFont.heightAtSize(ReportConst.CONTENT_FONT_SIZE);
        this.pdfUtils.addParagraph(page1, 400, 50, cursorY, random, false, ReportConst.COLOR_BLUE_BV, ReportConst.CONTENT_FONT_SIZE, this.regularFont, true, 20, 50, cursorY);

        // let lines = this.pdfUtils.parseLines(random, 400, fontSize, defaultFont);

        // this.logger.info("parseLines: ",lines.length);
        // for (let index = 0; index < lines.length; index++) {
        //     let text = lines[index];
        //     page1.drawText(text, {
        //         x: 50,
        //         y: cursorY,
        //         size: fontSize,
        //         font: defaultFont,
        //         color: this.turquoiseColor,//rgb(0, 0.53, 0.71),
        //     });
        //     cursorY = cursorY - defaultFont.heightAtSize(fontSize);
        // }

        // page1.drawText('Creating PDFs in JavaScript is awesome! w: ' + width + ' h:' + height, {
        //     x: 50,
        //     y: height - 4 * fontSize,
        //     size: fontSize,
        //     font: defaultFont,
        //     color: this.turquoiseColor,//rgb(0, 0.53, 0.71),
        // })



        // page1.drawLine()

        const jpgImage = await pdfDoc.embedJpg(jpgImageBytes)
        const pngImage = await pdfDoc.embedPng(pngImageBytes)

        const jpgDims = jpgImage.scale(0.5)
        const pngDims = pngImage.scale(0.5)

        const page2 = pdfDoc.addPage()

        page2.drawImage(jpgImage, {
            x: page2.getWidth() - ReportConst.HEADER_LOGO_SIZE.width,
            y: page2.getHeight() - ReportConst.HEADER_LOGO_SIZE.height,
            width: ReportConst.HEADER_LOGO_SIZE.width,
            height: ReportConst.HEADER_LOGO_SIZE.height,
        })
        page2.drawImage(pngImage, {
            x: page2.getWidth() / 2 - pngDims.width / 2 + 75,
            y: page2.getHeight() / 2 - pngDims.height + 250,
            width: pngDims.width,
            height: pngDims.height,
        })

        this.addDummyTable(pdfDoc, page2, page2.getHeight() / 2);

        const pdfBytes = await pdfDoc.save();

        this.pdfUtils.openFileInWindow(pdfBytes);
    }




    async addDummyTable(pdfDoc: PDFDocument, page: any, cursorY: number): Promise<NewPage> {
        //TABLE CONTACT SUMMARY
        let jsonTableDataObject: any = {};
        jsonTableDataObject.title = "Contact Summary";

        let rowsArray = [];
        let columnsArray = [];

        let col1: any = {};
        col1.name = "Header 1";
        col1.width = "0.3";

        let col2: any = {};
        col2.name = "Header 2";
        col2.width = "0.7";

        columnsArray.push(col1);
        columnsArray.push(col2);
        let row0 = []; row0.push("Company name"); row0.push("Manoj");
        let row1 = []; row1.push("IHM Report prepared by"); row1.push("Manojsdfdsfs");
        let row2 = []; row2.push("Report No."); row2.push("Manojsssdfdsfsd");
        let row3 = []; row3.push("Date of report generation"); row3.push("Manojxxxxxxxxxx");

        rowsArray.push(row0);
        rowsArray.push(row1);
        rowsArray.push(row2);
        rowsArray.push(row3);

        let sectionsArray = [];

        let section0: any = {};
        section0.name = "Section Name";//""
        section0.rows = rowsArray;

        sectionsArray.push(section0);

        jsonTableDataObject.columns = columnsArray;
        jsonTableDataObject.row_sections = sectionsArray;
        let newPage = await this.pdfUtils.createTableSimple(pdfDoc, page, ReportConst.REPORT_CONTENT_START_OFFSET_1, cursorY, page.getWidth() - 2 * ReportConst.REPORT_CONTENT_START_OFFSET_1,
            JSON.parse(ReportConst.DEFAULT_TABLE_STYLE_JSON_STRING), jsonTableDataObject, this.fontSet);
        return newPage;
    }




    //Other functions


    //   async presentLoading(msg) {
    //     this.loading = await this.loadingController.create({
    //       message: msg
    //     });
    //     return await this.loading.present();
    //   }

    //   //------------ code to download pdf using downloadjs @starts-----
    //   /*const pdfBytes = await pdfDoc.save();
    //   download(pdfBytes, "pdf-lib_page_copying_ionic_example.pdf", "application/pdf");*/

    //   //------------ code to download pdf using downloadjs @starts-----

    //   //------------ code to show the pdf in browser @starts-----
    //   /*
    //   const pdfUrl = URL.createObjectURL(
    //     new Blob([await pdfDoc.save()], { type: 'application/pdf' }),
    //   );
    //   window.open(pdfUrl, '_blank');
    //   */
    //   //------------ code to show the pdf in browser @ends-----


    //   //alternate code to merge two pdf
    //   async mergePdfs(pdfsToMerge: string[]) {
    //     //this.presentLoading('Creating PDF file...');
    //     const mergedPdf = await PDFDocument.create();
    //     for (const pdfCopyDoc of pdfsToMerge) {
    //       const pdfBytes = await fetch(pdfCopyDoc).then(res => res.arrayBuffer());
    //       const pdf = await PDFDocument.load(pdfBytes);
    //       const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    //       copiedPages.forEach((page) => {
    //         mergedPdf.addPage(page);
    //       });
    //     }
    //     const mergedPdfFile = await mergedPdf.save();
    //     var byteArrays = [];
    //     byteArrays.push(mergedPdfFile); //required to convert unit8array to blob and use that blob to create a pdf or open in browser
    //     var blob = new Blob(byteArrays, { type: "application/pdf" });


    //     //if desktop :
    //     const pdfUrl = URL.createObjectURL(blob);
    //     //this.loading.dismiss();
    //     window.open(pdfUrl, '_blank');
    //     //download(mergedPdfFile, "pdf-lib_page_copying_ionic_example.pdf", "application/pdf");


    //     //if device
    //     //this.createPdfFromData(blob);

    //   }


    //   createPdfFromData(buffer) {

    //     //This is where the PDF file will stored , you can change it as you like
    //     // for more information please visit https://ionicframework.com/docs/native/file/
    //     const directory = this.file.externalDataDirectory; //dataDirectory;
    //     const fileName = "invoice.pdf";
    //     let options: IWriteOptions = { replace: true };

    //     this.file.checkFile(directory, fileName).then((success) => {
    //       this.savePdfToDevice(directory, fileName, buffer, options);
    //     })
    //       .catch((error) => {
    //         options = { replace: false };
    //         this.savePdfToDevice(directory, fileName, buffer, options);
    //       });
    //   }

    //   savePdfToDevice(directory, fileName, buffer, options) {
    //     this.file.writeFile(directory, fileName, buffer, options)  //Writing File to Device
    //       .then((success) => {
    //         this.loading.dismiss();
    //         console.log("File created Succesfully in catch" + JSON.stringify(success));
    //       })
    //       .catch((error) => {
    //         this.loading.dismiss();
    //         console.log("Cannot Create File in catch's catch " + JSON.stringify(error));
    //       });
    //   }




    async processRevisionPDFMake(pdfDoc: PDFDocument, data: any, type = 'new') {
        //this.addAppendixPage(pdfDoc, "APPENDIX B", "TEST SHEETS");
        let obj: any = {};
        obj.showTestCases = await this.addTestCasePage(pdfDoc, data, type);
        obj.showFindings = [];//await this.addFindingsDocPage(pdfDoc, data, type);
        return obj;


        /*if (data.supportDocs && data.supportDocs.length > 0) {
            this.addAppendixPage(pdfDoc, "APPENDIX D ", " SUPPORTING DOCUMENTS");
            await this.addSupportDocPage(pdfDoc, data);//TODO: Enable it    
        }*/
    }
}
