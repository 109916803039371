import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { Vessel, VesselPrincipalDimensions, VesselDPConfiguration, VesselMainDPEquipments, VesselStatus } from '../../../models';

// PROVIDERS
import { VesselDao, VesselStatusDao } from '../../dao';
import { AbstractService } from '../abstract-service';
import { VesselDPConfigDao } from '../../dao/vessel_dp_config.dao';
import { VesselMainParticularsDao } from '../../dao/vessel_main_particulars.dao';
import { VesselPrincipalDimensDao } from '../../dao/vessel_principal_dimens.dao';
import { VesselMainDPEquipmentsDao } from '../../dao/vessel_main_dp_equipments.dao';
import { VesselMainParticulars } from 'src/app/models/vessel-main-particulars.model';
import { Constants } from 'src/app/app.constants';
import { UserService } from '../user/user.service';
import { uuid } from 'pouchdb-utils';
import { RevStatus, VesselStatusEnum } from 'src/app/models/enums';

@Injectable()
export class VesselService extends AbstractService {

    public vesselData: Vessel;
    public mainParticularsData: VesselMainParticulars;
    public vesselPrincipalDimensionsData: VesselPrincipalDimensions;
    public mainDpEquipmentData: VesselMainDPEquipments;
    public dpConfigurationData: VesselDPConfiguration;

    constructor(protected injector: Injector,
        private userService: UserService,
        private vesselDao: VesselDao,
        private vesselStatusDao: VesselStatusDao,
        private dpConfigDao: VesselDPConfigDao,
        private mainParticularsDao: VesselMainParticularsDao,
        private principalDimensDao: VesselPrincipalDimensDao,
        private mainDPEquipDao: VesselMainDPEquipmentsDao) {
        super(injector, "VesselService");
    }

    /**
     *
     * @param {Vessel} vessel
     * @returns {Promise<Vessel>}
     */
    public async createVessel(vessel: Vessel): Promise<Vessel> {
        // vessel.mdt = new Date();
        // this.addPrincipalUserToChannel(vessel);
        this.addPrincipalDocIdToChannel(vessel.imoNo, vessel);
        // console.log("==createVessel==",vessel);

        let response = this.vesselDao.create(vessel);
        console.log(response);
        return response;
    }

    /**
     *
     * @param {string} vesselId
     * @returns {Promise<Vessel>}
     */
    public async getVessel(vesselId: string): Promise<Vessel> {
        return await this.vesselDao.get(vesselId);
    }

    /**
     *
     * @returns {Promise<Array<Vessel>>}
     */
    public async findAll(): Promise<Array<Vessel>> {
        return this.vesselDao.findAll();
    }

    /**
     *
     * @returns {Promise<Array<Vessel>>}
     */
    public async findAllCreatedVessels(): Promise<Array<Vessel>> {
        var query = {
            type: Constants.TYPES.VESSEL,
            created: true
        }
        return this.vesselDao.findBasedOnQuery(query);
    }

    /**
         *
         * @returns {Promise<Array<Vessel>>}
         */
    public async findAllUserCreatedVessels(username: string, allVessels?: Array<Vessel>): Promise<Array<Vessel>> {
        if (!allVessels) {
            allVessels = await this.findAllCreatedVessels();
        }
        let userVesselIds: Array<string> = [];
        userVesselIds = await this.userService.getCurrentUserVessels(username);
        // allVessels = _.filter(allVessels, { uid: userVesselIds });
        var result: Array<Vessel> = [];
        _.forEach(allVessels, function (n, key) {
            _.forEach(userVesselIds, function (n2, key2) {
                if (n.imoNo === n2) {
                    result.push(n);
                }
            });
        });
        return result;
    }

    /**
     *
     * @param {Vessel} vessel
     * @returns {Promise<boolean>}
     */
    public async removeVessel(vessel: Vessel): Promise<boolean> {
        return this.vesselDao.delete(vessel);
    }

    /**
     *
     * @param {Vessel} vessel
     * @returns {Promise<Vessel>}
     */
    public async updateVessel(vessel: Vessel): Promise<Vessel> {
        // vessel.mdt = new Date();
        return this.vesselDao.update(vessel);
    }

    //Create section for 4 steps
    public async createDPConfig(doc: VesselDPConfiguration, imoNo: string): Promise<VesselDPConfiguration> {
        // doc.mdt = new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(imoNo, doc);
        // console.log("response createDPConfig ",doc);
        let response = this.dpConfigDao.create(doc);
        // console.log(response);
        return response;
    }
    public async createPrincipalDimens(doc: VesselPrincipalDimensions, imoNo: string): Promise<VesselPrincipalDimensions> {
        // doc.mdt = new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(imoNo, doc);
        // console.log("response createPrincipalDimens ",doc);
        let response = this.principalDimensDao.create(doc);
        return response;
    }
    public async createMainDPEquip(doc: VesselMainDPEquipments, imoNo: string): Promise<VesselMainDPEquipments> {
        // doc.mdt = new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(imoNo, doc);
        // console.log("response createMainDPEquip",doc);
        let response = this.mainDPEquipDao.create(doc);

        return response;
    }
    public async createMainParticulars(doc: VesselMainParticulars, imoNo: string): Promise<VesselMainParticulars> {
        // doc.mdt = new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(imoNo, doc);
        // console.log("response createMainParticulars",doc);
        let response = this.mainParticularsDao.create(doc);
        console.log(response);
        return response;
    }


    public async createVesselStatusDoc(doc: VesselStatus): Promise<VesselStatus> {
        // doc.mdt = new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.imoNo, doc);
        console.log("createVesselStatusDoc ",doc);
        let response = this.vesselStatusDao.create(doc);
        console.log(response);
        return response;
    }

    //Update steps
    /**
    *
    * @param {Vessel} doc
    * @returns {Promise<VesselDPConfiguration>}
    */
    public async updateDPConfig(doc: VesselDPConfiguration): Promise<VesselDPConfiguration> {
        // doc.mdt = new Date();
        return this.dpConfigDao.update(doc);
    }
    /**
    *
    * @param {Vessel} doc
    * @returns {Promise<VesselPrincipalDimensions>}
    */
    public async updatePrincipalDimens(doc: VesselPrincipalDimensions): Promise<VesselPrincipalDimensions> {
        // doc.mdt = new Date();
        return this.principalDimensDao.update(doc);
    }
    /**
    *
    * @param {Vessel} doc
    * @returns {Promise<VesselMainDPEquipments>}
    */
    public async updateMainDPEquip(doc: VesselMainDPEquipments): Promise<VesselMainDPEquipments> {
        // doc.mdt = new Date();
        return this.mainDPEquipDao.update(doc);
    }
    /**
    *
    * @param {Vessel} doc
    * @returns {Promise<VesselMainParticulars>}
    */
    public async updateMainParticulars(doc: VesselMainParticulars): Promise<VesselMainParticulars> {
        // doc.mdt = new Date();
        return this.mainParticularsDao.update(doc);
    }

    /**
    *
    * @param {Vessel} doc
    * @returns {Promise<VesselStatus>}
    */
    public async updateVesselStatusDoc(doc: VesselStatus): Promise<VesselStatus> {
        // doc.mdt = new Date();
        console.log("updateVesselStatusDoc ",doc);
        return this.vesselStatusDao.update(doc);
    }

    //Get step document
    /**
    *
    * @param {docId} vesselId
    * @returns {Promise<VesselDPConfiguration>}
    */
    public async getDPConfig(docId: string): Promise<VesselDPConfiguration> {
        return await this.dpConfigDao.get(docId);
    }
    /**
    *
    * @param {string} docId
    * @returns {Promise<VesselPrincipalDimensions>}
    */
    public async getPrincipalDimens(docId: string): Promise<VesselPrincipalDimensions> {
        return await this.principalDimensDao.get(docId);
    }
    /**
    *
    * @param {string} docId
    * @returns {Promise<VesselMainDPEquipments>}
    */
    public async getMainDPEquip(docId: string): Promise<VesselMainDPEquipments> {
        return await this.mainDPEquipDao.get(docId);
    }
    /**
    *
    * @param {string} docId
    * @returns {Promise<VesselMainParticulars>}
    */
    public async getMainParticulars(docId: string): Promise<VesselMainParticulars> {
        return await this.mainParticularsDao.get(docId);
    }

    /**
    *
    * @param {string} docId
    * @returns {Promise<VesselStatus>}
    */
   public async getVesselStatusDoc(docId: string): Promise<VesselStatus> {
    return await this.vesselStatusDao.get(docId);
}

public async getLatestVesselStatuses(vesselId: string): Promise<Array<VesselStatus>> {
    var query = {
        mdt: { $exists: true },
        type: Constants.TYPES.VESSEL_STATUS,
        vesselId: vesselId
    }
    let sortBy = [{ mdt: Constants.SORT_BY.DESC }];
    return this.vesselStatusDao.findBasedOnQuery(query, sortBy);
}

public async getLatestVesselStatus(vesselId: string): Promise<VesselStatus | null> {
    let trialRevs = await this.getLatestVesselStatuses(vesselId);
    if (trialRevs && trialRevs.length > 0) {
        return trialRevs[0];
    }
    return null;
}

    public fetchVesselInfoNeptune(shipNo: string, imoNo: boolean): Promise<any> {
        this.logger.info("fetchVesselInfoNeptune: shipNo:" + shipNo + " imoNo:" + imoNo);

        return this.newHttpRequest().get('/getNeptuneVesselInfo').withParams({
            shipNo: shipNo,
            imoNo: imoNo
        }).do();
    }

    public fetchVessel(regNo?: string, imoNo?: string, vesselName?: string): Promise<any> {
        this.logger.info("fetchVessel: regNo:" + regNo);

        return this.newHttpRequest().post('/getVesselInfo').withBody({
            param1: regNo,
            param2: imoNo,
            param3: vesselName
        }).do();
    }

    public checkVesselCreated(regNo?: string, imoNo?: string, vesselName?: string): Promise<any> {
        this.logger.info("checkVesselCreated: regNo:", regNo, imoNo, vesselName);

        return this.newHttpRequest().post('/checkVesselCreated').withBody({
            param1: regNo,
            param2: imoNo,
            param3: vesselName
        }).do();
    }

    /**
    *
    * @param {Vessel} vessel
    */
    public setVesselData(vessel: Vessel) {
        this.vesselData = vessel;
    }

    /**
    *
    * @returns {Vessel}
    */
    public getVesselData() {
        return this.vesselData;
    }

    /**
    *
    * @param {VesselMainParticulars} vesselMainPart
    */
    public setMainParticularsData(vesselMainPart: VesselMainParticulars) {
        this.mainParticularsData = vesselMainPart;
    }

    /**
    *
    * @returns {VesselMainParticulars}
    */
    public getMainParticularsData() {
        return this.mainParticularsData;
    }

    /**
    *
    * @param {VesselPrincipalDimensions} vesselPrincipalDimensions
    */
    public setPrincipalDimensionData(vesselPrincipalDimensions: VesselPrincipalDimensions) {
        this.vesselPrincipalDimensionsData = vesselPrincipalDimensions;
    }

    /**
    *
    * @returns {VesselPrincipalDimensions}
    */
    public getPrincipalDimensionData() {
        return this.vesselPrincipalDimensionsData;
    }

    /**
    *
    * @param {VesselMainDPEquipments} mainDpEquip
    */
    public setMainDpEquipmentData(mainDpEquip: VesselMainDPEquipments) {
        this.mainDpEquipmentData = mainDpEquip;
    }

    /**
    *
    * @returns {VesselMainDPEquipments}
    */
    public getMainDpEquipmentData() {
        return this.mainDpEquipmentData;
    }
    
    /**
    *
    * @param {VesselDPConfiguration} dpConfiguration
    */
    public setDpConfigurationData(dpConfiguration: VesselDPConfiguration) {
        this.dpConfigurationData = dpConfiguration;
    }

    /**
    *
    * @returns {VesselDPConfiguration}
    */
    public getDpConfigurationData() {
        return this.dpConfigurationData;
    }

    public getRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    public generateUUID() {
        return uuid();
    }
}
