import { Component, OnInit } from '@angular/core';

import { ModalController, NavParams } from "@ionic/angular";
import { Router } from "@angular/router";
import { AppManagerService } from 'src/app/providers/service';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.page.html',
  styleUrls: ['./generic-modal.page.scss'],
})
export class GenericModalPage implements OnInit {
  headerColor : string;
  modalTitle: string;
  message: number;
  buttonText : string;
  inputRequired: boolean = false;
  inputValue: number;
  columnWidth: number;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private router: Router,
    private appManager: AppManagerService
  ) {}

  ngOnInit() {
    this.headerColor = this.navParams.data.warning_message == "warning" ? "dp-red" : "dp-blue";
    this.modalTitle = this.navParams.data.header;
    this.message = this.navParams.data.msg;
    this.buttonText= this.navParams.data.buttonText;
    this.inputValue = this.inputRequired ? this.columnWidth : undefined;
    
  }

  async onCancel() {
    return await this.modalController.dismiss("cancelled");
  }

  async onConfirm() {
    if (this.inputRequired) {
      if (this.inputValue >= 100) {
        return await this.modalController.dismiss(this.inputValue);
      }
      else {
        this.appManager.showToast("Please enter column width atleast 100");
        return;
      }
    }
    return await this.modalController.dismiss("confirmed");
  }
}
