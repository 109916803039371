import { AbstractDocument } from "./abstract-document.model";
import { RedundancyTableCell } from './redundancy-table-cell.model';
import { Constants } from '../app.constants';


export class VesselMainParticulars extends AbstractDocument {

    public imoCircular: string;
    public classSocietyRules: string;
    public dpEquipmentClass: any;

    public provingTrialsCompletionDate: string;
    public dpRedundancyGroupsImageId: string;
    public noDPRedundancyGroup: number;

    public dpRedundancyGroupName= [];//RedundancyTableCell[];
    public dpPowerSystemRedundancyConcept: string;
    public wcfdi: string;

    public powerSystemImageId: string;
    public generalArrangementImageId: string;
    public addDescription: string;
    
    public mandatoryComplete: boolean;
    type=Constants.TYPES.VESSEL_MAIN_PARTICULARS;
}