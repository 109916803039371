import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { KeyPersonnelRole } from './enums';

export class KeyPersonnel {
    public roleType: KeyPersonnelRole;
    public roleName: string;
    public name: string;
    public userAdded: boolean;
}

export class EnvCondKeyPersonnel extends AbstractDocument {

    public vesselId: string;
    public annualTrialId: string;

    public wind: string;
    public calCurrent: string;
    public waveHeight: string;
    public waterDepth: string;
    public envConditionCompleted: boolean;

    public keyPersonnels: Array<KeyPersonnel> = [];
    public keyPersonnelCompleted: boolean;
    
    public completed: boolean;
    public created: boolean;
    type = Constants.TYPES.ENV_COND_PERSONNEL;
}