import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { TestCaseExec } from "../../models";

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";


@Injectable()
export class TestCaseExecDao extends AbstractDao<TestCaseExec> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.TESTCASE_EXEC, { create: true, delete: true, update: true }, "TestCaseExecDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: TestCaseExec): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}