import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { DocSubTypeEnum } from './enums';

export class Image extends AbstractDocument {

    public displayFileName: string;
    public fileName: string;
    public localpath: string;
    public remoteFileKey: string;
    public remoteUrl: string;
    public orgLocalpath: string;
    public orgRemoteFileKey: string;
    public orgRemoteUrl: string;
    public subtype: DocSubTypeEnum;
    public testNumber: string;
    public stepNumber: string;
    public parentDocId: string;
    public tempFile: any;
    type = Constants.TYPES.IMAGE;

}