import { CommonService } from 'src/app/auth/common.service';
import { Injectable } from '@angular/core';
import * as rs from 'jsrsasign';
import { Storage } from '@ionic/storage';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
};

@Injectable({
  providedIn: 'root'
})
export class OauthAppService {

    loginUrl: string;
    responseType: string = 'code';
    clientId: string = 'dptrials';
    redirectUri: string = 'http://localhost:8100/index.html';
    scope: string = 'read';
    oauthConfig;
    constructor(private storage: Storage, private http: HttpClient, private inAppBrowser: InAppBrowser, private commonService : CommonService) {
        this.oauthConfig = this.commonService.getOAuthConfig();
    }

    openInAppBrowser(): Promise<any>{
        console.log("Inside openInAppBrowser");
        console.log("this.oauthConfig:--",this.oauthConfig);
        console.log("loginURL : ",this.oauthConfig.loginUrl);
        // this.loginUrl = 'https://sso-veristar-ppr.bureauveritas.com/oauth/authorize?' +
        this.loginUrl = this.oauthConfig.loginUrl+'?' +
                'response_type=' + encodeURIComponent(this.responseType) +
                '&client_id=' + encodeURIComponent(this.clientId) +
                '&redirect_uri=' + encodeURIComponent(this.redirectUri) +
                '&scope=' + encodeURIComponent(this.scope);
        if (this.responseType === 'code') {
            console.log("Inside openInAppBrowser respType");
            const [challenge, verifier] = this.createChallangeVerifierPairForPKCE();
            this.storage.set('PKCI_verifier', verifier);
            this.loginUrl += '&code_challenge=' + challenge;
            this.loginUrl += '&code_challenge_method=S256';
        }
        var self = this;
        return new Promise(function(resolve, reject) {
            console.log("before inapp create");
            //console.log(this.inAppBrowser);
            const browser = self.inAppBrowser.create(self.loginUrl, "_blank", "location=no,clearsessioncache=yes,clearcache=yes");
            browser.on('loadstart').subscribe(event => {
                console.log("Inside openInAppBrowser create ");
                if ((event.url).indexOf("http://localhost:8100/index.html") === 0) {
                    exit.unsubscribe();
                    browser.close();
                    var responseParameters = ((event.url).split("?")[1]).split("&");
                    var parsedResponse = {};
                    for (var i = 0; i < responseParameters.length; i++) {
                        parsedResponse[responseParameters[i].split("=")[0]] = responseParameters[i].split("=")[1];
                    }
                    if (parsedResponse["code"] !== undefined && parsedResponse["code"] !== null) {
                        console.log("Inside openInAppBrowser b4 resolve");
                        resolve(parsedResponse);
                    } else {
                        console.log("Inside openInAppBrowser b4 reject");
                        reject("Problem authenticating with BV");
                        console.log("Inside openInAppBrowser reject");
                    }
                }
            });
            var exit = browser.on('exit').subscribe(event => {
                reject("The sign in flow was canceled");
            });
        });
    }

    getAccessToken(code, pkciVerifier){
        //let tokenUrl = "https://dpsolution-int.bureauveritas.com/api/oauth/token";
        var params = new HttpParams()
            .set('grant_type', 'authorization_code')
            .set('code', code)
            .set('redirect_uri', this.redirectUri)
            .set('code_verifier', pkciVerifier)
            .set('client_id', this.clientId);
        return this.http.post(this.oauthConfig.tokenEndpoint, params, httpOptions);
    }

    createNonce() {
        return new Promise((/**
        * @param {?} resolve
        * @return {?}
        */
        (resolve) => {
            /** @type {?} */
            const url = 'Uint8ArdomValuesObj012345679BCDEFGHIJKLMNPQRSTWXYZ_cfghkpqvwxyz-';
            /** @type {?} */
            let size = 45;
            /** @type {?} */
            let id = '';
            /** @type {?} */
            const crypto = self.crypto || self['msCrypto'];
            if (crypto) {
                /** @type {?} */
                const bytes = crypto.getRandomValues(new Uint8Array(size));
                while (0 < size--) {
                    id += url[bytes[size] & 63];
                }
            }
            else {
                while (0 < size--) {
                    id += url[Math.random() * 64 | 0];
                }
            }
            resolve(id);
        }));
    }

    createChallangeVerifierPairForPKCE() {
        /** @type {?} */
        const verifier = this.createNonce();
        /** @type {?} */
        const challengeRaw = this.calcHash(verifier, 'sha-256');
        /** @type {?} */
        const challange = this.base64UrlEncode(challengeRaw);
        return [challange, verifier];
    }

    base64UrlEncode(str) {
        /** @type {?} */
        const base64 = btoa(str);
        return base64
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
    }

    calcHash(valueToHash, algorithm) {
        /** @type {?} */
        let hashAlg = new rs.KJUR.crypto.MessageDigest({ alg: algorithm });
        /** @type {?} */
        let result = hashAlg.digestString(valueToHash);
        /** @type {?} */
        let byteArrayAsString = this.toByteArrayAsString(result);
        return Promise.resolve(byteArrayAsString);
    }

    toByteArrayAsString(hexString) {
        /** @type {?} */
        let result = '';
        for (let i = 0; i < hexString.length; i += 2) {
            /** @type {?} */
            let hexDigit = hexString.charAt(i) + hexString.charAt(i + 1);
            /** @type {?} */
            let num = parseInt(hexDigit, 16);
            result += String.fromCharCode(num);
        }
        return result;
    }
}
