export enum KeyPersonnelRole {
    VESSEL_MASTER="VESSEL_MASTER",
    SDPO="SDPO",
    DPO="DPO",
    ELECTRICIAN="ELECTRICIAN",
    INSTRUMENT_TECHNICIAN="INSTRUMENT_TECHNICIAN",
    CHIEF_ENGINEER="CHIEF_ENGINEER",
    ENGINEER_1="ENGINEER_1",
    ENGINEER_2="ENGINEER_2",
    ENGINEER_3="ENGINEER_3",
    CHEIF_OFFICER="CHEIF_OFFICER",
    CLASS_SURVEYOR="CLASS_SURVEYOR",
    VESSEL_SUPER_INTENDANT="VESSEL_SUPER_INTENDANT",
    TRIALS_RESPONSIBLE_PERSON_ONBOARD="TRIALS_RESPONSIBLE_PERSON_ONBOARD",
    OTHER="OTHER"

}