import { Injector } from "@angular/core";
import { AppVersion } from '@ionic-native/app-version/ngx';

// UTILS
import { CordovaUtils } from './cordova-utils';

// PROVIDERS
import { PlatformService } from "../../providers/service/index";
import { HttpRequestBuilder } from "./http-utils";

export class AppUtils {

    public static APP_VERSION = '__NOT_READY__';
    public static readonly CLIENT_VERSION_HEADER_NAME = 'X-Client-Version';
    public static APP_PACKAGE_NAME = "";
    private static readonly DEV_CLIENT_VERSION_HEADER_VALUE = '__DEV__';
    public static platform: number = 0;

    public static async setAppVersion(injector: Injector): Promise<void> {
        if (CordovaUtils.cordovaIsAvailable()) {
            AppUtils.APP_VERSION = injector.get(PlatformService).platformIsBrowser()
                ? /version="([0-9\.]+)"/.exec(await new HttpRequestBuilder(injector).get('config.xml').withResponseType('text').do())[1]
                : await (injector.get(AppVersion)).getVersionNumber();
        } else {
            // in development mode, inside browser but not in the 'browser' platform
            AppUtils.APP_VERSION = AppUtils.DEV_CLIENT_VERSION_HEADER_VALUE;
        }
    }

    public static async setAppPackageName(injector: Injector): Promise<void> {
        if (CordovaUtils.cordovaIsAvailable()) {
            AppUtils.APP_PACKAGE_NAME = await (injector.get(AppVersion)).getPackageName();
        } else {
            AppUtils.APP_PACKAGE_NAME = AppUtils.DEV_CLIENT_VERSION_HEADER_VALUE;
        }
        
    }

}
