import { Injectable, Injector } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Market } from '@ionic-native/market/ngx';

// MISC
import { AppUtils } from '../../../commons/utils/index';
import { PlatformType } from '../platform/platform.service';

// PROVIDERS
import { AbstractService } from '../abstract-service';

@Injectable()
export class AppUpdateService extends AbstractService {

    private readonly APP_UPDATE_INTERVAl = 3600;  //in minute
    private readonly LAST_APP_UPDATE_DATE = "lastAppUpdateDate";
    public lastCheckDate: any;
    public platformType: any;

    constructor(protected injector: Injector, private alertController: AlertController, private storage: Storage, private market: Market) {
        super(injector, "AppUpdateService");
    }

    /**
     * check App update for android and ios platform
     * 
     */
    public async checkAppUpdate(): Promise<void> {
        
        this.lastCheckDate = await this.storage.get(this.LAST_APP_UPDATE_DATE);
        this.platformType = PlatformType[this.platformService.getPlatformType()];
        if((!this.lastCheckDate || this.checkTimeDiff(this.lastCheckDate)) && (this.platformType === "ANDROID" || this.platformType === "IOS") ){
            this.storage.set(this.LAST_APP_UPDATE_DATE, new Date());

            var message = "<div class='app-update-message'>Version: 1.5.1<div class='app-update-release-notes'>Release notes:<br> 1. <b>Dark mode</b> update. <br>2. Whereas, the null is a <i>special assignment value</i>, which can be assigned to a variable as a representation of no value.<br/>3. In simple words you can say a null value means no value or absence of a value, and undefined means a variable that has been declared but no yet assigned a value.<br/>4. In fact, the only values that null is loosely equal to are undefined and ... One recommended way to check for null in JavaScript<br>5. Minor bug fixes.</div><div>";
            
            const updateAlert = await this.alertController.create({
                header: "Update Available!",
                message: message,
                cssClass: "app-update",
                buttons: [{
                    cssClass: 'button-no',
                    text: "Later",
                    role: 'cancel'
                }, {
                    cssClass: 'button-yes',
                    text: "Update",
                    handler: () => {  
                        this.market.open(AppUtils.APP_PACKAGE_NAME);
                    }
                }]
            });
            await updateAlert.present();
        }
    }

    /**
     * 
     */
    public checkTimeDiff(lastCheckDate){
        if(lastCheckDate){
            var currentDate = new Date();
            var momentDiff = (currentDate.getTime() - lastCheckDate.getTime()) / 1000;
            var hourDiff = momentDiff / this.APP_UPDATE_INTERVAl;
            
            return (hourDiff > 6) ? true : false;
        }
        else{
            return true;
        }
    }


}