import { Injectable, Injector } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { Constants } from '../app.constants';
import { Config } from '../providers/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private readonly loggedPromise;
  private promiseResolveCallback;
  private config: Config = this.injector.get(Config);

  constructor(private injector: Injector) {
    this.loggedPromise = new Promise((resolve, reject) => {
      this.promiseResolveCallback = resolve;
      console.log("CommonService this.loggedPromise---", this.loggedPromise)
    });
  }

  isLogged() {
    return this.loggedPromise;
  }

  setLogged() {
    this.promiseResolveCallback(true);
  }

  getOAuthConfig() {
    const authConfig: AuthConfig = {


      clientId: this.config.get(`${Constants.CONFIG.OAUTH.CLIENT_ID}`),

      loginUrl: this.config.get(`${Constants.CONFIG.OAUTH.LOGIN_URL}`),
      logoutUrl: this.config.get(`${Constants.CONFIG.OAUTH.LOGOUT_URL}`),

      tokenEndpoint: this.config.get(`${Constants.CONFIG.OAUTH.TOKEN_ENDPOINT}`),

      redirectUri: window.location.origin + '/index.html',//'/#/',
      responseType: 'code',
      scope: 'read',
      oidc: false

      // https://10.137.3.77:8080
      // L10INMUM01D2057
    };

    return authConfig;
  }

  getOAuthConfigDynamic() {
    const authConfig: AuthConfig = {


      clientId: Constants.CONFIG_OAUTH_CLIENT_ID,

      loginUrl: Constants.CONFIG_OAUTH_BASE_URL + "oauth/authorize",
      logoutUrl: Constants.CONFIG_OAUTH_BASE_URL + "exit?client_id=" + Constants.CONFIG_OAUTH_CLIENT_ID,

      tokenEndpoint: Constants.CONFIG_BASE_URL + "/api/oauth/token",

      redirectUri: window.location.origin + '/index.html',//'/#/',
      responseType: 'code',
      scope: 'read',
      oidc: false

      // https://10.137.3.77:8080
      // L10INMUM01D2057
    };

    return authConfig;
  }
}
