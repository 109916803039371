
import { Injector } from '@angular/core';
import * as _ from 'lodash';

import { Constants } from '../../../app.constants';
import { Config } from '../../../providers/core';

import { LogLevel } from '../logger';
import { LogWriter, LogHandler, LogReader } from './handler';
import { AbstractLogWriter } from './abstract-writer';

export class LogConsoleHandler implements LogHandler {

    private static readonly CONFIG_KEY = 'console';

    private config: Config = this.injector.get(Config);

    constructor(private injector: Injector) {
        // nothing
    }

    public async canUse(): Promise<boolean> {
        return true;
    }

    public getWriter(): LogWriter {
        const levelThreshold = this.config.get(`${Constants.CONFIG.LOG.ROOT}.${LogConsoleHandler.CONFIG_KEY}.${Constants.CONFIG.LOG.LEVEL}`)
            || this.config.get(`${Constants.CONFIG.LOG.ROOT}.${Constants.CONFIG.LOG.LEVEL}`)
            || LogLevel.DEBUG;
        const rawFilter = this.config.get(`${Constants.CONFIG.LOG.ROOT}.${LogConsoleHandler.CONFIG_KEY}.${Constants.CONFIG.LOG.FILTER}`)
            || this.config.get(`${Constants.CONFIG.LOG.ROOT}.${Constants.CONFIG.LOG.FILTER}`);
        console.debug(`LogConsoleHandler: '${levelThreshold}' '${rawFilter}'`);

        return new LogConsoleWriter(levelThreshold, rawFilter);
    }

    public getReader(): LogReader {
        throw 'LogConsoleHandler - no read capability';
    }

}

export class LogConsoleWriter extends AbstractLogWriter implements LogWriter {

    constructor(levelThreshold: LogLevel, rawFilter: string) {
        super(levelThreshold, rawFilter);
    }

    protected async doLog(level: LogLevel, name: string, message: string, ...param: any[]): Promise<void> {
        if (param && param.length > 0) {
            console[level](this.getLog(name, message), ...param);
        } else {
            console[level](this.getLog(name, message));
        }
    }

    private getLog(name: string, message: string): string {
        // pad name to visually uniformize display (date + name should have a minimum number of characters)
        name = _.padEnd(String(name), 25, ".");
        return `${new Date().toISOString()} DPSolution - ${name}: ${message}`;
    }

}
