import * as _ from 'lodash';
import { StringUtils } from '../utils/string-utils';

export enum Operator {
    CONTAINS_IGNORE_CASE
}

export class ArrayUtils {

    /**
     * Creates an array of unique values.
     * 
     * @static
     * @param {...any[]} arrays The arrays to merge
     * @returns {any[]} 
     * @memberof ArrayUtils
     */
    public static union(...arrays: any[]): any[] {
        return _.union(arrays);
    }

    /**
     * Remove all duplicates from an array.
     * 
     * @static
     * @param {any[]} array 
     * @param {string} criterion (optional)
     * @returns {any[]} 
     * @memberof ArrayUtils
     */
    public static uniq(array: any[], criterion?: string): any[] {
        if (criterion) {
            return _.uniqBy(array, criterion);
        } else {
            return _.uniq(array);
        }
    }

    /**
     * Remove all duplicates from an array and sort it.
     * 
     * @static
     * @param {string[]} array 
     * @param {boolean} [asc=true] 
     * @returns {any[]} 
     * @memberof ArrayUtils
     */
    public static sortUniq(array: (string | number)[], asc: boolean = true): any[] {
        let newArray = array.sort().filter((value, index, array) => index == array.indexOf(value));
        return asc ? newArray : newArray.reverse();
    }

    /**
     * Sort an array.
     * 
     * @static
     * @param {any[]} array 
     * @param {string|Function} criterion - could be a string (field name) or a fonction (that return the value to compare)
     * @param {boolean} [asc=true] 
     * @returns 
     * @memberof ArrayUtils
     */
    public static sort(array: any[], criterion: string | Function, asc: boolean = true) {
        let newArray = _.sortBy(array, criterion);
        return asc ? newArray : newArray.reverse();
    }
    /**
     * Sort an array form a referential array?
     * 
     * @static
     * @param {any[]} arrayToSort - the array to sort
     * @param {any[]} refArray  - the referential array that contains sorted values
     * @param {string} comparisonCriterion - the criterion to compare items to sort with referentials items (can only be a field actually) 
     * @param {boolean} [asc=true] - true to ascending sort, false for descending
     * @returns 
     * @memberof ArrayUtils
     */
    public static sortFromRefArray(arrayToSort: any[], refArray: any[], comparisonCriterion: string, asc: boolean = true) {
        // Clone to manipulate
        const refArrayClone = _.cloneDeep(refArray);
        // Add order to clone
        for (let i = 0; i < refArrayClone.length; i++) {
            refArrayClone['order'] = i;
        }
        // Sort the array
        let sortedArray = _.sortBy(arrayToSort, o => {
            // Search for match clone
            let sortedClones = refArrayClone.filter(clone => clone[comparisonCriterion] == o[comparisonCriterion]);
            if (sortedClones.length == 0) {
                return -1;
            }
            return sortedClones[0]['order'];
        });
        return asc ? sortedArray : sortedArray.reverse();
    }

    /**
     * Find a value in an array.
     * 
     * @static
     * @param {any[]} array 
     * @param {string} fieldName 
     * @param {*} value 
     * @returns {*} 
     * @memberof ArrayUtils
     */
    public static find(array: any[], fieldName: string, value: any): any {
        const index = _.findIndex(array, elt => elt && elt[fieldName] === value);
        return array && index < array.length ? array[index] : undefined;
    }

    /**
     * Determine if a value exist in an array.
     * 
     * @static
     * @param {any[]} array 
     * @param {*} value 
     * @param {(string | Function)} criterion - search by field (type string) or by comparator (function(elt, value))
     * @returns {boolean} 
     * @memberof ArrayUtils
     */
    public static contains(array: any[], value: any, criterion?: string | Function): boolean {
        if (!array) {
            return false;
        }
        return this.findIndex(array, value, criterion) > -1;
    }

    /**
     * Find the index of a value
     * @param array 
     * @param value 
     * @param criterion {(string | Function)} criterion - search by field (type string) or by comparator (function(elt, value))
     * @returns {number}
     */
    public static findIndex(array: any[], value: any, criterion?: string | Function): number {
        if (!array) {
            return -1;
        }
        if (!criterion) {
            return _.findIndex(array, elt => elt == value);
        } else if (typeof criterion === "string") {
            return _.findIndex(array, elt => elt[criterion] == value);
        } else {
            return _.findIndex(array, elt => criterion(elt, value));
        }
    }

    /**
     * Determine if a value is present in some object array or not
     * 
     * @static
     * @param {any[]} parents - array of values to filter
     * @param {string[]} fields - array of to filter
     * @param {string} value - the value to search
     * @param {Operator} [operator=Operator.CONTAINS_IGNORE_CASE] - the search operator
     * @returns {any[]} 
     * @memberof ArrayUtils
     */
    public static filter(parents: any[], fields: string[], value: string, operator: Operator = Operator.CONTAINS_IGNORE_CASE): any[] {
        if (!value || value.length == 0) {
            return parents;
        }

        return parents.filter(p => {
            let toKeep = false;
            let values = this.getValueFromPath(p, fields);
            switch (operator) {
                case Operator.CONTAINS_IGNORE_CASE:
                    values.forEach(v => { if (StringUtils.containsIgnoreCase(v, value)) { toKeep = true } });
                    break;
                default:
                    console.error(`Operator ${operator} unknow`);
            }
            return toKeep;
        });
    }

    /**
     * Get field value with a JSON path (ex 'record.item.name')
     * @param item Must be a complex object, object to parse
     * @param paths Paths to look up
     */
    private static getValueFromPath(item: any, paths: string[]): Array<string> {
        let values = [];

        paths.forEach(path => {
            //Getting path part
            let part = path.split('.');
            //Temporary item 
            let toExplore = item;
            //Browsing path
            part.forEach((p, index) => {
                //Alias
                let value = toExplore[p];
                if (value != null) {
                    if (Array.isArray(value)) {
                        //If typed array, loop on it an call the same method
                        value.forEach(v => { values = values.concat(this.getValueFromPath(v, part.slice(index + 1))) });
                    } else if (StringUtils.isString(value)) {
                        //Getting what we want
                        values.push(value);
                    } else if (typeof value === "number") {
                        values.push(value.toString());
                    } else {
                        //Set value to explored object
                        toExplore = value;
                    }
                }
            });
        });

        return values;
    }

    /**
     * Check if array is empty (null and undefined are considered as empty)
     * 
     * @static
     * @param {any[]} arr 
     * @returns {boolean} 
     * @memberof ArrayUtils
     */
    public static isEmpty(arr: any[]): boolean {
        return !arr || arr.length == 0;
    }

    /**
     * Check if array is not empty (null and undefined are considered as empty)
     * 
     * @static
     * @param {any[]} arr 
     * @returns {boolean} 
     * @memberof ArrayUtils
     */
    public static isNotEmpty(arr: any[]): boolean {
        return arr && arr.length > 0;
    }

    /**
     * Add item to array if not already present.
     * 
     * @static
     * @param {any[]} array 
     * @param item
     * @param criterion? for search by field (type string) or by comparator (function(elt, value))
     * @returns {boolean} true if array has been updated
     * @memberof ArrayUtils
     * @deprecated reimplement a new method with the same principe that find()
     */
    public static add(array: any[], item: any, criterion?: string | Function): boolean {
        let updated = false;
        if (array && item) {
            if (!this.contains(array, item, criterion)) {
                array.push(item);
                updated = true;
            }
        }
        return updated;
    }

    /**
     * Remove item from array if present.
     * 
     * @static
     * @param {any[]} array 
     * @returns {boolean} true if array has been updated
     * @memberof ArrayUtils
     * @deprecated reimplement a new method with the same principe that find()
     */
    public static remove(array: any[], item: any, criterion?: string | Function): boolean {
        let updated = false;
        if (array && item) {
            if (this.contains(array, item, criterion)) {
                array.splice(this.findIndex(array, item, criterion), 1);
                updated = true;
            }
        }
        return updated;
    }

    /**
     * Clear the array to remove all items.
     * 
     * @static
     * @param {any[]} array 
     * @memberof ArrayUtils
     */
    public static clear(array: any[]): void {
        if (array) {
            array.splice(0, array.length);
        }
    }
}