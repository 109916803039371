import { Injector } from "@angular/core";
import { Events } from "@ionic/angular";

export abstract class SynchroUtils {

    private static readonly TECHNICAL_FIELDS = {
        REMOVED: "_removed",
        DELETED: "_deleted"
    }

    /**
     * Pusblish an event
     * @param {string} eventKey - the event key
     * @param {any} eventData the data to send as the event
     */
    public static publishEvent(injector: Injector, eventKey: string, ...args: any[]) {
        injector.get(Events).publish(eventKey, args);
    }

    /**
     * Check if a doc is of a type or not.
     * 
     * @static
     * @param {*} doc 
     * @param {string} type 
     * @returns 
     * @memberof SynchroUtils
     */
    public static docIsType(doc: any, type: string) {
        return String(doc._id).startsWith(type)
    }

    /**
     * Check if a document is deleted or not.
     * 
     * @static
     * @param {*} doc 
     * @returns {boolean} 
     * @memberof SynchroUtils
     */
    public static isDeleted(doc: any): boolean {
        return doc[this.TECHNICAL_FIELDS.DELETED];
    }
}