import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { StatusCrewPersonnel } from 'src/app/models/status-crew-personnel.model';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";


@Injectable()
export class StatusCrewPersonnelDao extends AbstractDao<StatusCrewPersonnel> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.STATUS_CREW_PERSONNEL, { create: true, delete: true, update: true }, "StatusCrewPersonnelDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: StatusCrewPersonnel): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}