import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';

export class AnnualTrialsClientInfo extends AbstractDocument {
    //TODO: Manoj: 23072020 add annual trial ID
    public clientName: string;
    public clientRepresentative: string;
    public clientPosition: string;
    public clientTrialsCoordinator: any = []; //Jan 2022 CR 53 - DT 252 Not used anymore
    public mandatoryComplete: boolean;
    
    type = Constants.TYPES.ANNUAL_CLIENT_INFO;

}