import { Injectable } from '@angular/core';
import { VesselService, AppManagerService } from '..';
import { PageModeEnum } from 'src/app/models/enums';

export enum PAGES {
  CREATE_VESSEL = "create vessel",
  EDIT_VESSEL = "edit vessel",
  VESSEL = "vessel",
  ANNUAL_TRIAL = "annual trial",
  CREATE_ANNUAL_TRIAL = "create annual trial",
  EDIT_ANNUAL_TRIAL = "edit annual trial",
  ANNUAL_TRIALS_HISTORY = "annual trials history",
  ANNUAL_TRIALS_HISTORY_DETAILS = "annual trials history details",
  VESSEL_DETAILS = "vessel details",
  TESTCASE_LIBRARY = "test case library",
  TESTCASE_CREATION = "test case creation",
  TESTCASE_MODIFY = "modify test case",
  TESTCASE = "test case",
  STATUS_REPORT = "status report",
  TRIALS_CONCLUSION = "trials conclusion",
  TESTCASE_EXECUTIONS = "test case executions",
  TESTCASE_EXECUTION_STATUS = "test case execution status",
  ENV_CONDITION = "env. conditions & key personal on-board",
  FINDINGS_AND_CLOSEOUT = "findings and closeout",
  LEGALS = "legals",
}
@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  
  private navbarItem: any;
  constructor(private vesselService: VesselService, private appManager: AppManagerService) { } 

  async getNavbarItems(page, vesselId?, pageMode?) {
    this.navbarItem = [];
    if(vesselId){
      var vessel = await this.vesselService.getVessel(vesselId);
    }

    switch(page){
      case PAGES.CREATE_VESSEL: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        //this.navbarItem.push(this.appManager.isReadOnly?PAGES.VESSEL:(this.appManager.isEdit? PAGES.EDIT_VESSEL: PAGES.CREATE_VESSEL));
        this.navbarItem.push(pageMode == PageModeEnum.CREATE?PAGES.CREATE_VESSEL:(pageMode == PageModeEnum.EDIT? PAGES.EDIT_VESSEL: PAGES.VESSEL));
        break;
      }
      case PAGES.CREATE_ANNUAL_TRIAL: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        //this.navbarItem.push(this.appManager.isReadOnly?PAGES.ANNUAL_TRIAL:(this.appManager.isEdit? PAGES.EDIT_ANNUAL_TRIAL: PAGES.CREATE_ANNUAL_TRIAL));
        this.navbarItem.push(pageMode == PageModeEnum.CREATE?PAGES.CREATE_ANNUAL_TRIAL:(pageMode == PageModeEnum.EDIT? PAGES.EDIT_ANNUAL_TRIAL: PAGES.ANNUAL_TRIAL));
        break;
      }
      case PAGES.ANNUAL_TRIALS_HISTORY: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.ANNUAL_TRIALS_HISTORY);
        break;
      }
      case PAGES.ANNUAL_TRIALS_HISTORY_DETAILS: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.ANNUAL_TRIALS_HISTORY);
        break;
      }
      case PAGES.VESSEL_DETAILS: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        break;
      }
      case PAGES.TESTCASE_LIBRARY: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.TESTCASE_LIBRARY);
        break;
      }
      case PAGES.TESTCASE_CREATION: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.TESTCASE_LIBRARY);
        this.navbarItem.push(pageMode == PageModeEnum.CREATE?PAGES.TESTCASE_CREATION:(pageMode == PageModeEnum.EDIT? PAGES.TESTCASE_MODIFY: PAGES.TESTCASE));
        // this.navbarItem.push(PAGES.TESTCASE_CREATION);
        break;
      }
      case PAGES.STATUS_REPORT: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.STATUS_REPORT);
        break;
      }
      case PAGES.TRIALS_CONCLUSION: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.TRIALS_CONCLUSION);
        break;
      }
      case PAGES.TESTCASE_EXECUTION_STATUS: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.TESTCASE_EXECUTION_STATUS);
        break;
      }
      case PAGES.TESTCASE_EXECUTIONS: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.TESTCASE_EXECUTION_STATUS);
        this.navbarItem.push(PAGES.TESTCASE_EXECUTIONS);
        break;
      }
      case PAGES.ENV_CONDITION: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.ENV_CONDITION);
        break;
      }
      case PAGES.FINDINGS_AND_CLOSEOUT: {
        if(vesselId && vessel){
          this.navbarItem.push(vessel.vesselName);
        }
        this.navbarItem.push(PAGES.FINDINGS_AND_CLOSEOUT);
        break;
      }
      case PAGES.LEGALS: {
        this.navbarItem.push(PAGES.LEGALS);
        break;
      }
      default:
        break;
    }

    return this.navbarItem;
  }

}