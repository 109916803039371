import { AppManagerService } from './../../app-manager/app-manager.service';
import { LoadingService } from './../loading/loading.service';
import { FileEntry } from '@ionic-native/file/ngx';
//import { NativeApiService } from './../native-api/native-api.service';
import { Injectable, Injector } from '@angular/core';
import { WebView } from '@ionic-native/ionic-webview/ngx';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { Image } from 'src/app/models/image';
import { Doc } from 'src/app/models/doc';
import { OfflineImage } from 'src/app/models/offline-image';
import { OfflineDoc } from 'src/app/models/offline-doc';
import { OfflineDocStatusEnum } from 'src/app/models/enums/offlineStatus.enum';

// PROVIDERS

import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { ImageDocService } from './image-doc.service';
import { InAppBrowserOptions, InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusEnum, StatusCodeEnum, CommonStatusEnum, DocTypeEnum } from 'src/app/models/enums';
import { AbstractDao } from '../../dao/abstract.dao';
import * as FileSaver from 'file-saver';
import { FileUpload } from 'src/app/models';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { constants } from 'os';
import { Platform } from '@ionic/angular';
import { CordovaUtils } from '../../../commons/utils/cordova-utils';
import { NativeApiService } from '..';


@Injectable()
export class FileHandlerService extends AbstractService {
    alertShown: boolean = false;
    isCordovaAvailable: boolean;

    inAppBrowserOptions: InAppBrowserOptions = {
        location: 'yes',//Or 'no' 
        hidden: 'no', //Or  'yes'
        clearcache: 'yes',
        clearsessioncache: 'yes',
        zoom: 'yes',//Android only ,shows browser zoom controls 
        hardwareback: 'yes',
        mediaPlaybackRequiresUserAction: 'no',
        shouldPauseOnSuspend: 'no', //Android only 
        closebuttoncaption: 'Close', //iOS only
        disallowoverscroll: 'no', //iOS only 
        toolbar: 'yes', //iOS only 
        enableViewportScale: 'no', //iOS only 
        allowInlineMediaPlayback: 'no',//iOS only 
        presentationstyle: 'pagesheet',//iOS only 
        fullscreen: 'yes',//Windows only    
    };
    fileTransfer: FileTransferObject = this.transfer.create()
    docFileDestinationPath: string;

    constructor(protected injector: Injector,
        public imageDocService: ImageDocService,
        private transfer: FileTransfer,
        private file: File,
        private inAppBrowser: InAppBrowser, private fileOpener: FileOpener, private platform: Platform, private nativeApi: NativeApiService, private loadingService: LoadingService, private appManager: AppManagerService, private webview: WebView) {
        super(injector, "FileHandlerService");

        this.isCordovaAvailable = CordovaUtils.cordovaIsAvailable();
        if (!this.isCordovaAvailable) {
            console.log("Web");
        }
        else {
            if (!this.platform.is('ios')) {
                this.docFileDestinationPath = this.file.externalDataDirectory + Constants.IMAGE_MAIN_DIRECTORY;
            }
            else {
                this.docFileDestinationPath = this.file.documentsDirectory + Constants.IMAGE_MAIN_DIRECTORY;
            }
        }

    }


    public openURL(url: string, target?: string) {
        let dafault_target = "_system";
        if (target && StringUtils.isNotBlank(target)) {
            dafault_target = target
        }
        this.inAppBrowser.create(url, dafault_target, this.inAppBrowserOptions);
    }

    public openFileKey(fileKey: string, target?: string) {
        let url = this.getRemoteFileURL(fileKey);
        this.openURL(url, target);
    }

    /*checkFileExistsInDevice(fileName) : Promise<boolean>{
        return new Promise<boolean>((resolve,reject) =>{
            this.file.checkFile(this.docFileDestinationPath,fileName).then(()=>{
                resolve(true);
            })
            .catch(error =>{
                resolve(false);
            });
        });
    }*/

    getMIMETypeFromFileName(fileName) {
        let jpg: string = ".jpg, .jpeg, .JPG, .JPEG";//".pdf,.docx,.xlsx,image/*"
        let png = ".png,.PNG,";
        let pdf: string = ".pdf, .PDF";

        let extension = fileName.substr(fileName.lastIndexOf(".") + 1);
        if (jpg.indexOf(extension) >= 0)
            return Constants.MIME_TYPE_IMAGE_JPEG;
        else if (png.indexOf(extension) >= 0)
            return Constants.MIME_TYPE_IMAGE_PNG;
        else if (pdf.indexOf(extension) >= 0)
            return Constants.MIME_TYPE_PDF;
    }


    public async openFileKeyDevice(fileKey: string, target?: string) {  //open image on click

        let fileName = this.getFileNameFromKey(fileKey);
        let MIME_TYPE: string = this.getMIMETypeFromFileName(fileName);
        console.log("File name and path:" + this.docFileDestinationPath + "---" + fileName);
        let isFileExist: boolean = await this.nativeApi.checkFileExistsInDevice(this.docFileDestinationPath, fileName);
        if (isFileExist) {
            try {
                await this.openFileFromDevice(this.docFileDestinationPath + "/" + fileName, MIME_TYPE)
            } catch (error) {
                console.log("Error occured while opening file", error);
            }

        } else {
            //if (this.connectivityService.isConnected()) {          //To uncomment: Yash
            console.log("Downloading");
            try {
                await this.loadingService.loadingPresent(Constants.MSG_DOWNLOADING_FILES);
                let blobData = await this.fileDownload(fileKey, fileName);
                if (blobData) {
                    console.log("saving file in openFileKeyDevice: " + fileName);
                    await this.saveFileToDevice(this.docFileDestinationPath, fileName, blobData);
                    await this.openFileFromDevice(this.docFileDestinationPath + "/" + fileName, MIME_TYPE);
                    await this.loadingService.loadingDismiss();
                }
            }
            catch (err) {
                console.log("saveToFileSystem: error:", err);
                await this.loadingService.loadingDismiss();
                //this.appManager.showToast(Constants.ERROR_DOWNLOADING_FILE, "top");
            }
            /*}else{
                console.log("Please check your internet connection");
            }*/

        }
    }

    async fileDownloadDevice(fileKey, showLoader?: boolean, force = true) {
        let toShowLoader = true;
        if (showLoader == undefined)
            showLoader = true;

        if (showLoader)
            await this.loadingService.loadingPresent(Constants.MSG_DOWNLOADING_FILES);

        let fileName = this.getFileNameFromKey(fileKey);
        try {
            if (!force) { //not force download, get file from device
                let isFileExist: boolean = await this.nativeApi.checkFileExistsInDevice(this.docFileDestinationPath, fileName);
                if (isFileExist) {
                    if (showLoader)
                        await this.loadingService.loadingDismiss();
                    return;
                }
            }

            let blobData = await this.fileDownload(fileKey, fileName);
            if (blobData) {
                await this.saveFileToDevice(this.docFileDestinationPath, fileName, blobData);
                if (showLoader)
                    await this.loadingService.loadingDismiss();
            }
        }
        catch (err) {
            console.log("saveToFileSystem: error:", err);
            await this.loadingService.loadingDismiss();
            //this.appManager.showToast(Constants.ERROR_DOWNLOADING_FILE, "top");
        }
    }

    openFileFromDevice(path, MIME_TYPE): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.fileOpener.showOpenWithDialog(path, MIME_TYPE)
                .then(() => resolve(true))
                .catch(e => {
                    this.appManager.showToast(Constants.ERROR_OPENING_FILE, "top");
                    reject(false)
                });
        });
    }


    public openFileBytesInWindow(pdfBytes) {
        var byteArrays = [];
        byteArrays.push(pdfBytes); //required to convert unit8array to blob and use that blob to create a pdf or open in browser
        var blob = new Blob(byteArrays, { type: "application/pdf" });
        //if desktop :
        const pdfUrl = URL.createObjectURL(blob);
        // window.open(pdfUrl, '_blank');
        this.openURL(pdfUrl);
    }

    public openFileObjInWindow(fileObj) {
        // var byteArrays = [];
        // byteArrays.push(pdfBytes); //required to convert unit8array to blob and use that blob to create a pdf or open in browser
        // var blob = new Blob(byteArrays, { type: "application/pdf" });
        //if desktop :
        const pdfUrl = URL.createObjectURL(fileObj);
        // window.open(pdfUrl, '_blank');
        this.openURL(pdfUrl);
        // else {
        //     let cordova: any;
        //     var inAppBrowserRef;
        //     var target = "_self";
        //     var options = "location=no,hidden=no,enableViewportScale=yes,toolbar=no,hardwareback=yes";
        //     inAppBrowserRef = cordova.InAppBrowser.open(pdfUrl, target, options);
        // }

    }

    public openFileUpload(fileUpload: FileUpload, target?: string) {

        if (fileUpload) {
            if (StringUtils.isNotBlank(fileUpload.fileKey)) {
                if (!this.isCordovaAvailable) {
                    this.openFileKey(fileUpload.fileKey, target);
                } else
                    this.openFileKeyDevice(fileUpload.fileKey, target);
            }
            else if (fileUpload.fileObj || (!fileUpload.hasOwnProperty("fileObj") && StringUtils.isNotBlank(fileUpload.fileId))) {
                if (!this.isCordovaAvailable) {
                    this.openFileObjInWindow(fileUpload.fileObj);
                } else {    //open local image
                    let fileName = this.getFileNameFromKey(fileUpload.fileName);
                    let MIME_TYPE: string = this.getMIMETypeFromFileName(fileName);
                    if (!fileUpload.hasOwnProperty("fileObj")) {
                        fileUpload.filePath = this.docFileDestinationPath;
                        this.openFileFromDevice(fileUpload.filePath + "/" + fileName, MIME_TYPE);
                    }
                    if (fileUpload.hasOwnProperty("fileObj")) {
                        this.openFileFromDevice(fileUpload.filePath + "/" + fileName, MIME_TYPE);
                    }
                }
            }


        }
    }


    //Fetch and upload images/files from S3
    // public fetchFileFromS3(fileKey: string, fileName?: string): Promise<any> {
    //     this.logger.info("fetchFileFromS3: fileKey:" + fileKey + " fileName:" + fileName);
    //     let params = {
    //         s3key: fileKey
    //     };
    //     let paramsName = {
    //         s3key: fileKey,
    //         filename: fileName
    //     };
    //     let finalParams = params;
    //     if (StringUtils.isNotBlank(fileName)) {
    //         finalParams = paramsName;
    //     }
    //     return this.newFileHandlerHttpRequest().get(Constants.ENDPOINT_DOWNLOAD).withParams(finalParams).do();
    // }

    // public downloadFileFromS3(fileKey: string, fileName?: string): Promise<any> {
    //     this.logger.info("downloadFileFromS3: fileKey:" + fileKey + " fileName:" + fileName);
    //     let params = {
    //         s3key: fileKey,
    //         forcedownload: true
    //     };
    //     let paramsName = {
    //         s3key: fileKey,
    //         forcedownload: true,
    //         filename: fileName
    //     };
    //     let finalParams = params;
    //     if (StringUtils.isNotBlank(fileName)) {
    //         finalParams = paramsName;
    //     }
    //     return this.newFileHandlerHttpRequest().get(Constants.ENDPOINT_DOWNLOAD).withParams(finalParams).do();
    // }

    // downloadFileForDesktop(fileKey: string) {
    //     this.logger.debug("downloadFileForDesktop fileKey:", fileKey);

    //     return this.http.get(url, { headers: new HttpHeaders(), responseType: 'blob', reportProgress: true }).map(
    //       (res) => {

    //         this.appmanager.ConsoleLog("downloadFileForDesktop save as", res);
    //         return new Blob([res], { type: AppConstants.MIME_TYPE_PDF })
    //       })
    //   }

    public saveToFileSystem(fileKey: string, fileName?: string) {
        if (StringUtils.isBlank(fileName)) {
            fileName = this.getFileNameFromKey(fileKey);
        }
        this.logger.info("saveToFileSystem fileKey:", fileKey + " Name:" + fileName);

        this.fileDownload(fileKey, fileName).then(res => {
            this.logger.info("saveToFileSystem downloaded fileName:", fileName + " result:" + res);
            if (res) {
                FileSaver.saveAs(res, fileName);
            }
        })
            .catch(err => {
                console.log("saveToFileSystem: error:", err);
            });
    }

    getFileNameFromKey(fileKey: string) {
        if (StringUtils.isNotBlank(fileKey)) {
            let fileKeyArray = fileKey.split("/");
            let fileName = fileKeyArray[fileKeyArray.length - 1];
            return fileName;
        }
        return "";
    }

    public getRemoteFileURL(fileKey: string) {
        let fileKeyArray = fileKey.split("/");
        let fileName = fileKeyArray[fileKeyArray.length - 1];
        return this._baseUrlFileHandler + Constants.ENDPOINT_DOWNLOAD.toString() + "?" + Constants.URL_PARAM_S3_KEY + "=" + fileKey + "&filename=" + fileName;
    }

    public displayFileFromKey(fileKey: string) {
        let fileName = this.getFileNameFromKey(fileKey);
        if (StringUtils.isNotBlank(fileName)) {
            return this.webview.convertFileSrc(this.docFileDestinationPath + "/" + fileName);
        }
    }
    public displayFileFromFileName(fileName: string) {
        if (StringUtils.isNotBlank(fileName)) {
            return this.webview.convertFileSrc(this.docFileDestinationPath + "/" + fileName);
        }
    }

    //TODO: Pending
    // public uploadFileToS3(fileKey: string, filePath: string, contentType?: string): Promise<any> {
    //     this.logger.info("uploadFileToS3: fileKey:" + fileKey + " filePath:" + filePath);

    //     return this.newFileHandlerHttpRequest().post('/multipart/upload').withBody({
    //         s3key: fileKey
    //     }).do();
    // }

    generateFileKey(fileUrl: string, imoNo: string, contentType?: string): any {
        let result: any = { s3key: "", contentType: "" };

        var filename = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
        var serveruplpoadFolder = Constants.DOC_DIRECTORY;
        let rootDirectory = Constants.DEFAULT_ROOT_DIRECTORY;
        let cntType = "";
        if (filename.toLocaleLowerCase().includes('.pdf')) {
            cntType = Constants.MIME_TYPE_PDF;
            serveruplpoadFolder = Constants.DOC_DIRECTORY;
        }
        else if (filename.includes('ORG')) {
            cntType = Constants.MIME_TYPE_IMAGE_JPEG;
            serveruplpoadFolder = Constants.IMAGES_ORG_DIRECTORY;// + "/" + AppConstants.THUMBNAIL_DEFAULT_FOLDER;
        }
        else {
            cntType = Constants.MIME_TYPE_IMAGE_JPEG;
            serveruplpoadFolder = Constants.IMAGES_DIRECTORY;
        }

        if (contentType != undefined && contentType != null) {
            cntType = contentType
        }
        if (StringUtils.isNotBlank(imoNo)) {
            rootDirectory = imoNo;
        }
        result.s3key = rootDirectory + "/" + serveruplpoadFolder;// + "/" + filename;
        result.contentType = cntType;
        return result;
    }

    async uploadFile(fileName: string, fileURI: any, fileOptions: any): Promise<any> {//imoNo?: string, contentType?: string) {
        // let fileOptions = this.generateFileKey(fileName, imoNo, contentType);
        this.logger.info("uploadFileTest fileName:", fileName);
        this.logger.info("uploadFileTest FileOptions:", fileOptions);
        //this.logger.info("uploadFileTest fileURI:", fileURI);
        // let uri: any = encodeURI(this._baseUrlFileHandler + Constants.ENDPOINT_UPLOAD);
        // this.logger.debug("uploadFileTest Endpint:", uri);
        // const request_headers = { 's3key': fileOptions.s3key };

        var request_body: FormData = new FormData();
        request_body.append('file', fileURI, fileName);
        let result: any;
        try {
            result = await this.fileUpload(fileOptions.s3key, request_body);//, null);

        } catch (error) {
            if (CordovaUtils.cordovaIsAvailable()) {
                result = { status: StatusEnum.REJECTED };
                console.log("Error occured while uploading from from device");
            }
        }
        console.log("uploadFileTest result:", result);

        if (result.status == StatusEnum.SUCCESS) {

            if (CordovaUtils.cordovaIsAvailable()) {
                if (Constants.DEBUG_ENABLED) {
                    if (!this.alertShown) {
                        this.alertShown = true;
                        alert("file-handler : before Saving file - location : " + this.docFileDestinationPath + "/" + fileName);
                    }

                }
                // temp commented on 1st oct
                this.saveFileToDevice(this.docFileDestinationPath, fileName, fileURI).then(data => {
                    //console.log("File saved to local directory:----", data);
                })
                    .catch(error => {
                        // if (Constants.DEBUG_ENABLED) {
                        //     alert("Error occured while saving file at location : " + this.docFileDestinationPath + "/" + fileName);
                        //     alert(JSON.stringify(error));
                        // }
                    });


            }
            this.logger.info("uploadFile SUCCESS:", result.s3Key);
        }
        else {
            this.logger.info("uploadFile FAILED:", result.failureReason);
        }
        return result;
    }

    saveFileToDevice(destination, fileName, fileURI): Promise<FileEntry> {
        return new Promise<FileEntry>((resolve, reject) => {
            this.file.writeFile(this.docFileDestinationPath, fileName, fileURI, { replace: true }).then((fileEntry) => {
                resolve(fileEntry);
            })
                .catch(error => {
                    if (Constants.DEBUG_ENABLED) {
                        alert("file-handler : Error saving at : " + this.docFileDestinationPath + "/" + fileName);
                        alert("File-handler : Error occured while saving file to device in saveFileToDevice:" + JSON.stringify(error));
                    }
                    console.log("Error occured while writing file : ", error);
                    reject(error);
                })
        })

    }



    async reUploadFile(doc: Doc | Image, fileObj: any, imoNo?: string, contentType?: string): Promise<Doc | Image> {
        if (!fileObj || !doc) {
            return null;
        }
        let finalDocId = "";
        // let finalFileName = fileName.replace(/ /g, '_');
        // finalFileName = this.generateName(fi?nalFileName);
        let fileOptions = this.generateFileKey(doc.fileName, imoNo, contentType);
        // doc.fileName = finalFileName;
        //Check internet
        let fileUploaded: boolean = false;
        if (this.connectivityService.isConnected()) {
            let uploadResult = await this.uploadFile(doc.fileName, fileObj, fileOptions);
            fileUploaded = uploadResult.status == StatusEnum.SUCCESS
        }
        if (!fileUploaded) {
            // doc.tempFile = fileObj;
        }
        else {
            doc.remoteFileKey = fileOptions.s3key + "/" + doc.fileName;
        }
        let resultDoc = null;
        if (doc instanceof Image) {
            resultDoc = this.imageDocService.updateImage(doc);
        }
        else {
            resultDoc = this.imageDocService.updateDoc(doc);
        }
        console.log("resultDoc in reupload:---", resultDoc);
        return resultDoc;
    }

    async processSelectedFile(doc: Doc | Image, fileName: string, fileObj: any, imoNo?: string, contentType?: string, offlineDocImg?: OfflineDoc | OfflineImage): Promise<Doc | Image> {

        if (!fileObj || !doc) {
            return null;
        }
        let finalDocId = "";
        let finalFileName = "";
        if (offlineDocImg && offlineDocImg.fileName) {
            finalFileName = offlineDocImg.fileName;
        }
        else {
            finalFileName = fileName.replace(/ /g, '_');
            finalFileName = this.generateName(finalFileName);
        }
        let fileOptions = this.generateFileKey(finalFileName, imoNo, contentType);

        doc.fileName = finalFileName;

        //Check internet
        let fileUploaded: boolean = false;
        //if (this.connectivityService.isConnected()) {     //To uncomment: Yash
        let uploadResult = await this.uploadFile(finalFileName, fileObj, fileOptions);
        console.log("uploadResult in processSelectedFile:---", uploadResult);
        fileUploaded = uploadResult.status == StatusEnum.SUCCESS
        //}
        if (!fileUploaded) {
            // doc.tempFile = fileObj;

        }
        else {
            doc.remoteFileKey = fileOptions.s3key + "/" + finalFileName;
        }

        let resultDoc = null;

        if (doc.type == Constants.TYPES.IMAGE) {
            doc = doc as Image;
            if (StringUtils.isNotBlank(doc.uid)) {
                resultDoc = await this.imageDocService.updateImage(doc);
                console.log("Update image success");
            }
            else
                resultDoc = await this.imageDocService.createImage(doc);
        }
        else if (doc.type == Constants.TYPES.DOC) {
            doc = doc as Doc;
            if (StringUtils.isNotBlank(doc.uid)) {
                resultDoc = await this.imageDocService.updateDoc(doc);
                console.log("Update doc success");
            }
            else
                resultDoc = await this.imageDocService.createDoc(doc);
        }

        if (this.isCordovaAvailable) {

            if (!fileUploaded) {

                let offlineResultDoc = null;
                if (doc instanceof Image) {
                    //offlineDoc = doc as OfflineImage;
                    if (!offlineDocImg) {
                        offlineDocImg = new OfflineImage();
                    }
                    offlineDocImg.fileName = doc.fileName;
                    offlineDocImg.localpath = this.docFileDestinationPath;
                    offlineDocImg.orgLocalpath = doc.orgLocalpath;
                    offlineDocImg.subtype = doc.subtype;
                    offlineDocImg.parentDocId = doc._id;
                    offlineDocImg.tempFile = doc.tempFile;
                    offlineDocImg.isSync = OfflineDocStatusEnum.NOTSYNCED;
                    offlineDocImg.imoNo = imoNo;
                    if (StringUtils.isNotBlank(offlineDocImg.uid)) {
                        offlineResultDoc = await this.imageDocService.removeImageOffline(offlineDocImg);
                        if (offlineResultDoc)
                            console.log("Offline Image removed :", offlineDocImg);
                    }
                    else
                        offlineResultDoc = await this.imageDocService.createImageOffline(offlineDocImg);
                    if (offlineResultDoc)
                        console.log("Offline Image created :", offlineDocImg);

                } else if (doc instanceof Doc) {
                    if (!offlineDocImg) {
                        offlineDocImg = new OfflineDoc();
                    }
                    offlineDocImg.fileName = doc.fileName;
                    offlineDocImg.localpath = this.docFileDestinationPath;
                    offlineDocImg.subtype = doc.subtype;
                    offlineDocImg.parentDocId = doc._id;
                    offlineDocImg.isSync = OfflineDocStatusEnum.NOTSYNCED;
                    offlineDocImg.imoNo = imoNo;
                    if (StringUtils.isNotBlank(offlineDocImg.uid)) {
                        offlineResultDoc = await this.imageDocService.removeDocOffline(offlineDocImg);
                        if (offlineResultDoc)
                            console.log("Offline doc removed :", offlineDocImg);
                    }
                    else
                        offlineResultDoc = await this.imageDocService.createDocOffline(offlineDocImg);
                    if (offlineResultDoc)
                        console.log("Offline doc created :", offlineDocImg);
                }

                console.log("Offline image resultDoc------", offlineResultDoc);

                try {
                    console.log("saving file in processSelectedFile: " + finalFileName);
                    await this.saveFileToDevice(this.docFileDestinationPath, finalFileName, fileObj);
                }
                catch (error) {
                    console.log("Error occured while copying file ", error);
                }

            }
            else if (offlineDocImg && StringUtils.isNotBlank(offlineDocImg.uid)) {
                try {
                    let offlineResultDoc = null;
                    offlineDocImg.isSync = OfflineDocStatusEnum.SYNCED;
                    if (offlineDocImg.type == Constants.TYPES.OFFLINE_DOC) {
                        offlineResultDoc = await this.imageDocService.removeDocOffline(offlineDocImg);
                    }
                    else if (offlineDocImg.type == Constants.TYPES.OFFLINE_IMAGE) {
                        offlineResultDoc = await this.imageDocService.removeImageOffline(offlineDocImg);
                    }

                    console.log("Offline image FILE UPLOADED resultDoc------", offlineResultDoc);

                } catch (error) {
                    console.log("Error occured while updating offline doc:", error);
                }
            }
        }

        console.log("Inside processSelectedFile of filehandler");
        return resultDoc;
    }


    async processPendingFileUploads() {

        console.log("Checking if any image/doc to upload");

        let offlineDocs = await this.imageDocService.getPendingOfflineDocs();
        let offlineImages = await this.imageDocService.getPendingOfflineImages();

        console.log("Doc to upload :" + offlineDocs);
        console.log("Images to upload :" + offlineImages);

        if (offlineDocs && offlineDocs.length > 0) {
            for (let index = 0; index < offlineDocs.length; index++) {
                const offlineDoc = offlineDocs[index];
                if (StringUtils.isNotBlank(offlineDoc.parentDocId)) {
                    try {
                        let doc: Doc = await this.imageDocService.getDoc(offlineDoc.parentDocId);
                        if (doc) {
                            let filepath = this.docFileDestinationPath + "/" + offlineDoc.fileName;
                            let isFileExist: boolean = await this.nativeApi.checkFileExistsInDevice(this.docFileDestinationPath, offlineDoc.fileName);
                            if (isFileExist) {
                                let fileObj = await this.getFileFromPath(filepath);
                                console.log("Uploading doc: ", fileObj);
                                let resultDoc = await this.processSelectedFile(doc, offlineDoc.fileName, fileObj["file"], offlineDoc.imoNo, null, offlineDoc);
                            }
                        }
                    } catch (e) {
                        console.log("doc id not availvle:", e);
                    }
                }
            }
        }
        if (offlineImages && offlineImages.length > 0) {
            for (let index = 0; index < offlineImages.length; index++) {
                const offlineImage = offlineImages[index];
                if (StringUtils.isNotBlank(offlineImage.parentDocId)) {
                    try {
                        let doc: Image = await this.imageDocService.getImage(offlineImage.parentDocId);
                        if (doc) {
                            let filepath = this.docFileDestinationPath + "/" + offlineImage.fileName;
                            let isFileExist: boolean = await this.nativeApi.checkFileExistsInDevice(this.docFileDestinationPath, offlineImage.fileName);
                            if (isFileExist) {
                                let fileObj = await this.getFileFromPath(filepath);
                                console.log("Uploading img: ", fileObj);
                                let resultDoc = await this.processSelectedFile(doc, offlineImage.fileName, fileObj["file"], offlineImage.imoNo, null, offlineImage);
                            }
                        }
                    } catch (e) {
                        console.log("image doc id not availvle:", e);
                    }
                }
            }
        }

    }


    async getFileFromPath(filepath) {
        let entry = await this.file.resolveLocalFilesystemUrl(filepath);


        return new Promise((resolve, reject) => {
            this.file
                .resolveLocalFilesystemUrl(filepath)
                .then(entry => {
                    (<FileEntry>entry).file(file => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const imgBlob = new Blob([reader.result], {
                                type: file.type
                            });
                            //console.log("Inside FileEntry of getFileFromPath imgBlob:----");
                            //console.log(imgBlob);
                            resolve({
                                file: imgBlob,
                            });

                        };
                        reader.readAsArrayBuffer(file);
                    });
                })
                .catch(error => {
                    reject(error);
                });
        });
    }


    public dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var dw = new DataView(ab);
        for (var i = 0; i < byteString.length; i++) {
            dw.setUint8(i, byteString.charCodeAt(i));
        }
        // write the ArrayBuffer to a blob, and you're done
        return new Blob([ab], { type: mimeString });
    }

    /**
     * Generate name for File
     * 
     * @param {string} prefixId 
     * @returns {string} 
     */
    public generateName(prefixId: string): string {
        let fileName = prefixId.substr(0, prefixId.lastIndexOf('.'));
        let extenstion = prefixId.substr(prefixId.lastIndexOf('.'));
        return fileName + extenstion;
        // return fileName + AbstractDao.SEPARATOR + new Date().getTime() + extenstion;
    }

    public getDocType(fileName: string): DocTypeEnum {
        if (StringUtils.isBlank(fileName)) {
            return DocTypeEnum.NONE;
        }
        let extension: string = fileName.substr(fileName.lastIndexOf('.') + 1);
        if (Constants.ACCEPTED_IMAGE_FORMATS.indexOf(extension) >= 0) {
            return DocTypeEnum.IMAGE;
        }
        else if (Constants.ACCEPTED_DOC_FORMATS.indexOf(extension) >= 0) {
            return DocTypeEnum.DOC;
        }
        return DocTypeEnum.OTHER;
    }

    public filterName(fileName: string): string {
        fileName = fileName.replace(".", "");
        fileName = fileName.replace("/", "");
        fileName = fileName.replace("\\", "");
        fileName = fileName.replace(",", "");
        fileName = fileName.replace("?", "");
        fileName = fileName.replace(" ", "_");
        return fileName;
    }

    // async uploadFileTest2(fileName: string, fileURI: any, imoNo: string, contentType?: string) {
    //     let fileOptions = this.generateFileKey(fileName, imoNo, contentType);

    //     this.logger.debug("uploadFileTest fileName:%s and IMO:%s", fileName, imoNo);
    //     this.logger.debug("uploadFileTest FileOptions:", fileOptions);
    //     // this.logger.debug("uploadFileTest fileURI:", fileURI);
    //     let uri: any = encodeURI(this._baseUrlFileHandler + Constants.ENDPOINT_UPLOAD);
    //     this.logger.debug("uploadFileTest Endpint:", uri);


    //     // const request_headers = { 's3key': fileOptions.s3key };
    //     var request_body: FormData = new FormData();
    //     request_body.append('file', fileURI, fileName);

    //     // let result = this.newFileHandlerHttpRequest().post('/multipart/upload').wi.withBody(request_body).do();

    //     let result = this.fileUpload(Constants.ENDPOINT_UPLOAD+"?s3key="+fileOptions.s3key, request_body, null);
    //     this.logger.debug("uploadFileTest END:",result);
    // }

    // async uploadFileTest(fileName: string, fileURI: any, imoNo: string, contentType?: string) {
    //     let fileOptions = this.generateFileKey(fileName, imoNo, contentType);

    //     this.logger.debug("uploadFileTest fileName:%s and IMO:%s", fileName, imoNo);
    //     this.logger.debug("uploadFileTest FileOptions:", fileOptions);
    //     // this.logger.debug("uploadFileTest fileURI:", fileURI);
    //     let uri: any = encodeURI(this._baseUrlFileHandler + Constants.ENDPOINT_UPLOAD);//+"?s3key="+fileOptions.s3key);
    //     this.logger.debug("uploadFileTest Endpint:", uri);
    //     let options: FileUploadOptions = {};
    //     options.fileKey = "file";
    //     options.fileName = fileName;//fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
    //     options.mimeType = fileOptions.contentType;
    //     options.params = { // <----- headers object added here
    //             //'Content-Type': cntType,
    //             's3key': fileOptions.s3key,
    //         }
    //     // options.headers = { // <----- headers object added here
    //     //     //'Content-Type': cntType,
    //     //     's3key': fileOptions.s3key,
    //     // }
    //     // this.fileTransfer = this.transfer.create();
    //     this.logger.debug("uploadFileTest Options:", options);
    //     let result = await this.fileTransfer.upload(fileURI, uri, options);
    //     this.logger.debug("uploadFileTest result", result);
    //     // this.fileTransfer.upload(fileURI, uri, options).then((data) => {
    //     //     this.logger.debug("uploadFileTest SUCCESS", data);
    //     // }, (err) => {
    //     //     this.logger.debug("uploadFileTest Error", err);
    //     //     alert("err1 " + JSON.stringify(err));
    //     //     // resolve("error");
    //     //     // error
    //     // });
    //     this.logger.debug("uploadFileTest END:", this.fileTransfer);
    // }

    // uploadFile(fileUrl, imoNo: string, contentType?: string) {



    //     return new Promise(resolve => {

    //         this.file.resolveLocalFilesystemUrl(fileUrl).then(oneFile => {

    //             // const fileTransfer: FileTransferObject = this.transfer.create();


    //             let fileOptions = this.generateFileKey(fileUrl, imoNo, contentType);

    //             this.logger.debug("uploadFile content type", fileOptions.contentType);

    //             let options: FileUploadOptions = {};
    //             options.fileKey = "file";
    //             options.fileName = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
    //             options.mimeType = fileOptions.contentType;
    //             let uri: any = this._baseUrlFileHandler + Constants.ENDPOINT_UPLOAD;
    //             options.headers = { // <----- headers object added here
    //                 //'Content-Type': cntType,
    //                 's3key': fileOptions.s3key
    //             }

    //             this.fileTransfer.upload(fileUrl, uri, options).then((data) => {
    //                 resolve();
    //             }, (err) => {
    //                 this.logger.debug("uploadFile Error", err);
    //                 alert("err1 " + JSON.stringify(err));
    //                 resolve("error");
    //                 // error
    //             })

    //             // this.appmanager.getLocalData("token").then((token) => {
    //             //     options.headers = { // <----- headers object added here
    //             //         //'Content-Type': cntType,
    //             //         'filekey': serveruplpoadFolder,
    //             //         'Authorization': 'Bearer ' + token
    //             //     }

    //             //     this.fileTransfer.upload(fileUrl, uri, options).then((data) => {
    //             //         resolve();
    //             //     }, (err) => {
    //             //         this.logger.debug("uploadFiletoS3WithFileTransfer Error", err);
    //             //         alert("err1 " + JSON.stringify(err));
    //             //         resolve("error");
    //             //         // error
    //             //     })
    //             // })
    //         }, (err) => {
    //             this.logger.debug("uploadFiletoS3WithFileTransfer Error", err);
    //             alert("err2 " + JSON.stringify(err) + " " + fileUrl);
    //             resolve("error");
    //             // error
    //         })
    //     })
    // }

}
