
import * as _ from 'lodash';

import { LogLevel } from '../logger';
import { AbstractLogWriter } from './abstract-writer';

export abstract class AbstractLogJsonWriter extends AbstractLogWriter {

    private static readonly MAX_SIZE = 1000;

    protected logToJson(log: { level: LogLevel, name: string, message: string, param: any[] }): string {
        let paramsAsJson;
        if (log.param != null) {
            paramsAsJson = log.param
                // to JSON
                .map((p) => this.stringify(p))
                // truncate the result as it may be too big
                .map((p) => _.truncate(p, { length: AbstractLogJsonWriter.MAX_SIZE }));
        }

        return JSON.stringify({
            date: new Date().toISOString(),
            level: log.level,
            name: log.name,
            message: log.message,
            param: paramsAsJson
        });
    }

    private stringify(obj) {
        let replacer = null;
        if (obj instanceof Error) {
            // remove some angular properties which are not serialization friendly (circular reference, window object, ...)
            replacer = Object.getOwnPropertyNames(obj).filter((prop) => prop !== 'ngDebugContext' && prop !== 'ngErrorLogger');
        }

        try {
            return JSON.stringify(obj, <any>this.serializer(replacer));
        } catch (error) {
            console.warn("Log-JsonWriter: unexpected error while jsonify", error, obj);
            return "error when jsonify";
        }
    }

    // copied from "https://github.com/moll/json-stringify-safe/blob/master/stringify.js" with support for replacer property as an array
    private serializer(replacer) {
        if (Array.isArray(replacer)) {
            return replacer;
        } else {
            let stack = [], keys = []

            const cycleReplacer = function (key, value) {
                if (stack[0] === value) return "[Circular ~]"
                return "[Circular ~." + keys.slice(0, stack.indexOf(value)).join(".") + "]"
            }

            return function (key, value) {
                if (stack.length > 0) {
                    let thisPos = stack.indexOf(this)
                    ~thisPos ? stack.splice(thisPos + 1) : stack.push(this)
                    ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key)
                    if (~stack.indexOf(value)) value = cycleReplacer.call(this, key, value)
                }
                else stack.push(value)

                return replacer == null ? value : replacer.call(this, key, value);
            }
        }
    }

}
