import { Injector } from "@angular/core";

import { PlatformService } from "../../service/index";
import { Config } from "./config";
import { HttpRequestBuilder } from "../../../commons/utils";
import { Constants } from 'src/app/app.constants';
import { authConfig } from 'src/app/auth/auth.config';

export function ConfigLoaderFactory(config: Config, injector: Injector, platformService: PlatformService): Function {
    let CryptoJS = require("crypto-js");
    let skey: string = "DPSolution@1828";
    if (Constants.MOBILE_BUILD) {
        const url = (platformService.platformIsBrowser() ? '../' : './') + 'assets/config.json';

        return async () => {
            const configData = await new HttpRequestBuilder(injector).get(url).do();
            config.config = configData;
        }
    }
    else {
        return async () => {
            // const url = 'https://dpsolution-int.bureauveritas.com/api/getConfig';
            const url = Constants.LOCAL_BUILD ? 'http://localhost:8080/api/getConfig':'/api/getConfig';
            const configData = await new HttpRequestBuilder(injector).post(url).withBody({
                param1: "GjkgIUTIUI*#kgHF",
                param2: "",
                param3: ""
            }).do();
            console.log("fetchConfig: success:", configData);
            if (configData) {
                let config = configData['response'].data;
                console.log("fetchConfig: config from net:", config);

                Constants.CONFIG_BASE_URL = config["param1"];
                Constants.CONFIG_DB_REMOTE = config["param2"];
                Constants.CONFIG_DB_SYNC_USER = CryptoJS.AES.decrypt(decodeURI(config["param3"]), skey).toString(CryptoJS.enc.Utf8);
                Constants.CONFIG_DB_SYNC_PWD = CryptoJS.AES.decrypt(decodeURI(config["param4"]), skey).toString(CryptoJS.enc.Utf8);
                Constants.CONFIG_OAUTH_BASE_URL = config["param5"];
                Constants.CONFIG_OAUTH_CLIENT_ID = CryptoJS.AES.decrypt(decodeURI(config["param6"]), skey).toString(CryptoJS.enc.Utf8);
                Constants.CONFIG_DO_USERS = config["param7"];//CryptoJS.AES.decrypt(decodeURI(config["param7"]), skey).toString(CryptoJS.enc.Utf8);

                authConfig.clientId = Constants.CONFIG_OAUTH_CLIENT_ID;
                authConfig.loginUrl = Constants.CONFIG_OAUTH_BASE_URL + "oauth/authorize";
                authConfig.tokenEndpoint = Constants.CONFIG_BASE_URL + "/api/oauth/token";
                authConfig.logoutUrl = Constants.CONFIG_OAUTH_BASE_URL + "exit?client_id=" + Constants.CONFIG_OAUTH_CLIENT_ID;

                // this.appManagerService.localStorageService.setConfig(config);
                // this.postConfig();
            }
        }
    }

}

