import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';

export class TemplateSteps {
    public stepId: number;
    public method: string;
    public stepUUID: string;//Uniwue ID for step
    public expResult: string;
    public tableInfo: Array<string> = [];
    public tableData: Array<string> = [];
}

export class Tables {
    public tableInfo: Array<string> = [];
    public tableData: Array<string> = [];
}

export class TestCase extends AbstractDocument {
    public templateId: string;
    public vesselId: string;
    public testNumber: number;
    public testName: string;
    public testObjective: string;
    public testSysConfig: string;
    public comment: string;
    public imageDocIds: Array<string> = [];//TODO: Manoj 24042020: Need changes in code to use this
    public stepsData: Array<TemplateSteps> = [];
    type = Constants.TYPES.TESTCASE;
    public genComment: string;
    public gcTables: Array<Tables> = [];
    public isExcluded: boolean = false;//Added for Jan 2022 release
    public commentTable: Array<any> = [];

}

