import { Injectable, Injector } from "@angular/core";
import PouchDB from 'pouchdb';

// MISC
import { Logger, LoggerFactory } from "../../../commons/log";
import { SynchroPouchDBProviderParamHelper } from "./synchro-pouchdb-param-helper";
import { Constants } from "../../../app.constants";
import { SynchroUtils } from "./synchro-utils";
import { AppManagerService, LocalStorageService } from '../../service';

@Injectable()
export class SynchroPouchDBLiveProvider {

    private readonly logger: Logger = this.injector.get(LoggerFactory).buildLogger("PouchDBLiveSync");

    private parameterHelper: SynchroPouchDBProviderParamHelper = new SynchroPouchDBProviderParamHelper(this.injector);

    public task: any;

    constructor(private injector: Injector, private localStorageService: LocalStorageService) { }

    public async run(localDatabase, remoteDatabase): Promise<void> {
        this.logger.info(`Live sync : start with lastSeq = ${await this.parameterHelper.getLastSeq()}`);

        this.task = localDatabase.sync(
            remoteDatabase,
            await this.parameterHelper.getLiveSyncParameters()
        )
            .on('change', async change => await this.onChange(change))
            .on('complete', async info => await this.onComplete(info))
            .on('active', async () => await this.onActive())
            .on('paused', async err => await this.onPause(err))
            .on('denied', async err => await this.onDenied(err))
            .on('error', async err => await this.onError(err));
    }

    private async onActive(): Promise<void> {
        // replicate resumed (e.g. new changes replicating, user went back online)
        this.logger.debug('Live sync : active');
    }

    private async onChange(change: any): Promise<void> {
        this.logger.debug('Live sync : change', change);

        SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.CHANGE);
        // If has some docs changes
        // if (change && change.change && change.change.docs) {
        //     // For each changed docs
        //     change.change.docs.forEach(doc => {
        //         // If is a template and is deleted
        //         if (SynchroUtils.docIsType(doc, Constants.TYPES.TEMPLATE) && SynchroUtils.isDeleted(doc)) {
        //             SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.TEMPLATE.DELETED);
        //         }
        //         if (SynchroUtils.docIsType(doc, Constants.TYPES.SHIP)) {
        //             SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.SHIP.UPDATED);
        //         }
        //     });
        // }
        await this.parameterHelper.storeLastSeq(change.last_seq);
    }

    private async onComplete(info: any): Promise<void> {
        // changes() was canceled
        this.logger.info('Live sync : complete');
        this.logger.debug('Live sync : complete', info);
        this.localStorageService.setSyncTime(new Date());//this.appManagerService.getCurrentDate());
        SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.SUCCESS);
        await this.parameterHelper.storeLastSeq(info.last_seq);
    }

    private async onDenied(err: any): Promise<void> {
        // a document failed to replicate (e.g. due to permissions)
        this.logger.error('Live sync : denied: Document failed to replicate (e.g. due to permissions): ', err);
        SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.FAILED);
    }

    private async onError(err: any): Promise<void> {
        this.logger.error('Live sync : error', err);
        SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.FAILED);
    }

    private async onPause(err: any): Promise<void> {
        // replication paused (e.g. replication up to date, user went offline)
        if (err) {
            this.logger.error('Live sync : paused: Error', err);
        } else {
            this.logger.debug('Live sync : waiting_for_changes');
            SynchroUtils.publishEvent(this.injector, Constants.EVENTS.DB.SYNC.LIVE.WAITING);
            this.localStorageService.setSyncTime(new Date());//this.appManagerService.getCurrentDate());//TODO: Manoj 07042020 added please check
        }
    }

}