import { Injectable, Injector } from '@angular/core';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { UserProfile, User } from '../../../models';

// PROVIDERS
import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { UserDao, UserProfileDao } from '../../dao';
import { AppManagerService } from '../../app-manager/app-manager.service';
import { VesselAccessEnum } from 'src/app/models/enums';

@Injectable()
export class UserService extends AbstractService {

    constructor(protected injector: Injector,
        private userDao: UserDao,
        private userProfileDao: UserProfileDao) {
        super(injector, "UserService");
    }

    /**
     *
     * @param {User} doc
     * @returns {Promise<User>}
     */
    public async createUser(doc: User): Promise<User> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.username, doc);

        let response = this.userDao.create(doc);
        console.log(response);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<User>}
     */
    public async getUser(docId: string): Promise<User> {
        return await this.userDao.get(docId);
    }

    /**
     *
     * @returns {Promise<Array<User>>}
     */
    public async findAllUser(): Promise<Array<User>> {
        return this.userDao.findAll();
    }

    /**
     *
     * @returns {Promise<Array<User>>}
     */
    public async findUsers(username: string): Promise<Array<User>> {
        var query = {
            type: Constants.TYPES.USER,
            username: username
        }
        return this.userDao.findBasedOnQuery(query);
    }

    /**
     *
     * @param {User} user
     * @returns {Promise<boolean>}
     */
    public async removeUser(user: User): Promise<boolean> {
        return this.userDao.delete(user);
    }

    /**
     *
     * @param {User} doc
     * @returns {Promise<User>}
     */
    public async updateUser(doc: User): Promise<User> {
        // doc.mdt = new Date();
        return this.userDao.update(doc);
    }


    //User profile
    /**
    *
    * @param {UserProfile} doc
    * @returns {Promise<UserProfile>}
    */
    public async createUserProfile(doc: UserProfile): Promise<UserProfile> {
        // doc.mdt = new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.username, doc);

        let response = this.userProfileDao.create(doc);
        console.log(response);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<UserProfile>}
     */
    public async getUserProfile(docId: string): Promise<UserProfile> {
        return await this.userProfileDao.get(docId);
    }

    /**
     *
     * @returns {Promise<Array<UserProfile>>}
     */
    public async findAllUserProfile(): Promise<Array<UserProfile>> {
        return this.userProfileDao.findAll();
    }

    /**
     *
     * @returns {Promise<Array<UserProfile>>}
     */
    public async findUserProfiles(username: string): Promise<Array<UserProfile>> {
        var query = {
            type: Constants.TYPES.USER_PROFILE,
            username: username
        }
        return this.userProfileDao.findBasedOnQuery(query);
    }

    public async getCurrentUserProfile(username: string): Promise<UserProfile> {
        let userProfiles = await this.findUserProfiles(username);
        if (userProfiles && userProfiles.length > 0) {
            return userProfiles[0];
        }
        return undefined;
    }

    public async getCurrentUserVessels(username: string): Promise<Array<string>> {
        let result: Array<string> = [];
        let userProfile = await this.getCurrentUserProfile(username);
        if (userProfile) {
            result = userProfile.vesselIds;
        }
        return result;
    }
    /**
     *
     * @param {UserProfile} userProfile
     * @returns {Promise<boolean>}
     */
    public async removeUserProfile(userProfile: UserProfile): Promise<boolean> {
        return this.userProfileDao.delete(userProfile);
    }

    /**
     *
     * @param {UserProfile} doc
     * @returns {Promise<UserProfile>}
     */
    public async updateUserProfile(doc: UserProfile): Promise<UserProfile> {
        // doc.mdt = new Date();
        return this.userProfileDao.update(doc);
    }

    /**
     *
     * @param {UserProfile} doc
     * @returns {Promise<UserProfile>}
     */
    public async assignVesselToUser(username: string, vesselIMO: string): Promise<UserProfile> {
        let userProfile = await this.getCurrentUserProfile(username);
        if (userProfile) {
            let vesselIds = userProfile.vesselIds;
            let vesselEditIds = userProfile.vesselEditIds;
            if (!vesselIds) {
                vesselIds = [];
            }
            if (!vesselEditIds) {
                vesselEditIds = [];
            }
            let vesselPresent = false;
            let vesselEditPresent = false;

            if (vesselIds.length > 0 && vesselIMO) {
                vesselPresent = vesselIds.indexOf(vesselIMO) > -1;
            }
            if (!vesselPresent) {
                vesselIds.push(vesselIMO);
            }
            if (vesselEditIds.length > 0 && vesselIMO) {
                vesselEditPresent = vesselEditIds.indexOf(vesselIMO) > -1;
            }
            if (!vesselEditPresent) {
                vesselEditIds.push(vesselIMO);
            }
            userProfile.vesselIds = vesselIds;
            userProfile.vesselEditIds = vesselEditIds;
            if (!vesselEditPresent || !vesselPresent) {
                return this.updateUserProfile(userProfile);
            }
            else {
                //NO need to upade
                return userProfile;
            }

        }
        return undefined;
    }

    /**
    *
    * @param {UserProfile} doc
    * @returns {Promise<UserProfile>}
    */
    public async createNewUserProfile(username: string, vesselId: string, appManager: AppManagerService): Promise<UserProfile> {
        let userProfile = new UserProfile();
        userProfile.username = username;
        userProfile.userId = "";//TODO: Manoj to be added
        //TODO: Manoj manage User Profile add pending channels
        // userProfile.fullName = appManager.getUserFullName();
        userProfile.firstName = await appManager.localStorageService.getCurrentFirstName();
        userProfile.lastName = await appManager.localStorageService.getCurrentLastName();
        userProfile.email = await appManager.localStorageService.getCurrentEmail();
        let vesselIds: Array<string> = [];
        let vesselEditIds: Array<string> = [];
        let vesselReviewIds: Array<string> = [];
        let roles: Array<string> = [];
        if (appManager.isUserExpert()) {
            roles.push(Constants.QUALIF_ROLE.FMEA_EXPERT);
        }
        if (appManager.isUserSurveyor()) {
            roles.push(Constants.QUALIF_ROLE.FMEA_SURVEYOR);
        }
        userProfile.roles = roles;
        if (StringUtils.isNotBlank(vesselId)) {
            vesselIds.push(vesselId);
            vesselEditIds.push(vesselId);
        }

        userProfile.vesselIds = vesselIds;
        userProfile.vesselEditIds = vesselEditIds;
        userProfile.vesselReviewIds = vesselReviewIds;
        userProfile.vesselApproveIds = vesselReviewIds;
        userProfile.vesselOBTIds = vesselReviewIds;
        this.logger.info("Create Vessel DP Config: Create: userProfile: ", userProfile);
        userProfile = await this.createUserProfile(userProfile);
        return userProfile;
    }


    public async getVessselAccess(username: string, vesselIMO: string): Promise<Array<VesselAccessEnum>>{
        let result:Array<VesselAccessEnum> = [VesselAccessEnum.GUEST];
        if (StringUtils.isBlank(username) || StringUtils.isBlank(vesselIMO)) {
            return result;
        }
        let userProfile = await this.getCurrentUserProfile(username);
        if (userProfile) {
            if (userProfile.vesselIds!=null && userProfile.vesselIds.length>0) {
                if (userProfile.vesselIds.indexOf(vesselIMO) > -1){
                    // HAS SOME ACCESS
                }
            }
            if (userProfile.vesselApproveIds!=null && userProfile.vesselApproveIds.length>0) {
                if (userProfile.vesselApproveIds.indexOf(vesselIMO) > -1){
                    result.push(VesselAccessEnum.APPROVE);
                }
            }
            if (userProfile.vesselReviewIds!=null && userProfile.vesselReviewIds.length>0) {
                if (userProfile.vesselReviewIds.indexOf(vesselIMO) > -1){
                    result.push(VesselAccessEnum.REVIEW);
                }
            }
            if (userProfile.vesselOBTIds!=null && userProfile.vesselOBTIds.length>0) {
                if (userProfile.vesselOBTIds.indexOf(vesselIMO) > -1){
                    result.push(VesselAccessEnum.ONBOARD);
                }
            }
            if (userProfile.vesselEditIds!=null && userProfile.vesselEditIds.length>0) {
                if (userProfile.vesselEditIds.indexOf(vesselIMO) > -1){
                    result.push(VesselAccessEnum.EDIT);
                }
            }

        }
        return result;
    }

}
