import { AbstractDocument } from "./abstract-document.model";
import { ThrusterType } from './thruster-type.model';
import { Constants } from '../app.constants';


export class VesselMainDPEquipments extends AbstractDocument {

    /*public mainEngines: string;
    public mainGenerators: string;
    public emergencyGenerator: string;*/
    public mainSwitchboards: string;
    public emergencySwitchboards: string;
    public dpControlSystem: string;
    public environmentalSensors: string;
    public positionReferenceSystems: string;
    public backupDpControlSystem: string;
    public backupEnvironmentalSensors: string;
    public backupPositionReferenceSystems: string;
    public independentJoystickSystem: string;
    public vesselManagementSystem: string;
    public powerManagementSystem: string;
    public mandatoryComplete: boolean;
    public thrusterTypes: ThrusterType[];
    type=Constants.TYPES.VESSEL_MAIN_DP_EQUIP;

}

