import { Injectable, Injector } from '@angular/core';
import { PDFDocument, StandardFonts, degrees, grayscale, rgb, PDFPage, PDFImage, PDFFont } from 'pdf-lib'
// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';
import * as _ from 'lodash';

// MODEL


// PROVIDERS
import { Constants } from 'src/app/app.constants';
import { Logger, LoggerFactory } from 'src/app/commons/log';
import { PDRectangle, ReportConst, Point, RectTextStyle, ImageAspect, FontStyle, FontAlign, TextContentType, FontSet, NewPage, CellBasic, CellInTable } from './pdf-constants';


@Injectable()
export class PDFGenerateUtilsService {

    protected logger: Logger;
    public symbolFont: PDFFont;
    public zapfDingbatsFont: PDFFont;
    public parentPage: any = false;
    public currentPage: any;
    public currentTempCursorY:number = 0;
    public preTempCursorY: number = 0;

    constructor(protected injector: Injector) {
        this.logger = injector.get(LoggerFactory).buildLogger("PDFGenerateUtilsService");
    }

    openFileInWindow(pdfBytes) {
        var byteArrays = [];
        byteArrays.push(pdfBytes); //required to convert unit8array to blob and use that blob to create a pdf or open in browser
        var blob = new Blob(byteArrays, { type: "application/pdf" });
        //if desktop :
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, '_blank');
    }

    async drawImage(pdfDoc: PDFDocument, page: PDFPage, rect: PDRectangle, resourceURL: string, aspect?: ImageAspect): Promise<PDRectangle> {
        if (StringUtils.isBlank(resourceURL)) {
            return;
        }
        const imageBytes = await fetch(resourceURL).then((res) => res.arrayBuffer());
        let image: PDFImage;
        if (resourceURL.endsWith('png') || resourceURL.endsWith('PNG')) {
            image = await pdfDoc.embedPng(imageBytes);
        }
        else {
            image = await pdfDoc.embedJpg(imageBytes);
        }
        if (image && image.height != 0) {
            let imageAspect: number = image.width / image.height;
            let containerAspect: number = rect.width / rect.height;

            if (!aspect) {
                aspect = ImageAspect.CENTER;
            }

            if (aspect != ImageAspect.FILL) {
                if (imageAspect < containerAspect) {//means portrait image
                    let newWidth = rect.height * imageAspect;
                    let dx = (rect.width - newWidth) / 2;
                    let newX = rect.x + dx;
                    if (aspect == ImageAspect.START) {
                        newX = rect.x;
                    }
                    else if (aspect == ImageAspect.END) {
                        newX = rect.x + 2 * dx;
                    }
                    rect.x = newX;
                    rect.width = newWidth;
                }
                else {
                    let newHeight = rect.width / imageAspect;
                    let dy = (rect.height - newHeight) / 2;
                    let newY = rect.y + dy;
                    if (aspect == ImageAspect.START) {
                        newY = rect.y;
                    }
                    else if (aspect == ImageAspect.END) {
                        newY = rect.y + 2 * dy;
                    }
                    rect.y = newY;
                    rect.height = newHeight;
                }
            }
            // const jpgDims = image.scale(0.5)
            page.drawImage(image, rect);
        }
        return rect;

    }

    drawRectangle(page: any, rect: PDRectangle, fillColor?: any, borderWidth?: number, borderColor?: any) {
        if (!fillColor) {
            fillColor = ReportConst.COLOR_WHITE;
        }
        if (!borderWidth) {
            borderWidth = ReportConst.TABLE_BORDER_WIDTH_NORMAL;
        }
        if (!borderColor) {
            borderColor = ReportConst.COLOR_BLACK;
        }
        page.drawRectangle({
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
            rotate: degrees(0),
            borderWidth: borderWidth,
            borderColor: borderColor,
            color: fillColor,
            opacity: 1,
            borderOpacity: 1,
        })
    }

    drawLine(page: any, color: any, startPoint: Point, endPoint: Point, thickness: number) {
        page.drawLine({
            start: startPoint,
            end: endPoint,
            thickness: thickness,
            color: color
        })
    }

    drawText(page: any, fontColor: any, rect: PDRectangle, text: string,
        justify: boolean, fontSize: number, font: any, centerText: boolean): number {
        let lines = 0;
        lines = this.addParagraph(page, rect.width, rect.x, rect.y, text, justify, fontColor, fontSize, font, centerText, rect.height, rect.x, rect.y);
        return lines;
    }

    updateCursorY(cursorY: number, lines: number, font: PDFFont, fontSize: number): number {
        cursorY = cursorY - (font.heightAtSize(fontSize) * ReportConst.NEW_LINE_FONT_SIZE_FACTOR * lines);
        return cursorY
    }

    cleanText(text: string, forCount: boolean, ignoreSymbols?: boolean): string {
        // this.logger.debug("cleanText: START:",text);
        if (!text) {
            text = "";
        }
        if (text == " ") {
            return text;
        }
        // this.logger.debug("cleanText Start",text);
        // text = text.replace('
        // ', "");
        text = text.toString();  // Kundan: number needs to be converted to string before trim.
        text = text.trim();
        // text = text.replace('\'', "");//??/
        // text = text.replace('\'', "");????
        // text = text.replace('\n', Constants.STRING_SEPARATOR_2);
        // text = text.replace('\r', Constants.STRING_SEPARATOR_2);
        // text = text.replace('\f', Constants.STRING_SEPARATOR_2);
        // text = text.replace('\t', " ");
        // text = text.replace('\0', ", ");
        // text = text.replace("\n", Constants.STRING_SEPARATOR_2);
        // text = text.replace("\r", Constants.STRING_SEPARATOR_2);
        // text = text.replace("\f", Constants.STRING_SEPARATOR_2);
        // text = text.replace("\t", " ");
        // text = text.replace("\0", ", ");
        // text = text.replace("　", " ");//added 21082019 U+3000 ideographic space to normal space
        // text = text.replace(" ", " ");//FOR ERROR: U+00A0 ('nbspace') is not available in this font Helvetica encoding: WinAnsiEncoding
        // this.logger.debug("cleanText END",text);

        text = text.split("\n").join(Constants.STRING_SEPARATOR_2);
        text = text.split("\r").join(Constants.STRING_SEPARATOR_2);
        text = text.split("\f").join(Constants.STRING_SEPARATOR_2);
        text = text.split("\t").join(" ");
        text = text.split("\0").join(", ");
        text = text.split("　").join(" ");
        text = text.split(" ").join(" ");

        if (!ignoreSymbols) {
            for (let index = 0; index < Constants.SYMBOLS.length; index++) {
                const element = Constants.SYMBOLS[index];
                text = text.split(element).join(forCount ? "#" : Constants.SYMBOL_PLACEHOLDER_START + index + Constants.SYMBOL_PLACEHOLDER_END);
            }
        }

        // text = text.split(Constants.SYMBOLS[0]).join(Constants.SYMBOL_PLACEHOLDER_START+"0"+Constants.SYMBOL_PLACEHOLDER_END);
        // text = text.split(Constants.SYMBOLS[0]).join(Constants.SYMBOL_PLACEHOLDER_START+"0"+Constants.SYMBOL_PLACEHOLDER_END);
        // text = text.split(Constants.SYMBOLS[0]).join(Constants.SYMBOL_PLACEHOLDER_START+"0"+Constants.SYMBOL_PLACEHOLDER_END);
        // text = text.split(Constants.SYMBOLS[0]).join(Constants.SYMBOL_PLACEHOLDER_START+"0"+Constants.SYMBOL_PLACEHOLDER_END);

        text = text.trim();
        // this.logger.debug("cleanText: END:",text);
        return text;
    }

    addParagraph(page: PDFPage, width: number, sx: number, sy: number, text: string,
        justify: boolean, fontColor: any, fontSize: number, font: any, centerText: boolean, height?: number, posX?: number, posY?: number, isCell?: boolean, returnLines?: boolean, measureOnly?: boolean): any {
        const LEADING: number = -1.5 * fontSize;
        const LEADING_1: number = -1.3 * fontSize;
        let lines: Array<string> = [];
        let orgText = text;
        // text = this.cleanText(text, false, true);
        try {
            lines = this.parseLines(text, width, fontSize, font, isCell, true, false);
            this.logger.debug("addParagraph: Start: lines:", JSON.stringify(lines));
            let cursorY = sy;
            for (let index = 0; index < lines.length; index++) {
                const line = lines[index];
                this.logger.debug("addParagraph: line:", JSON.stringify(line));
                let subStringFORMAT = line;
                subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_START).join("");
                subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_END).join("");
                this.logger.debug("addParagraph: subStringFORMAT:", JSON.stringify(subStringFORMAT));
                let charSpacing: number = 0;
                if (justify) {
                    if (subStringFORMAT.length > 1) {
                        let size: number = font.widthOfTextAtSize(subStringFORMAT, fontSize);
                        let free: number = width - size;
                        if (free > 0 && lines[(lines.length - 1)] != (subStringFORMAT)) {
                            charSpacing = free / (subStringFORMAT.length - 1);
                            // this.logger.debug("addParagraph: subStringFORMAT last char:",JSON.stringify(subStringFORMAT.substring((subStringFORMAT.length - 1))));
                        }
                    }
                }
                if (justify && index == lines.length - 1) {
                    justify = false;
                }
                if (centerText) {//} && height && posX && posY) {
                    let size: number = font.widthOfTextAtSize(subStringFORMAT, fontSize);
                    let free: number = width - size;
                    let freeY: number = 0;
                    let xOffset: number = sx + free / 2;
                    let yOffset: number = cursorY;
                    if (height && posX && posY) {//Needed for centering text within a box
                        freeY = height - ((-LEADING_1) * lines.length);
                        xOffset = posX + free / 2;
                        yOffset = posY + height - ((-LEADING_1) * (index + 1)) - freeY / (2) + ((-LEADING_1) / 4);
                    }
                    if (!measureOnly)
                        this.drawTextWithSymbol(page, line, xOffset, yOffset, fontSize, font, fontColor, charSpacing, justify, width);
                    // page.drawText(line, {
                    //     x: xOffset,
                    //     y: yOffset,
                    //     size: fontSize,
                    //     font: font,
                    //     color: fontColor,
                    //     // lineHeight: 50
                    // });
                    cursorY = cursorY - (font.heightAtSize(fontSize) * ReportConst.NEW_LINE_FONT_SIZE_FACTOR);
                }
                else {
                    if (!measureOnly)
                        this.drawTextWithSymbol(page, line, sx, cursorY, fontSize, font, fontColor, charSpacing, justify, width);
                    // page.drawText(line, {
                    //     x: sx,
                    //     y: cursorY,
                    //     size: fontSize,
                    //     font: font,
                    //     color: fontColor,
                    //     // lineHeight: 50
                    // });
                    cursorY = cursorY - (font.heightAtSize(fontSize) * ReportConst.NEW_LINE_FONT_SIZE_FACTOR);
                }
            }
        }
        catch (e) {
            this.logger.error("addParagraph: Error for:" + orgText + " formatted:" + text + " error:", e);
        }

        return returnLines ? lines : lines.length;
    }

    drawTextWithSymbol(page: PDFPage, line: string, x: number, y: number, fontSize: number, font: PDFFont, fontColor: any, charSpacing: number, justify: boolean, contentWidth: number) {

        // let subStringFORMAT = line;
        // subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_START).join("");
        // subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_END).join("");
        let indexOfSymbol = line.indexOf(Constants.SYMBOL_PLACEHOLDER_START);
        let indexOfSymbolEnd = line.indexOf(Constants.SYMBOL_PLACEHOLDER_END);
        let symbols = [];
        let subStringStartIndex = 0;
        if (indexOfSymbol > -1 && indexOfSymbolEnd > 1) {
            let symbolsNumber = line.substring(indexOfSymbol + Constants.SYMBOL_PLACEHOLDER_START.length, indexOfSymbolEnd);
            let symbol = Constants.SYMBOLS[symbolsNumber];
            // let symbolIndex = indexOfSymbol + Constants.SYMBOL_PLACEHOLDER_START.length;
            symbols.push({ symbol: symbol, index: indexOfSymbol, symbolsNumber: symbolsNumber });
            let symbolEnd = indexOfSymbolEnd + Constants.SYMBOL_PLACEHOLDER_END.length;
            let subString = line.substring(symbolEnd);
            subStringStartIndex += symbolEnd;
            indexOfSymbol = subString.indexOf(Constants.SYMBOL_PLACEHOLDER_START);
            indexOfSymbolEnd = subString.indexOf(Constants.SYMBOL_PLACEHOLDER_END);
            this.logger.debug("drawTextWithSymbol: subString:" + subString + " indexOfSymbol:" + indexOfSymbol + " indexOfSymbolEnd:" + indexOfSymbolEnd, "symbol:", symbols);
            let count = 0;
            while (indexOfSymbol > -1 && indexOfSymbolEnd > 1 && count < 100) {
                count++;
                let symbolsNumber = subString.substring(indexOfSymbol + Constants.SYMBOL_PLACEHOLDER_START.length, indexOfSymbolEnd);
                let symbol = Constants.SYMBOLS[symbolsNumber];
                // let symbolIndex = indexOfSymbol + Constants.SYMBOL_PLACEHOLDER_START.length;
                symbols.push({ symbol: symbol, index: indexOfSymbol + subStringStartIndex, symbolsNumber: symbolsNumber });
                symbolEnd = indexOfSymbolEnd + Constants.SYMBOL_PLACEHOLDER_END.length;
                subString = subString.substring(symbolEnd);//indexOfSymbolEnd + Constants.SYMBOL_PLACEHOLDER_END.length);
                subStringStartIndex += symbolEnd;
                indexOfSymbol = subString.indexOf(Constants.SYMBOL_PLACEHOLDER_START);
                indexOfSymbolEnd = subString.indexOf(Constants.SYMBOL_PLACEHOLDER_END);
                this.logger.debug("drawTextWithSymbol: WHILE subString:" + subString + " indexOfSymbol:" + indexOfSymbol + " indexOfSymbolEnd:" + indexOfSymbolEnd, "symbol:", symbols);
            }
        }

        if (symbols.length > 0) {
            let startIndex = 0;
            let xPos = x;
            for (let index = 0; index < symbols.length; index++) {
                const element = symbols[index];
                let lineSub = line.substring(startIndex, element.index);
                if (StringUtils.isNotBlank(lineSub)) {
                    xPos = this.drawTextWithJustify(page, lineSub, xPos, y, font, fontSize, fontColor, justify, charSpacing);
                    // page.drawText(lineSub, {
                    //     x: xPos,
                    //     y: y,
                    //     size: fontSize,
                    //     font: font,
                    //     color: fontColor,
                    //     // lineHeight: 50
                    // });
                }
                // xPos = xPos + font.widthOfTextAtSize(lineSub, fontSize);
                startIndex = startIndex + lineSub.length;
                let finalSymbolFont = element.symbolsNumber >= Constants.SYMBOLS_START_INDEX ? this.symbolFont : this.zapfDingbatsFont;
                xPos = this.drawTextWithJustify(page, element.symbol, xPos + font.widthOfTextAtSize(" ", fontSize), y, finalSymbolFont, fontSize, fontColor, justify, charSpacing);  //DT-340 bug Fixed
                // page.drawText(element.symbol, {
                //     x: xPos,
                //     y: y,
                //     size: fontSize,
                //     font: finalSymbolFont,
                //     color: fontColor,
                //     // lineHeight: 50
                // });
                // xPos = xPos + finalSymbolFont.widthOfTextAtSize(element.symbol, fontSize);
                startIndex = startIndex + element.symbolsNumber.length + Constants.SYMBOL_PLACEHOLDER_START.length + Constants.SYMBOL_PLACEHOLDER_END.length;
            }
            if (startIndex < line.length - 1) {
                let lineSub = line.substring(startIndex, line.length);
                if (StringUtils.isNotBlank(lineSub)) {
                    xPos = this.drawTextWithJustify(page, lineSub, xPos, y, font, fontSize, fontColor, justify, charSpacing);
                    // page.drawText(lineSub, {
                    //     x: xPos,
                    //     y: y,
                    //     size: fontSize,
                    //     font: font,
                    //     color: fontColor,
                    //     // lineHeight: 50
                    // });
                }
            }
        }
        else {
            this.drawTextWithJustify(page, line, x, y, font, fontSize, fontColor, justify, charSpacing);
            // if (justify && charSpacing > 0) {
            //     let tempX = x;
            //     for (let index = 0; index < line.length; index++) {
            //         const element = line[index];
            //         page.drawText(element, {
            //             x: tempX,
            //             y: y,
            //             size: fontSize,
            //             font: font,
            //             color: fontColor,
            //             // lineHeight: 50
            //         });
            //         tempX = tempX + font.widthOfTextAtSize(element, fontSize) + charSpacing;
            //     }

            // }
            // else {
            //     page.drawText(line, {
            //         x: x,
            //         y: y,
            //         size: fontSize,
            //         font: font,
            //         color: fontColor,
            //         // lineHeight: 50
            //     });
            // }
        }
    }

    drawTextWithJustify(page: PDFPage, line: string, x: number, y: number, font: any, fontSize: number, fontColor: any, justify: boolean, charSpacing: number) {
        let tempX = x;
        if (justify && charSpacing > 0) {
            let totalSpacing = line.length * charSpacing;
            let totalSpaces = 0;
            for (let index = 0; index < line.length; index++) {
                const element = line[index];
                if (element === " ") {
                    totalSpaces++;
                }
            }
            let spaceCharSapcing = 0.0;
            if (totalSpaces > 0) {
                spaceCharSapcing = totalSpacing / totalSpaces;
            }
            for (let index = 0; index < line.length; index++) {
                const element = line[index];
                page.drawText(element, {
                    x: tempX,
                    y: y,
                    size: fontSize,
                    font: font,
                    color: fontColor,
                    // lineHeight: 50
                });
                if (element === " ") {
                    tempX = tempX + font.widthOfTextAtSize(element, fontSize) + spaceCharSapcing;
                }
                else {
                    tempX = tempX + font.widthOfTextAtSize(element, fontSize);
                }
                // tempX = tempX + font.widthOfTextAtSize(element, fontSize) + charSpacing;
            }

        }
        else {
            page.drawText(line, {
                x: x,
                y: y,
                size: fontSize,
                font: font,
                color: fontColor,
                // lineHeight: 50
            });
            tempX = tempX + font.widthOfTextAtSize(line, fontSize);
        }
        return tempX;
    }

    parseLines(orgText: string, width: number, fontSize: number, font: any, isCell: boolean, cleanText: boolean, forCount: boolean): Array<string> {
        let lines: Array<string> = [];
        let lastSpace: number = -1;
        if (cleanText) {
            orgText = this.cleanText(orgText, forCount);
        }
        let orgWidth = width;
        let textArray: string[] = orgText.split(Constants.STRING_SEPARATOR_2);
        let cellOffsetX = ReportConst.TABLE_CONTENT_TEXT_START_OFFSET + ReportConst.TABLE_BORDER_WIDTH_NORMAL;// / 2;
        if (isCell) {
            width -= 2 * cellOffsetX;
        }
        this.logger.debug("parseLines: orgWidth:" + orgWidth + " width:" + width + " isCell:" + isCell + " cleanText:" + cleanText + " orgText:" + orgText + " textArray:" + JSON.stringify(textArray));
        if (textArray && textArray.length > 0) {
            for (let index = 0; index < textArray.length; index++) {
                let text = textArray[index];

                if (text.length > 0) {
                    while (text.length > 0) {
                        let spaceIndex = text.indexOf(" ", lastSpace + 1);
                        if (spaceIndex < 0)
                            spaceIndex = text.length;
                        let subString: string = text.substring(0, spaceIndex);
                        let subStringFORMAT = subString;
                        // this.logger.debug("parseLines: 1 subString" + subString);
                        subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_START).join("");
                        subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_END).join("");

                        let justTextWidth: number = font.widthOfTextAtSize(subStringFORMAT, fontSize);
                        let size: number = justTextWidth;//fontSize * font.widthOfTextAtSize(subString, fontSize) / 1000;
                        // this.logger.debug("parseLines: 2 size: " + size + " subStringFORMAT:" + subStringFORMAT);
                        // size += 10;
                        if (size > width) {
                            if (lastSpace < 0) {
                                lastSpace = spaceIndex;
                            }
                            subString = text.substring(0, lastSpace);
                            subStringFORMAT = subString;
                            subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_START).join("");
                            subStringFORMAT = subStringFORMAT.split(Constants.SYMBOL_PLACEHOLDER_END).join("");
                            let size1: number = font.widthOfTextAtSize(subStringFORMAT, fontSize);//fontSize * font.widthOfTextAtSize(subString, fontSize) / 1000;
                            // size1 += 10;///readded condition 06092020 to test
                            // this.logger.debug("parseLines: 3 lastSpace: " + lastSpace + " size1:" + size1 + " width:" + width + " subStringFORMAT:" + subStringFORMAT);
                            if (size1 > width) {
                                let size1Boundary: number = size1;
                                let widthBoundary: number = width;// - 10;
                                let countL: number = Math.floor(size1Boundary / widthBoundary);//int
                                if (size1Boundary % widthBoundary > 0) {
                                    countL++;
                                }
                                let startIndex: number = 0;
                                let endIndex: number = 0;
                                let lettersPerLine: number = Math.ceil((subString.length * 1.0) / (countL * 1.0));
                                for (let i = 0; i <= countL; i++) {
                                    if (i == countL) {
                                        endIndex = subString.length;
                                    } else {
                                        endIndex = (i + 1) * lettersPerLine;
                                    }
                                    if (endIndex <= subString.length) {
                                        let subStringToAdd: string = text.substring(startIndex, endIndex);
                                        if (StringUtils.isNotBlank(subStringToAdd) || (i < countL - 1)) {//added condition 06092020
                                            // this.logger.debug("parseLines: "+text+" current:"+subStringToAdd+" lineWidth:"+ReportConst.TABLE_BORDER_WIDTH_NORMAL+" sx:"+cellOffsetX+" width:"+width+" size1:"+size1+" size:"+size);
                                            // this.logger.debug("parseLines: 4 PUSH startIndex: " + startIndex + " endIndex:" + endIndex + " subStringToAdd:" + subStringToAdd);
                                            lines.push(subStringToAdd + ((i < countL - 1) ? "-" : ""));// +":"+startIndex+":"+endIndex);    
                                        }
                                        startIndex = endIndex;
                                    }
                                }
                            }
                            else {
                                // this.logger.debug("parseLines: "+text+" current:"+subString+" lineWidth:"+ReportConst.TABLE_BORDER_WIDTH_NORMAL+" sx:"+cellOffsetX+" width:"+width+" size1:"+size1+" size:"+size);
                                // this.logger.debug("parseLines: 5 PUSH subString: " + subString);
                                lines.push(subString);
                            }
                            text = text.substring(lastSpace).trim();
                            lastSpace = -1;
                        }
                        else if (spaceIndex == text.length) {
                            // this.logger.debug("parseLines: "+text+" lineWidth:"+ReportConst.TABLE_BORDER_WIDTH_NORMAL+" sx:"+cellOffsetX+" width:"+width+" size:"+size);
                            // this.logger.debug("parseLines: 6 PUSH text: " + text);
                            lines.push(text);
                            text = "";
                            lastSpace = -1;
                        } else {
                            // this.logger.debug("parseLines: 10 lastSpace: " + lastSpace);
                            lastSpace = spaceIndex;
                        }
                    }
                }
                else {
                    if (StringUtils.isBlank(text) && index < textArray.length - 1) {
                        text = " ";
                        // this.logger.debug("parseLines: 12 PUSH blank: " + text);
                        lines.push(text);
                        text = "";
                        lastSpace = -1;

                    }
                }

            }
        }
        if (lines.length <= 0) {
            lines.push(" ");
        }
        return lines;
    }


    getSelectedFont(fontStyle: FontStyle, fontSet: FontSet): PDFFont {
        let result = fontSet.regular;
        switch (fontStyle) {
            case FontStyle.BOLD:
                result = fontSet.bold
                break;
            case FontStyle.ITALIC:
                result = fontSet.italic
                break;
            case FontStyle.BOLD_ITALIC:
                result = fontSet.boldItalic
                break;
            default:
                break;
        }
        return result;
    }

    //Table methods
    async createTableSimple(pdfDoc: PDFDocument, page: PDFPage, tableCursorX: number, tableCursorY: number, tableWidth: number, jsonTableStyle: any, jsonTableData: any,
        fontSet: FontSet, measureOnly?: boolean, landscape?: boolean, isInnerTable?: boolean): Promise<NewPage> {
        let tempCursorX = tableCursorX;
        let tempCursorY = tableCursorY;
        let newPage: NewPage = new NewPage();
        newPage.page = page;
        newPage.cursorY = tempCursorY;
        try {

            let title: string = jsonTableData.title;

            let borderColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.border_color);
            let backgroundColor = ReportConst.COLOR_WHITE;//Color.decode(titleStyle.background_color);
            let fontColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.font_color);

            console.log("Line: 574 " + tableCursorY, ReportConst.MIN_TABLE_HEIGHT, ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE);
            if (tableCursorY - ReportConst.MIN_TABLE_HEIGHT < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE && !measureOnly && !isInnerTable) {
                console.log("page break");
                if (landscape) {
                    page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
                }
                else
                    page = pdfDoc.addPage();
                tempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                newPage.isNew = true; //TODO: Manoj what about measureOnly variable?
            }
            newPage.page = page;
            newPage.cursorY = tempCursorY;

            if (title && title.length > 0) {

                let titleStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.title_style);
                try {
                    borderColor = this.convertHexToRgbA(titleStyle.border_color);
                    backgroundColor = this.convertHexToRgbA(titleStyle.background_color);
                    fontColor = this.convertHexToRgbA(titleStyle.font_color);
                } catch (error) {
                    this.logger.error("Execption:", error);
                }
                let lines = 1;
                try {
                    lines = this.parseLines(title, tableWidth, titleStyle.font_size, this.getSelectedFont(titleStyle.font_style, fontSet), true, true, true).length;
                } catch (error) {
                    console.log(error);
                }
                let titleHeight = titleStyle.height * lines;
                tempCursorY -= titleHeight;
                if (!measureOnly) {
                    this.drawRectWithText(page, title, this.getSelectedFont(titleStyle.font_style, fontSet), tempCursorX, tempCursorY, tableWidth, titleHeight,
                        titleStyle.font_size, titleStyle.border_thick, borderColor, backgroundColor, fontColor, titleStyle.font_align == 1, true, true);
                }
            }


            let rowSections = jsonTableData.row_sections;
            if (rowSections != null && rowSections.length > 0) {
                let row0 = rowSections[0].rows;
                if (row0 != null && row0.length > 0) {
                    let row0Items = row0[0];
                    let columns;
                    if (null != jsonTableData.columns)
                        columns = jsonTableData.columns;

                    //RESETTING THE COLUMN OBJECT
                    if (columns == null || columns.length <= 0) {
                        let columnsTemp = [];// = new JsonArray();
                        let columnWidthDefault: number = (1.0 / (row0Items.length * 1.0));
                        for (let i = 0; i < row0Items.length; i++) {
                            let columnObj: any = {};
                            columnObj.name = "";
                            columnObj.width = columnWidthDefault;
                            columnsTemp.push(columnObj);
                        }
                        columns = columnsTemp;
                    }
                    else if (columns.length < row0Items.length) {
                        let columnsTemp = [];// = new JsonArray();
                        let columnWidthDefault = 1;
                        let columnWidthTotal = 0;
                        for (let i = 0; i < row0Items.length; i++) {

                            if (i < columns.length) {
                                let columnObj = columns[i];//.get(i).getAsJsonObject();
                                let columnW: number = columnObj.width;
                                columnWidthTotal += columnW;
                                columnsTemp.push(columnObj);
                            }
                            else {
                                columnWidthDefault = ((1.0 - columnWidthTotal) / (row0Items.length - columns.length));
                                let columnObj: any = {};
                                columnObj.name = "";
                                columnObj.width = columnWidthDefault;
                                columnsTemp.push(columnObj);
                            }
                        }
                        columns = columnsTemp;
                    }

                    if (columns != null && columns.length > 0) {
                        let drawColumnHeader = false;
                        let headerRows = [];
                        // let columnNameArray = [];
                        for (let col = 0; col < columns.length; col++) {
                            let column = columns[col];//get(col).getAsJsonObject();
                            let columnHeader: string = column.name;//").getAsString();
                            // columnNameArray.push(columnHeader);
                            if (!StringUtils.isBlank(columnHeader)) {
                                drawColumnHeader = true;
                            }
                        }
                        let headerStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.header_style);
                        if (drawColumnHeader) {

                            headerRows.push(columns);

                            newPage = await this.drawTableRows(pdfDoc, page, headerStyle, columns, headerRows, fontSet, tempCursorX, tempCursorY, tableWidth, measureOnly, drawColumnHeader, headerStyle, landscape);
                            tempCursorY = newPage.cursorY;
                            if (newPage.isNew && !measureOnly) {
                                page = newPage.page;
                            }
                            // tempCursorY = this.drawTableRows(page, rectTextStyle, columns, columns, fontSet, tempCursorX, tempCursorY, tableWidth);//(rectTextStyle.font_style == 1 ? boldFont : regularFont)
                            //							int lines = getMaxLineForSimpleRow(columns, columnNameArray, tableWidth, rectTextStyle.getFont_size(), (rectTextStyle.getFont_style()==1?boldFont:regularFont));
                            //							float rowHeight = rectTextStyle.getHeight()*lines;
                            //							tempCursorY -= rowHeight;
                            //							for (int col = 0; col < columns.size(); col++) {
                            //								JsonObject column = columns.get(col).getAsJsonObject();
                            //								String columnHeader = column.get("name").getAsString();
                            //								float columnWidthRatio = column.get("width").getAsFloat();
                            //								float columnWidth = columnWidthRatio * tableWidth;
                            //								
                            //								drawRectWithText(contentStream, columnHeader, (rectTextStyle.getFont_style()==1?boldFont:regularFont), tempCursorX, tempCursorY, columnWidth, rowHeight, 
                            //										rectTextStyle.getFont_size(), rectTextStyle.getBorder_thick(), Color.decode(rectTextStyle.getBorder_color()), Color.decode(rectTextStyle.getBackground_color()), Color.decode(rectTextStyle.getFont_color()), false, true, true);
                            //								tempCursorX +=columnWidth;
                            //								
                            //							}
                        }
                        let sectionStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.section_style);
                        let rectTextStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.row_style);
                        for (let i = 0; i < rowSections.length; i++) {
                            let sectionJsonObject = rowSections[i];//.get(i).getAsJsonObject();
                            let rowJsonArray = sectionJsonObject.rows;//.get("rows").getAsJsonArray();
                            let sectionTitle: string = sectionJsonObject.name;
                            tempCursorX = tableCursorX;
                            if (rowJsonArray != null && rowJsonArray.length > 0) {

                                if (!StringUtils.isBlank(sectionTitle)) {
                                    try {
                                        borderColor = this.convertHexToRgbA(sectionStyle.border_color);
                                        backgroundColor = this.convertHexToRgbA(sectionStyle.background_color);
                                        fontColor = this.convertHexToRgbA(sectionStyle.font_color);
                                    } catch (error) {
                                        this.logger.error("Execption:", error);
                                    }
                                    let lines = 1;
                                    try {
                                        this.logger.debug("createTable TABLEWIDTH:", tableWidth);
                                        lines = this.parseLines(sectionTitle, tableWidth, sectionStyle.font_size, this.getSelectedFont(sectionStyle.font_style, fontSet), true, true, true).length;
                                    } catch (error) {
                                        console.log(error);
                                    }
                                    let titleHeight = sectionStyle.height * lines;
                                    tempCursorY -= titleHeight;

                                    console.log("Line: 722 " + tableCursorY, ReportConst.MIN_TABLE_HEIGHT, ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE);
                                    if (tableCursorY - titleHeight < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE && !measureOnly) {
                                        console.log("Page Break");
                                        if (landscape) {
                                            page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
                                        }
                                        else
                                            page = pdfDoc.addPage();
                                        tempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                                        newPage.isNew = true;
                                        newPage.page = page;
                                        newPage.cursorY = tempCursorY;
                                    }
                                    if (!measureOnly) {
                                        this.drawRectWithText(page, sectionTitle, this.getSelectedFont(sectionStyle.font_style, fontSet), tempCursorX, tempCursorY, tableWidth, titleHeight,
                                            sectionStyle.font_size, sectionStyle.border_thick, borderColor, backgroundColor, fontColor, sectionStyle.font_align == 1, true, true);
                                    }
                                }
                                // try {
                                //     borderColor = this.convertHexToRgbA(rectTextStyle.border_color);
                                //     backgroundColor = this.convertHexToRgbA(rectTextStyle.background_color);
                                //     fontColor = this.convertHexToRgbA(rectTextStyle.font_color);
                                // } catch (error) {
                                //     this.logger.error("Execption:",error);
                                // }
                                newPage = await this.drawTableRows(pdfDoc, page, rectTextStyle, columns, rowJsonArray, fontSet, tempCursorX, tempCursorY, tableWidth, measureOnly, drawColumnHeader, headerStyle, landscape);
                                if (newPage.isNew) {
                                    if (measureOnly) {

                                    }
                                    else
                                        page = newPage.page;
                                }
                                tempCursorY = newPage.cursorY;
                                // for (let j = 0; j < rowJsonArray.length; j++) {
                                //     let rowItemsJsonArray = rowJsonArray[j];//(rectTextStyle.font_style == 1 ? boldFont : regularFont)
                                //     tempCursorY = this.drawTableRows(page, rectTextStyle, columns, rowItemsJsonArray, fontSet, tempCursorX, tempCursorY, tableWidth);
                                //     //									tempCursorY += rectTextStyle.getBorder_thick();
                                // }
                            }


                        }

                    }
                }
                //else no table

            }


        }
        catch (error) {
            console.log(error);
        }
        return newPage;
    }

    //Sudhanshu Chaubey: March 2022: DT-299 Start
    //Test Case Table methods
    async createTestCaseTableSimple(pdfDoc: PDFDocument, page: PDFPage, tableCursorX: number, tableCursorY: number, tableWidth: number, jsonTableStyle: any, jsonTableData: any,
        fontSet: FontSet, measureOnly?: boolean, landscape?: boolean, isInnerTable?: boolean): Promise<NewPage> {
        let tempCursorX = tableCursorX;
        let tempCursorY = tableCursorY;
        let newPage: NewPage = new NewPage();
        newPage.page = page;
        newPage.cursorY = tempCursorY;
        try {

            let title: string = jsonTableData.title;

            let borderColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.border_color);
            let backgroundColor = ReportConst.COLOR_WHITE;//Color.decode(titleStyle.background_color);
            let fontColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.font_color);

            this.logger.debug("Line: 793 " + tableCursorY, ReportConst.MIN_TABLE_HEIGHT, ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE);
            if (tableCursorY - ReportConst.MIN_TABLE_HEIGHT < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE && !measureOnly && !isInnerTable) {
                console.log("page break");
                if (landscape) {
                    page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
                }
                else
                    page = pdfDoc.addPage();
                tempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                newPage.isNew = true; //TODO: Manoj what about measureOnly variable?
            }
            newPage.page = page;
            newPage.cursorY = tempCursorY;

            if (title && title.length > 0) {

                let titleStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.title_style);
                try {
                    borderColor = this.convertHexToRgbA(titleStyle.border_color);
                    backgroundColor = this.convertHexToRgbA(titleStyle.background_color);
                    fontColor = this.convertHexToRgbA(titleStyle.font_color);
                } catch (error) {
                    this.logger.error("Execption:", error);
                }
                let lines = 1;
                try {
                    lines = this.parseLines(title, tableWidth, titleStyle.font_size, this.getSelectedFont(titleStyle.font_style, fontSet), true, true, true).length;
                } catch (error) {
                    console.log(error);
                }
                let titleHeight = titleStyle.height * lines;
                tempCursorY -= titleHeight;
                if (!measureOnly) {
                    this.drawRectWithText(page, title, this.getSelectedFont(titleStyle.font_style, fontSet), tempCursorX, tempCursorY, tableWidth, titleHeight,
                        titleStyle.font_size, titleStyle.border_thick, borderColor, backgroundColor, fontColor, titleStyle.font_align == 1, true, true);
                }
            }


            let rowSections = jsonTableData.row_sections;
            if (rowSections != null && rowSections.length > 0) {
                let row0 = rowSections[0].rows;
                if (row0 != null && row0.length > 0) {
                    let row0Items = row0[0];
                    let columns;
                    if (null != jsonTableData.columns)
                        columns = jsonTableData.columns;

                    //RESETTING THE COLUMN OBJECT
                    if (columns == null || columns.length <= 0) {
                        let columnsTemp = [];// = new JsonArray();
                        let columnWidthDefault: number = (1.0 / (row0Items.length * 1.0));
                        for (let i = 0; i < row0Items.length; i++) {
                            let columnObj: any = {};
                            columnObj.name = "";
                            columnObj.width = columnWidthDefault;
                            columnsTemp.push(columnObj);
                        }
                        columns = columnsTemp;
                    }
                    else if (columns.length < row0Items.length) {
                        let columnsTemp = [];// = new JsonArray();
                        let columnWidthDefault = 1;
                        let columnWidthTotal = 0;
                        for (let i = 0; i < row0Items.length; i++) {

                            if (i < columns.length) {
                                let columnObj = columns[i];//.get(i).getAsJsonObject();
                                let columnW: number = columnObj.width;
                                columnWidthTotal += columnW;
                                columnsTemp.push(columnObj);
                            }
                            else {
                                columnWidthDefault = ((1.0 - columnWidthTotal) / (row0Items.length - columns.length));
                                let columnObj: any = {};
                                columnObj.name = "";
                                columnObj.width = columnWidthDefault;
                                columnsTemp.push(columnObj);
                            }
                        }
                        columns = columnsTemp;
                    }

                    if (columns != null && columns.length > 0) {
                        let drawColumnHeader = false;
                        let headerRows = [];
                        // let columnNameArray = [];
                        for (let col = 0; col < columns.length; col++) {
                            let column = columns[col];//get(col).getAsJsonObject();
                            let columnHeader: string = column.name;//").getAsString();
                            // columnNameArray.push(columnHeader);
                            if (!StringUtils.isBlank(columnHeader)) {
                                drawColumnHeader = true;
                            }
                        }
                        let headerStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.header_style);
                        if (drawColumnHeader) {

                            headerRows.push(columns);

                            newPage = await this.drawTestCaseTableRows(pdfDoc, page, headerStyle, columns, headerRows, fontSet, tempCursorX, tempCursorY, tableWidth, measureOnly, drawColumnHeader, headerStyle, landscape, isInnerTable);
                            tempCursorY = newPage.cursorY;
                            if (newPage.isNew && !measureOnly) {
                                page = newPage.page;
                            }
                            // tempCursorY = this.drawTableRows(page, rectTextStyle, columns, columns, fontSet, tempCursorX, tempCursorY, tableWidth);//(rectTextStyle.font_style == 1 ? boldFont : regularFont)
                            //							int lines = getMaxLineForSimpleRow(columns, columnNameArray, tableWidth, rectTextStyle.getFont_size(), (rectTextStyle.getFont_style()==1?boldFont:regularFont));
                            //							float rowHeight = rectTextStyle.getHeight()*lines;
                            //							tempCursorY -= rowHeight;
                            //							for (int col = 0; col < columns.size(); col++) {
                            //								JsonObject column = columns.get(col).getAsJsonObject();
                            //								String columnHeader = column.get("name").getAsString();
                            //								float columnWidthRatio = column.get("width").getAsFloat();
                            //								float columnWidth = columnWidthRatio * tableWidth;
                            //								
                            //								drawRectWithText(contentStream, columnHeader, (rectTextStyle.getFont_style()==1?boldFont:regularFont), tempCursorX, tempCursorY, columnWidth, rowHeight, 
                            //										rectTextStyle.getFont_size(), rectTextStyle.getBorder_thick(), Color.decode(rectTextStyle.getBorder_color()), Color.decode(rectTextStyle.getBackground_color()), Color.decode(rectTextStyle.getFont_color()), false, true, true);
                            //								tempCursorX +=columnWidth;
                            //								
                            //							}
                        }
                        let sectionStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.section_style);
                        let rectTextStyle: RectTextStyle = this.getRectTextStyle(jsonTableStyle.row_style);
                        for (let i = 0; i < rowSections.length; i++) {
                            let sectionJsonObject = rowSections[i];//.get(i).getAsJsonObject();
                            let rowJsonArray = sectionJsonObject.rows;//.get("rows").getAsJsonArray();
                            let sectionTitle: string = sectionJsonObject.name;
                            tempCursorX = tableCursorX;
                            if (rowJsonArray != null && rowJsonArray.length > 0) {

                                if (!StringUtils.isBlank(sectionTitle)) {
                                    try {
                                        borderColor = this.convertHexToRgbA(sectionStyle.border_color);
                                        backgroundColor = this.convertHexToRgbA(sectionStyle.background_color);
                                        fontColor = this.convertHexToRgbA(sectionStyle.font_color);
                                    } catch (error) {
                                        this.logger.error("Execption:", error);
                                    }
                                    let lines = 1;
                                    try {
                                        this.logger.debug("createTable TABLEWIDTH:", tableWidth);
                                        lines = this.parseLines(sectionTitle, tableWidth, sectionStyle.font_size, this.getSelectedFont(sectionStyle.font_style, fontSet), true, true, true).length;
                                    } catch (error) {
                                        console.log(error);
                                    }
                                    let titleHeight = sectionStyle.height * lines;
                                    tempCursorY -= titleHeight;

                                    this.logger.debug("Line: 941 " + tableCursorY, ReportConst.MIN_TABLE_HEIGHT, ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE);
                                    if (tableCursorY - titleHeight < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE && !measureOnly) {
                                        console.log("Page Break");

                                        if (landscape) {
                                            page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
                                        }
                                        else
                                            page = pdfDoc.addPage();
                                        tempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                                        newPage.isNew = true;
                                        newPage.page = page;
                                        newPage.cursorY = tempCursorY;
                                    }
                                    if (!measureOnly) {
                                        this.drawRectWithText(page, sectionTitle, this.getSelectedFont(sectionStyle.font_style, fontSet), tempCursorX, tempCursorY, tableWidth, titleHeight,
                                            sectionStyle.font_size, sectionStyle.border_thick, borderColor, backgroundColor, fontColor, sectionStyle.font_align == 1, true, true);
                                    }
                                }
                                // try {
                                //     borderColor = this.convertHexToRgbA(rectTextStyle.border_color);
                                //     backgroundColor = this.convertHexToRgbA(rectTextStyle.background_color);
                                //     fontColor = this.convertHexToRgbA(rectTextStyle.font_color);
                                // } catch (error) {
                                //     this.logger.error("Execption:",error);
                                // }
                                newPage = await this.drawTestCaseTableRows(pdfDoc, page, rectTextStyle, columns, rowJsonArray, fontSet, tempCursorX, tempCursorY, tableWidth, measureOnly, drawColumnHeader, headerStyle, landscape, isInnerTable);
                                if (newPage.isNew) {
                                    if (measureOnly) {

                                    }
                                    else
                                        page = newPage.page;
                                }
                                tempCursorY = newPage.cursorY;
                                // for (let j = 0; j < rowJsonArray.length; j++) {
                                //     let rowItemsJsonArray = rowJsonArray[j];//(rectTextStyle.font_style == 1 ? boldFont : regularFont)
                                //     tempCursorY = this.drawTableRows(page, rectTextStyle, columns, rowItemsJsonArray, fontSet, tempCursorX, tempCursorY, tableWidth);
                                //     //									tempCursorY += rectTextStyle.getBorder_thick();
                                // }
                            }


                        }

                    }
                }
                //else no table

            }


            console.log("finish ==> rravi title " + jsonTableData.title);
        }
        catch (error) {
            console.log(error);
        }
        return newPage;
    }

    async drawTableRows(pdfDoc: PDFDocument, page: PDFPage, rectTextStyle: RectTextStyle, columnWidths: any, rows: any,
        fontSet: FontSet, tempCursorX: number, tempCursorY: number, tableWidth: number, measureOnly: boolean, addHeader: boolean, headerStyle: RectTextStyle, landscape: boolean): Promise<NewPage> {

        let newPage: NewPage = new NewPage();
        newPage.page = page;
        newPage.cursorY = tempCursorY;
        let finalFont = this.getSelectedFont(rectTextStyle.font_style, fontSet);//(rectTextStyle.font_style == 1 ? fontSet.bold : fontSet.regular);

        let borderColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.border_color);
        let backgroundColor = ReportConst.COLOR_WHITE;//Color.decode(titleStyle.background_color);
        let fontColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.font_color);
        try {
            borderColor = this.convertHexToRgbA(rectTextStyle.border_color);
            backgroundColor = this.convertHexToRgbA(rectTextStyle.background_color);
            fontColor = this.convertHexToRgbA(rectTextStyle.font_color);
        } catch (error) {
            this.logger.error("Execption:", error);
        }
        let orgCursorX = tempCursorX;
        // let tempCells: any = {};//Array<CellInTable> = [];
        let rowHolder: Array<CellInTable> = [];

        for (let index = 0; index < rows.length; index++) {
            const rowValues = rows[index];
            // this.logger.debug("drawTableRows: columnWidths" , columnWidths);
            this.logger.debug("drawTableRows TABLEWIDTH:", tableWidth);
            let lines = await this.getMaxLineForSimpleRow(columnWidths, rowValues, tableWidth, rectTextStyle.font_size, finalFont, fontSet, pdfDoc, page, tempCursorX, tempCursorY, landscape);//Manoj:16072020: might have issue if row has mix regular/bold
            let rowHeight = rectTextStyle.height * lines;
            tempCursorY -= rowHeight;
            this.logger.debug("drawTableRows: lines:" + lines + " measureOnly: " + measureOnly + " tempCursorY: " + tempCursorY + " rowHeight: " + rowHeight + " rectTextStyle.height: " + rectTextStyle.height + " rowValues:", rowValues);
            // tempCursorY -= rowHeight;
            tempCursorX = orgCursorX;
            // let columnIndex = 0;
            let actualColIndex = 0;
            let extraRowHeight = 0;

            console.log("1040: " + tempCursorY, ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE);
            if (tempCursorY < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE) {
                //Add part of rowSpan cell here:START
                console.log(rowValues);
                for (let col = 0; col < rowValues.length; col++) {
                    let updateColIndex = true;
                    let cell = rowValues[col];
                    if (!cell.colSpan) {
                        cell.colSpan = 1;
                    }
                    if (!cell.rowSpan) {
                        cell.rowSpan = 1;
                    }
                    finalFont = this.getSelectedFont(rectTextStyle.font_style, fontSet);
                    // actualColIndex = actualColIndex + cell.colSpan - 1;
                    this.logger.debug("drawTableRowsLAST: col:" + col + " actualColIndex:" + actualColIndex + " cell:", cell);
                    let column = columnWidths[actualColIndex];
                    let columnHeader: string = cell.name;
                    let columnWidthRatio: number = column.width;
                    let columnWidth: number = columnWidthRatio * tableWidth;
                    // this.logger.debug("drawTableRows: ALL1 Col:" + col + " actualColIndex:" + actualColIndex + " tempCells:", tempCells);
                    this.logger.debug("drawTableRowsLAST: ALL2 Col:" + col + " actualColIndex:" + actualColIndex + " rowHolder:", rowHolder);


                    let finalFontAlign = rectTextStyle.font_align == 1;
                    if (cell.style) {
                        finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                    }
                    if (cell.align) {
                        finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                    }
                    let backColor = backgroundColor;
                    let ftColor = fontColor;
                    if (StringUtils.isNotBlank(cell.backgroundColor)) {
                        try {
                            backColor = this.convertHexToRgbA(cell.backgroundColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    if (StringUtils.isNotBlank(cell.fontColor)) {
                        try {
                            ftColor = this.convertHexToRgbA(cell.fontColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    let rowHolderIndex = this.getItemFromList(actualColIndex, rowHolder);
                    if (rowHolderIndex > -1) {//tempCells[actualColIndex + ""]) {
                        let cellInTable = rowHolder[rowHolderIndex];//tempCells[actualColIndex + ""];
                        this.logger.debug("ROWSPAN BEFORE:", JSON.stringify(cellInTable));
                        let mergeCellColWidth = cellInTable.columnWidth;
                        this.logger.debug("drawTableRowsLAST: MERGE Col BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        // if (cellInTable.rowIndex + cellInTable.cell.rowSpan - 1 == index) {
                        this.logger.debug("drawTableRowsLAST: MERGE Col LAST BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        cell = cellInTable.cell;
                        if (cell.style) {
                            finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                        }
                        if (cell.align) {
                            finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                        }
                        if (StringUtils.isNotBlank(cell.backgroundColor)) {
                            try {
                                backColor = this.convertHexToRgbA(cell.backgroundColor);
                            } catch (error) {
                                this.logger.error("Execption:", error);
                            }
                        }
                        if (StringUtils.isNotBlank(cell.fontColor)) {
                            try {
                                ftColor = this.convertHexToRgbA(cell.fontColor);
                            } catch (error) {
                                this.logger.error("Execption:", error);
                            }
                        }

                        let callValue = cellInTable.cell.name;
                        let mergeCellLines = 1;
                        try {
                            mergeCellLines = this.parseLines(callValue, mergeCellColWidth, rectTextStyle.font_size, finalFont, true, true, true).length;
                        }
                        catch (error) {
                            console.log(error);
                        }
                        let mergeCellHeight = rectTextStyle.height * mergeCellLines;
                        let heightWithOthers = cellInTable.y + cellInTable.rowHeight - rowHeight - tempCursorY;//?? + cellInTable.rowHeight;// + rowHeight;
                        // if (heightWithOthers < mergeCellHeight) {//NO NEED TO CHECK
                        //     rowHeight = mergeCellHeight - heightWithOthers;
                        //     heightWithOthers = mergeCellHeight;
                        // }
                        // else {
                        //     heightWithOthers = heightWithOthers + rowHeight
                        // }
                        //LAST row for span
                        // let newTempCursorY = cellInTable.y + cellInTable.rowHeight - heightWithOthers;// - rowHeight);
                        // extraRowHeight = tempCursorY - newTempCursorY;
                        // tempCursorY = newTempCursorY;
                        if (!measureOnly) {
                            this.drawRectWithText(page, callValue, finalFont, tempCursorX, tempCursorY + rowHeight, cellInTable.columnWidth, heightWithOthers,
                                rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                backColor, ftColor, finalFontAlign, true, true);
                        }

                        this.logger.debug("ROWSPAN Prev SPAN:" + rowHolder[rowHolderIndex].cell.rowSpan + "index:" + index + "rowHolder:" + rowHolder[rowHolderIndex].rowIndex);
                        rowHolder[rowHolderIndex].cell.rowSpan = rowHolder[rowHolderIndex].cell.rowSpan - (index - rowHolder[rowHolderIndex].rowIndex);

                        rowHolder[rowHolderIndex].rowIndex = rowHolder[rowHolderIndex].rowIndex + (index - rowHolder[rowHolderIndex].rowIndex);//?

                        this.logger.debug("ROWSPAN NEW SPAN:", rowHolder[rowHolderIndex].cell.rowSpan);
                        rowHolder[rowHolderIndex].pageNumber = rowHolder[rowHolderIndex].pageNumber + 1;
                        rowHolder[rowHolderIndex].rowHeight = rowHeight;
                        rowHolder[rowHolderIndex].y = page.getHeight() - ReportConst.MARGIN_TOP_2 - rowHeight;
                        this.logger.debug("ROWSPAN AFTER:", rowHolder[rowHolderIndex]);
                        // rowHolder.splice(rowHolderIndex, 1);
                        // }
                        tempCursorX += (mergeCellColWidth);

                        //Extra for others ahead

                        column = columnWidths[actualColIndex];
                        columnWidthRatio = column.width;
                        columnWidth = columnWidthRatio * tableWidth;
                        if (updateColIndex) {
                            actualColIndex++;
                        }
                        col--;
                        continue;
                        // actualColIndex++;
                    }
                }
                //Add part of rowSpan cell here:END
                //Add column header if present
                if (landscape) {
                    page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
                }
                else
                    page = pdfDoc.addPage();
                // page = pdfDoc.addPage();
                tempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                newPage.page = page;
                newPage.isNew = true;
                tempCursorX = orgCursorX;
                actualColIndex = 0;
                extraRowHeight = 0;
                if (addHeader) {
                    let headerRows = [];
                    headerRows.push(columnWidths);
                    newPage = await this.drawTableRows(pdfDoc, page, headerStyle, columnWidths, headerRows, fontSet, tempCursorX, tempCursorY, tableWidth, measureOnly, addHeader, headerStyle, landscape);
                    tempCursorY = newPage.cursorY;
                    tempCursorY -= rowHeight;
                    if (newPage.isNew && !measureOnly) {
                        page = newPage.page;
                    }
                }
                else {
                    tempCursorY -= rowHeight;
                }
            }

            for (let col = 0; col < rowValues.length; col++) {
                let updateColIndex = true;
                let cell = rowValues[col];
                if (!cell.colSpan) {
                    cell.colSpan = 1;
                }
                if (!cell.rowSpan) {
                    cell.rowSpan = 1;
                }
                finalFont = this.getSelectedFont(rectTextStyle.font_style, fontSet);
                // actualColIndex = actualColIndex + cell.colSpan - 1;
                this.logger.debug("drawTableRows: col:" + col + " actualColIndex:" + actualColIndex + " cell:", cell);
                let column = columnWidths[actualColIndex];
                let columnHeader: string = cell.name;
                let columnWidthRatio: number = column.width;
                let columnWidth: number = columnWidthRatio * tableWidth;
                if (cell.rowSpan > 1) {
                    let cellInTable: CellInTable = new CellInTable();
                    cellInTable.cell = cell;
                    cellInTable.rowIndex = index;
                    cellInTable.colIndex = actualColIndex;//added -1
                    cellInTable.x = tempCursorX;
                    cellInTable.y = tempCursorY;
                    cellInTable.columnWidth = columnWidth;
                    cellInTable.rowHeight = rowHeight;
                    cellInTable.pageNumber = pdfDoc.getPageCount();
                    // tempCells[actualColIndex + ""] = cellInTable;
                    rowHolder.push(cellInTable)
                    this.logger.debug("drawTableRows: ADD:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                    // this.logger.debug("drawTableRows: LIST1:" + col + " actualColIndex:" + actualColIndex + " tempCells:", tempCells);
                    this.logger.debug("drawTableRows: LIST2:" + col + " actualColIndex:" + actualColIndex + " rowHolder:", rowHolder);
                    tempCursorX += (columnWidth);
                    if (updateColIndex) {
                        actualColIndex++;
                    }
                    continue;
                }
                // this.logger.debug("drawTableRows: ALL1 Col:" + col + " actualColIndex:" + actualColIndex + " tempCells:", tempCells);
                this.logger.debug("drawTableRows: ALL2 Col:" + col + " actualColIndex:" + actualColIndex + " rowHolder:", rowHolder);


                let finalFontAlign = rectTextStyle.font_align == 1;
                if (cell.style) {
                    finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                }
                if (cell.align) {
                    finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                }
                let backColor = backgroundColor;
                let ftColor = fontColor;
                if (StringUtils.isNotBlank(cell.backgroundColor)) {
                    try {
                        backColor = this.convertHexToRgbA(cell.backgroundColor);
                    } catch (error) {
                        this.logger.error("Execption:", error);
                    }
                }
                if (StringUtils.isNotBlank(cell.fontColor)) {
                    try {
                        ftColor = this.convertHexToRgbA(cell.fontColor);
                    } catch (error) {
                        this.logger.error("Execption:", error);
                    }
                }
                let rowHolderIndex = this.getItemFromList(actualColIndex, rowHolder);
                this.logger.debug("actualColIndex: " + actualColIndex + " rowHolderIndex: " + rowHolderIndex)
                if (rowHolderIndex > -1) {//tempCells[actualColIndex + ""]) {
                    let cellInTable = rowHolder[rowHolderIndex];//tempCells[actualColIndex + ""];
                    let mergeCellColWidth = cellInTable.columnWidth;
                    this.logger.debug("drawTableRows: MERGE Col BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                    if (cellInTable.rowIndex + cellInTable.cell.rowSpan - 1 == index) {
                        this.logger.debug("drawTableRows: MERGE Col LAST BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        cell = cellInTable.cell;
                        if (cell.style) {
                            finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                        }
                        if (cell.align) {
                            finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                        }
                        if (StringUtils.isNotBlank(cell.backgroundColor)) {
                            try {
                                backColor = this.convertHexToRgbA(cell.backgroundColor);
                            } catch (error) {
                                this.logger.error("Execption:", error);
                            }
                        }
                        if (StringUtils.isNotBlank(cell.fontColor)) {
                            try {
                                ftColor = this.convertHexToRgbA(cell.fontColor);
                            } catch (error) {
                                this.logger.error("Execption:", error);
                            }
                        }

                        let callValue = cellInTable.cell.name;
                        let mergeCellLines = 1;
                        try {
                            mergeCellLines = this.parseLines(callValue, mergeCellColWidth, rectTextStyle.font_size, finalFont, true, true, true).length;
                        }
                        catch (error) {
                            console.log(error);
                        }
                        let mergeCellHeight = rectTextStyle.height * mergeCellLines;
                        let heightWithOthers = cellInTable.y - tempCursorY + cellInTable.rowHeight;// + rowHeight;
                        if (heightWithOthers < mergeCellHeight) {
                            rowHeight = mergeCellHeight - heightWithOthers;
                            heightWithOthers = mergeCellHeight;
                        }
                        // else {
                        //     heightWithOthers = heightWithOthers + rowHeight
                        // }
                        //LAST row for span
                        let newTempCursorY = cellInTable.y + cellInTable.rowHeight - heightWithOthers;// - rowHeight);
                        extraRowHeight = tempCursorY - newTempCursorY;
                        tempCursorY = newTempCursorY;
                        if (!measureOnly) {
                            this.drawRectWithText(page, callValue, finalFont, tempCursorX, tempCursorY, cellInTable.columnWidth, heightWithOthers,
                                rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                backColor, ftColor, finalFontAlign, true, true);
                        }
                        rowHolder.splice(rowHolderIndex, 1);
                    }
                    tempCursorX += (mergeCellColWidth);

                    //Extra for others ahead

                    column = columnWidths[actualColIndex];
                    columnWidthRatio = column.width;
                    columnWidth = columnWidthRatio * tableWidth;
                    if (updateColIndex) {
                        actualColIndex++;
                    }
                    col--;
                    continue;
                    // actualColIndex++;
                }
                if (cell.colSpan > 1) {
                    try {
                        let spanWidth = 0;
                        this.logger.debug("drawTableRows: COLSPANSTART col:" + col + " actualColIndex:" + actualColIndex + " spanWidth:" + spanWidth + " cell:", cell);
                        for (let index = 0; index < cell.colSpan; index++) {
                            spanWidth = spanWidth + tableWidth * columnWidths[actualColIndex + index].width;
                            // actualColIndex++;
                        }
                        // actualColIndex--;
                        // actualColIndex++;
                        actualColIndex = actualColIndex + cell.colSpan;// - 1;
                        columnWidth = spanWidth;
                        updateColIndex = false;
                        this.logger.debug("drawTableRows: COLSPANEND col:" + col + " actualColIndex:" + actualColIndex + " spanWidth:" + spanWidth + " cell:", cell);
                    } catch (error) {
                        console.log(error);
                    }
                }
                else {
                    // actualColIndex++;
                }
                this.logger.debug("drawTableRows: FINAL col:" + col + " actualColIndex:" + actualColIndex + " tempCursorX:" + tempCursorX + " columnWidth:" + columnWidth + " cell:", cell);
                if (!measureOnly) {
                    if (cell.cellType == 1) {
                        this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                            rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                            backColor, ftColor, finalFontAlign, true, true);
                        if (StringUtils.isNotBlank(cell.imageKey)) {

                            let rect = {
                                x: tempCursorX + ReportConst.TABLE_IMAGE_OFFSET, y: tempCursorY + ReportConst.TABLE_IMAGE_OFFSET,
                                width: columnWidth - 2 * ReportConst.TABLE_IMAGE_OFFSET, height: ReportConst.TABLE_IMAGE_SIZE.height
                            };
                            // this.logger.debug("drawTableRows: DRAW IMAGE:" + col + " actualColIndex:" + actualColIndex + " rect:", rect);
                            await this.drawImage(pdfDoc, page, rect, cell.imageKey, ImageAspect.CENTER);
                            // this.logger.debug("drawTableRows: DRAW IMAGE END:" + col + " actualColIndex:" + actualColIndex + " rect:", rect);
                        }
                    }
                    else if (cell.cellType == 2) {
                        this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                            rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                            backColor, ftColor, finalFontAlign, true, true);
                        this.logger.debug("DRAWTABLEINTABLE:", cell.tabelData);
                        try {
                            if (cell.tabelData && cell.tabelData.length > 0) {
                                let firstTable = cell.tabelData[0];
                                let tNewPage = await this.createTableSimple(pdfDoc, page, tempCursorX + ReportConst.TABLE_IMAGE_OFFSET, tempCursorY + rowHeight - ReportConst.TABLE_IMAGE_OFFSET,
                                    columnWidth - 2 * ReportConst.TABLE_IMAGE_OFFSET,
                                    JSON.parse(ReportConst.SMALL_TABLE_STYLE_JSON_STRING), firstTable, fontSet, false, false, true);
                            }
                        } catch (error) {
                            this.logger.error("DRAWTABLEINTABLE:", error);
                        }
                    }
                    else {
                        this.drawRectWithText(page, columnHeader, finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                            rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                            backColor, ftColor, finalFontAlign, true, true);
                    }
                }
                tempCursorX += (columnWidth);//- rectTextStyle.getBorder_thick());
                if (updateColIndex) {
                    actualColIndex++;
                }
            }
        }
        newPage.cursorY = tempCursorY;
        return newPage;
    }
    //Sudhanshu Chaubey: March 2022: DT-299 End

    getItemFromList(colIndex: number, rowHolder: Array<CellInTable>) {
        if (rowHolder && rowHolder.length > 0) {
            for (let index = 0; index < rowHolder.length; index++) {
                const element = rowHolder[index];
                if (element.colIndex == colIndex) {
                    return index;
                }
            }
        }
        return -1;
    }

    //Sudhanshu Chaubey: March 2022: DT-299 Start
    async drawTestCaseTableRows(pdfDoc: PDFDocument, page: PDFPage, rectTextStyle: RectTextStyle, columnWidths: any, rows: any,
        fontSet: FontSet, tempCursorX: number, tempCursorY: number, tableWidth: number, measureOnly: boolean, addHeader: boolean, headerStyle: RectTextStyle, landscape: boolean, isInnerTable: boolean): Promise<NewPage> {

        let newPage: NewPage = new NewPage();
        newPage.page = page;
        newPage.cursorY = tempCursorY;
        let finalFont = this.getSelectedFont(rectTextStyle.font_style, fontSet);//(rectTextStyle.font_style == 1 ? fontSet.bold : fontSet.regular);

        let borderColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.border_color);
        let backgroundColor = ReportConst.COLOR_WHITE;//Color.decode(titleStyle.background_color);
        let fontColor = ReportConst.COLOR_BLACK;//Color.decode(titleStyle.font_color);
        try {
            borderColor = this.convertHexToRgbA(rectTextStyle.border_color);
            backgroundColor = this.convertHexToRgbA(rectTextStyle.background_color);
            fontColor = this.convertHexToRgbA(rectTextStyle.font_color);
        } catch (error) {
            this.logger.error("Execption:", error);
        }
        let orgCursorX = tempCursorX;
        // let tempCells: any = {};//Array<CellInTable> = [];
        let rowHolder: Array<CellInTable> = [];

        //Start for ROW
        this.currentPage = undefined;
        for (let index = 0; index < rows.length; index++) {
            const rowValues = rows[index];
            // this.logger.debug("drawTableRows: columnWidths" , columnWidths);
            this.logger.debug("drawTableRows TABLEWIDTH:", tableWidth);
            let lines = await this.getMaxLineForSimpleRow(columnWidths, rowValues, tableWidth, rectTextStyle.font_size, finalFont, fontSet, pdfDoc, page, tempCursorX, tempCursorY, landscape);//Manoj:16072020: might have issue if row has mix regular/bold
            let rowHeight = rectTextStyle.height * lines;
            tempCursorY -= rowHeight;

            /* if (rows[index][0].name == 'General comments') { //rows[index][0].name.indexOf("Step") == 0 || //DT-246 Ongoing Investigation
                if (this.parentPage) {
                    tempCursorY = 0;
                    this.parentPage = false;

                }
            } */

            this.logger.debug("drawTableRows: SUdhanshu lines:" + lines + " measureOnly: " + measureOnly + "tempCursorX: " + tempCursorX + " tempCursorY: " + tempCursorY + " rowHeight: " + rowHeight + " rectTextStyle.height: " + rectTextStyle.height + " rowValues:", rowValues);
            // tempCursorY -= rowHeight;
            tempCursorX = orgCursorX;
            // let columnIndex = 0;
            let actualColIndex = 0;
            let extraRowHeight = 0;
            console.log("1446: " + tempCursorY, ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE + "Measure Only: " + measureOnly);
            if (rows[index][0].name.indexOf("Step") == 0 && this.currentTempCursorY && newPage.isNew) {
                console.log(`MyCurrentPage: `, this.currentPage, rows[index][0].name, `tempCursorY: `, tempCursorY, this.currentTempCursorY);
                tempCursorY = this.currentTempCursorY;
            }
            if (tempCursorY < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE) {

                if(measureOnly) {
                    this.parentPage = true; //DT-246 Ongoing Investigation
                }
                //Add part of rowSpan cell here:START
                console.log(rowValues);
                for (let col = 0; col < rowValues.length; col++) {
                    let updateColIndex = true;
                    let cell = rowValues[col];
                    if (!cell.colSpan) {
                        cell.colSpan = 1;
                    }
                    if (!cell.rowSpan) {
                        cell.rowSpan = 1;
                    }
                    finalFont = this.getSelectedFont(rectTextStyle.font_style, fontSet);
                    // actualColIndex = actualColIndex + cell.colSpan - 1;
                    this.logger.debug("drawTableRowsLAST: col:" + col + " actualColIndex:" + actualColIndex + " cell:", cell);
                    let column = columnWidths[actualColIndex];
                    let columnHeader: string = cell.name;
                    let columnWidthRatio: number = column.width;
                    let columnWidth: number = columnWidthRatio * tableWidth;
                    // this.logger.debug("drawTableRows: ALL1 Col:" + col + " actualColIndex:" + actualColIndex + " tempCells:", tempCells);
                    this.logger.debug("drawTableRowsLAST: ALL1 Col:" + col + " actualColIndex:" + actualColIndex + " rowHolder:", rowHolder);


                    let finalFontAlign = rectTextStyle.font_align == 1;
                    if (cell.style) {
                        finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                    }
                    if (cell.align) {
                        finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                    }
                    let backColor = backgroundColor;
                    let ftColor = fontColor;
                    if (StringUtils.isNotBlank(cell.backgroundColor)) {
                        try {
                            backColor = this.convertHexToRgbA(cell.backgroundColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    if (StringUtils.isNotBlank(cell.fontColor)) {
                        try {
                            ftColor = this.convertHexToRgbA(cell.fontColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    let rowHolderIndex = this.getItemFromList(actualColIndex, rowHolder);
                    if (rowHolderIndex > -1) {//tempCells[actualColIndex + ""]) {
                        let cellInTable = rowHolder[rowHolderIndex];//tempCells[actualColIndex + ""];
                        this.logger.debug("ROWSPAN BEFORE:", JSON.stringify(cellInTable));
                        let mergeCellColWidth = cellInTable.columnWidth;
                        this.logger.debug("drawTableRowsLAST: MERGE Col BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        // if (cellInTable.rowIndex + cellInTable.cell.rowSpan - 1 == index) {
                        this.logger.debug("drawTableRowsLAST: MERGE Col LAST BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        cell = cellInTable.cell;
                        if (cell.style) {
                            finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                        }
                        if (cell.align) {
                            finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                        }
                        if (StringUtils.isNotBlank(cell.backgroundColor)) {
                            try {
                                backColor = this.convertHexToRgbA(cell.backgroundColor);
                            } catch (error) {
                                this.logger.error("Execption:", error);
                            }
                        }
                        if (StringUtils.isNotBlank(cell.fontColor)) {
                            try {
                                ftColor = this.convertHexToRgbA(cell.fontColor);
                            } catch (error) {
                                this.logger.error("Execption:", error);
                            }
                        }

                        let callValue = cellInTable.cell.name;
                        let mergeCellLines = 1;
                        try {
                            mergeCellLines = this.parseLines(callValue, mergeCellColWidth, rectTextStyle.font_size, finalFont, true, true, true).length;
                        }
                        catch (error) {
                            console.log(error);
                        }
                        let mergeCellHeight = rectTextStyle.height * mergeCellLines;
                        let heightWithOthers = cellInTable.y + cellInTable.rowHeight - rowHeight - tempCursorY;//?? + cellInTable.rowHeight;// + rowHeight;
                        // if (heightWithOthers < mergeCellHeight) {//NO NEED TO CHECK
                        //     rowHeight = mergeCellHeight - heightWithOthers;
                        //     heightWithOthers = mergeCellHeight;
                        // }
                        // else {
                        //     heightWithOthers = heightWithOthers + rowHeight
                        // }
                        //LAST row for span
                        // let newTempCursorY = cellInTable.y + cellInTable.rowHeight - heightWithOthers;// - rowHeight);
                        // extraRowHeight = tempCursorY - newTempCursorY;
                        // tempCursorY = newTempCursorY;
                        if (!measureOnly) {
                            let myTempCursorY = tempCursorY + rowHeight;
                            let myHeightWithOthers = heightWithOthers;
                            if(measureOnly === undefined && heightWithOthers != rectTextStyle.height) {
                                myTempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2 - rectTextStyle.height - ReportConst.TABLE_IMAGE_OFFSET;
                                myHeightWithOthers = rectTextStyle.height;
                            }
                            this.drawRectWithText(page, callValue, finalFont, tempCursorX, myTempCursorY, cellInTable.columnWidth, myHeightWithOthers,
                                rectTextStyle.font_size, rectTextStyle.border_thick, ReportConst.COLOR_RED,
                                backColor, ftColor, finalFontAlign, true, true);
                        }

                        this.logger.debug("ROWSPAN Prev SPAN:" + rowHolder[rowHolderIndex].cell.rowSpan + "index:" + index + "rowHolder:" + rowHolder[rowHolderIndex].rowIndex);
                        rowHolder[rowHolderIndex].cell.rowSpan = rowHolder[rowHolderIndex].cell.rowSpan - (index - rowHolder[rowHolderIndex].rowIndex);

                        rowHolder[rowHolderIndex].rowIndex = rowHolder[rowHolderIndex].rowIndex + (index - rowHolder[rowHolderIndex].rowIndex);//?

                        this.logger.debug("ROWSPAN NEW SPAN:", rowHolder[rowHolderIndex].cell.rowSpan);
                        rowHolder[rowHolderIndex].pageNumber = rowHolder[rowHolderIndex].pageNumber + 1;
                        rowHolder[rowHolderIndex].rowHeight = rowHeight;
                        rowHolder[rowHolderIndex].y = page.getHeight() - ReportConst.MARGIN_TOP_2 - rowHeight;
                        this.logger.debug("ROWSPAN AFTER:", rowHolder[rowHolderIndex]);
                        // rowHolder.splice(rowHolderIndex, 1);
                        // }
                        tempCursorX += (mergeCellColWidth);

                        //Extra for others ahead

                        column = columnWidths[actualColIndex];
                        columnWidthRatio = column.width;
                        columnWidth = columnWidthRatio * tableWidth;
                        if (updateColIndex) {
                            actualColIndex++;
                        }
                        col--;
                        continue;
                        // actualColIndex++;
                    }
                }
                console.log("Add New Page");

                //Add part of rowSpan cell here:END
                //Add column header if present
                console.log("!measureOnly 1 ", !measureOnly, " measureOnly ", measureOnly);
                if (tempCursorY - ReportConst.MIN_TABLE_HEIGHT < ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE && !measureOnly) {
                    if (landscape) {
                        page = pdfDoc.addPage([ReportConst.DEFAULT_PAGE_HEIGHT, ReportConst.DEFAULT_PAGE_WIDTH]);
                    }
                    else
                        page = pdfDoc.addPage();
                    // page = pdfDoc.addPage();
                }
                tempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
                newPage.page = page;
                newPage.isNew = true;
                tempCursorX = orgCursorX;
                actualColIndex = 0;
                extraRowHeight = 0;

                if(measureOnly === false && isInnerTable) { // && index === rows.length - 1
                    this.currentPage = page;
                    this.currentTempCursorY = tempCursorY - rowHeight;
                }

                if (addHeader) {
                    let headerRows = [];
                    headerRows.push(columnWidths);
                    newPage = await this.drawTestCaseTableRows(pdfDoc, page, headerStyle, columnWidths, headerRows, fontSet, tempCursorX, tempCursorY, tableWidth, measureOnly, addHeader, headerStyle, landscape, isInnerTable);
                    tempCursorY = newPage.cursorY;
                    tempCursorY -= rowHeight;
                    if (newPage.isNew && !measureOnly) {
                        page = newPage.page;
                    }
                }
                else {
                    tempCursorY -= rowHeight;
                }
            }
            let ignoreActualColIndexUpdate = false;
            let ignoreRowHolderRepeat = false;
            console.log(`rowValues 1621: `, rowValues);
            for (let col = 0; col < rowValues.length; col++) {
                this.logger.debug("Cell Index: " + col + " Actual RowHolder: " + rowHolder);
                let processsRest = true;
                let updateColIndex = true;
                let cell = rowValues[col];
                if (!cell.colSpan) {
                    cell.colSpan = 1;
                }
                if (!cell.rowSpan) {
                    cell.rowSpan = 1;
                }
                finalFont = this.getSelectedFont(rectTextStyle.font_style, fontSet);
                // actualColIndex = actualColIndex + cell.colSpan - 1;
                this.logger.debug("drawTableRows: col:" + col + " actualColIndex:" + actualColIndex + " cell:", cell);
                // let column = columnWidths[actualColIndex];
                let column = columnWidths[col];
                let columnHeader: string = cell.name;
                let columnWidthRatio: number = column.width;
                let columnWidth: number = columnWidthRatio * tableWidth;
                if(cell.width && isInnerTable  && measureOnly === false) {
                    columnWidth = cell.width * tableWidth;
                }
                this.logger.debug("columnWidth: " + columnWidth);
                if (!ignoreRowHolderRepeat) {
                    if (cell.rowSpan > 1) {
                        let cellInTable: CellInTable = new CellInTable();
                        cellInTable.cell = cell;
                        cellInTable.rowIndex = index;
                        cellInTable.colIndex = actualColIndex;//added -1
                        cellInTable.x = tempCursorX;
                        cellInTable.y = tempCursorY;
                        if (cell.colSpan > 1) {
                            cellInTable.columnWidth = columnWidth * cell.colSpan;
                            // actualColIndex = actualColIndex + cell.colSpan - 1;
                        }
                        else {
                            cellInTable.columnWidth = columnWidth;
                        }
                        cellInTable.rowHeight = rowHeight;
                        cellInTable.pageNumber = pdfDoc.getPageCount();
                        rowHolder.push(cellInTable);
                        // this.parentPage = true;
                        this.logger.debug("drawTableRows: ADD:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        // this.logger.debug("drawTableRows: LIST1:" + col + " actualColIndex:" + actualColIndex + " tempCells:", tempCells);
                        this.logger.debug("drawTableRows: LIST2:" + col + " actualColIndex:" + actualColIndex + " rowHolder:", rowHolder);
                        // tempCursorX += (cellInTable.columnWidth);
                        // if (updateColIndex) {
                        //     // actualColIndex++;
                        //     actualColIndex = actualColIndex + cell.colSpan;
                        // }
                        // continue;
                        if (col == rowValues.length - 1) {
                            this.logger.debug("drawTableRows: LAST COL " + col);
                            tempCursorX -= cellInTable.columnWidth;
                            // tempCursorY -= rowHeight;
                            extraRowHeight += (cellInTable.rowHeight);
                            tempCursorY -= extraRowHeight;
                            processsRest = true;
                            ignoreActualColIndexUpdate = true;
                            // actualColIndex = actualColIndex - cell.colSpan;
                        }
                        else {
                            tempCursorX += (cellInTable.columnWidth);
                            extraRowHeight += (cellInTable.rowHeight);
                            // tempCursorY -= rowHeight;
                            if (updateColIndex) {
                                // actualColIndex++;
                                actualColIndex = actualColIndex + cell.colSpan;
                            }
                            processsRest = false;
                        }
                    }
                }

                if (processsRest) {
                    // this.logger.debug("drawTableRows: ALL1 Col:" + col + " actualColIndex:" + actualColIndex + " tempCells:", tempCells);
                    this.logger.debug("drawTableRows: ALL2 Col:" + col + " actualColIndex:" + actualColIndex + " rowHolder:", rowHolder);

                    let finalFontAlign = rectTextStyle.font_align == 1;
                    if (cell.style) {
                        finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                    }
                    if (cell.align) {
                        finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                    }
                    let backColor = backgroundColor;
                    let ftColor = fontColor;
                    if (StringUtils.isNotBlank(cell.backgroundColor)) {
                        try {
                            backColor = this.convertHexToRgbA(cell.backgroundColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    if (StringUtils.isNotBlank(cell.fontColor)) {
                        try {
                            ftColor = this.convertHexToRgbA(cell.fontColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }

                    let rowHolderIndex = this.getItemFromList(actualColIndex, rowHolder);
                    this.logger.debug("drawTableRows: rowHolderIndex:" + rowHolderIndex + "index:" + index);
                    if (rowHolderIndex > -1) {//tempCells[actualColIndex + ""]) {
                        let cellInTable = rowHolder[rowHolderIndex];//tempCells[actualColIndex + ""];
                        let mergeCellColWidth = cellInTable.columnWidth;
                        this.logger.debug("cellInTable.columnWidth: " + cellInTable.columnWidth);
                        this.logger.debug("drawTableRows: MERGE Col BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                        if (cellInTable.rowIndex + cellInTable.cell.rowSpan - 1 == index) {
                            this.logger.debug("drawTableRows: MERGE Col LAST BEFORE:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable);
                            let cell = cellInTable.cell;
                            if (cell.style) {
                                finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                            }
                            if (cell.align) {
                                finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                            }
                            if (StringUtils.isNotBlank(cell.backgroundColor)) {
                                try {
                                    backColor = this.convertHexToRgbA(cell.backgroundColor);
                                } catch (error) {
                                    this.logger.error("Execption:", error);
                                }
                            }
                            if (StringUtils.isNotBlank(cell.fontColor)) {
                                try {
                                    ftColor = this.convertHexToRgbA(cell.fontColor);
                                } catch (error) {
                                    this.logger.error("Execption:", error);
                                }
                            }

                            let callValue = cellInTable.cell.name;
                            let mergeCellLines = 1;
                            try {
                                mergeCellLines = this.parseLines(callValue, mergeCellColWidth, rectTextStyle.font_size, finalFont, true, true, true).length;
                            }
                            catch (error) {
                                console.log(error);
                            }
                            let mergeCellHeight = rectTextStyle.height * mergeCellLines;
                            let heightWithOthers = cellInTable.y - tempCursorY + cellInTable.rowHeight;// + rowHeight;
                            if (heightWithOthers < mergeCellHeight) {
                                rowHeight = mergeCellHeight - heightWithOthers;
                                heightWithOthers = mergeCellHeight;
                            }
                            // else {
                            //     heightWithOthers = heightWithOthers + rowHeight
                            // }
                            //LAST row for span
                            let newTempCursorY = cellInTable.y + cellInTable.rowHeight - heightWithOthers;// - rowHeight);
                            extraRowHeight = tempCursorY - newTempCursorY;
                            tempCursorY = newTempCursorY;

                            if (!measureOnly) {
                                // this.parentPage = true;  //DT-246 Ongoing Investigation
                                //Sudhanshu Chaubey: April 2022 DT-246 Start
                                /* if (rows[index][0].cellType == 2 && rows[index + 1][0].name === "General comments") {
                                    if(newPage.isNew) {
                                        extraRowHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                        tempCursorY = ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                        this.drawRectWithText(page, callValue, finalFont, tempCursorX, tempCursorY, cellInTable.columnWidth, heightWithOthers + extraRowHeight,
                                            rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                            backColor, ftColor, finalFontAlign, true, true);
                                    }
                                    else if (tempCursorY > ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE) {
                                        // extraRowHeight = ReportConst.MIN_TABLE_HEIGHT + ReportConst.TABLE_IMAGE_OFFSET;
                                        extraRowHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                        // tempCursorY -= (ReportConst.MIN_TABLE_HEIGHT + ReportConst.TABLE_IMAGE_OFFSET);
                                        tempCursorY = ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                        this.drawRectWithText(page, callValue, finalFont, tempCursorX, tempCursorY, cellInTable.columnWidth, heightWithOthers + extraRowHeight,
                                            rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                            backColor, ftColor, finalFontAlign, true, true);
                                    }
                                    else {
                                        this.drawRectWithText(page, callValue, finalFont, tempCursorX, tempCursorY, cellInTable.columnWidth, heightWithOthers,
                                            rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                            backColor, ftColor, finalFontAlign, true, true);
                                    }
                                }
                                else {
                                    this.drawRectWithText(page, callValue, finalFont, tempCursorX, tempCursorY, cellInTable.columnWidth, heightWithOthers,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                } */
                                // Original Code after DT-246
                                let myTempCursorY = tempCursorY;
                                let myHeightWithOthers = heightWithOthers;
                                if(measureOnly === undefined && newPage.isNew && heightWithOthers != rectTextStyle.height) {
                                    myTempCursorY = page.getHeight() - ReportConst.MARGIN_TOP_2 - rectTextStyle.height - ReportConst.TABLE_IMAGE_OFFSET;
                                    myHeightWithOthers = rectTextStyle.height;
                                }
                                // if(!newPage.isNew) {
                                    this.drawRectWithText(page, callValue, finalFont, tempCursorX, myTempCursorY, cellInTable.columnWidth, myHeightWithOthers,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, ReportConst.COLOR_GREEN,
                                        backColor, ftColor, finalFontAlign, true, true);
                                // }
                               
                                //Sudhanshu Chaubey: April 2022 DT-246 Start

                            }
                            rowHolder.splice(rowHolderIndex, 1);
                        }
                        // if(cell.rowSpan == 1 && cell.colSpan == 1 && col == rowValues.length - 1){
                        //     // tempCursorX = orgCursorX;
                        //     tempCursorX = tempCursorX;
                        // }
                        // if(cell.rowSpan == 1 && cell.colSpan == 1) {
                        //     // tempCursorX = orgCursorX;
                        //     tempCursorX = tempCursorX;
                        // }
                        // else {
                        //     tempCursorX += (mergeCellColWidth);
                        // }
                        //Extra for others ahead
                        tempCursorX += (mergeCellColWidth);

                        column = columnWidths[actualColIndex];
                        columnWidthRatio = column.width;
                        columnWidth = columnWidthRatio * tableWidth;
                        if(cell.width && isInnerTable && measureOnly === false) {
                            columnWidth = cell.width * tableWidth;
                        }

                        if (updateColIndex) {
                            actualColIndex++;
                        }
                        col--;
                        ignoreRowHolderRepeat = true;
                        this.logger.debug("drawTableRows: MERGE Col After:" + col + " actualColIndex:" + actualColIndex + " cellInTable:", cellInTable, "tempCursorX", tempCursorX);
                        this.logger.debug("drawTableRows: updateActualColIndex:" + actualColIndex + " Col:" + col);
                        continue;
                        // actualColIndex++;
                    }
                    if (cell.colSpan > 1 && cell.rowSpan == 1) {
                        tempCursorY += extraRowHeight;
                        extraRowHeight = 0;
                        try {
                            let spanWidth = 0;
                            this.logger.debug("drawTableRows: COLSPANSTART col:" + col + " actualColIndex:" + actualColIndex + " spanWidth:" + spanWidth + " cell:", cell);
                            for (let index = 0; index < cell.colSpan; index++) {
                                spanWidth = spanWidth + tableWidth * columnWidths[actualColIndex + index].width;
                                // actualColIndex++;
                            }
                            // actualColIndex--;
                            // actualColIndex++;
                            actualColIndex = actualColIndex + cell.colSpan;// - 1;
                            columnWidth = spanWidth;
                            updateColIndex = false;
                            this.logger.debug("drawTableRows: COLSPANEND col:" + col + " actualColIndex:" + actualColIndex + " spanWidth:" + spanWidth + " cell:", cell);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    else {
                        // if(cell.rowSpan > 1 && cell.colSpan > 1 && col == rowValues.length - 1){
                        //     tempCursorX -= columnWidth;
                        // }
                        // actualColIndex++;
                        let spanWidth = 0;
                        for (let index = 0; index < cell.colSpan; index++) {
                            spanWidth = spanWidth + tableWidth * columnWidths[col + index].width;
                            // actualColIndex++;
                        }
                        actualColIndex = actualColIndex + cell.colSpan
                        columnWidth = spanWidth;
                        if(cell.width && isInnerTable && measureOnly === false) {
                            columnWidth = cell.width * tableWidth;
                        }
                        updateColIndex = false;
                    }
                    this.logger.debug("drawTableRows: FINAL col:" + col + " actualColIndex:" + actualColIndex + " tempCursorX:" + tempCursorX + " tempCursorY:" + tempCursorY + " columnWidth:" + columnWidth + " rowHeight" + rowHeight + "+" + "extraRowHeight" + extraRowHeight + ": " + (rowHeight + extraRowHeight) + " cell:", cell);
                    // this.logger.debug("celltype == 0 columnHeader: "+columnHeader+"tempCursorX: "+tempCursorX+"columnWidth: "+columnWidth+"rowHeight + extraRowHeight: "+rowHeight + extraRowHeight)
                    console.log("!measureOnly 3 ", !measureOnly, " measureOnly ", measureOnly);
                    if (!measureOnly) {
                        if (cell.cellType == 1) {//Image
                            this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                backColor, ftColor, finalFontAlign, true, true);
                            if (StringUtils.isNotBlank(cell.imageKey)) {

                                let rect = {
                                    x: tempCursorX + ReportConst.TABLE_IMAGE_OFFSET, y: tempCursorY + ReportConst.TABLE_IMAGE_OFFSET,
                                    width: columnWidth - 2 * ReportConst.TABLE_IMAGE_OFFSET, height: ReportConst.TABLE_IMAGE_SIZE.height
                                };
                                // this.logger.debug("drawTableRows: DRAW IMAGE:" + col + " actualColIndex:" + actualColIndex + " rect:", rect);
                                await this.drawImage(pdfDoc, page, rect, cell.imageKey, ImageAspect.CENTER);
                                // this.logger.debug("drawTableRows: DRAW IMAGE END:" + col + " actualColIndex:" + actualColIndex + " rect:", rect);
                            }
                        }
                        else if (cell.cellType == 2) {//Inner table
                            //Sudhanshu Chaubey: April 2022 DT-246 Start
                            /* if(rows[index][0].name == " " && rows[index + 1][0].name == "General comments") {
                                if(newPage.isNew){
                                    // extraRowHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    // tempCursorY = ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                    //Add extra box
                                    let calculatedRowHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    let newCursorY = ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    this.drawRectWithText(page, " ", finalFont, tempCursorX, newCursorY, columnWidth, calculatedRowHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                }
                                else if(tempCursorY > ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE) {
                                    // extraRowHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    // tempCursorY = ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);

                                    //Add extra box
                                    let calculatedRowHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    let newCursorY = ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE;
                                    this.drawRectWithText(page, " ", finalFont, tempCursorX, newCursorY, columnWidth, calculatedRowHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                }
                                else {
                                    this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                }
                            }
                            else {
                                this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                    rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                    backColor, ftColor, finalFontAlign, true, true);
                            } */
                            // Original Code After DT-246
                            if(!newPage.isNew) {
                                this.drawRectWithText(page, " ", finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                backColor, ftColor, finalFontAlign, true, true);
                            }
                           
                            //Sudhanshu Chaubey: April 2022 DT-246 End
                            this.logger.debug("DRAWTABLEINTABLE:", cell.tabelData);
                            try {
                                if (cell.tabelData && cell.tabelData.length > 0) {
                                    let firstTable = cell.tabelData[0];
                                    console.log("rravi title = " + firstTable.title);
                                    // Original Code after DT-246
                                    let tNewPage = await this.createTestCaseTableSimple(pdfDoc, page, tempCursorX + ReportConst.TABLE_IMAGE_OFFSET, tempCursorY + rowHeight - ReportConst.TABLE_IMAGE_OFFSET,
                                        columnWidth - 2 * ReportConst.TABLE_IMAGE_OFFSET,
                                        JSON.parse(ReportConst.SMALL_TABLE_STYLE_JSON_STRING), firstTable, fontSet, false, false, true);
                                   
                                    /* let tNewPage = await this.createTestCaseTableSimple(pdfDoc, page, tempCursorX + ReportConst.TABLE_IMAGE_OFFSET, tempCursorY + rowHeight + extraRowHeight - ReportConst.TABLE_IMAGE_OFFSET,
                                    columnWidth - 2 * ReportConst.TABLE_IMAGE_OFFSET,
                                    JSON.parse(ReportConst.SMALL_TABLE_STYLE_JSON_STRING), firstTable, fontSet, false, false, true); DT-246 Ongoing Investigation*/
                                }
                            } catch (error) {
                                this.logger.error("DRAWTABLEINTABLE:", error);
                            }
                        }
                        else {//plain text cell
                            //Sudhanshu Chaubey: April 2022 DT-246 Start
                            if (rows[index][0].name.indexOf("Step") == 0 || columnHeader == 'General comments' || columnHeader.indexOf('Witnessed By:') == 0) {
                                if(this.currentPage != undefined) {
                                    page = this.currentPage;
                                    tempCursorY =  this.currentTempCursorY;
                                }
                            }
                            if((rows.length-1 == index) && cell.cellType != 0) {//lastone (add extra condition to avoid inner table)
                                if(tempCursorY > ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE) { //  - ReportConst.MIN_TABLE_HEIGHT  //Witnessed By issue Fixed
                                    let calculatedExtraHeight = tempCursorY - ReportConst.MARGIN_DEFAULT_BOTTOM_TABLE - (rowHeight + extraRowHeight);//to be calculated
                                    let backCursorY = tempCursorY - calculatedExtraHeight + rowHeight + extraRowHeight;
                                    let column = columnWidths[col+1];
                                    //add blank cell extra
                                    if(col == 0) {
                                        let nextColumnWidthRatio = column.width;
                                        let nextColumnWidth: number = nextColumnWidthRatio * tableWidth;
                                        this.drawRectWithText(page, " ", finalFont, tempCursorX, backCursorY, columnWidth + nextColumnWidth, calculatedExtraHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                    }
                                    // this.drawRectWithText(page, " ", finalFont, tempCursorX, backCursorY, columnWidth, calculatedExtraHeight,
                                    //     rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                    //     backColor, ftColor, finalFontAlign, true, true);
                                    // tempCursorY = tempCursorY + calculatedExtraHeight;
                                    let newCursorY = backCursorY - (rowHeight + extraRowHeight)
                                    this.drawRectWithText(page, columnHeader, finalFont, tempCursorX, newCursorY, columnWidth, rowHeight + extraRowHeight,
                                        rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                        backColor, ftColor, finalFontAlign, true, true);
                                    // tempCursorY = tempCursorY + calculatedExtraHeight + rowHeight;
                                }
                            }
                            else {
                                this.drawRectWithText(page, columnHeader, finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                    rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                    backColor, ftColor, finalFontAlign, true, true);
                                this.currentTempCursorY = tempCursorY - 16;
                                if (isInnerTable) {
                                    this.currentTempCursorY -= ReportConst.TABLE_IMAGE_OFFSET;
                                }
                            } 
                            /* Original Code after DT-246
                            this.drawRectWithText(page, columnHeader, finalFont, tempCursorX, tempCursorY, columnWidth, rowHeight + extraRowHeight,
                                rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                backColor, ftColor, finalFontAlign, true, true);
                            */
                            //Sudhanshu Chaubey: April 2022 DT-246 End
                        }
                    }
                    tempCursorX += (columnWidth);//- rectTextStyle.getBorder_thick());
                    if (updateColIndex && !ignoreActualColIndexUpdate) {
                        actualColIndex++;
                    }
                    if (col == rowValues.length - 1 && cell.rowSpan > 1) {
                        tempCursorY += extraRowHeight;
                        // extraRowHeight  -= rowHeight;
                    }
                }
                else {
                    let backTempCursorX = tempCursorX - columnWidth;
                    let backTempCursorY = tempCursorY - rowHeight;
                    let moreRowHeight = rowHeight + extraRowHeight;
                    this.logger.debug("drawTableRows: ProcessRest: false Col:" + col + " actualColIndex:" + actualColIndex + "measureOnly: " + measureOnly);
                    // if(rowHolderIndex == -1) {
                    let finalFontAlign = rectTextStyle.font_align == 1;
                    if (cell.style) {
                        finalFont = this.getSelectedFont(cell.style, fontSet);//(cell.style === FontStyle.BOLD ? fontSet.bold : fontSet.regular);
                    }
                    if (cell.align) {
                        finalFontAlign = cell.align === FontAlign.CENTER;//false=normal and true=center
                    }
                    let backColor = backgroundColor;
                    let ftColor = fontColor;
                    if (StringUtils.isNotBlank(cell.backgroundColor)) {
                        try {
                            backColor = this.convertHexToRgbA(cell.backgroundColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    if (StringUtils.isNotBlank(cell.fontColor)) {
                        try {
                            ftColor = this.convertHexToRgbA(cell.fontColor);
                        } catch (error) {
                            this.logger.error("Execption:", error);
                        }
                    }
                    this.logger.debug("drawTableRows:Else FINAL col:" + col + " actualColIndex:" + actualColIndex + " backTempCursorX:" + backTempCursorX + " backTempCursorY:" + backTempCursorY + " columnWidth:" + columnWidth + " moreRowHeight: " + moreRowHeight + " cell:", cell);
                    if (!measureOnly) {
                        if (cell.cellType == 0) {
                            this.drawRectWithText(page, columnHeader, finalFont, backTempCursorX, backTempCursorY, columnWidth, moreRowHeight,
                                rectTextStyle.font_size, rectTextStyle.border_thick, borderColor,
                                backColor, ftColor, finalFontAlign, true, true);

                        }
                    }
                    extraRowHeight = 0;
                    // }
                }
            }
        }


        newPage.cursorY = tempCursorY;
        return newPage;
    }
    //Sudhanshu Chaubey: March 2022: DT-299 End

    drawRectWithText(page: any, text: string, font: any,
        cursorX: number, cursorY: number, width: number, height: number,
        fontSize: number, lineWidth: number,
        borderColor: any, fillColor: any, fontColor: any, centerText: boolean, borderRequired: boolean, fillRequired: boolean) {
        try {
            fillColor = fillColor == undefined ? ReportConst.COLOR_WHITE : fillColor;
            borderColor = borderColor == undefined ? ReportConst.COLOR_WHITE : borderColor;
            fontColor = fontColor == undefined ? ReportConst.COLOR_BLACK : fontColor;
            let rect: PDRectangle = { x: cursorX, y: cursorY, width: width, height: height };

            if (!fillRequired) {
                fillColor = undefined;
            }

            if (!borderRequired) {
                borderColor = undefined;
                lineWidth = undefined
            }
            this.drawRectangle(page, rect, fillColor, lineWidth, borderColor);

            // draw text
            let cellOffsetX = ReportConst.TABLE_CONTENT_TEXT_START_OFFSET + lineWidth / 2;
            let sx = (cursorX + cellOffsetX);
            // this.logger.debug("drawRectWithText: "+text+" lineWidth:"+lineWidth+" sx:"+sx+" width:"+width);
            this.addParagraph(page, width, sx, (cursorY + (height - fontSize - lineWidth / 2)), text,
                false, fontColor, fontSize, font, centerText, height - lineWidth, cursorX, cursorY, true);// width - 2 * cellOffsetX??
        } catch (error) {
            // TODO Auto-generated catch block
            console.log(error);

        }

    }

    getRectTextStyle(jsonElement: any): RectTextStyle {
        let rectTextStyle: RectTextStyle = Object.assign({}, jsonElement);
        return rectTextStyle;

    }

    getHeightForContent(orgText: string, width: number, fontSize: number, font: PDFFont): number {
        const lines: number = this.parseLines(orgText, width, fontSize, font, false, true, true).length;
        const height = lines * font.heightAtSize(fontSize);
        return height;
    }

    contentFitsInPage(page: PDFPage, cursorY: number, orgText: string, width: number, fontSize: number, font: PDFFont, bottomMargin?: number): boolean {
        const contentHeight = this.getHeightForContent(orgText, width, fontSize, font);
        let margin = ReportConst.MARGIN_BOTTOM;
        if (bottomMargin && bottomMargin >= 0) {
            margin = bottomMargin;
        }
        return contentHeight + cursorY < page.getHeight();
    }

    addNewPage(pdfDoc: PDFDocument): NewPage {
        const page = pdfDoc.addPage();
        let cursorY = page.getHeight() - ReportConst.MARGIN_TOP_2;
        let newPage: NewPage = new NewPage();
        newPage.cursorY = cursorY;
        newPage.page = page;
        newPage.isNew = true;
        return newPage;
    }

    async getMaxLineForSimpleRow(columnWidths: any, rowValues: any, tableWidth: number, fontSize: number, finalFont: any, fontSet: FontSet, pdfDoc: PDFDocument, page: PDFPage, tempCursorX: number, tempCursorY: number, landscape: boolean): Promise<number> {
        let lines: Array<string> = [];
        let a: Array<number> = [];
        if (rowValues == null || rowValues.length <= 0) {
            return 0;
        }
        if (columnWidths == null || columnWidths.length <= 0 || columnWidths.length < rowValues.length) {
            columnWidths = [];
            let columnWidthDefault: number = 1.0 / (rowValues.length * 1.0);
            for (let i = 0; i < rowValues.length; i++) {
                let columnObj: CellBasic = new CellBasic();

                columnObj.name = "";
                columnObj.width = columnWidthDefault;
                columnWidths.push(columnObj);
            }
        }
        for (let i = 0; i < rowValues.length; i++) {
            if (rowValues[i].rowSpan > 1) {
                continue;
            }
            let text: string = rowValues[i].name;
            // text = text.replace("\"", "");//why
            // this.logger.info("getMaxLineForSimpleRow: normal width: i:" + i + " colums:" + columnWidths.length);
            let width: number = tableWidth * columnWidths[rowValues[i].colIndex].width;// * columnWidths[i].width;//.get(i).getAsJsonObject().get("width").getAsFloat();
            if(rowValues[i].width) {
                width = rowValues[i].width * tableWidth;
            }
            if (rowValues[i].colSpan > 1) {
                try {
                    let temmpWidth = 0;
                    for (let index = 0; index < rowValues[i].colSpan; index++) {
                        // this.logger.info("getMaxLineForSimpleRow: before width:" + temmpWidth + " i:" + i + " index:" + index);
                        temmpWidth = temmpWidth + tableWidth * columnWidths[rowValues[i].colIndex + index].width;// * columnWidths[i + index].width;
                        // this.logger.info("getMaxLineForSimpleRow: after width:" + temmpWidth + " i:" + i + " index:" + index);
                        // width -=20;
                        width = temmpWidth;
                    }
                    // this.logger.info("getMaxLineForSimpleRow: final width:" + temmpWidth);
                } catch (error) {
                    console.log(error);
                }
            }
            try {
                if (rowValues[i].style) {
                    finalFont = this.getSelectedFont(rowValues[i].style, fontSet);
                }
                if (rowValues[i].cellType == 1) {
                    let linesNumber = ReportConst.TABLE_IMAGE_CELL_SIZE.height / ReportConst.HEIGHT_TO_LINES_FACTOR;
                    a.push(linesNumber);
                }
                else if (rowValues[i].cellType == 2) {
                    let tableSize = 0;
                    let tablesHeight = 0;
                    if (rowValues[i].tabelData && rowValues[i].tabelData.length > 0) {
                        let firstTable = rowValues[i].tabelData[0];
                        this.logger.debug("getMaxLineForSimpleRow: CELL TABLE START: ", firstTable);
                        tempCursorY = page.getHeight();
                        let tNewPage = await this.createTestCaseTableSimple(pdfDoc, page, tempCursorX, tempCursorY,
                            width  - 2 * ReportConst.TABLE_IMAGE_OFFSET,
                            JSON.parse(ReportConst.SMALL_TABLE_STYLE_JSON_STRING), firstTable, fontSet, true, landscape);

                        tablesHeight = tempCursorY - tNewPage.cursorY + 2 * ReportConst.TABLE_IMAGE_OFFSET;
                        // if (tNewPage.cursorY == 263.89) {
                        //     tablesHeight = tempCursorY - tNewPage.cursorY + 2 * ReportConst.TABLE_IMAGE_OFFSET_1;
                        // }
                        // else {
                        //     tablesHeight = tempCursorY - tNewPage.cursorY + 2 * ReportConst.TABLE_IMAGE_OFFSET;
                        // }
                        this.logger.debug("getMaxLineForSimpleRow: CELL TABLE END: ", tablesHeight, tempCursorY, tNewPage, tNewPage.cursorY);
                    }
                    else {
                        tablesHeight = ReportConst.TABLE_TABLE_CELL_SIZE.height;
                    }
                    let linesNumber = tablesHeight / ReportConst.HEIGHT_TO_LINES_FACTOR;
                    a.push(linesNumber);
                }
                else {

                    lines = this.parseLines(text, width, fontSize, finalFont, true, true, true);//SHOULD BE isCell: true
                    // lines = this.addParagraph(page, width, 0, page.getHeight(), text, false, ReportConst.COLOR_BLACK, fontSize, finalFont, false, 0, 0, 0, true, true, true);
                    this.logger.debug("getMaxLineForSimpleRow: TABLEWIDTH: " + tableWidth + " lines:" + lines.length);
                    a.push(lines.length);
                }

                // this.logger.info("getMaxLineForSimpleRow: lines:" + lines + " length:" + lines.length+ " width:" + width);

            }
            catch (error) {
                console.log(error);
            }
        }
        this.logger.debug("getMaxLineForSimpleRow: LINE COUNT START: " + JSON.stringify(a));
        // a.sort();//NOT ALWAYS SORTING CORRECTLY>>> HENCE USING LODASH sortBy
        a = _.sortBy(a);
        this.logger.debug("getMaxLineForSimpleRow: LINE COUNT END: " + JSON.stringify(a));
        return a[(a.length - 1)];
    }



    convertHexToRgbA(hexVal): any {
        var ret;

        // If the hex value is valid. 
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexVal)) {

            // Getting the content after '#', 
            // eg. 'ffffff' in case of '#ffffff' 
            ret = hexVal.slice(1);

            // Splitting each character 
            ret = ret.split('');

            // Checking if the length is 3 
            // then make that 6 
            if (ret.length == 3) {
                var ar = [];
                ar.push(ret[0]);
                ar.push(ret[0]);
                ar.push(ret[1]);
                ar.push(ret[1]);
                ar.push(ret[2]);
                ar.push(ret[2]);
                ret = ar;
            }

            // Starts with '0x'(in hexadecimal) 
            ret = '0x' + ret.join('');

            // Converting the first 2 characters 
            // from hexadecimal to r value 
            var r = ((ret >> 16) & 255) / 255;

            // Converting the second 2 characters 
            // to hexadecimal to g value 
            var g = ((ret >> 8) & 255) / 255;

            // Converting the last 2 characters 
            // to hexadecimal to b value 
            var b = (ret & 255) / 255;

            // Appending all of them to make 
            // the RGBA value 
            // this.logger.debug("convertHexToRgbA: ", r, g, b);
            return rgb(r, g, b);
            // return 'rgba('+[r, g, b].join(',')+',1)'; 
        }
    }





    //Other functions


    //   async presentLoading(msg) {
    //     this.loading = await this.loadingController.create({
    //       message: msg
    //     });
    //     return await this.loading.present();
    //   }

    //   //------------ code to download pdf using downloadjs @starts-----
    //   /*const pdfBytes = await pdfDoc.save();
    //   download(pdfBytes, "pdf-lib_page_copying_ionic_example.pdf", "application/pdf");*/

    //   //------------ code to download pdf using downloadjs @starts-----

    //   //------------ code to show the pdf in browser @starts-----
    //   /*
    //   const pdfUrl = URL.createObjectURL(
    //     new Blob([await pdfDoc.save()], { type: 'application/pdf' }),
    //   );
    //   window.open(pdfUrl, '_blank');
    //   */
    //   //------------ code to show the pdf in browser @ends-----


    //   //alternate code to merge two pdf
    //   async mergePdfs(pdfsToMerge: string[]) {
    //     //this.presentLoading('Creating PDF file...');
    //     const mergedPdf = await PDFDocument.create();
    //     for (const pdfCopyDoc of pdfsToMerge) {
    //       const pdfBytes = await fetch(pdfCopyDoc).then(res => res.arrayBuffer());
    //       const pdf = await PDFDocument.load(pdfBytes);
    //       const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    //       copiedPages.forEach((page) => {
    //         mergedPdf.addPage(page);
    //       });
    //     }
    //     const mergedPdfFile = await mergedPdf.save();
    //     var byteArrays = [];
    //     byteArrays.push(mergedPdfFile); //required to convert unit8array to blob and use that blob to create a pdf or open in browser
    //     var blob = new Blob(byteArrays, { type: "application/pdf" });


    //     //if desktop :
    //     const pdfUrl = URL.createObjectURL(blob);
    //     //this.loading.dismiss();
    //     window.open(pdfUrl, '_blank');
    //     //download(mergedPdfFile, "pdf-lib_page_copying_ionic_example.pdf", "application/pdf");


    //     //if device
    //     //this.createPdfFromData(blob);

    //   }


    //   createPdfFromData(buffer) {

    //     //This is where the PDF file will stored , you can change it as you like
    //     // for more information please visit https://ionicframework.com/docs/native/file/
    //     const directory = this.file.externalDataDirectory; //dataDirectory;
    //     const fileName = "invoice.pdf";
    //     let options: IWriteOptions = { replace: true };

    //     this.file.checkFile(directory, fileName).then((success) => {
    //       this.savePdfToDevice(directory, fileName, buffer, options);
    //     })
    //       .catch((error) => {
    //         options = { replace: false };
    //         this.savePdfToDevice(directory, fileName, buffer, options);
    //       });
    //   }

    //   savePdfToDevice(directory, fileName, buffer, options) {
    //     this.file.writeFile(directory, fileName, buffer, options)  //Writing File to Device
    //       .then((success) => {
    //         this.loading.dismiss();
    //         console.log("File created Succesfully in catch" + JSON.stringify(success));
    //       })
    //       .catch((error) => {
    //         this.loading.dismiss();
    //         console.log("Cannot Create File in catch's catch " + JSON.stringify(error));
    //       });
    //   }

}
