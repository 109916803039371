import { uuid } from 'pouchdb-utils';
import * as _ from 'lodash';
// MISC
import { Logger } from './../../commons/log/logger';
import { LoggerFactory } from '../../commons/log/logger-factory';

// MODELS
import { AbstractDocument, Attachment } from '../../models';

// PROVIDERS - CORE
import { PouchdbDatabase } from '../../providers/core';
import { Injector } from '@angular/core';
import { DateUtils, StringUtils } from 'src/app/commons/utils';

export class AuthorisationOptions {
    create?: boolean;
    delete?: boolean;
    update?: boolean;
}

export abstract class AbstractDao<T extends AbstractDocument> {

    // error status when document not found
    private static readonly ERROR_STATUS_NOT_FOUND: string = "404";

    // default id prefixed by document type
    private static readonly DEFAULT_ID: string = '0';

    // separator for id prefixed by document type
    public static readonly SEPARATOR: string = '-';

    protected readonly logger: Logger;

    protected database: PouchdbDatabase = this.injector.get(PouchdbDatabase);
    
    constructor(protected injector: Injector,
        public documentType: string,
        private authorisationOptions: AuthorisationOptions = new AuthorisationOptions(),
        loggerName: string = "AbstractDao") {
        this.logger = injector.get(LoggerFactory).buildLogger(loggerName);
    }

    /**
     * Check if authorised
     * @param {boolean} isAuthorised
     * @throws {Error} if not authorised
     */
    private checkIfAuthorised(isAuthorised: boolean): void {
        if (!isAuthorised) {
            throw new Error("Not authorised.");
        }
    }

    /**
     * Get prefix for documents id of current type
     * 
     * @param {...any[]} additionalPrefix 
     * @returns {string} 
     * @memberof AbstractDao
     */
    public prefixId(...additionalPrefix: any[]): string {
        let prefixId = this.documentType;
        if (additionalPrefix && additionalPrefix.length > 0) {
            prefixId += AbstractDao.SEPARATOR + [].join.call(additionalPrefix, AbstractDao.SEPARATOR);
        }
        return prefixId;
    }

    /**
     * Generate id for document
     * 
     * @param {string} prefixId 
     * @param {T} doc 
     * @returns {string} 
     * @memberof AbstractDao
     */
    public generateId(prefixId: string, doc: T): string {
        // return prefixId + AbstractDao.SEPARATOR + new Date().getTime() + AbstractDao.SEPARATOR + uuid();
        return prefixId + AbstractDao.SEPARATOR + DateUtils.getCurrentDateMilli() + AbstractDao.SEPARATOR + uuid();
    }

    /**
     * Create document if authorised
     * @param {T} doc
     * @returns {Promise<T>}
     */
    public create(doc: T): Promise<T> {
        this.checkIfAuthorised(this.authorisationOptions.create);
        return this.doCreate(doc);
    }

    /**
     * Create documents if authorised
     * @param {T} docs
     * @returns {Promise<T[]>}
     */
    public createAll(docs: T[]): Promise<T[]> {
        this.checkIfAuthorised(this.authorisationOptions.create);
        return this.doCreateAll(docs);
    }

    getCurrentUsername(){
        return StringUtils.getEmptyForNull(this.database.currentUsername);
    }

    /**
     * Create document
     * @param {T} doc
     * @returns {Promise<T>}
     */
    protected async doCreate(doc: T): Promise<T> {
        let id = doc._id;
        if (!id) {
            id = this.generateId(this.prefixId(), doc);
        }
        if (!doc.cdt) {
            doc.cdt = DateUtils.getCurrentDateMilli();//new Date();
        }
        doc.mdt = DateUtils.getCurrentDateMilli();//
        doc.uid = id;
        doc.type = this.documentType;
        doc.cBy = this.getCurrentUsername();
        doc.mBy = doc.cBy;
        this.logger.debug("create document", this.documentType, doc);
        if (this.database.isDatabaseAvailable()) {
            return <T>await this.database.put(id, doc);
        }
    }

    /**
     * Create documents
     * @param {T} docs
     * @returns {Promise<T[]>}
     */
    protected async doCreateAll(docs: T[]): Promise<T[]> {
        docs.forEach((doc) => {
            if (!doc._id) {
                doc._id = this.generateId(this.prefixId(), doc);
                doc.uid = doc._id;
                if (!doc.cdt) {
                    doc.cdt = DateUtils.getCurrentDateMilli();//new Date();
                }
                doc.mdt = DateUtils.getCurrentDateMilli();//
            }
            doc.type = this.documentType;
            doc.cBy = this.getCurrentUsername();
            doc.mBy = doc.cBy;
        });
        this.logger.debug("create documents", this.documentType, docs);
        if (this.database.isDatabaseAvailable()) {
            return <T[]>await this.database.putAll(docs);
        }
    }

    /**
     * Delete document if authorised
     * @param {T} doc
     * @returns {Promise<boolean>}
     */
    public delete(doc: T): Promise<boolean> {
        this.checkIfAuthorised(this.authorisationOptions.delete);
        return this.doDelete(doc);
    }

    /**
     * Delete document
     * @param {T} doc
     * @returns {Promise<boolean>}
     */
    protected doDelete(doc: T): Promise<boolean> {
        this.logger.debug("delete document", this.documentType, doc);
        if (this.database.isDatabaseAvailable()) {
            return this.database.remove(doc);
        }
    }

    /**
     * Find all documents of current type.
     * 
     * @example 
     * findAll("{parentId}") 
     * // search "{documentType}-{parentId}-*"
     * @example 
     * findAll()
     * // search "{documentType}-*"
     * 
     * @param {string} [additionalPrefix] (not mandatory) additional prefix to search for id;
     * @returns {Promise<Array<T>>} 
     * @memberof AbstractDao
     */
    public async findAll(additionalPrefix?: string): Promise<Array<T>> {
        this.logger.debug("findAll", this.documentType, additionalPrefix);
        if (this.database.isDatabaseAvailable()) {
            const prefix = additionalPrefix ? this.prefixId(additionalPrefix) : this.prefixId();
            return await this.database.findAll(prefix + AbstractDao.SEPARATOR) || [];
            // return await this.database.findAll() || [];//TODO: MANOJ IMP UNCOMMENT ABOVE LINE AND UPDATE DB RECORDS WITH UPDATED IDs
        }
    }

    /**
     * Return a range of documents of current type, for pagination purpose.
     *
     * @param {string} getFromId id of the first element to include, may be null
     * @param {string} getUntilId id of the last element to include, use this or fetchSize to limit
     * @param {number} fetchSize number of elements to fetch, use this or fetchSize to limit
     * @param {boolean} [reverseOrder] if true return documents by descending order fo their _id
     * @returns {Promise<Array<T>>} 
     * @memberof AbstractDao
     */
    public async findRange(getFromId: string, getUntilId: string, fetchSize: number, reverseOrder?: boolean): Promise<Array<T>> {
        this.logger.debug("findRange", this.documentType, getFromId, getUntilId, fetchSize, reverseOrder);
        if (this.database.isDatabaseAvailable()) {
            return await this.database.findRange(this.prefixId() + AbstractDao.SEPARATOR, getFromId, getUntilId, fetchSize, reverseOrder) || [];
        }
    }

    /**
     * Get a specific document by id
     * 
     * @param {string} [docId] - id of the doc to search, search default id if null
     * @param {boolean} [ignoreIfNotFound=false] to ignore error and return undefined if not found
     * @returns {Promise<T>} 
     * @memberof AbstractDao
     */
    public async get(docId?: string, ignoreIfNotFound: boolean = false): Promise<T> {
        let id = docId;
        if (!id) {
            id = this.prefixId(AbstractDao.DEFAULT_ID);
        }
        this.logger.debug("get", this.documentType, id, ignoreIfNotFound);
        if (this.database.isDatabaseAvailable()) {
            try {
                return <T>await this.database.get(id);
            } catch (error) {
                if (!ignoreIfNotFound || error.status != AbstractDao.ERROR_STATUS_NOT_FOUND) {
                    throw error;
                }
            }
        }
    }

    /**
     * Update document if authorised
     * @param {T} doc
     * @returns {Promise<T>}
     */
    public update(doc: T): Promise<T> { 
        
        this.checkIfAuthorised(this.authorisationOptions.update); 
        return this.doUpdate(doc);
    }

    /**
     * Update documents if authorised
     * @param {T} docs
     * @returns {Promise<T[]>}
     */
    public updateAll(docs: T[]): Promise<T[]> {
        this.checkIfAuthorised(this.authorisationOptions.update);
        return this.doUpdateAll(docs);
    }

    /**
     * Update document
     * @param {T} doc
     * @returns {Promise<T>}
     */
    protected async doUpdate(doc: T): Promise<T> {
        this.logger.debug("update document", this.documentType, doc);
        if (this.database.isDatabaseAvailable()) {
            doc.mdt = DateUtils.getCurrentDateMilli();//
            doc.mBy = this.getCurrentUsername();
            return <T>await this.database.put(doc._id, doc);
        }
    }

    /**
     * Update documents
     * @param {T} doc
     * @returns {Promise<T>}
     */
    protected async doUpdateAll(docs: T[]): Promise<T[]> {
        this.logger.debug("update documents", this.documentType, docs);
        if (this.database.isDatabaseAvailable()) {
            for (let index = 0; index < docs.length; index++) {
                docs[index].mdt = DateUtils.getCurrentDateMilli();//
                docs[index].mBy = this.getCurrentUsername();
            }
            return <T[]>await this.database.putAll(docs);
        }
    }

    
    /**
     * Find all documents for the query provided
     * 
     * @example 
     * findBasedOnQuery("{type:XXX, status:1}") 
     * // search "{documentType}-{status}-*"
     * 
     * @param {any} [queryParams] the query which includes type and more params to search with
     * @param {any} [sort] OPTIONAL sort by array options: ['name'] OR [{name:'desc'}] OR [{series: 'desc'}, {debut: 'desc'}]
     * @param {number} [limit] OPTIONAL limit to be specified
     * @returns {Promise<Array<T>>} 
     * @memberof AbstractDao
     */
    public async findBasedOnQueryOld(queryParams:any, sortBy?: any, limit?: number): Promise<Array<T>> {
        this.logger.debug("findBasedOnQuery: ", this.documentType, queryParams);
        if (this.database.isDatabaseAvailable()) {
            
            return await this.database.findBasedOnQuery(queryParams, sortBy, limit) || [];
        }
    }

    //offline device

    /**
     * Create document if authorised
     * @param {T} doc
     * @returns {Promise<T>}
     */
    public createOffline(doc: T): Promise<T> {
        this.checkIfAuthorised(this.authorisationOptions.create);
        return this.doCreateOffline(doc);
    }

    /**
     * Create document
     * @param {T} doc
     * @returns {Promise<T>}
     */
    protected async doCreateOffline(doc: T): Promise<T> {
        let id = doc._id;
        if (!id) {
            id = this.generateId(this.prefixId(), doc);
        }
        if (!doc.cdt) {
            doc.cdt = DateUtils.getCurrentDateMilli();//new Date();
        }
        doc.mdt = DateUtils.getCurrentDateMilli(); //after mereging with rev2
        doc.uid = id;
        doc.type = this.documentType;
        this.logger.debug("create document", this.documentType, doc);
        if (this.database.isOfflineDatabaseAvailable()) {
            return <T>await this.database.putOffline(id, doc);
        }
    }

    /**
     * Get a specific document by id
     * 
     * @param {string} [docId] - id of the doc to search, search default id if null
     * @param {boolean} [ignoreIfNotFound=false] to ignore error and return undefined if not found
     * @returns {Promise<T>} 
     * @memberof AbstractDao
     */
    public async getOffline(docId?: string, ignoreIfNotFound: boolean = false): Promise<T> {
        let id = docId;
        if (!id) {
            id = this.prefixId(AbstractDao.DEFAULT_ID);
        }
        this.logger.debug("get", this.documentType, id, ignoreIfNotFound);
        if (this.database.isOfflineDatabaseAvailable()) {
            try {
                return <T>await this.database.getOffline(id);
            } catch (error) {
                if (!ignoreIfNotFound || error.status != AbstractDao.ERROR_STATUS_NOT_FOUND) {
                    throw error;
                }
            }
        }
    }


    /** @param {string} [additionalPrefix] (not mandatory) additional prefix to search for id;
     * @returns {Promise<Array<T>>} 
     * @memberof AbstractDao
     */
    public async findAllOffline(additionalPrefix?: string): Promise<Array<T>> {
        this.logger.debug("findAll", this.documentType, additionalPrefix);
        if (this.database.isOfflineDatabaseAvailable()) {
            const prefix = additionalPrefix ? this.prefixId(additionalPrefix) : this.prefixId();
            return await this.database.findAllOffline(prefix + AbstractDao.SEPARATOR) || [];
        }
    }

    /**
     * Update document if authorised
     * @param {T} doc
     * @returns {Promise<T>}
     */
    public updateOffline(doc: T): Promise<T> { 
        
        this.checkIfAuthorised(this.authorisationOptions.update); 
        return this.doUpdateOffline(doc);
    }

    /**
     * Update document
     * @param {T} doc
     * @returns {Promise<T>}
     */
    protected async doUpdateOffline(doc: T): Promise<T> {
        this.logger.debug("update document", this.documentType, doc);
        if (this.database.isOfflineDatabaseAvailable()) {
            return <T>await this.database.putOffline(doc._id, doc);
        }
    }

    /**
     * Delete document if authorised
     * @param {T} doc
     * @returns {Promise<boolean>}
     */
    public deleteOffline(doc: T): Promise<boolean> {
        this.checkIfAuthorised(this.authorisationOptions.delete);
        return this.doDeleteOffline(doc);
    }

    /**
     * Delete document
     * @param {T} doc
     * @returns {Promise<boolean>}
     */
    protected doDeleteOffline(doc: T): Promise<boolean> {
        this.logger.debug("delete document", this.documentType, doc);
        if (this.database.isOfflineDatabaseAvailable()) {
            return this.database.removeOffline(doc);
        }
    }

    public async findBasedOnQuery(queryParams:any, sortBy?: any, limit?: number): Promise<Array<T>> {
        this.logger.debug("findBasedOnQuery: ", this.documentType, queryParams, sortBy);
        if (this.database.isDatabaseAvailable()) {
            let result = await this.findAll();
            if (result && result.length>0) {
                // if (queryParams.createdDate) {
                    queryParams = _.omit(queryParams,['createdDate','mdt']);
                // }
                // this.logger.debug("findBasedOnQuery: OMIT", queryParams);
                result = _.filter(result, queryParams);
                this.logger.debug("findBasedOnQuery: filter ", result);
                if (sortBy && sortBy.length > 0) {
                    let keys = [];
                    let order = [];
                    for (let index = 0; index < sortBy.length; index++) {
                        const element = sortBy[index];
                        keys = Object.keys(element);
                        if (keys && keys.length > 0) {
                            for (let j = 0; j < keys.length; j++) {
                                const val = element[keys[j]];
                                order.push(val);
                            }
                        }
                    }
                    this.logger.debug("findBasedOnQuery: SORT ", keys, order);
                    if (keys.length > 0 && keys.length == order.length) {
                        result = _.orderBy(result, keys, order);
                    }
                    this.logger.debug("findBasedOnQuery: SORT Result: ", result);
                }
            }
            return result;
        }
        return [];
    }
}
