import { ReviewStatusEnum } from './../../../models/enums/reviewStatus.enum';
import { AppManagerService, LoadingService } from 'src/app/providers/service';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from "@ionic/angular";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { TrialStatus, VesselAccessEnum, StatusCodeEnum, UserTypeEnum } from '../../../models/enums';
import { LocalStorageService, VesselService, AnnualTrialsService } from '../../../providers/service';
import { UserBasic, Vessel } from '../../../models';
import { QualifService } from './../../../providers/service/qualif/qualif.service';
import { Constants } from '../../../app.constants';
import { StringUtils } from 'src/app/commons/utils';

@Component({
  selector: 'app-assigned-people-modal',
  templateUrl: './assigned-people-modal.page.html',
  styleUrls: ['./assigned-people-modal.page.scss'],
})
export class AssignedPeopleModalPage implements OnInit {

  public vesselData: any;
  private loggedinUserBasic: any;
  public dpResponsiblePersonLabel: string = "No person assigned";
  private dpResponsiblePerson: UserBasic;
  public dpResponsiblePersonUsername: string;
  public allDpResponsiblePersons: any;
  private trialResponsiblePerson: UserBasic;
  public trialResponsiblePersonUsername: string;
  public trialResponsiblePersonLabel: string = "No person assigned";
  public allTrialResponsiblePersons: any = [];
  public dpResponsibleEdit: boolean = false;
  public trialResponsibleEdit: boolean = false;
  public isDpResponsibleEditAllowed: boolean = false;
  public isTrialResponsibleEditAllowed: boolean = false;
  private isCurrentlyAssignedVisible: boolean = false;
  
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private router: Router,
    private vesselService: VesselService,
    private annualService: AnnualTrialsService,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService, private qualifService: QualifService, private appManager: AppManagerService
  ) { }

  async ngOnInit() {
    // if (Constants.MUS_SSO_ENABLED) {
      let users = await this.qualifService.getQualifUserByType(UserTypeEnum.EXPERT);
      this.allDpResponsiblePersons = users.response.data;
    // }
    this.appManager.isTeamReassigned = false;
    this.vesselData = this.navParams.data.vesselData;
    this.loggedinUserBasic = await this.appManager.getLoggedInUserBasic();
    this.allTrialResponsiblePersons.push(this.vesselData.dpResponsiblePerson);
    this.vesselData.trialsResponsiblePersonsOnboard.forEach(element => {
      this.allTrialResponsiblePersons.push(element.userBasic);
    });
    this.allTrialResponsiblePersons = _.uniqBy(this.allTrialResponsiblePersons, 'username');
    let userRole = await this.localStorageService.getUserRoles();
    if (this.vesselData.hasOwnProperty('annualTrialStatus')) {
      // let userRole = await this.localStorageService.getUserRoles();
      if (userRole.includes(Constants.QUALIF_ROLE.FMEA_EXPERT)) {
        this.isDpResponsibleEditAllowed = true;
        if ((this.vesselData.activeRevApprove && this.vesselData.activeRevApprove.status == ReviewStatusEnum.PENDING) || (this.vesselData.activeRevReview && this.vesselData.activeRevReview.status == ReviewStatusEnum.PENDING)) {
          this.isDpResponsibleEditAllowed = false;
        }
      }
      if (userRole.includes(Constants.QUALIF_ROLE.FMEA_EXPERT) || userRole.includes(Constants.QUALIF_ROLE.FMEA_SURVEYOR)) {
        this.isTrialResponsibleEditAllowed = true;
        if ((this.vesselData.activeRevApprove && this.vesselData.activeRevApprove.status == ReviewStatusEnum.PENDING) || (this.vesselData.activeRevReview && this.vesselData.activeRevReview.status == ReviewStatusEnum.PENDING)) {
          this.isTrialResponsibleEditAllowed = false;
        }
        if (!this.vesselData.hasOwnProperty('actualTrialResponsiblePerson') || (this.vesselData.hasOwnProperty('actualTrialResponsiblePerson') && !this.vesselData.actualTrialResponsiblePerson)) {
          this.isTrialResponsibleEditAllowed = false;
        }
      }
      if (this.vesselData.hasOwnProperty('actualTrialResponsiblePerson')) {
        this.trialResponsiblePerson = this.vesselData.actualTrialResponsiblePerson;
        if (this.trialResponsiblePerson) {
          this.trialResponsiblePersonUsername = this.trialResponsiblePerson.username;
          this.trialResponsiblePersonLabel = StringUtils.getCamelCase(this.trialResponsiblePerson.fullName);
        }
      }
      if (this.vesselData.annualTrialStatus == TrialStatus.CLOSED) {
        this.isTrialResponsibleEditAllowed = false;
      }
    }
    else {
      //If there are no active annual trial (Closed or none)
      if (userRole.includes(Constants.QUALIF_ROLE.FMEA_EXPERT)) {
        this.isDpResponsibleEditAllowed = true;
      }
    }

    if (this.vesselData.hasOwnProperty('actualDPResponsiblePerson')) {
      this.dpResponsiblePerson = this.vesselData.actualDPResponsiblePerson;
      this.dpResponsiblePersonUsername = this.dpResponsiblePerson.username;
      this.dpResponsiblePersonLabel = StringUtils.getCamelCase(this.dpResponsiblePerson.fullName);
    }
  }

  async onDpResponsiblePersonConfirm() {
    this.dpResponsibleEdit = !this.dpResponsibleEdit;
    this.dpResponsiblePerson = this.allDpResponsiblePersons.find(data => data.username == this.dpResponsiblePersonUsername);
    this.dpResponsiblePersonLabel = this.dpResponsiblePerson.fullName;
    this.processAssignOnboardAction(VesselAccessEnum.EDIT, this.vesselData._id, this.vesselData.activeAnnualTrialsId, this.dpResponsiblePerson, this.loggedinUserBasic);
    /*let vesselUpdateData: Vessel = _.omit(this.vesselData, ['annualTrialStatus', 'dpResponsiblePerson', 'trialsResponsiblePersonsOnboard']) as Vessel;
    vesselUpdateData.actualDPResponsiblePerson = this.dpResponsiblePerson;
    await this.vesselService.updateVessel(vesselUpdateData);*/
    // if (Constants.MUS_SSO_ENABLED) {
    //   let apiData = {
    //     "annualTrialId": this.vesselData.activeAnnualTrialsId,
    //     "vesselId": this.vesselData._id,
    //     "accessType": 1,
    //     "assignTo": this.dpResponsiblePerson,
    //     "assignedBy": this.loggedinUserBasic
    //   }
    //   try {
    //     await this.annualService.assignVesselRequest(apiData);
    //   } catch (e) {
    //     console.log("Error occured while assigning vessel " + e);
    //   }
    // }
  }

  async onTrialResponsiblePersonConfirm() {
    this.trialResponsibleEdit = !this.trialResponsibleEdit;
    this.trialResponsiblePerson = this.allTrialResponsiblePersons.find(data => data.username == this.trialResponsiblePersonUsername);
    this.trialResponsiblePersonLabel = StringUtils.getCamelCase(this.trialResponsiblePerson.fullName);
    this.processAssignOnboardAction(VesselAccessEnum.ONBOARD, this.vesselData._id, this.vesselData.activeAnnualTrialsId, this.trialResponsiblePerson, this.loggedinUserBasic);
    /*let annualTrialData = await this.annualService.getAnnualTrials(this.vesselData.activeAnnualTrialsId);
    annualTrialData.actualTrialResponsiblePerson = this.trialResponsiblePerson;
    await this.annualService.updateAnnualTrials(annualTrialData);*/
    // if (Constants.MUS_SSO_ENABLED) {
    //   let apiData = {
    //     "annualTrialId": this.vesselData.activeAnnualTrialsId,
    //     "vesselId": this.vesselData._id,
    //     "accessType": 2,
    //     "assignTo": this.trialResponsiblePerson,
    //     "assignedBy": this.loggedinUserBasic
    //   }
    //   try {
    //     await this.annualService.assignVesselRequest(apiData);
    //   } catch (e) {
    //     console.log("Error occured while assigning vessel " + e);
    //   }
    // }
  }

  async onCancel() {
    return await this.modalController.dismiss("cancelled");
  }

  processAssignOnboardAction(accessType: VesselAccessEnum, vesselId: string, annualTrialId: string, assignTo: UserBasic, assignedBy: UserBasic) {
    this.loadingService.loadingPresent(Constants.MSG_SENDING_REQUEST);
    this.annualService.assignVesselRequest(accessType, vesselId, annualTrialId, assignTo, assignedBy)
      .then(res => {
        console.log("processAssignOnboardAction: response:", res);
        this.loadingService.loadingDismiss();
        if (res) {
          let vesselInfo = res.response.statusCode;
          console.log("processAssignOnboardAction: success data:", vesselInfo);
          if (res.response.statusCode == StatusCodeEnum.OK) {
            this.appManager.isTeamReassigned = true;
            this.appManager.showToast(res.response.message, "top");
          }
          else {
            this.appManager.showToast(res.response.message, "top");
          }
        }
      })
      .catch(err => {
        console.log("processAssignOnboardAction: error:", err);
        this.loadingService.loadingDismiss();
        this.appManager.showToast("Error! Failed to process your request...", "top");
      });
  }
}
