import { JsonDatePipe } from '../../pipes/json-date';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { Constants } from 'src/app/app.constants';

export class DateUtils {

    public static readonly PATTERN = {
        DD_MMM_YY: 'DD MMM YY',
        HH_2DOTS_MM: 'HH:mm'
    }

    /**
     * Return true if date1 is after date2.
     * Use this method instead of "date1 > date2" as dates returned from pouchdb/couchdb are stringified.
     * @param date1 
     * @param date2 
     * @returns {boolean} 
     * @memberof DateUtils
     */
    public static isAfter(date1: Date | string | number, date2: Date | string | number): boolean {
        return moment.utc(date1).isAfter(moment.utc(date2));
        // return moment(date1).isAfter(moment(date2));
    }

    /**
     * Get date max
     * 
     * @static
     * @param {...Date[]} dates 
     * @returns {Date} 
     * @memberof DateUtils
     */
    public static maxDate(...dates: Date[]): Date {
        let maxDate;
        if (dates && dates.length > 0) {
            maxDate = _.max(dates);
        }
        return maxDate;
    }

    /**
     * Format a date into a string.
     * 
     * @static
     * @param {Date} date 
     * @param {string} format 
     * @returns {string} 
     * @memberof DateUtils
     */
    public static formatDate(date: Date, format: string): string {
        const pipe: JsonDatePipe = new JsonDatePipe();
        return pipe.transform(date, format);
    }

    /**
     * Format the date from ion-datetime.
     * @param pickerDate 
     */
    public static formatDateFromPicker(pickerDate: string): string {
        if (!pickerDate) {
            return null;
        }
        return new Date(pickerDate).toISOString();
    }

    /**
     * Format date form displaying in feed list (notifications, bv news, ...).
     * See #BR-HOM-019.
     * 
     * @static
     * @param {Date} date
     * @param {Date} now used for unit testing
     * @returns {string} 
     * @memberof DateUtils
     */
    public static formatNotificationDate(date: Date, now?: Date): string {
        const mDate: moment.Moment = moment(date);
        const mNow: moment.Moment = moment(now);
        const durationFromNow: moment.Duration = moment.duration(mNow.diff(mDate));
        let formatedDate: string;

        // farther than a year
        if (durationFromNow.years() > 1) {
            formatedDate = mDate.format('DD MMM YYYY [at] HH:mm');
        }
        // farther than yesterday
        else if (durationFromNow.asDays() > 2) {
            formatedDate = mDate.format('DD MMM [at] HH:mm');
        }
        // yesterday
        else if (mNow.clone().subtract(1, 'day').startOf('day').isSame(mDate, 'day')) {
            formatedDate = mDate.format('[Yesterday at] HH:mm');
        }
        // today
        else if (durationFromNow.asHours() >= 1) {
            formatedDate = durationFromNow.get('hour') + 'h';
        }
        // last hour
        else if (durationFromNow.asMinutes() >= 1) {
            formatedDate = durationFromNow.get('minute') + 'm';
        }
        // last minute
        else {
            formatedDate = "now";
        }

        return formatedDate;
    }

    /**
 * Get max date for ionic date picker (max date = current date + 10 year)
 * 
 * @static
 * @param {Date} date 
 * @returns {string} 
 * @memberof DateUtils
 */
    public static getMaxDateForPicker(date: Date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear() + 20;

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    /**
    * Get min date for ionic date picker (min date = current date - 10 year)
    * 
    * @static
    * @param {Date} date 
    * @returns {string} 
    * @memberof DateUtils
    */
    public static getMinDateForPicker(date: Date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear() - 40;

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    /**
    * Get min date for ionic date picker (min date = current date - 10 year)
    * 
    * @static
    * @param {Date} date 
    * @returns {string} 
    * @memberof DateUtils
    */
    public static getCurrentDateForPicker(date: Date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    /**
    * Get min date for ionic date picker (min date = current date - 10 year)
    * 
    * @static
    * @param {Date} date 
    * @returns {string} 
    * @memberof DateUtils
    */
    public static getCurrentDateForTestCaseExec(date: Date) {
        console.log("getCurrentDateForTestCaseExec: date", date);
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        let result = [day, month, year].join('-');
        console.log("getCurrentDateForTestCaseExec: result", result);
        return result;
    }

    /**
    * Get min year for ionic date picker (min year = current year - 10 year)
    * 
    * @static
    * @param {Date} date 
    * @returns {string} 
    * @memberof DateUtils
    */
    public static getMinYearForPicker(date: Date) {
        let d = new Date(date),
            year = d.getFullYear() - 40;

        return year;
    }

    /**
    * Get max year for ionic date picker (max year = current year + 10 year)
    * 
    * @static
    * @param {Date} date 
    * @returns {string} 
    * @memberof DateUtils
    */
    public static getMaxYearForPicker(date: Date) {
        let d = new Date(date),
            year = d.getFullYear() + 20;

        return year;
    }

    public static getCurrentDateMilli() {
        let date = new Date()
        // let milli = date.getTime();
        // console.log("getCurrentDateMilli: ", milli);
        return date.getTime();//getUTCMilliseconds();
    }

    /**
     * Format the date from ion-datetime.
     * @param pickerDate 
     */
    public static getMilliFromStringDate(strigDate: string) {
        let splitChar = "-";
        if (strigDate.indexOf("/") > 0) {
            splitChar = "/";
        }
        let dateparts = strigDate.split(splitChar);
        strigDate = dateparts[2]+"-"+ dateparts[1]+"-"+ dateparts[0]+"T00:00:00";
        console.log("getMilliFromStringDate: S", strigDate);
        if (!strigDate) {
            console.log("getMilliFromStringDate: R0");
            return 0;
        }
        let dateV =  new Date(strigDate);
        console.log("getMilliFromStringDate: R1-", dateV);
        return dateV.getTime();
    }
}

