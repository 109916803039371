import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastController, AlertController } from '@ionic/angular';
import { Constants } from '../../../app.constants';
import { Config } from '../../core/config-loader/config';

export interface MessageOptions {
    /**
     * Bundle key
     */
    messageKey?: string;

    /**
     * Message to be displayed
     */
    message?: string,

    /**
     * Message from server
     */
    messageServer?: string;

    /**
     * Object containing the parameters to be replaced in the string
     */
    messageParams?: any;

    /**
     * The error throwed
     */
    error?: Error;

    /**
     * The error label, for a best localization of the problem
     */
    errorLabel?: string;

    /**
     * Css class for toast message
     */
    cssClass?: string;

    /**
     * Duration in milisecond.
     */
    duration?: number;

    /**
     * Position for example: top, bottom
     */
    position?: any;

    /**
     * Boolean value for close button
     */
    showCloseButton?: boolean;
}

@Injectable()
export class MessageService {

    constructor(
        private alertCtrl: AlertController,
        private config: Config,
        private toastCtrl: ToastController,
        private translate: TranslateService
    ) { }

    /**
     * Show an error toaster.
     * @param options MessageOptions, contains i18n key message to show
     */
    public async showError(options: MessageOptions) {
        this.openErrorDetail(options);
        // css
        options.cssClass = 'toast-error';
        // adapt message if special case "updagre client"
        if (options.error && options.error.name === Constants.CLIENT_UPDATE_ERROR_TYPE) {
            options.messageServer = options.error.message;
            options.messageKey = null;
            options.showCloseButton = true;
        }

        return this.showMessage(options);
    }

    private async openErrorDetail(options: MessageOptions) {
        const { error, errorLabel } = options;
        if (error && Boolean(this.config.get(Constants.CONFIG.ERROR.DETAIL))) {
            let message = (error instanceof Error)?"[" + error.name + "(" + error.message + ")] - " + error.stack : JSON.stringify(error);
            const alert = await this.alertCtrl.create({
                header: 'Error details',
                subHeader: errorLabel,
                message: message,
                buttons: ['OK'],
                cssClass: "bv-alert-error"
            });
            await alert.present();
        }
    }

    /**
     * Show an success toaster.
     * @param options MessageOptions, contains i18n key message to show
     */
    public async showSuccess(options: MessageOptions) {
        // css
        options.cssClass = 'toast-success';
        return this.showMessage(options);
    }

    /**
     * Show an message toaster.
     * @param options MessageOptions, contains i18n key message to show
     */
    public async showMessage(options: MessageOptions) {
        const opts = this._transformOptions(options);
        const toast = await this.toastCtrl.create(opts);
        return toast.present();
    }

    /**
     * Show an warning toaster.
     * @param options MessageOptions, contains i18n key message to show
     */
    public async showWarning(options: MessageOptions) {
        // css
        options.cssClass = 'toast-warning';
        return this.showMessage(options);
    }

    private _transformOptions(options: MessageOptions): MessageOptions {
        // close button
        //options.closeButtonText = 'X';
        //options.showCloseButton = true;
        // duration
        if (!options.duration && !options.showCloseButton) {
            options.duration = 3000;
        }

        // position
        if (!options.position) {
            options.position = 'top';
        }

        // message
        const { messageKey, messageServer } = options;
        if (messageKey) {
            options.message = this.translate.instant(messageKey, options.messageParams);
        }
        if (messageServer) {
            options.message = messageServer
        }
        return options;
    }

}
