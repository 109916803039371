import { Injectable } from '@angular/core'; 
import { LoadingController } from '@ionic/angular'; 

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  isLoading = false;
  currentLoader: HTMLIonLoadingElement;
  constructor(public loadingController: LoadingController) { } 

  async loadingPresent(message) {
    if (this.isLoading && this.currentLoader) {
      await this.loadingDismiss();
    }
    this.isLoading = true;
    return await this.loadingController.create({
      message: message,
      backdropDismiss: true
    }).then(a => {
      this.currentLoader = a;
      this.currentLoader.present().then(() => {
        if (!this.isLoading) {
          this.currentLoader.dismiss().then(() => console.log('abort laoding'));
        }
      });
    });
  }

  async loadingDismiss() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {
      console.log('loading dismissed');
      this.currentLoader = null;
    });
  }

  setLoaderMessage(message: string){
    if (this.isLoading && this.currentLoader) {
      this.currentLoader.message = message;
    }
    // else {
    //   this.loadingPresent(message);
    // }
  }
}