export enum StatusEnum {
    PENDING = "PENDING",
    ACCEPTED = "ACCEPTED",
    NOTINTRESTED = "REJECTED",
    PREPOSED = "PREPOSED",
    OK = "OK",
    DELIVERED = "DELIVERED",
    BOOKED = "BOOKED",
    REJECTED = "REJECTED",
    FAILED = "FAILED",
    SUCCESS = "SUCCESS",
    ANY = "Any",
    ABORTED = "Aborted",
    TIMED_OUT = "TIMED OUT",
    RESOURCE_NOT_FOUND = "RESOURCE NOT FOUND"
}