import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { DocSubTypeEnum } from './enums';
import { OfflineDocStatusEnum } from './enums/offlineStatus.enum';

export class OfflineImage extends AbstractDocument {

    public fileName: string;
    public localpath: string;
    public orgLocalpath: string;
    public subtype: DocSubTypeEnum;
    public parentDocId: string;
    public tempFile: any;
    //for offline
    public imoNo : string;
    public isSync: OfflineDocStatusEnum = OfflineDocStatusEnum.NOTSYNCED;
    type = Constants.TYPES.OFFLINE_IMAGE;

}