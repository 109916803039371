export abstract class AbstractDocument {

    public _id: string;
    public _rev: string;
    public _deleted: boolean;//to check whether _ based variables are supported or not
    public _conflicts: Array<string> = [];
    //public _attachments: DocumentAttachments = {};
    public channels: Array<string> = [];
    public type: string;
    
    /**Duplicate id same as _id can be used for easy reference as in later versions of Couchbase _id is meta data */
    public uid: string;
    public cdt: number;//Date;
    public cBy: string;
    public mBy: string;
    public mdt: number;//Date;
    public mComments: string;
    public deleted: boolean = false;
    public ddt: number;//Date;
    public dBy: string;
    public dComments: string;
    public platform: number;
    public deviceId: string;
}
/**
 * TODO: Remove the comments
 * */
// export interface DocumentAttachments {
//     [name: string]: DocumentAttachment | DocumentAttachmentStub;
// }

// export class DocumentAttachment {
//     public content_type: string;
//     public data: any;
// }

// export class DocumentAttachmentStub {
//     public content_type: string;
//     public stub: boolean;
// }