import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VesselTableServiceService {
  createdVesselTable : any;
  constructor() { }

  getTableData(){
    return this.createdVesselTable;
  }

  setTableData(data){
    this.createdVesselTable = data;
  }

}
