export abstract class HtmlUtils {

    /**
     * Replace breakline like "\r", "\n" or "\r\n" with a "<br>" tag
     * 
     * @static
     * @param {string} text 
     * @returns 
     * @memberof HtmlUtils
     */
    public static replaceBreakLineWithBR(text: string) {
        return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
    }
}