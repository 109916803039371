import { Injector, Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

// MISC
import { Constants } from "../../../app.constants";
import { Logger, LoggerFactory } from "../../../commons/log";
import { StringUtils } from "../../../commons/utils/string-utils";
import { AuthenticationStore } from "../../service/authenticate/authentication.store";

// MODELS
import { AbstractDocument } from "../../../models/abstract-document.model";
import { LocalStorageService } from '../../service';

@Injectable()
export class SynchroPouchDBProviderParamHelper {

    public static readonly PARAMS = {
        CHECKPOINT: {
            SOURCE: "source"
        },
        DB: {
            LASTSEQ: "db_last_seq"
        },
        SINCE: "since"
    }

    private static readonly CHANNEL_STATS = "REF_STATS";//TODO: Manoj remove it and replace by LOVs?

    // Injection
    private logger: Logger = this.injector.get(LoggerFactory).buildLogger("SynchroPouchDBProviderParamHelper");
    // private storage: Storage = this.injector.get(Storage);
    private authenticationStore: AuthenticationStore = this.injector.get(AuthenticationStore);

    public localStorageService: LocalStorageService = this.injector.get(LocalStorageService);

    constructor(private injector: Injector) { }

    /**
     * Build replication parameters to continously replicate all documents visible for the user (from all channels) 
     */
    public async getLiveSyncParameters(): Promise<any> {
        const params = {
            live: true,
            retry: true,
            batch_size: 20,
            batches_limit: 1,
            filter: false,
            push: {
                checkpoint: SynchroPouchDBProviderParamHelper.PARAMS.CHECKPOINT.SOURCE
            },
            pull: {
                since: await this.getLastSeq()
            }
        };
        this.logger.debug("Sync params", params);
        return params;
    }

    /**
     * Build replication parameters to pull (one shot) all stats documents from channel REF_STATS 
     * Goal is to lower memory usage when retrieving stats documents
     */
    public async getFirstSyncStatsParameters(): Promise<any> {
        return await this.addSinceToSyncParameters({
            live: false,
            retry: false,
            batch_size: 10,
            batches_limit: 1,
            filter: 'sync_gateway/bychannel',
            query_params: { channels: SynchroPouchDBProviderParamHelper.CHANNEL_STATS }//TODO: Manoj Change this
            // query_params: { channels: SynchroPouchDBProviderParamHelper.CHANNEL_STATS }
        });
    }

    /**
     * Build replication parameters to pull (one shot) all documents visible for the user (from all channels) 
     */
    public async getFirstSyncParameters(): Promise<any> {
        return await this.addSinceToSyncParameters({
            live: false,
            retry: true,
            batch_size: 50,
            batches_limit: 1
        });
    }

    /**
     * Add the since parameters.
     * 
     * @private
     * @param {*} params 
     * @returns {Promise<any>} 
     * @memberof SynchroPouchDBProviderParameterHelper
     */
    private async addSinceToSyncParameters(params: any): Promise<any> {
        const runSince = await this.getLastSeq();
        if (runSince) {
            params[SynchroPouchDBProviderParamHelper.PARAMS.SINCE] = runSince;
        }
        this.logger.debug("Sync params", params);
        return params;
    }

    /**
     * Store the last_seq in local storage.
     * 
     * @param {*} seq 
     * @returns {Promise<void>} 
     * @memberof SynchroPouchDBProviderParameterHelper
     */
    public async storeLastSeq(seq: any): Promise<void> {
        if (seq) {
            this.logger.debug(`storeLastSeq: Start to ${seq}`);
            // const userInfo = await this.storage.get(this.authenticationStore.principalValue.login);
            const username = await this.localStorageService.getCurrentUserName();
            let userInfo = await this.localStorageService.getValueForKey(username);
            if (userInfo) {
                userInfo[SynchroPouchDBProviderParamHelper.PARAMS.DB.LASTSEQ] = seq;
                this.logger.debug('storeLastSeq: Updating Seq:', seq);
            }
            else {
                userInfo = {};
                userInfo[SynchroPouchDBProviderParamHelper.PARAMS.DB.LASTSEQ] = seq;
                this.logger.debug('storeLastSeq: Adding Seq:', seq);
            }
            // await this.storage.set(this.authenticationStore.principalValue.login, userInfo);
            await this.localStorageService.setValueForKey(username, userInfo);
            this.logger.debug('storeLastSeq: DONE to :', userInfo);
        }
    }

    /**
     * Get the last_seq from local storage.
     * 
     * @returns {Promise<any>} 
     * @memberof SynchroPouchDBProviderParameterHelper
     */
    public async getLastSeq(): Promise<any> {
        // const userInfo = await this.storage.get(this.authenticationStore.principalValue.login);
        const username = await this.localStorageService.getCurrentUserName();
        const userInfo = await this.localStorageService.getValueForKey(username);
        let seq = 0;//NOTE: Manoj set by default to zero
        if (userInfo) {
            seq = userInfo[SynchroPouchDBProviderParamHelper.PARAMS.DB.LASTSEQ];

        }
        else {
            this.logger.warn('getLastSeq: no sequence found');
        }
        this.logger.debug('getLastSeq: Sequence=', seq);
        return seq;
    }

}