import { Injectable, Injector } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Subscription } from 'rxjs';

// MISC
import { CordovaUtils } from '../../../commons/utils/cordova-utils';
import { Logger, LoggerFactory } from '../../../commons/log';

// PROVIDERS
import { MessageService } from '../utils/message.service';
import { CommonService } from 'src/app/auth/common.service';

@Injectable()
export class ConnectivityService {

    private readonly logger: Logger = this.injector.get(LoggerFactory).buildLogger("ConnectivityService");

    // See "Advanced" here for other types : https://ionicframework.com/docs/native/network/
    private readonly TYPES = {
        _2G: "2g",
        NONE: "none"
    }

    /**
     * Connectivity
     */
    private connected: boolean;
    private connection: Subscription;
    private disconnection: Subscription;

    // Injection
    private network: Network = this.injector.get(Network);
    private messageService: MessageService = this.injector.get(MessageService);
    oauthConfig;
    constructor(protected injector: Injector, private commonService : CommonService) {
      this.oauthConfig = this.commonService.getOAuthConfig();
     }

    /**
     * Init connectivity check.
     */
    public initConnectivityCheck() {
        if (!CordovaUtils.getCordova()) {
            this.connected = true;
            this.logger.info("Network up");
            return;
        }

        // Connectivity check
        this.connected = this.network.type != this.TYPES.NONE;
        this.logger.info(`Network '${this.network.type}' ${this.connected ? 'up' : 'down'}`);
        this.logger.info(`Is poor connection ? ${this.isLimitedConnection()}`);

        this.connection = this.network.onConnect().subscribe(() => {
            this.logger.info(`Network '${this.network.type}' up`);
            this.connected = true;
        });
        this.disconnection = this.network.onDisconnect().subscribe(() => {
            this.logger.info("Network down");
            this.connected = false;
        });
    }

    /**
     * Return connection state.
     * To be true the user must be online and network in a correct quality (not 3G/4G/Wifi)
     * 
     * @param {boolean} [showMessage] If true, show error message if not connected 
     * @returns {boolean} True if connected 
     * @memberof ConnectivityService
     */
    public isConnected(showMessage?: boolean): boolean {
        if (showMessage && (!this.connected || this.isLimitedConnection())) {
            this.messageService.showError({ messageKey: 'global.error.noConnection' });
        }
        return this.connected && !this.isLimitedConnection();
    }

    /**
     * Determine if connection is poor or not.
     * A connection is poor if not connecterd or is 2G. 
     * 
     * @returns {boolean} 
     * @memberof ConnectivityService
     */
    private isLimitedConnection(): boolean {
        const type = this.network.type;
        this.logger.debug("Network type", type);
        return type === this.TYPES._2G || type === this.TYPES.NONE;
    }

    public async isInternetConnected(showMessage?: boolean) {

        return new Promise((resolve, reject) => {
          if (!navigator.onLine) {
            //Do task when no internet connection
            //alert("No internet");
            resolve(false)
          } else {
            this.checkOnlineStatus().then((status) => {
              //alert("Internet");
              resolve(status);
            }).catch(err => {
              console.log("isInternetConnected err--" + err);
              resolve(false);
            });
          }
        })
      }
      
    checkOnlineStatus = async () => {
        try {
          const online = await fetch(this.oauthConfig.loginUrl);
          return online.status >= 200 && online.status < 300; // either true or false
        } catch (err) {
          return false; // definitely offline
        }
      }

}
