import { Injectable, Injector } from '@angular/core';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';

@Injectable()
export class FirebaseAnalyticsService {

    private firebaseAnalytics: FirebaseAnalytics = this.injector.get(FirebaseAnalytics);

    constructor(private injector: Injector) {
    }

    /**
     * Init the firebase analytics
     * 
     * @param user (firstName, lastname, userId)
     */
    public async initContext(user): Promise<void> {
        let name = user.firstName+" "+user.lastName;
        await this.firebaseAnalytics.logEvent('loggedin', {userId: user.userId, name: name});
        await this.firebaseAnalytics.setUserId(user.userId);
    }

    /**
     * Log screen on page navigation
     * 
     * @param {string} screenName
     */
    public async setCurrentScreen(screenName: string): Promise<void> {
        await this.firebaseAnalytics.setCurrentScreen(screenName);
    }

}