import { Injectable, Injector } from '@angular/core';
import { Logger, LoggerFactory } from '../../../commons/log';
import { Storage } from '@ionic/storage';
import { Constants } from 'src/app/app.constants';

@Injectable()
export class LocalStorageService {

    private readonly logger: Logger = this.injector.get(LoggerFactory).buildLogger("LocalStorageService");
    constructor(protected injector: Injector, private storage: Storage) {
    }

    async resetLocalStorage() {
        this.logger.info("resetLocalStorage");
        this.storage.clear();
        // .then(() => {
        //   this.storage.set('isTermsAccepted', true);
        // });
    }

    getValueForKey(key: string) {
        return new Promise<any>(resolve => {
            this.storage.get(key).then((val) => {
                resolve(val);
            })
        });
    }

    setValueForKey(key: string, value: any) {
        return new Promise(resolve => {
            this.storage.set(key, value).then((val) => {
                resolve(val);
            })
        });
    }

    getCurrentUserName() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.USERNAME).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentUserName(username: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.USERNAME, username).then((val) => {
                resolve(val);
            })
        });
    }

    getCurrentEmail() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.USER_EMAIL).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentEmail(email: string) {
        return new Promise<string>(resolve => {
            this.storage.set(Constants.KEYS.USER_EMAIL, email).then((val) => {
                resolve(val);
            })
        });
    }

    getCurrentFullName() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.USER_FULL_NAME).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentFullName(fullName: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.USER_FULL_NAME, fullName).then((val) => {
                resolve(val);
            })
        });
    }

    getCurrentFirstName() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.USER_FIRST_NAME).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentFirstName(firstName: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.USER_FIRST_NAME, firstName).then((val) => {
                resolve(val);
            })
        });
    }

    getCurrentLastName() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.USER_LAST_NAME).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentLastName(lastName: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.USER_LAST_NAME, lastName).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentUserInfo(username: string, email: string, firstName: string, lastName: string) {
        this.setCurrentUserName(username);
        this.setCurrentEmail(email);
        this.setCurrentFirstName(firstName);
        this.setCurrentLastName(lastName);
        this.setCurrentFullName(firstName+" "+lastName);
    }


    getLastSyncTime() {
        return new Promise<Date>(resolve => {
            this.storage.get(Constants.KEYS.SYNC_TIME).then((val) => {
                resolve(val);
            })
        });
    }

    setSyncTime(syncTime: Date) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.SYNC_TIME, syncTime).then((val) => {
                resolve(val);
            })
        });
    }

    getCurrentPage() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.CURRENT_PAGE).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentPage(pageName: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.CURRENT_PAGE, pageName).then((val) => {
                resolve(val);
            })
        });
    }

    getTermsAccepted(username: string) {
        return new Promise<boolean>(resolve => {
            this.storage.get(username+'_'+Constants.KEYS.TERMS_ACCEPTED).then((val) => {
                resolve(val);
            })
        });
    }

    setTermsAccepted(username: string, terms: boolean) {
        return new Promise(resolve => {
            this.storage.set(username+'_'+Constants.KEYS.TERMS_ACCEPTED, terms).then((val) => {
                resolve(val);
            })
        });
    }

    getCookieClosed(username: string) {
        return new Promise<boolean>(resolve => {
            this.storage.get(username+'_'+Constants.KEYS.COOKIE_CLOSED).then((val) => {
                resolve(val);
            })
        });
    }

    setCookieClosed(username: string, cookie: boolean) {
        return new Promise(resolve => {
            this.storage.set(username+'_'+Constants.KEYS.COOKIE_CLOSED, cookie).then((val) => {
                resolve(val);
            })
        });
    }

    getAccessToken() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.ACCESS_TOKEN).then((val) => {
                resolve(val);
            })
        });
    }

    setAccessToken(accessToken: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.ACCESS_TOKEN, accessToken).then((val) => {
                resolve(val);
            })
        });
    }

    getAccessTokenCurrent() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.ACCESS_TOKEN_CURRENT).then((val) => {
                resolve(val);
            })
        });
    }

    setAccessTokenCurrent(accessToken: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.ACCESS_TOKEN_CURRENT, accessToken).then((val) => {
                resolve(val);
            })
        });
    }

    getRefreshToken() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.REFRESH_TOKEN).then((val) => {
                resolve(val);
            })
        });
    }

    setRefreshToken(refreshToken: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.REFRESH_TOKEN, refreshToken).then((val) => {
                resolve(val);
            })
        });
    }

    getJWTToken() {
        return new Promise<any>(resolve => {
            this.storage.get(Constants.KEYS.JWT_TOKEN).then((val) => {
                resolve(val);
            })
        });
    }

    setJWTToken(jwtToken: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.JWT_TOKEN, jwtToken).then((val) => {
                resolve(val);
            })
        });
    }

    getAccessTokenExpiry() {
        return new Promise<Date>(resolve => {
            this.storage.get(Constants.KEYS.ACCESS_TOKEN_EXPIRY).then((val) => {
                resolve(val);
            })
        });
    }

    setAccessTokenExpiry(tokenExpiry: Date) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.ACCESS_TOKEN_EXPIRY, tokenExpiry).then((val) => {
                resolve(val);
            })
        });
    }

    getRefreshTokenExpiry() {
        return new Promise<Date>(resolve => {
            this.storage.get(Constants.KEYS.REFRESH_TOKEN_EXPIRY).then((val) => {
                resolve(val);
            })
        });
    }

    setRefreshTokenExpiry(tokenExpiry: Date) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.REFRESH_TOKEN_EXPIRY, tokenExpiry).then((val) => {
                resolve(val);
            })
        });
    }

    /////
    getCurrentVesselId() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.CURRENT_VESSEL_ID).then((val) => {
                resolve(val);
            })
        });
    }

    async setCurrentVesselId(vesselId: string) {
        return this.storage.set(Constants.KEYS.CURRENT_VESSEL_ID, vesselId);
        // return new Promise(resolve => {
        //     this.storage.set(Constants.KEYS.CURRENT_VESSEL_ID, vesselId).then((val) => {
        //         resolve(val);
        //     })
        // });
    }

    getCurrentTrialId() {
        return new Promise<string>(resolve => {
            this.storage.get(Constants.KEYS.CURRENT_TRIAL_ID).then((val) => {
                resolve(val);
            })
        });
    }

    setCurrentTrialId(trialId: string) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.CURRENT_TRIAL_ID, trialId).then((val) => {
                resolve(val);
            })
        });
    }

    getUserRoles() {
        return new Promise<string[]>(resolve => {
            this.storage.get(Constants.KEYS.USER_ROLE).then((val) => {
                resolve(val);
            })
        });
    }

    setUserRoles(userRoles: string[]) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.USER_ROLE, userRoles).then((val) => {
                resolve(val);
            })
        });
    }

    getUserRoleCheckTimestamp() {
        return new Promise<Date>(resolve => {
            this.storage.get(Constants.KEYS.USER_ROLE_TIMESTAMP).then((val) => {
                resolve(val);
            })
        });
    }

    setUserRoleCheckTimestamp(roleCheckDate: Date) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.USER_ROLE_TIMESTAMP, roleCheckDate).then((val) => {
                resolve(val);
            })
        });
    }

    getConfig() {
        return new Promise<any>(resolve => {
            this.storage.get(Constants.KEYS.CONFIG).then((val) => {
                resolve(val);
            })
        });
    }

    setConfig(config: any) {
        return new Promise(resolve => {
            this.storage.set(Constants.KEYS.CONFIG, config).then((val) => {
                resolve(val);
            })
        });
    }
}