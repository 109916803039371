import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { Doc } from 'src/app/models/doc';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";





@Injectable()
export class DocDao extends AbstractDao<Doc> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.DOC, { create: true, delete: true, update: true }, "DocDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: Doc): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}