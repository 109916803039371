import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Indicate to angular that the provided URL/URI is safe.
 * Use with caution !
 * Useful for displaying blob URI in image.
 * 
 * ex: <img [src]="myblob | safeUrl"  /> with myblob = "blob:..."
 */
@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(url): SafeUrl|null {
        return url != null ? this.sanitizer.bypassSecurityTrustUrl(url) : null;
    }

}