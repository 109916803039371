export enum KeyPersonnelRoleLabel {
    VESSEL_MASTER="Vessel Master",//Not required as per Jan 2022 CR DT-266
    SDPO="SDPO",
    DPO="DPO",
    ELECTRICIAN="Electrician",
    INSTRUMENT_TECHNICIAN="Instrument Technician",
    CHIEF_ENGINEER="Chief Engineer",
    ENGINEER_1="1st Engineer",
    ENGINEER_2="2nd Engineer",
    ENGINEER_3="3rd Engineer",
    CHEIF_OFFICER="Chief Officer",
    CLASS_SURVEYOR="Class Surveyor",
    VESSEL_SUPER_INTENDANT="Vessel Superintendent",
    TRIALS_RESPONSIBLE_PERSON_ONBOARD="Trials responsible person on-board",
    OTHER="OTHER"

}