import { AbstractDocument } from './abstract-document.model';
import { StatusField, AnalysisResult } from './enums';
import { Constants } from '../app.constants';
/**TODO: Manoj check whether better to group feilds in objects, rather then keep all open in same object */
export class StatusDPDocuments extends AbstractDocument {

    public vesselId: string;
    public annualTrialId: string;
    public statusReportId: string;
    
    public cpCorrectCap: StatusField;
    public cpFootPrints: StatusField;
    public cpComments: string;

    public incAny: StatusField;
    public incCount: string;//TODO: Manoj check if should be number or string
    public incDocExplain: StatusField;
    public incComments: string;

    public fmeaRevision: string;
    public fmeaFindingsClosed: StatusField;
    public fmeaNotDocRemarks: string;
    public fmeaLastDPTrialDate: Date;
    public fmeaFindingsClosed2: StatusField; //TODO: Manoj check if duplicate
    public fmeaOpenRecommIncluded: StatusField;
    public fmeaComments: string;

    public othDPOpManual: StatusField;
    public othAppChecklistPresent: StatusField;
    public othDPLogUptoDate: StatusField;
    public othPersonAccessIMCADoc: StatusField;
    public othDocsForVendors: StatusField;
    public othComments: string;

    public mandatoryComplete: boolean;
    type= Constants.TYPES.STATUS_DOCUMENT;
}
