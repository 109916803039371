import { Injector } from '@angular/core';

// MISC
import { Constants } from "../../app.constants";
import { Logger } from "../../commons/log/logger";
import { LoggerFactory } from "../../commons/log/logger-factory";

// MODELS
import { AbstractDocument } from '../../models';

// PROVIDERS - CORE
import { Config } from '../../providers/core/config-loader/config';

// PROVIDERS
import { AuthenticationStore } from './authenticate/authentication.store';
import { ConnectivityService } from './connectivity/connectivity.service';
import { PlatformService } from './platform/platform.service';
import { ErrorService } from './index';
import { HttpRequestBuilder, StringUtils, DateUtils } from "../../commons/utils";
import { LocalStorageService } from './local-storage/local-storage.service';


import { OAuthService } from "angular-oauth2-oidc";
import { CommonService } from 'src/app/auth/common.service';
import { SynchroPouchDBProvider } from '../core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { error } from '@angular/compiler/src/util';
import { throwError } from 'rxjs';

class ServerResponse {
    data: any;
    status: string;
    message: string;
    statusCode: number;
}

export abstract class AbstractService {

    protected logger: Logger;
    public DateUtils = DateUtils;
    public oauthConfigSet = false;

    // deps
    protected _errorService = this.injector.get(ErrorService);
    protected _config = this.injector.get(Config);

    protected get _baseUrl(): string {
        // return 'https://dpsolution-int.bureauveritas.com/api';//
        return Constants.MOBILE_BUILD ? this._config.get(Constants.CONFIG.API.PATH) : Constants.LOCAL_BUILD ? "http://localhost:8080/api":"/api";
        // return !Constants.MOBILE_BUILD ? this._config.get(Constants.CONFIG.API.PATH) : Constants.LOCAL_BUILD ? "http://localhost:8080/api":"/api";
    }

    protected get _baseUrlFileHandler(): string {
        // return 'https://dpsolution-int.bureauveritas.com/bvcommons/filehandler/s3';//
        return Constants.MOBILE_BUILD ? this._config.get(Constants.CONFIG.API.FILE_HANDLER) : Constants.LOCAL_BUILD ? "http://localhost:8080/bvcommons/filehandler/s3":"/bvcommons/filehandler/s3";
        // return !Constants.MOBILE_BUILD ? this._config.get(Constants.CONFIG.API.FILE_HANDLER) : Constants.LOCAL_BUILD ? "http://localhost:8080/bvcommons/filehandler/s3":"/bvcommons/filehandler/s3";
    }

    // Injection
    protected authenticationStore: AuthenticationStore = this.injector.get(AuthenticationStore);
    public connectivityService: ConnectivityService = this.injector.get(ConnectivityService);
    protected platformService: PlatformService = this.injector.get(PlatformService);
    public localStorageService: LocalStorageService = this.injector.get(LocalStorageService);
    public oauthService: OAuthService = this.injector.get(OAuthService);
    public commonService: CommonService = this.injector.get(CommonService);
    public synchroPouchDBProvider: SynchroPouchDBProvider = this.injector.get(SynchroPouchDBProvider);

    public http: HttpClient = this.injector.get(HttpClient);

    constructor(protected injector: Injector, loggerName: string = "AbstractService") {
        this.logger = injector.get(LoggerFactory).buildLogger(loggerName);

        // let oauthConfig = this.commonService.getOAuthConfig();
        // this.logger.info("AbstractService: Called");
        // this.oauthService.configure(oauthConfig);


        // Constants.CONFIG_BASE_URL = "http://localhost:8080";
        // Constants.CONFIG_DB_REMOTE = "http://localhost:4984/dpt/";
        // Constants.CONFIG_DB_SYNC_USER = "dptrialsadmin";
        // Constants.CONFIG_DB_SYNC_PWD = "dptrialsadmin2020";
        // Constants.CONFIG_OAUTH_BASE_URL = "https://sso-veristar-ppr.bureauveritas.com/";
        // Constants.CONFIG_OAUTH_CLIENT_ID = "dptrials";

        // this.setUpOAuthService();
        // if (this.oauthConfigSet) {
        //     this.logger.info("oauthConfig Set again:");
        //     this.setUpOAuthService();
        // }
    }

    public setUpOAuthService(){
        let oauthConfig = this.commonService.getOAuthConfigDynamic();
        this.logger.info("oauthConfig New:", oauthConfig);
        this.oauthService.configure(oauthConfig);
    }

    public getConfig(): Promise<any> {
        this.logger.info("getConfig:");
//public static final String CONFIG_KEY = "GjkgIUTIUI*#kgHF";
        return this.newHttpRequestNoCreds().post('/getConfig').withBody({
            param1: "GjkgIUTIUI*#kgHF",
            param2: "",
            param3: ""
        }).do();
    }

    protected newHttpRequestNoCreds(): HttpRequestBuilder<ServerResponse> {
        return new HttpRequestBuilder<ServerResponse>(this.injector)
            // use base URL
            .withBaseUrl(this._baseUrl)
            // manage business failure
            .interceptResponse((response: ServerResponse, resolve: Function, reject: Function) => {
                resolve(response);
                // if (response.success) {
                //     resolve(response.data);
                // } else {
                //     // business failure
                //     reject(new Error(response.message));
                // }
            });
    }

    postRequst(endPoint: string, body: any) {
        // let URL = Endpoints.surveyscheme.cmms.replace('<schId>', schId);
        return this.http.post(this._baseUrl + endPoint, body);
    }

    protected newHttpRequest(): HttpRequestBuilder<ServerResponse> {
        return new HttpRequestBuilder<ServerResponse>(this.injector)
            // use base URL
            .withBaseUrl(this._baseUrl)
            .withCredentials()
            // manage business failure
            .interceptResponse((response: ServerResponse, resolve: Function, reject: Function) => {
                resolve(response);
                // if (response.success) {
                //     resolve(response.data);
                // } else {
                //     // business failure
                //     reject(new Error(response.message));
                // }
            });
    }

    protected newFileHandlerHttpRequest(): HttpRequestBuilder<ServerResponse> {
        return new HttpRequestBuilder<ServerResponse>(this.injector)
            // use base URL
            .withBaseUrl(this._baseUrlFileHandler)
            // manage business failure
            .interceptResponse((response: any, resolve: Function, reject: Function) => {
                resolve(response);
            });
    }

     /**
     * Add primary doc id to channels of document(s).
     * @param docs
     */
    protected addPrincipalDocIdToChannel(principalDocId: string, ...docs: AbstractDocument[]) {
        // const principal = this.authenticationStore.principalValue;
        // docs.forEach((doc) => doc.channels.push(principal.userId));
        let channel = principalDocId;//"CHANNEL_DEFAULT";
        docs.forEach((doc) => {
            if (doc.channels.indexOf(channel) < 0) {
                doc.channels.push(channel);
            }

        });//TODO: Manoj IMP CHANGE
    }

    /**
     * Add user id to channels of document(s).
     * @param docs
     */
    protected addPrincipalUserToChannel(...docs: AbstractDocument[]) {
        // const principal = this.authenticationStore.principalValue;
        // docs.forEach((doc) => doc.channels.push(principal.userId));
        let channel = "CHANNEL_DEFAULT";
        docs.forEach((doc) => {
            if (doc.channels.indexOf(channel) < 0) {
                doc.channels.push(channel);
            }

        });//TODO: Manoj IMP CHANGE
    }

    /**
     * Add user id to document's channels if not already present.
     * @param userId
     * @param document
     * @returns {boolean} true if document's channels has been updated
     */
    protected addUserToChannel(userId: string, document: AbstractDocument) {
        let updated = false;
        if (userId) {
            const notIncluded = (document.channels.indexOf(userId) < 0);
            if (notIncluded) {
                this.logger.debug('share', document._id, userId);
                document.channels.push(userId);
                updated = true;
            }
        }
        return updated;
    }

    /**
     * Return connection state
     * 
     * @param {boolean} [showMessage] If true, show error message if not connected 
     * @returns {boolean} True if connected 
     * @memberof ConnectivityService
     */
    public isConnected(showMessage?: boolean): boolean {
        return this.connectivityService.isConnected(showMessage) || this.platformService.platformIsBrowser();
    }

    public async fileDownload(fileKey: string, fileName?: string) {
        let apiEndpoint = Constants.ENDPOINT_DOWNLOAD + "?" + Constants.URL_PARAM_S3_KEY + "=" + fileKey;
        if (StringUtils.isNotBlank(fileName)) {
            apiEndpoint = apiEndpoint + "&" + Constants.URL_PARAM_FILE_NAME + "=" + fileName;
        }
        let result = await this.http.get(encodeURI(this._baseUrlFileHandler + apiEndpoint),
            { headers: new HttpHeaders(), responseType: 'blob', reportProgress: true }).toPromise();
        this.logger.debug("fileDownload: ", result);
        return result;
    }

    public async fileUpload(fileKey: string, requestData) {//}, header) {
        // let reqOption = {
        //     headers: new HttpHeaders(header)
        // }
        // let request_timeout: number;
        // request_timeout = Constants.REQUEST_TIMEOUT_UPLOAD;
        let apiEndpoint = Constants.ENDPOINT_UPLOAD + "?" + Constants.URL_PARAM_S3_KEY + "=" + fileKey;
        let result = await this.http.post(encodeURI(this._baseUrlFileHandler + apiEndpoint), requestData).toPromise();
        this.logger.debug("fileUpload: ", result);
        return result;
        // return this.http.post(encodeURI(this._baseUrlFileHandler + apiEndpoint), requestData).pipe(
        //     /* Catch a backend error and let the component know */
        //     catchError(err => {
        //         /* Rethrow error */
        //         return throwError(err);
        //     }), map((res: Response) => res 

        //     ));

        // .timeout(request_timeout)
        //   .map((res: Response) => res)
        //   .catch((e: any) => Observable.throw(e));
    }
}
