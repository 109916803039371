import { UserBasic } from './user-basic.model';
import { VesselStatusEnum, TrialStatus } from './enums';
import { Review } from './review.model';
import { TrialRev } from './annual-trial-rev';
import { AbstractDocument } from './abstract-document.model';
import { Constants } from '../app.constants';

/**VesselStatus information */
export class VesselStatus  extends AbstractDocument{
    public actualDPResponsiblePerson: UserBasic;
    public actualTrialResponsiblePerson: UserBasic;
    public activeRevReview: Review;
    public activeRevApprove: Review;
    public latestRev: TrialRev;
    public imoNo: string;
    public vesselType: string;
    public vesselName: string;
    public vesselId: string;
    public imageKey: string;
    public imageId: string;
    public annualTrialId: string;
    public annualTrialStatus: TrialStatus;
    public dpResponsiblePerson: UserBasic;
    public trialsResponsiblePersonsOnboard: Array<any> = [];
    public status: VesselStatusEnum;
    public statusMsg: string;
    public shortStatusMsg: string;
    public statusMsgGeneralDetails: string;
    type = Constants.TYPES.VESSEL_STATUS;
}