import { LogLevel } from '../logger';
import { StringUtils } from '../../utils';

export abstract class AbstractLogWriter {

    private filter: RegExp | null;

    constructor(private levelThreshold: LogLevel, rawFilter: string) {
        this.filter = StringUtils.isBlank(rawFilter) ? null : new RegExp(this.filter);
    }

    public async log(level: LogLevel, name: string, message: string, ...param: any[]): Promise<void> {
        if (this.couldBeLoggedByLevel(level) && this.couldBeLoggedByFilter(name)) {
            const args = [level, name, message].concat(param);
            return this.doLog.apply(this, args);
        }
    }

    protected abstract async doLog(level: LogLevel, name: string, message: string, ...param: any[]): Promise<void>

    /**
     * Test if a log must be logged ar not.
     * 
     * @private
     * @param {LogLevel} level 
     * @returns {boolean} 
     * @memberof AbstractLogWriter
     */
    private couldBeLoggedByLevel(level: LogLevel): boolean {
        switch (this.levelThreshold) {
            case LogLevel.INFO:
                return level !== LogLevel.DEBUG;
            case LogLevel.WARN:
                return level !== LogLevel.DEBUG && level !== LogLevel.INFO;
            case LogLevel.ERROR:
                return level !== LogLevel.ERROR;
            // Always log if no level or DEBUG level
            default:
                return true;
        }
    }

    /**
     * Test if a log could be logged by the filter.
     * 
     * @private
     * @returns {boolean} 
     * @memberof AbstractLogWriter
     */
    private couldBeLoggedByFilter(name: string): boolean {
        // If no filter, log always
        if (this.filter == null) {
            return true;
        }
        // If filter, test RegEx
        return this.filter.test(name);
    }

}
