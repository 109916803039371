import { Injectable, Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ErrorService {

    // deps
    private translateService: TranslateService = this.injector.get(TranslateService);

    constructor(private injector: Injector) {
        // empty
    }

    public getFormValidatorMessage(fieldErrors: object): string {        
        
        const listOfError: string[] = fieldErrors ? Object.keys(fieldErrors) : [];

        return listOfError.length > 0 ?
            this.getMessage({ messageKey: 'global.error.form.' + listOfError[0], messageParam: fieldErrors[listOfError[0]] })
            : null;
    }

    /**
     *
     * @param {{message?: string; messageKey?: string}} options
     * @returns {string}
     * @private
     */
    private getMessage(options: { message?: string, messageKey?: string, messageParam?: Object }): string {
        const { message, messageKey, messageParam } = options;
       
        let msg = message;
        if (!message && messageKey) {  
            // msg=" this required"
            msg = this.translateService.instant(options.messageKey, messageParam);
        }
        return this.formatMessage(msg);
    }

    /**
     *
     * @param {string} message
     * @returns {string}
     * @private
     */
    private formatMessage(message: string): string {
        return message.replace(/<br ?\/?>/g, '\n');
    }

}
