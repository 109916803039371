import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Pipe({ name: 'translate' })
export class TranslatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) { }

    /** @override */
    transform(value: string) {
        // Translate by normal process
        const translation = this.translateService.instant(value);
        // Store the final translation
        let finalTranslation;
        // If result is an array
        if (Array.isArray(translation)) {
            finalTranslation = "";
            translation.forEach(s => finalTranslation += s);
        }
        // Else result is a simple string
        else {
            finalTranslation = translation
        }
        return finalTranslation;
    }

}