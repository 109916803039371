import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AssignedPeopleModalPageRoutingModule } from './assigned-people-modal-routing.module';

import { AssignedPeopleModalPage } from './assigned-people-modal.page';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AssignedPeopleModalPageRoutingModule,
    NgSelectModule
  ],
  declarations: [AssignedPeopleModalPage]
})
export class AssignedPeopleModalPageModule {}
