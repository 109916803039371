import { Injector } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';

import { AppUtils } from '../../utils';

import { LogReader } from './handler';
import { AuthenticationStore, PlatformService } from '../../../providers/service';

export abstract class AbstractLogReader implements LogReader {

    protected file: File = this.injector.get(File);

    private device: Device = this.injector.get(Device);
    private authenticationStore: AuthenticationStore = this.injector.get(AuthenticationStore);
    private platformService: PlatformService = this.injector.get(PlatformService);
    
    constructor(protected injector: Injector) {
        // nothing
    }

    public async getAllLogs(): Promise<string> {
        const infos = {
            date: new Date().toISOString(),
            timezoneOffset: new Date().getTimezoneOffset(),
            os: this.device.platform,
            device: {
                manufacturer: this.device.manufacturer,
                model: this.device.model,
                serial: this.device.serial
            },
            navigator: `${navigator.vendor} ${navigator.userAgent}`,
            language: navigator.language,
            versions: AppUtils.APP_VERSION,
            user: JSON.stringify(this.authenticationStore.principalValue, [
                // filter to not send sensitive informations such as the user password
                'isAnonymous', 'login', 'profile', 'roles', 'accountId', 'userId', 'tokenId'
            ]),
            file: {
                freespace: this.platformService.platformIsBrowser() ? 'NA' : await this.file.getFreeDiskSpace()
            }
        };

        const logs = await this.readLogs();
        return `{
            "infos": ${JSON.stringify(infos)},
            "logs": [${logs}]
        }`;
    }

    protected abstract readLogs(): Promise<string>;

}
