export enum RevStatus {
    NOT_STARTED = 0,
    GENERATED = 10,          //draft
    SENT_FOR_REVIEW = 20,   //pending review
    REVIEW_DENIED = 30,
    REVIEW_ACCEPTED = 40,
    //REVIEWER_UPDATED = 50,//Manoj 230720202: To check if this is needed?
    SENT_FOR_APPROVAL = 50,
    APPROVAL_DENIED = 60,
    APPROVAL_ACCEPTED = 70//Basically means APPROVED
}