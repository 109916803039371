import { Injectable, Injector } from '@angular/core';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { StatusReport, StatusSwitchBoardDPSystem, StatusThruster, StatusCrewPersonnel, StatusDPDocuments, StatusEngine } from '../../../models';

// PROVIDERS
import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { StatusReportDao, StatusCrewPersonnelDao, StatusEngineDao, StatusThrusterDao, StatusDPDocumentsDao, StatusSwitchboardDao } from '../../dao';
import { SectionStatus } from 'src/app/models/enums/sectionStatus.enum';

@Injectable()
export class StatusReportService extends AbstractService {

    constructor(protected injector: Injector,
        private statusReportDao: StatusReportDao,
        private thrusterDao: StatusThrusterDao,
        private engineDao: StatusEngineDao,
        private crewPersonnelDao: StatusCrewPersonnelDao,
        private dpDocumentsDao: StatusDPDocumentsDao,
        private switchboardDao: StatusSwitchboardDao) {
        super(injector, "StatusReportService");
    }

    /**
     *
     * @param {StatusReport} doc
     * @returns {Promise<StatusReport>}
     */
    public async createStatusReport(doc: StatusReport): Promise<StatusReport> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);

        let response = this.statusReportDao.create(doc);
        console.log(response);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<StatusReport>}
     */
    public async getStatusReport(docId: string): Promise<StatusReport> {
        return await this.statusReportDao.get(docId);
    }

    /**
     *
     * @returns {Promise<Array<StatusReport>>}
     */
    public async findAll(): Promise<Array<StatusReport>> {
        return this.statusReportDao.findAll();
    }

    /**
     *
     * @returns {Promise<Array<StatusReport>>}
     */
    public async findAllCreatedStatusReport(): Promise<Array<StatusReport>> {
        var query = {
            type: Constants.TYPES.STATUS_REPORT,
            created: true
        }
        return this.statusReportDao.findBasedOnQuery(query);
    }

    /**
     *
     * @returns {Promise<Array<StatusReport>>}
     */
    public async findStatusReportForAnnualTrial(annualTrialIdVal: string): Promise<Array<StatusReport>> {

        var query = {
            type: Constants.TYPES.STATUS_REPORT,
            annualTrialId: annualTrialIdVal
            //TODO: Manoj add created param also
        }
        return this.statusReportDao.findBasedOnQuery(query);
    }

    public async getCurrentStatusReportForAnnualTrial(annualTrialIdVal: string): Promise<StatusReport> {
        let statusReportArray = await this.findStatusReportForAnnualTrial(annualTrialIdVal);
        if (statusReportArray && statusReportArray.length > 0) {
            return statusReportArray[0];
        }
        return undefined;
    }

    public async getStatusOfCurrentStatusReportForAnnualTrial(annualTrialIdVal: string, statusReport?: StatusReport): Promise<SectionStatus> {
        if (statusReport) {
            statusReport = await this.getCurrentStatusReportForAnnualTrial(annualTrialIdVal);
        }
        if (statusReport) {
            if (statusReport.completed) {
                return SectionStatus.COMPLETED;
            }
            else if (statusReport.created) {
                return SectionStatus.CREATED;
            }
            else if (!statusReport.created) {
                return SectionStatus.DRAFT;
            }
        }
        return SectionStatus.PENDING;
    }

    /**
     *
     * @param {StatusReport} statusReport
     * @returns {Promise<boolean>}
     */
    public async removeStatusReport(statusReport: StatusReport): Promise<boolean> {
        return this.statusReportDao.delete(statusReport);
    }

    /**
     *
     * @param {StatusReport} doc
     * @returns {Promise<StatusReport>}
     */
    public async updateStatusReport(doc: StatusReport): Promise<StatusReport> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.statusReportDao.update(doc);
    }

    //Create section for 4 steps
    public async createStatusThruster(doc: StatusThruster): Promise<StatusThruster> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        let response = this.thrusterDao.create(doc);
        return response;
    }
    public async createStatusCrewPersonnel(doc: StatusCrewPersonnel): Promise<StatusCrewPersonnel> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        let response = this.crewPersonnelDao.create(doc);
        return response;
    }
    public async createStatusDPDocuments(doc: StatusDPDocuments): Promise<StatusDPDocuments> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        let response = this.dpDocumentsDao.create(doc);

        return response;
    }
    public async createStatusEngine(doc: StatusEngine): Promise<StatusEngine> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        let response = this.engineDao.create(doc);
        return response;
    }
    public async createStatusSwitchBoardDPSystem(doc: StatusSwitchBoardDPSystem): Promise<StatusSwitchBoardDPSystem> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        let response = this.switchboardDao.create(doc);
        return response;
    }


    //Update steps
    /**
    *
    * @param {StatusThruster} doc
    * @returns {Promise<StatusThruster>}
    */
    public async updateStatusThruster(doc: StatusThruster): Promise<StatusThruster> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.thrusterDao.update(doc);
    }
    /**
    *
    * @param {StatusCrewPersonnel} doc
    * @returns {Promise<StatusCrewPersonnel>}
    */
    public async updateStatusCrewPersonnel(doc: StatusCrewPersonnel): Promise<StatusCrewPersonnel> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.crewPersonnelDao.update(doc);
    }
    /**
    *
    * @param {StatusDPDocuments} doc
    * @returns {Promise<StatusDPDocuments>}
    */
    public async updateStatusDPDocuments(doc: StatusDPDocuments): Promise<StatusDPDocuments> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.dpDocumentsDao.update(doc);
    }
    /**
    *
    * @param {StatusEngine} doc
    * @returns {Promise<StatusEngine>}
    */
    public async updateStatusEngine(doc: StatusEngine): Promise<StatusEngine> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.engineDao.update(doc);
    }
    /**
    *
    * @param {StatusSwitchBoardDPSystem} doc
    * @returns {Promise<StatusSwitchBoardDPSystem>}
    */
    public async updateStatusSwitchBoardDPSystem(doc: StatusSwitchBoardDPSystem): Promise<StatusSwitchBoardDPSystem> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.switchboardDao.update(doc);
    }

    //Get step document
    /**
    *
    * @param {docId} docId
    * @returns {Promise<StatusThruster>}
    */
    public async getStatusThruster(docId: string): Promise<StatusThruster> {
        return await this.thrusterDao.get(docId);
    }
    /**
    *
    * @param {string} docId
    * @returns {Promise<StatusCrewPersonnel>}
    */
    public async getStatusCrewPersonnel(docId: string): Promise<StatusCrewPersonnel> {
        return await this.crewPersonnelDao.get(docId);
    }
    /**
    *
    * @param {string} docId
    * @returns {Promise<StatusDPDocuments>}
    */
    public async getStatusDPDocuments(docId: string): Promise<StatusDPDocuments> {
        return await this.dpDocumentsDao.get(docId);
    }
    /**
    *
    * @param {string} docId
    * @returns {Promise<StatusEngine>}
    */
    public async getStatusEngine(docId: string): Promise<StatusEngine> {
        return await this.engineDao.get(docId);
    }

    /**
    *
    * @param {string} docId
    * @returns {Promise<StatusSwitchBoardDPSystem>}
    */
    public async getStatusSwitchBoardDPSystem(docId: string): Promise<StatusSwitchBoardDPSystem> {
        return await this.switchboardDao.get(docId);
    }

    public async getStatusThrusters(docIds: Array<string>): Promise<Array<StatusThruster>> {
        let docs: Array<StatusThruster> = [];
        for (let index = 0; index < docIds.length; index++) {
            const docId = docIds[index];
            if (StringUtils.isNotBlank(docId)) {
                let doc = await this.thrusterDao.get(docId);
                if (doc) {
                    docs.push(doc);    
                }
            }
        }
        return docs;
    }

    public async getStatusEngines(docIds: Array<string>): Promise<Array<StatusEngine>> {
        let docs: Array<StatusEngine> = [];
        for (let index = 0; index < docIds.length; index++) {
            const docId = docIds[index];
            if (StringUtils.isNotBlank(docId)) {
                let doc = await this.engineDao.get(docId);
                if (doc) {
                    docs.push(doc);    
                }
            }
        }
        return docs;
    }

    public async getStatusCrewPersonnels(docIds: Array<string>): Promise<Array<StatusCrewPersonnel>> {
        let docs: Array<StatusCrewPersonnel> = [];
        for (let index = 0; index < docIds.length; index++) {
            const docId = docIds[index];
            if (StringUtils.isNotBlank(docId)) {
                let doc = await this.crewPersonnelDao.get(docId);
                if (doc) {
                    docs.push(doc);    
                }
            }
        }
        return docs;
    }

    /**
    *
    * @param {string} docId
    * @returns {Promise<boolean>}
    */
    public async deleteThurster(docId: string): Promise<boolean> {
        let doc = await this.getStatusThruster(docId);
        if (doc) {
            return this.thrusterDao.delete(doc);
        }
        else {
            return false;
        }
    }

    /**
   *
   * @param {string} docId
   * @returns {Promise<boolean>}
   */
    public async deleteEngine(docId: string): Promise<boolean> {
        let doc = await this.getStatusEngine(docId);
        if (doc) {
            return this.engineDao.delete(doc);
        }
        else {
            return false;
        }
    }

    /**
    *
    * @param {string} docId
    * @returns {Promise<boolean>}
    */
    public async deleteCrewPersonnel(docId: string): Promise<boolean> {
        let doc = await this.getStatusCrewPersonnel(docId);
        if (doc) {
            return this.crewPersonnelDao.delete(doc);
        }
        else {
            return false;
        }
    }

}
