import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';

/**User information */
export class User extends AbstractDocument {
    /**SLO of user */
    public username: string;
    public fullName: string;
    public email: string;
    public company: string;
    public role: Array<string> = [];
    public token: string;
    public platform: any;
    public version: string = "0.0.0";
    public versionCode: number = 0;
    // public pushNotifications: boolean;
    // public cookiesAccepted: boolean;
    // public termsAccepted: boolean;
    // public termsAcceptanceDate: number;
    // public termsAcceptedVersion: number;
    // public active: number;
    /**To be used incase user gets deleted or profile expires */
    // public activeExpiry: number;
    type= Constants.TYPES.USER;

}