import { AbstractDocument } from './abstract-document.model';
import { Constants } from '../app.constants';

export class StatusCrewPersonnel extends AbstractDocument {

    public vesselId: string;
    public annualTrialId: string;
    public statusReportId: string;

    public name: string;
    public rank: string;
    public training: string;
    public timeOnVessel: string;
    public dpOperationsExp: string;

    type = Constants.TYPES.STATUS_CREW_PERSONNEL;
}
