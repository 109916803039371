import { Injectable } from '@angular/core';
//import { Camera, CameraOptions } from '@ionic-native/camera';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { File, IWriteOptions, FileEntry } from '@ionic-native/file/ngx';
//import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer';


//import { IOSFilePicker } from '@ionic-native/file-picker';
import { Constants } from '../../../app.constants';
import { LoadingService, AppManagerService } from '../../../providers/service';
import { Platform, ActionSheetController } from '@ionic/angular';

import { FilePath } from '@ionic-native/file-path/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
//import { Base64 } from '@ionic-native/base64';
//import { FileHandlerService } from '../image-doc/file-handler.service';
import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';
import { CordovaUtils } from '../../../commons/utils/cordova-utils';

import { ImageResizer, ImageResizerOptions } from '@ionic-native/image-resizer/ngx';



@Injectable({
  providedIn: 'root'
})
export class NativeApiService {
  nativePath;
  isCordovaAvailable: boolean;

  main_directory_root: any;
  //IOSFilePicker
  constructor(private file: File, private filePath: FilePath, private camera: Camera, private appmanager: AppManagerService, private loadingService: LoadingService, private platform: Platform, private chooser: Chooser, private webView: WebView, private transfer: FileTransfer, private actionSheetController: ActionSheetController, private imageResizer: ImageResizer) {
    this.platform.ready().then(() => {
      this.isCordovaAvailable = CordovaUtils.cordovaIsAvailable();
      if (this.isCordovaAvailable) {

        if (!this.platform.is('ios')) {
          this.main_directory_root = this.file.externalDataDirectory;
        }
        else
          this.main_directory_root = this.file.documentsDirectory;

        this.file.checkDir(this.main_directory_root, Constants.IMAGE_MAIN_DIRECTORY)
          .then(_ => {
            //console.log('Directory exists');
          })
          .catch(err => {
            this.file.createDir(this.main_directory_root, Constants.IMAGE_MAIN_DIRECTORY, false).then(() => {
              if (Constants.DEBUG_ENABLED) {
                alert("directory created at path: " + this.main_directory_root + Constants.IMAGE_MAIN_DIRECTORY)
              }
            });
          });
      }
    });
  }

  async openActionSheet(type, callBack) {

    let buttons: Array<any> = [];
    let data;
    buttons = [
      {
        text: 'Capture',
        icon: "camera",
        role: 'destructive',
        handler: async () => {
          data = await this.takePicture(this.camera.PictureSourceType.CAMERA);
          await actionSheet.dismiss(data);
          return true;
        }
      },
      {
        text: 'Choose from Gallery',
        icon: "image",
        role: 'destructive',
        handler: async () => {
          //data = await this.takePicture(this.camera.PictureSourceType.PHOTOLIBRARY);
          data = await this.chooseFile(Constants.TYPE_IMAGE);
          await actionSheet.dismiss(data);
          return true;
        }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
    if (type == Constants.TYPE_DOC || type == Constants.TYPE_IMAGE_DOC) {
      type = Constants.TYPE_DOC;
      buttons.push({
        text: 'Choose Document',
        icon: "document",
        role: 'destructive',
        handler: async () => {
          data = await this.chooseFile(type);
          await actionSheet.dismiss(data);
          return true;
        }
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: "Select option",
      backdropDismiss: true,
      buttons: buttons
    });

    actionSheet.onDidDismiss().then(data => {
      console.log("Data in onDismiss:", data);
      callBack(data.data);
    });
    setTimeout(async () => {
      await actionSheet.present();
    }, 400)
  }




  /*async takePicture_old(sourceType: PictureSourceType): Promise<any> {
    var options: CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.FILE_URI,
      sourceType: sourceType,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      saveToPhotoAlbum: false,
      correctOrientation: true,
    };

    var correctPath;
    var currentName;

    let imagePath = await this.camera.getPicture(options);
    let filePath;
    if (this.platform.is('android') && sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
      filePath = await this.filePath.resolveNativePath(imagePath);
      correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
      currentName = filePath.substring(filePath.lastIndexOf('/') + 1);
    } else {
      correctPath = imagePath.substr(0, imagePath.lastIndexOf('/') + 1);
      currentName = imagePath.substr(imagePath.lastIndexOf('/') + 1);
    }

    let imageFile = await this.getFileFromPath(correctPath + currentName)
    if (imageFile && imageFile.hasOwnProperty("file")) {
      return ({
        file: imageFile["file"],
        name: currentName,
        filePath: correctPath
      });
    } else {
      return false;
    }

  }*/





  takePicture_working(sourceType: PictureSourceType) {
    return new Promise((resolve, reject) => {
      var options: CameraOptions = {
        quality: 100,
        sourceType: sourceType,
        saveToPhotoAlbum: false,
        correctOrientation: true,
        targetHeight: 1000,
        targetWidth: 1000,
        allowEdit: false
      };

      this.camera.getPicture(options).then(imagePath => {
        if (this.platform.is("android") && sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
          this.filePath.resolveNativePath(imagePath).then(filePath => {
            let correctPath = filePath.substr(0, filePath.lastIndexOf("/") + 1);
            let currentName = imagePath.substring(imagePath.lastIndexOf("/") + 1, imagePath.lastIndexOf("?"));
            this.getFileFromPath(correctPath + currentName).then(imageFile => {
              resolve({
                file: imageFile["file"],
                name: currentName,
                filePath: correctPath
              });
            })
              .catch(error => {
                reject(error);
              });
          });
        } else {  //camera
          var currentName = imagePath.substr(imagePath.lastIndexOf("/") + 1);
          var correctPath = imagePath.substr(0, imagePath.lastIndexOf("/") + 1);
          this.getFileFromPath(correctPath + currentName).then(imageFile => {
            resolve({
              file: imageFile["file"],
              name: currentName,
              filePath: correctPath
            });

          })
            .catch(error => {
              reject(error);
            });
        }
      })
        .catch(error => {
          reject(error);
        });
    });
  }



  chooseFile_working(fileType) {
    let filter: string;
    switch (fileType) {
      case Constants.TYPE_DOC:
        filter = Constants.MIME_TYPE_PDF;
        break;
      case Constants.TYPE_IMAGE:
        filter = Constants.MIME_TYPE_IMAGE_JPEG + "," + Constants.MIME_TYPE_IMAGE_PNG;
        break;
      case Constants.TYPE_IMAGE_DOC:
        filter = Constants.MIME_TYPE_PDF + "," + Constants.MIME_TYPE_IMAGE_JPEG + "," + Constants.MIME_TYPE_IMAGE_PNG;
        break;
    }

    return new Promise<any>((resolve, reject) => {
      this.chooser.getFile(filter)
        .then(async (file) => {
          if (file) {
            //uri: "content://com.android.externalstorage.documents/document/primary%3ADownload%2Fship2.jpg"
            //dataURI: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQ...

            await this.loadingService.loadingPresent(Constants.MSG_LOADING);
            this.filePath.resolveNativePath(file.uri).then(async filePath => {   //filePath : file:///storage/emulated/0/Download/ship2.jpg

              let correctPath = filePath.substr(0, filePath.lastIndexOf('/') + 1);
              let currentName = filePath.substring(filePath.lastIndexOf('/') + 1);

              this.getFileFromPath(correctPath + currentName)
                .then(imageFile => {
                  console.log("Choosefile : Inside getFileFromPath***", imageFile);
                  this.loadingService.loadingDismiss();
                  resolve({
                    file: imageFile["file"],
                    name: currentName,
                    filePath: correctPath
                  });
                })
                .catch(error => {
                  this.loadingService.loadingDismiss();
                  if (Constants.DEBUG_ENABLED) {
                    alert("Native-api : Error occured in choosing file getFileFromPath :" + JSON.stringify(error));
                  }
                  reject(error);
                });
            })
              .catch(error => {
                this.loadingService.loadingDismiss();
                if (Constants.DEBUG_ENABLED) {
                  alert("Native-api : Error occured in choosing file resolveNativePath :" + JSON.stringify(error));
                }
              });
          }
        })
    });
  }


  takePicture(sourceType: PictureSourceType) {
    return new Promise((resolve, reject) => {
      var options: CameraOptions = {
        quality: 100,
        sourceType: sourceType,
        saveToPhotoAlbum: false,
        correctOrientation: true,
        targetHeight: 600,
        targetWidth: 600,
        allowEdit: false
      };

      this.camera.getPicture(options).then(async imagePath => {
        if (this.platform.is("android") && sourceType === this.camera.PictureSourceType.PHOTOLIBRARY) {
          this.filePath.resolveNativePath(imagePath).then(filePath => {
            let correctPath = filePath.substr(0, filePath.lastIndexOf("/") + 1);
            let currentName = imagePath.substring(imagePath.lastIndexOf("/") + 1, imagePath.lastIndexOf("?"));

            this.getFileFromPath(correctPath + currentName).then(imageFile => {

              this.saveFileToDevice(imageFile["file"], currentName).then(result => {
                resolve({
                  file: imageFile["file"],
                  name: currentName,
                  filePath: this.main_directory_root + Constants.IMAGE_MAIN_DIRECTORY
                });
              });

              // resolve({
              //   file: imageFile["file"],
              //   name: currentName,
              //   filePath: correctPath
              // });

            })
              .catch(error => {
                if (Constants.DEBUG_ENABLED) {
                  alert("Native-api : Error occured in getFileFromPath of  PHOTOLIBRARY : " + JSON.stringify(error));
                }
                reject(error);
              });
          });
        } else {  //camera
          var currentName = imagePath.substr(imagePath.lastIndexOf("/") + 1);
          var correctPath = imagePath.substr(0, imagePath.lastIndexOf("/") + 1);

          //***** image resize code***** // Sudhanshu: April 2022 DT-306 Resize Image method return wrong path: Start
          // let resizedData = await this.resizeImage(imagePath);
          // if (resizedData) {
          //   let resizedImgName = resizedData.filePath.substr(resizedData.filePath.lastIndexOf("/") + 1);
          //   let resizedImgPath = resizedData.filePath.substr(0, resizedData.filePath.lastIndexOf("/") + 1);

          //   let imageFile = await this.getFileFromPath(resizedImgPath + resizedImgName);
          //   if (imageFile) {
          //     resolve({
          //       file: imageFile["file"],
          //       name: resizedImgName,
          //       filePath: resizedImgPath
          //     });
          //   }
          // }
          //***** image resize code ends***** // Sudhanshu: April 2022 DT-306 Resize Image method return wrong path: End


          this.getFileFromPath(correctPath + currentName).then(imageFile => { //uncommented before resize code
            resolve({
              file: imageFile["file"],
              name: currentName,
              filePath: correctPath
            });

          })
            .catch(error => {
              if (Constants.DEBUG_ENABLED) {
                alert("Native-api : Error occured whie using camera : " + JSON.stringify(error));
              }
              reject(error);
            });
        }
      })
        .catch(error => {
          if (Constants.DEBUG_ENABLED) {
            alert("Native-api : Error occured in catch of getPicture : " + JSON.stringify(error));
          }
          reject(error);
        });
    });
  }


  chooseFile(fileType) {
    let filter: string;
    switch (fileType) {
      case Constants.TYPE_DOC:
        filter = Constants.MIME_TYPE_PDF;
        break;
      case Constants.TYPE_IMAGE:
        filter = Constants.MIME_TYPE_IMAGE_JPEG + "," + Constants.MIME_TYPE_IMAGE_PNG;
        break;
      case Constants.TYPE_IMAGE_DOC:
        filter = Constants.MIME_TYPE_PDF + "," + Constants.MIME_TYPE_IMAGE_JPEG + "," + Constants.MIME_TYPE_IMAGE_PNG;
        break;
    }

    return new Promise<any>((resolve, reject) => {
      this.chooser.getFile(filter)
        .then(async (file) => {
          if (file) {

            await this.loadingService.loadingPresent(Constants.MSG_LOADING);
            let uri = file.uri;
            let path = uri;

            let blob;
            if (fileType == Constants.TYPE_IMAGE) {   //resize condition
              let resizeImage = await this.createThumbnail(file.dataURI);
              if (resizeImage)
                blob = this.dataURItoBlob(resizeImage);
            } else {
              blob = this.dataURItoBlob(file.dataURI);
            }
            
            if (blob) {
              this.saveFileToDevice(blob, file.name).then(result => {
                this.loadingService.loadingDismiss();
                resolve({
                  file: blob,
                  name: file.name,
                  filePath: this.main_directory_root + Constants.IMAGE_MAIN_DIRECTORY
                });

              });
            }

          }
        })
    });
  }

  public dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }
    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  }

  saveFileToDevice(blob, fileName): Promise<FileEntry> {
    return new Promise<FileEntry>((resolve, reject) => {
      this.file.writeFile(this.main_directory_root + Constants.IMAGE_MAIN_DIRECTORY, fileName, blob, { replace: true }).then((fileEntry) => {
        resolve(fileEntry);
      })
        .catch(error => {
          if (Constants.DEBUG_ENABLED) {
            alert("Path in saveFileTodevice :" + this.main_directory_root + Constants.IMAGE_MAIN_DIRECTORY);
            alert("Native-api : Error occured while saving file at app directory in saveFileToDevice:" + JSON.stringify(error));
          }
          console.log("Error occured while writing file : ", error);
          reject(error);
        })
    })

  }

  // Sudhanshu: April 2022 Resize Image method return wrong path and imageResizer plugin is not working properly: Start
  resizeImage(uri) {
    return new Promise<any>((resolve, reject) => {
      let options = {
        uri: uri,
        quality: 90,
        width: 600,
        height: 600
      } as ImageResizerOptions;

      this.imageResizer
        .resize(options)
        .then((filePath: string) => {
          console.log('FilePath', filePath);
          resolve({
            filePath: filePath
          });
        })
        .catch(e => {
          console.log(e)
        });

    });
  }
  // Sudhanshu: April 2022 Resize Image method return wrong path: End

  createThumbnail(bigimg) {
    return new Promise(resolve => {
      this.generateFromImage(bigimg).then((data) => {
        resolve(data)
      });
    })
  }

  generateFromImage(img) {
    return new Promise(resolve => {
      let canvas: any = document.createElement("canvas");
      let image = new Image();
      image.src = img;
      image.onload = () => {
        let imagewidth = image.naturalWidth;
        let imageheight = image.naturalHeight;
        console.log("Orig width : height " + imagewidth + ":" + imageheight);
        let canvas_width = Constants.thumbImageSize;
        let setCanvasHeight: any = Constants.thumbImageSize;
        if (imageheight > imagewidth) {
          let heightratio: any = imageheight / imagewidth;
          let heghtFixed: any = heightratio.toFixed(2);
          setCanvasHeight = Math.round(canvas_width * heghtFixed);
          console.log("heightratio " + heightratio + ": heghtFixed " + heghtFixed + "---setCanvasHeight : " + setCanvasHeight);
        }
        canvas.width = canvas_width;
        canvas.height = setCanvasHeight;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, canvas_width, setCanvasHeight);
        // IMPORTANT: 'jpeg' NOT 'jpg'
        var dataUrl = canvas.toDataURL(Constants.MIME_TYPE_IMAGE_JPEG);
        resolve(dataUrl);
      }
    })
  }

  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  getFileFromPath(filepath) {
    return new Promise((resolve, reject) => {
      this.file
        .resolveLocalFilesystemUrl(filepath)
        .then(entry => {
          (<FileEntry>entry).file(file => {
            console.log("Inside FileEntry of getFileFromPath:----");
            console.log(file);
            const reader = new FileReader();
            reader.onloadend = () => {
              const imgBlob = new Blob([reader.result], {
                type: file.type
              });

              resolve({
                file: imgBlob,
              });

              /*this.copyFileToDir(filepath, file.name).then(data => {
                resolve({
                  file: imgBlob,
                  fileName: data.name,
                  filepath: data.nativeURL,
                  viewPath: this.pathForImage(data.nativeURL)
                });
              });*/
            };
            reader.readAsArrayBuffer(file);
          });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  pathForImage(img) {
    if (img === null) {
      return "";
    } else {
      let converted = this.webView.convertFileSrc(img);
      return converted;
    }
  }

  copyFileToDir(filePath, filename) {
    var correctPath = filePath.substr(0, filePath.lastIndexOf("/") + 1);
    console.log("correctpath in copytodir: ", correctPath);
    return this.file.copyFile(correctPath, filename, this.main_directory_root + Constants.IMAGE_MAIN_DIRECTORY, filename);
  }

  checkFileExistsInDevice(docFiledestinationPath: string, fileName: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.file.checkFile(docFiledestinationPath + "/", fileName).then(() => {
        resolve(true);
      })
        .catch(error => {
          resolve(false);
        });
    });
  }

}
