import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: 'app-warning-msg-modal',
  templateUrl: './warning-msg-modal.page.html',
  styleUrls: ['./warning-msg-modal.page.scss'],
})
export class WarningMsgModalPage implements OnInit {

  modalTitle: string;
  modelId: number;
  currentVesselId:any; 
  modalContent : any;
  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private router: Router
  ) { }

  ngOnInit() { 
    
    this.currentVesselId= this.navParams.data.id;
   
    this.modelId = this.navParams.data.paramID;
    // this.modalTitle = this.navParams.data.paramTitle;
    this.modalTitle = "Warning";
    // this.modalContent = "Please fill all mandatory fields in order to complete the vessel creation."
  }

  async onCancel() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  goToDashboard() {
    this.router.navigate(['/vessel-general-details', this.currentVesselId]);   

    // this.router.navigateByUrl("/dashboard");
    this.modalController.dismiss();
  }
}
