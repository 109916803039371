import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as _ from 'lodash';
import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import cordovaSQLitePlugin from 'pouchdb-adapter-cordova-sqlite';

// MISC
import { Constants } from "../../../app.constants";
import { Logger } from '../../../commons/log/logger';

// MODELS
import { AbstractDocument } from '../../../models';

// PROVIDERS - CORE
import { Config } from '../../../providers/core';
import { CordovaUtils } from '../../../commons/utils/cordova-utils';
import { PlatformService } from '../../service/platform/platform.service';
import { LoggerFactory } from '../../../commons/log/logger-factory';
import { Observable, from, forkJoin } from 'rxjs';

@Injectable()
export class PouchdbDatabase {

    private static END_KEY_CHAR = '\uffff';

    private logger: Logger = this.injector.get(LoggerFactory).buildLogger("PouchdbDatabase");
    private config: Config = this.injector.get(Config);
    private platform: Platform = this.injector.get(Platform);
    private platformService: PlatformService = this.injector.get(PlatformService);

    private database;
    private offlineDatabase;
    public currentUsername;

    public constructor(private injector: Injector) {
        this.database = new PouchDB("DPSolution");//TODO MANOJ TO CHECK WHETHER REQUIRED OR NOT
        //PouchDB.plugin(pouchdbDebug);
        /**TODO: Use environment config to enable disable */
        //PouchDB.debug.enable();
    }

    /**
     * Get the PouchDB database
     */
    public get db() {
        return this.database;
    }

    /**
     * Disconnect the database
     */
    public disconnect() {
        if (this.database) {
            this.logger.info('Closing database');
            this.database.close();
            this.database = null;
        }
    }

    /**
     * Delete the database
     */
    public deleteDatabase() {
        if (this.database) {
            this.logger.info('Destroying database');
            this.database.destroy();
            this.database = null;
        }
    }

    /**
     * Initialize the database
     * @param {Object} options
     */
    public async initDb(username: string): Promise<void> {
        await this.platform.ready();
        this.currentUsername = username;
        PouchDB.plugin(PouchDBFind);
        // If Cordova available and not on Browser
        if (CordovaUtils.cordovaIsAvailable() && !this.platformService.platformIsBrowser()) {
            this.logger.debug("Add plugin cordovaSQLitePlugin");
            PouchDB.plugin(cordovaSQLitePlugin);
        }
        //PouchDB.debug.enable('*');
        //PouchDB.debug.disable();
        this.logger.info(`Init database for user: ${username}`);
        this.database = new PouchDB(this.config.get(Constants.CONFIG.DATABASE.WORKER.LOCAL) + ":" + username, this.getLocalOption());
        this.logger.info(`Database created with adapter : ${this.database.adapter}`);
        //alert('Database created with adapter : ' + this.database.adapter)
        this.createDBIndex();

        //if (CordovaUtils.cordovaIsAvailable() && !this.platformService.platformIsBrowser()) {
        if (CordovaUtils.cordovaIsAvailable()) {
            this.offlineDatabase = new PouchDB(this.config.get(Constants.CONFIG.DATABASE.WORKER.LOCAL) + ":" + username + "_offline", this.getLocalOption());
            this.logger.info(`Database created with adapter for offline mobile: ${this.offlineDatabase.adapter}`);
        }
    }

    private createDBIndex() {
        const indexes: Array<string> = ["type", "uid", "createdDate", "cdt", "mdt", "username", "created"];
        this.buildIndexes(indexes);
        // this.database.createIndex({
        //     index: { fields: ["type", "uid", "createdDate", "cdt", "mdt", "username", "created"] }
        // }).then((res) => {
        //     this.logger.info("createDBIndex:", this.database);
        // })
    }

    buildIndexes(indexes: Array<string>): Observable<any> {
        const observables = [];
        for (const index of indexes) {
            observables.push(from(this.database.createIndex({
                index: { fields: [index] }
            })));
        }
        return forkJoin(observables);
    }

    private getLocalOption() {
        let localOption;
        // If Cordova available and not on Browser
        if (CordovaUtils.cordovaIsAvailable() && !this.platformService.platformIsBrowser()) {
            // If has PluginSqlite
            if (CordovaUtils.getPluginSqlite()) {
                this.logger.debug('PouchDB: using sqlite adapter');
                //alert('PouchDB: using sqlite adapter');
                localOption = Constants.CONFIG.DATABASE.WORKER.LOCAL_OPTION_SQLITE;
            } else {
                this.logger.debug('PouchDB: using default adapter as sqlitePlugin is not available');
                //alert('PouchDB: using default adapter as sqlitePlugin is not available');
                localOption = Constants.CONFIG.DATABASE.WORKER.LOCAL_OPTION;
            }
        } else {
            this.logger.debug('PouchDB: using default adapter');
            //alert('PouchDB: using default adapter');
            localOption = Constants.CONFIG.DATABASE.WORKER.LOCAL_OPTION;
        }

        return localOption;
    }

    /**
     * @returns true if database is available
     */
    public isDatabaseAvailable(): boolean {
        return this.database != null;
    }
    
    /**
     * @returns true if offlineDatabase is available
     */
    public isOfflineDatabaseAvailable(): boolean {
        return this.offlineDatabase != null;
    }

    /**
     * Check if database is empty.
     * 
     * @private
     * @returns {Promise<boolean>} 
     * @memberof PouchdbDatabase
     */
    public async isDatabaseEmpty(): Promise<boolean> {
        const infos = await this.database.info();
        return infos.doc_count == 0;
    }
    /**
     * Check if offlineDatabase is empty.
     * 
     * @private
     * @returns {Promise<boolean>} 
     * @memberof PouchdbDatabase
     */
    public async isOfflineDatabaseEmpty(): Promise<boolean> {
        const infos = await this.offlineDatabase.info();
        return infos.doc_count == 0;
    }

    /**
     * Find all documents
     *
     * @param {string} [prefix]  to search for id, may be null
     * @returns {Promise<Array<AbstractDocument>>}
     */
    public async findAll(prefix?: string): Promise<any> {
        let options = {
            include_docs: true,
            startkey: null,
            endkey: null
        };

        if (prefix) {
            options.startkey = prefix;
            options.endkey = prefix + PouchdbDatabase.END_KEY_CHAR;
        }

        this.logger.debug("findAll", prefix, options);
        const result = await this.database.allDocs(options);
        this.logger.debug("findAll: result:", result);
        return _.map(result.rows, 'doc');
    }

    /**
     * Return a range of documents of current type, for pagination purpose.
     * Use documents ids to return range of documents, as it is the prefered method for performance reason over using 'skip'.
     *
     * @param {string} prefix
     * @param {string} getFromId id of the first element to include, may be null
     * @param {string} getUntilId id of the last element to include, use this or fetchSize to limit
     * @param {number} fetchSize number of elements to fetch, use this or fetchSize to limit
     * @param {boolean} [reverseOrder] if true return documents by descending order fo their _id
     * @returns {Promise<Array<AbstractDocument>>}
     */
    public async findRange(prefix: string, getFromId: string, getUntilId: string, fetchSize: number, reverseOrder?: boolean): Promise<any> {
        const startKey = getFromId || prefix;
        const endkey = getUntilId || (prefix + PouchdbDatabase.END_KEY_CHAR);
        let options = {
            include_docs: true,
            // in reverse order, startkey and endkey should also be reversed
            startkey: reverseOrder ? endkey : startKey,
            endkey: reverseOrder ? startKey : endkey,
            // "limit" cannot be "null" else it bugs if using WebSql as provider
            limit: fetchSize || undefined,
            descending: reverseOrder
        };

        const result = await this.database.allDocs(options);
        return _.map(result.rows, 'doc');
    }

    /**
     * Find all document using an index
     * @param {string} fields
     * @returns {any}
     */
    public findAllUsingIndex(fields: any) {
        return this.database.find({
            selector: fields
        });
    }

    /**
     * Get a specific document by id
     * @param {string} id
     * @param {{ attachments?: boolean }} [options={}] options - 
     *  - attachments: true (default) to get the full attachments ;
     * @returns {Promise<AbstractDocument>}
     */
    public async get(id: string, options: { attachments?: boolean } = {}): Promise<AbstractDocument> {
        const doc = await this.database.get(id, options);
        this.logger.debug("get", id, options, doc);
        return doc;
    }

    /**
     * Send document
     * @param {string} id
     * @param document
     * @returns {Promise<AbstractDocument>}
     */
    public async put(id: string, document: AbstractDocument): Promise<AbstractDocument> {
        document._id = id;
        try {
            let oldDoc = await this.get(id);

            document.uid = id;
            document._rev = oldDoc._rev;
        } catch (error) {
            if (error.status != "404") {
                throw error;
            }
        }
        let response = await this.database.put(document);
        document._rev = response.rev;
        return document;
    }

    /**
     * Send documents
     * @param documents
     * @returns {Promise<AbstractDocument>}
     */
    public async putAll(documents: AbstractDocument[]): Promise<AbstractDocument[]> {
        const response = await this.database.bulkDocs(documents);

        // setting new revision (docs are returned in the same order than the supplied ones)
        for (let i = 0; i < response.length; i++) {
            documents[i]._rev = response[i].rev;
        }

        return documents;
    }

    /**
     * Remove a document
     * @param document
     * @returns {Promise<boolean>}
     */
    public async remove(document: AbstractDocument): Promise<boolean> {
        return this.get(document._id).then(async result => {
            let response = await this.database.remove(document);
            return response.ok;
        });
    }


    /**
     * Find all documents
     *
     * @param {any} [queryParams]  the query which includes type and more params to search with
     * @param {any} [sort] OPTIONAL sort by array options: ['name'] OR [{name:'desc'}] OR [{series: 'desc'}, {debut: 'desc'}]
     * @param {number} [limit] OPTIONAL limit to be specified
     * @returns {Promise<Array<AbstractDocument>>}
     */
    public async findBasedOnQuery(queryParams: any, sortBy?: any, limit?: number): Promise<any> {
        // queryParams._id = { "$gte": null };
        let options: any = {
            selector: queryParams
        };
        if (sortBy) {
            // sortBy._id= {"$gte": null};
            options.sort = sortBy;
        }
        if (limit) {
            options.limit = limit;
        }
        this.logger.debug("findBasedOnQuery: ", options);
        const result = await this.database.find(options);
        this.logger.debug("findBasedOnQuery: result:", result);
        return _.map(result.docs);
        // if (result) {
        //     this.logger.debug("findBasedOnQuery: result:", result);
        //     return _.map(result.docs);
        // }
        // else {
        //     this.logger.error("findBasedOnQuery: error");
        //     return null;
        // }

    }

    //offline db

    /**
     * Send document
     * @param {string} id
     * @param document
     * @returns {Promise<AbstractDocument>}
     */
    public async putOffline(id: string, document: AbstractDocument): Promise<AbstractDocument> {
        document._id = id;
        try {
            let oldDoc = await this.getOffline(id);

            document.uid = id;
            document._rev = oldDoc._rev;
        } catch (error) {
            if (error.status != "404") {
                throw error;
            }
        }
        let response = await this.offlineDatabase.put(document);
        document._rev = response.rev;
        return document;
    }

    /**
     * Get a specific document by id
     * @param {string} id
     * @param {{ attachments?: boolean }} [options={}] options - 
     *  - attachments: true (default) to get the full attachments ;
     * @returns {Promise<AbstractDocument>}
     */
    public async getOffline(id: string, options: { attachments?: boolean } = {}): Promise<AbstractDocument> {
        const doc = await this.offlineDatabase.get(id, options);
        this.logger.debug("get", id, options, doc);
        return doc;
    }

    /**
     * Find all documents
     *
     * @param {string} [prefix]  to search for id, may be null
     * @returns {Promise<Array<AbstractDocument>>}
     */
    public async findAllOffline(prefix?: string): Promise<any> {
        let options = {
            include_docs: true,
            startkey: null,
            endkey: null
        };

        if (prefix) {
            options.startkey = prefix;
            options.endkey = prefix + PouchdbDatabase.END_KEY_CHAR;
        }

        this.logger.debug("findAll", prefix, options);
        const result = await this.offlineDatabase.allDocs(options);
        this.logger.debug("findAll: result:", result);
        return _.map(result.rows, 'doc');
    }

    /**
     * Remove a document
     * @param document
     * @returns {Promise<boolean>}
     */
    public async removeOffline(document: AbstractDocument): Promise<boolean> {
        return this.getOffline(document._id).then(async result => {
            let response = await this.offlineDatabase.remove(document);
            return response.ok;
        });
    }



}
