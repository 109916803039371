import { Injectable } from '@angular/core';
import { Vessel } from "../../models/vessel.model";
import { VesselService } from "../service/vessel/vessel.service";


@Injectable({
  providedIn: 'root'
})
export class CreateVesselService {
  vesselId: any;
  vesselMandatoryComplete: boolean = false;
  mainParticularComplete: boolean = false;
  principalMandatoryComplete: boolean = false;
  mainDpMandatoryComplete: boolean = false;
  dpConfigMandatoryComplete: boolean = false;
  vesselDetailsComplete: boolean = false;

  
  activeAnnualId:any; //TODO RADHIKA
  isVesselCreationProcessContinue: boolean;
  vessel: Vessel;

  vesselEditCreateMode : string;

  constructor(private vesselService: VesselService) { }

  getVesselMode(){
    return this.vesselEditCreateMode;
  }
  
  setVesselMode(mode){
    this.vesselEditCreateMode = mode;
  }

  getVesselCreationProcessContinue() {
    return this.isVesselCreationProcessContinue;
  }
  setVesselCreationProcessContinue(isOngoing) {
    this.isVesselCreationProcessContinue = isOngoing;
  }

  async getVessel() {
    this.vessel = await this.vesselService.getVessel(this.vesselId);
    return this.vessel;
  }
  getVesselId() {
    return this.vesselId;
  }
  setVesselId(vesselId) {
    this.vesselId = vesselId;
  }

  setActiveAnnualId(activeAnnualId){
    this.activeAnnualId = activeAnnualId;
  }

  getActiveAnnualId(){
     return this.activeAnnualId ;
  }

  getMandatoryCompleteStatus() {
    let mandatoryCompleteStatus = {
      vesselMandatoryComplete: this.vesselMandatoryComplete,
      mainParticularComplete: this.mainParticularComplete,

      principalMandatoryComplete: this.principalMandatoryComplete,
      mainDpMandatoryComplete: this.mainDpMandatoryComplete,
      dpConfigMandatoryComplete: this.dpConfigMandatoryComplete
    }
    return mandatoryCompleteStatus;
  }
  resetMandatoryFields() {
    this.vesselMandatoryComplete = false;
    this.mainParticularComplete = false;

    this.principalMandatoryComplete = false;
    this.mainDpMandatoryComplete = false;
    this.dpConfigMandatoryComplete = false;
  }

  
  setVesselMandatoryComplete(isComplete) {
    this.vesselMandatoryComplete = isComplete;
  }
  setPrincipalMandatoryComplete(isComplete) {
    this.principalMandatoryComplete = isComplete;
  }
  setMainDpMandatoryComplete(isComplete) {
    this.mainDpMandatoryComplete = isComplete;
  }
  setDpConfigMandatoryComplete(isComplete) {
    this.dpConfigMandatoryComplete = isComplete;
  }
  getVesselDetailsComplete() {
    return this.vesselDetailsComplete;
  }
  setvesselDetailsComplete(isComplete) {
    this.vesselDetailsComplete = isComplete;
  }

  async fetchMandatoryCompletion(vesselId) {  //use to fetch already created ship mandatory status when clicked on edit
    let mandatoryObj = [];
    if (vesselId != undefined) {

      let vesselObj = this.vesselService.getVesselData();
      if(!vesselObj){
        vesselObj = await this.vesselService.getVessel(vesselId);
      }
      let vesselMandatoryComplete : any;
      let mainParticularsMandat: any;
      let mainDPEquipmentMandat: any;
      let principalDimensIdMandat: any;
      let dpConfigurationMandat: any;


      if (vesselObj.hasOwnProperty("mandatoryComplete")) {
        this.vesselMandatoryComplete = vesselObj.mandatoryComplete;
      } else
        this.vesselMandatoryComplete = false;

      if (vesselObj.hasOwnProperty("mainParticularsId")) {
        mainParticularsMandat = this.vesselService.getMainParticularsData();
        if(!mainParticularsMandat){
          mainParticularsMandat = await this.vesselService.getMainParticulars(vesselObj.mainParticularsId);
        }
        this.mainParticularComplete = mainParticularsMandat.mandatoryComplete;
      } else
        this.mainParticularComplete = null;

      if (vesselObj.hasOwnProperty("mainDPEquipmentId")) {
        mainDPEquipmentMandat = this.vesselService.getMainDpEquipmentData();
        if(!mainDPEquipmentMandat){
          mainDPEquipmentMandat = await this.vesselService.getMainDPEquip(vesselObj.mainDPEquipmentId);
        }
        this.mainDpMandatoryComplete = mainDPEquipmentMandat.mandatoryComplete;
      } else
        this.mainDpMandatoryComplete = null;

      if (vesselObj.hasOwnProperty("principalDimensId")) {
        principalDimensIdMandat = this.vesselService.getPrincipalDimensionData();
        if(!principalDimensIdMandat){
          principalDimensIdMandat = await this.vesselService.getPrincipalDimens(vesselObj.principalDimensId);
        }
        this.principalMandatoryComplete = principalDimensIdMandat.mandatoryComplete;
      } else
        this.principalMandatoryComplete = null;

      if (vesselObj.hasOwnProperty("dpConfigurationId")) {
        dpConfigurationMandat = await this.vesselService.getDPConfig(vesselObj.dpConfigurationId);
        this.dpConfigMandatoryComplete = dpConfigurationMandat.mandatoryComplete;
      } else
        this.dpConfigMandatoryComplete = null;

      //Note :here completion status is fetcched but in breadcrumb, the incomplete status is used to check hence used ! i.e !complete is incomplete
      mandatoryObj.push(!(this.vesselMandatoryComplete && this.mainParticularComplete)); 
      /*mandatoryObj.push(!this.principalMandatoryComplete); 
      mandatoryObj.push(!this.mainDpMandatoryComplete); 
      mandatoryObj.push(!this.dpConfigMandatoryComplete);*/ 
      mandatoryObj.push((this.principalMandatoryComplete != null) ? !this.principalMandatoryComplete :  null); 
      mandatoryObj.push((this.mainDpMandatoryComplete!=null) ? !this.mainDpMandatoryComplete : null); 
      mandatoryObj.push((this.dpConfigMandatoryComplete !=null ) ? !this.dpConfigMandatoryComplete : null); 

    }
    if (vesselId == undefined) {
      //mandatoryObj = [true,false,true,true]; //same as note from above ; true = incomplete, false = complete
      mandatoryObj = [null,null,null,null]; //same as note from above ; true = incomplete, false = complete, null for new creation
    }
    return mandatoryObj;
  }
}
