import { MainDataService } from './data/main-data.service';
import { AppManagerService } from 'src/app/providers/service';
import { Injectable } from '@angular/core';
import { StatusReportService } from '.';
import { StringUtils } from 'src/app/commons/utils';

//import {  StatusReportService } from "../../../providers/service";


@Injectable({
  providedIn: 'root'
})
export class CreateStatusReportService {

  statusReportDetails: any;
  documentMandatoryComplete: boolean = false;
  switchboardSysMandatoryComplete: boolean = false;
  statusId: any;

  dpSystemMandatoryComplete: boolean = false;
  dpDocMandatoryComplete: boolean = false;

  constructor(private statusReportService: StatusReportService, private appManager : AppManagerService, private mainDataService : MainDataService) { }

  setStatusReportDetails(statusReportData) {
    this.statusReportDetails = statusReportData;
  }
  getStatusReportDetails() {
    return this.statusReportDetails;
  }

  getStatusReportId() {
    return this.statusId;
  }
  setStatusReportId(statusId) {
    this.statusId = statusId;
  }

  setDpDocMandatoryComplete(isComplete) {
    this.documentMandatoryComplete = isComplete;
  }
  setSwitchboardSysMandatoryComplete(isComplete) {
    this.switchboardSysMandatoryComplete = isComplete;
  }
  getMandatoryCompleteStatus() {
    let mandatoryCompleteStatus = {
      dpSystemMandatoryComplete: this.dpSystemMandatoryComplete,
      dpDocMandatoryComplete: this.dpDocMandatoryComplete,
    }
    return mandatoryCompleteStatus;
  }
  resetMandatoryFields() {
    this.documentMandatoryComplete = false;
    this.switchboardSysMandatoryComplete = false;
  }

  async fetchstatusReportMandatoryCompletion(reportId, vesselId?) {
    let mandatoryObj = [];
    let canUserEditInfo : boolean = true;
    if(vesselId){
      let vesselAccess = this.appManager.getSelectedVesselAccess();
      let vesselStatus = await this.mainDataService.getVesselStatus(vesselId, false);
      canUserEditInfo = this.mainDataService.canUserEditInfo(vesselAccess.isExpert, vesselAccess.isTrialResp, vesselStatus, true);
      if(!canUserEditInfo){
        return [false, false, false, false];
      }
    }
    
    if (reportId != undefined) {

      let statusReportObj = await this.statusReportService.getStatusReport(reportId); //getStatusReport
      let dpDocMandat: any;

      if (statusReportObj.dpDocumentsId != undefined && !StringUtils.isBlank(statusReportObj.dpDocumentsId)) {
        dpDocMandat = await this.statusReportService.getStatusDPDocuments(statusReportObj.dpDocumentsId);
        this.dpDocMandatoryComplete = dpDocMandat.mandatoryComplete;
      } else
        this.dpDocMandatoryComplete = false;

      //Note :here completion status is fetcched but in breadcrumb, the incomplete status is used to check hence used ! i.e !complete is incomplete
      mandatoryObj.push(false);
      mandatoryObj.push(false);
      mandatoryObj.push(!this.dpDocMandatoryComplete);
      mandatoryObj.push(false);

    }
    if (reportId == undefined) {
      //mandatoryObj = [false, false, true, false]; //same as note from above; ex. [not mandatory,nm, Mandatory,nm]
      mandatoryObj = [null, null, null, null]; //same as note from above
    }
    
    return mandatoryObj;
  }

}
