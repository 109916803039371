export abstract class CordovaUtils {

    /**
     * Return the cordova object.
     * 
     * @static
     * @returns {*} 
     * @memberof CordovaUtils
     */
    public static getCordova(): any {
        const cordova = window['cordova'];
        if (!cordova) {
            console.debug("Cordova not available");
            return undefined;
        }
        return cordova;
    }

    /**
     * Check if cordova is available.
     * 
     * @static
     * @returns {boolean} 
     * @memberof CordovaUtils
     */
    public static cordovaIsAvailable(): boolean {
        return this.getCordova();
    }

    /**
     * Return a cordova plugin by name.
     * 
     * @private
     * @static
     * @param {string} pluginName 
     * @returns 
     * @memberof CordovaUtils
     */
    private static getPlugin(pluginName: string): any {
        const cordova = CordovaUtils.getCordova();
        if (cordova) {
            return cordova.plugins[pluginName];
        }
        return undefined;
    }

    /**
     * Return the plugin cordova-pdf-generator if available.
     * 
     * @static
     * @returns {*} 
     * @memberof CordovaUtils
     */
    public static getPluginPdfGenerator(): any {
        return CordovaUtils.getPlugin("pdf");
    }

    /**
     * Return the plugin sqlitePlugin if available.
     * 
     * @static
     * @returns {*} 
     * @memberof CordovaUtils
     */
    public static getPluginSqlite(): any {
        return (<any>window).sqlitePlugin;
    }
}