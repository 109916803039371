
/**User Basic information */
export class UserBasic {
    /**SLO of user */
    public username: string;
    public fullName: string; //Might keep only full name
    public firstName: string;
    public lastName: string;
    public email: string;
    public office: string;
    public roles: Array<string> = [];

}