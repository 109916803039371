import { Component, Injector } from '@angular/core';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Platform, MenuController, Events, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { CustomFunctionsService } from './providers/service/custom-functions.service';



import { Constants } from './app.constants';
// UTILS
import { Logger, LoggerFactory } from './commons/log';
import { AppUtils, CordovaUtils, StringUtils } from './commons/utils/index';
import { PlatformType, PlatformService, ConnectivityService, AppManagerService, LocalStorageService } from './providers/service/index';
import { CommonService } from './auth/common.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth/auth.config';
import { Location } from '@angular/common';

//import { EmailComposer } from '@ionic-native/email-composer/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public appVersion: string;
  public userFullName: string;
  public currentUserName: string;
  redirection: boolean;
  errorMessage;
  decodedToken: any = false;
  public loading: boolean = false;

  public versionNo: string = Constants.DP_SOLUTION_VERSION;

  selectedMenu: any;
  private logger: Logger = this.injector.get(LoggerFactory).buildLogger("DPSolution");
  public sideMenu: any;
  // Injection
  private connectivityService: ConnectivityService = this.injector.get(ConnectivityService);
  private keyboard: Keyboard = this.injector.get(Keyboard);
  private platform: Platform = this.injector.get(Platform);
  private platformService: PlatformService = this.injector.get(PlatformService);
  private splashScreen: SplashScreen = this.injector.get(SplashScreen);
  private statusBar: StatusBar = this.injector.get(StatusBar);
  private translate: TranslateService = this.injector.get(TranslateService);

  CryptoJS: any;
  skey: string = "DPSolution@1828";

  constructor(private injector: Injector,
    private customFunctionsService: CustomFunctionsService,
    private menu: MenuController,
    private oauthService: OAuthService,
    private appManagerService: AppManagerService,
    private router: Router, private events: Events,
    private navCntrl: NavController,
    private location: Location) { //private emailComposer: EmailComposer
    this.selectedMenu = "dashboard";

    // let oauthConfig = commonService.getOAuthConfig();
    // this.logger.info("oauthConfig:",oauthConfig);
    // this.oauthService.configure(oauthConfig);

    this.initializeApp();

    this.navigationMenu();
    // this.checkConfig();
    // this.oauthService.configure(authConfig);
    // this.login();


    if (!CordovaUtils.cordovaIsAvailable()){
      this.oauthService.configure(authConfig);
      this.login();
    }
    else {
      this.getUserNameForMobile();
    }


    // this.performLogin();
    // if (Constants.MUS_SSO_ENABLED) {
    //   this.appManagerService.localStorageService.getCurrentUserName().then((userName) => {
    //     this.currentUserName = userName;
    //     this.logger.debug("PreLogin: getCurrentUserName:",userName);
    //     this.login();
    //   });

    //   this.appManagerService.localStorageService.getCurrentFullName().then((fullName) => {
    //     this.userFullName = fullName;
    //   });
    // }
    // else {
    //   this.userFullName = "Test User";
    // }
  }

  async getUserNameForMobile() {
    this.events.subscribe('username:changed', async (userFullName) => {
      this.userFullName = await this.appManagerService.localStorageService.getCurrentFullName();
      console.log("userFullName:----", userFullName);
      this.userFullName = userFullName;
    });
    
    if (StringUtils.isBlank(this.userFullName)) {
      this.userFullName = await this.appManagerService.localStorageService.getCurrentFullName();
      // this.events.unsubscribe('username:changed');
    }
    this.connectivityService.initConnectivityCheck();
  }


  // checkConfig() {


  //   //   var name = "dptrialsadmin";
  //   //   var encryptedUsername = encodeURI(this.CryptoJS.AES.encrypt(name, this.skey));
  //   //   var name1 = "dptrialsadmin2020";
  //   //   var encryptedUsername1 = encodeURI(this.CryptoJS.AES.encrypt(name1, this.skey));
  //   //   var name2 = "dptrials";
  //   //   var encryptedUsername2= encodeURI(this.CryptoJS.AES.encrypt(name2, this.skey));
  //   // //   //var url = "U2FsdGVkX18/k9poNcvxPdUSmBgv+aSCZc0xWNFM4qGgK/mFQKbEdPYKfH+PhYpvrIBbPGiLyvibYHf/fRnqQw==";
  //   // //  // var encryptedUsername = this.CryptoJS.AES.decrypt(decodeURI(url), this.skey).toString(this.CryptoJS.enc.Utf8);
  //   //    console.log("url "+encryptedUsername)
  //   //    console.log("url2 "+encryptedUsername1)
  //   //    console.log("dptrials "+encryptedUsername2)

  //   // Constants.CONFIG_BASE_URL = "http://localhost:8080";
  //   // Constants.CONFIG_DB_REMOTE = "http://localhost:4984/dpt/";
  //   // Constants.CONFIG_DB_SYNC_USER = "dptrialsadmin";
  //   // Constants.CONFIG_DB_SYNC_PWD = "dptrialsadmin2020";
  //   // Constants.CONFIG_OAUTH_BASE_URL = "https://sso-veristar-ppr.bureauveritas.com/";
  //   // Constants.CONFIG_OAUTH_CLIENT_ID = "dptrials";
  //   // this.postConfig();
  //   this.logger.debug("getConfig: config from db: START");
  //   this.appManagerService.localStorageService.getConfig().then(config => {
  //     if (config) {
  //       this.logger.debug("getConfig: config from db:", config);
  //       Constants.CONFIG_BASE_URL = config["param1"];
  //       Constants.CONFIG_DB_REMOTE = config["param2"];
  //       Constants.CONFIG_DB_SYNC_USER = this.CryptoJS.AES.decrypt(decodeURI(config["param3"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_DB_SYNC_PWD = this.CryptoJS.AES.decrypt(decodeURI(config["param4"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_OAUTH_BASE_URL = config["param5"];
  //       Constants.CONFIG_OAUTH_CLIENT_ID = this.CryptoJS.AES.decrypt(decodeURI(config["param6"]), this.skey).toString(this.CryptoJS.enc.Utf8);

  //       this.postConfig();
  //     }
  //     else {
  //       this.loadConfig();
  //     }

  //   }).catch(err => {
  //     this.logger.debug("checkConfig: error:", err);
  //     this.loadConfig();
  //   });
  // }

  // loadConfig() {
  //   this.appManagerService.getConfig()
  //   .then(res => {
  //     this.logger.debug("loadConfig: success:", res);
  //     if (res) {
  //       let config = res.response.data;
  //       this.logger.debug("loadConfig: config from net:", config);
  //       Constants.CONFIG_BASE_URL = config["param1"];
  //       Constants.CONFIG_DB_REMOTE = config["param2"];
  //       Constants.CONFIG_DB_SYNC_USER = this.CryptoJS.AES.decrypt(decodeURI(config["param3"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_DB_SYNC_PWD = this.CryptoJS.AES.decrypt(decodeURI(config["param4"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_OAUTH_BASE_URL = config["param5"];
  //       Constants.CONFIG_OAUTH_CLIENT_ID = this.CryptoJS.AES.decrypt(decodeURI(config["param6"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       this.appManagerService.localStorageService.setConfig(config);
  //       this.postConfig();
        
  //     }
  //   })
  //   .catch(err => {
  //     this.logger.debug("fetchVesselInfoNeptune: error:", err);
  //   });
  // }

  // async postConfig() {
  //   this.logger.debug("postConfig:");
  //   this.appManagerService.oauthConfigSet = true;
  //   await this.appManagerService.setUpOAuthService();
  //   // setTimeout(() => {
  //   //   this.performLogin();
  //   // }, 10000);
  //   this.performLogin();
  // }

  navigationMenu() {
    this.sideMenu =
      [
        {
          title: "Dashboard",
          url: "/dashboard",
          icon: "assets/icon/dashboard.svg",
          alias: "dashboard"
        },
        {
          title: "Bureau Veritas Group Website",
          url: null,
          icon: "assets/icon/network-business-blue.svg",
          alias: "website"
        },
        {
          title: "Contact DP Solution Support",
          url: null,
          icon: "assets/icon/user-chat-2.svg",
          alias: "support"
        },
        // {
        //   title: "Legals",
        //   url: "/legals",
        //   icon: "assets/icon/rewards-badge-2.svg",
        //   alias: "legals"
        // }
      ]
  }

  menuCLicked(alias) {
    if (alias == "website" || alias == "support") {
      //this.selectedMenu = "no-background"  
    } else
      this.selectedMenu = alias;
    if (alias == "website") {
      this.customFunctionsService.openWebsiteURL("https://group.bureauveritas.com/");
    }
    if (alias == "support") {
      this.sendEmail();
    }
  }

  sendEmail() {
    let email = "dpsolution@bureauveritas.com"
    this.platform.ready().then(() => {
      window.open('mailto:' + email);
    });
  }

  toggleMenu() {
    this.menu.close();
  }


  async initializeApp() {
    this.CryptoJS = require("crypto-js");
    await this.platform.ready();

    //Hardware Navigation
			this.platform.backButton.subscribeWithPriority(0, () => {
			  console.log(`Current Url: `, this.router.url);
			  if(this.router.url === '/dashboard') {
				navigator['app'].exitApp();
			  }
			  else {
				this.navCntrl.setDirection("back", true, "back");
				this.location.back();
			  }
			});

    this.statusBar.styleDefault();
    this.statusBar.overlaysWebView(false);
    this.statusBar.backgroundColorByHexString('#177AA0');
    this.splashScreen.hide();

    // get app version and keep it for app life
    await AppUtils.setAppVersion(this.injector);
    await AppUtils.setAppPackageName(this.injector);
    this.logger.info(`App version ${AppUtils.APP_VERSION}`);
    this.logger.info(`App Package ${AppUtils.APP_PACKAGE_NAME}`);
    this.logger.info(`Platform(s): ${this.platform.platforms().join(', ')} => type=${PlatformType[this.platformService.getPlatformType()]}`);

    // this.appVersion = "v" + AppUtils.APP_VERSION;
    if (CordovaUtils.getCordova()) {
      this.appVersion = "v" + AppUtils.APP_VERSION;
    } else {
      this.appVersion = "dev";
    }

    // init the connectivity services
    this.connectivityService.initConnectivityCheck();

    // set default language
    await this.configureLanguage(Constants.LANG.EN);

    // Add custom class on body for keyboard events
    this.keyboard.hideFormAccessoryBar(false);
    this.keyboard.disableScroll(false);
    this.keyboard.onKeyboardShow().subscribe(() => {
      document.body.classList.add('keyboard-open');
    });
    this.keyboard.onKeyboardHide().subscribe(() => {
      document.body.classList.remove('keyboard-open');
    });


  }

  // async performLogin() {
  //   if (Constants.MUS_SSO_ENABLED) {
  //     // this.userFullName = this.appManagerService.userFullName;
  //     await this.appManagerService.login();
  //     this.userFullName = this.appManagerService.userFullName;
  //     if (StringUtils.isBlank(this.userFullName)) {
  //       this.userFullName = await this.appManagerService.localStorageService.getCurrentFullName();
  //     }
  //   }
  //   else {
  //     this.appManagerService.initSyncDb(await this.appManagerService.localStorageService.getCurrentUserName());
  //     this.userFullName = await this.appManagerService.localStorageService.getCurrentFullName();
  //   }
  // }

  /**
     * Configure Language
     * @param {string} lang
     * @private
     */
  private async configureLanguage(lang: string): Promise<void> {
    this.translate.setDefaultLang(lang);
    await new Promise<void>((resolve) => this.translate.use(lang).subscribe(() => resolve()));
  }

  // logoutUser() {
  //   this.toggleMenu();
  //   this.appManagerService.logoutUser();
  // }

  getUserFullName() {
    return StringUtils.getCamelCase(this.userFullName);
  }

  login() {
    this.oauthService.tryLogin().then((res: any) => {
      console.log("tryLogin() -- res-- ", JSON.stringify(res))
      if (!this.isLogged()) {
        // alert("Inside if")
        this.redirection = true;
        this.oauthService.initCodeFlow();
        console.log("token----in if: ", this.oauthService.getAccessToken());
      } else {
        // alert("Inside else")
        const jwtHelper = new JwtHelperService();
        this.decodedToken = jwtHelper.decodeToken(this.oauthService.getAccessToken());
        this.redirection = false;
        this.appManagerService.commonService.setLogged();
        console.log("token access----in else: ", this.oauthService.getAccessToken());
        console.log("token access expiry----: ", this.oauthService.getAccessTokenExpiration());
        console.log("token----JWT:", this.decodedToken);
        console.log("token refresh----in else: ", this.oauthService.getRefreshToken());
        let firstName: string = this.decodedToken.firstName;
        let lastName: string = this.decodedToken.lastName;
        this.userFullName = firstName +" "+ lastName;
        this.appManagerService.saveLoggedInInfo(this.decodedToken);
        // this.oauthService.silentRefresh();
      }
    }).catch(err => {
      // alert("Inside catch")
      console.log("isnide catch---- ", err);
      this.redirection = false;
      this.errorMessage = err;
    })
  }

  isLogged() {
    if (Constants.MUS_SSO_ENABLED) {
      console.log("token--isLogged() " + this.oauthService.getAccessToken())
      return this.oauthService.getAccessToken() != null;
    }
    else {
      return false;//TODO: Manoj Check
    }
  }

  logoutUser() {
    if (Constants.MUS_SSO_ENABLED) {
      sessionStorage.setItem("id_token", "dummy"); // Workaround for angular-oauth2-oidc-codeflow bug
      this.oauthService.logOut();
      if(Constants.MOBILE_BUILD){
        this.appManagerService.localStorageService.resetLocalStorage();
        this.menu.close();
        // this.router.navigate(['']);
        this.router.navigateByUrl(Constants.PAGES.LOGIN);
      }
    }
    else {
      //??
    }

  }

  // logoutUser() {
  //   //TODO: PERFORM FINAL SYNC
  //   this.toggleMenu();
  //   this.logout();
  //   this.appManagerService.setUserExpert(false);
  //   this.appManagerService.setUserSurveyor(false);
  //   this.appManagerService.setLoggedInUserName(null);
  //   this.appManagerService.localStorageService.resetLocalStorage();
  //   this.router.navigateByUrl(Constants.PAGES.LOGIN);
  // }

  // /**TODO: Manoj To be changed later */
  // async saveLoggedInInfo() {
  //   const jwtHelper = new JwtHelperService();

  //   let firstName: string = this.decodedToken.firstName;
  //   let lastName: string = this.decodedToken.lastName;
  //   let userName: string = this.decodedToken.user_name;
  //   let email: string = this.decodedToken.email;

  //   this.currentUserName = await this.appManagerService.localStorageService.getCurrentUserName();

  //   this.userFullName = await this.appManagerService.localStorageService.getCurrentFullName();


  //   if (userName != this.currentUserName) {
  //     this.appManagerService.localStorageService.resetLocalStorage();//?TODO??
  //   }



  //   this.appManagerService.localStorageService.setAccessToken(this.appManagerService.oauthService.getAccessToken());
  //   this.appManagerService.localStorageService.setRefreshToken(this.appManagerService.oauthService.getRefreshToken());
  //   this.appManagerService.setLoggedInUserName(userName);

  //   this.appManagerService.localStorageService.setCurrentUserInfo(userName, email, firstName, lastName);

  //   //TODO: Manoj JWT Token not present, only decoded JWT available: REMOVE IT?
  //   this.appManagerService.localStorageService.setJWTToken(this.decodedToken);

  //   this.appManagerService.localStorageService.setAccessTokenExpiry(jwtHelper.getTokenExpirationDate(this.appManagerService.oauthService.getAccessToken()));
  //   this.appManagerService.localStorageService.setRefreshTokenExpiry(jwtHelper.getTokenExpirationDate(this.appManagerService.oauthService.getRefreshToken()));
  // }


}
