import { Injectable, Injector } from '@angular/core';
import { Platform } from '@ionic/angular';

export enum PlatformType {
    ANDROID, BROWSER, IOS, UNKNOW
}
@Injectable()
export class PlatformService {

    private platform: Platform = this.injector.get(Platform);

    constructor(private injector: Injector) {
    }

    /**
     * Get the type of platform.
     * 
     * @returns {PlatformType} 
     * @memberof PlatformService
     */
    public getPlatformType(): PlatformType {
        let type = PlatformType.UNKNOW;
        if (this.platform.is("ios")) {
            type = PlatformType.IOS;
        } else if (this.platform.is("android")) {
            type = PlatformType.ANDROID;
        } else if(this.platform.is("mobileweb") || document.URL.startsWith('http')) {
            type = PlatformType.BROWSER;
        }

        return type;
    }

    /**
     * Check if platform is Browser.
     * 
     * @returns {boolean} 
     * @memberof PlatformService
     */
    public platformIsBrowser(): boolean {
        return this.getPlatformType() == PlatformType.BROWSER;
    }

    /**
     * Check if platform is Browser but not in mobile.
     * 
     * @returns {boolean} 
     * @memberof PlatformService
     */
    public platformIsBrowserNotMobile(): boolean {
        return this.getPlatformType() == PlatformType.BROWSER && !this.platform.is("mobileweb");
    }

}