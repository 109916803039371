import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { StatusEngine } from './status-engine.model';
import { StatusThruster } from './status-thruster.model';
import { StatusCrewPersonnel } from './status-crew-personnel.model';

/**Parent document which stores Status Report information. It includes the General Details section and references for other vessel information */
export class StatusReport extends AbstractDocument {

    public vesselId: string;
    public annualTrialId: string;

    public engineIds: Array<string> = [];
    public thrusterIds: Array<string> = [];

    public switchBoardDPSystemId: string;//StatusSwitchBoardDPSystem UUID
    public dpDocumentsId: string;//StatusDPDocuments UUID

    public crewPersonnelIds: Array<string> = [];

    public created: boolean;
    public completed: boolean;

    type = Constants.TYPES.STATUS_REPORT;

}