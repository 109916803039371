import {AuthConfig} from 'angular-oauth2-oidc';
/**
 * Not used anymore
 */
// export const authConfig: AuthConfig = {

//   clientId: 'dptrials',

//   loginUrl: 'https://sso-veristar-ppr.bureauveritas.com/oauth/authorize',
//   logoutUrl : 'https://sso-veristar-ppr.bureauveritas.com/exit?client_id={{client_id}}',

//  // tokenEndpoint: 'https://L10INMUM01D2057:883'+ '/oauth/token', //server machine URL
//  //'https://d2mul2iejx3aei.cloudfront.net/api/oauth/token',
//  //'https://dnec5dxx5kxuc.cloudfront.net/api/oauth/token',
//  tokenEndpoint: 'https://d2mul2iejx3aei.cloudfront.net/api/oauth/token',
//  //'https://dm84kq0voxyff.cloudfront.net/api/oauth/token',//'https://d2mul2iejx3aei.cloudfront.net/api/oauth/token',
 
//  redirectUri: window.location.origin + '/index.html',//'/#/',

//   scope: 'read',
//   oidc: false

//   // https://10.137.3.77:8080
//   // L10INMUM01D2057
// };
export const authConfig: AuthConfig = {

  clientId: '',
  loginUrl: '',
  logoutUrl: '',

  tokenEndpoint: '',
  redirectUri: window.location.origin + '/index.html',
  responseType: 'code',
  scope: 'read',
  oidc: false

};