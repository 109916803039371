import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

// MISC
import { LoggerFactory, Logger } from '../../../commons/log/index';

// PROVIDERS
import { PouchdbDatabase } from '../../../providers/core/pouchdb/pouchdb-database';

@Injectable()
export class PouchDBChangesListener {

    private changesTask: any;
    private types: Set<string> = new Set();
    private observers: Subject<any> = new Subject();

    private database: PouchdbDatabase = this.injector.get(PouchdbDatabase);
    private logger: Logger = this.injector.get(LoggerFactory).buildLogger("PouchDBChangesListener");

    constructor(private injector: Injector) {
        // nothing
    }

    /**
     * Initialize the changes listener
     */
    public async startChangesListening(): Promise<void> {
        if (!this.changesTask) {
            this.logger.info('Starting changes listener');

            this.changesTask = this.database.db
                .changes({
                    since: 'now',
                    live: true,
                    include_docs: true
                })
                .on('change', async (change) => this.onChange(change));
        } else {
            this.logger.warn('Already started');
        }
    }

    private onChange(change: any): void {
        this.logger.debug('change detected', change);
        // Do not move it in filter conf
        if (this.types.has(change.doc.type)) {
            this.observers.next(change.doc);
        }
    }

    /**
     * Stop the changes listener
     */
    public stopChangesListening() {
        if (this.changesTask) {
            this.logger.info('Stopping changes listener');
            this.changesTask.cancel();
            this.changesTask = null;
        }
    }

    public changes(documentType: string, fn: (value: any) => void) {
        this.types.add(documentType);
        //this.observers.filter((doc) => doc.type === documentType).subscribe(fn);

        this.logger.debug(`added observer for type ${documentType}`);
    }

}