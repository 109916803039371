import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { VesselDPConfiguration } from 'src/app/models';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";



@Injectable()
export class VesselDPConfigDao extends AbstractDao<VesselDPConfiguration> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.VESSEL_DP_CONFIG, { create: true, delete: true, update: true }, "VesselDPConfigDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: VesselDPConfiguration): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}