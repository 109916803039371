import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { DocSubTypeEnum } from './enums';

export class Doc extends AbstractDocument {

    public displayFileName: string;
    public fileName: string;
    public localpath: string;
    public remoteFileKey: string;
    public remoteUrl: string;
    public subtype: DocSubTypeEnum;
    public parentDocId: string;
    public testNumber: string;
    public stepNumber: string;
    public tempFile: any;
    public docInfoPages: Array<number> = [];
    type = Constants.TYPES.DOC;

} 