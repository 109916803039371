export class Constants {

    static readonly MOBILE_BUILD: boolean = false;
    static readonly LOCAL_BUILD: boolean = false;//True for local build..else false
    static readonly MUS_SSO_ENABLED: boolean = true;
    static readonly CB_SYNC_ENABLED: boolean = true;
    static readonly DEBUG_ENABLED: boolean = false;//True for local build..else false
    static readonly REV_TEST_ENABLED: boolean = false;
    static readonly DUMMY_ENV_CHANGES: boolean = false;
    static readonly ENV_PROD: boolean = true;
    static readonly STRING_SEPARATOR: string = "##@##";
    static readonly SYMBOL_PLACEHOLDER: string = "~#~";
    static readonly SYMBOL_PLACEHOLDER_START: string = "~#~";
    static readonly SYMBOL_PLACEHOLDER_END: string = "~@#";
    static readonly STRING_SEPARATOR_2: string = "#@#";
    static readonly STRING_BREAK: string = '\n';
    static readonly REV_OFFSET_UNICODE_VAL: number = 64;//A=65, B=66
    static readonly REV_0_VAL: number = 99999;
    static readonly REV_A_VAL: number = 1;
    static readonly REV_B_VAL: number = 2;
    static readonly REV_MAX_VAL: number = 26;
    static readonly STATUS_ONBOARD_SHORT_MSG: string = "OBT";
    static readonly IN_PROGRESS_SHORT_MSG: string = "...";
    static readonly REQUEST_TIMEOUT_COMMON: number = 60000;
    static readonly REQUEST_TIMEOUT_UPLOAD: number = 120000;
    static readonly DP_SOLUTION_VERSION: string = "v1.1.8";

    //mobile @starts
    public static IMAGE_MAIN_DIRECTORY = 'DpSolution';
    public static TYPE_IMAGE = "image";
    public static TYPE_DOC = "doc";
    public static TYPE_IMAGE_DOC = "image_doc";
    public static CREATE = 0;
    public static EDIT = 1;
    public static thumbImageSize: any = 400;
    //public static ERROR_OPENING_FILE = "Error occured while opening file. Please try again."
    public static ERROR_OPENING_FILE = "Please download the file to view."
    //public static ERROR_DOWNLOADING_FILE = "Error occured while downloading file. Please try again."
    public static ERROR_DOWNLOADING_FILE = "Please check your internet connection and try again."
    public static NO_INTERNET = "No Internet, please check your internet connection"
    //public static IMAGE_DEFAULT_FOLDER = "app_images";
    //public static THUMBNAIL_DEFAULT_FOLDER = "app_thumbnails";

    //mobile @ends

    static readonly FINDING_OPEN: string = "Open";
    static readonly FINDING_CLOSED: string = "Closed";

    static readonly DEFAULT_ROOT_DIRECTORY: string = "default";
    static readonly IMAGES_DIRECTORY: string = "images";
    static readonly IMAGES_ORG_DIRECTORY: string = "imagesOrg";
    static readonly REPORTS_DIRECTORY: string = "reports";
    static readonly DOC_DIRECTORY: string = "documents";

    static readonly ENDPOINT_DOWNLOAD: string = "/downloadstream";
    static readonly ENDPOINT_UPLOAD: string = "/multipart/upload";
    static readonly URL_PARAM_S3_KEY: string = "s3key";
    static readonly URL_PARAM_FILE_NAME: string = "filename";

    static readonly MIME_TYPE_PDF: string = "application/pdf";
    static readonly MIME_TYPE_IMAGE_JPEG: string = "image/jpeg";
    static readonly MIME_TYPE_IMAGE_JPEG_BASE64START: string = "data:image/jpeg;base64,"
    static readonly MIME_TYPE_IMAGE_PNG: string = "image/png";
    static readonly MIME_TYPE_IMAGE_PNG_BASE64START: string = "data:image/png;base64,"

    static readonly MSG_LOADING: string = "Loading...";
    static readonly MSG_SENDING_REQUEST: string = "Sending request...";
    static readonly MSG_UPLOADING_FILES: string = "Uploading files...";
    static readonly MSG_DOWNLOADING_FILES: string = "Downloading file...";
    static readonly MSG_SYNCING_FILES: string = "Please wait, syncing files...";

    static readonly REPORT_NAME_PROV_FINDINGS: string = "BVS Provisional Findings Letter";
    static readonly REPORT_NAME_END_OF_TRIALS: string = "BVS End of DP Trials Compliance Letter";
    static readonly REPORT_NAME_REVISION: string = "BVS REVISION";

    static readonly IMO_CIRCULAR_645_1994: string = "IMO MSC/Circ. 645 - Guidelines for Vessels with Dynamic Positioning Systems, 1994.";
    static readonly IMO_CIRCULAR_1580_2017: string = "IMO MSC.1/Circ. 1580 - Guidelines for Vessels and Units with Dynamic Positioning (DP) Systems, 2017";


    static readonly ACCEPTED_IMAGE_FORMATS: string = ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG";//".pdf,.docx,.xlsx,image/*"
    static readonly ACCEPTED_DOC_FORMATS: string = ".pdf, .PDF";//.png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .pdf, .PDF
    static readonly ACCEPTED_IMAGE_DOC_FORMATS: string = Constants.ACCEPTED_IMAGE_FORMATS + ", " + Constants.ACCEPTED_DOC_FORMATS;//TODO: FIND A WAY TO USE IN HTML

    public static CONFIG_BASE_URL = "";
    public static CONFIG_DB_REMOTE = "";
    public static CONFIG_DB_SYNC_USER = "";
    public static CONFIG_DB_SYNC_PWD = "";
    public static CONFIG_OAUTH_CLIENT_ID = "";
    public static CONFIG_OAUTH_BASE_URL = "";
    public static CONFIG_DO_USERS = "ndevkate,msutar,msayyad,klathiya,sudchaubey,abastos";

    public static lovDPController = ["Main DP Control System", "Back up DP Control System"];

    static readonly CONFIG = {
        API: {
            PATH: 'api.path',
            FILE_HANDLER: 'api.fileHandler'
        },
        CONTACT: {
            MAIL: "contact.mail"
        },
        DATABASE: {
            WORKER: {
                LOCAL: 'database.worker.local',
                REMOTE: 'database.worker.remote',
                SYNC_USER: 'database.worker.syncuser',
                SYNC_PWD: 'database.worker.syncpwd',
                LOCAL_OPTION_SQLITE: {
                    auto_compaction: true,
                    adapter: 'cordova-sqlite',
                    location: 'default'
                },
                LOCAL_OPTION: {
                    auto_compaction: true,
                    size: 100
                },
                REMOTE_OPTION: {
                    //filter: '_view',
                    //view: 'worker/all'
                }
            },
            REFERENTIAL: {
                LOCAL: 'database.referential.local',
                REMOTE: 'database.referential.remote'
            }
        },
        ENV: "env",
        ERROR: {
            DETAIL: "error.detail"
        },
        LOG: {
            ROOT: "log",
            LEVEL: "level",
            FILTER: "filter"
        },
        OAUTH: {
            CLIENT_ID: "oauth.clientId",
            LOGIN_URL: "oauth.loginUrl",
            LOGOUT_URL: "oauth.logoutUrl",
            TOKEN_ENDPOINT: "oauth.tokenEndpoint"
        }
    };

    static readonly CLIENT_UPDATE_ERROR_TYPE: string = 'client-update';

    static readonly EVENTS = {
        DB: {
            SYNC: {
                INIT:
                    {
                        ABORTED: {
                            POORNETWORK: "db:sync:init:aborted:poornetwork"
                        },
                        FAILED: "db:sync:init:failed",
                        SKIPPED: "db:sync:init:skipped",
                        SUCCESS: "db:sync:init:success"
                    },
                LIVE:
                    {
                        FAILED: "db:sync:live:failed",
                        SUCCESS: "db:sync:live:success",
                        WAITING: "db:sync:live:waiting",
                        CHANGE: "db:sync:live:change",
                        TEMPLATE: {
                            DELETED: "db:sync:live:template:deleted"
                        },
                        SHIP: {
                            UPDATED: "db:sync:live:ship:updated"
                        }
                    }
            }
        },
        HTTP: {
            ERROR: {
                SHOULD_UPDATE_APP: "http:error:shouldupdateapp"
            }
        }
    };

    static readonly FILE = {
        EXTENSION: {
            JSON: "json",
            JPEG: 'jpeg',
            PNG: 'png',
            PDF: 'pdf'
        }
    };

    static readonly LANG = {
        EN: 'en'
    };

    static readonly MIME_TYPE = {
        IMAGE_JPEG: 'image/jpeg',
        IMAGE_PNG: 'image/png',
        APPLICATION_PDF: 'application/pdf'
    };

    static readonly TYPES = {
        GENERIC: "GENERIC",//NOT BE USED
        ACCOUNT: "ACCOUNT",
        VESSEL: "VESSEL",
        VESSEL_PRINCIPAL_DIMENS: "VESSEL_PRINCIPAL_DIMENS",
        VESSEL_MAIN_PARTICULARS: "VESSEL_MAIN_PARTICULARS",
        VESSEL_MAIN_DP_EQUIP: "VESSEL_MAIN_DP_EQUIP",
        VESSEL_DP_CONFIG: "VESSEL_DP_CONFIG",
        USER: "USER",
        REF_TERMS: "REF_TERMS",
        LOGIN: "LOGIN",
        IMAGE: "IMAGE",
        OFFLINE_IMAGE: "OFFLINE_IMAGE",
        DOC: "DOC",
        OFFLINE_DOC: "OFFLINE_DOC",
        ANNUAL_TRIAL: "ANNUAL_TRIAL",
        ANNUAL_CLIENT_INFO: "ANNUAL_CLIENT_INFO",
        TESTCASE: "TESTCASE",
        STATUS_REPORT: "STATUS_REPORT",
        STATUS_CREW_PERSONNEL: "STATUS_CREW_PERSONNEL",
        STATUS_ENGINE: "STATUS_ENGINE",
        STATUS_THRUSTER: "STATUS_THRUSTER",
        STATUS_SWITCH_ENV: "STATUS_SWITCH_ENV",
        STATUS_DOCUMENT: "STATUS_DOCUMENT",
        TESTCASE_EXEC: "TESTCASE_EXEC",
        ENV_COND_PERSONNEL: "ENV_COND_PERSONNEL",
        USER_PROFILE: "USER_PROFILE",
        FINDINGS_AND_CLOSEOUT: "FINDINGS_AND_CLOSEOUT",
        TRIAL_CONCLUSION: "TRIAL_CONCLUSION",
        TRIAL_REV: "TRIAL_REV",
        VESSEL_ASSIGN: "VESSEL_ASSIGN",
        VESSEL_STATUS: "VESSEL_STATUS",
        FINDINGS_AND_CLOSEOUT_HISTORY: "FINDINGS_AND_CLOSEOUT_HISTORY",
        OTHER_DOCS: 'OTHER_DOCS'
    }
    static readonly SUB_TYPES = {
        REPORT: "REPORT",
        SUPPORT_DOC: "SUPPORT_DOC",
        GA: "GA"
    }

    static readonly KEYS = {
        USERNAME: "USERNAME",
        USER_EMAIL: "USER_EMAIL",
        USER_FULL_NAME: "USER_FULL_NAME",
        USER_FIRST_NAME: "USER_FIRST_NAME",
        USER_LAST_NAME: "USER_LAST_NAME",
        USER_ROLE: "USER_ROLE",
        USER_ROLE_TIMESTAMP: "USER_ROLE_TIMESTAMP",
        SYNC_TIME: "SYNC_TIME",
        CURRENT_PAGE: "CURRENT_PAGE",
        TERMS_ACCEPTED: "TERMS_ACCEPTED",//TODO: MANOJ: TO BE REMOVED AND USED  WITH SYNC
        COOKIE_CLOSED: "COOKIE_CLOSED",//TODO: MANOJ: TO BE REMOVED AND USED  WITH SYNC
        ACCESS_TOKEN: "ACCESS_TOKEN",
        ACCESS_TOKEN_CURRENT: "ACCESS_TOKEN_CURRENT",
        REFRESH_TOKEN: "REFRESH_TOKEN",
        JWT_TOKEN: "JWT_TOKEN",
        ACCESS_TOKEN_EXPIRY: "ACCESS_TOKEN_EXPIRY",
        REFRESH_TOKEN_EXPIRY: "REFRESH_TOKEN_EXPIRY",
        CURRENT_VESSEL_ID: "CURRENT_VESSEL_ID",
        CURRENT_TRIAL_ID: "CURRENT_TRIAL_ID",
        CONFIG: "CONFIG"
    }
    static readonly PAGES = {
        LOGIN: "/login",
        DASHBOARD: "/dashboard",
        SUPPORT_DOCUMENTS: "/support-documents",
        CLIENT_INFORMATION: "/client-information",
        PRINCIPAL_DIMENSIONS: "/principal-dimensions",
        DP_CONFIGURATION: "/dp-configuration",
        MAIN_DP_EQUIPMENT: "/main-dp-equipment",
        GENERAL_DETAILS: "/general-details",
        MACHINERY_MAINTENANCE: "/machinery-maintenance",
        SWITCH_BOARD_MAINTENANCE: "/switch-board-maintenance",
        DP_DOCUMENTS: "/dp-documents",
        DP_COMPENTENCIES: "/dp-compentencies",
        TERMS_AND_CONDITIONS: "/termsandconditions",
    }

    static readonly DATE_FORMAT = {
        TIMESTAMP: "YYYY-MM-DDTHH:mm:ssTZD",
        TIMESTAMP_2: "YYYY-MM-DD HH:mm:ss",
        TIMESTAMP_3_ANGULAR: "dd/MM/yyyy HH:mm",
        DATE_4_ANGULAR: "dd/MM/yyyy",
        DATE_4_ANGULAR_HYPHEN: "dd-MM-yyyy",
        DATE_2_ANGULAR: "dd/MM/yy",
        YEAR_4: "YYYY",
        YEAR_2: "YY",
        YEAR_4_ANGULAR: "yyyy",
        YEAR_2_ANGULAR: "yy",

    }

    static readonly ERROR_MSG = {
        REQUIRED: "This field is required",

    }

    static readonly QUALIF_ROLE = {
        FMEA_TRAINEE: "FMEA_TRAINEE",
        FMEA_SURVEYOR: "FMEA_SURVEYOR",
        FMEA_EXPERT: "FMEA_EXPERT",
        FMEA_MANAGER: "FMEA_MANAGER",
        DO_USER: "DO_USER"
    }

    static readonly SORT_BY = {
        ASC: "asc",
        DESC: "desc"
    }

    static readonly STATUS = {
        OK: 0,
        ERROR: 1
    }

    static readonly STATUS_FIELD_STRING = {
        "0": "",
        "1": "Yes",
        "2": "No",
        "3": "NA"
    }

    static readonly ANALYSIS_RESULT_STRING = {
        "0": "",
        "1": "OK",
        "2": "Caution",
        "3": "Warning"
    }

    static readonly LINE_INDENT_VAL = {
        "0": 6,
        "1": 8,
        "2": 12,
        "3": 24,
        "4": 12,
        "5": 16,
        "6": 6,
    }

    static readonly REV_STATUS_STRING = {
        "0": "Not started",
        "10": "Draft",
        "20": "Pending Review",
        "30": "Review Denied",
        "40": "Reviewed",
        "50": "Reviewed / Pending Approval",
        "60": "Reviewed / Approval denied",
        "70": "Reviewed / Approved",
        "61": "Reviewed / Approval denied"
    }

    static readonly VESSEL_STATUS_STRING = {
        "-1": "Not Found",
        "0": "Vessel details incomplete",
        "10": "Not started",
        "20": "Not started",
        "30": "Started",
        "40": "Started",
        "50": "Draft",
        "60": "Pending review",
        "70": "Review denied",
        "80": "Reviewed",
        "90": "Reviewed / Pending Approval",
        "100": "Reviewed / Approval denied",
        "110": "Reviewed / Approved",
        "120": "Not started",
        "130": "In progress",
        "140": "Closed"

    }

    static readonly VESSEL_STATUS_DASHBOARD_STRING = {
        "-1": "Not Found",
        "0": "Vessel details incomplete",
        "10": "Not started",
        "20": "Not started",
        "30": "Started",
        "40": "Started",
        "50": "Draft",
        "60": "Pending review",
        "70": "Review denied",
        "80": "Reviewed",
        "90": "Reviewed / Pending Approval",
        "100": "Reviewed / Approval denied",
        "110": "Reviewed / Approved",
        "120": "OBT Not started",
        "130": "OBT In progress",
        "140": "Closed"

    }

    static readonly PAGE_NAME = {
        CREATE_VESSEL: "create-vessel",
        ANNUAL_TRIAL: "annual-trial",
        STATUS_REPORT: "status-report",
        ENV_CONDITION: "env-condition",
        TESTCASE_EXEC: "testcase-exec",
        TRIAL_CONCLUSION: "trial-conclusion",
        FINDINGS_CLOSING: "findings-closeout",
        CREATE_TESTCASE: "create-testcase"
    }

    static readonly BACK_BUTTON_POPUP_TEXT = {
        "create-vessel": "You are about to lose the data created for the vessel. Do you confirm?",
        "annual-trial": "You are about to quit Annual Trial Creation. Unsaved data entered by you will be lost.",
        "status-report": "You are about to quit entering details on the Status Report. Unsaved data entered by you will be lost.",
        "env-condition": "You are about to quit entering details on the Environmental Conditions and Key Personnel. Unsaved data entered by you will be lost.",
        "testcase-exec": "You are about quit executing the test cases. Unsaved data entered by you will be lost.",
        "trial-conclusion": "You are about to quit entering Trials conclusions details. Unsaved data entered by you will be lost.",
        "findings-closeout": "You are about to quit editing findings and close out details. Unsaved data entered by you will be lost.",

    }

    static readonly FINDINGS_CATEGORY = {
        A_FINDING: "Not as expected - A Findings",
        B_FINDING: "Not as expected - B Findings",
        C_FINDING: "Not as expected - C Findings",
    }

    static readonly synchronisation = {
        aborted: {
            poorNetwork: {
                firstTime: {
                    message: "Please be aware that you have a poor connection. Please get connected to 3G/4G or Wifi during application initialization",
                    title: "Limited connection"
                },
                notFirstTime: {
                    buttons: {
                        later: "Come later",
                        offline: "Offline mode"
                    },
                    message: "You have a poor connection. You can continue offline but won't receive last updates. Please confirm",
                    title: "Limited connection"
                }
            }
        },
        inProgress: "Synchronization in progress",
        error: "Synchronization in error. Please check that you are connected to the Internet with a good bandwith (3G/4G/Wifi) and retry to login."
    }
    static readonly FINDINGS_STATUS = {
        "0": "Test not performed", //Sudhanshu:Dec2021 change Not executed to Test not performed
        "10": "As expected",
        "20": "Not as expected - A Finding", //Sudhanshu Chaubey: April 2022 DT-310
        "30": "Not as expected - B Finding", 
        "40": "Not as expected - C Finding",
        "50": "Not as expected",//"Not as expected, see test comments"//"Not as expected - Comments",
        "60": "Witnessed by Crew - As Expected", //Sudhanshu Chaubey: Feb 2022 Added new value
    }

    static readonly TEST_CASE_STATUS = {
        "NOT_STARTED": "Not started",
        "IN_PROGRESS": "In progress",
        "COMPLETED": "Completed",
        "NOT_EXECUTED": "Test not performed"//"Not executed"
    }

    /**From ∏ symbol font has to be used and before it ZapfDingbats */
    static readonly SYMBOLS_START_INDEX = 5;
    static readonly SYMBOLS = ["✠", "✓", "✔", "✕", "✖",
        "Α", "Β", "Γ", "∆", "Ε", "Ζ", "Η", "Θ", "Ι", "Κ", "Λ", "Μ", "Ν", "Ξ", "Ο", "Π", "Ρ", "Σ", "Τ", "Υ", "Φ", "Χ", "Ψ", "Ω",
        "α", "β", "γ", "δ", "ε", "ζ", "η", "θ", "ι", "κ", "λ", "µ", "ν", "ξ", "ο", "π", "ρ", "ς", "σ", "τ", "υ", "φ", "χ", "ψ",
        "ω", "ϑ", "ϒ", "ϕ", "ϖ", "•", "…", "′", "″", "⁄", "€", "ℑ", "℘", "ℜ", "Ω", "ℵ", "←", "↑", "→", "↓", "↔", "↵", "⇐", "⇑",
        "⇒", "⇓", "⇔", "∀", "∂", "∃", "∅", "∆", "∇", "∈", "∉", "∋", "∏", "∑", "−", "⁄", "∗", "√", "∝", "∞", "∠", "∧", "∨", "∩",
        "∪", "∫", "∴", "∼", "≅", "≈", "≠", "≡", "≤", "≥", "⊂", "⊃", "⊄", "⊆", "⊇", "⊕", "⊗", "⊥", "⋅", "⌠", "⌡", "〈", "〉", "◊"
    ];

    static readonly PRESENT_GENERIC_MODAL = {
        SUCCESS: "success",
        WARNING: "warning",
        HEADER_TITLE: {
            WARNING: "Warning",
            CONFIRMATION: "Confirmation",
            ERROR: "Error",
        }
    }
    static readonly VALID_IMAGE_ERROR_TYPE = {
        TYPE_DOC: "Invalid file type. Please select valid PDF file.",
        TYPE_IMAGE: "Invalid file type. Please select valid JPG / PNG image file.",
        TYPE_IMAGE_DOC : "Invalid file type. Please select valid JPG / PNG / PDF file."
    }

    // Sudhanshu Chaubey: Feb 2022 Add constants object for testCase help text: Start
    static readonly TEST_CASE_LIB_TEXT = {
        EXCLUDED: "Excluded",
        CLICK_TO_EXCLUDE: "Click to Exclude"
    }
    // Sudhanshu Chaubey: Feb 2022 Add constants object for testCase help text: End

    // static readonly SYMBOLS = {
    //     "0": "✠",
    //     "1": "✓",
    //     "2": "✔",
    //     "3": "✕",
    //     "4": "✖"
    // }
}