import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: 'app-success-msg-modal',
  templateUrl: './success-msg-modal.page.html',
  styleUrls: ['./success-msg-modal.page.scss'],
})
export class SuccessMsgModalPage implements OnInit {

  modalTitle: string;
  modelId: number;
  currentVesselId:any;
  // modalContent: string;
  modalContent: any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private router: Router
  ) { }

  ngOnInit() {
    console.log("---id---", this.navParams.data.id);
    this.currentVesselId= this.navParams.data.id;
     
    this.modelId = this.navParams.data.paramID;
    // this.modalTitle = this.navParams.data.paramTitle;
    this.modalTitle = "Confirmation ";
    // this.modalContent = "You are about to complete the vessel creation. Do you confirm"
  }

  async onCancel() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  confirmModal() {
    this.router.navigate(['/vessel-general-details', this.currentVesselId]); 
    // this.router.navigateByUrl("/dashboard");
    this.modalController.dismiss();
  }
}
