import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { RevStatus } from './enums';
import { UserBasic } from './user-basic.model';
import { Review } from './review.model';

/**Trial Revision document which includes rev genreated, approved denied etc. information 
 * List of actions done online
 * Sent for Review, Accept Review, Deny Review, Sent for Approval, Accept Approval, Deny Approval, Onboard trials Assign, Close Annual Trials
 * 
 * Offline actions
 * Generate Rev (any)
*/
export class TrialRev extends AbstractDocument {

    public annualTrialId: string;
    public docId: string;
    public revdDocId: string;
    public apprDocId: string;
    public docDate: number;
    public revdDate: number;
    public apprDate: number;
    /**A=1, B=2 ... 0 =99999 from Constants.REV_0_VAL */
    public revNumber: number;
    public revStatus: RevStatus;

    /**Count which shows number of times document was updated. Can also be used to get the latest version of Rev X document */
    public docUpdated: number = 0;
 /**Is Date PDF was generated by first expert or PDF updated by reviewer/first expert */
    public createdDate: number;//Date;
    public createdByUser: UserBasic;
    public reviewers: Array<Review> = [];
    public approvers: Array<Review> = [];

    // public reviewDenyReason: string;
    // public approvalDenyReason: string;

    // public reviewDenyReasonRead: boolean;
    // public approvalDenyReasonRead: boolean;

    // public reviewSentDate: Date;
    // public reviewResponseDate: Date;
    
    // public approvalSentDate: Date;
    // public approvalResponseDate: Date;

    
    // public reviewByUser: UserBasic;
    // public approvedByUser: UserBasic;

    type = Constants.TYPES.TRIAL_REV;

}