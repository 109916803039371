import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { AbstractDocument } from "../../models";

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";


@Injectable()
export class OtherDocsDao extends AbstractDao<AbstractDocument> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.OTHER_DOCS, { update: true }, "OtherDocsDao");
    }

}