import { AbstractDocument } from './abstract-document.model';
import { StatusField, AnalysisResult } from './enums';
import { Constants } from '../app.constants';

export class EnvSensors {
    public uuid: string;//Added Jan 2022
    public name: string;
    public remarks: string;
    public sensorValue: StatusField;
    public userAdded: boolean;
}

export class StatusSwitchBoardDPSystem extends AbstractDocument {

    public vesselId: string;
    public annualTrialId: string;
    public statusReportId: string;
    
    public sbCompany: string;
    public sbMaintDate: string;
    public sbReportNumber: string;
    public sbModsLastTrial: string;
    public sbRemarks: string;


    public envSensors: Array<EnvSensors> = [];
    public envGenRemarks: string;
    
    public hdAnyMods: StatusField;
    public hdModsTested: StatusField;
    public hdTrialProcUpdated: StatusField;
    public hdComments: string;

    
    public mandatoryComplete: boolean;
    type= Constants.TYPES.STATUS_SWITCH_ENV;
}
