import { Injectable } from '@angular/core';
import { Router,CanActivate,ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  authInfo : any;
  constructor(private router:Router) { 
    console.log("AuthGuardService constructor");
    this.authInfo = {
      authenticated : false,
    }
  }

  canActivate(route:ActivatedRouteSnapshot):boolean { 
    console.log("AuthGuardService constructor canActivate called");
    if(!this.authInfo.authenticated){
      console.log("AuthGuardService constructor canActivate called false");
      // this.router.navigate(["/login"]);
      return false;
    }
    console.log("AuthGuardService constructor canActivate called true");
    return true;
  }


}
