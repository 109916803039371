export abstract class SelectUtils {
    
    /**
     * Compare two objects by code.
     * 
     * @static
     * @param {*} o1 
     * @param {*} o2 
     * @returns {boolean} 
     * @memberof SelectUtils
     */
    public static compareByCode(o1: any, o2: any): boolean {
        return o1 && o2 ? o1['code'] === o2['code'] : o1 === o2;
    }
}