import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, AlertController, ModalController } from '@ionic/angular';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Market } from '@ionic-native/market/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateModule, TranslateLoader, TranslateStore } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';

import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Camera, CameraOptions, PictureSourceType } from '@ionic-native/camera/ngx';
import { ImageResizer, ImageResizerOptions } from '@ionic-native/image-resizer/ngx';

import { Chooser } from '@ionic-native/chooser/ngx';  //not using
import { FilePath } from '@ionic-native/file-path/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
//import { FileTransfer, FileTransferObject, FileUploadOptions } from '@ionic-native/file-transfer/ngx';

// PIPES
import { CapitalizePipe, JsonDatePipe, LpadPipe, SafeHtmlPipe, SafeUrlPipe, TranslatePipe } from './pipes';
import { PipesModule } from "./pipes/pipes.module";
// PROVIDERS - CORE
import {
  Config,
  ConfigLoaderFactory,
  PouchDBChangesListener,
  PouchdbDatabase,
  SynchroPouchDBLiveProvider,
  SynchroPouchDBProvider,
  SynchroPouchDBProviderParamHelper
} from './providers/core';

// PROVIDERS - DAO
import * as DAO from './providers/dao';

// PROVIDERS - SERVICE
import * as SERVICE from './providers/service';
import { CustomFunctionsService } from "./providers/service/custom-functions.service";
import { VesselTableServiceService } from "./providers/service/vessel-table-service.service";
import { CreateVesselService } from "./providers/service/create-vessel.service";
import { CreateAnnualSubService } from './providers/service/create-annual-sub.service';
import { CreateStatusReportService } from './providers/service/create-status-dpdocuments.service';
import { CreateEnvConditionKeyService } from './providers/service/create-env-condition-key.service';
import { TestCaseExecutionService } from './providers/service/test-case-execution/test-case-execution.service';
import { OauthAppService } from './auth/oauth.service';

// COMMONS
import { LoggerFactory } from './commons/log/logger-factory';

//Modal
import { ColorModalPageModule } from './pages/modal/color-modal/color-modal.module';
import { GenericModalPageModule } from './pages/modal/generic-modal/generic-modal.module';

import { AssignedPeopleModalPageModule } from './pages/modal/assigned-people-modal/assigned-people-modal.module';

import { WarningMsgModalPageModule } from './pages/modal/warning-msg-modal/warning-msg-modal.module';
import { SuccessMsgModalPageModule } from './pages/modal/success-msg-modal/success-msg-modal.module';
import { ConfirmationMsgModalPageModule } from './pages/modal/confirmation-msg-modal/confirmation-msg-modal.module';

import { from } from 'rxjs';
import { DatePipe } from '@angular/common';
import { authConfig } from './auth/auth.config';
import { Constants } from './app.constants';
import { TokenInterceptor } from './auth/token.interceptor';
import { PdfViewerModule } from 'ng2-pdf-viewer';

export function HttpLoaderFactory(http: HttpClient) {
  return new SERVICE.CustomTranslateLoader(http);
}

// export function fetchConfig(http: HttpClient) {
//   return () => {
//     return http.post('/api/getConfig', {
//       param1: "GjkgIUTIUI*#kgHF",
//       param2: "",
//       param3: ""
//     })
//       .toPromise()
//       .then((res) => {

//         // authConfig.clientId = resp['oAuth2ClientId'];
//         // authConfig.loginUrl = resp['oAuth2AuthorizeEndpoint'];
//         // authConfig.tokenEndpoint = window.location.origin + resp['oAuth2TokenEndpoint'];
//         // authConfig.logoutUrl = resp['oAuth2LogoutEndpoint'];
//         // AppConfig.appVersion = resp['appVersion'];

//         this.logger.debug("fetchConfig: success:", res);
//         if (res) {
//           let config = res['response'].data;
//           this.logger.debug("fetchConfig: config from net:", config);

//           Constants.CONFIG_BASE_URL = config["param1"];
//           Constants.CONFIG_DB_REMOTE = config["param2"];
//           Constants.CONFIG_DB_SYNC_USER = this.CryptoJS.AES.decrypt(decodeURI(config["param3"]), this.skey).toString(this.CryptoJS.enc.Utf8);
//           Constants.CONFIG_DB_SYNC_PWD = this.CryptoJS.AES.decrypt(decodeURI(config["param4"]), this.skey).toString(this.CryptoJS.enc.Utf8);
//           Constants.CONFIG_OAUTH_BASE_URL = config["param5"];
//           Constants.CONFIG_OAUTH_CLIENT_ID = this.CryptoJS.AES.decrypt(decodeURI(config["param6"]), this.skey).toString(this.CryptoJS.enc.Utf8);

//           authConfig.clientId = Constants.CONFIG_OAUTH_CLIENT_ID;
//           authConfig.loginUrl = Constants.CONFIG_OAUTH_BASE_URL + "oauth/authorize",
//             authConfig.tokenEndpoint = Constants.CONFIG_BASE_URL + "/api/oauth/token";
//           authConfig.logoutUrl = Constants.CONFIG_OAUTH_BASE_URL + "exit?client_id=" + Constants.CONFIG_OAUTH_CLIENT_ID;

//           this.appManagerService.localStorageService.setConfig(config);
//           this.postConfig();
//         }

//       });
//   };
// }

@NgModule({

  declarations: [
    AppComponent,
    //Pipes
    CapitalizePipe,
    JsonDatePipe,
    LpadPipe,
    SafeHtmlPipe,
    SafeUrlPipe,
    TranslatePipe,
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    // FormsModule,
    // ReactiveFormsModule,
    HttpClientModule,
    PipesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IonicModule.forRoot(),
    OAuthModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '__dpsolution',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    ColorModalPageModule,
    GenericModalPageModule,
    AssignedPeopleModalPageModule,
    WarningMsgModalPageModule,
    SuccessMsgModalPageModule,
    ConfirmationMsgModalPageModule,
    PdfViewerModule
  ],

  providers: [
    AppVersion,
    Device,
    File,
    CustomFunctionsService,
    VesselTableServiceService,
    CreateVesselService,
    CreateAnnualSubService,
    CreateStatusReportService,
    CreateEnvConditionKeyService,
    TestCaseExecutionService,
    FirebaseAnalytics,
    Keyboard,
    Market,
    Network,
    ScreenOrientation,
    StatusBar,
    SplashScreen,
    DatePipe,
    Ng2ImgMaxModule,
    FileTransfer,
    FileTransferObject,
    Camera,
    ImageResizer,
    InAppBrowser,
    Chooser,
    FilePath,
    WebView,
    FileOpener,

    // PROVIDERS - CORE
    Config,
    PouchDBChangesListener,
    PouchdbDatabase,
    SynchroPouchDBLiveProvider,
    SynchroPouchDBProvider,
    SynchroPouchDBProviderParamHelper,
    // DAO
    DAO.TestCaseDao,
    DAO.TestCaseExecDao,
    DAO.VesselDao,
    DAO.VesselDPConfigDao,
    DAO.VesselMainParticularsDao,
    DAO.VesselPrincipalDimensDao,
    DAO.VesselMainDPEquipmentsDao,
    DAO.AnnualTrialsDao,
    DAO.AnnualTrialsClientInfoDao,
    DAO.FindingsAndCloseoutDetailsDao,
    DAO.TrialConclusionDao,
    DAO.ImageDao,
    DAO.DocDao,
    DAO.OfflineImageDao,
    DAO.OfflineDocDao,
    DAO.EnvCondPersonnelDao,

    DAO.StatusReportDao,
    DAO.StatusCrewPersonnelDao,
    DAO.StatusEngineDao,
    DAO.StatusThrusterDao,
    DAO.StatusDPDocumentsDao,
    DAO.StatusSwitchboardDao,
    DAO.UserDao,
    DAO.UserProfileDao,
    DAO.AnnualTrialRevDao,
    DAO.VesselStatusDao,
    DAO.GenericDao,
    DAO.FindingsAndCloseoutDetailsHistoryDao,
    DAO.OtherDocsDao,
    // COMMONS
    LoggerFactory,
    // PROVIDERS - SERVICE
    SERVICE.AppUpdateService,
    SERVICE.AuthenticationStore,
    SERVICE.ConnectivityService,
    SERVICE.CustomTranslateLoader,
    SERVICE.ErrorService,
    SERVICE.FirebaseAnalyticsService,
    SERVICE.FindingsAndCloseoutService,
    SERVICE.NavbarService,
    SERVICE.TrialConclusionService,
    SERVICE.MessageService,
    SERVICE.PlatformService,
    SERVICE.VesselService,
    SERVICE.TestCaseService,
    SERVICE.AnnualTrialsService,
    SERVICE.TemplateTableHandlerService,
    SERVICE.ImageDocService,
    SERVICE.AppManagerService,
    SERVICE.LocalStorageService,
    SERVICE.StatusReportService,
    SERVICE.EnvCondPersonnelService,
    SERVICE.PDFGenerateService,
    SERVICE.PDFGenerateUtilsService,
    SERVICE.QualifService,
    SERVICE.UserService,
    SERVICE.FileHandlerService,
    SERVICE.MainDataService,
    SERVICE.NativeApiService,
    OauthAppService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // initialize application
    {
      provide: APP_INITIALIZER,
      deps: [Config, Injector, SERVICE.PlatformService],
      multi: true,
      useFactory: ConfigLoaderFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
