import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { UserBasic } from './user-basic.model';

/**Parent document which stores Vessel information. It includes the General Details section and references for other vessel information */
export class Vessel extends AbstractDocument {

    //General Details section
    public vesselName: string;
    public nonBVvessel: boolean;
    public regNo: string;
    public imoNo: string;
    public vesselType: string;
    public imageId: string;
    public builderName: string;
    public dateOfBuild: string; 
    public deliveryYear: string;
    public flagState: string;
    public classSociety: string;
    public classNotation: string;
    public mandatoryComplete: boolean;
    
    public vesselDetailsComplete: boolean;
    public created: boolean;
    public mainParticularsId: string;
    public mainDPEquipmentId: string;
    public principalDimensId: string;
    public dpConfigurationId: string;
    public activeAnnualTrialsId: string;
    public annualTrials = [];

    public actualDPResponsiblePerson: UserBasic;

    public lastSync: Date;
    public vesselStatusId: string;
    type = Constants.TYPES.VESSEL;

}