

export class ReportIndex {
    public srNo: string;
    public name: string;
    public pageNo: number;
    public isTitle: boolean;
    public isAppendix: number;//1 2 child
    

}