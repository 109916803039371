import { Injectable, Injector } from "@angular/core";

import { PlatformService } from "../../providers/service/index";

import { Logger } from "./logger";
import { LogHandler, LogWriter, LogReader } from "./handlers/handler";
import { LogLocalStorageHandler } from "./handlers/localstorage";
import { LogFileHandler } from "./handlers/file";
import { LogConsoleHandler } from "./handlers/console";

@Injectable()
export class LoggerFactory {

    private platformService: PlatformService = this.injector.get(PlatformService);

    // "promise" as some writers may take a while to initialize
    private readonly handlers: LogHandler[] = [];
    private readonly writers: Promise<LogWriter[]>;

    constructor(private injector: Injector) {
        // create writers, some depends of platform
        this.writers = new Promise(async (resolve, reject) => {
            // browser
            if (this.platformService.platformIsBrowser()) {
                await this.addHandler(new LogLocalStorageHandler(injector));
            }
            // devices
            else {
                const canLogToFile = await this.addHandler(new LogFileHandler(injector));
                // failover
                if (!canLogToFile) {
                    await this.addHandler(new LogLocalStorageHandler(injector));
                }
            }

            // add console for direct debugging (always second as console handler doesn't have a reader)
            await this.addHandler(new LogConsoleHandler(injector));

            const writers = this.handlers.map((handler) => handler.getWriter());
            resolve(writers);
        });
    }

    /**
     * Build a new Logger.
     * 
     * @param {string} loggerName 
     * @returns {Logger} 
     * @memberof LoggerFactory
     */
    public buildLogger(loggerName: string): Logger {
        return new Logger(loggerName, this.writers);
    }

    public getLogReader(): LogReader {
        return this.handlers[0].getReader();
    }

    private async addHandler(handler: LogHandler): Promise<boolean> {
        const can = await handler.canUse();
        if (can) {
            this.handlers.push(handler);
        }

        return can;
    }

}