import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { FindingsAndCloseoutDetails, FindingsStepInfo, FindingsAndCloseoutDetailsHistory } from '../../../models';

// PROVIDERS
import { AbstractService } from '../abstract-service';

// DAO
import { FindingsAndCloseoutDetailsDao, DocDao, FindingsAndCloseoutDetailsHistoryDao } from '../../dao';
import { Constants } from 'src/app/app.constants';
import { StepExecStatus } from 'src/app/models/enums';
import { ReportConst } from '../pdf-generate/pdf-constants';
import { sprintf } from 'sprintf-js';


@Injectable()
export class FindingsAndCloseoutService extends AbstractService {

    constructor(protected injector: Injector, private findingsAndCloseoutDao: FindingsAndCloseoutDetailsDao, public docDao: DocDao,
        private findingsAndCloseoutHistoryDao: FindingsAndCloseoutDetailsHistoryDao) {
        super(injector, "FindingsAndCloseoutService");
    }

    /**
     *
     * @param {FindingsAndCloseoutDetails} doc
     * @returns {Promise<FindingsAndCloseoutDetails>}
     */
    public async createFindingsAndCloseoutDetails(doc: FindingsAndCloseoutDetails): Promise<FindingsAndCloseoutDetails> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        return this.findingsAndCloseoutDao.create(doc);
    }

    /**
     *
     * @param {FindingsAndCloseoutDetails} doc
     * @returns {Promise<FindingsAndCloseoutDetails>}
     */
    public async updateFindingsAndCloseoutDetails(doc: FindingsAndCloseoutDetails): Promise<FindingsAndCloseoutDetails> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.findingsAndCloseoutDao.update(doc);
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<FindingsAndCloseoutDetails>}
     */
    public async getFindingsAndCloseoutDetails(docId: string): Promise<FindingsAndCloseoutDetails> {
        return await this.findingsAndCloseoutDao.get(docId);
    }

    /**
     * @param {string} vesselId
     * @param {string} annualTrialId
     * @returns {Promise<Array<TestCase>>}
     */
    public async findAll(vesselId, annualTrialId): Promise<any> {
        let findingsAndCloseout = await this.findingsAndCloseoutDao.findAll();
        return _.filter(findingsAndCloseout, { type: Constants.TYPES.FINDINGS_AND_CLOSEOUT, vesselId: vesselId, annualTrialId: annualTrialId });
    }

    /**
     * @param {string} findingsAndCloseoutId
     * @returns {Promise<Array<Doc>>}
     */
    public async getFiles(findingsAndCloseoutId: string): Promise<any> {
        let docs = await this.docDao.findAll();
        return _.filter(docs, { parentDocId: findingsAndCloseoutId });
        //return _.map(docs, _.partialRight(_.pick, ['_id', 'fileName']));
    }

    public filterOpenFindings(steps: FindingsStepInfo[]): FindingsStepInfo[] {
        if (steps && steps.length > 0) {
            let result = _.filter(steps, { testStatus: "Open" });
            return result;
        }
        return [];
    }

    public async addPreviousVesselTrialOpenFinding(vesselId: string, currentTrialId: string, allTrials: string[], currentFindings: FindingsAndCloseoutDetails): Promise<FindingsAndCloseoutDetails> {
        let previousOpenFindings = await this.getPreviousVesselTrialOpenFinding(vesselId, currentTrialId, allTrials);
        if (previousOpenFindings) {
            let dateTime = this.DateUtils.getCurrentDateMilli() + "";
            for (let index = 0; index < previousOpenFindings.aFindings.length; index++) {
                const element = previousOpenFindings.aFindings[index];
                element.fromPreviousTrial = true;
                element.previousTrialId = previousOpenFindings.annualTrialId;
                element.stepUUID = element.stepUUID + "_" + dateTime;
                currentFindings.aFindings.push(element);
            }
            for (let index = 0; index < previousOpenFindings.bFindings.length; index++) {
                const element = previousOpenFindings.bFindings[index];
                element.fromPreviousTrial = true;
                element.previousTrialId = previousOpenFindings.annualTrialId;
                element.stepUUID = element.stepUUID + "_" + dateTime;
                currentFindings.bFindings.push(element);
            }
            for (let index = 0; index < previousOpenFindings.cFindings.length; index++) {
                const element = previousOpenFindings.cFindings[index];
                element.fromPreviousTrial = true;
                element.previousTrialId = previousOpenFindings.annualTrialId;
                element.stepUUID = element.stepUUID + "_" + dateTime;
                currentFindings.cFindings.push(element);
            }
            currentFindings.containsPreviousFindings = true;
        }
        currentFindings.previousFindingsImported = true;
        return currentFindings;
    }

    public async getPreviousVesselTrialOpenFinding(vesselId: string, currentTrialId: string, allTrials: string[]): Promise<FindingsAndCloseoutDetails | null> {
        let previousFinding = await this.getPreviousVesselTrialFinding(vesselId, currentTrialId, allTrials);
        if (previousFinding) {
            let openAFindings = this.filterOpenFindings(previousFinding.aFindings);
            let openBFindings = this.filterOpenFindings(previousFinding.bFindings);
            let openCFindings = this.filterOpenFindings(previousFinding.cFindings);

            if (openAFindings && openAFindings.length > 0 || openBFindings && openBFindings.length > 0 || openCFindings && openCFindings.length > 0) {
                previousFinding.aFindings = openAFindings;
                previousFinding.bFindings = openBFindings;
                previousFinding.cFindings = openCFindings;

                return previousFinding;
            }
        }
        return null;
    }
    public async getPreviousVesselTrialFinding(vesselId: string, currentTrialId: string, allTrials: string[]): Promise<FindingsAndCloseoutDetails> {
        let previousFinding;
        let previousTrialId = "";
        if (allTrials && allTrials.length > 0) {
            let indexOfCurrentTrial = allTrials.indexOf(currentTrialId);
            if (indexOfCurrentTrial > 0) {
                previousTrialId = allTrials[indexOfCurrentTrial - 1];//ONLY TAKE THE PREVOUS TRIAL
            }
        }
        if (StringUtils.isNotBlank(previousTrialId)) {
            let findingsForTrials = await this.findAll(vesselId, previousTrialId);
            //TODO: Manoj check why taking first one?
            if (findingsForTrials && findingsForTrials.length > 0) {
                previousFinding = findingsForTrials[0];
            }
        }
        return previousFinding;
    }

    // public async getPreviousVesselTrialFindings(vesselId: string, currentTrialId: string, allTrials: string[]): Promise<Array<FindingsAndCloseoutDetails> {
    //     let allPreviousFindings: Array<FindingsAndCloseoutDetails> = [];
    //     let allPreviousTrials = [];
    //     if (allTrials && allTrials.length > 0) {
    //         allPreviousTrials = _.filter(allTrials, (trial) => {
    //             trial != currentTrialId;
    //         })
    //     }
    //     if (allPreviousTrials.length > 0) {
    //         for (let index = 0; index < allPreviousTrials.length; index++) {
    //             const trialId = allPreviousTrials[index];
    //             let findingsForTrials = await this.findAll(vesselId, trialId);
    //             //TODO: Manoj check why taking first one?
    //             if (findingsForTrials && findingsForTrials.length > 0) {
    //                 let findingCloseout = findingsForTrials[0];
    //                 allPreviousFindings.push(findingCloseout);
    //             }
    //         }
    //     }
    //     return allPreviousFindings;
    // }

    /**
     *
     * @returns {Promise<Array<StatusReport>>}
     */
    public async findFindingsCloseoutForForAnnualTrial(annualTrialIdVal: string): Promise<Array<FindingsAndCloseoutDetails>> {

        var query = {
            type: Constants.TYPES.FINDINGS_AND_CLOSEOUT,
            annualTrialId: annualTrialIdVal
            //TODO: Manoj add created param also
        }
        let sortBy = [{ mdt: Constants.SORT_BY.DESC }];
        return this.findingsAndCloseoutDao.findBasedOnQuery(query, sortBy);
    }

    public async getCurrentFindingsCloseoutForAnnualTrial(annualTrialIdVal: string): Promise<FindingsAndCloseoutDetails> {
        let statusReportArray = await this.findFindingsCloseoutForForAnnualTrial(annualTrialIdVal);
        if (statusReportArray && statusReportArray.length > 0) {
            return statusReportArray[0];
        }
        return undefined;
    }


    /////////////////////////Findings Closout History
    /**
    *
    * @param {FindingsAndCloseoutDetailsHistory} doc
    * @returns {Promise<FindingsAndCloseoutDetailsHistory>}
    */
    public async createFindingsAndCloseoutHistoryDetails(doc: FindingsAndCloseoutDetailsHistory): Promise<FindingsAndCloseoutDetailsHistory> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        return this.findingsAndCloseoutHistoryDao.create(doc);
    }


    public async createFindingsHistory(findingsDocId: string, revNumber: number, trialYear: string, finding?: FindingsAndCloseoutDetails, maxRevCPlus?: number) {
        if (StringUtils.isBlank(findingsDocId)) {
            return null;
        }
        if (!finding) {
            finding = await this.getFindingsAndCloseoutDetails(findingsDocId);
        }
        let historyFindings = await this.findAllHistoryWithRev(findingsDocId, revNumber);
        if (finding) {
            let historyFinding = null;
            if (historyFindings && historyFindings.length > 0) {
                historyFinding = historyFindings[0];
            }
            if (!historyFinding) {
                historyFinding = new FindingsAndCloseoutDetailsHistory();
            }
            historyFinding.vesselId = finding.vesselId;
            historyFinding.annualTrialId = finding.annualTrialId;
            historyFinding.findingsCloseoutId = findingsDocId;
            historyFinding.revNumber = revNumber;
            historyFinding.aFindings = finding.aFindings;
            historyFinding.bFindings = finding.bFindings;
            historyFinding.cFindings = finding.cFindings;

            if (revNumber > Constants.REV_B_VAL + 1) {
                let previousRevNo = revNumber - 1;
                if (revNumber == Constants.REV_0_VAL) {
                    previousRevNo = maxRevCPlus;
                }
                let prevHistoryFindings = await this.findAllHistoryWithRev(findingsDocId, previousRevNo);//ISSUE OVER HERE FOR REV 0 as you need to get MAX REV C
                let prevHistoryFinding = null;
                if (prevHistoryFindings && prevHistoryFindings.length > 0) {
                    prevHistoryFinding = prevHistoryFindings[0];
                }
                historyFinding.amendmentRecords = this.getAmendments(prevHistoryFinding, historyFinding, trialYear);
            }
            else if (revNumber == Constants.REV_B_VAL + 1) {
                historyFinding.amendmentRecords = this.getAmendments(null, null, trialYear);
            }

            historyFinding = await this.createFindingsAndCloseoutHistoryDetails(historyFinding);
            this.logger.debug("createFindingsHistory: findingsDocId:" + findingsDocId + " revNumber:" + revNumber + " historyFinding:", historyFinding);
            return historyFinding;
        }
        return null;
    }

    public updateFindingsWithUpdatedTestCase(findingsAndCloseoutDetails: FindingsAndCloseoutDetails,
        aFindings: Array<FindingsStepInfo>, bFindings: Array<FindingsStepInfo>, cFindings: Array<FindingsStepInfo>) {
        this.logger.info("updateFindingsWithUpdatedTestCase START: ", findingsAndCloseoutDetails);
        this.logger.info("updateFindingsWithUpdatedTestCase aFindings: ", aFindings);
        this.logger.info("updateFindingsWithUpdatedTestCase bFindings: ", bFindings);
        this.logger.info("updateFindingsWithUpdatedTestCase cFindings: ", cFindings);
        let prevAllFindings = [];
        prevAllFindings = _.concat(prevAllFindings, findingsAndCloseoutDetails.aFindings);
        prevAllFindings = _.concat(prevAllFindings, findingsAndCloseoutDetails.bFindings);
        prevAllFindings = _.concat(prevAllFindings, findingsAndCloseoutDetails.cFindings);

        let currAllFindings = [];
        currAllFindings = _.concat(currAllFindings, aFindings);
        currAllFindings = _.concat(currAllFindings, bFindings);
        currAllFindings = _.concat(currAllFindings, cFindings);

        if (prevAllFindings && currAllFindings) {
            _.forEach(currAllFindings, function (finding) {
                let oldFindingIndex = _.findIndex(prevAllFindings, function (old) { return old.stepUUID == finding.stepUUID });
                if (oldFindingIndex < 0) {
                    prevAllFindings.push(finding);
                }
                else {
                    prevAllFindings.splice(oldFindingIndex, 1, finding);
                }
            });

            findingsAndCloseoutDetails.aFindings = _.filter(prevAllFindings, { category: StepExecStatus.NOT_AS_EXPECTED_FINDING_A });
            findingsAndCloseoutDetails.bFindings = _.filter(prevAllFindings, { category: StepExecStatus.NOT_AS_EXPECTED_FINDING_B });
            findingsAndCloseoutDetails.cFindings = _.filter(prevAllFindings, { category: StepExecStatus.NOT_AS_EXPECTED_FINDING_C });
        }

        this.logger.info("updateFindingsWithUpdatedTestCase END: ", findingsAndCloseoutDetails);
        return findingsAndCloseoutDetails;
    }

    public getAmendments(previousFindings: FindingsAndCloseoutDetailsHistory, currentFindings: FindingsAndCloseoutDetailsHistory, trialYear: string) {
        this.logger.info("getAmendments START: ", trialYear);
        let result: Array<string> = [];
        let newFindings: Array<FindingsStepInfo> = [];
        let addSingleLineMsg = true;
        if (previousFindings && currentFindings) {
            let prevAllFindings = [];
            prevAllFindings = _.concat(prevAllFindings, previousFindings.aFindings);
            prevAllFindings = _.concat(prevAllFindings, previousFindings.bFindings);
            prevAllFindings = _.concat(prevAllFindings, previousFindings.cFindings);

            let currAllFindings = [];
            currAllFindings = _.concat(currAllFindings, currentFindings.aFindings);
            currAllFindings = _.concat(currAllFindings, currentFindings.bFindings);
            currAllFindings = _.concat(currAllFindings, currentFindings.cFindings);

            if (prevAllFindings && currAllFindings) {
                _.forEach(currAllFindings, function (finding) {
                    let addToNew = true;
                    let oldFinding = _.find(prevAllFindings, function (old) { return old.stepUUID == finding.stepUUID && old.testStatus == finding.testStatus; });
                    if (oldFinding) {
                        addToNew = false;
                    }
                    if (addToNew) {
                        newFindings.push(finding);
                    }
                });
            }

            newFindings = _.orderBy(newFindings, ['testStatus'], ['asc']);
            addSingleLineMsg = newFindings.length <= 0;
            newFindings.forEach(finding => {
                let status = "Findings closed";
                if (finding.testStatus == "Open") {
                    status = "Findings opened";
                }
                let category = "";
                if (finding.category == StepExecStatus.NOT_AS_EXPECTED_FINDING_A) {
                    category = "A";
                }
                else if (finding.category == StepExecStatus.NOT_AS_EXPECTED_FINDING_B) {
                    category = "B";
                }
                else if (finding.category == StepExecStatus.NOT_AS_EXPECTED_FINDING_C) {
                    category = "C";
                }

                let testNumber = "-";
                if (finding.testNumber) {
                    testNumber = "" + finding.testNumber;
                }
                let stepNumber = "-";
                if ((finding.stepNumber || finding.stepNumber == '0') && !finding.isAdditionalFinding) {//|| finding.stepNumber == 0)
                    stepNumber = 1 + finding.stepNumber + "";
                }
                let finalStatus = sprintf(ReportConst.REPORT_MSGS.AMEND_TEST_NO_STEP_NO, status, category, testNumber, stepNumber);
                if (finding.isAdditionalFinding) {
                    finalStatus = sprintf(ReportConst.REPORT_MSGS.AMEND_ADDITIONAL_FINDING, status, category, finding.testName);
                }
                // let finalStatus = status+" "+category+" - Test No. "+testNumber+" - Step No. "+stepNumber;
                // let finalStatus = status + " " + category + " - " + testNumber + " - " + stepNumber;
                result.push(finalStatus);
            });
        }
        if (addSingleLineMsg) {
            result.push("Test results from DP Annual Trials " + StringUtils.getEmptyForNull(trialYear, "-") + " included.")
        }
        this.logger.info("getAmendments END: ", result);
        return result;
    }

    /**
     *
     * @param {FindingsAndCloseoutDetailsHistory} doc
     * @returns {Promise<FindingsAndCloseoutDetailsHistory>}
     */
    public async updateFindingsAndCloseoutHistoryDetails(doc: FindingsAndCloseoutDetailsHistory): Promise<FindingsAndCloseoutDetailsHistory> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.findingsAndCloseoutHistoryDao.update(doc);
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<FindingsAndCloseoutDetailsHistory>}
     */
    public async getFindingsAndCloseoutHistoryDetails(docId: string): Promise<FindingsAndCloseoutDetailsHistory> {
        return await this.findingsAndCloseoutHistoryDao.get(docId);
    }

    /**
     * @param {string} findingsCloseoutId
     * @returns {Promise<Array<FindingsAndCloseoutDetailsHistory>>}
     */
    public async findAllHistory(findingsCloseoutId): Promise<any> {
        var query = {
            // createdDate: { $exists: true },
            type: Constants.TYPES.FINDINGS_AND_CLOSEOUT_HISTORY,
            findingsCloseoutId: findingsCloseoutId
        }
        let sortBy = [{ mdt: Constants.SORT_BY.DESC }];
        return this.findingsAndCloseoutHistoryDao.findBasedOnQuery(query, sortBy);
        // let findingsAndCloseout = await this.findingsAndCloseoutHistoryDao.findAll();
        // return _.filter(findingsAndCloseout, { findingsCloseoutId: findingsCloseoutId});
    }

    public async findAllHistoryWithRev(findingsCloseoutId: string, revNumber: number): Promise<any> {
        var query = {
            // createdDate: { $exists: true },
            type: Constants.TYPES.FINDINGS_AND_CLOSEOUT_HISTORY,
            findingsCloseoutId: findingsCloseoutId,
            revNumber: revNumber
        }
        let sortBy = [{ mdt: Constants.SORT_BY.DESC }];
        return this.findingsAndCloseoutHistoryDao.findBasedOnQuery(query, sortBy);
        // let findingsAndCloseout = await this.findingsAndCloseoutHistoryDao.findAll();
        // return _.filter(findingsAndCloseout, { findingsCloseoutId: findingsCloseoutId, revNumber: revNumber});
    }

    public async findLatestHistoryWithRev(findingsCloseoutId: string, revNumber: number): Promise<any> {
        let history = await this.findAllHistoryWithRev(findingsCloseoutId, revNumber);
        if (history && history.length > 0) {
            return history[0];
        }
        return null;
    }
}
