import { Injectable, Injector } from '@angular/core';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { AnnualTrials } from '../../../models/annual-trial';
import { AnnualTrialsClientInfo } from '../../../models/annual-trial-client-info';

// PROVIDERS
import { AbstractService } from '../abstract-service';

// DAO
import { AnnualTrialRevDao, AnnualTrialsDao, AnnualTrialsClientInfoDao, FindingsAndCloseoutDetailsDao } from '../../dao';
import { TrialRev } from 'src/app/models/annual-trial-rev';
import { Constants } from 'src/app/app.constants';
import { UserBasic, PromiseResponse, VesselStatus } from 'src/app/models';
import { RevStatus, ReviewRequestEnum, CommonActionEnum, TrialStatus, VesselStatusEnum, VesselAccessEnum, SectionStatus } from 'src/app/models/enums';
import { Review } from 'src/app/models/review.model';


@Injectable()
export class AnnualTrialsService extends AbstractService {

    constructor(protected injector: Injector,
        private annualTrialsDao: AnnualTrialsDao,
        private clientInfoDoa: AnnualTrialsClientInfoDao,
        private findingsAndCloseoutDao: FindingsAndCloseoutDetailsDao,
        private trialRevDao: AnnualTrialRevDao) {
        super(injector, "AnnualTrialsService");
    }

    /**
     *
     * @param {AnnualTrials} doc
     * @returns {Promise<AnnualTrials>}
     */
    public async createAnnualTrials(doc: AnnualTrials, parentDocId: string): Promise<AnnualTrials> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(parentDocId, doc);
        let response = this.annualTrialsDao.create(doc);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<AnnualTrials>}
     */
    public async getAnnualTrials(docId: string): Promise<AnnualTrials> {
        return await this.annualTrialsDao.get(docId);
    }

    /**
     *
     * @returns {Promise<Array<AnnualTrials>>}
     */
    public async findAll(): Promise<Array<AnnualTrials>> {
        return this.annualTrialsDao.findAll();
    }

    /**
     *
     * @param {AnnualTrialsClientInfo} doc
     * @returns {Promise<boolean>}
     */
    public async removeAnnualTrials(doc: AnnualTrials): Promise<boolean> {
        return this.annualTrialsDao.delete(doc);
    }

    /**
     *
     * @param {AnnualTrialsClientInfo} doc
     * @returns {Promise<AnnualTrials>}
     */
    public async updateAnnualTrials(doc: AnnualTrials): Promise<AnnualTrials> {
        console.log("-----UPDATE---", doc);

        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.annualTrialsDao.update(doc);
    }

    //Create section for 4 steps
    public async createAnnualTrialsClientInfo(doc: AnnualTrialsClientInfo, parentDocId: string): Promise<AnnualTrialsClientInfo> {

        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(parentDocId, doc);
        let response = this.clientInfoDoa.create(doc);
        return response;
    }
    //Update steps
    /**
    *
    * @param {AnnualTrialsClientInfo} doc
    * @returns {Promise<AnnualTrialsClientInfo>}
    */
    public async updateAnnualTrialsClientInfo(doc: AnnualTrialsClientInfo): Promise<AnnualTrialsClientInfo> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        console.log("---updateAnnualTrialsClientInfo-----", doc);

        return this.clientInfoDoa.update(doc);
    }

    //Get step document
    /**
    *
    * @param {docId} docId
    * @returns {Promise<AnnualTrialsClientInfo>}
    */
    public async getAnnualTrialsClientInfo(docId: string): Promise<AnnualTrialsClientInfo> {
        return await this.clientInfoDoa.get(docId);
    }


    //Trial Rev Section
    public async creatTrialRev(doc: TrialRev, parentDocId: string): Promise<TrialRev> {

        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        this.addPrincipalDocIdToChannel(parentDocId, doc);
        let response = this.trialRevDao.create(doc);
        return response;
    }
    //Update any trial rev generic
    /**
    *
    * @param {TrialRev} doc
    * @returns {Promise<TrialRev>}
    */
    public async updateTrialRev(doc: TrialRev): Promise<TrialRev> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        console.log("---TrialRev-----", doc);

        return this.trialRevDao.update(doc);
    }

    //Get particular trial rev
    /**
    *
    * @param {docId} docId
    * @returns {Promise<TrialRev>}
    */
    public async getTrialRev(docId: string): Promise<TrialRev> {
        return await this.trialRevDao.get(docId);
    }

    public async getAllTrialRevs(annualTrialId: string): Promise<Array<TrialRev>> {
        var query = {
            createdDate: { $exists: true },
            type: Constants.TYPES.TRIAL_REV,
            annualTrialId: annualTrialId
        }
        let sortBy = [{ createdDate: Constants.SORT_BY.ASC }];
        return this.trialRevDao.findBasedOnQuery(query, sortBy);
    }

    public async getAllTrialWithRev(annualTrialId: string, revNo: number, orderDesc?: boolean): Promise<Array<TrialRev>> {
        var query = {
            createdDate: { $exists: true },
            type: Constants.TYPES.TRIAL_REV,
            annualTrialId: annualTrialId,
            revNumber: revNo
        }
        let sortBy = [{ createdDate: Constants.SORT_BY.ASC }];
        if (orderDesc) {
            sortBy = [{ createdDate: Constants.SORT_BY.DESC }];
        }
        return this.trialRevDao.findBasedOnQuery(query, sortBy);
    }

    public async getLatestTrialWithRev(annualTrialId: string, revNo: number): Promise<TrialRev | null> {
        let trialRevs = await this.getAllTrialWithRev(annualTrialId, revNo, true);
        if (trialRevs && trialRevs.length > 0) {
            return trialRevs[0];
        }
        return null;
    }

    public async getLatestTrialRevs(annualTrialId: string): Promise<Array<TrialRev>> {
        var query = {
            createdDate: { $exists: true },
            type: Constants.TYPES.TRIAL_REV,
            annualTrialId: annualTrialId
        }
        let sortBy = [{ createdDate: Constants.SORT_BY.DESC }];
        return this.trialRevDao.findBasedOnQuery(query, sortBy);
    }

    public async getLatestTrialRev(annualTrialId: string): Promise<TrialRev | null> {
        let trialRevs = await this.getLatestTrialRevs(annualTrialId);
        if (trialRevs && trialRevs.length > 0) {
            return trialRevs[0];
        }
        return null;
    }

    public async getMaxCPlusRevNoTrialRev(annualTrialId: string): Promise<TrialRev | null> {
        let trialRevs = await this.getLatestTrialRevs(annualTrialId);
        if (trialRevs && trialRevs.length > 0) {
            for (let index = 0; index < trialRevs.length; index++) {
                const element = trialRevs[index];
                if (element.revNumber != Constants.REV_0_VAL) {
                    return element;
                }
            }
        }
        return null;
    }

    public getRevisionLabel(revNumber: number): string {
        let rev = String.fromCharCode(revNumber + Constants.REV_OFFSET_UNICODE_VAL);
        return rev;
    }

    /**
     * Local operations
     * 1. get all trial revs
     * 2. Create Rev A
     * 3. Update Rev A generation
     * 4. Create Rev B
     * 5. Update Rev B generation
     * 6. 
     */

    public async updateAnnualTrialStatusForCreatedRev(annualTrialId: string, revNo: number): Promise<AnnualTrials> {
        let annualTrialStatus = TrialStatus.NOT_STARTED;
        if (revNo == 1) {
            annualTrialStatus = TrialStatus.REV_A_IN_PROGRESS;
        }
        else if (revNo == 2) {
            annualTrialStatus = TrialStatus.REV_B_IN_PROGRESS;
        }
        else if (revNo == Constants.REV_0_VAL) {
            annualTrialStatus = TrialStatus.REV_0_IN_PROGRESS;
        }
        else {
            annualTrialStatus = TrialStatus.REV_C_IN_PROGRESS;
        }
        let doc = await this.getAnnualTrials(annualTrialId);
        doc.annualTrialStatus = annualTrialStatus;

        return this.updateAnnualTrials(doc);
    }

    public async createCommonTrialRev(annualTrialId: string, revNo: number, pdfDocId: string, creator: UserBasic): Promise<TrialRev> {
        let currentDate = this.DateUtils.getCurrentDateMilli();//new Date();

        let doc = new TrialRev();
        doc.createdByUser = creator;
        doc.revNumber = revNo;
        doc.annualTrialId = annualTrialId;
        doc.docId = pdfDocId;
        doc.createdDate = currentDate;
        doc.revStatus = RevStatus.GENERATED;
        doc.docUpdated = 0;

        let updatedTrialsDoc = await this.updateAnnualTrialStatusForCreatedRev(annualTrialId, revNo);
        this.logger.debug("createCommonTrialRev: updateAnnualTrialStatusForCreatedRev:", updatedTrialsDoc);

        return this.creatTrialRev(doc, annualTrialId);
    }

    public async updateCommonTrialRev(doc: TrialRev, pdfDocId: string): Promise<TrialRev> {
        let currentDate = this.DateUtils.getCurrentDateMilli();//new Date();
        doc.docId = pdfDocId;
        // if (!isReviewer) {
        //     doc.createdDate = currentDate;
        //     isReviewer = false;
        // }
        doc.revStatus = RevStatus.GENERATED;//isReviewer ? RevStatus.REVIEWER_UPDATED : 
        doc.docUpdated = doc.docUpdated + 1;

        return this.updateTrialRev(doc);
    }

    public async createTrialRevA(annualTrialId: string, pdfDocId: string, creator: UserBasic, generateNew: boolean): Promise<PromiseResponse> {
        return this.createTrialRev(annualTrialId, pdfDocId, creator, Constants.REV_A_VAL, generateNew);
    }

    public async createTrialRevB(annualTrialId: string, pdfDocId: string, creator: UserBasic, generateNew: boolean): Promise<PromiseResponse> {
        return this.createTrialRev(annualTrialId, pdfDocId, creator, Constants.REV_B_VAL, generateNew);
    }

    public async createTrialRev0(annualTrialId: string, pdfDocId: string, creator: UserBasic, generateNew: boolean): Promise<PromiseResponse> {
        return this.createTrialRev(annualTrialId, pdfDocId, creator, Constants.REV_0_VAL, generateNew);
    }


    public async createTrialRevCPlus(annualTrialId: string, pdfDocId: string, creator: UserBasic, currentRev: number, generateNew: boolean): Promise<PromiseResponse> {
        return this.createTrialRev(annualTrialId, pdfDocId, creator, currentRev, generateNew);
    }

    public async createTrialRev(annualTrialId: string, pdfDocId: string, creator: UserBasic, currentRev: number, generateNew: boolean): Promise<PromiseResponse> {
        let newRev = currentRev;
        let doc = null;
        if (generateNew) {
            // if (currentRev > Constants.REV_B_VAL && currentRev <= Constants.REV_MAX_VAL) {
            //     newRev = currentRev + 1;
            // }
            doc = await this.createCommonTrialRev(annualTrialId, newRev, pdfDocId, creator);
        }
        else {
            let revList = await this.getAllTrialWithRev(annualTrialId, newRev);
            let isUpdate: boolean = false;
            if (revList && revList.length > 0) {
                doc = revList[revList.length - 1];//Get the latest one for any modification;
                this.logger.debug("createTrialRev 0A: pdfDocId:",pdfDocId);
                this.logger.debug("createTrialRev 1A: doc:",doc);
                doc = await this.updateCommonTrialRev(doc, pdfDocId);
                this.logger.debug("createTrialRev 2A: finalDoc:",doc);
                isUpdate = true;
            }
            else {
                this.logger.debug("createTrialRev 0B: pdfDocId:",pdfDocId);
                this.logger.debug("createTrialRev 1B: doc:",doc);
                doc = await this.createCommonTrialRev(annualTrialId, newRev, pdfDocId, creator);
                this.logger.debug("createTrialRev 2B: finalDoc:",doc);
            }
        }
        let response = new PromiseResponse();
        if (doc) {
            response.data = doc;
        }
        else {
            response.status = Constants.STATUS.ERROR;
            response.message = "Unable to create Rev " + newRev + " (TO change message)"
        }
        return response;
    }


    public getLatestReviewer(triaRev: TrialRev) {
        if (triaRev && triaRev.reviewers) {
            if (triaRev.reviewers.length > 0) {
                return triaRev.reviewers[triaRev.reviewers.length - 1];
            }
        }
        return null;
    }

    public getLatestApprover(triaRev: TrialRev) {
        if (triaRev && triaRev.approvers) {
            if (triaRev.approvers.length > 0) {
                return triaRev.approvers[triaRev.approvers.length - 1];
            }
        }
        return null;
    }

    public getReasonReadKey(isReviewer:boolean, isExpertDPResp:boolean, isOnboard:boolean){
        let key = isReviewer?"reasonReadByReviewer":(isExpertDPResp?"reasonReadByDPPerson":(isOnboard?"reasonReadByOnboard":""));
        //let key = isReviewer?"reasonRead":(isExpertDPResp?"reasonReadByDPPerson":(isOnboard?"reasonReadByOnboard":""));
        return key;
    }

    public async updateCommentRead(trialRev: TrialRev, commentOfApprover: boolean, isReviewer:boolean, isExpertDPResp:boolean, isOnboard:boolean): Promise<boolean> {
        if (trialRev) {
            // let reviewer: Review = commentOfApprover ? this.getLatestApprover(trialRev) : this.getLatestReviewer(trialRev);
            let reviewers: Array<Review> = commentOfApprover ? trialRev.approvers : trialRev.reviewers;
            if (reviewers && reviewers.length > 0) {
                // reviewers[reviewers.length - 1].reasonRead
                let key = isReviewer?"reasonReadByReviewer":(isExpertDPResp?"reasonReadByDPPerson":(isOnboard?"reasonReadByOnboard":""));
                if (StringUtils.isNotBlank(key)) {
                    if (!reviewers[reviewers.length - 1][key]) {
                        reviewers[reviewers.length - 1][key] = true;
                        let newTrialDoc = await this.updateTrialRev(trialRev);
                        if (newTrialDoc) {//Necessaey??
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }


    public sendRevForReview(trialRevDocId: string, vesselId: string, typeApprove: ReviewRequestEnum, reviewer: UserBasic): Promise<any> {
        console.log("trialDocId : " + trialRevDocId + " vesselId: " + vesselId + " typeApprove : " + typeApprove + " reviewer : " + JSON.stringify(reviewer));
        return this.newHttpRequest().post('/sendRevForReview').withBody({
            trialRevDocId: trialRevDocId, vesselId: vesselId, typeApprove: typeApprove, reviewer: reviewer
        }).do();
    }


    public reviewRev(action: CommonActionEnum, reason: string, trialRevDocId: string, vesselId: string, typeApprove: ReviewRequestEnum, reviewer: UserBasic): Promise<any> {
        return this.newHttpRequest().post('/reviewRev').withBody({
            action: action, reason: reason, trialRevDocId: trialRevDocId, vesselId: vesselId, typeApprove: typeApprove, reviewer: reviewer
        }).do();
    }

    public assignVesselRequest(accessType: VesselAccessEnum, vesselId: string, annualTrialId: string, assignTo: UserBasic, assignedBy: UserBasic): Promise<any> {
        return this.newHttpRequest().post('/assignVessel').withBody({
            accessType: accessType, vesselId: vesselId, annualTrialId: annualTrialId, assignTo: assignTo, assignedBy: assignedBy
        }).do();
    }

    public closeAnnualTrial(vesselId: string, annualTrialId: string, user: UserBasic): Promise<any> {
        return this.newHttpRequest().post('/closeTrial').withBody({
            user: user, vesselId: vesselId, annualTrialId: annualTrialId
        }).do();
    }
}
