import { Injectable, Injector } from '@angular/core';
import { Constants } from '../../app.constants';
import { ToastController, LoadingController, Events } from '@ionic/angular';
import { AbstractService } from '../service/abstract-service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { StringUtils, AppUtils, CordovaUtils } from 'src/app/commons/utils';
import { Router } from '@angular/router';
import { JwtHelperService } from "@auth0/angular-jwt";
import { UserBasic, VesselAccess, User } from 'src/app/models';
import { PageModeEnum } from 'src/app/models/enums';
import { UserService } from '../service';
//import { Base64 } from '@ionic-native/base64';


@Injectable({
  providedIn: 'root'
})
export class AppManagerService extends AbstractService {

  CryptoJS: any;
  skey: string = "DPSolution@1828";
  public loggedInUserName: string;
  public userFullName: string;
  public userExpert: boolean;
  public userSurveyor: boolean;
  public isGdprHidden: boolean = false;
  public isCookieAccepted: boolean = false;
  public headerTitle: string;
  public loggedInUserBasic: UserBasic;
  private selectedVesselAccess: VesselAccess;
  paginationHeaderNo: number = 0;
  paginationHeaderMaxClickedNo: number = 4; //set explicitly in breadcrumbs ts page
  expandCollapseButton = {
    'create-vessel': [true, false, true, true],
    'annual-trial': [true, false, false],
    'findings-closeout': [true],
    'status-report': [true, true, true, true],
    'env-condition': [true, true],
    'create-testcase': [true],
  }

  isFormValueChanged = false;
  isEdit: boolean = false;
  isReadOnly: boolean = false;
  testCasePageMode: PageModeEnum = PageModeEnum.READ_ONLY;
  isTeamReassigned: boolean = false;
  history: boolean = false;
  showSyncLoader: boolean = false;

  constructor(protected injector: Injector, public toastCtrl: ToastController, private userService: UserService,
    private datePipe: DatePipe, private router: Router, private events: Events) {
    super(injector, "AppManagerService");
    this.logger.info("AppManagerService Started");
    this.CryptoJS = require("crypto-js");
    // this.checkConfig();
  }

  setLoggedInUserName(userName: string) {
    this.loggedInUserName = userName;
  }

  getLoggedInUserName() {
    return this.loggedInUserName;
  }

  setUserFullName(userFullName: string) {
    this.userFullName = userFullName;
  }

  getUserFullName() {
    return this.userFullName;
  }

  setUserExpert(val: boolean) {
    this.userExpert = val;
  }

  setUserSurveyor(val: boolean) {
    this.userSurveyor = val;
  }

  isUserExpert() {
    return this.userExpert;
  }

  isUserSurveyor() {
    return this.userSurveyor;
  }

  isHistory() {
    return this.history;
  }

  setInHistory(value: boolean) {
    this.history = value;
  }


  setSelectedVesselAccess(vesselAccess: VesselAccess) {
    this.logger.debug("setSelectedVesselAccess:", vesselAccess);
    this.selectedVesselAccess = vesselAccess;
  }

  getSelectedVesselAccess() {
    return this.selectedVesselAccess;
  }

  // setLoggedInUserBasic(firstName: string, lastName: string, email: string, username: string) {
  //   this.loggedInUserBasic = new UserBasic();
  //   this.loggedInUserBasic.firstName = firstName;
  //   this.loggedInUserBasic.lastName = lastName;
  //   this.loggedInUserBasic.email = email;
  //   this.loggedInUserBasic.username = username;
  //   this.loggedInUserBasic.office = "";
  // }

  setUpUserInfoVarsInAppService(loggedInUserBasic: UserBasic) {
    if (loggedInUserBasic) {
      this.setUserFullName(loggedInUserBasic.fullName);
      this.setLoggedInUserName(loggedInUserBasic.username);
      let isExpert = this.checkValuePresentInArray(loggedInUserBasic.roles, Constants.QUALIF_ROLE.FMEA_EXPERT);
      let isSurveyor = this.checkValuePresentInArray(loggedInUserBasic.roles, Constants.QUALIF_ROLE.FMEA_SURVEYOR);
      this.setUserExpert(isExpert);
      this.setUserSurveyor(isSurveyor);
    }
  }

  async getLoggedInUserBasic(): Promise<UserBasic> {
    if (this.loggedInUserBasic) {
      this.setUpUserInfoVarsInAppService(this.loggedInUserBasic);
      return this.loggedInUserBasic;
    }
    else {
      this.loggedInUserBasic = new UserBasic();
      this.loggedInUserBasic.firstName = await this.localStorageService.getCurrentFirstName();
      this.loggedInUserBasic.lastName = await this.localStorageService.getCurrentLastName();
      this.loggedInUserBasic.email = await this.localStorageService.getCurrentEmail();
      this.loggedInUserBasic.username = await this.localStorageService.getCurrentUserName();
      this.loggedInUserBasic.office = "";
      this.loggedInUserBasic.roles = await this.localStorageService.getUserRoles();
      this.loggedInUserBasic.fullName = this.loggedInUserBasic.firstName + ' ' + this.loggedInUserBasic.lastName;
      this.setUpUserInfoVarsInAppService(this.loggedInUserBasic);
      return this.loggedInUserBasic;
    }
  }
  // show toast messeage 
  showToast(msg: string, position?, longDuration?: boolean) {

    if (position == undefined) {
      position = 'top';
    }

    let toast = this.toastCtrl
      .create({
        message: msg,
        duration: longDuration ? 5000 : 2500,
        animated: true,
        showCloseButton: true,
        closeButtonText: "OK",
        cssClass: "my-toast",
        position: position
      })
      .then(obj => {
        obj.present();
      });
  }

  //WHEN KEYPRESS allowed only numbers   
  onKeypressCheckNo(event: any) {
    const pattern = /[0-9.,]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }


  //WHEN PASTE CHAR only allow numbers

  onPasteCheckNo(event: any): boolean {

    var clipboardData, pastedData;
    clipboardData = event.clipboardData;// || window.clipboardData;
    pastedData = clipboardData.getData('Text').toUpperCase();

    if (pastedData.indexOf('E') > -1) { //if found E

      event.stopPropagation();
      event.preventDefault();
    }
    return true;
  }

  isValidEmailId(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isNumber(value: string | number): boolean {
    return ((value != null) && !isNaN(Number(value.toString())));
  }

  isValidDate(date: string) {
    if (moment(date, "DD/MM/YYYY"))
      return true;
    else
      return false;
  }
  getCurrentDate() {
    return new Date();
  }

  // getCurrentTimeStampUTCMilli() {
  //   var date = new Date();
  //   return date.getUTCMilliseconds();
  // }

  getCurrentTimeStamp() {
    // return moment().format("YYYY-MM-DDTHH:mm:ss")
    var date = new Date();
    return this.datePipe.transform(date, Constants.DATE_FORMAT.TIMESTAMP_2);
  }

  getDateFromString(dateString: string) {
    // return moment().format("YYYY-MM-DDTHH:mm:ss")
    var date = new Date();
    return this.datePipe.transform(dateString, Constants.DATE_FORMAT.TIMESTAMP);
  }

  getFormattedTimeStamp(date: Date | number, format?: string) {
    if (!date) {
      return "";
    }
    if (typeof date === "number") {
      date = new Date(date);
    }
    if (StringUtils.isBlank(format)) {
      format = Constants.DATE_FORMAT.TIMESTAMP_2;
    }
    this.logger.info("getFormattedTimeStamp: date", date + " FORMAT:" + format);
    try {
      let result = StringUtils.getEmptyForNull(this.datePipe.transform(date, format));
      this.logger.info("getFormattedTimeStamp: result", result);
      return result;
    } catch (error) {
      this.logger.error("getFormattedTimeStamp: ", error);
      return date + "";
    }

  }

  // chagneDateFormat(date) {
  //   return moment(date).format("DD/MM/YYYY")
  // }

  getCurrentYear(lastTwo?: boolean) {
    var date = new Date();
    return this.datePipe.transform(date, lastTwo ? Constants.DATE_FORMAT.YEAR_2_ANGULAR : Constants.DATE_FORMAT.YEAR_4_ANGULAR);
  }

  getReadableTimeDifference(startTime) {
    var duration = moment.duration(moment().diff(startTime));
    var days = Math.floor(duration.asDays());
    var hours = Math.floor(duration.asHours());
    var minutes = Math.floor(duration.asMinutes());
    var resultTime = "";
    if (days > 0) {
      resultTime = days + " day";
      if (days > 1)
        resultTime = days + " days";
    }
    else if (hours > 0) {
      resultTime = hours + " hour";
      if (hours > 1)
        resultTime = hours + " hours";
    }
    else {
      resultTime = "a moment";
      if (minutes >= 1)
        resultTime = minutes + " minutes";
    }
    return resultTime;
  }

  checkValuePresentInArray(arrayData: any[], searchVal: any) {
    if (arrayData && arrayData.length > 0 && searchVal) {
      return arrayData.indexOf(searchVal) > -1;
    }
    return false;
  }


  //Auth Part: Start

  // checkConfig() {
  //   this.logger.debug("getConfig: config from db: START");
  //   this.localStorageService.getConfig().then(config => {
  //     if (config) {
  //       this.logger.debug("getConfig: config from db:", config);
  //       Constants.CONFIG_BASE_URL = config["param1"];
  //       Constants.CONFIG_DB_REMOTE = config["param2"];
  //       Constants.CONFIG_DB_SYNC_USER = this.CryptoJS.AES.decrypt(decodeURI(config["param3"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_DB_SYNC_PWD = this.CryptoJS.AES.decrypt(decodeURI(config["param4"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_OAUTH_BASE_URL = config["param5"];
  //       Constants.CONFIG_OAUTH_CLIENT_ID = this.CryptoJS.AES.decrypt(decodeURI(config["param6"]), this.skey).toString(this.CryptoJS.enc.Utf8);

  //       this.postConfig();
  //     }
  //     else {
  //       this.loadConfig();
  //     }

  //   }).catch(err => {
  //     this.logger.debug("checkConfig: error:", err);
  //     this.loadConfig();
  //   });
  // }

  // loadConfig() {
  //   this.getConfig()
  //   .then(res => {
  //     this.logger.debug("loadConfig: success:", res);
  //     if (res) {
  //       let config = res.response.data;
  //       this.logger.debug("loadConfig: config from net:", config);
  //       Constants.CONFIG_BASE_URL = config["param1"];
  //       Constants.CONFIG_DB_REMOTE = config["param2"];
  //       Constants.CONFIG_DB_SYNC_USER = this.CryptoJS.AES.decrypt(decodeURI(config["param3"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_DB_SYNC_PWD = this.CryptoJS.AES.decrypt(decodeURI(config["param4"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       Constants.CONFIG_OAUTH_BASE_URL = config["param5"];
  //       Constants.CONFIG_OAUTH_CLIENT_ID = this.CryptoJS.AES.decrypt(decodeURI(config["param6"]), this.skey).toString(this.CryptoJS.enc.Utf8);
  //       this.localStorageService.setConfig(config);
  //       this.postConfig();

  //     }
  //   })
  //   .catch(err => {
  //     this.logger.debug("fetchVesselInfoNeptune: error:", err);
  //   });
  // }

  // postConfig() {
  //   this.logger.debug("postConfig:");
  //   this.oauthConfigSet = true;
  //   this.setUpOAuthService();
  //   // setTimeout(() => {
  //   //   this.performLogin();
  //   // }, 10000);
  //   this.performLogin();
  // }

  // async performLogin(){
  //   await this.login();
  // }



  async login() {
    console.log("login----Start: ", this.oauthService.loginUrl);
    let res = await this.oauthService.tryLogin();
    console.log("login----tryLogin Result: ", res);
    let isLogged = await this.isLogged();
    if (isLogged == 0) {//(!this.isLogged()) {
      this.oauthService.initCodeFlow();
      console.log("token----in if: ", this.oauthService.getAccessToken());
    } else {
      // alert("Inside else")
      if (isLogged == 1) {
        const jwtHelper = new JwtHelperService();
        let decodedToken = jwtHelper.decodeToken(this.oauthService.getAccessToken());

        this.commonService.setLogged();
        console.log("token access----in else: ", this.oauthService.getAccessToken());
        console.log("token access expiry----: ", this.oauthService.getAccessTokenExpiration());
        console.log("token----JWT:", decodedToken);
        console.log("token refresh----in else: ", this.oauthService.getRefreshToken());
        await this.saveLoggedInInfo(decodedToken);
      }
      else {
        console.log("login----tryLogin Already logged in: ");
        await this.setLoggedInInfo();
      }


      // this.oauthService.silentRefresh();
    }
  }

  // login() {
  //   this.oauthService.tryLogin().then((res: any) => {
  //     console.log("tryLogin() -- res-- ", JSON.stringify(res))
  //     if (!this.isLogged()) {
  //       this.oauthService.initCodeFlow();
  //       console.log("token----in if: ", this.oauthService.getAccessToken());
  //     } else {
  //       // alert("Inside else")
  //       const jwtHelper = new JwtHelperService();
  //       let decodedToken = jwtHelper.decodeToken(this.oauthService.getAccessToken());

  //       this.commonService.setLogged();
  //       console.log("token access----in else: ", this.oauthService.getAccessToken());
  //       console.log("token access expiry----: ", this.oauthService.getAccessTokenExpiration());
  //       console.log("token----JWT:", decodedToken);
  //       console.log("token refresh----in else: ", this.oauthService.getRefreshToken());
  //       this.saveLoggedInInfo(decodedToken);
  //       // this.oauthService.silentRefresh();
  //     }
  //   }).catch(err => {
  //     // alert("Inside catch")
  //     console.log("isnide catch---- ", err);
  //     // this.redirection = false;
  //     // this.errorMessage = err;
  //   })
  // }

  // async isLogged() {
  //   if (Constants.MUS_SSO_ENABLED) {
  //     console.log("token--isLogged() " + this.oauthService.getAccessToken())
  //     if (this.oauthService.getAccessToken() == null) {
  //       let storedToken =  await this.localStorageService.getAccessToken();
  //       console.log("token--isLogged() Stored Token: " + storedToken)
  //       return StringUtils.isNotBlank(storedToken);// != null;
  //     }
  //     return this.oauthService.getAccessToken() != null;
  //   }
  //   else {
  //     return false;//TODO: Manoj Check
  //   }
  // }

  getOauthToken() {
    return this.oauthService.getAccessToken();
  }

  async isLogged(): Promise<number> {
    if (Constants.MUS_SSO_ENABLED) {
      console.log("token--isLogged() " + this.oauthService.getAccessToken())
      if (this.oauthService.getAccessToken() == null) {
        let storedToken = await this.localStorageService.getAccessToken();
        console.log("token--isLogged() Stored Token: " + storedToken)
        let result = StringUtils.isNotBlank(storedToken);// != null;
        return result ? 2 : 0;
      }
      let result = this.oauthService.getAccessToken() != null;
      return result ? 1 : 0;
    }
    else {
      return 0;//TODO: Manoj Check
    }
  }


  // logoutUser() {
  //   //TODO: PERFORM FINAL SYNC
  //   if (Constants.MUS_SSO_ENABLED) {
  //     sessionStorage.setItem("id_token", "dummy"); // Workaround for angular-oauth2-oidc-codeflow bug
  //     this.oauthService.logOut();
  //   }
  //   this.setUserExpert(false);
  //   this.setUserSurveyor(false);
  //   this.setLoggedInUserName(null);
  //   this.loggedInUserBasic = null;
  //   this.localStorageService.resetLocalStorage();
  //   this.router.navigateByUrl(Constants.PAGES.LOGIN);
  // }

  async logoutUser() {
    //TODO: PERFORM FINAL SYNC
    
    console.log("logoutUser token" + this.oauthService.getAccessToken())
    this.setUserExpert(false);
    this.setUserSurveyor(false);
    this.setLoggedInUserName(null);
    this.loggedInUserBasic = null;
    await this.localStorageService.setAccessToken(null);
    await this.localStorageService.resetLocalStorage();
    sessionStorage.setItem("id_token", "dummy"); // Workaround for angular-oauth2-oidc-codeflow bug
    if (CordovaUtils.cordovaIsAvailable()) {
      this.router.navigateByUrl(Constants.PAGES.LOGIN);  
    }
    if (Constants.MUS_SSO_ENABLED) {
      this.oauthService.logOut();
      sessionStorage.setItem("id_token", "dummy"); // Workaround for angular-oauth2-oidc-codeflow bug
    }

  }

  async setLoggedInInfo() {
    let userName = await this.localStorageService.getCurrentUserName();
    let userFullName = await this.localStorageService.getCurrentFullName();
    this.setUserFullName(userFullName);
    this.setLoggedInUserName(userName);
    this.initSyncDb(userName);
  }

  /**TODO: Kundan To be changed later */
  async saveLoggedInInfoForDevice(_decodedToken: any) {   //changes if any has to be done in saveLoggedInInfo() and saveLoggedInInfoForDevice()
    const jwtHelper = new JwtHelperService();
    let decodedToken = jwtHelper.decodeToken(_decodedToken.access_token);

    let firstName: string = decodedToken.firstName;
    let lastName: string = decodedToken.lastName;
    let userName: string = decodedToken.user_name;
    let email: string = decodedToken.email;
    this.setLoggedInUserName(userName);

    if (!Constants.ENV_PROD) {
      firstName = this.encodeString(firstName);
      lastName = this.encodeString(lastName);
    }

    let currentUserName = await this.localStorageService.getCurrentUserName();
    let userFullName = await this.localStorageService.getCurrentFullName();
    this.setUserFullName(userFullName);

    if (userName != currentUserName) {
      this.localStorageService.resetLocalStorage();//?TODO??
    }

    this.localStorageService.setAccessToken(_decodedToken.access_token);
    this.localStorageService.setRefreshToken(_decodedToken.refresh_token);
    this.setLoggedInUserName(userName);
    this.localStorageService.setCurrentUserInfo(userName, email, firstName, lastName);

    this.events.publish('username:changed', firstName + " " + lastName);

    //TODO: Manoj JWT Token not present, only decoded JWT available: REMOVE IT?
    this.localStorageService.setJWTToken(decodedToken);
    this.localStorageService.setAccessTokenExpiry(jwtHelper.getTokenExpirationDate(_decodedToken.access_token));
    this.localStorageService.setRefreshTokenExpiry(jwtHelper.getTokenExpirationDate(_decodedToken.refresh_token));
    console.log("userName:" + userName);
    this.initSyncDb(userName);
  }

  /**TODO: Manoj To be changed later */
  async saveLoggedInInfo(decodedToken: any) {
    const jwtHelper = new JwtHelperService();

    let firstName: string = decodedToken.firstName;
    let lastName: string = decodedToken.lastName;
    let userName: string = decodedToken.user_name;
    let email: string = decodedToken.email;

    if (!Constants.ENV_PROD) {
      firstName = this.encodeString(firstName);
      lastName = this.encodeString(lastName);
    }

    firstName = StringUtils.getCamelCase(firstName);
    lastName = StringUtils.getCamelCase(lastName);

    let currentUserName = await this.localStorageService.getCurrentUserName();

    let userFullName = await this.localStorageService.getCurrentFullName();
    this.setUserFullName(userFullName);

    if (userName != currentUserName) {
      this.localStorageService.resetLocalStorage();//?TODO??
    }


    // this.setLoggedInUserBasic(firstName, lastName, email, userName);

    this.localStorageService.setAccessToken(this.oauthService.getAccessToken());
    this.localStorageService.setRefreshToken(this.oauthService.getRefreshToken());
    this.setLoggedInUserName(userName);

    this.localStorageService.setCurrentUserInfo(userName, email, firstName, lastName);

    //TODO: Manoj JWT Token not present, only decoded JWT available: REMOVE IT?
    this.localStorageService.setJWTToken(decodedToken);

    this.localStorageService.setAccessTokenExpiry(jwtHelper.getTokenExpirationDate(this.oauthService.getAccessToken()));
    this.localStorageService.setRefreshTokenExpiry(jwtHelper.getTokenExpirationDate(this.oauthService.getRefreshToken()));

    this.initSyncDb(userName);
  }


  showDebugToast(msg: string, position?, longDuration?: boolean) {
    if (!position) {
      position = 'top';
    }
    if (!longDuration) {
      longDuration = true;
    }
    if (Constants.DEBUG_ENABLED) {
      this.showToast(msg, position, longDuration);
    }
  }
  //Auth Part: End

  async createUserLog(username: string) {
    let user: User = new User();
    user.username = username;
    user.email = await this.localStorageService.getCurrentEmail();
    user.token = await this.localStorageService.getAccessToken();
    user.fullName = await this.localStorageService.getCurrentFullName();
    user.role = await this.localStorageService.getUserRoles();
    user.version = AppUtils.APP_VERSION;
    user.platform = this.platformService.getPlatformType();
    this.userService.createUser(user);
  }

  async initSyncDb(username: string) {
    console.log("App Manager---- initSyncDb", username);
    this.createUserLog(username);
    this.synchroPouchDBProvider.initSyncDb(username);
  }

  encodeString(orgData): string {
    let encoded = btoa(orgData);
    // return encoded.replace(/[^a-zA-Z]/g, 'X');
    // let encoded: string = Base64.encodeToString(orgData.getBytes());
		encoded = encoded.substring(0, orgData.length);
		encoded = encoded.replace(/[^a-zA-Z]/g, 'X');
	 	return encoded;
  }
}
