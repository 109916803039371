import * as _ from 'lodash';

export abstract class ObjectUtils {

    /**
     * Do a deep clone of an object.
     * 
     * @static
     * @param {*} toClone 
     * @returns {*} 
     * @memberof ObjectUtils
     */
    public static deepClone(toClone: any): any {
        return _.cloneDeep(toClone);

    }
    
    /**
     * Performs a deep comparison between two values to determine if they are equivalent.
     * 
     * @static
     * @param {*} value The value to compare.
     * @param {*} other The other value to compare.
     * @returns {boolean} Returns true if the values are equivalent, else false.
     * @memberof ObjectUtils
     */
    public static isEqual(value, other): boolean {
        return _.isEqual(value, other);
    }

}