import { VesselAccessEnum } from './enums';

export class VesselAccess {
    public vesselId: string;
    public vesselIMO: string;
    public username: string;
    public vesselAccesses: Array<VesselAccessEnum>;
    public isExpert: boolean;       //set the logged in user type
    public isReviewer: boolean;    //set the logged in user type
    public isApprover: boolean;    //set the logged in user type
    /**Means Onboard responsible */
    public isTrialResp: boolean;   //set the logged in user type 
    /** Can be any other expert or surveyor*/
    public isGuest: boolean;
}