import { VesselService } from './../vessel/vessel.service';
import { element } from 'protractor';
import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { TestCase, TestCaseExec } from '../../../models';

// PROVIDERS
import { TestCaseDao, TestCaseExecDao } from '../../dao';
import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { TemplateStepsExec } from 'src/app/models/test-case-exec.model';
import { TestExecStatus, StepExecStatus } from 'src/app/models/enums';
import { AnnualTrialsService } from '../annual-trials/annual-trials.service';
import { ImageDocService } from '../image-doc/image-doc.service';
import { CreateAnnualSubService } from '../create-annual-sub.service';

export class TestCaseExecParams {
    public testCaseExecId: string;
    public fromStatus: TestExecStatus;
    public toStatus?: TestExecStatus;
    public result?: string;
}

@Injectable()
export class TestCaseService extends AbstractService {

    public vesselId: string;
    public vesselIMO: string;
    public testCaseId: string;
    public maxTestNumber: number;
    public testCasesFromVesselTestCase: any = [];
    constructor(protected injector: Injector, private testCaseDao: TestCaseDao, public imageDocService: ImageDocService, private testCaseExecDoa: TestCaseExecDao, private annualTrialService: AnnualTrialsService, private vesselService : VesselService, private createAnnualTrialService: CreateAnnualSubService,) {//TODO: Manoj 24042020: Change from docDoa to imagedocservice
        super(injector, "TestCaseService");
    }

    /**
     *
     * @param {TestCase} testCase
     * @returns {Promise<TestCase>}
     */
    public async createTestCase(testCase: TestCase): Promise<TestCase> {
        testCase.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(testCase);
        this.addPrincipalDocIdToChannel(testCase.vesselId, testCase);
        let response = this.testCaseDao.create(testCase);
        //Dt-248 Start
        let activeAnnualTrialId = this.createAnnualTrialService.getActiveAnnualTrialId();
        if (StringUtils.isNotBlank(activeAnnualTrialId)) {
            let activeAnnualTrial = await this.annualTrialService.getAnnualTrials(activeAnnualTrialId);
            if (activeAnnualTrial) {
                if (activeAnnualTrial.tCExecStatus) {
                    if (activeAnnualTrial.tCExecStatus === 1) {
                        let createTCExec = await this.createTestCaseExecFromTestCase(activeAnnualTrialId, testCase, true);
                    }
                }
            }
        }
        //End
        return response;
    }

    /**
     *
     * @param {TestCase} testCase
     * @returns {Promise<TestCase>}
     */
    public async updateTestCase(testCase: TestCase): Promise<TestCase> {
        /* Old Code
        testCase.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        return this.testCaseDao.update(testCase);
        */
        testCase.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        let response = this.testCaseDao.update(testCase);
        //DT-248 Start
        let activeAnnualTrialId = this.createAnnualTrialService.getActiveAnnualTrialId();
        if (StringUtils.isNotBlank(activeAnnualTrialId)) {
            let activeAnnualTrial = await this.annualTrialService.getAnnualTrials(activeAnnualTrialId);
            if (activeAnnualTrial) {
                if (activeAnnualTrial.tCExecStatus) {
                    if (activeAnnualTrial.tCExecStatus === 1) {
                        let updateTCExec = await this.updateTestCaseExecFromTestCase(activeAnnualTrialId, testCase, true);
                    }
                }
            }
        }
        //End
        return response;
    }

    /**
     *
     * @param {string} testCaseId
     * @returns {Promise<TestCase>}
     */
    public async getTestCase(testCaseId: string): Promise<TestCase> {
        return await this.testCaseDao.get(testCaseId);
    }

    /**
     *
     * @returns {Promise<Array<TestCase>>}
     */
    public async findAll(vesselId): Promise<any> {
        let testCases = await this.testCaseDao.findAll();
        return _.filter(testCases, { vesselId: vesselId });
    }
    /**
     *
     * @returns {Promise<Array<TestCase>>}
     */
    public async getAllVesselsWithTestCase(): Promise<any> {
        let testCases = await this.testCaseDao.findAll();
        if (testCases.length > 0) {
            return Array.from(new Set(testCases.map(data => data.vesselId)));
        } else {
            return null;
        }
    }


    public async getMyVesselsWithTestCase(userLoggedIn): Promise<any> {
        let allTestCasesVesselId = await this.getAllVesselsWithTestCase(); //all vessel id with testcase
        let myVesselsWithTestCase = [];
        if (allTestCasesVesselId.length > 0) {
            let userVessel = [];
            userVessel = await this.vesselService.findAllUserCreatedVessels(userLoggedIn);
            userVessel.forEach(vesselData =>{
                if(allTestCasesVesselId.indexOf(vesselData._id) != -1){
                    myVesselsWithTestCase.push(vesselData);
                }
            });
            return myVesselsWithTestCase;
        } else {
            return null;
        }
    }

    /**
     *
     * @returns {Promise<Array<TestCase>>}
     */
    public async updateAll(docs: TestCase[]): Promise<Array<TestCase>> {
        // return this.testCaseDao.updateAll(docs);
        let response = this.testCaseDao.updateAll(docs);
        let testCaseExecIndex = await this.updateTCEIndex(docs);
        return response;
    }

    //DT-248 Start
    public async updateTCEIndex(testCases: TestCase[]): Promise<Array<TestCase>>{
        let activeAnnualTrialId = this.createAnnualTrialService.getActiveAnnualTrialId();
        if (StringUtils.isNotBlank(activeAnnualTrialId)) {
            for(let i = 0; i < testCases.length; i++) {
                let activeAnnualTrial = await this.annualTrialService.getAnnualTrials(activeAnnualTrialId);
                if (activeAnnualTrial) {
                    if (activeAnnualTrial.tCExecStatus) {
                        if (activeAnnualTrial.tCExecStatus === 1) {
                            let testCaseExec = await this.findTestCaseExec(activeAnnualTrialId, testCases[i]._id);
                            testCaseExec[0].testNumber = testCases[i].testNumber;
                            let updatedTCEIndex = await this.updateTestCaseExec(testCaseExec[0]);
                        }
                    }
                }
            }
        }
        return;
    }
    //End
    /**
     *
     * @param {TestCase} testCase
     * @returns {Promise<boolean>}
     */
    public async remove(testCase: TestCase): Promise<boolean> {
        //DT-248 Start
        let activeAnnualTrialId = this.createAnnualTrialService.getActiveAnnualTrialId();
        if (StringUtils.isNotBlank(activeAnnualTrialId)) {
            let activeAnnualTrial = await this.annualTrialService.getAnnualTrials(activeAnnualTrialId);
            if (activeAnnualTrial) {
                if (activeAnnualTrial.tCExecStatus) {
                    if (activeAnnualTrial.tCExecStatus === 1) {
                        let testCaseExecArray = await this.findTestCaseExec(activeAnnualTrialId, testCase._id);
                        let removeTCExec = await this.deleteTestCaseExec(testCaseExecArray[0]);
                    }
                }
            }
        }
        /*let testCaseExecArray = await this.findTestCaseExec(activeAnnualTrialId, testCase._id);
        if(testCaseExecArray && testCaseExecArray.length > 0) {
            await this.deleteTestCaseExec(testCaseExecArray[0]);
        }*/
        //End
        return await this.testCaseDao.delete(testCase);
    }

    /**
     * @param {string} testCaseId
     * @returns {Promise<Array<Doc>>}
     */
    // public async getFiles(testCaseId: string): Promise<any> {//TODO: Manoj 240420 Check if map is better than query or not
    //     let docs = await this.docDao.findAll();
    //     docs = _.filter(docs, { parentDocId: testCaseId });
    //     return docs;
    //     // return _.map(docs, _.partialRight(_.pick, ['_id', 'fileName']));
    // }

     /**
     * @param {string} testCaseId
     * @returns {Promise<Array<Doc>>}
     */
    public async getFiles(testCaseId: string): Promise<any> {//TODO: Manoj 240420 Check if map is better than query or not
        let docs:any = await this.imageDocService.findAllDocs();
        docs = _.filter(docs, { parentDocId: testCaseId });
        

        let images:any = await this.imageDocService.findAllImages();
        images = _.filter(images, { parentDocId: testCaseId });

        let files = _.concat(docs, images);
        files = _.remove(files, function(file) {//TODO: Manoj Removed deleted files
            let containsDelete = _.hasIn(file, 'deleted');
            return !(containsDelete && file.deleted);
            // if (containsDelete && file.deleted) {
            //     files = _.slice(files, )
            // }
          })
        return files;
        // var query = {
        //     type: Constants.TYPES.TESTCASE,
        //     parentDocId: testCaseId
        // }
        // return this.testCaseDao.findBasedOnQuery(query);
    }

    public async getFilesFromArray(fileIds: Array<string>): Promise<any> {//TODO: Manoj 240420 Check if map is better than query or not
        
    }

    /**
     *
     * @param {string} vesselId
     */
    public setVesselId(vesselId: string) {
        this.vesselId = vesselId;
    }

    /**
     *
     * @returns {string}
     */
    public getVesselId() {
        return this.vesselId;
    }

     /**
     *
     * @param {string} vesselIMO
     */
    public setVesselIMO(vesselIMO: string) {
        this.vesselIMO = vesselIMO;
    }

    /**
     *
     * @returns {string}
     */
    public getVesselIMO() {
        return this.vesselIMO;
    }

    /**
     *
     * @param {string} testCaseId
     */
    public setTestCaseId(testCaseId: string) {
        this.testCaseId = testCaseId;
    }

    /**
     *
     * @returns {string}
     */
    public getTestCaseId() {
        return this.testCaseId;
    }

    /**
     *
     * @param {string} maxTestNumber
     */
    public setMaxTestNumber(maxTestNumber) {
        this.maxTestNumber = parseInt(maxTestNumber);
    }

    /**
     *
     * @returns {number}
     */
    public getMaxTestNumber() {
        return this.maxTestNumber;
    }

    /**
     *
     * @returns {Promise<Array<Vessel>>}
     */
    public async findVesselTestCases(vesselUid: string, sort?: string): Promise<Array<TestCase>> {
        var query = {
            type: Constants.TYPES.TESTCASE,
            vesselId: vesselUid
        }
        if (StringUtils.isNotBlank(sort)) {
            let sortBy = [{ testNumber: sort }];
            return this.testCaseDao.findBasedOnQuery(query, sortBy);    
        }
        else
            return this.testCaseDao.findBasedOnQuery(query);
    }

    // Test case execution
    /**
    * @param {string} annualTrialId
    * @param {TestCase} testCase
    * @returns {Promise<TestCaseExec>}
    */
    public async createTestCaseExecFromTestCase(annualTrialId: string, testCase: TestCase, save?: boolean): Promise<TestCaseExec> {
        //Check test Case already created, if yes then send that only to user

        //If not found, then send new one
        // let testCase = await this.getTestCase(testCaseId);
        if (testCase) {
            let testCaseExecArray = await this.findTestCaseExec(annualTrialId, testCase._id);
            if (testCaseExecArray && testCaseExecArray.length > 0) {
                return testCaseExecArray[0];
            }
            let testCaseExec = new TestCaseExec();
            testCaseExec.annualTrialId = annualTrialId;
            testCaseExec.vesselId = testCase.vesselId;
            testCaseExec.testCaseId = testCase._id;
            testCaseExec.testNumber = testCase.testNumber;
            testCaseExec.testName = testCase.testName;
            testCaseExec.testObjective = testCase.testObjective;
            testCaseExec.testSysConfig = testCase.testSysConfig;
            testCaseExec.genGuideComments = testCase.comment;
            testCaseExec.genGuideImageDocIds = testCase.imageDocIds;
            testCaseExec.isExcluded = testCase.isExcluded;
            let stepsExecData: Array<TemplateStepsExec> = [];
            if (testCase.stepsData && testCase.stepsData.length > 0) {
                testCase.stepsData.forEach(step => {
                    let stepExec = new TemplateStepsExec();
                    stepExec.method = step.method;
                    stepExec.stepId = step.stepId;//Manoj added 15092020
                    stepExec.stepUUID = step.stepUUID;
                    stepExec.expResult = step.expResult;
                    stepExec.tableInfo = step.tableInfo;
                    stepExec.tableData = step.tableData;
                    stepsExecData.push(stepExec);
                });
            }

            testCaseExec.stepsData = stepsExecData;
            testCaseExec.commentTable = testCase.commentTable;
            testCaseExec.finalComments = testCase.genComment;
            // testCaseExec.executionStatus = TestExecStatus.NOT_STARTED;
            testCaseExec.executionStatus = testCase.isExcluded ? TestExecStatus.NOT_EXECUTED : TestExecStatus.NOT_STARTED;//Added for Jan 2022 release

            if (save) {
                testCaseExec = await this.createTestCaseExec(testCaseExec);
            }

            return testCaseExec;
        }
        return;
    }

    //DT-248 Start
    public async updateTestCaseExecFromTestCase(annualTrialId: string, testCase: TestCase, update?: boolean): Promise<TestCaseExec> { 
        //annualTrialId: string, testCase: TestCase, save?: boolean
        //Check test Case already created, if yes then send that only to user

        //If not found, then send new one
        // let testCase = await this.getTestCase(testCaseId);
        if (testCase) {
            let testCaseExecArray = await this.findTestCaseExec(annualTrialId, testCase._id);
            let testCaseExec;
            if (testCaseExecArray && testCaseExecArray.length > 0) {
                // let testCaseExec = new TestCaseExec();
                testCaseExec = testCaseExecArray[0];
                testCaseExec.annualTrialId = annualTrialId;
                testCaseExec.vesselId = testCase.vesselId;
                testCaseExec.testCaseId = testCase._id;
                testCaseExec.testName = testCase.testName;
                testCaseExec.testNumber = testCase.testNumber;
                
                testCaseExec.testObjective = testCase.testObjective;
                testCaseExec.testSysConfig = testCase.testSysConfig;
                testCaseExec.genGuideComments = testCase.comment;
                testCaseExec.genGuideImageDocIds = testCase.imageDocIds;
                testCaseExec.isExcluded = testCase.isExcluded;
                let stepsExecData: Array<TemplateStepsExec> = [];
                if (testCase.stepsData && testCase.stepsData.length > 0) {
                    testCase.stepsData.forEach(step => {
                        let stepExec = new TemplateStepsExec();
                        stepExec.method = step.method;
                        stepExec.stepId = step.stepId;//Manoj added 15092020
                        stepExec.stepUUID = step.stepUUID;
                        stepExec.expResult = step.expResult;
                        stepExec.tableInfo = step.tableInfo;
                        stepExec.tableData = step.tableData;
                        stepsExecData.push(stepExec);
                    });
                }

                testCaseExec.stepsData = stepsExecData;
                testCaseExec.commentTable = testCase.commentTable;
                testCaseExec.finalComments = testCase.genComment;
                // testCaseExec.executionStatus = TestExecStatus.NOT_STARTED;
                testCaseExec.executionStatus = testCase.isExcluded ? TestExecStatus.NOT_EXECUTED : TestExecStatus.NOT_STARTED;//Added for Jan 2022 release
            }
            if (update) {
                testCaseExec = await this.updateTestCaseExec(testCaseExec);
            }

            return testCaseExec;
        }
        return;
    }
    //End
    /**
    *
    * @param {TestCaseExec} testCaseExec
    * @returns {Promise<TestCaseExec>}
    */
    public async createTestCaseExec(testCaseExec: TestCaseExec): Promise<TestCaseExec> {
        testCaseExec.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(testCaseExec);
        this.addPrincipalDocIdToChannel(testCaseExec.vesselId, testCaseExec);
        let response = this.testCaseExecDoa.create(testCaseExec);
        return response;
    }

    /**
     *
     * @param {TestCaseExec} testCaseExec
     * @returns {Promise<TestCaseExec>}
     */
    public async updateTestCaseExec(testCaseExec: TestCaseExec): Promise<TestCaseExec> {
        testCaseExec.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(testCaseExec);
        this.addPrincipalDocIdToChannel(testCaseExec.vesselId, testCaseExec);
        return this.testCaseExecDoa.update(testCaseExec);
    }

    //DT-248 Start
    public async deleteTestCaseExec(testCaseExec: TestCaseExec): Promise<boolean> {
        testCaseExec.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(testCaseExec);
        this.addPrincipalDocIdToChannel(testCaseExec.vesselId, testCaseExec);
        return this.testCaseExecDoa.delete(testCaseExec);
    }
    //End
    /**
     *
     * @param {string} testCaseId
     * @returns {Promise<TestCaseExec>}
     */
    public async getTestCaseExec(testCaseExecId: string): Promise<TestCaseExec> {
        return await this.testCaseExecDoa.get(testCaseExecId);
    }

    /**
     * @param {string} annualTrialId The annual Trial document Id
     * @param {string} testCaseId The test case document Id
     * @returns {Promise<TestCaseExec[]>}
     */
    public async findTestCaseExec(annualTrialId: string, testCaseId: string): Promise<TestCaseExec[]> {
        var query = {
            type: Constants.TYPES.TESTCASE_EXEC,
            annualTrialId: annualTrialId,
            testCaseId: testCaseId
            // deleted: false //TODO: Manoj Check this everywhere for all docs
        }
        return this.testCaseExecDoa.findBasedOnQuery(query);
    }

    //DT-248 Start
    public async findTestCaseExecWithTestCase(testCaseId: string): Promise<TestCaseExec[]> {
        var query = {
            type: Constants.TYPES.TESTCASE_EXEC,
            // annualTrialId: annualTrialId,
            testCaseId: testCaseId
            // deleted: false //TODO: Manoj Check this everywhere for all docs
        }
        let sortBy = [{ mdt: Constants.SORT_BY.DESC }];
        return this.testCaseExecDoa.findBasedOnQuery(query, sortBy, 1);
    }
    //Emd
    public getStatusOfTestCaseExecution(testCaseExec: TestCaseExec): TestExecStatus {
        if (!testCaseExec) {
            return;
        }
        let status = TestExecStatus.NOT_STARTED;
        //Manoj working on this part

        return status;
    }

    public async findAllTestCaseExec(annualTrialId: string, sort?: string): Promise<any> {
        // let testCasesExec = await this.testCaseExecDoa.findAll();
        // return _.filter(testCasesExec, { annualTrialId: annualTrialId });
        var query = {
            type: Constants.TYPES.TESTCASE_EXEC,
            annualTrialId: annualTrialId
        }
        if (StringUtils.isNotBlank(sort)) {
            let sortBy = [{ testNumber: sort }];
            return this.testCaseExecDoa.findBasedOnQuery(query, sortBy);    
        }
        else
            return this.testCaseExecDoa.findBasedOnQuery(query);
    }

    public async anyTestCaseExecCompleted(annualTrialId): Promise<boolean> {   //returns true if atleast one test case exec is completed
        let allTestCaseExec = await this.findAllTestCaseExec(annualTrialId);
        let execCompleted: boolean = false;
        if (allTestCaseExec.length > 0) {
            for (let element of allTestCaseExec) {
                if (element.executionStatus == TestExecStatus.COMPLETED) {  //completed
                    execCompleted = true;
                    break;
                }
            };
        }
        return execCompleted;
    }

    public async isAllTestCaseExecCompletedOrNotExec(annualTrialId): Promise<boolean> { //returns true if all test case are completed or not exec
        let testCaseExecArray = await this.findAllTestCaseExec(annualTrialId);
        let isAllTestCaseExecCompleted: boolean = false;
        for (let element of testCaseExecArray) {

            if (element.executionStatus != TestExecStatus.COMPLETED && element.executionStatus != TestExecStatus.NOT_EXECUTED && element.executionStatus != TestExecStatus.EXCLUDED) {//Added for Jan 2022
                isAllTestCaseExecCompleted = false;
                break;
            } else {
                isAllTestCaseExecCompleted = true;
            }
        }
        return isAllTestCaseExecCompleted;
    }



    //Test Case Move fucntionality

    async updateAnnualTrialsTC(annualTrialId: string, testCasesConverted: number, testCaseExecStarted: boolean): Promise<boolean> {
        let updateStatus = false;
        this.logger.debug(`updateAnnualTrialsTC START: annualTrialId:${annualTrialId} testCasesConverted:${testCasesConverted} testCaseExecStarted:${testCaseExecStarted}`);
        if (StringUtils.isNotBlank(annualTrialId)) {
            let annualTrial = await this.annualTrialService.getAnnualTrials(annualTrialId);
            if (annualTrial) {

                if (annualTrial.tCExecStatus) {
                    if ((testCaseExecStarted && annualTrial.tCExecStatus == 0) || (!testCaseExecStarted && annualTrial.tCExecStatus == 1)) {
                        updateStatus = true;
                    }
                }
                else {
                    updateStatus = true;
                }
                if (updateStatus) {
                    this.logger.debug(`updateAnnualTrialsTC updateStatus:${updateStatus} `);
                    if (testCaseExecStarted) {
                        annualTrial.tCExecStartDate = this.DateUtils.getCurrentDateMilli();//new Date();
                        annualTrial.tCExecStatus = 1;
                        annualTrial.tCExecCount = testCasesConverted;
                    }
                    else {
                        annualTrial.tCExecStartDate = null;
                        annualTrial.tCExecStatus = 0;
                        annualTrial.tCExecCount = 0;//TODO: Manoj check whether right move
                    }
                    annualTrial = await this.annualTrialService.updateAnnualTrials(annualTrial);
                }
            }
        }
        this.logger.debug(`updateAnnualTrialsTC END updateStatus:${updateStatus} `);
        return updateStatus;
    }

    async isTestCaseExecStarted(annualTrialId: string): Promise<boolean> {
        if (StringUtils.isNotBlank(annualTrialId)) {
            let annualTrial = await this.annualTrialService.getAnnualTrials(annualTrialId);
            if (annualTrial) {
                if (annualTrial.tCExecStatus) {
                    if (annualTrial.tCExecStatus === 1) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    async setTestCasesForExec(vesselId: string, annualTrialId: string): Promise<number> {
        let testCasesConverted: number = 0;
        let statusUpdated: boolean = false;
        if (StringUtils.isBlank(vesselId) || StringUtils.isBlank(annualTrialId)) {
            return -1;
        }
        let testCases: Array<TestCase> = await this.findVesselTestCases(vesselId);
        if (testCases && testCases.length > 0) {
            for (let index = 0; index < testCases.length; index++) {
                const testCase = testCases[index];
                let testCaseExec = await this.createTestCaseExecFromTestCase(annualTrialId, testCase, true);
                if (testCaseExec) {
                    testCasesConverted++;
                }
            }
            if (testCasesConverted > 0) {
                statusUpdated = await this.updateAnnualTrialsTC(annualTrialId, testCasesConverted, true);
            }
        }
        this.logger.debug(`setTestCasesForExec END testCasesConverted:${testCasesConverted} statusUpdated:${statusUpdated}`);
        return testCasesConverted;
    }

    /**
     * @param {Array<TestCaseExecParams>} testExecParams list of test cases with filled request info
     * @param {TestExecStatus} toStatus to move status
     * @param {string} username user name
     * @param {string} notExecutedReason if moved from Not Started Not Executed.
     * @returns {Promise<string>} SUCCESS If successfull, else error reason for failure
     */
    async moveTestCases(testExecParams: Array<TestCaseExecParams>, toStatus: TestExecStatus, username: string, notExecutedReason: string): Promise<string> {

        let moveResult: string = "SUCCESS";
        let errorMsg: string = "";

        if (!testExecParams || testExecParams.length <= 0) {
            return "Oops! Please select test cases to move";
        }

        let testCaseCount = testExecParams.length;
        let successCount = 0;
        for (let index = 0; index < testExecParams.length; index++) {
            let testExecParam = testExecParams[index];
            testExecParam.toStatus = toStatus;//not needed 
            let result = await this.moveTestCase(testExecParam.testCaseExecId, username, testExecParam.fromStatus, testExecParam.toStatus, notExecutedReason);
            testExecParam.result = result;//not needed 
            if (result === "SUCCESS") {
                successCount++;
            }
            else {
                if (errorMsg.length == 0) {
                    errorMsg = result;
                }
                else {
                    errorMsg = errorMsg + ", " + result;
                }
            }
        }
        if (successCount != testCaseCount) {
            moveResult = successCount + " out of " + testCaseCount + " are moved successfully as requested! Failed to move other " + (testCaseCount - successCount) + " test cases due to: " + errorMsg;
        }
        return moveResult;

    }

    /**
     * @param {string} testCaseExecId The TEST CASE Exec document Id
     * @param {string} username user name
     * @param {TestExecStatus} fromStatus TEST CASE STATUS TO BE MOVED TO
     * @param {TestExecStatus} toStatus TEST CASE STATUS TO BE MOVED TO
     * @param {string} notExecutedReason Reason for not executing test case
     * @returns {Promise<string>} SUCCESS If successfull, else error reason for failure
     */
    async moveTestCase(testCaseExecId: string, username: string, fromStatus: TestExecStatus, toStatus: TestExecStatus, notExecutedReason: string): Promise<string> {
        let result: boolean = false;
        let success: string = "SUCCESS";
        let testCaseExec: TestCaseExec;

        if (StringUtils.isNotBlank(testCaseExecId)) {
            testCaseExec = await this.getTestCaseExec(testCaseExecId);
        }
        let allowOperation: boolean = false;
        if (fromStatus == TestExecStatus.NOT_STARTED && toStatus == TestExecStatus.NOT_EXECUTED) {
            allowOperation = true;
        }
        if (fromStatus == TestExecStatus.IN_PROGRESS && (toStatus == TestExecStatus.NOT_EXECUTED || toStatus == TestExecStatus.COMPLETED)) {
            allowOperation = true;
        }
        if (fromStatus == TestExecStatus.COMPLETED && (toStatus == TestExecStatus.NOT_EXECUTED || toStatus == TestExecStatus.IN_PROGRESS)) {
            allowOperation = true;
        }
        if (fromStatus == TestExecStatus.NOT_EXECUTED && toStatus == TestExecStatus.NOT_STARTED) {
            allowOperation = true;
        }

        if (!allowOperation) {
            return "Oops! This action is not possible";
        }
        if (!testCaseExec) {
            return "Error! Test case not found.";
        }

        //Perform operation

        let testProofDocIds: Array<string> = [];
        if (fromStatus == TestExecStatus.NOT_STARTED && toStatus == TestExecStatus.NOT_EXECUTED) {
            testCaseExec.executionStatus = TestExecStatus.NOT_EXECUTED;
            testCaseExec.notExecutedReason = notExecutedReason;
            let stepsData = testCaseExec.stepsData;
            if (stepsData) {
                for (let index = 0; index < stepsData.length; index++) {
                    stepsData[index].testResults = Constants.TEST_CASE_STATUS.NOT_EXECUTED;
                    stepsData[index].testComments = "";
                    // stepsData[index].testComments = notExecutedReason; //Sudhanshu Chaubey: pass reason value into test comments
                    stepsData[index].testProofDocIds = testProofDocIds;
                }
            }
            testCaseExec.stepsData = stepsData;
            testCaseExec.finalComments = "";
            testCaseExec.finalWitnessedBy = username;
            testCaseExec.finalModDate = this.DateUtils.getCurrentDateMilli();//new Date();

            testCaseExec = await this.createTestCaseExec(testCaseExec);

            return success;
        }
        if (fromStatus == TestExecStatus.NOT_EXECUTED && toStatus == TestExecStatus.NOT_STARTED) {
            testCaseExec.executionStatus = TestExecStatus.NOT_STARTED;
            testCaseExec.notExecutedReason = '';
            let stepsData = testCaseExec.stepsData;
            if (stepsData) {
                for (let index = 0; index < stepsData.length; index++) {
                    stepsData[index].testResults = "";
                    stepsData[index].testComments = "";
                    stepsData[index].testProofDocIds = testProofDocIds;
                }
            }
            testCaseExec.stepsData = stepsData;
            testCaseExec.finalComments = "";
            testCaseExec.finalWitnessedBy = username;
            testCaseExec.finalModDate = this.DateUtils.getCurrentDateMilli();//new Date();

            testCaseExec = await this.createTestCaseExec(testCaseExec);

            return success;
        }

        if ((fromStatus == TestExecStatus.COMPLETED || fromStatus == TestExecStatus.IN_PROGRESS) && toStatus == TestExecStatus.NOT_EXECUTED) {
            testCaseExec.executionStatus = TestExecStatus.NOT_EXECUTED;
            let stepsData = testCaseExec.stepsData;
            if (stepsData) {
                for (let index = 0; index < stepsData.length; index++) {
                    stepsData[index].testResults = Constants.TEST_CASE_STATUS.NOT_EXECUTED;
                    stepsData[index].testComments = "";
                    stepsData[index].testProofDocIds = testProofDocIds;
                }
            }
            testCaseExec.stepsData = stepsData;
            testCaseExec.finalComments = "";
            testCaseExec.finalWitnessedBy = username;
            testCaseExec.finalModDate = this.DateUtils.getCurrentDateMilli();//new Date();

            testCaseExec = await this.createTestCaseExec(testCaseExec);

            return success;
        }
        if (fromStatus == TestExecStatus.COMPLETED && toStatus == TestExecStatus.IN_PROGRESS) {
            testCaseExec.executionStatus = TestExecStatus.IN_PROGRESS;
            testCaseExec.finalWitnessedBy = username;
            testCaseExec.finalModDate = this.DateUtils.getCurrentDateMilli();//new Date();

            testCaseExec = await this.createTestCaseExec(testCaseExec);

            return success;
        }
        if (fromStatus == TestExecStatus.IN_PROGRESS && toStatus == TestExecStatus.COMPLETED) {
            let checkAllComplete: boolean = true;
            let stepsData = testCaseExec.stepsData;
            if (stepsData) {
                for (let index = 0; index < stepsData.length; index++) {
                    if (StringUtils.isBlank(stepsData[index].testResults)) {
                        checkAllComplete = false;
                        break;
                    }
                    else {
                        if (stepsData[index].testResults != Constants.FINDINGS_STATUS[10]) {//"As expected") {
                            if (StringUtils.isBlank(stepsData[index].testComments)) {
                                checkAllComplete = false;
                                break;
                            }
                        }
                    }
                }
            }
            if (!checkAllComplete) {
                return "Error! All the steps & mandatory fields of the test cases must be completed to enable this action.";
            }
            testCaseExec.executionStatus = TestExecStatus.COMPLETED;
            testCaseExec.executeCompleteDate = this.DateUtils.getCurrentDateMilli();
            testCaseExec.finalWitnessedBy = username;
            testCaseExec.finalModDate = this.DateUtils.getCurrentDateMilli();//new Date();

            testCaseExec = await this.createTestCaseExec(testCaseExec);

            return success;
        }

        return "Error! Unable to move test case";
    }

    /**
     *
     * @param {string} testCases
     */
    public setTestCasesFromVesselTestCase(testCases) {
        this.testCasesFromVesselTestCase = testCases;
    }

    /**
     *
     * @returns {return} testCases
     */
    public getTestCasesFromVesselTestCase() {
        return this.testCasesFromVesselTestCase;
    }


    async resetExcludedTestCases(vesselId: string) {
      let testCases = await this.findAll(vesselId);
      if (testCases && testCases.length > 0) {
        let exludedTestCases = _.filter(testCases, { isExcluded: true });
        if (exludedTestCases && exludedTestCases.length > 0) {
            for (let index = 0; index < exludedTestCases.length; index++) {
                let element = exludedTestCases[index];
                element.isExcluded = false;
                let finalElement = await this.updateTestCase(element);
                this.logger.debug(`resetExcludedTestCases testCase :${finalElement}`);
            }
        }
      }
    }
}
