import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { Router } from "@angular/router";

@Component({
  selector: 'app-confirmation-msg-modal',
  templateUrl: './confirmation-msg-modal.page.html',
  styleUrls: ['./confirmation-msg-modal.page.scss'],
})
export class ConfirmationMsgModalPage implements OnInit {

  modalTitle: string;
  modelId: number;
  modalContent:any;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    private router: Router
  ) {}

  ngOnInit() {
    this.modelId = this.navParams.data.paramID; 
    this.modalTitle = "Confirmation";
  }

  async onCancel() {
    const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss(onClosedData);
  }

  onConfirm() {
    this.router.navigateByUrl("/dashboard");
    this.modalController.dismiss();
  }
}
