import { AbstractDocument } from './abstract-document.model';
import { StatusField, AnalysisResult } from './enums';
import { Constants } from '../app.constants';

export class StatusEngine extends AbstractDocument {

    public vesselId: string;
    public annualTrialId: string;
    public statusReportId: string;
    public engineName: string;
    public maintRecordCheck: StatusField;
    public outstandMaint: StatusField;
    public lastOilAnalDate: Date;//TODO Manoj String or Date?
    public lastOilAnalResult: AnalysisResult;
    public thursterRunHrs: string;//TODO Manoj number or String?
    public lastMjrOverhaulHrs: string;//TODO Manoj number or String?
    public remarks: string;
    type= Constants.TYPES.STATUS_ENGINE;

    public vesselThrusterEngRefUID: string;//Added for Jan 2022 release
}
