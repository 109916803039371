import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { StatusThruster } from 'src/app/models/status-thruster.model';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";


@Injectable()
export class StatusThrusterDao extends AbstractDao<StatusThruster> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.STATUS_THRUSTER, { create: true, delete: true, update: true }, "StatusThrusterDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: StatusThruster): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}