import { Constants } from './../../app.constants';
import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GenericModalPage } from '../../../app/pages/modal/generic-modal/generic-modal.page';
import { AssignedPeopleModalPage } from '../../pages/modal/assigned-people-modal/assigned-people-modal.page';
import { constants } from 'zlib';
import { OtherDocsDao } from '../dao';

@Injectable({
  providedIn: 'root'
})
export class CustomFunctionsService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;
  private expandCollapse: string;

  constructor(private router: Router, public modalController: ModalController, private otherDocsDao: OtherDocsDao) {
    this.currentUrl = this.router.url;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  getExpandCollapse() {
    return this.expandCollapse;
  }
  setExpandCollapse(action) {
    this.expandCollapse = action;
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  openWebsiteURL(url) {
    //window.open(url, "_blank");
    window.open(url, '_system', 'location=yes')
  }

  /**
   * warning_message : warning or message
   * header : header text for popup
   * msgString : message to display
  */
  async presentGenericModal(warning_message, header, buttonText, msgString, callback, inputRequired?, columnWidth?) {
    const modal = await this.modalController.create({
      component: GenericModalPage,
      componentProps: {
        warning_message: warning_message,
        header: header,
        msg: msgString,
        buttonText: buttonText,
        inputRequired: inputRequired,
        columnWidth: columnWidth,
      },
      cssClass: 'generic-modal-css'
    });
    modal.onDidDismiss().then(action => {
      callback(action);
    });
    return await modal.present();
  }

  async presentAssignedPeopleModal(warning_message, header, dpResp, trialResp, callback) {
    const modal = await this.modalController.create({
      component: AssignedPeopleModalPage,
      componentProps: {
        warning_message: warning_message,
        header: header,
        dpResp: dpResp,
        trialResp: trialResp
      },
      cssClass: 'responsible-person-modal-css'
    });
    modal.onDidDismiss().then(action => {
      callback(action);
    });
    return await modal.present();
  }

  async presentDropDownModal(warning_message, header, buttonText, msgString, callback) {
    const modal = await this.modalController.create({
      component: GenericModalPage,
      componentProps: {
        warning_message: warning_message,
        header: header,
        msg: msgString,
        buttonText: buttonText
      },
      cssClass: 'generic-modal-css'
    });
    modal.onDidDismiss().then(action => {
      callback(action);
    });
    return await modal.present();
  }

  isValidFileFormat(fileName, type) {
    let allowedType :string ;
    switch (type) {
      case Constants.TYPE_DOC:
        allowedType = ".pdf, .PDF";
        break;
      case Constants.TYPE_IMAGE:
        allowedType = ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG";
        break;
      case Constants.TYPE_IMAGE_DOC:
        allowedType = ".png, .jpg, .jpeg, .PNG, .JPG, .JPEG, .pdf, .PDF";
        break;
    }

    let extension: string = fileName.substr(fileName.lastIndexOf('.') + 1);
    if (allowedType.indexOf(extension) >= 0)
      return true;
    else
      return false;
  }

  async showFileFormatError(type) {
    let msgString: string;
    switch (type) {
      case Constants.TYPE_DOC:
        msgString = Constants.VALID_IMAGE_ERROR_TYPE.TYPE_DOC;
        break;
      case Constants.TYPE_IMAGE:
        msgString = Constants.VALID_IMAGE_ERROR_TYPE.TYPE_IMAGE;
        break;
      case Constants.TYPE_IMAGE_DOC:
        msgString = Constants.VALID_IMAGE_ERROR_TYPE.TYPE_IMAGE_DOC;
        break;
    }
    let warning_or_msg = Constants.PRESENT_GENERIC_MODAL.WARNING;
    let headerTitle = Constants.PRESENT_GENERIC_MODAL.HEADER_TITLE.ERROR;
    let buttonText = "OneButton";

    let returnedDataFromModal = await this.presentGenericModal(warning_or_msg, headerTitle, buttonText, msgString, (data) => {
    });
    return;
  }

  public async getOtherDocs(subType?: string) {
    return await this.otherDocsDao.get(Constants.TYPES.OTHER_DOCS);
  }
}
