import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { TestExecStatus, StepExecStatus} from '../models/enums'

export class TemplateStepsExec {
    public stepId: number;
    public stepUUID: string;//Uniwue ID for step
    public method: string;
    public expResult: string;
    public tableInfo: Array<string> = [];
    public tableData: Array<string> = [];
    
    public testResults: StepExecStatus;
    public testComments: string;
    public testProofDocIds: Array<string> = [];//Moslty images      //pending Y
}

export class TestCaseExec extends AbstractDocument {

    public testCaseId: string;
    public vesselId: string;
    public annualTrialId: string;
    
    public testNumber: number;
    public testName: string;
    public testObjective: string;
    public testSysConfig: string;
    public genGuideComments: string;
    public genGuideImageDocIds: Array<string> = [];//TODO: Manoj 24042020: Need changes in code to use this             //pending
    //public stepsData: Array<TemplateStepsExec> = [];//put TemplateStepsExec instead of any
    public stepsData: Array<any> = [];//put TemplateStepsExec instead of any                //done

    public finalComments: string;
    public finalWitnessedBy: string;    //not in html           //pending
    public executeDate: string;
    public executeCompleteDate: number;//14102021
    public finalModDate: number;//Date;

    public executionStatus: TestExecStatus;
    public mandatoryComplete: boolean;
    public notExecutedReason: string;
    public isExcluded: boolean = false;//Added for Jan 2022 release
    public commentTable: Array<any> = [];
    
    type = Constants.TYPES.TESTCASE_EXEC;

}

