import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { TrialStatus } from './enums';
import { UserBasic } from './user-basic.model';

/**Parent document which stores Vessel information. It includes the General Details section and references for other vessel information */
export class AnnualTrials extends AbstractDocument {

    //General Details section
    public bvsProjectNo: string;
    public bvsReportNo: string;
    public proposalRefId: string;
    // public reportNo: string;
    public industryGuideDoc: string;
    public portJoining: string;
    public trialsLocation: string;
    public trialYear: string;
    public startDateTrial: any;//Date;
    public endDateTrial: any;//Date;
    public titleTrialsDoc: string;
    public NoTrialsDoc: number;
    public revTrialsDoc: string;  
    public prevReportNo: string;
    public upgradesSinceLastTrials: string;
    public mandatoryComplete: boolean;
    
    public annualTrialDetailsComplete: boolean;

    public dpResponsiblePerson: UserBasic; //TODO: Manoj: 06082020: To be changed to User Basic
    public trialsResponsiblePersonsOnboard: Array<any>;//TODO: Manoj: 06082020: To be changed to User Basic array
    
    /**TODO: MANOJ THINK MORE ABOUT STATUS */
    public annualTrialStatus: TrialStatus;
    
    public clientInfoId: string;
    public supportingDocs = [];

    /**Test case execution started: 0 Pending: 1 Started Rest to be defined*/
    public tCExecStatus: number;
    public tCExecCount: number;
    public tCExecStartDate: number;//Date;

    public trialContentMDT: number;//Date;

    public actualTrialResponsiblePerson: UserBasic;

    public endOfTrialDocId: string;
    public provFindingsDocId: string;
    
    type = Constants.TYPES.ANNUAL_TRIAL;

}