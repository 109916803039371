
import { LogWriter } from './handlers/handler';

export enum LogLevel {
    DEBUG = "debug", INFO = "info", WARN = "warn", ERROR = "error"
}

export class Logger {

    private marksDate = new Map<string, Date>();

    constructor(private name: string, private writers: Promise<LogWriter[]>) {
        // nothing
    }

    public markDate(key: string): void {
        this.marksDate.set(key, new Date());
    }

    public infoDuration(message: string, key: string, ...param: any[]) {
        this.duration(LogLevel.INFO, message, key, ...param);
    }

    public debug(message: string, ...param: any[]) {
        this.log(LogLevel.DEBUG, message, ...param);
    }

    public info(message: string, ...param: any[]) {
        this.log(LogLevel.INFO, message, ...param);
    }

    public warn(message: string, ...param: any[]) {
        this.log(LogLevel.WARN, message, ...param);
    }

    public error(message: string, ...param: any[]) {
        this.log(LogLevel.ERROR, message, ...param);
    }

    private log(level: LogLevel, message: string, ...param: any[]): void {
        const args = [level, this.name, message].concat(param);
        this.writers.then((writers) => writers.forEach((writer) => writer.log.apply(writer, args)));
    }

    private duration(level: LogLevel, message: string, key: string, ...param: any[]) {
        // Retreive marked date
        const date = this.marksDate.get(key);
        // Process duration
        const duration = date ? (new Date().valueOf() - date.valueOf()) / 1000 + "s" : "no marked date for key " + key;
        // Log
        this.log(level, message, duration, ...param);
        // Update marked dates
        this.marksDate.delete(key);
    }

}