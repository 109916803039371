import { Injectable, Injector } from '@angular/core';
import { Subscription } from 'rxjs';

// MISC
import { CordovaUtils } from '../../../commons/utils/cordova-utils';
import { Logger, LoggerFactory } from '../../../commons/log';
import * as _ from 'lodash';

// PROVIDERS
import { MessageService } from '../utils/message.service';
import { VesselService } from '../vessel/vessel.service';
import { AnnualTrialsService } from '../annual-trials/annual-trials.service';
import { TestCaseService } from '../test-case-library/test-case.service';
import { RevStatus, VesselStatusEnum, TrialStatus, SectionStatus, KeyPersonnelRole, StatusField, AnalysisResult, StepExecStatus, ReviewStatusEnum } from 'src/app/models/enums';
import { Constants } from 'src/app/app.constants';
import { VesselStatus, Vessel, AnnualTrials, Image, Doc, StatusCrewPersonnel, StatusThruster, StatusEngine, KeyPersonnel, StatusSwitchBoardDPSystem, StatusDPDocuments, TrialRev } from 'src/app/models';
import { StringUtils, DateUtils } from 'src/app/commons/utils';
import { ImageDocService } from '../image-doc/image-doc.service';
import { StatusReportService } from '../status-report/status-report.service';
import { EnvCondPersonnelService } from '../env-cond-personnel/env-cond-personnel.service';
import { TrialConclusionService } from '../trial-conclusion/trial-conclusion.service';
import { FindingsAndCloseoutService } from '../findings-and-closeout/findings-and-closeout.service';
import { AppManagerService } from '../../app-manager/app-manager.service';
import { GenericDao } from '../../dao';
import { ReportConst } from '../pdf-generate/pdf-constants';
import { sprintf } from 'sprintf-js';

@Injectable()
export class MainDataService {

    private readonly logger: Logger = this.injector.get(LoggerFactory).buildLogger("MainDataService");


    constructor(protected injector: Injector,
        public vesselService: VesselService,
        public annualTrialService: AnnualTrialsService,
        public testCaseService: TestCaseService,
        public statusReportService: StatusReportService,
        public envCondPersonnelService: EnvCondPersonnelService,
        public trialConclusionService: TrialConclusionService,
        public findingsAndCloseoutService: FindingsAndCloseoutService,
        private appManagerService: AppManagerService,
        public genericDao: GenericDao,
        public imageDocService: ImageDocService) {
    }

    async getDocumentLastUpdateDate(docId: string) {
        // let doc = await this.
    }

    async getTestCaseCount(vesselId: string) {
        var result: number = 0;
        var testCaseArray: any = [];
        testCaseArray = await this.testCaseService.findVesselTestCases(vesselId);
        if (testCaseArray) {
            result = testCaseArray.length;
        }
        return result;
    }

    public getRevStatusMsg(revStatus: number, isApprover: boolean, isReviewer: boolean): string {
        if (revStatus == RevStatus.APPROVAL_DENIED && !isApprover && !isReviewer) {
            return Constants.REV_STATUS_STRING["61"];
        }
        return Constants.REV_STATUS_STRING[revStatus.toString()];
    }

    public getVesselStatusDisplay(vesselStatus: number, isGeneralDetailsPage?: boolean): string {
        // if (vesselStatus == VesselStatusEnum.TRIAL_CLOSED && isGeneralDetailsPage) {
        //     return Constants.VESSEL_STATUS_STRING["141"];
        // }
        return Constants.VESSEL_STATUS_STRING[vesselStatus.toString()];;
    }

    public getVesselShortStatusMsg(vesselStatus: VesselStatus): string {
        let msg = Constants.IN_PROGRESS_SHORT_MSG;
        if (vesselStatus) {
            if (vesselStatus.status == VesselStatusEnum.OBT_NOT_STARTED || vesselStatus.status == VesselStatusEnum.OBT_IN_PROGRESS) {
                msg = Constants.STATUS_ONBOARD_SHORT_MSG;
            }
            else if (vesselStatus.latestRev && vesselStatus.status >= VesselStatusEnum.REV_DRAFT && vesselStatus.status <= VesselStatusEnum.REV_APPROVAL_ACCEPTED) {
                msg = this.getRevDisplayName(vesselStatus.latestRev.revNumber);
            }
        }
        return msg;
    }

    public getTestCaseService() {
        return this.testCaseService;
    }

    public getAnnualTrialService() {
        return this.annualTrialService;
    }

    public getRevDisplayName(revNo: number): string {
        if (revNo == Constants.REV_0_VAL) {
            return "0";
        }
        else
            return String.fromCharCode(Constants.REV_OFFSET_UNICODE_VAL + revNo);
    }

    public canUserEditInfo(isExpert: boolean, isTrialResp: boolean, vesselStatus: VesselStatus, onboardSection: boolean, extraInfo?: any): boolean {
        if (this.appManagerService.isHistory()) {
            return false;
        }
        if (((vesselStatus.activeRevApprove && vesselStatus.activeRevApprove.status == ReviewStatusEnum.PENDING) ||
            (vesselStatus.activeRevReview && vesselStatus.activeRevReview.status == ReviewStatusEnum.PENDING))) {
            return false;
        }
        else {
            if (isExpert && !isTrialResp) {
                if (vesselStatus.annualTrialStatus && vesselStatus.annualTrialStatus >= TrialStatus.ONBOARD) {
                    if (extraInfo && vesselStatus.annualTrialStatus < TrialStatus.REV_0_IN_PROGRESS && extraInfo.pageName == Constants.PAGE_NAME.FINDINGS_CLOSING
                        && (StringUtils.isNotBlank(extraInfo.endLetterId) && StringUtils.isNotBlank(extraInfo.provLetterId))) {
                        return onboardSection;
                    }
                    return false;
                }
                return !onboardSection;
            }
            if (isTrialResp) {
                if (Constants.DUMMY_ENV_CHANGES) {
                    return true;
                }
                if (vesselStatus.annualTrialStatus && vesselStatus.annualTrialStatus >= TrialStatus.ONBOARD
                    && vesselStatus.annualTrialStatus < TrialStatus.REV_0_FINAL) {
                    return true;
                }
                return false;
            }
        }
        return false;
    }

    public async getVesselStatus(vesselId: string, refresh: boolean, vessel?: Vessel): Promise<VesselStatus> {
        if (!vessel) {
            vessel = await this.vesselService.getVessel(vesselId);
        }
        let vesselStatusDoc = null;

        if (StringUtils.isNotBlank(vessel.vesselStatusId)) {
            vesselStatusDoc = await this.vesselService.getVesselStatusDoc(vessel.vesselStatusId);
        }
        if (!vesselStatusDoc || refresh) {
            let vesselStatus = await this.getVesselStatusModel(vesselId);
            if (vesselStatusDoc) {
                vesselStatus._id = vesselStatusDoc._id;
                vesselStatusDoc = await this.vesselService.updateVesselStatusDoc(vesselStatus);
            }
            else {
                vesselStatusDoc = await this.vesselService.createVesselStatusDoc(vesselStatus);

                vessel.vesselStatusId = vesselStatusDoc._id;
                vessel = await this.vesselService.updateVessel(vessel);
            }
        }
        return vesselStatusDoc;
    }

    public async getVesselStatusModel(vesselId: string, vessel?: Vessel): Promise<VesselStatus> {
        let vesselStatus: VesselStatus = new VesselStatus();
        vesselStatus.status = VesselStatusEnum.NOT_FOUND;
        if (!vessel) {
            vessel = await this.vesselService.getVessel(vesselId);
        }
        if (vessel) {
            vesselStatus.status = vessel.vesselDetailsComplete ? VesselStatusEnum.CREATED : VesselStatusEnum.DRAFT;
            vesselStatus.vesselName = vessel.vesselName;
            vesselStatus.imoNo = vessel.imoNo;
            vesselStatus.vesselType = vessel.vesselType;
            vesselStatus.imageId = vessel.imageId;
            if (StringUtils.isNotBlank(vessel.imageId)) {
                vesselStatus.imageKey = await this.imageDocService.getImageOrDocKey(vessel.imageId);
            }
            vesselStatus.actualDPResponsiblePerson = vessel.actualDPResponsiblePerson;
            if (StringUtils.isNotBlank(vessel.uid)) {
                vesselStatus.vesselId = vessel.uid;
            }
            else {
                vesselStatus.vesselId = vessel._id;
            }

            let testCaseCount = await this.getTestCaseCount(vessel.uid);

            if (testCaseCount > 0) {
                vesselStatus.status = VesselStatusEnum.TEST_CASE_CREATED;
            }
            if (StringUtils.isNotBlank(vessel.activeAnnualTrialsId)) {
                vesselStatus.annualTrialId = vessel.activeAnnualTrialsId;
                vesselStatus = await this.updateVesselStatusModel(vesselStatus);
            }
            else if (vessel.annualTrials && vessel.annualTrials.length > 0) {
                vesselStatus.status = VesselStatusEnum.TRIAL_CLOSED;
            }
        }
        vesselStatus.statusMsg = this.getVesselStatusDisplay(vesselStatus.status, false);//isGeneralDetailsPage
        vesselStatus.statusMsgGeneralDetails = this.getVesselStatusDisplay(vesselStatus.status, true);
        vesselStatus.shortStatusMsg = this.getVesselShortStatusMsg(vesselStatus);
        return vesselStatus;
    }

    public async updateVesselStatusModel(vesselStatus: VesselStatus): Promise<VesselStatus> {
        if (!vesselStatus) {
            return null;
        }
        let annualTrial: AnnualTrials = await this.annualTrialService.getAnnualTrials(vesselStatus.annualTrialId);
        if (annualTrial) {
            vesselStatus.actualTrialResponsiblePerson = annualTrial.actualTrialResponsiblePerson;
            vesselStatus.annualTrialStatus = annualTrial.annualTrialStatus;
            vesselStatus.dpResponsiblePerson = annualTrial.dpResponsiblePerson;
            vesselStatus.trialsResponsiblePersonsOnboard = annualTrial.trialsResponsiblePersonsOnboard;
            switch (annualTrial.annualTrialStatus) {
                case TrialStatus.CLOSED:
                    vesselStatus.status = VesselStatusEnum.TRIAL_CLOSED;
                    break;
                case TrialStatus.ONBOARD:
                    vesselStatus.status = VesselStatusEnum.OBT_NOT_STARTED;
                    vesselStatus = await this.updateVesselOnboardStatus(vesselStatus);//This will set OBT IN PROGRESS
                    break;
                case TrialStatus.NOT_STARTED:
                    vesselStatus.status = annualTrial.annualTrialDetailsComplete ? VesselStatusEnum.TRIAL_CREATED : VesselStatusEnum.TRIAL_DRAFT;
                    break;
                case TrialStatus.REV_0_IN_PROGRESS:
                case TrialStatus.REV_A_IN_PROGRESS:
                case TrialStatus.REV_B_IN_PROGRESS:
                case TrialStatus.REV_C_IN_PROGRESS:
                case TrialStatus.REV_A_FINAL:
                case TrialStatus.REV_B_FINAL:
                case TrialStatus.REV_C_FINAL:
                case TrialStatus.REV_0_FINAL:
                    vesselStatus.status = VesselStatusEnum.REV_DRAFT;
                    let latestRev = await this.annualTrialService.getLatestTrialRev(vesselStatus.annualTrialId);
                    if (latestRev) {
                        vesselStatus.latestRev = latestRev;
                        switch (latestRev.revStatus) {
                            case RevStatus.GENERATED:
                                vesselStatus.status = VesselStatusEnum.REV_DRAFT;
                                break;
                            case RevStatus.NOT_STARTED:
                                vesselStatus.status = VesselStatusEnum.REV_DRAFT;//????
                                break;
                            case RevStatus.REVIEW_DENIED:
                                vesselStatus.status = VesselStatusEnum.REV_REVIEW_DENIED;
                                break;
                            case RevStatus.APPROVAL_DENIED:
                                vesselStatus.status = VesselStatusEnum.REV_APPROVAL_DENIED;
                                vesselStatus.activeRevReview = this.annualTrialService.getLatestReviewer(latestRev);
                                break;
                            case RevStatus.APPROVAL_ACCEPTED:
                                vesselStatus.status = VesselStatusEnum.REV_APPROVAL_ACCEPTED;
                                break;
                            case RevStatus.REVIEW_ACCEPTED:
                                vesselStatus.status = VesselStatusEnum.REV_REVIEW_ACCEPTED;
                                break;
                            case RevStatus.SENT_FOR_REVIEW:
                                vesselStatus.status = VesselStatusEnum.REV_REVIEW_PROGRESS;
                                vesselStatus.activeRevReview = this.annualTrialService.getLatestReviewer(latestRev);
                                break;
                            case RevStatus.SENT_FOR_APPROVAL:
                                vesselStatus.status = VesselStatusEnum.REV_APPROVAL_PROGRESS;
                                vesselStatus.activeRevApprove = this.annualTrialService.getLatestApprover(latestRev);
                                break;
                            default:
                                break;
                        }

                    }
                    break;
                default:
                    break;
            }
        }
        return vesselStatus;
    }


    public async updateVesselOnboardStatus(vesselStatus: VesselStatus): Promise<VesselStatus> {
        if (!vesselStatus) {
            return null;
        }
        if (vesselStatus.status != VesselStatusEnum.OBT_NOT_STARTED) {//Function just for OBT NOT started status
            return vesselStatus;
        }
        //Status Report
        let statusReportStatus = SectionStatus.PENDING;
        let statusReport = await this.statusReportService.getCurrentStatusReportForAnnualTrial(vesselStatus.annualTrialId);
        if (statusReport) {
            statusReportStatus = await this.statusReportService.getStatusOfCurrentStatusReportForAnnualTrial(vesselStatus.annualTrialId, statusReport);
            // if (statusReportStatus != SectionStatus.PENDING) {//Manoj Optimize later
            //     vesselStatus.status = VesselStatusEnum.OBT_IN_PROGRESS;
            //     return vesselStatus;
            // }
        }

        let statusReportCompleted = statusReportStatus == SectionStatus.COMPLETED;

        //Env Conditions and Key Personnel
        let envKeyPersonnel = await this.envCondPersonnelService.getCurrentEnvCondKeyPersonnelForAnnualTrial(vesselStatus.annualTrialId);
        let envKeyPersonnelStatus = SectionStatus.PENDING;
        if (envKeyPersonnel) {
            envKeyPersonnelStatus = await this.envCondPersonnelService.getStatusOfCurrentEnvCondKeyPersonnelForAnnualTrial(vesselStatus.annualTrialId, envKeyPersonnel);
            // this.envConditionCompleted = (envKeyPersonnel.envConditionCompleted && envKeyPersonnel.keyPersonnelCompleted);
        }
        let envCondKeyPersonnelCompleted = envKeyPersonnelStatus == SectionStatus.COMPLETED;

        //Test Case Execution completed
        let minTestCaseExecCompleted = await this.testCaseService.anyTestCaseExecCompleted(vesselStatus.annualTrialId);
        let testCaseExecCompleted = await this.testCaseService.isAllTestCaseExecCompletedOrNotExec(vesselStatus.annualTrialId);

        //Trials Conclusion completed
        let trialConclusion = await this.trialConclusionService.getCurrentTrialConclusionForAnnualTrial(vesselStatus.annualTrialId);
        let trialConclusionStatus = SectionStatus.PENDING;
        if (trialConclusion) {
            trialConclusionStatus = await this.trialConclusionService.getStatusOfCurrentTrialConclusionForAnnualTrial(vesselStatus.annualTrialId, trialConclusion);
        }
        let trialsConclusionCompleted = trialConclusionStatus == SectionStatus.COMPLETED;

        // let enableRevC: boolean = trialsConclusionCompleted && testCaseExecCompleted && statusReportCompleted && envCondKeyPersonnelCompleted;

        let onboardInProgress: boolean = statusReportStatus != SectionStatus.PENDING || envKeyPersonnelStatus != SectionStatus.PENDING ||
            minTestCaseExecCompleted || trialConclusionStatus != SectionStatus.PENDING;

        if (onboardInProgress) {//Check again
            vesselStatus.status = VesselStatusEnum.OBT_IN_PROGRESS;
            return vesselStatus;
        }
        return vesselStatus;
    }

    async getDocumentHistoryData(vesselId: string, annualTrialId: string, revNumber: number, revStatus: RevStatus) {
        this.logger.debug("getDocumentHistoryData: vesselId: " + vesselId + " annualTrialId: " + annualTrialId + " revNumber:" + revNumber + ""+ " revStatus:" + revStatus + "");
        let data: any = {};
        let vessel = await this.vesselService.getVessel(vesselId);
        let annualTrial = await this.annualTrialService.getAnnualTrials(annualTrialId);
        data.trialYear = annualTrial.trialYear;
        data.revisionHistory = await this.getRevisionHistory(annualTrialId, revNumber, data.trialYear, true, revStatus);
        data.onboard = revNumber > Constants.REV_B_VAL;
        data.revChar = this.getRevDisplayName(revNumber);
        data.vesselId = vesselId;
        data.annualTrialId = annualTrialId;
        data.revNumber = revNumber;
        data.vesselName = vessel.vesselName;
        data.imoNo = vessel.imoNo;
        data.vessel = vessel;
        data.annualTrial = annualTrial;
        let findingsAndCloseoutList = await this.findingsAndCloseoutService.findAll(vesselId, annualTrialId);
        if (findingsAndCloseoutList && findingsAndCloseoutList.length > 0) {
            data.findings = findingsAndCloseoutList[0];
        }
        else {
            data.findings = null;
            // data.oldFindings = null;
            // data.newFindings = null;
            // data.findingsAPresent = false;
        }
        if (data.findings) {
            data.amendments = await this.getAmendments(data.findings.uid, revNumber, data.trialYear);
        }
        else {
            data.amendments = [];
        }
        //Amendment pending
        this.logger.debug("getDocumentHistoryData: data: ", data);
        return data;
    }

    async getRevisionData(vesselId: string, annualTrialId: string, revNumber: number) {
        this.logger.debug("getRevisionData: vesselId: " + vesselId + " annualTrialId: " + annualTrialId + " revNumber:" + revNumber + "");
        let data: any = {};
        let vessel = await this.vesselService.getVessel(vesselId);
        let annualTrial = await this.annualTrialService.getAnnualTrials(annualTrialId);
        let clientInfo = await this.annualTrialService.getAnnualTrialsClientInfo(annualTrial.clientInfoId);
        let findingsAndCloseoutList = await this.findingsAndCloseoutService.findAll(vesselId, annualTrialId);
        let vesselMainParticulars = await this.vesselService.getMainParticulars(vessel.mainParticularsId);
        let vesselMainDPEquipment = await this.vesselService.getMainDPEquip(vessel.mainDPEquipmentId);
        let vesselPrincipalDimens = await this.vesselService.getPrincipalDimens(vessel.principalDimensId);
        let vesselDpConfiguration = await this.vesselService.getDPConfig(vessel.dpConfigurationId);
        let vesselImage = await this.imageDocService.getImage(vessel.imageId);
        let vesselDpRedundancyGroupsImage = await this.imageDocService.getImage(vesselMainParticulars.dpRedundancyGroupsImageId);
        let vesselPowerSystemImage = await this.imageDocService.getImage(vesselMainParticulars.powerSystemImageId);
        let vesselGeneralArrangementImage = await this.imageDocService.getImage(vesselMainParticulars.generalArrangementImageId);
        let envKeyPersonnel = await this.envCondPersonnelService.getCurrentEnvCondKeyPersonnelForAnnualTrial(annualTrialId);
        let trialConclusion = await this.trialConclusionService.getCurrentTrialConclusionForAnnualTrial(annualTrialId);
        let statusReport = await this.statusReportService.getCurrentStatusReportForAnnualTrial(annualTrialId);
        let statusEngines: Array<StatusEngine> = [];
        let statusThrusters: Array<StatusThruster> = [];
        let statusCrewPersonnels: Array<StatusCrewPersonnel> = [];

        let statusSwitchBoardDPSystem: StatusSwitchBoardDPSystem = new StatusSwitchBoardDPSystem();
        let statusDpDocuments: StatusDPDocuments = new StatusDPDocuments();//OR NULL?
        if (statusReport) {
            if (statusReport.engineIds && statusReport.engineIds.length > 0) {
                statusEngines = await this.statusReportService.getStatusEngines(statusReport.engineIds);    
            }
            if (statusReport.thrusterIds && statusReport.thrusterIds.length > 0) {
                statusThrusters = await this.statusReportService.getStatusThrusters(statusReport.thrusterIds);
            }
            if (statusReport.crewPersonnelIds && statusReport.crewPersonnelIds.length > 0) {
                statusCrewPersonnels = await this.statusReportService.getStatusCrewPersonnels(statusReport.crewPersonnelIds);
            }
            if (statusReport.dpDocumentsId && StringUtils.isNotBlank(statusReport.dpDocumentsId)) {
                statusDpDocuments = await this.statusReportService.getStatusDPDocuments(statusReport.dpDocumentsId);
            }
            if (statusReport.switchBoardDPSystemId && StringUtils.isNotBlank(statusReport.switchBoardDPSystemId)) {
                statusSwitchBoardDPSystem = await this.statusReportService.getStatusSwitchBoardDPSystem(statusReport.switchBoardDPSystemId);
            }
        }
        let testCasesExec = await this.testCaseService.findAllTestCaseExec(annualTrialId, Constants.SORT_BY.ASC);
        let testCases = await this.testCaseService.findVesselTestCases(vesselId, Constants.SORT_BY.ASC);

        let supportDocs: Array<Image | Doc> = [];
        for (let index = 0; index < annualTrial.supportingDocs.length; index++) {
            const docId = annualTrial.supportingDocs[index];
            if (StringUtils.isNotBlank(docId)) {
                let doc = await this.imageDocService.getImageOrDoc(docId);
                if (doc) {
                    supportDocs.push(doc);
                }
            }
        }

        data.onboard = revNumber > Constants.REV_B_VAL;
        data.revChar = this.getRevDisplayName(revNumber);
        data.vesselId = vesselId;
        data.annualTrialId = annualTrialId;
        data.revNumber = revNumber;
        data.vesselName = vessel.vesselName;
        data.imoNo = vessel.imoNo;
        data.trialYear = annualTrial.trialYear;
        data.vessel = vessel;
        data.annualTrial = annualTrial;
        data.clientInfo = clientInfo;
        data.vesselMainParticulars = vesselMainParticulars;
        data.vesselMainDPEquipment = vesselMainDPEquipment;
        data.vesselPrincipalDimens = vesselPrincipalDimens;
        data.vesselDpConfiguration = vesselDpConfiguration;
        data.supportDocs = supportDocs;
        data.vesselImage = vesselImage;
        data.vesselDpRedundancyGroupsImage = vesselDpRedundancyGroupsImage;
        data.vesselPowerSystemImage = vesselPowerSystemImage;
        data.vesselGeneralArrangementImage = vesselGeneralArrangementImage;
        data.envKeyPersonnel = envKeyPersonnel;
        data.trialConclusion = trialConclusion;
        data.statusReport = statusReport;
        data.statusEngines = statusEngines;
        data.statusThrusters = statusThrusters;
        data.statusCrewPersonnels = statusCrewPersonnels;
        data.statusSwitchBoardDPSystem = statusSwitchBoardDPSystem;
        data.statusDpDocuments = statusDpDocuments;
        data.testCases = testCases;
        data.testCasesExec = testCasesExec;
        data.revisionHistory = await this.getRevisionHistory(annualTrialId, revNumber, data.trialYear, false);
        let datesTrial = this.getTrialStartEndDates(data, "DD/MM/YYYY");
        if (datesTrial && datesTrial.length > 0) {
            let savedStartV = data.annualTrial.startDateTrial;
            let savedEndV = data.annualTrial.endDateTrial;
            data.annualTrial.startDateTrial = datesTrial[0];
            if (datesTrial.length > 1) {
                data.annualTrial.endDateTrial = datesTrial[1];
            }

            if (savedStartV != data.annualTrial.startDateTrial || savedEndV != data.annualTrial.endDateTrial) {
                //Update new date values
                this.logger.debug("Update AnnualTrialDoc with date: Start");
                this.annualTrialService.updateAnnualTrials(data.annualTrial);
                this.logger.debug("Update AnnualTrialDoc with date: End");
            }
        }

        if (findingsAndCloseoutList && findingsAndCloseoutList.length > 0) {
            data.findings = findingsAndCloseoutList[0];
            if (data.findings) {
                let oldAFindings = [];
                let oldBFindings = [];
                let oldCFindings = [];

                let newAFindings = [];
                let newBFindings = [];
                let newCFindings = [];
                if (data.findings.aFindings) {
                    data.findingsAPresent = (data.findings.aFindings && data.findings.aFindings.length > 0);
                }
                if (data.findings.aFindings && data.findings.aFindings.length > 0) {
                    for (let index = 0; index < data.findings.aFindings.length; index++) {
                        const findingStep = data.findings.aFindings[index];
                        if (findingStep) {
                            if (findingStep.fromPreviousTrial) {
                                oldAFindings.push(findingStep);
                            }
                            else {
                                newAFindings.push(findingStep);
                            }
                        }
                    }
                }
                if (data.findings.bFindings && data.findings.bFindings.length > 0) {
                    for (let index = 0; index < data.findings.bFindings.length; index++) {
                        const findingStep = data.findings.bFindings[index];
                        if (findingStep) {
                            if (findingStep.fromPreviousTrial) {
                                oldBFindings.push(findingStep);
                            }
                            else {
                                newBFindings.push(findingStep);
                            }
                        }
                    }
                }
                if (data.findings.cFindings && data.findings.cFindings.length > 0) {
                    for (let index = 0; index < data.findings.cFindings.length; index++) {
                        const findingStep = data.findings.cFindings[index];
                        if (findingStep) {
                            if (findingStep.fromPreviousTrial) {
                                oldCFindings.push(findingStep);
                            }
                            else {
                                newCFindings.push(findingStep);
                            }
                        }
                    }
                }

                if (oldAFindings.length > 0 || oldBFindings.length > 0 || oldCFindings.length > 0) {
                    data.oldFindings = {};
                    data.oldFindings.aFindings = oldAFindings;
                    data.oldFindings.bFindings = oldBFindings;
                    data.oldFindings.cFindings = oldCFindings;
                }
                else {
                    data.oldFindings = null;
                }
                data.newFindings = {};
                data.newFindings.aFindings = newAFindings;
                data.newFindings.bFindings = newBFindings;
                data.newFindings.cFindings = newCFindings;
            }
        }
        else {
            data.findings = null;
            data.oldFindings = null;
            data.newFindings = null;
            data.findingsAPresent = false;
        }
        if (data.findings) {
            data.amendments = await this.getAmendments(data.findings.uid, revNumber, data.trialYear);
        }
        else {
            data.amendments = [];
        }

        //Amendment pending

        this.logger.debug("getRevisionData: data: ", data);
        return data;
    }

    getFindingDescriptionFormatted(finding: any) {
        let testNumber = "-";
        if (finding.testNumber) {
            testNumber = "" + finding.testNumber;
        }
        let stepNumber = "-";
        if (finding.stepNumber || finding.stepNumber == 0) {
            stepNumber = 1 + finding.stepNumber + "";
        }

        let content = sprintf(ReportConst.REPORT_MSGS.F_TEST_NO_STEP_NO, testNumber, finding.testName, stepNumber, finding.testDesc);
        if (finding.isAdditionalFinding) {
            content = sprintf(ReportConst.REPORT_MSGS.F_ADDITIONAL_FINDING, finding.testName, finding.testDesc);
        }
        return content;
    }

    /**Only for closed ones */
    async getFindingAttachments(findings) {
        this.logger.debug("getFindingAttachments: Start:", findings);

        let findingDescs: any = [];
        if (findings && findings.length > 0) {
            for (let index = 0; index < findings.length; index++) {
                const finding = findings[index];
                if (finding && finding.testStatus == Constants.FINDING_CLOSED) {
                    let obj: any = {};
                    let supportDocs: Array<Image | Doc> = [];
                    obj.content = this.getFindingDescriptionFormatted(finding);
                    if (finding.imageDocIds && finding.imageDocIds.length > 0) {
                        for (let i = 0; i < finding.imageDocIds.length; i++) {
                            const docId = finding.imageDocIds[i];
                            if (StringUtils.isNotBlank(docId)) {
                                let doc = await this.imageDocService.getImageOrDoc(docId);
                                if (doc) {
                                    supportDocs.push(doc);
                                }
                            }
                        }
                    }
                    obj.supportDocs = supportDocs;
                    findingDescs.push(obj);
                }
            }
        }
        this.logger.debug("getFindingAttachments: findingDescs:", findingDescs);
        return findingDescs;
    }

    public getFindingStatus(status: StepExecStatus): string {
        return Constants.FINDINGS_STATUS[status];
    }

    getNameInitials(name: string): string {
        if (StringUtils.isBlank(name)) {
            return "";
        }
        name = name.trim();
        let result = "";
        let split = name.split(" ");
        if (split && split.length > 0) {
            for (let index = 0; index < split.length; index++) {
                const element = split[index];
                if (StringUtils.isNotBlank(element)) {
                    result = result + element.substr(0, 1);
                }
            }
        }
        return result.toUpperCase();
    }

    getRevisionDescription(revNumber: number, trialYear: string) {
        let description = "Draft for internal review";
        if (revNumber == Constants.REV_A_VAL) {
            description = "Draft for internal review";
        }
        else if (revNumber == Constants.REV_B_VAL) {
            description = "For use in trials";
        }
        else if (revNumber == Constants.REV_0_VAL) {//NOT requied
            description = "Final issue";
        }
        else if (revNumber == Constants.REV_B_VAL + 1) {
            description = "Updated following " + StringUtils.getEmptyForNull(trialYear, "-") + " DP Annual Trials ";
        }
        else {
            description = "Updated report (refer to Amendment record)";
        }
        return description;
    }

    async getRevision(rev: TrialRev, trialYear: string, useRevStatus: boolean, revStatus: RevStatus) {
        let revision: any = {};
        revision.rev = this.getRevDisplayName(rev.revNumber);
        let createdDate = rev.createdDate;
        let doc = null;
        if (StringUtils.isNotBlank(rev.docId)) {
            doc = await this.imageDocService.getDoc(rev.docId);
        }
        if (doc && doc.cdt) {
            createdDate = doc.cdt;
        }
        revision.date = this.appManagerService.getFormattedTimeStamp(createdDate, Constants.DATE_FORMAT.DATE_4_ANGULAR);
        revision.description = this.getRevisionDescription(rev.revNumber, trialYear);

        let creatorFullName = rev.createdByUser.fullName;
        if (StringUtils.isBlank(rev.createdByUser.fullName)) {
            creatorFullName = rev.createdByUser.firstName + " " + rev.createdByUser.lastName;
        }
        revision.by = this.getNameInitials(creatorFullName);
        revision.reviewed = "-";
        revision.approved = "-";
        if (useRevStatus) {
            if (rev.reviewers && rev.reviewers.length > 0) {
                let reviewer = rev.reviewers[rev.reviewers.length - 1];
                if (reviewer) {
                    if (revStatus == RevStatus.REVIEW_ACCEPTED) {
                        if (reviewer.status != ReviewStatusEnum.DENY) {//Can be removed?
                            revision.reviewed = this.getNameInitials(reviewer.user.fullName);    
                        }
                    }
                    else {
                        if (reviewer.status == ReviewStatusEnum.ACCEPT) {
                            revision.reviewed = this.getNameInitials(reviewer.user.fullName);    
                        }
                    }    
                }
            }
            if (rev.approvers && rev.approvers.length > 0) {
                let reviewer = rev.approvers[rev.approvers.length - 1];
                if (reviewer) {
                    if (revStatus > RevStatus.APPROVAL_ACCEPTED) {
                        if (reviewer.status != ReviewStatusEnum.DENY) {//Can be removed?
                            revision.approved = this.getNameInitials(reviewer.user.fullName);    
                        }
                    }
                    else {
                        if (reviewer.status == ReviewStatusEnum.ACCEPT) {
                            revision.approved = this.getNameInitials(reviewer.user.fullName);    
                        }
                    }    
                }
            }
        }
        else {
            if (rev.reviewers && rev.reviewers.length > 0) {
                let reviewer = rev.reviewers[rev.reviewers.length - 1];
                if (reviewer && reviewer.status == ReviewStatusEnum.ACCEPT) {
                    revision.reviewed = this.getNameInitials(reviewer.user.fullName);
                }
            }
            if (rev.approvers && rev.approvers.length > 0) {
                let reviewer = rev.approvers[rev.approvers.length - 1];
                if (reviewer && reviewer.status == ReviewStatusEnum.ACCEPT) {
                    revision.approved = this.getNameInitials(reviewer.user.fullName);
                }
            }
        }
        
        return revision;
    }

    getAmendment(revNumber: number, amendmentRecords: string[]) {
        let description = "";
        if (amendmentRecords && amendmentRecords.length > 0) {
            for (let index = 0; index < amendmentRecords.length; index++) {
                const element = amendmentRecords[index];
                description = description + "" + element;
                if (index < amendmentRecords.length - 1) {
                    description = description + Constants.STRING_SEPARATOR_2;
                }
            }
        }
        let amendment = {
            rev: this.getRevDisplayName(revNumber),
            description: description
        }
        return amendment;
    }
    async getAmendments(findingsId: string, revNumber: number, trialYear: string) {
        let amendments = [];
        if (revNumber > Constants.REV_B_VAL) {
            let maxCPlusRev: number = Constants.REV_MAX_VAL;
            if (revNumber == Constants.REV_0_VAL) {
                maxCPlusRev = Constants.REV_MAX_VAL;
            }
            else {
                maxCPlusRev = revNumber;
            }
            for (let index = Constants.REV_B_VAL + 1; index <= maxCPlusRev; index++) {
                let findingHistory = await this.findingsAndCloseoutService.findLatestHistoryWithRev(findingsId, index);
                if (findingHistory) {
                    amendments.push(this.getAmendment(index, findingHistory.amendmentRecords));
                }
                else {
                    break;//No point in contiuing if any one is not found;
                }
            }
            if (revNumber == Constants.REV_0_VAL) {
                let findingHistory = await this.findingsAndCloseoutService.findLatestHistoryWithRev(findingsId, revNumber);
                if (findingHistory) {
                    amendments.push(this.getAmendment(revNumber, findingHistory.amendmentRecords));
                }
            }
            // if (revNumber == Constants.REV_B_VAL + 1) {
            //     let amendment = {
            //         rev: this.getRevDisplayName(revNumber),
            //         description: "Test results from DP Annual Trials " + StringUtils.getEmptyForNull(trialYear, "-") + " included."
            //     }
            //     amendments.push(this.getAmendment(revNumber, resultDescription));
            // }
            //TODO add more
        }
        return amendments;
    }

    async getRevisionHistory(annualTrialId: string, revNumber: number, trialYear: string, useRevStatus: boolean, revStatus?: RevStatus) {
        let revisions = [];
        let revision0 = null;
        let finalTrialRev: TrialRev = await this.annualTrialService.getLatestTrialRev(annualTrialId);
        let addNewRevision = true;
        let useRevStatusForMaxC = useRevStatus;
        if (finalTrialRev) {
            let maxCPlusRev: number = Constants.REV_MAX_VAL;
            if (finalTrialRev.revNumber == Constants.REV_0_VAL) {
                maxCPlusRev = Constants.REV_MAX_VAL;
                revision0 = finalTrialRev;
                useRevStatusForMaxC = false;
            }
            else {
                maxCPlusRev = finalTrialRev.revNumber;
            }
            for (let index = Constants.REV_A_VAL; index <= maxCPlusRev; index++) {
                const rev = await this.annualTrialService.getLatestTrialWithRev(annualTrialId, index);
                if (rev) {
                    let revision = await this.getRevision(rev, trialYear, index == maxCPlusRev ? useRevStatusForMaxC : false, revStatus);
                    revisions.push(revision);
                }
            }
            if (revision0) {
                let revision = await this.getRevision(revision0, trialYear, useRevStatus, revStatus);
                revisions.push(revision);
            }
            if (finalTrialRev.revNumber == revNumber) {
                addNewRevision = false;
            }
        }
        // else {
        if (addNewRevision) {
            let revision: any = {};
            revision.rev = this.getRevDisplayName(revNumber);
            revision.date = this.appManagerService.getFormattedTimeStamp(new Date(), Constants.DATE_FORMAT.DATE_4_ANGULAR);
            revision.by = this.getNameInitials(this.appManagerService.getUserFullName());
            revision.description = this.getRevisionDescription(revNumber, trialYear);
            revision.reviewed = "-";
            revision.approved = "-";
            revisions.push(revision);
        }

        // }
        return revisions;
    }

    getEnvCondKeyPersonnel(keyPersonnels: Array<KeyPersonnel>, roleType: KeyPersonnelRole): KeyPersonnel {
        if (keyPersonnels && keyPersonnels.length > 0) {
            for (let index = 0; index < keyPersonnels.length; index++) {
                const keyPersonnel = keyPersonnels[index];
                if (keyPersonnel && keyPersonnel.roleType == roleType) {
                    return keyPersonnel;
                }
            }
        }
        return null;
    }

    getEnvCondKeyPersonnelName(keyPersonnels: Array<KeyPersonnel>, roleType: KeyPersonnelRole): string {
        let name = "";
        let keyPersonnel = this.getEnvCondKeyPersonnel(keyPersonnels, roleType);
        if (keyPersonnel) {
            name = keyPersonnel.name;
        }
        return name;
    }

    getEnvCondKeyPersonnels(keyPersonnels: Array<KeyPersonnel>, roleType: KeyPersonnelRole): Array<KeyPersonnel> {
        let result: Array<KeyPersonnel> = [];
        if (keyPersonnels && keyPersonnels.length > 0) {
            for (let index = 0; index < keyPersonnels.length; index++) {
                const keyPersonnel = keyPersonnels[index];
                if (keyPersonnel && keyPersonnel.roleType == roleType) {
                    result.push(keyPersonnel);
                }
            }
        }
        return result;
    }

    getEnvCondKeyPersonnelNames(keyPersonnels: Array<KeyPersonnel>, roleType: KeyPersonnelRole): string {
        let result: Array<KeyPersonnel> = this.getEnvCondKeyPersonnels(keyPersonnels, roleType);
        let names: Array<string> = [];
        if (result && result.length > 0) {
            for (let index = 0; index < result.length; index++) {
                const element = result[index];
                names.push(element.name);
            }
        }
        return StringUtils.getReadStringForList(names);
    }

    getActualTrialRespPersonNames(annualTrial: AnnualTrials) {
        let result = [];
        if (annualTrial.actualTrialResponsiblePerson) {
            result.push(annualTrial.actualTrialResponsiblePerson.fullName);
        }
        // return result;
        return StringUtils.getReadStringForList(result);

    }

    getListOfOnboardPersons(annualTrial: AnnualTrials) {
        let result = [];
        let addActualTrialPerson = true;
        let actualTrialPersonUsername = "";
        if (annualTrial.actualTrialResponsiblePerson) {
            actualTrialPersonUsername = annualTrial.actualTrialResponsiblePerson.username;
        }
        if (annualTrial.trialsResponsiblePersonsOnboard && annualTrial.trialsResponsiblePersonsOnboard.length > 0) {
            for (let index = 0; index < annualTrial.trialsResponsiblePersonsOnboard.length; index++) {
                const element = annualTrial.trialsResponsiblePersonsOnboard[index];
                if (element) {
                    let user = element.userBasic;
                    if (user) {
                        result.push(user.fullName);
                        if (StringUtils.isNotBlank(actualTrialPersonUsername) && StringUtils.isNotBlank(user.username) && actualTrialPersonUsername === user.username) {
                            addActualTrialPerson = false;
                        }
                    }
                }
            }
        }
        if (addActualTrialPerson && annualTrial.actualTrialResponsiblePerson) {
            result.push(annualTrial.actualTrialResponsiblePerson.fullName);
        }
        return result;
    }

    getOnboardPersonNames(annualTrial: AnnualTrials): string {
        let onboardUsers = this.getListOfOnboardPersons(annualTrial);
        return StringUtils.getReadStringForList(onboardUsers);
    }


    getStatusFieldValue(val: StatusField): string {
        let status = "";
        if (val) {
            status = Constants.STATUS_FIELD_STRING[val];
        }
        return status;
    }

    getAnalysisResultValue(val: AnalysisResult): string {
        let status = "";
        if (val) {
            status = Constants.ANALYSIS_RESULT_STRING[val];
        }
        return status;
    }

    async getGenericDocMDTs(arrayToFetch: string[]) {
        let result: Array<number> = [];
        for (let index = 0; index < arrayToFetch.length; index++) {
            const element = arrayToFetch[index];
            if (StringUtils.isNotBlank(element)) {
                let doc = await this.genericDao.get(element);
                if (doc) {
                    result.push(doc.mdt);
                }
            }
        }
        return result;
    }

    getDPResponsiblePersonName(vessel: Vessel): string {
        if (vessel && vessel.actualDPResponsiblePerson) {
            let name = vessel.actualDPResponsiblePerson.fullName;
            if (StringUtils.isBlank(name)) {
                name = vessel.actualDPResponsiblePerson.firstName + " " + vessel.actualDPResponsiblePerson.lastName;
            }
            return name;
        }
        return "";
    }

    getOnboardTrialPersonName(annualTrial: AnnualTrials): string {
        if (annualTrial && annualTrial.actualTrialResponsiblePerson) {
            let name = annualTrial.actualTrialResponsiblePerson.fullName;
            if (StringUtils.isBlank(name)) {
                name = annualTrial.actualTrialResponsiblePerson.firstName + " " + annualTrial.actualTrialResponsiblePerson.lastName;
            }
            return name;
        }
        return "";
    }
    
  getTrialStartEndDates(data: any, stringFormat?: string) {
    let result = [];
    let dates = []
    
    if (data.testCases && data.testCases.length > 0) {
        let testCaseExecStarted = data.testCasesExec && data.testCasesExec.length > 0;
        for (let index = 0; index < data.testCases.length; index++) {
            // for (let index = 0; index < 1; index++) {//TODO: MANOJ UNCOMMENT
            const testCase = data.testCases[index];
            if (testCase) {
                let currentTestCaseExec = null;
                if (testCaseExecStarted) {
                    let testCaseExecList = _.filter(data.testCasesExec, { testCaseId: testCase.uid });
                    if (testCaseExecList && testCaseExecList.length > 0) {
                        currentTestCaseExec = testCaseExecList[0];
                        let executeDate = currentTestCaseExec.executeCompleteDate > 0 ? currentTestCaseExec.executeCompleteDate : 0;
                        if (StringUtils.getEmptyForNull(currentTestCaseExec.executeDate, "").length > 0) {
                            executeDate = DateUtils.getMilliFromStringDate(currentTestCaseExec.executeDate);
                        }
                        if (executeDate > 0) {
                            result.push(executeDate);    
                        }
                    }
                }

            }
        }
    }
    this.logger.info("getTrialStartEndDates: tstcases: ", result);
    if (result.length > 0) {
        result = _.sortBy(result);
        if (StringUtils.isNotBlank(stringFormat)) {
            dates.push(DateUtils.formatDate(new Date(result[0]), stringFormat))
            dates.push(DateUtils.formatDate(new Date(result[result.length - 1]), stringFormat))
        }
        else {
            dates.push(result[0]);
            dates.push(result[result.length - 1]);
        }
    }
    this.logger.info("getTrialStartEndDates: ", dates);
    return dates;
  }
}
