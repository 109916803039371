import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { EnvCondKeyPersonnel } from 'src/app/models';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";


@Injectable()
export class EnvCondPersonnelDao extends AbstractDao<EnvCondKeyPersonnel> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.ENV_COND_PERSONNEL, { create: true, delete: true, update: true }, "EnvCondPersonnelDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {EnvCondKeyPersonnel} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: EnvCondKeyPersonnel): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}