import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';

export class TrialConclusion extends AbstractDocument {
    public vesselId: string;
    public annualTrialId: string;
    public mMaintenance: string;
    public dpMaintenance: string;
    public pGeneration: string;
    public pdistribution: string;
    public pManagement: string;
    public cLoops: string;
    public eSensors: string;
    public pReferences: string;
    public dpControl: string;
    public personnel: string;
    public incidents: string;


    
    // public machineryMaintenance: string;
    // public dpControlMaintenance: string;
    // public powerGeneration: string;
    // public powerDistribution: string;
    // public powerManagement: string;
    // public controlLoops: string;
    // public envHeadingSensors: string;
    // public positionReferences: string;

    public completed: boolean;
    type = Constants.TYPES.TRIAL_CONCLUSION;
}