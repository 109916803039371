import { Injectable, Injector } from '@angular/core';
//MODELS
import { AnnualTrialsClientInfo } from "../../models/annual-trial-client-info";
import { AnnualTrials } from '../../models/annual-trial';
import { Image } from '../../models/image';
import { Doc } from '../../models/doc';
//SERVICE
import { AnnualTrialsService } from "../service/annual-trials/annual-trials.service";
import { ImageDocService } from "../service/image-doc/image-doc.service";
import { AppManagerService, QualifService, LoadingService } from '.';
import { AbstractService } from './abstract-service';
import * as _ from 'lodash';
import { UserTypeEnum } from 'src/app/models/enums';
import { FileUpload } from '../../models';
import { Constants } from "../../app.constants";

@Injectable({
  providedIn: 'root'
})
export class CreateAnnualSubService extends AbstractService {

  annualtrialModel: AnnualTrials;
  annualClientModel: AnnualTrialsClientInfo;
  annualId: any;
  activeAnnualId: any;
  clientId: any;
  docModel: Doc;
  imageModel: Image;
  docId: any;
  imgId: any;
  supportDocFile: any;
  isAnnualCreationProcessContinue: boolean;


  vesselMandatoryComplete: boolean = false;

  annualMandatoryComplete: Boolean = false;
  cilentInfoMandatoryComplete: Boolean = false;

  annualEditCreateMode: string;
  annualHistoryId: string;
  public generalDetailsData: AnnualTrials;
  public clientInfoData: AnnualTrialsClientInfo;
  public supportingDocData: Array<FileUpload>;

  constructor(protected injector: Injector, private annualService: AnnualTrialsService, private appManager: AppManagerService,
    private docService: ImageDocService, private qualifService: QualifService, private loadingService : LoadingService) {
    super(injector, "CreateAnnualSubService");
  }

  //COMMON 
  setAnnualMandatoryComplete(isComplete) {
    this.annualMandatoryComplete = isComplete;
  }
  setCilentInfoMandatoryComplete(isComplete) {
    this.cilentInfoMandatoryComplete = isComplete;
  }

  getMandatoryCompleteStatus() {
    let mandatoryCompleteStatus = {
      annualMandatoryComplete: this.annualMandatoryComplete,
      cilentInfoMandatoryComplete: this.cilentInfoMandatoryComplete,
    }

    return mandatoryCompleteStatus;
  }
  resetMandatoryFields() {
    this.annualMandatoryComplete = false;
    this.cilentInfoMandatoryComplete = false;
    // this.supportDocMandatoryComplete = false; 
  }

  getAnnualMode() {
    return this.annualEditCreateMode;
  }

  setAnnualMode(mode) {
    this.annualEditCreateMode = mode;
  }

  //ANNUAL TRIAL DETAILS
  setAnnual(annualData) {
    this.annualtrialModel = annualData;
  }

  async getAnnual() {
    this.annualtrialModel = await this.annualService.getAnnualTrials(this.annualId);
    return this.annualtrialModel;
  }

  getAnnualId() {
    return this.annualId;
  }
  setAnnualId(annualId) {
    this.annualId = annualId;
  }

  public getAnnualHistoryId() {
    return this.annualHistoryId;
  }
  public setAnnualHistoryId(annualId: string) {
    this.annualHistoryId = annualId;
  }

  public async getAnnualTrialById(annualTrialId: string): Promise<AnnualTrials> {
    return await this.annualService.getAnnualTrials(annualTrialId);
  }

  getActiveAnnualTrialId() {
    return this.activeAnnualId;
  }
  setActiveAnnualTrialId(activeAnnualId) {
    this.activeAnnualId = activeAnnualId;
  }
  getAnnualCreationProcessContinue() {
    return this.isAnnualCreationProcessContinue;
  }
  setAnnualCreationProcessContinue(isOngoing) {
    this.isAnnualCreationProcessContinue = isOngoing;
  }

  //CLIENT-INFO 
  setClientInfo(annualData) {
    this.annualClientModel = annualData;
  }
  async getClientInfo() {
    this.annualClientModel = await this.annualService.getAnnualTrialsClientInfo(this.clientId);
    return this.annualtrialModel;
  }

  getClientId() {
    return this.clientId;
  }
  setClientId(clientId) {
    this.clientId = clientId;
  }

  //SUPPORT DOCUMENT 

  getSupportDocId() {
    return this.docId;
  }
  setSupportDocId(docId) {
    this.docId = docId;
  }
  setSupportDoc(supportDocData) {
    this.docModel = supportDocData;
  }

  async getSupportDoc() {
    this.docModel = await this.docService.getDoc(this.docId);
    return this.docModel;
  }
  //SUPPORT IMAGES
  setSupportImg(supportImgData) {
    this.imageModel = supportImgData;
  }

  getSupportImgId() {
    return this.imgId;
  }
  setSupportImgId(imgId) {
    this.imgId = imgId;
  }

  async getSupportImg() {
    this.imageModel = await this.docService.getImage(this.imgId);
    return this.imageModel;
  }

  async fetchAnnualMandatoryCompletion(annualId) {  //use to fetch already created ship mandatory status when clicked on edit
    let mandatoryObj = [];
    if (annualId != undefined) {

      let annualObj = this.getAnnualData();
      if (!annualObj) {
        annualObj = await this.annualService.getAnnualTrials(annualId);
      }
      let annualMandatoryComplete: any;
      let clientInfoMandat: any;

      if (annualObj.hasOwnProperty("mandatoryComplete")) {
        this.annualMandatoryComplete = annualObj.mandatoryComplete;
      } else
        this.annualMandatoryComplete = false;

      if (annualObj.hasOwnProperty("clientInfoId")) {
        clientInfoMandat = this.getClientInfoData();
        if (!clientInfoMandat) {
          clientInfoMandat = await this.annualService.getAnnualTrialsClientInfo(annualObj.clientInfoId);
        }
        this.cilentInfoMandatoryComplete = clientInfoMandat.mandatoryComplete;
      } else
        this.cilentInfoMandatoryComplete = null;



      //Note :here completion status is fetcched but in breadcrumb, the incomplete status is used to check hence used ! i.e !complete is incomplete
      /*mandatoryObj.push(!(this.annualMandatoryComplete)); 
      mandatoryObj.push(!this.cilentInfoMandatoryComplete); 
      mandatoryObj.push(false); */
      mandatoryObj.push(!this.annualMandatoryComplete);
      mandatoryObj.push((this.cilentInfoMandatoryComplete != null) ? !this.cilentInfoMandatoryComplete : null);
      mandatoryObj.push(false);
    }
    if (annualId == undefined) {
      //mandatoryObj = [true,true,false]; //same as note from above ; true = incomplete, false = complete
      mandatoryObj = [null, null, false]; //same as note from above ; true = incomplete, false = complete
    }
    return mandatoryObj;
  }

  async getDpResponsiblePerson() {
    console.log("Inside allDpResponsiblePersons:---");
    /*let allDpResponsiblePersons = [
      {"username":"adamavandi","firstName":"Abbas","lastName":"DAMAVANDI","email":"abbas.damavandi@ae.bureauveritas.com","office":"ABU DHABI - ADH0","roles":["FMEA_SURVEYOR"],"fullName":"Abbas DAMAVANDI"},
      {"username":"acombe","firstName":"Adrien","lastName":"COMBE","email":"zpmm_msl@fr.bureauveritas.com","office":"MARSEILLES - MSL0","roles":["FMEA_SURVEYOR"],"fullName":"Adrien COMBE"},
      {"username":"aabdelaziz","firstName":"Ahmed","lastName":"ABDELAZIZ","email":"Ahmed.Abdelaziz@ae.bureauveritas.com","office":"DAMMAM - AL-KHOBAR - ALK0","roles":["FMEA_SURVEYOR"],"fullName":"Ahmed ABDELAZIZ"},
      {"username":"aalbishawi","firstName":"Alaa","lastName":"ALBISHAWI","email":"alaa.albishawi@ae.bureauveritas.com","office":"MANAMA - BRN0","roles":["FMEA_SURVEYOR"],"fullName":"Alaa ALBISHAWI"},
      {"username":"afarhood","firstName":"Ali","lastName":"FARHOOD","email":"ali.farhood@ae.bureauveritas.com","office":"DUBAI - DBA0","roles":["FMEA_SURVEYOR"],"fullName":"Ali FARHOOD"},
      {"username":"amagarwal","firstName":"Amit","lastName":"AGARWAL","email":"amit.agarwal@in.bureauveritas.com","office":"MUMBAI (BOMBAY) - BBY0","roles":["FMEA_SURVEYOR"],"fullName":"Amit AGARWAL"},
      {"username":"ainojosa","firstName":"Angel","lastName":"INOJOSA","email":"angel.inojosa@ve.bureauveritas.com","office":"PANAMA - PNM0","roles":["FMEA_SURVEYOR"],"fullName":"Angel INOJOSA"},
      {"username":"aayi","firstName":"Anthony","lastName":"AYI","email":"anthony.ayi@ng.bureauveritas.com","office":"PORT HARCOURT - PHC0","roles":["FMEA_SURVEYOR"],"fullName":"Anthony AYI"},
      {"username":"ebilen","firstName":"Ender","lastName":"BILEN","email":"ender.bilen@tr.bureauveritas.com","office":"ISTANBUL - ITB0","roles":["FMEA_SURVEYOR"],"fullName":"Ender BILEN"}
    ];*/

    try {
      await this.loadingService.loadingPresent(Constants.MSG_LOADING);

      let dpResp = await this.qualifService.getQualifUserByType(UserTypeEnum.EXPERT);
      let allDpResponsiblePersons = dpResp.response.data;
      this.loadingService.loadingDismiss();
      return allDpResponsiblePersons;
    }
    catch (e) {
      this.loadingService.loadingDismiss();
      this.appManager.showToast("Unable to load API data. Please check your internet or pulse connection",'top');
      console.log("closing loader---");
    }

  }

  async getTrialsResponsiblePersonOnBoard() {
    /*let allTrialsResponsiblePersonOnBoard = [
      {"username":"adamavandi","firstName":"Abbas","lastName":"DAMAVANDI","email":"abbas.damavandi@ae.bureauveritas.com","office":"ABU DHABI - ADH0","roles":["FMEA_SURVEYOR"],"fullName":"Abbas DAMAVANDI"},
      {"username":"acombe","firstName":"Adrien","lastName":"COMBE","email":"zpmm_msl@fr.bureauveritas.com","office":"MARSEILLES - MSL0","roles":["FMEA_SURVEYOR"],"fullName":"Adrien COMBE"},
      {"username":"aabdelaziz","firstName":"Ahmed","lastName":"ABDELAZIZ","email":"Ahmed.Abdelaziz@ae.bureauveritas.com","office":"DAMMAM - AL-KHOBAR - ALK0","roles":["FMEA_SURVEYOR"],"fullName":"Ahmed ABDELAZIZ"},
      {"username":"aalbishawi","firstName":"Alaa","lastName":"ALBISHAWI","email":"alaa.albishawi@ae.bureauveritas.com","office":"MANAMA - BRN0","roles":["FMEA_SURVEYOR"],"fullName":"Alaa ALBISHAWI"},
      {"username":"afarhood","firstName":"Ali","lastName":"FARHOOD","email":"ali.farhood@ae.bureauveritas.com","office":"DUBAI - DBA0","roles":["FMEA_SURVEYOR"],"fullName":"Ali FARHOOD"},
      {"username":"amagarwal","firstName":"Amit","lastName":"AGARWAL","email":"amit.agarwal@in.bureauveritas.com","office":"MUMBAI (BOMBAY) - BBY0","roles":["FMEA_SURVEYOR"],"fullName":"Amit AGARWAL"},
      {"username":"ainojosa","firstName":"Angel","lastName":"INOJOSA","email":"angel.inojosa@ve.bureauveritas.com","office":"PANAMA - PNM0","roles":["FMEA_SURVEYOR"],"fullName":"Angel INOJOSA"},
      {"username":"aayi","firstName":"Anthony","lastName":"AYI","email":"anthony.ayi@ng.bureauveritas.com","office":"PORT HARCOURT - PHC0","roles":["FMEA_SURVEYOR"],"fullName":"Anthony AYI"},
      {"username":"ebilen","firstName":"Ender","lastName":"BILEN","email":"ender.bilen@tr.bureauveritas.com","office":"ISTANBUL - ITB0","roles":["FMEA_SURVEYOR"],"fullName":"Ender BILEN"}
    ];
    return allTrialsResponsiblePersonOnBoard;*/
    try {
      await this.loadingService.loadingPresent(Constants.MSG_LOADING);

      let trialResp = await this.qualifService.getQualifUserByType(UserTypeEnum.EXPERT_SURVEYOR);  //pending to do 10
      let allTrialsResponsiblePersonOnBoard = trialResp.response.data;
      
      this.loadingService.loadingDismiss();
      return allTrialsResponsiblePersonOnBoard;
    }
    catch (e) {
      this.loadingService.loadingDismiss();
      this.appManager.showToast("Unable to load API data. Please check your internet or pulse connection",'top');
      console.log("closing loader---");
    }



  }

  /**
   *
   * @param {AnnualTrials} generalDetailsData
   */
  public setAnnualData(generalDetailsData: AnnualTrials) {
    this.generalDetailsData = generalDetailsData;
  }

  /**
   *
   * @returns {AnnualTrials}
   */
  public getAnnualData() {
    return this.generalDetailsData;
  }

  /**
   *
   * @param {AnnualTrialsClientInfo} clientInfoData
   */
  public setClientInfoData(clientInfoData: AnnualTrialsClientInfo) {
    this.clientInfoData = clientInfoData;
  }

  /**
   *
   * @returns {AnnualTrialsClientInfo}
   */
  public getClientInfoData() {
    return this.clientInfoData;
  }

  /**
   *
   * @param {Array<FileUpload>} supportingDocData
   */
  public setSupportingDocData(supportingDocData: Array<FileUpload>) {
    this.supportingDocData = supportingDocData;
  }

  /**
   *
   * @returns {Array<FileUpload>}
   */
  public getSupportingDocData() {
    return this.supportingDocData;
  }

}
