import { Pipe, PipeTransform } from '@angular/core';
import { ConcatSource } from 'webpack-sources';

@Pipe({
    name: "vesselSearch"
})

export class BuilderFilterPipe implements PipeTransform {
    transform(value: any[], searchString: string) {
        if (!searchString) {
            console.log('no search')
            return value
        }

        let filterRes = value.filter(it => {
            searchString = searchString.trim();
            const vesselName = (it.vesselName)?it.vesselName.toString().toLowerCase().includes(searchString.toLowerCase()) : false;
            const imoNumber = (it.imoNo)?it.imoNo.toString().toLowerCase().includes(searchString.toLowerCase()) : false;
            const regNo = (it.regNo)?it.regNo.toString().toLowerCase().includes(searchString.toLowerCase()) : false;
            //const regNumber = it.regNo.toString().toLowerCase().includes(searchString.toLowerCase())
            let result = (vesselName + imoNumber + regNo );
            return (vesselName + imoNumber + regNo);
        });
        if(filterRes.length == 0){
            //console.log("filterRes:"+JSON.stringify(filterRes));
            return [-1];
        }else{
            //console.log("filterRes:"+JSON.stringify(filterRes));
            return filterRes;
        }
    }
}