import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';

// MISC
import { ArrayUtils } from '../../../commons/utils';

// PROVIDERS
import { AbstractService } from '../abstract-service';

// MODEL
import { TrialConclusion } from '../../../models';

// DAO
import { TrialConclusionDao, DocDao } from '../../dao';
import { Constants } from 'src/app/app.constants';
import { SectionStatus } from 'src/app/models/enums';


@Injectable()
export class TrialConclusionService extends AbstractService {

    constructor(protected injector: Injector, private trialConclusionDao: TrialConclusionDao, public docDao: DocDao,) {
        super(injector, "TrialConclusionService");
    }

    /**
     *
     * @param {TrialConclusion} doc
     * @returns {Promise<TrialConclusion>}
     */
    public async createTrialConclusion(doc: TrialConclusion): Promise<TrialConclusion> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();     
        // this.addPrincipalUserToChannel(doc);  
        this.addPrincipalDocIdToChannel(doc.annualTrialId, doc);
        return this.trialConclusionDao.create(doc);
    }

    /**
     *
     * @param {TrialConclusion} doc
     * @returns {Promise<TrialConclusion>}
     */
    public async updateTrialConclusion(doc: TrialConclusion): Promise<TrialConclusion> { 
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date(); 
        return this.trialConclusionDao.update(doc);
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<TrialConclusion>}
     */
    public async getTrialConclusion(docId: string): Promise<TrialConclusion> { 
        return await this.trialConclusionDao.get(docId);
    }
  
    /**
     * @param {string} annualTrialId
     * @returns {Promise<Array<TrialConclusion>>}
     */
    public async findAll(annualTrialId): Promise<any> {
        let trialConclusion = await this.trialConclusionDao.findAll();
        return _.filter(trialConclusion, { annualTrialId: annualTrialId });
    }

     /**
     *
     * @returns {Promise<Array<TrialConclusion>>}
     */
    public async findAllTrialsConclusion(): Promise<Array<TrialConclusion>> {
        var query = {
            type: Constants.TYPES.TRIAL_CONCLUSION,
            created: true
        }
        return this.trialConclusionDao.findBasedOnQuery(query);
    }


    /**
     *
     * @returns {Promise<Array<TrialConclusion>>}
     */
    public async findTrialConclusionForAnnualTrial(annualTrialIdVal: string): Promise<Array<TrialConclusion>> {

        var query = {
            type: Constants.TYPES.TRIAL_CONCLUSION,
            annualTrialId: annualTrialIdVal
            //TODO: Manoj add created param also
        }
        return this.trialConclusionDao.findBasedOnQuery(query);
    }

    public async getCurrentTrialConclusionForAnnualTrial(annualTrialIdVal: string): Promise<TrialConclusion> {
        let envArray = await this.findTrialConclusionForAnnualTrial(annualTrialIdVal);
        if (envArray && envArray.length > 0) {
            return envArray[0];
        }
        return undefined;
    }

    public async getStatusOfCurrentTrialConclusionForAnnualTrial(annualTrialIdVal: string, env?: TrialConclusion): Promise<SectionStatus> {
        if (!env) {
            env = await this.getCurrentTrialConclusionForAnnualTrial(annualTrialIdVal);
        }
        if (env) {
            if (env.completed) {
                return SectionStatus.COMPLETED;
            }
            else {
                return SectionStatus.DRAFT;
            }
        }
        return SectionStatus.PENDING;
    }
}
