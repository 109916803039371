import { Injectable } from '@angular/core';
import { TestCase } from '../../../models';
import { TestCaseExec } from '../../../models';


@Injectable({
  providedIn: 'root'
})
export class TestCaseExecutionService {

  testCase : TestCase;
  testCaseExec : TestCaseExec;
  constructor() { }

  getTestCase(){
    return this.testCase;
  }
  
  setTestCase(testCase){
    this.testCase = testCase;
  }
  
  getTestCaseExec(){
    return this.testCaseExec;
  }
  
  setTestCaseExec(testCaseExec){
    this.testCaseExec = testCaseExec;
  }


  ngOnInit(){

  }
}
