import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { VesselMainParticulars } from 'src/app/models/vessel-main-particulars.model';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";


@Injectable()
export class VesselMainParticularsDao extends AbstractDao<VesselMainParticulars> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.VESSEL_MAIN_PARTICULARS, { create: true, delete: true, update: true }, "VesselMainParticularsDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: VesselMainParticulars): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}