import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

import {OAuthService} from "angular-oauth2-oidc";
// import {authConfig} from "./auth.config";

import {BehaviorSubject, from, Observable, throwError,} from "rxjs";
import {catchError, filter, finalize, switchMap, take, map} from "rxjs/operators";
import { CommonService } from './common.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private oauthService: OAuthService, 
    private commonService: CommonService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authRequest = this.getAuthRequest(request);
    console.log("authRequest() -- res-- ",JSON.stringify(authRequest));
    return next.handle(authRequest).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          console.log("interceptevent---HttpResponse>>>", event);
        }
        return event;
      }),
      catchError((error) => {
        console.log("authRequest() -- response-- ",JSON.stringify(error));
        if (error && error.status === 401) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.getAuthRequest(authRequest)))
            );
          } else {
            this.refreshTokenInProgress = true;
            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            return from(this.oauthService.refreshToken()).pipe(
              switchMap((success: any) => {
                this.refreshTokenSubject.next(success);
                return next.handle(this.getAuthRequest(request));
              }),
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              finalize(() => this.refreshTokenInProgress = false)
            );
          }
        } else {
          console.log("authRequest() SOME ERROR OTHER THAN 401");
          return throwError(error);
        }
      }));

  }

  getAuthRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (request.url !== this.commonService.getOAuthConfig().tokenEndpoint && this.oauthService.getAccessToken() != null) {
      let authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.oauthService.getAccessToken()}`
        }
      });
      return authRequest;
    }
    return request;
  }

}
