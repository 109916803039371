import { UserBasic } from './user-basic.model';
import { ReviewEnum, ReviewStatusEnum } from './enums';

/**Reviewer information */
export class FileUpload {
    public fileName: string;
    public index: number;
    public displayName: string;
    public deleted: boolean;
    public fileId: string;
    public fileKey: string;
    public fileObj: any;
    public frmControlItem: any;
    public filePath : string="";
}