export abstract class StringUtils {

    /**
     * Test if string not blank.
     * 
     * @param {string} str 
     * @returns 
     * @memberof StringUtils
     */
    public static isBlank(str: string) {
        let result = true;
        if (str && this.isString(str)) {
            return str.trim() == "";
        }
        return result;
        // return !str || str.trim() == "";
    }

    /**
     * Test if string is not blank.
     * 
     * @param {string} str 
     * @returns 
     * @memberof StringUtils
     */
    public static isNotBlank(str: string) {
        let result = false;
        if (str && this.isString(str)) {
            return str.trim() != "";
        }
        return result;
        // return str && str.trim() != "";
    }

    /**
     * Test if string are equals.
     * 
     * @static
     * @param {string} str1 
     * @param {string} str2 
     * @returns 
     * @memberof StringUtils
     */
    public static equalsIgnoreCase(str1: string, str2: string) {
        if ((str1 && !str2) || (!str1 && str2)) {
            return false;
        }
        return str1.toLowerCase() === str2.toLowerCase();
    }

    /**
     * Test if value is a string or not.
     * 
     * @static
     * @param {*} value 
     * @returns {boolean} 
     * @memberof StringUtils
     */
    public static isString(value: any): boolean {
        return typeof value === "string";
    }

    /**
     * Test if a string contains a value.
     * 
     * @static
     * @param {string} value 
     * @param {string} toSearch 
     * @returns {boolean} 
     * @memberof StringUtils
     */
    public static contains(value: string, toSearch: string): boolean {
        return value && toSearch && value.indexOf(toSearch) > -1;
    }

    /**
     * Test if a string contains a value without case sensitive.
     * 
     * @static
     * @param {string} value 
     * @param {string} toSearch 
     * @returns {boolean} 
     * @memberof StringUtils
     */
    public static containsIgnoreCase(value: string, toSearch: string): boolean {
        let search = "";
        if (this.isString(toSearch)) {
            search = toSearch.toLowerCase();
        } else {
            search = toSearch;
        }
        return value && toSearch && this.contains(value.toLowerCase(), search);
    }

    /**
     * Replace occurenece of space /g with specified value OR by defalut '_'
     * 
     * @static
     * @param {string} value 
     * @param {string} replacement 
     * @returns {string} 
     * @memberof StringUtils
     */
    public static replaceSpace(value: string, replacement?: string): string {
        if (this.isNotBlank(value)) {
            if (this.isBlank(replacement)) {
                replacement = '_';
            }
            return value.replace(/ /g, replacement);
        }
        return value;
    }

    public static getEmptyForNull(value: string, defaultValue?: string): string {
        if (StringUtils.isBlank(value)) {
            if (defaultValue && defaultValue.length > 0) {
                return defaultValue;
            }
            return "";
        }
        return value;
    }

    public static getValueFromObjAny(obj: any, key: string, defaultValue?: any): any {
        let value;
        if (obj && obj[key]) {
            value = obj[key];
        }
        if (!value) {
            if (defaultValue) {
                return defaultValue;
            }
            return value;//
        }
        return value;
    }

    public static getValueFromObjString(obj: any, key: string, defaultValue?: string): string {
        let value;
        if (obj && obj[key]) {
            value = obj[key];
        }
        if (StringUtils.isBlank(value)) {
            if (defaultValue && defaultValue.length > 0) {
                return defaultValue;
            }
            return "";
        }
        return value;
    }

    public static getReadStringForList(list: Array<string>): string {
        if (list && list.length > 0) {
            let result = "";
            for (let index = 0; index < list.length; index++) {
                result = result + list[index];
                if (index < list.length - 2) {
                    result = result + ", "
                }
                else if (index < list.length - 1) {
                    result = result + " and "
                }
            }
            return result;
        }
        return "";
    }

    // public static getCamelCaseSentence(value: string): string {
    //     let sentence = value.toLowerCase().split(" ");
    //     for (let i = 0; i < sentence.length; i++) {
    //       sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    //     }
        
    //     return sentence.join(" ");
    // }

    public static getCamelCase(str) {
        if (this.isBlank(str)) {
            return str;
        }
        return str
            .split(' ')
            .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }
}