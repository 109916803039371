import { Injectable, Injector } from '@angular/core';

// MISC
import { ArrayUtils } from '../../../commons/utils';

// MODEL

// PROVIDERS
import { AbstractService } from '../abstract-service';
import { Constants } from 'src/app/app.constants';
import { UserTypeEnum } from 'src/app/models/enums';

@Injectable()
export class QualifService extends AbstractService {

    constructor(protected injector: Injector,
        ) {
        super(injector, "QualifService");
    }
    public getUserQualification(username: string): Promise<any> {
        this.logger.info("checkUserQualification: username:"+username);

        return this.newHttpRequest().post('/getUserQualification').withBody({
            username: username
        }).do();
    }

    // public getUserQualification(username: string) {
    //     this.logger.info("checkUserQualification: username:"+username);
    //     return this.postRequst('/getUserQualification', {username: username});
    // }

    public getQualifUserByType(userType:UserTypeEnum): Promise<any> {
        this.logger.info("fetch user info: userType:" + userType);

        return this.newHttpRequest().get('/getQualifiedUsers').withParams({
            type: userType,
        }).do();
    }

    public getConfig(): Promise<any> {
        this.logger.info("getConfig ");
        return this.newHttpRequest().get('/getConfig').do();
    }
}
