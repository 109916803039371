import { Injectable, Injector } from '@angular/core';

// MISC
import { Constants } from '../../app.constants';

// MODELS
import { FindingsAndCloseoutDetails } from '../../models';

// PROVIDERS - DAO
import { AbstractDao } from "../../providers/dao/abstract.dao";



@Injectable()
export class FindingsAndCloseoutDetailsDao extends AbstractDao<FindingsAndCloseoutDetails> {

    constructor(protected injector: Injector) {
        super(injector, Constants.TYPES.FINDINGS_AND_CLOSEOUT, { create: true, delete: true, update: true }, "FindingsAndCloseoutDetailsDao");
    }

    /**
     * Delete record (with "_deleted" flag because we use sync filtering)
     * @see https://pouchdb.com/api.html#filtered-replication
     * 
     * @param {Record} doc
     * @returns {Promise<boolean>}
     */
    protected async doDelete(doc: FindingsAndCloseoutDetails): Promise<boolean> {
        doc._deleted = true;
        const updatedDoc = await this.update(doc);
        return updatedDoc._deleted;
    }

}