import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';
import { FindingsStepInfo } from './findings-and-closeout-details.model';

export class FindingsAndCloseoutDetailsHistory extends AbstractDocument {
    public vesselId: string;
    public annualTrialId: string;
    public findingsCloseoutId: string;
    public aFindings: Array<FindingsStepInfo> = [];
    public bFindings: Array<FindingsStepInfo> = [];
    public cFindings: Array<FindingsStepInfo> = [];

    public amendmentRecords: Array<string> = [];
    public revNumber: number = -1;
    type = Constants.TYPES.FINDINGS_AND_CLOSEOUT_HISTORY;
}

