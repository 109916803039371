import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './providers/service';
import { AuthenticationGuard } from './auth/authentication.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },  
  {
    path: 'color-modal',
    loadChildren: () => import('./pages/modal/color-modal/color-modal.module').then( m => m.ColorModalPageModule)
  }, 
  {
    path: 'findings-closeout',
    loadChildren: () => import('./pages/annualTrial/findings-closeout/findings-closeout.module').then( m => m.FindingsCloseoutPageModule)
  },  
  {
    path: 'findings-closeout/:activeAnnualId',
    loadChildren: () => import('./pages/annualTrial/findings-closeout/findings-closeout.module').then( m => m.FindingsCloseoutPageModule)
  },  
  //NOTE if any pages related breadcumb/steps(Collapse ALL) then add page BEFORE this( general-details/:vesselId)
  {
    path: 'vessel-general-details/:vesselId',
    loadChildren: () => import('./pages/vessel-general-details/vessel-general-details.module').then( m => m.VesselGeneralDetailsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'termsandconditions',
    //canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/termsandconditions/termsandconditions.module').then(m => m.TermsandconditionsPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'legals',
    loadChildren: () => import('./pages/legals/legals.module').then( m => m.LegalsPageModule)
  },
  {
    path: 'test-case-library/:vesselId',
    loadChildren: () => import('./pages/test-case-library/test-case-list/test-case-list.module').then( m => m.TestCaseListPageModule)
  },
  {
    path: 'add-from-templates/:templateId/:isEdit/:isDuplicate',
    loadChildren: () => import('./pages/test-case-library/add-from-templates/add-from-templates.module').then( m => m.AddFromTemplatesPageModule)
  },
  {
    path: 'select-new-test-case-modal',
    loadChildren: () => import('./pages/modal/select-new-test-case-modal/select-new-test-case-modal.module').then( m => m.SelectNewTestCaseModalPageModule)
  },
  {
    path: 'warning-msg-modal',
    loadChildren: () => import('./pages/modal/warning-msg-modal/warning-msg-modal.module').then( m => m.WarningMsgModalPageModule)
  },
  {
    path: 'success-msg-modal',
    loadChildren: () => import('./pages/modal/success-msg-modal/success-msg-modal.module').then( m => m.SuccessMsgModalPageModule)
  },
  {
    path: 'confirmation-msg-modal',
    loadChildren: () => import('./pages/modal/confirmation-msg-modal/confirmation-msg-modal.module').then( m => m.ConfirmationMsgModalPageModule)
  },
  {
    path: 'generic-modal',
    loadChildren: () => import('./pages/modal/generic-modal/generic-modal.module').then( m => m.GenericModalPageModule)
  },
  {
    path: 'assigned-people-modal',
    loadChildren: () => import('./pages/modal/assigned-people-modal/assigned-people-modal.module').then( m => m.AssignedPeopleModalPageModule)
  },
  {
    path: 'vessel-list/:vesselId',
    loadChildren: () => import('./pages/test-case-library/vessel-list/vessel-list.module').then( m => m.VesselListPageModule)
  },
  {
    path: 'from-vessel-test-cases/:vesselId/:sourceVesselId',
    loadChildren: () => import('./pages/test-case-library/vessel-list/from-vessel-test-cases/from-vessel-test-cases.module').then( m => m.FromVesselTestCasesPageModule)
  },
  {
    path: 'test-case-execution-list',
    loadChildren: () => import('./pages/test-case-execution/test-case-execution-list/test-case-execution-list.module').then( m => m.TestCaseExecutionListPageModule)
  },  
  {
    path: 'test-case-execution-list/:activeAnnualId',
    loadChildren: () => import('./pages/test-case-execution/test-case-execution-list/test-case-execution-list.module').then( m => m.TestCaseExecutionListPageModule)
  },  
  {
    path: 'test-case-execution-details',
    loadChildren: () => import('./pages/test-case-execution/test-case-execution-details/test-case-execution-details.module').then( m => m.TestCaseExecutionDetailsPageModule)
  },
  {
    path: 'test-case-execution-details/:activeAnnualId',
    loadChildren: () => import('./pages/test-case-execution/test-case-execution-details/test-case-execution-details.module').then( m => m.TestCaseExecutionDetailsPageModule)
  },
  {
    path: 'trials-conclusions',
    loadChildren: () => import('./pages/annualTrial/trials-conclusions/trials-conclusions.module').then( m => m.TrialsConclusionsPageModule)
  },
  {
    path: 'trials-conclusions/:activeAnnualId',
    loadChildren: () => import('./pages/annualTrial/trials-conclusions/trials-conclusions.module').then( m => m.TrialsConclusionsPageModule)
  },
  {
    path: 'create-vessel-page',
    loadChildren: () => import('./pages/create-vessel-page/create-vessel-page.module').then( m => m.CreateVesselPagePageModule)
  },
  {
    path: 'create-vessel-page/:vesselId',
    loadChildren: () => import('./pages/create-vessel-page/create-vessel-page.module').then( m => m.CreateVesselPagePageModule)
  },
  {
    path: 'annual-trial-page',
    loadChildren: () => import('./pages/annualTrial/annual-trial-page/annual-trial-page.module').then( m => m.AnnualTrialPagePageModule)
  },
  {
    path: 'annual-trial-page/:annualId',
    loadChildren: () => import('./pages/annualTrial/annual-trial-page/annual-trial-page.module').then( m => m.AnnualTrialPagePageModule)
  },
  {
    path: 'status-report-page',
    loadChildren: () => import('./pages/status-report-page/status-report-page.module').then( m => m.StatusReportPagePageModule)
  },
  {
    path: 'status-report-page/:activeAnnualId',
    loadChildren: () => import('./pages/status-report-page/status-report-page.module').then( m => m.StatusReportPagePageModule)
  },
  {
    path: 'env-condition',
    loadChildren: () => import('./pages/env-condition/env-condition.module').then( m => m.EnvConditionPageModule)
  },
  {
    path: 'env-condition/:envKeyId',
    loadChildren: () => import('./pages/env-condition/env-condition.module').then( m => m.EnvConditionPageModule)
  },
  {
    path: 'annual-trial-history/:vesselId',
    loadChildren: () => import('./pages/annualTrial/annual-trial-history/annual-trial-history.module').then( m => m.AnnualTrialHistoryPageModule)
  },
  {
    path: 'annual-trial-history-details/:vesselId/:annualId',
    loadChildren: () => import('./pages/annualTrial/annual-trial-history-details/annual-trial-history-details.module').then( m => m.AnnualTrialHistoryDetailsPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash:true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
