import { Injector } from '@angular/core';
import { File, FileEntry } from '@ionic-native/file/ngx';

import { Constants } from '../../../app.constants';
import { Config } from '../../../providers/core';
import { PlatformType, PlatformService } from '../../../providers/service/platform/platform.service';

import { LogLevel } from '../logger';
import { LogWriter, LogReader, LogHandler } from './handler';
import { AbstractLogReader } from './abstract-reader';
import { AbstractLogJsonWriter } from './abstract-json-writer';

export class LogFileHandler implements LogHandler {

    private static readonly CONFIG_KEY = 'file';
    private static readonly FILE_NAME = 'log.json';
    // max log file size in bytes = 5mo
    private static readonly MAX_FILE_SIZE = 5000000;

    private file: File = this.injector.get(File);
    private platformService: PlatformService = this.injector.get(PlatformService);
    private config: Config = this.injector.get(Config);

    private directory: string;
    private logFile: string;

    constructor(private injector: Injector) {
        this.directory = this.getDirectory();
        this.logFile = LogFileHandler.FILE_NAME;
    }

    public async canUse(): Promise<boolean> {
        // try init log file
        let can: boolean = true;
        let fileExists: boolean;
        try {
            fileExists = await this.file.checkFile(this.directory, this.logFile)
        } catch (error) {
            // "this.file.checkFile" throws an error if file is not found (!)
            // https://github.com/ionic-team/ionic-native/issues/1711#issuecomment-356889103
            fileExists = false;
        }

        if (!fileExists) {
            try {
                await this.file.createFile(this.directory, this.logFile, true);
            } catch (error) {
                console.warn(`LogFileHandler: an error occured when trying to init the log file in directory '${this.directory}'`, error);
                can = false;
            }
        } else {
            try {
                // recreate log file if current file exceed some size
                const fileEntry: FileEntry = await this.file.getFile(await this.file.resolveDirectoryUrl(this.directory), this.logFile, {});
                await new Promise((resolve, reject) => {
                    try {
                        fileEntry.file(async (fileObj) => {
                            console.debug(`LogFileHandler: log file size ${fileObj.size}`);
                            if (fileObj.size > LogFileHandler.MAX_FILE_SIZE) {
                                try {
                                    await this.file.createFile(this.directory, this.logFile, true);
                                } catch (error) {
                                    reject(error);
                                }
                            }
                            resolve();
                        }, (error) => reject(error));
                    } catch (error) {
                        reject(error);
                    }
                });
            } catch (error) {
                console.warn(`LogFileHandler: an error occured when trying to rollover the log file in directory '${this.directory}'`, error);
                can = false;
            }
        }

        return can;
    }

    public getWriter(): LogWriter {
        const levelThreshold = this.config.get(`${Constants.CONFIG.LOG.ROOT}.${LogFileHandler.CONFIG_KEY}.${Constants.CONFIG.LOG.LEVEL}`)
            || this.config.get(`${Constants.CONFIG.LOG.ROOT}.${Constants.CONFIG.LOG.LEVEL}`)
            || LogLevel.INFO;
        const rawFilter = this.config.get(`${Constants.CONFIG.LOG.ROOT}.${LogFileHandler.CONFIG_KEY}.${Constants.CONFIG.LOG.FILTER}`)
            || this.config.get(`${Constants.CONFIG.LOG.ROOT}.${Constants.CONFIG.LOG.FILTER}`);
        console.debug(`LogFileHandler: '${levelThreshold}' '${rawFilter}'`);

        return new LogFileWriter(this.injector, levelThreshold, rawFilter, this.directory, this.logFile);
    }

    public getReader(): LogReader {
        return new LogFileReader(this.injector, this.directory, this.logFile);
    }

    private getDirectory(): string {
        if (this.platformService.getPlatformType() === PlatformType.ANDROID) {
            return this.file.externalApplicationStorageDirectory;
        } else if (this.platformService.getPlatformType() === PlatformType.IOS) {
            return this.file.documentsDirectory;
        } else {
            throw `LogFileHandler - unsupported platform '${this.platformService.getPlatformType()}'`;
        }
    }

}

export class LogFileWriter extends AbstractLogJsonWriter implements LogWriter {

    private static readonly SEPARATOR = ',';

    private file: File = this.injector.get(File);

    private logsToProcess: any[] = [];
    private handling: boolean;

    constructor(private injector: Injector, levelThreshold: LogLevel, rawFilter: string, private directory: string, private logFile: string) {
        super(levelThreshold, rawFilter);
    }

    protected async doLog(level: LogLevel, name: string, message: string, ...param: any[]): Promise<void> {
        // in case multiple notifications are received at the same time,
        // use the same loop to process them to avoid opening and closing file too much
        this.logsToProcess.push({
            level: level,
            name: name,
            message: message,
            param: param
        });
        if (!this.handling) {
            // use a "new" thread to handle logs to not block current thread and isolate it from errors
            setTimeout(() => this.handleLogs());
        }
    }

    private async handleLogs(): Promise<void> {
        this.handling = true;
        while (this.logsToProcess.length > 0) {
            // remove all elements and transform them as a JSON string (ex: {},{},{},...)
            const json = this.logsToProcess.splice(0, this.logsToProcess.length).map((j) => this.logToJson(j)).join(LogFileWriter.SEPARATOR);

            try {
                await this.file.writeFile(this.directory, this.logFile, json, { append: true });
            } catch (error) {
                console.warn('LogFileWriter: an error occured when writing to log file', error);
            }
        }
        this.handling = false;
    }

}

export class LogFileReader extends AbstractLogReader {

    constructor(injector: Injector, private directory: string, private logFile: string) {
        super(injector);
    }

    protected readLogs(): Promise<string> {
        return this.file.readAsText(this.directory, this.logFile);
    }

}
