import { Injector } from '@angular/core';

import { Constants } from '../../../app.constants';
import { Config } from '../../../providers/core';

import { LogLevel } from '../logger';
import { LogWriter, LogReader, LogHandler } from './handler';
import { AbstractLogReader } from './abstract-reader';
import { AbstractLogJsonWriter } from './abstract-json-writer';

export class LogLocalStorageHandler implements LogHandler {

    private static readonly CONFIG_KEY = 'localstorage';

    private static readonly KEY = 'LOG_KEY';

    private config: Config = this.injector.get(Config);

    constructor(private injector: Injector) {
        // nothing
    }

    public async canUse(): Promise<boolean> {
        let can: boolean = true;
        try {
            if (window.localStorage == null) {
                console.warn("LogLocalStorageHandler: no local storage available or we are not authorized to use it");
                can = false;
            }
        } catch (error) {
            console.warn("LogLocalStorageHandler: no local storage available or we are not authorized to use it", error);
            can = false;
        }

        return can;
    }

    public getWriter(): LogWriter {
        const levelThreshold = this.config.get(`${Constants.CONFIG.LOG.ROOT}.${LogLocalStorageHandler.CONFIG_KEY}.${Constants.CONFIG.LOG.LEVEL}`)
            || this.config.get(`${Constants.CONFIG.LOG.ROOT}.${Constants.CONFIG.LOG.LEVEL}`)
            || LogLevel.INFO;
        const rawFilter = this.config.get(`${Constants.CONFIG.LOG.ROOT}.${LogLocalStorageHandler.CONFIG_KEY}.${Constants.CONFIG.LOG.FILTER}`)
            || this.config.get(`${Constants.CONFIG.LOG.ROOT}.${Constants.CONFIG.LOG.FILTER}`);
        console.debug(`LogLocalStorageHandler: '${levelThreshold}' '${rawFilter}'`);

        return new LogLocalStorageWriter(levelThreshold, rawFilter, LogLocalStorageHandler.KEY);
    }

    public getReader(): LogReader {
        return new LogLocalStorageReader(this.injector, LogLocalStorageHandler.KEY);
    }

}

export class LogLocalStorageWriter extends AbstractLogJsonWriter implements LogWriter {

    // max log size in bytes = 500ko
    private static readonly MAX_LOG_SIZE = 500000;

    private static readonly SEPARATOR = ',';

    constructor(levelThreshold: LogLevel, rawFilter: string, private localStorageKey: string) {
        super(levelThreshold, rawFilter);
    }

    protected async doLog(level: LogLevel, name: string, message: string, ...param: any[]): Promise<void> {
        // use a "new" thread to handle logs to not block current thread and isolate it from errors
        setTimeout(() => {
            // global try/catch
            try {
                const newJson = this.logToJson({
                    level: level,
                    name: name,
                    message: message,
                    param: param
                });
                let existingJson = window.localStorage.getItem(this.localStorageKey);

                // empty log if it exceeds max size
                if (existingJson && existingJson.length > LogLocalStorageWriter.MAX_LOG_SIZE) {
                    existingJson = '';
                }

                try {
                    window.localStorage.setItem(this.localStorageKey, (!existingJson ? '' : existingJson + LogLocalStorageWriter.SEPARATOR) + newJson);
                } catch (error) {
                    // quota exceeded, remove all
                    console.warn("LogLocalStorageWriter: quota exceeded");
                    window.localStorage.setItem(this.localStorageKey, newJson);
                }
            } catch (error) {
                console.warn("LogLocalStorageWriter: unexpected error", error);
            }
        });
    }

}

export class LogLocalStorageReader extends AbstractLogReader {

    constructor(injector: Injector, private localStorageKey: string) {
        super(injector);
    }

    protected readLogs(): Promise<string> {
        return Promise.resolve(window.localStorage.getItem(this.localStorageKey));
    }

}
