export enum TrialStatus {
    NOT_STARTED = 0,
    REV_A_IN_PROGRESS = 10,
    REV_A_FINAL = 20,
    REV_B_IN_PROGRESS = 30,
    REV_B_FINAL = 40,
    ONBOARD = 50,
    REV_C_IN_PROGRESS = 60,
    REV_C_FINAL = 70,
    REV_0_IN_PROGRESS = 80,
    REV_0_FINAL = 90,
    CLOSED = 100
}