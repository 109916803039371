import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';


export class VesselPrincipalDimensions extends AbstractDocument {

    public lengthOverall: number;
    public breadthMoulded: number;
    public lengthPerpendiculars: number;
    public drought: number;
    // public draft: number;
    public grossTon: number;
    public deadweightTon: number;
    public mandatoryComplete: boolean; 
    type = Constants.TYPES.VESSEL_PRINCIPAL_DIMENS;
}