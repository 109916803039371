import { LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateFormatPipe, LocalePipe } from 'angular2-moment';


@Pipe({ name: 'jsonDate' })
export class JsonDatePipe implements PipeTransform {

    transform(value: string | Date, format: string = 'DD MMM YY'): any {
        if (!value) {
            return null;
        }
        const dateFormatPipe = new DateFormatPipe();
        const localePipe = new LocalePipe();
        let jsonValue: string = this.getJsonDate(value);
        const localeDate = localePipe.transform(jsonValue, LOCALE_ID.toString());
        return dateFormatPipe.transform(localeDate, format);
    }

    private getJsonDate(value: string | Date): string {
        let jsonValue: string;
        if (value instanceof Date) {
            jsonValue = JSON.stringify(value);
        } else {
            jsonValue = value;
        }
        // Parse in case of value is a JSON String
        return jsonValue.indexOf('"') > -1 ? JSON.parse(jsonValue) : value;
    }

}