import { Injectable, Injector } from '@angular/core';
import * as _ from 'lodash';

// MISC
import { Logger, LoggerFactory } from '../../../../commons/log';

@Injectable()
export class TemplateTableHandlerService {

    public templateTableData: any = [];
    public genCommentTableInfo: any = [];
    private readonly logger: Logger = this.injector.get(LoggerFactory).buildLogger("TemplateTableHandlerService");
    // private readonly tableFormatData = [
    //     {
    //         tableId: "TABLE1",
    //         tableData: {
    //             tableType: "GENERAL",
    //             isTableName: false,
    //             tableName: "",
    //             noOfRows: 6,
    //             noOfCols: 3,
    //             isTableHeader: true,
    //             tableHeaderValue: [],
    //             isTableHeaderDisabled: false,
    //             tableFirstColValue: []
    //         }
    //     },
    //     {
    //         tableId: "TABLE2",
    //         tableData: {
    //             tableType: "GENERAL",
    //             isTableName: true,
    //             tableName: "",
    //             noOfRows: 8,
    //             noOfCols: 2,
    //             isTableHeader: true,
    //             tableHeaderValue: [],
    //             isTableHeaderDisabled: false,
    //             tableFirstColValue: [],
    //         }
    //     },
    //     {
    //         tableId: "TABLE3",
    //         tableData: {
    //             tableType: "WITHSPAN",
    //             isTableName: true,
    //             tableName: "",
    //             noOfRows: 5,
    //             noOfCols: 3,
    //             isTableHeader: true,
    //             tableHeaderValue: [],
    //             isTableHeaderDisabled: false,
    //             tableFirstColValue: []
    //         }
    //     }
    // ];
    
    constructor(protected injector: Injector) { }

    // public getTableInfo(tableFormat: string): Promise<any>{
    //     let tableData:any;
    //     this.tableFormatData.forEach(item => {
    //         if(item.tableId == tableFormat){
    //             tableData = item.tableData;
    //         }
    //     });
    //     return tableData;
    // }

    public addStep(stepIndex){
        let stepData = { stepId: stepIndex, tableInfo: [] };
        this.templateTableData.push(stepData);
    }

    public setTemplateTableData(stepIndex, tableInfo){
        this.templateTableData.forEach((stepItem) => {
            if(stepItem.stepId == stepIndex){
                stepItem.tableInfo = tableInfo;
            }
        });
    }

    public getTemplateTableData(){
        return this.templateTableData;
    }

    public deleteStep(stepIndex){
        _.remove(this.templateTableData, { stepId: stepIndex });
        this.templateTableData.forEach((step, index) => {
            step.stepId = index;
        });
    }

    public resetTemplateTableData(){
        return this.templateTableData = [];
    }

    public setGenCommentTableData(tableInfo) {
        this.logger.debug("setGenCommentTableData: ", tableInfo);
        this.genCommentTableInfo = tableInfo;
    }

    public getGenCommentTableData() {
        return this.genCommentTableInfo;
    }

}
