import { Injectable } from "@angular/core";
import { get } from 'lodash';

@Injectable()
export class Config {

    private _config: object = {};

    constructor() {
        // empty
    }

    public get(path: string): any {
        return get(this._config, path);
    }

    set config(config: object) {
        this._config = config;
    }

}
