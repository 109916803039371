import { OfflineDocStatusEnum } from 'src/app/models/enums/offlineStatus.enum';

import { Injectable, Injector } from '@angular/core';

// MISC
import { ArrayUtils, StringUtils } from '../../../commons/utils';

// MODEL
import { Image } from 'src/app/models/image';
import { Doc } from 'src/app/models/doc';
import { OfflineImage } from 'src/app/models/offline-image';
import { OfflineDoc } from './../../../models/offline-doc';

// PROVIDERS

import { AbstractService } from '../abstract-service';
import { ImageDao } from '../../dao/image.dao';
import { DocDao } from '../../dao/doc.dao';
import { OfflineImageDao } from '../../dao/offline_image.dao';
import { OfflineDocDao } from '../../dao/offline_doc.dao';
import { Constants } from 'src/app/app.constants';


@Injectable()
export class ImageDocService extends AbstractService {

    constructor(protected injector: Injector,
        private imageDoa: ImageDao,
        private docDao: DocDao, private offlineImageDao : OfflineImageDao, private offlineDocDao : OfflineDocDao) {
        super(injector, "ImageDocService");
    }

    /**
     *
     * @param {Image} doc
     * @returns {Promise<Image>}
     */
    public async createImage(doc: Image): Promise<Image> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // this.addPrincipalUserToChannel(doc);
        let channel = "DEFAULT";
        if (StringUtils.isNotBlank(doc.parentDocId)) {
            channel = doc.parentDocId
        }
        this.addPrincipalDocIdToChannel(channel, doc);
        // console.log("-----CREATE IMG----",doc);     
        let response = this.imageDoa.create(doc);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<Image>}
     */
    public async getImage(docId: string): Promise<Image | null> {
        // console.log("-----getImage IMG----",docId);    
        try{
            return await this.imageDoa.get(docId);
        }
        catch(e){
            return null;
        }
        
    }

    /**
     *
     * @returns {Promise<Array<Image>>}
     */
    public async findAllImages(): Promise<Array<Image>> {
        return this.imageDoa.findAll();
    }

    /**
     *
     * @param {Image} doc
     * @returns {Promise<boolean>}
     */
    public async removeImage(doc: Image): Promise<boolean> {
        return this.imageDoa.delete(doc);
    }

    /**
     *
     * @param {Image} doc
     * @returns {Promise<Image>}
     */
    public async updateImage(doc: Image): Promise<Image> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // console.log("-----UPDATE IMG----",doc);     
        return this.imageDoa.update(doc);
    }

    /**
     *
     * @param {Doc} doc
     * @returns {Promise<Doc>}
     */
    public async createDoc(doc: Doc): Promise<Doc> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // console.log("---CREATE-DOC----",doc);

        // this.addPrincipalUserToChannel(doc);
        let channel = "DEFAULT";
        if (StringUtils.isNotBlank(doc.parentDocId)) {
            channel = doc.parentDocId
        }
        this.addPrincipalDocIdToChannel(channel, doc);
        let response = this.docDao.create(doc);
        return response;
    }

    /**
     *
     * @param {string} docId
     * @returns {Promise<Doc>}
     */
    public async getDoc(docId: string): Promise<Doc> {
        return await this.docDao.get(docId);
    }

    /**
     *
     * @returns {Promise<Array<Doc>>}
     */
    public async findAllDocs(): Promise<Array<Doc>> {
        return this.docDao.findAll();
    }


    /**
     *
     * @param {Doc} doc
     * @returns {Promise<boolean>}
     */
    public async removeDoc(doc: Doc): Promise<boolean> {
        return this.docDao.delete(doc);
    }
    

    /**
     *
     * @param {Doc} doc
     * @returns {Promise<Doc>}
     */
    public async updateDoc(doc: Doc): Promise<Doc> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // console.log("-----UPDATE DOC----",doc);     
        return this.docDao.update(doc);
    }

    /**
     *
     * @param {Doc} doc
     * @returns {Promise<Doc>}
     */
    public async createDocs(docs: Doc[]): Promise<Array<Doc>> {
        docs.forEach((doc) => {
            doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        });
        return this.docDao.createAll(docs);
    }

    /**
     *
     * @param {Doc} doc
     * @returns {Promise<Doc>}
     */
    public async updateDocs(docs: Doc[]): Promise<Array<Doc>> {
        docs.forEach((doc) => {
            doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        });
        return this.docDao.updateAll(docs);
    }


    /**
    *
    * @param {string} docId
    * @returns {Promise<any>}
    */
    public async getImageOrDoc(docId: string): Promise<any> {
        if (StringUtils.isNotBlank(docId)) {
            if (docId.startsWith(Constants.TYPES.IMAGE)) {
                return this.getImage(docId)
            }
            else if (docId.startsWith(Constants.TYPES.DOC)) {
                return this.getDoc(docId)
            }
        }
        return;//TODO: Manoj 24042020: Check if only return is correct way to do
    }

    /**
    *
    * @param {string} docId
    * @returns {Promise<any>}
    */
    public async getImageOrDocKey(docId: string, imageDoc?: Doc | Image): Promise<string> {
        if (!imageDoc) {
            imageDoc = await this.getImageOrDoc(docId);
        }
        if (imageDoc) {
            return imageDoc.remoteFileKey;
        }
        return "";
    }

    /**
    *
    * @param {string} docId
    * @returns {Promise<any>}
    */
    public async getImageOrDocFileName(docId: string, displayName?: boolean, imageDoc?: Doc | Image): Promise<string> {
        if (!imageDoc) {
            imageDoc = await this.getImageOrDoc(docId);
        }
        if (imageDoc) {
            let fileName = displayName ? imageDoc.displayFileName : imageDoc.fileName;
            if (StringUtils.isBlank(fileName)) {
                fileName = displayName ? imageDoc.fileName : imageDoc.displayFileName;
            }
            return fileName;
        }
        return "";
    }

    public async compareArrayMarkDeleted(oldDocArray: Array<String>, newDocArray: Array<String>) {
        let toBeDeletedImages = [];
        if (oldDocArray && oldDocArray.length > 0 && newDocArray && newDocArray.length > 0) {
          for (let index = 0; index < oldDocArray.length; index++) {
            if(newDocArray.indexOf(oldDocArray[index]) < 0){
              toBeDeletedImages.push(oldDocArray[index]);
            }
          }
        }
        if (toBeDeletedImages && toBeDeletedImages.length > 0) {
          for (let index = 0; index < toBeDeletedImages.length; index++) {
            let doc = await this.getImageOrDoc(toBeDeletedImages[index]);
            if (doc) {
              doc.deleted = true;
              doc.ddt = this.DateUtils.getCurrentDateMilli();//new Date();
              this.logger.warn("compareArrayMarkDeleted: Deleted: ", doc.uid);
              if (doc.type == Constants.TYPES.IMAGE) {
                let finalDoc = await this.updateImage(doc);  
              }
              else {
                let finalDoc = await this.updateDoc(doc);
              }
            }
          }
        }
    }

    //offline device

    /**
     *
     * @param {Image} doc
     * @returns {Promise<OfflineImage>}
     */
    public async createImageOffline(doc: OfflineImage): Promise<OfflineImage> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        this.addPrincipalUserToChannel(doc);
        // console.log("-----CREATE IMG----",doc);     
        let response = this.offlineImageDao.createOffline(doc);
        return response;
    }
    
    
    /**
     *
     * @param {Image} doc
     * @returns {Promise<OfflineDoc>}
     */
    public async createDocOffline(doc: OfflineDoc): Promise<OfflineDoc> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        this.addPrincipalUserToChannel(doc);
        let response = this.offlineDocDao.createOffline(doc);
        return response;
    }

    /**
     *
     * @returns {Promise<Array<Image>>}
     */
    public async findAllImagesOffline(): Promise<Array<OfflineImage>> {
        return this.offlineImageDao.findAllOffline();
    }

     /**
     *
     * @returns {Promise<Array<Doc>>}
     */
    public async findAllDocsOffline(): Promise<Array<OfflineDoc>> {
        return this.offlineDocDao.findAllOffline();
    }


    /**
     *
     * @param {Image} doc
     * @returns {Promise<Image>}
     */
    public async updateImageOffline(doc: OfflineImage): Promise<OfflineImage> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // console.log("-----UPDATE IMG----",doc);     
        return this.offlineImageDao.update(doc);
    }

    /**
     *
     * @param {Doc} doc
     * @returns {Promise<Doc>}
     */
    public async updateDocOffline(doc: OfflineDoc): Promise<OfflineDoc> {
        doc.mdt = this.DateUtils.getCurrentDateMilli();//new Date();
        // console.log("-----UPDATE DOC----",doc);     
        return this.offlineDocDao.update(doc);
    }



    public async getPendingOfflineImages() : Promise<Array<OfflineImage>>{
        let allOfflineImages = await this.findAllImagesOffline();
        let toSyncImages : Array<OfflineImage> = [];
        
        if(allOfflineImages.length >0){
            toSyncImages = allOfflineImages.filter(data => data.isSync == OfflineDocStatusEnum.NOTSYNCED);
            return toSyncImages;
        }
    }
    
    
    public async getPendingOfflineDocs() : Promise<Array<OfflineDoc>>{
        let allOfflineDocs = await this.findAllDocsOffline();
        let toSyncDocs : Array<OfflineDoc> = [];
        
        if(allOfflineDocs.length >0){
            toSyncDocs = allOfflineDocs.filter(data => data.isSync == OfflineDocStatusEnum.NOTSYNCED);
            return toSyncDocs;
        }
    }

    /**
     *
     * @param {Image} doc
     * @returns {Promise<boolean>}
     */
    public async removeImageOffline(doc: OfflineImage): Promise<boolean> {
        return this.offlineImageDao.deleteOffline(doc);
    }
    /**
     *
     * @param {Doc} doc
     * @returns {Promise<boolean>}
     */
    public async removeDocOffline(doc: OfflineDoc): Promise<boolean> {
        return this.offlineDocDao.deleteOffline(doc);
    }



    /**
     *
     * @param {Doc} doc
     * @returns {Promise<Doc>}
     */
    /*public async createDocOffline(doc: Doc): Promise<Doc> {
        doc.mdt = new Date();
        // console.log("---CREATE-DOC----",doc);

        this.addPrincipalUserToChannel(doc);
        let response = this.docDao.createOffline(doc);
        return response;
    }*/


}
