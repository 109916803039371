import { Constants } from 'src/app/app.constants';
import { rgb, PDFFont, PDFPage } from 'pdf-lib';

export class PDRectangle {
    public x: number;
    public y: number;
    public width: number;
    public height: number;
}

export class Size {
    public width: number;
    public height: number;
}

export class CellBasic {
    public name: string;
    public backgroundColor?: string;
    public width?: number;
    public style?: FontStyle;
    public align?: FontAlign;
    public fontColor?: string;
    public colSpan?: number = 1;
    public rowSpan?: number = 1;
    public id?: string;
    public colIndex: number = -1;
    /**0 = text, 1= image and 2=Table
    **/
    public cellType?: number = 0; 
    public imageKey?: string;
    public tabelData?: any;
}

export class CellInTable {
    public x: number;
    public y: number;
    public colIndex: number = -1;
    public rowIndex: number = -1;
    public cell: CellBasic;
    public columnWidth: number;
    public rowHeight: number;
    public pageNumber: number;
}

export class Point {
    public x: number;
    public y: number;
}

export class NewPage {
    public page: PDFPage;
    public cursorY: number;
    public isNew: boolean = false;
    public addedPages: number = 0;
}

export class FontSet {
    public regular: PDFFont;
    public bold: PDFFont;
    public italic: PDFFont;
    public boldItalic: PDFFont;
    public symbolFont: PDFFont;
    public zapfDingbatsFont: PDFFont;
}

export class RectTextStyle {
    public font_size: number;
    public font_style: number;
    public font_color: string;
    public background_color: string;
    public border_color: string;
    public border_thick: number;
    public font_align: number;
    public x_offset: number;
    public y_offset: number;
    public height: number;
}

export enum ImageAspect {
    CENTER = "0",
    FILL = "10",
    START = "20",
    END = "30"
}

export enum FontStyle {
    REGULAR = 0,
    BOLD = 1,
    ITALIC = 2,
    BOLD_ITALIC = 3
}

export enum FontAlign {
    LEFT = 0,
    CENTER = 1,
    RIGHT = 2,
    JUSTIFY = 3
}

export enum TextContentType {
    PARA = 0,
    SUB_HEADER = 1,
    HEADER = 2,
    TABLE = 3,
    SUB_TITLE = 4,
    TITLE = 5, 
    CAPTION = 6
}

export class ReportConst {

    static readonly DEFAULT_TABLE_STYLE_JSON_STRING: string = "{\"title_style\":{\"font_size\":10,\"font_style\":1,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#222222\",\"border_thick\":0.4,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":20},\"section_style\":{\"font_size\":10,\"font_style\":1,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.4,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":18},\"header_style\":{\"font_size\":10,\"font_style\":1,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.4,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":16},\"row_style\":{\"font_size\":10,\"font_style\":0,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.4,\"font_align\":0,\"x_offset\":0,\"y_offset\":0,\"height\":16}}";
    static readonly LARGE_TABLE_STYLE_JSON_STRING: string = "{\"title_style\":{\"font_size\":9,\"font_style\":1,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#222222\",\"border_thick\":0.4,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":20},\"section_style\":{\"font_size\":9,\"font_style\":1,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.4,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":18},\"header_style\":{\"font_size\":9,\"font_style\":1,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.4,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":16},\"row_style\":{\"font_size\":9,\"font_style\":0,\"font_color\":\"#000000\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.4,\"font_align\":0,\"x_offset\":0,\"y_offset\":0,\"height\":16}}";
    static readonly SMALL_TABLE_STYLE_JSON_STRING: string = "{\"title_style\":{\"font_size\":8,\"font_style\":1,\"font_color\":\"#202020\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.3,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":18},\"section_style\":{\"font_size\":8,\"font_style\":1,\"font_color\":\"#202020\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.3,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":16},\"header_style\":{\"font_size\":8,\"font_style\":1,\"font_color\":\"#202020\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.3,\"font_align\":1,\"x_offset\":0,\"y_offset\":0,\"height\":14},\"row_style\":{\"font_size\":8,\"font_style\":0,\"font_color\":\"#202020\",\"background_color\":\"#FFFFFF\",\"border_color\":\"#7F7F7F\",\"border_thick\":0.3,\"font_align\":0,\"x_offset\":0,\"y_offset\":0,\"height\":14}}";
    static readonly BV_LOGO_BLUE_PATH: string = "assets/images/bvs_report_logo.jpg";
    static readonly BV_LOGO_BLUE_LIGHT_PATH: string = "assets/images/bvs_report_logo_light.jpg";
    static readonly BV_LOGO_PATH: string = "assets/images/Log_BVSolutionsWhite@2x.png";
    static readonly BV_LOGO_BLACK_PATH: string = "assets/images/Log_BVSolutionsBlack@2x.png";
    static readonly BV_LOGO_HEADER_PATH: string = "assets/images/headerLogo.png";
    static readonly DPS_LOGO_BLUE_PATH: string = "assets/images/BVS_Fleche_Blanche_blue@2x.png";
    static readonly DPS_LOGO_WHITE_PATH: string = "assets/images/BVS_Fleche_Blanche@2x.png";

    static readonly COLOR_HEX_WHITE: string = "#FFFFFF";
    static readonly COLOR_HEX_BLACk: string = "#000000";
    static readonly COLOR_HEX_RED: string = "#FF0000";
    static readonly COLOR_HEX_GREEN: string = "#00b050";
    static readonly COLOR_HEX_BLUE: string = "#007aa0";
    static readonly COLOR_HEX_GREY: string = "#7F7F7F";

    static readonly HEADER_LOGO_SIZE: Size = { width: 120, height: 70 };
    static readonly TABLE_IMAGE_CELL_SIZE: Size = { width: 140, height: 140 };
    static readonly TABLE_TABLE_CELL_SIZE: Size = { width: 160, height: 160 };
    static readonly TABLE_IMAGE_SIZE: Size = { width: 130, height: 130 };
    static readonly TABLE_IMAGE_OFFSET:number = 5;
    static readonly TABLE_IMAGE_OFFSET_1:number = 19;
    static readonly FOOTER_LOGO_SIZE: Size = { width: 18, height: 32 };
    static readonly HEADER_LOGO_OFFSET: Point = { x: 30, y: 20 };

    static readonly CONTENT_IMAGE_MIN_SIZE: Size = { width: 393, height: 393 };
    static readonly CONTENT_IMAGE_X: number = 101;
    static readonly MIN_TABLE_HEIGHT_HEADER: number = 160;
    static readonly MIN_TABLE_HEIGHT: number = 130;
    static readonly MIN_PARA_HEIGHT: number = 100;
    static readonly MARGIN_TABLE_EXTRA_TITLE: number = 30;
    static readonly HEIGHT_TO_LINES_FACTOR: number = 16;

    static readonly CONTENT_SPACE: string = "      ";

    static readonly TITLE_FONT_SIZE_0: number = 30;
    static readonly TITLE_FONT_SIZE_1: number = 24;
    static readonly TITLE_FONT_SIZE_2: number = 18;
    static readonly SUBTITLE_FONT_SIZE: number = 14;
    static readonly SUBTITLE_FONT_SIZE_2: number = 12;
    static readonly CONTENT_FONT_SIZE: number = 11;
    static readonly CONTENT_FONT_SIZE_2: number = 10;
    static readonly CONTENT_FONT_SIZE_W_INDENT: number = 12;
    static readonly TABLE_FONT_SIZE: number = 10;
    static readonly TABLE_FONT_SIZE_2: number = 9;
    static readonly CAPTION_FONT_SIZE: number = 9;
    static readonly PAGE_NO_FONT_SIZE: number = 8;
    static readonly FOOTER_FONT_SIZE: number = 7.5;

    static readonly NEW_LINE_FONT_SIZE_FACTOR: number = 1.3;
    static readonly NEW_LINE_FONT_SIZE_FACTOR_2: number = 1.8;

    static readonly LINE_INDENT_DEFAULT: number = 3;
    static readonly LINE_INDENT_SUBTITLE: number = 6;
    static readonly LINE_INDENT_SUBTITLE_2: number = 12;
    static readonly PARA_INDENT: number = 24;
    static readonly PARA_INDENT_2: number = 6;

    static readonly TABLE_BORDER_WIDTH_NORMAL: number = 0.5;
    static readonly TABLE_BORDER_WIDTH_STRONG: number = 2;
    static readonly TABLE_BORDER_WIDTH_STRONG_2: number = 1.25;
    static readonly FOOTER_BORDER_WIDTH: number = 2;

    static readonly MARGIN_DEFAULT_BOTTOM_TABLE: number = 93;
    static readonly MARGIN_DEFAULT: number = 70;
    static readonly MARGIN_DEFAULT_LOW: number = 60;
    static readonly MARGIN_BOTTOM: number = 90;
    static readonly MARGIN_TOP: number = 90;
    static readonly MARGIN_TOP_2: number = 120;
    static readonly MARGIN_TOP_3: number = 130;

    static readonly MARGIN_IMAGE_1: number = 36;
    static readonly MARGIN_IMAGE_2: number = 32;
    static readonly MARGIN_IMAGE_3: number = 24;

    static readonly REPORT_CONTENT_START_OFFSET_1: number = 54;
    static readonly REPORT_CONTENT_START_OFFSET_2: number = 5;

    static readonly REPORT_CONTENT_SRNO_WIDTH: number = 42;
    static readonly REPORT_CONTENT_SRNO_WIDTH_2: number = 18;
    static readonly REPORT_CONTENT_SRNO_WIDTH_3: number = 32;

    static readonly MARGIN_DEFAULT_SRNO: number = ReportConst.REPORT_CONTENT_START_OFFSET_1 + 30;


    static readonly TABLE_CONTENT_TEXT_START_OFFSET: number = 4;
    static readonly TABLE_START_OFFSET: number = 5;

    static readonly DEFAULT_PAGE_WIDTH: number = 595.28;
    static readonly DEFAULT_PAGE_HEIGHT: number = 841.89;
    static readonly DEFAULT_PAGE_CM_WIDTH: number = 28.3467;//ReportConst.DEFAULT_PAGE_WIDTH / 21;

    static readonly COLOR_BLACK = rgb(0, 0, 0);
    static readonly COLOR_WHITE = rgb(1, 1, 1);
    static readonly COLOR_GREY = rgb(0.5, 0.5, 0.5);
    static readonly COLOR_BLUE_DARK = rgb(0.11, 0.26, 0.49);
    static readonly COLOR_BLUE_LIGHT_BV = rgb(0.496, 0.734, 0.812);
    static readonly COLOR_BLUE_BV = rgb(0, 0.478, 0.627);
    static readonly COLOR_RED = rgb(1, 0, 0);
    static readonly COLOR_RED_BV = rgb(0.71, 0.3, 0.18);
    static readonly COLOR_GREEN = rgb(0, 0.69, 0.313);
    
    static readonly REPORT_MSGS = {

        VESSEL_CONFIG_TEST_SHEET_CROSS_REF:"The vessel should be configured for DP operations for this test, as defined in section 2.2 Vessel configuration for DP Operations",
        PENDING_ONBOARD_TABLE: "The above table will be completed during the on-board trials",
        PENDING_ONBOARD_SECTION: "This section will be completed during the on-board trials",
        DISCLAIMER: "DISCLAIMER",
        DISCLAIMER_MSG: "This report is intended for the sole use of the person or company to whom it is addressed and no liability of any nature whatsoever shall be assumed to any other party in respect of its contents.  As to the addressee, BUREAU VERITAS SOLUTIONS MARINE & OFFSHORE SAS  and its Affiliates (the Company) shall not (save as provided in the Company's Conditions of Business dated January 2014, Rev 1) be liable for any loss or damage whatsoever suffered by virtue of any act, omission or default (whether arising by negligence or otherwise) by the Company or any of its servants.",
        PRO_NO: "PROJECT No:",
        REP_NO: "REPORT No:",
        REV: "REV ",
        DATE: "Date", // Sudhanshu Chaubey: Feb 2022 Change "DATE" to "Date"
        DP_SOLUTION: "DP Solution",
        DP_SOLUTION_1: "DP Annual Trial ",
        DP_SOLUTION_ANNUAL_TRIALS: "DP Solution " + Constants.STRING_SEPARATOR_2 + "Annual Trials",
        DOCUMENT_INFORMATION: "DOCUMENT INFORMATION",
        REV_HISTORY: "REVISION HISTORY",
        AMENDMENT_RECORD: "AMENDMENT RECORD",
        FINDINGS_CLOSED: "Findings closed ",
        FINDINGS_OPENED: "Findings opened ",
        A: "A",
        BULLET_POINT: "•",
        B: "B",
        C: "C",
        D: "D",
        REV_0: "0",
        CONTENTS: "CONTENTS",
        EXECUTIVE_SUMMARY: "EXECUTIVE SUMMARY",
        F_EXECUTIVE_SUMMARY_INTRO_1: "Bureau Veritas Solutions M&O (BVS) was requested by %s, %s of %s to produce a DP Annual Trials programme for the %s.",
        F_EXECUTIVE_SUMMARY_INTRO_2: "%s is a DP Equipment Class %s %s which was delivered in %s. It is classed by %s with DP notation: %s",//+Constants.STRING_SEPARATOR_2+"%s.",
        DP_POWER_REDUN: "DP Power System Redundancy Concept",
        WCFDI_TITLE: "Worst Case Failure Design Intent (WCFDI)",
        COMPLIANCE: "Compliance",
        F_COMPLIANCE_MSG: "The vessel’s DP Annual Trials for %s were carried out between %s and %s at %s.  The trials were carried out in accordance with the DP Annual Trials programme included in this document. All trials were co-ordinated by the vessel Master or his nominees and were witnessed by %s of BVS.",
        F_COMPLIANCE_MSG_B: "The vessel’s DP Annual Trials for %s were carried out on %s at %s.  The trials were carried out in accordance with the DP Annual Trials programme included in this document. All trials were co-ordinated by the vessel Master or his nominees and were witnessed by %s of BVS.",
        F_COMPLIANCE_MSG_B_1: "The vessel’s DP Annual Trials for %s were carried out at %s.  The trials were carried out in accordance with the DP Annual Trials programme included in this document. All trials were co-ordinated by the vessel Master or his nominees and were witnessed by %s of BVS.",
        F_COMPLIANCE_MSG_1: "On the basis of the trials results, the “%s” is considered fit to carry out DP operations equivalent to IMO DP Equipment Class %s, within the normal operating limits and when the DP system is configured as defined in Section 2.2.",
        F_COMPLIANCE_MSG_2: "On the basis of the trials results, the “%s” is considered fit to carry out DP operations equivalent to IMO DP Equipment Class %s, within the normal operating limits of the vessel, when A level findings arising from the trials which require ‘immediate attention’ have been addressed (refer to Table 5-1)",
        // F_COMPLIANCE_MSG_3: "The vessel’s DP Annual Trials for %s were carried out on %s at %s.  The trials were carried out in accordance with the DP Annual Trials programme included in this document.  All trials were co-ordinated by the vessel Master %s or his nominees and were witnessed by %s of BVS.", //Sudhanshu Chaubey: Feb 2022 Create new parameter for onboard trail compliance only with start date
        INTRODUCTION: "INTRODUCTION",
        SEC_1_1: "Instructions ",
        F_SEC_1_1_1: "Bureau Veritas Solutions M&O (BVS) was instructed by %s, %s of %s, to produce a DP Annual Trials programme for the %s.  BVS was further instructed to attend on board and witness the vessel’s DP Annual Trials for %s.",
        SEC_1_2: "Scope of Work",
        F_SEC_1_2_1: "BVS’s scope of work for this project is outlined in its proposal %s.",
        SEC_1_3: "Conduct of Work",
        F_SEC_1_3_1: "This DP Annual Trials programme was developed from the vessel’s DP FMEA and/or the vessel’s previous DP Annual Trials programme%s.",
        F_SEC_1_3_2: "The vessel’s DP Annual Trials for %s were carried out between %s and %s at %s. All trials were co-ordinated by the Vessel Master or his nominees and were witnessed by %s of BVS.",
        F_SEC_1_3_2_B: "The vessel’s DP Annual Trials for %s were carried out on %s at %s. All trials were co-ordinated by the Vessel Master or his nominees and were witnessed by %s of BVS.",
        SEC_1_4: "Applicable Rules and Guidelines",
        F_SEC_1_4_1: "The trials are intended to show that the vessel continues to meet the requirements of:",//+ Constants.STRING_SEPARATOR_2 +"•    %s"+ Constants.STRING_SEPARATOR_2 +"•    %s",
        APPLICABLE_IMO_CURCULAR: "•	Applicable IMO circular",
        APPLICABLE_CLASS_SOCIETY: "•	Applicable Class society rules",
        F_BULLET_LINE: "•    %s",
        F_SEC_1_4_2: "Guidance was sought from the following industry guidance documents:",
        NOTE_90: "•	Industry guidance document",
        SEC_1_4_3: "IMO MSC/Circ. 645 states:",
        NOTE_99: "Annual survey should be carried out within three months before or after each anniversary date of the initial survey. The annual survey should ensure that the DP-system has been maintained in accordance with applicable parts of the guidelines and is in good working order. Further an annual test of all important systems and components should be carried out to document the ability of the DP-vessel to keep position after single failures associated with the assigned equipment class. The type of test carried out and results should be documented in the FSVAD.",
        SEC_1_4_4: "IMO MSC.1/Circ. 1580 states:",
        NOTE_100: "An annual survey should be carried out within three months before or after each anniversary date of the Dynamic Positioning Verification Acceptance Document. The annual survey should ensure that the DP system has been maintained in accordance with applicable parts of the Guidelines and is in good working order. The annual test of all important systems and components should be carried out to document the ability of the DP vessel to keep position after single failures associated with the assigned equipment class and validate the FMEA and operations manual. The type of tests carried out and results should be recorded and kept on board.",
        F_SEC_1_4_5: "The Anniversary date is taken to be %s",
        SEC_1_5: "Environmental Conditions during Trials",
        SEC_1_5_1: "Environmental conditions throughout the trials period were as shown in Table 1-1.",
        TAB_1_1: "Table 1-1	Environmental Conditions during Trials",
        SEC_1_6: "Key Personnel in Attendance at Trials",
        F_SEC_1_6_1: "The key personnel listed in Table 1-2 were present at the %s DP Annual Trials.",
        TAB_1_2: "Table 1-2	Key Personnel in Attendance",
        SEC_2: "Vessel Particulars",
        SEC_2_1: "General",
        F_SEC_2_1_1: "%s is a DP Class %s %s that was built by %s. It was delivered in %s. ",
        ADDITIONAL_DESC: "ADDITIONNAL DESCRIPTION.",
        SEC_2_1_2: "DP Power System Redundancy Concept.",
        SEC_2_1_3: "Major power system equipment is allocated as shown in Table 2-1 and arranged as shown in Table 2-1.",
        TAB_2_1: "Table 2-1	Allocation of Equipment to DP Redundancy Groups",
        NAME_OF_REDUNDANCY_GROUP: "Name of the redundancy group ",
        FIG_2_1: "Figure 2-1  Overview of DP Redundancy Groups",
        F_SEC_2_1_4: "Figure 2-%s shows the general arrangement of %s.",
        F_SEC_2_1_5: "Figure 2-%s shows a simplified overview of the %s power system.",
        F_FIG_2_2: "Figure 2-%s  General Arrangement of %s",
        FIG_2_3: "Figure 2-%s  Simplified Power System Single Line Drawing",
        F_SEC_2_1_6: "%s general details and main particulars are shown in Table 2-2.",
        F_SEC_2_1_7: "The main DP equipment on %s is shown in Table 2-3.",
        TAB_2_2: "Table 2-2	General Details & Main Particulars",
        TAB_2_3: "Table 2-3	Main DP Equipment",
        TAB_2_4: "Table 2-4	DP Configuration",
        F_SEC_2_2_1: "The vessel’s DP FMEA has determined that the %s should be configured as shown in Table 2-4 for DP operations.",
        SEC_3: "DP Trials Programme",
        SEC_3_1: "Guidance for Testing",
        TRIALS_NOT_PERFORMED: "The trials have not been performed yet",
        SEC_3_1_1: "The client will appoint a Trials Co-ordinator to schedule the necessary resources and organise the conduct of the trials programme. This person must not be the BVS Surveyor.",
        SEC_3_1_2: "During the trials, all relevant shipboard equipment is required to be fully operational.  In particular, all propulsion units and their controls (both manual and automatic), all power generation equipment, all computer systems and all position reference systems must be fully functional, including their alarms, standby units, battery backups, shutdowns, trips, etc.",
        SEC_3_1_3: "All trials will be conducted with the approval of the Master or his nominee and with full regard to the safe navigation of the vessel.",
        SEC_3_1_4: "The Trials Co-ordinator (not the BVS Surveyor) will satisfy himself by whatever means necessary that a test can be conducted safely.  Any test that cannot be conducted safely will be cancelled.  A suitable and sufficient risk assessment should also be carried out by the vessel staff for any tests where there is a risk of equipment damage, even if that test can be conducted safely. An unsatisfactory outcome will be assumed for any test cancelled on the grounds of safety or equipment damage until proven otherwise.",
        SEC_3_1_5: "All tests will be carried out on full DP in realistic environmental conditions or with some varying load on the system induced by movements of the vessel.",
        SEC_3_1_6: "During the trials, the vessel staff will assist as required in recording alarms and failures locally.  Locally means not only at the DP console but also at the ECR, the thruster room, etc.",
        SEC_3_1_7: "During failure tests, the system must not be reinstated until the DP operators, ECR staff and Surveyor are satisfied they understand the full effects of the failure and that all the information or indicators to show what has occurred have been noted.",
        SEC_3_1_8: "When reinstating systems after failure simulations, two people will check that circuit breakers have been reinstated.  Only when everyone is satisfied that the system has been reset and has stabilised will the trials continue.",
        SEC_3_1_9: "If there are any doubts about a test, it will be repeated.  If test results are unexpected, the test will also be repeated.  It should be noted that seemingly small or spurious faults in DP control systems may be the first manifestations of a more serious problem.",
        SEC_3_1_10: "Tests will continue only when all those involved have been informed and (where necessary) suitable communications have been set up, e.g. DP console to thruster room.",
        SEC_3_1_11: "The tests will not only prove hardware redundancy and DP capability after failures but also that the operators have the necessary training and experience to use the system and deal successfully with such failures.",
        SEC_4: "CONCLUSIONS",
        SEC_4_1: "Trials Conclusions",
        F_SEC_4_1_1: "The %s DP Annual Trials for %s were carried out between %s and %s.  The vessel was subjected to a set of tests in accordance with the vessel’s DP Annual Trials checklist, included in this report as APPENDIX B",
        F_SEC_4_1_1_B: "The %s DP Annual Trials for %s were carried out on %s.  The vessel was subjected to a set of tests in accordance with the vessel’s DP Annual Trials checklist, included in this report as APPENDIX B",
        F_SEC_4_1_2: "The trials were assessed against the following DP rules and guidelines, specifically:",//+ Constants.STRING_SEPARATOR_2 +"•    %s"+ Constants.STRING_SEPARATOR_2 +"•    %s",
        F_SEC_4_1_3_A: "On the basis of the trials results, the “%s” is considered fit to carry out DP operations equivalent to IMO DP Equipment Class %s, within the normal operating limits and when the DP system is configured as defined in Section 2.2",
        F_SEC_4_1_3_B: "On the basis of the trials results, the “%s” is considered fit to carry out DP operations equivalent to IMO DP Equipment Class %s, within the normal operating limits of the vessel, when A level findings arising from the trials which require ‘immediate attention’ have been addressed (refer to Table 5-1)",
        SEC_4_2: "Documentation",
        SEC_4_2_1: "APPENDIX A shows a review of documentation related to the maintenance history of the vessel.  This provides evidence that all DP related systems are maintained in accordance with prevailing guidelines.  It also demonstrates that the vessel is maintained by competent staff, backed up by shore-based management experienced in the operation of DP vessels.",
        SEC_4_3: "Machinery Maintenance",
        SEC_4_4: "DP Control System Maintenance",
        SEC_4_5: "Power Generation",
        SEC_4_6: "Power Distribution",
        SEC_4_7: "Power Management",
        SEC_4_8: "Control Loops",
        SEC_4_9: "Environmental and Heading Sensors",
        SEC_4_10: "Position References",
        SEC_4_11: "DP Control",
        SEC_4_12: "Personnel",
        SEC_4_13: "Incidents",
        MARINE_AUX_SYSTEMS: "Marine & Auxiliary Systems",
        POWER_GENERATION: "Power Generation",
        POWER_DISTRIBUTION: "Power Distribution",
        THRUSTERS: "Thrusters",
        DP_CONTROL_SYSTEM: "DP Control System",
        VESSEL_MANAGEMENT_SYSTEM: "Vessel Management system",
        POWER_MANAGEMENT_SYSTEM: "Power Mangement system",
        SYSTEM: "System",
        CONFIGURATION_FOR_DP: "Configuration for DP",
        MAINTENANCE_RECORDS_CHECKED: "Maintenance records checked",
        OUTSTANDING_MAINTENANCE: "Outstanding maintenance",
        LAST_OIL_ANALYSIS_DATE: "Last oil analysis date",
        LAST_OIL_ANALYSIS_RESULT: "Last oil analysis result",
        THRUSTER_RUNNING_HOURS: "Thruster running hours",
        LAST_MAJOR_OVERHAUL: "Last major overhaul",
        REMARKS: "Remarks",
        REMARKS_C: "Remarks:",
        ENGINES: "Engines",
        COMPANY: "Company",
        ENGINE_RUNNING_HOURS: "Engine running hours",
        SWITCHBOARDS: "Switchboards",
        SWITHCBOAR_MAINTENANCE_HEALTH_CHECK: "Switchboard Maintenance/Health Check",
        MODIFICATIONS_SINCE_LAST_TRIALS: "Modifications since last trials",
        REPORT_NUMBER: "Report number",
        DP_ENVIRONMENTAL_SENSORS_AND_POS_REF_SYS: "DP Environmental Sensors & Position Reference Systems ",
        RECORDS_CHECKED: "Records Checked",
        HARDWARE_MODS: "Hardware Modifications",
        HAVE_ANY_MODS_SINCE_LAST_TRIALS: "Have there been any modifications since last DP Annual Trials?",
        HAVE_MODS_TESTED: "Have modifications been thoroughly tested?",
        HAVE_TRIALS_PROCED_UPDATED: "Have trials procedures been updated?",
        ADDITIONAL_COMMENTS: "Additional Comments",
        DP_CAPABILITY_PLOTS: "DP Capability Plots",
        ARE_CORRECT_PLOTS_ONBOARD: "Are the correct capability plots on board?  Plots should include the intact state and post worst case failure state as a minimum.  Reference IMCA M 103.",
        ARE_VERIFY_FOOTPRINTS: "Are there verifying footprints on board?",
        DP_INCIDENTS: "DP Incidents",
        HAVE_INCIDENTS_RECORDED: "Have any DP incidents been recorded?"+Constants.STRING_SEPARATOR_2+"Incidents and events may be recorded using the IMCA reporting format or the company’s own reporting process.",
        HAVE_INCIDENTS_RECORDED_1: "Have any DP incidents been recorded?"+Constants.STRING_BREAK+"Incidents and events may be recorded using the IMCA reporting format or the company’s own reporting process.",
        YES_HOW_MANY: "If yes, how many incidents?",
        IS_THERE_DOC_SATISFACTORY_EXPLANATION: "Is there a documented satisfactory explanation?",
        DP_FMEA_ANNUAL_TRIALS: "DP FMEA & Annual Trials",
        LAST_FMEA_REVISION_DATE: "Last FMEA revision and date",
        HAVE_ALL_FINDINGS_CLOSED: "Have all findings been closed out?",
        ANY_FINDINGS_NOT_DOCUMENTED: "Any findings arising from the FMEA which are not documented as being closed out should be entered in the remarks column opposite.",
        LAST_DP_TRIALS_DATE: "Last DP Annual Trials date",
        HAVE_OPEN_RECOMMEND_FOR_REPORT: "Have any open recommendations been included in the relevant section of this report?",
        OTHER_DOCUEMENTATION: "Other Documentation",
        IS_VESSEL_SPECIFIC_MANUAL: "Is there a vessel specific DP Operations Manual as described in IMCA M103 section 3.3?",
        ARE_APPROPRIATE_CHECKLIST: "Are there appropriate checklists covering field arrival trials, location checklists, periodic checks as appropriate for bridge and engine room?",
        LOGBOOK_UP_TO_DATE: "Is a DP logbook kept up to date?",
        DO_KEY_DP_PERSONNEL_HAVE_ACCESS_TO_GUIDE_DOCS: "Do the key DP personnel have access to appropriate IMCA guidance documents either via internet or hard copy?",
        ARE_RECORDS_AVAILABLE_FOR_VENDORS: "Are records available for vendors’ visits for repair or service of DP system components?",
        KEY_PERSONNEL: "Key Personnel",
        NAME: "Name",
        RANK: "Rank",
        TRAINING: "Training",
        // TIME_ON_VESSEL: "Time on Vessel",
        YEAR_VESSEL_EXP: "Years Vessel Experience", //Sudhanshu Chaubey: April 2022 DT-311
        TIME_IN_RANK: "Time in Rank",

        

        F_SUBJECT_PROV_FINDINGS: "%s – DP ANNUAL TRIALS %s – PROVISIONAL FINDINGS",
        F_SUB_END_OF_TRIALS: "%s – DP ANNUAL TRIALS %s",
        F_OUR_REFERENCE: "Our Reference: %s",
        F_EOD_P1: "Bureau Veritas Solutions M&O (BVS) was instructed to witness the DP Annual Trials Programme of the %s / %s at the request of %s.",
        F_EOD_P2: "%s attended the vessel at %s. The trials were conducted at %s from the %s to %s in accordance with %s.",
        F_EOD_P2_B: "%s attended the vessel at %s. The trials were conducted at %s on %s in accordance with %s.",
        F_EOD_P3: "All trials were coordinated by %s or their nominees and witnessed by %s of BVS.",//MAC / BVS
        F_EOD_P3_1: "All trials were coordinated by the Vessel Master and Chief Engineer or their nominees and witnessed by %s of BVS.",//MAC / BVS
        EOD_P4: "The trials broadly confirm the findings of the vessel’s DP FME(C)A and previous trials.",
        F_EOD_P5: 'On the basis of the trials results, the ‘%s’ is considered fit to carry out DP operations equivalent to IMO DP Equipment Class %s, within the normal operating limits of the vessel. ',
        F_EOD_P6: 'On the basis of the trials results, the ‘%s’ is considered fit to carry out DP operations equivalent to IMO DP Equipment Class %s, within the normal operating limits of the vessel, when those points arising from the trials which require ‘immediate attention’ have been addressed.',
        FOR_IMMD_ATTENTION: 'For immediate attention:',
        FULL_REPORT_TO_FOLLOW: 'A full report and analysis of the trials will follow.',
        ON_BEAHLF_OF_BVS: 'For and on behalf of BV Solutions M&O',
        F_PF_P1: 'The DP Annual Trials of the %s were carried out at %s from %s to the %s in accordance with %s. The following list of provisional findings was generated from the trials results and graded into three categories following IMCA guidelines.  A full report and analysis of the trials results will follow and categories of findings may change subject to internal review.',
        F_PF_P1_B: 'The DP Annual Trials of the %s were carried out at %s on %s in accordance with %s. The following list of provisional findings was generated from the trials results and graded into three categories following IMCA guidelines.  A full report and analysis of the trials results will follow and categories of findings may change subject to internal review.',
        CAT_A_PF_TITLE: 'Category A – For immediate attention:',
        NO_FINDINGS_FOR_CATEGORY: 'There are no findings in this category.',
        OR: 'OR',
        CLOSED_C: 'Closed :',
        CAT_B_PF_TITLE: 'Category B – For attention when reasonably convenient:',
        CAT_C_PF_TITLE: 'Category C – For consideration:',
        F_TEST_NO_STEP_NO: 'Test No. %s ‘%s’ – Step No. %s: %s',
        F_ADDITIONAL_FINDING: '‘%s’ – %s',
        AMEND_TEST_NO_STEP_NO: '%s: %s - Test No. %s – Step No. %s',
        AMEND_ADDITIONAL_FINDING: '%s: %s - ‘%s’'
        // DISCLAIMER: 'Compliance',
        // DISCLAIMER: 'Compliance',

    }


}

export class ReportContentType {
    static readonly TESTCASE: string = "testcase";
    static readonly VESSEL_PARTICULARS: string = "vesselParticulars";
}