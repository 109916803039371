import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injector } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Events } from "@ionic/angular";
import { Observable } from "rxjs";
import { Constants } from "../../app.constants";
import { HttpErrorEnum } from "../../models/enums/index";
//import { AuthenticateService } from '../../providers/service';
import { Logger, LoggerFactory } from '../log';


/**
 * HTTP request builder.
 * 
 * Singleton.
 */
export class HttpRequestBuilder<R> {

    private readonly logger: Logger = this.injector.get(LoggerFactory).buildLogger('HttpRequestBuilder');

    private readonly DEFAULT_MESSAGE_KEY = 'global.error.exception';

    private baseUrl: string = '';

    private method: string;
    private url: string;
    private body: any;
    private options: any = {};
    private interceptor: Function;

    private accountIsDeactivated = false;

    // Injections
    private translateService: TranslateService = this.injector.get(TranslateService);
    private events: Events = this.injector.get(Events);

    constructor(private injector: Injector) {
        //(this.injector.get(AuthenticateService) as AuthenticateService).getDeactivation().subscribe(data => this.accountIsDeactivated = data);
    }

    public get(url: string): HttpRequestBuilder<R> {
        this.method = 'get';
        this.url = url;

        return this;
    }

    public post(url: string): HttpRequestBuilder<R> {
        this.method = 'post';
        this.url = url;

        return this;
    }

    public put(url: string): HttpRequestBuilder<R> {
        this.method = 'put';
        this.url = url;

        return this;
    }

    public delete(url: string): HttpRequestBuilder<R> {
        this.method = 'delete';
        this.url = url;

        return this;
    }

    public withBaseUrl(baseUrl: string): HttpRequestBuilder<R> {
        this.baseUrl = baseUrl;

        return this;
    }

    public withCredentials(): HttpRequestBuilder<R> {
        this.options.withCredentials = true;

        return this;
    }

    public withBody(body: any): HttpRequestBuilder<R> {
        this.body = body;

        return this;
    }

    public withParams(params: any): HttpRequestBuilder<R> {
        for (const prop in params) {
            if (this.options.params == null) {
                this.options.params = new HttpParams();
            }

            this.options.params = this.options.params.set(prop, params[prop]);
        }

        return this;
    }

    /**
     * Set the intended response type.
     * @param type 
     */
    public withResponseType(type: string): HttpRequestBuilder<R> {
        this.options.responseType = type;

        return this;
    }

    /**
     * Return the full response (headers + payload) instead of only the payload as by default.
     */
    public returnResponse(): HttpRequestBuilder<R> {
        this.options.observe = 'response';

        return this;
    }

    /**
     * Add an interceptor which will manage the response and wil have to reject/resolve the promise.
     */
    public interceptResponse(fn: (response: R, resolve: Function, reject: Function) => void): HttpRequestBuilder<R> {
        this.interceptor = fn;

        return this;
    }

    public do(): Promise<any> {
        const client = this.injector.get(HttpClient);
        const args = [this.baseUrl + this.url];
        if (this.body) {
            args.push(this.body);
        }
        args.push(this.options);
        this.logger.debug('do', this.method, args)
        return this.toPromise(client[this.method].apply(client, args));
    }

    private async toPromise(query: Observable<Object>): Promise<any> {
        return await new Promise((resolve: Function, reject: Function) => {
            query.subscribe(
                // ok
                (response: R) => {
                    if (this.interceptor) {
                        this.interceptor(response, resolve, reject);
                    } else {
                        resolve(response);
                    }
                },
                // ko
                (err: HttpErrorResponse) => {
                    // Workaround to evict error when promise initiated with a user is logged but ended when deactivated...
                    if (this.accountIsDeactivated) {
                        resolve();
                    } else {
                        if (err.error instanceof Error) {
                            reject(this.getError(err.error, {
                                messageKey: 'message'
                            }))
                        } else if (err.status === 0 || err.status >= 400) {
                            reject(this.getErrorFromResponse(err, {
                                messageKey: 'message'
                            }))
                        }
                    }
                });
        });
    }

    /**
     *
     * @param {Error} err
     * @param {{messageKey?: string}} options
     * @returns {void}
     */
    public getError(err: Error, options: { messageKey?: string } = {}): Error {
        const { message } = err;
        const { messageKey = this.DEFAULT_MESSAGE_KEY } = options;

        const errorMessage = this.getMessage({
            message: message,
            messageKey: messageKey
        });

        throw new Error(errorMessage);
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @param {{messageKey?: string}} options
     * @returns {void}
     */
    public getErrorFromResponse(response: HttpErrorResponse, options: { messageKey?: string } = {}): Error {
        const { status } = response;
        const { messageKey = this.DEFAULT_MESSAGE_KEY } = options;

        let error: Error;
        switch (status.toString()) {
            case '0': error = this.manageDisconnected(response); break;
            case HttpErrorEnum.ERROR_400: error = this.manageBadRequest(response); break;
            case HttpErrorEnum.ERROR_401: error = this.manageUnauthorized(response); break;
            case HttpErrorEnum.ERROR_403: error = this.manageForbidden(response); break;
            case HttpErrorEnum.ERROR_404: error = this.manageNotFound(response); break;
            case HttpErrorEnum.ERROR_409: error = this.manageConflict(response); break;
            case HttpErrorEnum.ERROR_412: error = this.managePreconditionFailed(response); break;
            case HttpErrorEnum.ERROR_413: error = this.managePayloadTooLargeRequest(response); break;
            case HttpErrorEnum.ERROR_417: error = this.manageExpectationFailedRequest(response); break;
            case HttpErrorEnum.ERROR_500: error = this.manageInternal(response); break;
            default:
                const errorMessage = this.getMessage({
                    messageKey: messageKey, message: response.error
                });
                error = new Error(errorMessage);
        }

        return error;
    }

    /**
     *
     * @param {string} message
     * @returns {string}
     * @private
     */
    private formatMessage(message: string): string {
        return message && message.replace ? message.replace(/<br ?\/?>/g, '\n') : message;
    }

    /**
     *
     * @param {{message?: string; messageKey?: string}} options
     * @returns {string}
     * @private
     */
    private getMessage(options: { message?: string, messageKey?: string, messageParam?: Object }): string {
        const { message, messageKey, messageParam } = options;

        let msg = message;
        if (!message && messageKey) {
            msg = this.translateService.instant(options.messageKey, messageParam);
        }
        return this.formatMessage(msg);
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {string}
     * @private
     */
    private getMessageFromResponse(response: HttpErrorResponse): string {
        try {
            return response.error;
        } catch (err) {
            return null;
        }
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {Promise<string>}
     * @private
     */
    private manageConflict(response: HttpErrorResponse): Error {
        const { headers } = response;

        const error = new Error();

        const clientUpdate = headers.get('X-Client-Update');
        if (clientUpdate === 'true') {
            // if client app must be updated
            error.name = Constants.CLIENT_UPDATE_ERROR_TYPE;
            //TODO wdy - 12/01/2018 - why is it a json string instead of a plain js object ???
            error.message = JSON.parse(response.error).message;

            setTimeout(() => this.events.publish(Constants.EVENTS.HTTP.ERROR.SHOULD_UPDATE_APP));
        } else {
            // simply conflict
            error.name = response.status.toString();
            error.message = response.error;
        }

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageDisconnected(response: HttpErrorResponse): Error {
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            messageKey: 'global.error.server.disconnected'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private managePreconditionFailed(response: HttpErrorResponse): Error {
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            messageKey: 'global.error.server.preconditionFailed'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageForbidden(response: HttpErrorResponse): Error {
        const error = new Error();
        if (response.headers.has('X-Reason-Message')) {
            error.name = 'forbidden_because_of';
            error.message = response.headers.get('X-Reason-Message');
        } else {
            error.name = 'forbidden';
            error.message = this.getMessage({
                messageKey: 'global.error.server.forbidden'
            });
        }

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageInternal(response: HttpErrorResponse): Error {
        const message = this.getMessageFromResponse(response);
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            message: message,
            messageKey: 'global.error.server.internal'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageNotFound(response: HttpErrorResponse): Error {
        const message = this.getMessageFromResponse(response);
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            message: message,
            messageKey: 'global.error.server.notFound'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageUnauthorized(response: HttpErrorResponse): Error {
        const message = this.getMessageFromResponse(response);
        const error = new Error();
        // simply unauthorized
        error.name = response.status.toString();
        error.message = this.getMessage({
            message: message,
            messageKey: 'global.error.server.unauthorized'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageBadRequest(response: HttpErrorResponse): Error {
        const message = this.getMessageFromResponse(response);
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            message: message,
            messageKey: 'global.error.server.badRequest'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private managePayloadTooLargeRequest(response: HttpErrorResponse): Error {
        const message = this.getMessageFromResponse(response);
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            message: message,
            messageKey: 'global.error.server.payloadTooLarge'
        });

        return error;
    }

    /**
     *
     * @param {HttpErrorResponse} response
     * @returns {void}
     * @private
     */
    private manageExpectationFailedRequest(response: HttpErrorResponse): Error {
        const message = this.getMessageFromResponse(response);
        const error = new Error();
        error.name = response.status.toString();
        error.message = this.getMessage({
            message: message,
            messageKey: 'global.error.server.expectationFailed'
        });

        return error;
    }

}
