import { UserBasic } from './user-basic.model';

export class OnboardTile {
    public showSection: boolean;
    public showRevCPlus: boolean;
    public readOnly: boolean;//Some other user or if onboard completed
    public assignedTo: UserBasic;
    public assigned: boolean;//Needed?
    public assignedUserLogggedIn: boolean;
    public showAssignToButton: boolean;
    public subSectionDisabled: boolean;
    public statusReportCompleted: boolean;
    public envCondKeyPersonnelCompleted: boolean;
    public testCaseExecCompleted: boolean;
    public minTestCaseExecCompleted: boolean;
    public trialsConclusionCompleted: boolean;
    public lettersCompleted: boolean;
    
    public showGenerateLettersRevCButton: boolean;
    public showReGenerateLettersRevCButton: boolean;
    public hasNewModsPostLastGenerate: boolean;
    
    public endOfTrialFileKey: string;
    public endOfTrialLocalPath: string;
    public endOfTrialFileName: string;

    public provFindingsFileKey: string;
    public provFindingsLocalPath: string;
    public provFindingsFileName: string;

    public enableFindings: boolean;
    public enableRev0Button: boolean;
    public rev0generated: boolean;
    
}