import { Injectable } from '@angular/core';
import { EnvCondKeyPersonnel } from '../../models/env-conditions.model';
import { EnvCondPersonnelService } from "../service/env-cond-personnel/env-cond-personnel.service";

@Injectable({
  providedIn: 'root'
})
export class CreateEnvConditionKeyService {

  envKeyId: any;
  envKey: EnvCondKeyPersonnel;
  keyPersonalData: any;
  envConditionMandatoryComplete: boolean = false;
  envSensorMandatoryComplete: boolean = false;
  envEditCreateMode:string;

  constructor(
    private envCondPersonnelService: EnvCondPersonnelService
  ) { }

  setAddKeyPersonnelData(keyPersonalData) {
    this.keyPersonalData = keyPersonalData;
  }

  getAddKeyPersonnelData() {
    return this.keyPersonalData;
  }

  /* Mandatory complete */
  setEnvCondMandatoryComplete(isComplete) {
    this.envConditionMandatoryComplete = isComplete;
  }
  setEnvSeonsorMandatoryComplete(isComplete) {
    this.envSensorMandatoryComplete = isComplete;
  }
  getEnvCondMandatoryStatus() {
    let mandatoryCompleteStatus = {
      envConditionMandatoryComplete: this.envConditionMandatoryComplete,
      envSensorMandatoryComplete: this.envSensorMandatoryComplete
    }
    return mandatoryCompleteStatus;
  }

  /*SET GET ENV ID*/
  setEnvKeyId(envKeyId) {
    this.envKeyId = envKeyId;
  }
  getEnvKeyId() {
    return this.envKeyId;
  }
  async getEnvCondKeyData() {
    this.envKey = await this.envCondPersonnelService.getCurrentEnvCondKeyPersonnelForAnnualTrial(this.envKeyId);
    return this.envKey;
  }
  
 
  //SET ENV CONDITION
  envTrialData;
  setEnvTrialFirstStep(envTrialData){
    this.envTrialData=envTrialData
  }
  getEnvTrialFirstStep(){
    return this.envTrialData;
  }

  //check Model is CREATE/UPDATE MODE use in modal/Footer btn
  getEnvKeyMode(){
    return this.envEditCreateMode;
  }
  
  setEnvKeyMode(mode){
    this.envEditCreateMode = mode;
  }

}
