import { AbstractDocument } from "./abstract-document.model";
import { Constants } from '../app.constants';

/**User Profile information */
export class UserProfile extends AbstractDocument {
    /**SLO of user */
    public username: string;
    // public fullName: string;//Manoj Removed
    public firstName: string;
    public lastName: string;
    public email: string;
    public userId: string;
    public office: string;
    public roles: Array<string> = [];
    public vesselIds: Array<string> = [];
    public vesselEditIds: Array<string> = [];//Expert
    public vesselReviewIds: Array<string> = [];//Review
    public vesselApproveIds: Array<string> = [];//Review
    public vesselOBTIds: Array<string> = [];//Surveyor
    type= Constants.TYPES.USER_PROFILE;

}