import { Constants } from './../../../app.constants';
import { Injectable, Injector } from '@angular/core';
import PouchDB from 'pouchdb';

// MISC
import { Config } from '../../../providers/core';
import { Logger, LoggerFactory } from '../../../commons/log';

// PROVIDERS
//import { SynchroConflictPouchDBProvider } from './synchro-conflict-pouchdb.provider';
import { SynchroPouchDBProviderParamHelper } from './synchro-pouchdb-param-helper';
import { PouchdbDatabase } from '../../../providers/core/pouchdb/pouchdb-database';
import { PouchDBChangesListener } from './pouchdb-changes-listener';
import { AuthenticationStore } from '../../../providers/service';
import { ConnectivityService } from '../../service/connectivity/connectivity.service';
import { AlertController } from '@ionic/angular';
import { SynchroPouchDBLiveProvider } from './synchro-pouchdb-live.provider';
import { SynchroUtils } from './synchro-utils';

enum FirstSyncAction {
    ABORT, RUN, SKIP
}

@Injectable()
export class SynchroPouchDBProvider {

    private logger: Logger = this.injector.get(LoggerFactory).buildLogger("SynchroPouchDBProvider");
    private pouchDBChangesListener: PouchDBChangesListener = this.injector.get(PouchDBChangesListener);

    private remoteOption: Object;
    private remoteDatabase;
    private loggedInUser: string;


    // Injection
    private alertCtrl: AlertController = this.injector.get(AlertController);
    private authenticationStore: AuthenticationStore = this.injector.get(AuthenticationStore);
    private config: Config = this.injector.get(Config);
    private connectivityService: ConnectivityService = this.injector.get(ConnectivityService);
    private database: PouchdbDatabase = this.injector.get(PouchdbDatabase);
    //private synchroConflictPouchDBProvider: SynchroConflictPouchDBProvider = this.injector.get(SynchroConflictPouchDBProvider);
    private synchroLivePouchDBProvider: SynchroPouchDBLiveProvider = this.injector.get(SynchroPouchDBLiveProvider);
    private parameterHelper: SynchroPouchDBProviderParamHelper = this.injector.get(SynchroPouchDBProviderParamHelper);

    constructor(protected injector: Injector) {
        this.remoteOption = Constants.CONFIG.DATABASE.WORKER.REMOTE_OPTION;
    }

    /**
     * Get the local database
     */
    private get getloggedInUser() {
        return this.loggedInUser;
    }


    /**
     * Get the local database
     */
    private get localDatabase() {
        return this.database.db;
    }

    /**
     * Get the remote database
     */
    public get db() {
        return this.remoteDatabase;
    }

    /**
     * Create the remote database if not already created
     */
    private createRemoteDatabase() {
        if (!this.remoteDatabase) {
            this.logger.info("createRemoteDatabase: "+JSON.stringify(this.remoteOption));
            // this.logger.info("createRemoteDatabase: "+this.config.get(Constants.CONFIG.DATABASE.WORKER.REMOTE));
            // this.logger.info("createRemoteDatabase: "+Constants.CONFIG_DB_REMOTE);
            // this.remoteDatabase = new PouchDB(this.config.get(Constants.CONFIG.DATABASE.WORKER.REMOTE), this.remoteOption);
            this.remoteDatabase = new PouchDB(Constants.CONFIG_DB_REMOTE, this.remoteOption);
            if (Constants.MOBILE_BUILD) {
                this.remoteDatabase = new PouchDB(this.config.get(Constants.CONFIG.DATABASE.WORKER.REMOTE), this.remoteOption);
            }
            this.logger.info("createRemoteDatabase: "+ this.remoteDatabase);
        }
    }

    /**
     * Stop synchronization and disconnect from local database
     */
    public disconnect() {
        this.stopSync();
        this.database.disconnect();
    }

    /**
     * Stop synchronization and delete the local database
     */
    public deleteDatabase() {
        this.stopSync();
        this.database.deleteDatabase();
    }

    /**
     * Stop the synchronization
     */
    public stopSync() {
        //this.synchroConflictPouchDBProvider.stopSyncConflict();
        this.pouchDBChangesListener.stopChangesListening();
        if (this.synchroLivePouchDBProvider.task) {
            this.logger.info('Stopping sync');
            this.synchroLivePouchDBProvider.task.cancel();
            this.synchroLivePouchDBProvider.task = null;
        }
        if (this.remoteDatabase) {
            this.logger.info('Closing remote database');
            this.remoteDatabase.close();
            this.remoteDatabase = null;
        }
    }

    /**
     * Initialize local database and synchronization
     * 
     * @returns {Promise<void>} 
     * @memberof SynchroPouchDBProvider
     */
    public async initSyncDb(loggedInUser: string): Promise<void> {
        this.loggedInUser = loggedInUser;
        await this.initLocalDatabase(loggedInUser);
        if (!Constants.CB_SYNC_ENABLED) {
            return;
        }
        this.createRemoteDatabase();

        let syncForFirstTime = await this.isFirstSyncForFirstTime();

        switch (await this.checkNetworkBeforeFirstSync(syncForFirstTime)) {
            // First sync must be aborted
            case FirstSyncAction.ABORT:
                this.logger.warn("First sync : aborted, caused by poor network");
                this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.ABORTED.POORNETWORK, syncForFirstTime);
                break;
            // First sync must be normally runned
            case FirstSyncAction.RUN:
                // If first sync must be run
                if (syncForFirstTime) {
                    await this.initFirstSyncForEmptyDatabase();
                } else {
                    await this.initFirstSync();
                }
                break;
            // First sync must be skipped
            case FirstSyncAction.SKIP:
                this.logger.warn("First sync : skipped");
                this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.SKIPPED);
                break;
        }
    }

    /**
     * Check if first sync executed for first time or not.
     * 
     * @private
     * @returns {Promise<boolean>} 
     * @memberof SynchroPouchDBProvider
     */
    private async isFirstSyncForFirstTime(): Promise<boolean> {
        let syncForFirstTime = false;
        // If data base is empty
        if (await this.database.isDatabaseEmpty()) {
            this.logger.info("Database is empty");
            syncForFirstTime = true;
        }
        // Else database is not empty but not have a last_seq number stored (unstable state)
        else if ((await this.parameterHelper.getLastSeq()) === undefined) {
            this.logger.warn("Database existing without last_seq number");
            // Reset database to be sure to have a correct state
            await this.resetLocalDatabase(this.getloggedInUser);
            syncForFirstTime = true;
        }
        return syncForFirstTime;
    }

    /**
     * Check the network quality before running first sync.
     * 
     * @private
     * @param {boolean} isFirstSyncForFirstTime true if is a first sync for the first time
     * @returns {FirstSyncAction} the sync action
     * @memberof SynchroPouchDBProvider
     */
    private async checkNetworkBeforeFirstSync(isFirstSyncForFirstTime: boolean): Promise<FirstSyncAction> {
        this.logger.info("First sync : check for poor network");
        // First synchro is always runned when user is online with a good network
        if (this.connectivityService.isConnected()) {
            return FirstSyncAction.RUN;
        }
        // If is the first "first sync"
        else {
            
            if (isFirstSyncForFirstTime) {
                // No question to user, directly aborted
                this.logger.warn("First sync : aborted, caused by poor network");
                // return FirstSyncAction.ABORT;
                this.logger.warn("First sync : Code commented: Running sync for poor/no network");
                return FirstSyncAction.RUN;
            }
        }
        // Else is not the first "first sync"
        // Show confirm message to user to choose to continue without sync or not
        return new Promise<FirstSyncAction>(resolve => {
            this.confirmSyncFromUser(resolve);
        });
    }

    // Show confirm message to user to choose to continue without sync or not
    async confirmSyncFromUser(resolve){
        const alert = await this.alertCtrl.create({
            //header: "global.synchronisation.aborted.poorNetwork.notFirstTime.title",
            header:Constants.synchronisation.aborted.poorNetwork.notFirstTime.title,
            message: Constants.synchronisation.aborted.poorNetwork.notFirstTime.message,
            buttons: [
                {
                    cssClass: 'button-yes',
                    text: Constants.synchronisation.aborted.poorNetwork.notFirstTime.buttons.offline,
                    handler: () => { 
                        resolve(FirstSyncAction.SKIP) 
                    }
                },
                {
                    cssClass: 'button-no',
                    text: Constants.synchronisation.aborted.poorNetwork.notFirstTime.buttons.later,
                    handler: () => {
                        resolve(FirstSyncAction.ABORT);
                    }
                }
            ]
        });
        await alert.present();
    }

    /**
     * Initilialize the local database.
     * 
     * @private
     * @returns {Promise<void>} 
     * @memberof SynchroPouchDBProvider
     */
    private async initLocalDatabase(loggedInUser: string): Promise<void> {
        // const principal = this.authenticationStore.principalValue;
        this.logger.info('Initializing local DB for user: ', loggedInUser);//, Constants.CONFIG_DB_SYNC_USER);
        // const login = principal.login;
        // this.remoteOption['auth'] = { username: principal.userId, password: principal.password };
        // await this.database.initDb(login);

        // const principal = { login: "admin", userId: this.config.get(Constants.CONFIG.DATABASE.WORKER.SYNC_USER), 
        // password: this.config.get(Constants.CONFIG.DATABASE.WORKER.SYNC_PWD) };

        let principal = { login: "admin", userId: Constants.CONFIG_DB_SYNC_USER, 
        password: Constants.CONFIG_DB_SYNC_PWD };

        if (Constants.MOBILE_BUILD) {
            principal = { login: "admin", userId: this.config.get(Constants.CONFIG.DATABASE.WORKER.SYNC_USER), 
            password: this.config.get(Constants.CONFIG.DATABASE.WORKER.SYNC_PWD) };
        }

        // this.logger.info('Initializing local DB for user OLD: ', JSON.stringify(principalOLD));
        // this.logger.info('Initializing local DB for user NEW: ', JSON.stringify(principal));

        this.remoteOption['auth'] = { username: principal.userId, password: principal.password };
        await this.database.initDb(loggedInUser);//principal.login);
    }

    /**
     * Initialize the sync for an empty database.
     * In case of an empty database the classic "replicate" process is not used to permit best performances.
     * In this method docs are retreived by allDocs method and written one by one with a bulk.
     * 
     * @private
     * @returns {Promise<void>} 
     * @memberof SynchroPouchDBProvider
     */
    private async initFirstSyncForEmptyDatabase(): Promise<void> {
        this.logger.info('First sync : init for empty database');
        const marker = "initFirstSyncForEmptyDatabase";
        this.logger.markDate(marker);
        try {
            const response = await this.remoteDatabase.allDocs({
                include_docs: true,
                //returnDocs: true,
                attachments: true,
                update_seq: true
                //binary: true
            });
            console.log("initFirstSyncForEmptyDatabase: "+JSON.stringify(response));
            for (let row of response.rows) {
                const doc = row['doc'];
                // If doc has attachments container
                if (doc._attachments) {
                    await this.updateDocWithAttachments(doc);
                }
                await this.localDatabase.bulkDocs({ docs: [doc], new_edits: false });
                this.logger.debug("Bulk successfull", doc);
            }
            this.logger.infoDuration('First sync : for empty database complete in', marker);
            this.logger.info(`First sync : lastSeq = ${response.update_seq}`);
            this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.SUCCESS);
            await this.parameterHelper.storeLastSeq(response.update_seq);
            await this.startLiveSync();
        } catch (err) {
            // Send event
            this.logger.error("Error during init sync for empty database", err);
            this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.FAILED, err);
        }
    }

    /**
     * Store attachments in a doc.
     * 
     * @private
     * @param {*} doc 
     * @returns {Promise<void>} 
     * @memberof SynchroPouchDBProvider
     */
    private async updateDocWithAttachments(doc: any): Promise<void> {
        // For each attachments in the doc
        const docAttachmentsNames = Object.keys(doc._attachments);
        for (let docAttachmentsName of docAttachmentsNames) {
            const docAttachment = doc._attachments[docAttachmentsName];
            if (docAttachment.stub) {
                // Call remote db to add data in attachment
                docAttachment.data = await this.remoteDatabase.getAttachment(doc._id, docAttachmentsName);
                // Remove stub key
                delete docAttachment.stub;
            }
        }
    }

    /**
     * Reset by destroying and reinitializing the local database.
     * Used when init sync failed or when an unstable state if detected.
     * 
     * @returns {Promise<void>} 
     * @memberof SynchroPouchDBProvider
     */
    public async resetLocalDatabase(loggedInUser: string): Promise<void> {
        // Recreated database
        this.logger.warn("Reset database");
        await this.localDatabase.destroy();
        await this.initLocalDatabase(loggedInUser);
    }

    /**
     * First run one way, one-off synchronization until completion before initializing the normal synchronization
     * This initial replication is splitted into two phases:
     * - Pull all stats documents from channel REF_STATS 
     * - Pull all documents visible for the user (from all channels) 
     * 
     * This split allows to use distinct replication parameters. Goal is to lower memory usage when retrieving stats documents  
     * 
     */
    private async initFirstSync(): Promise<void> {
        this.logger.info(`First sync : init with lastSeq = ${await this.parameterHelper.getLastSeq()}`);
        const marker = "initFirstSync";
        this.logger.markDate(marker);

        // Do one way, one-off sync from the server to pull all stats documents from channel REF_STATS 
        this.localDatabase.replicate.from(this.remoteDatabase, await this.parameterHelper.getFirstSyncStatsParameters())
            .on('error', async (err) => {
                this.logger.error('First sync : for stats error: ', err);
                this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.FAILED, err);
            })
            .on('active', async (info) => {
                this.logger.info('First sync : for stats active');
                this.logger.debug('First sync : for stats active', info);
            })
            .on('change', async (change) => {
                this.logger.info('First sync : for stats change');
                this.logger.debug('First sync : for stats change', change);
            })
            .on('complete', async (info) => {
                this.logger.infoDuration('First sync : for stats complete in', marker);
                this.logger.debug('First sync : for stats complete', info);
                this.logger.markDate(marker);

                // Do one way, one-off sync from the server to pull all documents visible for the user (from all channels) 
                this.localDatabase.replicate.from(this.remoteDatabase, await this.parameterHelper.getFirstSyncParameters())
                    .on('error', async (err) => {
                        this.logger.error('First sync : for all documents error: ', err);
                        this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.FAILED, err);
                    })
                    .on('active', async (info) => {
                        this.logger.info('First sync : for all documents active');
                        this.logger.debug('First sync : for all documents active', info);
                    })
                    .on('change', async (change) => {
                        this.logger.info('First sync : for all documents change');
                        this.logger.debug('First sync : for all documents change', change);

                        await this.parameterHelper.storeLastSeq(info.last_seq);
                    })
                    .on('complete', async (info) => {
                        this.logger.infoDuration('First sync : for all documents complete in', marker);
                        this.logger.debug('First sync : for all documents complete', info);

                        this.publishEvent(Constants.EVENTS.DB.SYNC.INIT.SUCCESS);
                        await this.parameterHelper.storeLastSeq(info.last_seq);

                        // consistency check
                        const errs = await this.checkConsistency();
                        if (errs.length == 0) {
                            this.logger.info('Consistency check OK');
                        } else {
                            this.logger.error('Consistency check', errs);
                        }

                        // then two-way, continuous, retry-able sync
                        await this.startLiveSync();
                    });
            });
    }

    /**
     * Initialize the two-way, continuous, retriable synchronization
     */
    public async startLiveSync(): Promise<void> {
        if (!Constants.CB_SYNC_ENABLED) {
            return;
        }
        this.logger.debug('Live sync : start with syncTask :', this.synchroLivePouchDBProvider.task);
        if (!this.synchroLivePouchDBProvider.task || !this.synchroLivePouchDBProvider.task.canceled) {
            this.logger.info('Live sync : start');

            this.createRemoteDatabase();
            //await this.synchroConflictPouchDBProvider.startSyncConflict();
            await this.pouchDBChangesListener.startChangesListening();
            await this.synchroLivePouchDBProvider.run(this.localDatabase, this.remoteDatabase);
        } else {
            this.logger.warn('Live sync : cancel');
        }
    }

    public async checkConsistency(): Promise<any> {
        const errs = [];
        try {
            const localDocs = (await this.localDatabase.allDocs()).rows;
            const remoteDocs = (await this.db.allDocs()).rows;
            localDocs.forEach(async (localDoc) => {
                // INSPECTION_QUESTIONS aren't replicated
                const remoteDoc = remoteDocs.find((d) => d.id === localDoc.id);
                if (remoteDoc) {
                    // add error if local rev is inferior to remote rev
                    if (parseInt(localDoc.value.rev) < parseInt(remoteDoc.value.rev)) {
                        errs.push({ type: 'rev', local: localDoc, remote: remoteDoc });
                    }
                } else {
                    errs.push({ type: 'not_found', local: localDoc });
                }
            });
        } catch (error) {
            console.error('SynchroPouchDBProvider-checkConsistency', error);
        }

        return errs;
    }

    /**
     * Pusblish an event
     * @param {string} eventKey - the event key
     * @param {any} eventData the data to send as the event
     */
    private publishEvent(eventKey: string, ...args: any[]) {
        SynchroUtils.publishEvent(this.injector, eventKey, ...args);
    }

}