export enum VesselStatusEnum {
    NOT_FOUND = -1,
    DRAFT = 0,
    CREATED = 10,
    TEST_CASE_CREATED = 20,
    TRIAL_DRAFT = 30,
    TRIAL_CREATED = 40,
    REV_DRAFT = 50,
    REV_REVIEW_PROGRESS = 60,
    REV_REVIEW_DENIED = 70,
    REV_REVIEW_ACCEPTED = 80,
    REV_APPROVAL_PROGRESS = 90,
    REV_APPROVAL_DENIED = 100,
    REV_APPROVAL_ACCEPTED = 110,
    OBT_NOT_STARTED = 120,
    OBT_IN_PROGRESS = 130,
    TRIAL_CLOSED = 140
}