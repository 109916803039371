import { LoadingController } from '@ionic/angular';
import { Injector } from '@angular/core';


export abstract class LoaderUtils {
    
    /**
     * Create and show a loading modal.
     * @returns {Promise<Loading>} the loading modal
     * @memberof LoaderUtils
     */
    private static async _presentLoading(injector: Injector) {
        const loadingCtrl = injector.get(LoadingController);
        const loading = await loadingCtrl.create();
        await loading.present();
        return loading;
    }

    /**
     * Call the supplied function while a "loader" is displayed to user.
     * 
     * @protected
     * @param {Function} fn the function to call, should be a "async" function
     * @returns {Promise<void>} 
     * @memberof LoaderUtils
     */
    public static async doWithLoader(fn: Function, injector: Injector): Promise<void> {
        const loader = await this._presentLoading(injector);
        try {
            await fn();
        }
        finally {
            await loader.dismiss();
        }
    }
}