export enum HttpErrorEnum {
    ERROR_400 = "400",
    ERROR_401 = "401",
    ERROR_403 = "403",
    ERROR_404 = "404",
    ERROR_409 = "409",
    ERROR_412 = "412",
    ERROR_413 = "413",
    ERROR_417 = "417",
    ERROR_500 = "500",
    
}