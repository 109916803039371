import { ProfilesEnum } from "./enums/profiles.enum";

export abstract class AccountAbstract {

    public userId: string;
    public avatar: string;
    public commercialUse: boolean;
    public dataForAccount: boolean;
    public usageStatistics: boolean;
    public company: string;
    public creationDate: Date;
    public email: string;
    public emailNotifications: boolean;
    public flagOrCountry: string;
    public friends: Array<string> = [];
    public lastUpdateDate: Date;
    public profile: ProfilesEnum;
    public pushNotifications: boolean;
    public status: string;
    public password: string;
    public captchaCode: string;
    public captchaAnswer: string;
    public cookiesAccepted: Boolean;
    
}