import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'lpad' })
export class LpadPipe implements PipeTransform {

    transform(value: any, length: any, char: any): any {
        let workingValue = value;
        if (!value) {
            workingValue = '0';
        }
        let result = _.padStart(String(workingValue), length, char);
        return result;
    }

}