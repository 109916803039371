import { Injectable, Injector } from "@angular/core";
import { Events } from "@ionic/angular";
import { Subject } from "rxjs";

import { Constants } from "../../../app.constants";

import { PlatformService } from '../platform/platform.service';

/**PSC Ready code... Not directly used in DP Solution */
@Injectable()
export class AuthenticationStore {

    /**
     * Anonymous user (not authentified).
     * 
     * @static
     * @memberof AuthenticationStore
     */
    public static readonly ANONYMOUS_USER = {
        isAnonymous: true
    }

    private events: Events = this.injector.get(Events);
    private platformService: PlatformService = this.injector.get(PlatformService);
    //private firebaseAnalytics: FirebaseAnalytics = this.injector.get(FirebaseAnalytics);

    private isAuthenticatedSubject: Subject<boolean> = new Subject<boolean>();
    private principalSubject: Subject<any> = new Subject<any>();
    private principal: any = AuthenticationStore.ANONYMOUS_USER;

    constructor(private injector: Injector) {
        // logout user if app should be updated
        this.events.subscribe(Constants.EVENTS.HTTP.ERROR.SHOULD_UPDATE_APP, () => this.setPrincipal(AuthenticationStore.ANONYMOUS_USER));
    }

    /**
     * Let caller subscribe to change in authentication and principal value.
     * 
     * @param {(value: boolean) => void} authenticationChange 
     * @param {(value: any) => void} principalChange 
     * @memberof AuthenticationStore
     */
    public changes(authenticationChange: (value: boolean) => void, principalChange: (value: any) => void): void {
        this.isAuthenticatedSubject.subscribe(authenticationChange);
        this.principalSubject.subscribe(principalChange);
    }

    /**
     * Set user to principal (connected user)
     * 
     * @param {*} principal Connected user
     * @param {boolean} [sendAuthenticationNotification=true] 
     * @returns {any} principal
     * @memberof AuthenticationStore
     */
    public setPrincipal(principal: any, sendAuthenticationNotification: boolean = true): any {
        this.principal = principal;
        this.principalSubject.next(principal);

        if (sendAuthenticationNotification) {
            this.isAuthenticatedSubject.next(!principal.isAnonymous);
        }

        if (!this.platformService.platformIsBrowser() && principal.login != null) {
            /*
            this.firebaseAnalytics.setUserId(principal.login).then((res: any) => console.log(res))
            .catch((error: any) => console.error(error));
            */
        }

        return this.principal;
    }

    /**
     * Clear the store.
     */
    public async clear(): Promise<void> {
        await this.setPrincipal(AuthenticationStore.ANONYMOUS_USER);
    }

    /**
     *
     * @returns {any}
     */
    public get principalValue(): any {
        return this.principal;
    }

    /**
     * Check if user has at least one of roles passed in params.
     * @param roles Roles to check
     */
    public hasAnyRoles(...roles: Array<string>): boolean {
        let principal = this.principalValue;
        if (!principal.isAnonymous) {
            return roles.some((item) => {
                return principal.roles.indexOf(item) > -1
            }, this);
        }
        return false;
    }

    /**
     * Check if user has all roles passed in params.
     * @param roles Roles to check
     */
    public hasRoles(...roles: Array<string>): boolean {
        let principal = this.principalValue;
        if (!principal.isAnonymous) {
            return roles.every((item) => {
                return principal.roles.indexOf(item) > -1
            }, this);
        }
        return false;
    }

    /**
     * Check if user has profile passed in params.
     * @param profile Profile to check
     */
    public isProfile(profile: any): boolean {
        const principal = this.principalValue;
        if (!principal.isAnonymous) {
            return principal.profile === profile;
        }
        return false;
    }

}
